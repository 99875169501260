import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import AccordionTextDataProposicaoParlamentar from "./AccordionTextDataProposicaoParlamentar";
import PaginationSaveState from "../../../components/PaginationSaveState";
import ParlamentarProposicaoService from "../../../services/ParlamentarProposicaoService";
import { IListInfo } from "../../../models/IListInfo";
import FilterQuantity from '../../../components/filter/FilterQuantity';

export default function ParlamentarProposicoesProposicao(props: { id_parlamentar: number,permissaoUsuario?: number }) {
    const { pallet } = usePallet();
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1)
    };
    

    useEffect(() => {
        getListProposicaoParlamentar();
        // eslint-disable-next-line
    }, [currentPage,currentPageSize])

    const getListProposicaoParlamentar = useCallback(() => {
        if (currentPage && currentPage > 0)
            ParlamentarProposicaoService.GetList(props.id_parlamentar, currentPage,currentPageSize).then(async res => {
                if (res && res.success) {
                    setLista(res.lista);
                    setInfoList(res.listInfo);
                }
            })
    }, [props.id_parlamentar, currentPage,currentPageSize])

    const setPage = (page: number) => setCurrentPage(page);

    return (

        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Grid container direction="row" alignItems="baseline" justifyContent="flex-start" style={{
                        paddingLeft: "20px",
                        paddingTop: `${isMobile ? "10px" : "0px"}`,
                        marginBottom: `${isMobile ? "-10px" : "0px"}`
                    }}>
                        <Grid item style={{ marginRight: "8px" }}>
                            <Grid style={{
                                color: `${pallet.textColorSecondary}`,
                                fontWeight: pallet.general.weight_600
                            }}>
                                {infoList?.totalItens}
                            </Grid>

                        </Grid>
                        
                        <Grid item>
                            <Grid item style={{
                                fontSize: "14px",
                                fontWeight: pallet.general.weight_600
                            }}>
                                {infoList?.totalItens > 1 ? "REGISTROS" : "REGISTRO"}
                            </Grid>
                        </Grid>
                        {infoList?.totalItens > 4 ?
                         <Grid item xs={12} style={{marginTop:"10px", marginBottom:"20px"}}>
                                <FilterQuantity
                                    list={infoList}
                                    currentPageSize={currentPageSize}
                                    onChange={handleOnChangeItensPerPageSize}
                                />
                                </Grid>
                            : null}

                        {/* <Grid>
                            <MenuButtonIconLeftRadio title={"Visualizar"} icon={<Visibility />} itens={["Tudo", "Titular", "Suplente"]} />
                        </Grid> */}
                    </Grid>
                    <Grid style={{paddingLeft: `${isMobile ? "20px" : "20px"}`, paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: "20px", paddingBottom: "20px", textTransform: "capitalize" }}>
                        <AccordionTextDataProposicaoParlamentar lista={lista} permissaoUsuario={props.permissaoUsuario} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                    <PaginationSaveState
                        name={'ParlamentarProposicaoList'}
                        pages={infoList?.totalPages}
                        page={currentPage}
                        onChange={setPage}
                    />
                </Grid>
            </Grid>
        </div>
    )
}