import React from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as EmptyState } from '../img/emptyCorrelata.svg';

function EmptyError(props: { title: string, description: string }) {
  return (
    <>
      <EmptyState style={{ marginTop: '15px' }} />
      <h2>{props.title ? props.title : ""}</h2>
      <Grid>{props.description ? props.description : "Não existem dados a apresentar."}</Grid>
    </>
  );

}

export default EmptyError;
