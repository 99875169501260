import { useAuthorization } from '../../../../contexts/AuthorizationContext';
import { useEffect, useRef } from 'react'
import { useDidMountEffect } from '../../../../utils/utils';
import { Grid, Avatar, Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Step, StepLabel, StepContent } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillCursors from 'quill-cursors';
import styled from "styled-components";
import { StartSocket } from '../../../../services/SocketService';
import { useState } from 'react';
import IconLabelButtons from './../../../../components/ButtonIconSalvar';
import { usePallet } from './../../../../contexts/PalletContext';
import { useAlert } from "../../../../contexts/AlertContext";
import { setTextRange } from 'typescript';
import $ from "jquery";
import ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaColaborativaService";
import { Height, SettingsPhoneOutlined } from '@material-ui/icons';
import InputMultilineTextField from '../../../../components/InputMultilineTextField';
import ClearIcon from '@mui/icons-material/Clear';
import NTColaborativaImprimir from './NTColaborativaImprimir';
import { htmlToText } from 'html-to-text';
import StringsFile from 'strings-file';
import { saveAs } from "file-saver";
import CircularLoading from '../../../../components/CircularLoading';
import { isMobile } from 'react-device-detect';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
// import HTMLtoDOCX from 'html-to-docx';
import html2pdf from 'html2pdf.js';
import { asBlob } from 'html-docx-js-typescript'

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export interface IRange {
    index: number,
    length: number
}
interface IActiveUser {
    acesso: string;
    cliente: {
        id: number;
        nome: string;
    }
    departamento: string;
    id: Number;
    nome: string;
    url_foto: string;
    color?: string;
    range?: {
        index: number;
        length: number;
    };
}

const UsersPanel = styled(Grid)`
  min-height: 55px;
  width: 100%;
  display: flex;
  position: relative;

  .ButtonGrid {
    margin-left: auto;
    
    button {
      height: 55px;
      margin: 10px;
    }
  }
`;

const ActiveUser = styled(Grid)`
  .userAvatar {
    cursor: pointer;
    margin-top: 6px;
    margin-left: 10px;

    & + .userAvatar {
      margin-left: 5px;
    }
  }

  &::after  {
    content: "";
      height: 3px;
      border-radius: 3px 3px 0 0;
      width: 40px;
      position: absolute;
      bottom: 5px;
      margin-left: 10px;
      /* left: 0; */
      background: ${(props => props.color ? props.color : "unset")}
  }
`;

const Editor = styled(Grid)`
  .ql-container, .ql-editor {
    height: ${props => props.pageHeight - 257}px;
  }
  .ql-container {
    background-color: #E1E8F2;
  }
  .ql-editor {
    background-color: #fff;
    margin: 35px 10%;
    padding: 30px;
  }
  .ql-comments-add {
    width: 100% !important;
    font-size: 15px;
    background: grey!important;
    border-radius: 7px;
    margin-top: 10px;
    color: white!important;

    &:after {
        content: "comentar";
    }
  }
  .ql-print {
    width: 100% !important;
    
    &:after {
      content: "imprimir";
    }
  }
  .ql-cursors {
    .ql-cursor-caret-container {
      margin-left: -15px;
      padding: 0;
    }
    .ql-cursor-flag { 
      display: flex;
      justify-content: center;
      z-index: 100;

      small {
        margin: 0 5px;
      }
    }
  }
`;
const RealTimeQuillEditor = (
    props: {
        docId: number,
        modules,
        value: string,
        onSave,
        onChange,
        onComment?,
        idNotaTecnica: number,
    }
) => {
    const { pallet } = usePallet();
    const { NewAlert } = useAlert();


    const quillRef = useRef(null);
    const [editor, setEditor] = useState(quillRef.current && quillRef.current.getEditor());
    const { user } = useAuthorization();

    const [socketIO, setSocketIO] = useState<any>();
    const [socketData, setSocketData] = useState<string>(props.value);

    const [activeUsers, setActiveUsers] = useState<IActiveUser[]>([{
        acesso: user.acesso,
        cliente: {
            id: user.cliente.id,
            nome: user.cliente.nome,
        },
        departamento: user.departamento,
        id: user.id,
        nome: user.nome,
        url_foto: "",
        color: "red"
    }]);
    const [isConnected, setIsConnected] = useState<boolean>(false);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectionTextRange, setSelectionTextRange] = useState<IRange>({ index: 0, length: 0 });
    const [quote, setQuote] = useState<string>("");
    const [commentary, setCommentary] = useState<string>("");

    const [arrayCommentsState, setArrayCommentsState] = useState([]);
    const [idNotaColab, setIdNotaColab] = useState<number>();
    const [subcomentario, setSubcomentario] = useState('');
    const [subcomentarioList, setSubcomentarioList] = useState([]);
    const [indexSubComments, setIndexSubComments] = useState(0);
    const [loading, setLoading] = useState(false);
    const [minComments, setMinComments] = useState(false);
    const [pageHeight, setPageHeight] = useState(window.innerHeight);

    useEffect(() => {
        const updatePageHeight = () => {
            setPageHeight(window.innerHeight);
        };

        window.addEventListener('resize', updatePageHeight);

        return () => {
            window.removeEventListener('resize', updatePageHeight);
        };
    }, []);


    // useEffect q só vai funcionar depois que renderizar (DidMount) a pagina
    useDidMountEffect(() => {
        props.onChange(props.docId, socketData);
    }, [socketData]);


    Quill.register('modules/cursors', QuillCursors);
    const newModules = {
        cursors: {
            transformOnTextChange: true,
        },
        ...props.modules
    }


    useEffect(() => {
        getComments()
        SubConsulting()
        if (editor) {
            const s = StartSocket(user);
            setSocketIO(s)
            s.on(`NTColaborativa_${props.docId}`, (data) => {
                // if (activeUsers !== data.user) 
                const oldValue = String(socketData).length
                const newValue = data.text.length
                setActiveUsers(data.user);

                if (newValue !== oldValue) {
                    setSocketData(data.text);

                    const cursor = editor.getModule("cursors");

                    data.user.map(async activeUser => {
                        if (activeUser.id !== user.id) {
                            //Cria ponteiro de outros usuarios
                            if (cursor.cursors().filter(x => x.id === activeUser.id).length === 0) {
                                await cursor.createCursor(activeUser.id, activeUser.nome, activeUser.color);
                            }
                            setTimeout(() => {
                                // const range = editor.getSelection() || { index: 0, length: 0 };
                                cursor.moveCursor(activeUser.id, activeUser.range);
                                cursor.toggleFlag(activeUser.id, true);
                                const range = editor.getSelection() || { index: 0, length: 0 };
                                if (activeUser.range && activeUser.range.index < Number(range.index)) {
                                    quillRef.current.setEditorSelection(editor, {
                                        index: Number(range.index) /*+ 1*/ + Number(activeUser.range.length),
                                        length: range.length
                                    })
                                }
                            }, 2);

                        }
                    })
                }
            });

            s.on("disconnect", (reason) => {
                setIsConnected(false)
                s.emit('disconnectToRoom', 'NTColaborativa', props.docId, {
                    id: user.id,
                    range: editor.getSelection(),
                })
            });

            s.on("connect", () => {
                setIsConnected(true);
                s.emit('connectToRoom', 'NTColaborativa', props.docId, {
                    id: user.id,
                    range: editor.getSelection(),
                })
            });

            // Se tiver evento para comentar, cria trigger do botão de comentar
            if (props.onComment) {
                let addCommentBtn = document.querySelector('button.ql-comments-add');
                addCommentBtn.addEventListener('click', () => {

                    let range = editor.getSelection();

                    // nothing is selected
                    if (!range || range.length == 0) {
                        // commentAddClick(null);
                        NewAlert("error", "Selecione um trecho antes de comentar.")
                        return;
                    }

                    commentAddClick(range);

                })
            }
        }

    }, [editor]);


    useEffect(() => {
        setEditor(quillRef.current.getEditor())
    }, [quillRef])


    useEffect(() => {
        // setTimeout(() => {
        //     props.onSave(props.docId, socketData)
        // }, 1000);
        getComments()
    }, [idNotaColab])

    const commentAddClick = (range: IRange) => {
        const text = editor.getText(range.index, range.length)
        setSelectionTextRange(range)
        setQuote(text)
        setModalOpen(true)
    }

    const saveComment = () => {
        editor.formatText(selectionTextRange.index, selectionTextRange.length, {
            background: "#ffe87f",
        });

        let data = {
            nomeComentario: commentary,
            textoGrifado: quote,
            nomeComentarista: user.nome,
            idComentarista: user.id,
        }

        ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.PostComments(props.idNotaTecnica, data).then((res) => {
            setIdNotaColab(res)
            // setTriggerComentarButton(res)

            if (res) {
                NewAlert("success", "Nota Colaborativa cadastrada com sucesso")
            } else {
                NewAlert("error", "Erro ao cadastrar, selecione o trecho e salve novamente");
            }
        })
        // .then(props.onSave(props.docId, socketData));

        setModalOpen(false)
        setQuote("")
        setCommentary("")
        setSelectionTextRange({ index: 0, length: 0 })
        setSocketData(editor.root.innerHTML)
        getComments()
    }

    const getComments = async () => {

        if (props.idNotaTecnica && props.idNotaTecnica !== undefined) {
            setLoading(true)
            await ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.GetComments(props.idNotaTecnica).then((res) => {
                setArrayCommentsState(res)
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            }).then();
        }
    }

    const handleSubComentarioChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setSubcomentario(event.target.value)
        setIndexSubComments(index)
    };

    const saveSubComment = (idNotaColab) => {
        if (subcomentario !== '') {
            let data = {
                subcomentario: subcomentario,
                nomeComentarista: user.nome,
                idComentarista: user.id,
            }
            ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.PostSubComments(idNotaColab, data).then((res) => {
                if (res > 0) {
                    NewAlert("success", "Sub-comentário cadastrado com sucesso")
                    setTimeout(() => {
                        setSubcomentario('')
                    }, 500);
                } else {
                    NewAlert("error", "Erro ao cadastrar, escreva novamente e salve");
                }
            })
                .then(() => SubConsulting());
        } else {
            NewAlert("error", "Observação vazia, escreva e tente novamente");
        }
    }


    const excluirComment = async (idNotaColab) => {
        if (idNotaColab) {
            await ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.DeleteComments(idNotaColab).then((res) => {
                if (res) {
                    NewAlert("success", "Comentário foi excluido com sucesso")
                    setTimeout(() => {
                    }, 500);
                } else {
                    NewAlert("error", "Erro ao excluir, por favor tente novamente");
                }
            });

            setModalOpen(false)
            setQuote("")
            setCommentary("")
            setSelectionTextRange({ index: 0, length: 0 })
            setSocketData(editor.root.innerHTML)
            getComments();
        }
    }

    const SubConsulting = async () => {
        await ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.GetSubComments().then(resSub => {
            setSubcomentarioList(resSub)
        })
    }

    const keyUpActiveDoc = (event) => {
        // console.log("TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT", event.target.innerHTML)
        if (!isConnected) socketIO?.connect()
        const text = editor.root.innerHTML;
        if (socketData.replace(/<[^>]*>/g, "").trim() !== text.replace(/<[^>]*>/g, "").trim()) {
            setSocketData(text);
            socketIO.emit('sendToRoom', 'NTColaborativa', props.docId, text, {
                id: user.id,
                range: editor.getSelection(),
            })
            return true
        }
    }

    const deleteSubComment = (id) => {
        ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.DeleteSubComment(id).then((res) => {
            if (res) {
                NewAlert("success", "Sub-comentário apagado com sucesso")
            } else {
                NewAlert("error", "Erro ao apagar, tente novamente");
            }
        }).then(() => SubConsulting());
    }

    const imprimir = () => {
        // window.print();

        const htmlContent = editor.root.innerHTML;
        const element = document.createElement('div');
        element.innerHTML = htmlContent;
        html2pdf().from(element).set({ margin: 10, filename: 'nota_colaborativa.pdf' }).save();
    }

    // const mousemove = async (event) => {
    //     console.log("pageX: ", event.pageX,
    //         "pageY: ", event.pageY,
    //         "clientX: ", event.clientX,
    //         "clientY:", event.clientY)
    //     await setTesteMouseX(event.pageX)
    //     await setTesteMouseY(event.pageY)
    //     console.log(event)
    // }

    // window.addEventListener('mousemove', mousemove);
    // console.log(window.event)


    // const linkComment = async (t) => {
    //     // let str = t
    //     // let re = /[A-Z]/g
    //     // let reDot = /[.]/g
    //     console.log(socketData.search(t))
    //     // setTrack(document.getElementById("demo").innerHTML = text.search(//))
    //     // document.getElementById("demo").innerHTML = loko
    //     // await editor.root.innerHTML.setSelection(index, length);
    // }

    const exportToDocx = async () => {

        // const fileBuffer = await HTMLtoDOCX(editor.root.innerHTML.replaceAll('<em>','<i>').replaceAll('</em>','</i>'), null, {
        //     table: { row: { cantSplit: true } },
        //     footer: true,
        //     pageNumber: true,

        // });
        const fileBuffer = await asBlob(editor.root.innerHTML, null)
        saveAs(fileBuffer, 'nota_colaborativa.docx');
    }

    // const exportToDocxNatural = async () => {
    //     const htmlContent = editor.root.innerHTML;
    //     const blob = new Blob([htmlContent], { type: 'text/html' });
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'nota_colaborativa.docx';
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //     URL.revokeObjectURL(url);
    // }

    const expandingComments = () => {
        setMinComments(false)
    }

    const minimizeComments = () => {
        setMinComments(true)
    }

    console.log("activeUsers", activeUsers)
    // console.log("User", user)
    // console.log("count characters",editor && editor.root ?  editor.root.innerText.length : null)
    // console.log("editor.root.innerHTML", editor?.root?.innerText)
    // console.log("socketData", socketData)
    // console.log("props.value", props.value)
    // console.log("GTTTTTTTTTTTTTTTTTTTTTTTTTTTT", quillRef?.current?.editor?.options.container?.__quill?.editor?.delta.ops)
    // console.log("props.valueeeeeeeeeeeeeeeeeeeeee", props.value)
    // console.log(quillRef?.current, "arrayComments")
    // console.log(selectionTextRange.index, selectionTextRange.length, "coordenadas")

    const css = `
        @media print {
            body {
                visibility: hidden;
            }
            div.ql-editor {
                visibility: visible;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        `;

    return (
        <div>
            <style>{css}</style>
            <Editor pageHeight={pageHeight}>
                <UsersPanel>
                    {activeUsers?.map(user => {
                        return (
                            <ActiveUser color={user.color}>
                                <Avatar
                                    className="userAvatar"
                                    title={user.nome}
                                    src={
                                        user.url_foto
                                            ?
                                            user.url_foto
                                            :
                                            ""
                                    }

                                />
                            </ActiveUser>
                        )
                    })}
                    <Grid className='ButtonGrid'>
                        <Grid container>
                            <IconLabelButtons
                                key={"salvar"}
                                onClick={() => props.onSave(props.docId, editor.root.innerHTML)}
                                tipo={"salvar"}
                                backgroundColor={pallet.backgroundColorPrimary}
                                border={"1px solid lightgrey"}
                                fontColor={pallet.textColorPrimary}
                                fontSize={pallet.general.size12}
                                padding="0 20px"
                                icon={""}
                                // onBlock={editor?.root?.innerText?.length}
                                title={"SALVAR TEXTO"}
                            />
                            <IconLabelButtons
                                key={"imprimir"}
                                onClick={() => imprimir()}
                                tipo={"imprimir"}
                                backgroundColor={pallet.backgroundColorQuaternary}
                                border={"1px solid white"}
                                fontColor={pallet.backgroundColorPrimary}
                                fontSize={pallet.general.size12}
                                padding="0 20px"
                                icon={""}
                                title={"PDF"}
                            />
                            <IconLabelButtons
                                key={"imprimirDocx"}
                                onClick={() => exportToDocx()}
                                tipo={"imprimirDocx"}
                                backgroundColor={pallet.backgroundColorQuaternary}
                                border={"1px solid white"}
                                fontColor={pallet.backgroundColorPrimary}
                                fontSize={pallet.general.size12}
                                padding="0 20px"
                                icon={""}
                                title={"DOCX"}
                            />
                            {minComments
                                ?
                                <Grid style={{
                                    fontSize: '13px',
                                    cursor: 'pointer',
                                    marginTop: "30px",
                                    marginRight: "30px",
                                    marginLeft: "10px",
                                    fontFamily: `${pallet.general.fontFamily}`,

                                }}
                                    onClick={() => expandingComments()}>
                                    Ver Comentários
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    </Grid>


                </UsersPanel>
                <Grid container>
                    <Grid>
                        <ReactQuill
                            modules={newModules}
                            ref={quillRef}
                            style={{ minHeight: 700, maxWidth: minComments ? "100%" : 750 }}
                            // value={props.value}
                            value={socketData}
                            onKeyUp={keyUpActiveDoc}
                            theme="snow"
                            preserveWhitespace={true}
                        />
                    </Grid>
                    {minComments
                        ?
                        null
                        :
                        <Grid style={{
                            fontFamily: `${pallet.general.fontFamily}`,
                            padding: '10px',
                            marginBottom: '50px',
                            // backgroundColor: `${pallet.backgroundColorTertiary}`,
                            maxWidth: '370px',
                            marginLeft: '15px',
                            borderRadius: '7px',
                        }}>
                            <Grid container justifyContent={"space-between"} style={{
                                fontSize: '13px',
                                fontWeight: pallet.general.weight_600,
                                backgroundColor: `${pallet.backgroundColorQuintenary}`,
                                padding: '10px 20px',
                                borderRadius: '7px'

                            }}>
                                <Grid style={{ marginTop: "5px" }}>
                                    LISTA DE COMENTÁRIOS
                                </Grid>
                                <Grid style={{ cursor: "pointer" }} onClick={minimizeComments}>
                                    <CloseFullscreenIcon />
                                </Grid>
                            </Grid>
                            <Grid style={{
                                maxHeight: `${pageHeight - 247}px`,
                                overflow: 'auto',
                                // marginTop: '20px'
                            }}>
                                {loading ?
                                    <CircularLoading
                                        positionCustom={true}
                                        size={60}
                                        top={isMobile ? "37%" : "50%"}
                                        left={isMobile ? "6%" : "45%"}
                                        zIndex={2}
                                    />
                                    :

                                    arrayCommentsState?.map((x, index) => {
                                        return (
                                            <Grid key={index} style={{ wordBreak: 'break-all' }}>
                                                <div style={{ marginTop: '30px', cursor: "pointer" }}>
                                                    <div style={{ fontSize: '13px', fontWeight: 800, fontStyle: 'italic' }}>
                                                        ...{x.texto_grifado}...
                                                    </div>
                                                    <div style={{ fontSize: '10px' }}>
                                                        <span style={{ fontWeight: 600 }}>Comentário:</span> {x.titulo}
                                                    </div>
                                                    <div style={{ fontSize: '10px' }}>
                                                        Autor: {x.nome_user}
                                                    </div>
                                                </div>
                                                <Grid style={{
                                                    borderLeft: '3px solid grey'
                                                }}>
                                                    {subcomentarioList?.filter(op => op.id_comment === x.id).map(el => {
                                                        return (
                                                            <Grid container justifyContent={'space-between'}>
                                                                <Grid xl={11} lg={11}>
                                                                    <div style={{
                                                                        fontSize: "11px",
                                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                                        fontWeight: pallet.general.weight_bold,
                                                                        marginTop: '8px',
                                                                        paddingLeft: '10px'
                                                                    }}>
                                                                        {el.observacao}
                                                                    </div>
                                                                    <div style={{
                                                                        fontSize: '10px',
                                                                        paddingLeft: '10px'
                                                                    }}>
                                                                        Autor: {el.nome_user}
                                                                    </div>
                                                                </Grid>
                                                                <Grid style={{ cursor: 'pointer' }} xl={1} lg={1} onClick={() => deleteSubComment(el.id)}>
                                                                    <ClearIcon style={{
                                                                        fontSize: '12px',
                                                                        marginRight: '5px'
                                                                    }} />
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>

                                                <Grid lg={12} xs={12} style={{
                                                    marginTop: '12px',
                                                    paddingRight: '9px'
                                                }}>
                                                    <input
                                                        style={{
                                                            fontFamily: `${pallet.general.fontFamily}`,
                                                            width: '100%',
                                                            fontSize: '12px',
                                                            padding: '3px'
                                                        }}
                                                        type="text"
                                                        name={String(index)}
                                                        value={index === indexSubComments ? subcomentario : null}
                                                        onChange={(e) => handleSubComentarioChange(e, index)}
                                                    />
                                                </Grid>
                                                <Grid style={{ padding: "5px" }}>
                                                    <Button
                                                        onClick={() => saveSubComment(x.id)}
                                                        style={{
                                                            width: '100%',
                                                            fontSize: '10px',
                                                            marginLeft: '-4px',
                                                            backgroundColor: 'grey'
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Incluir Observação
                                                    </Button>
                                                </Grid>
                                                <Grid style={{ padding: "5px" }}>
                                                    <Button
                                                        onClick={() => excluirComment(x.id)}
                                                        style={{
                                                            width: '100%',
                                                            fontSize: '10px',
                                                            marginLeft: '-4px',
                                                            backgroundColor: 'grey'
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Excluir Comentário
                                                    </Button>
                                                </Grid>

                                            </Grid>
                                        )
                                    })}
                            </Grid>

                        </Grid>
                    }

                </Grid>
                <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth>
                    <DialogTitle>Comentar</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <b>Trecho:</b> "{quote}"
                        </DialogContentText>
                        <Grid container style={{ alignItems: "center" }}>
                            <Grid item xs={1}>
                                <Avatar
                                    className="userAvatar"
                                    title={user.nome}
                                    src={
                                        user.url_foto
                                            ?
                                            user.url_foto
                                            :
                                            ""
                                    }
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    autoFocus
                                    id="comment"
                                    placeholder='Digite algo...'
                                    type="text"
                                    value={commentary}
                                    onChange={event => setCommentary(event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
                        <Button onClick={() => saveComment()}>Comentar</Button>
                    </DialogActions>
                </Dialog>
            </Editor >
        </div>

    )
}

export default RealTimeQuillEditor