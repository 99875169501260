import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import TabPanel from "../../../components/carousel/TabPanel";
import { usePallet } from "../../../contexts/PalletContext";
import { IParlamentar } from "../../../models/Parlamentar.Model";
import ParlamentarAcoesInfluenciaDados from "./ParlamentarAcoesInfluenciaDados";
import ParlamentarAcoesInfluenciaInfluencia from "./ParlamentarAcoesInfluenciaInfluencia";

const ParlamentarCarouselTabsAcoesInfluencia = (props: { id_parlamentar: number, activeTab: any, index: number, parlamentar?: IParlamentar }) => {
    const { pallet } = usePallet();

    const [changeDadosOuInfluencia, setChangeDadosOuInfluencia] = React.useState("dadosinfluencia")

    const trocaParaDadosOuInfluencia = (key: string) => {
        setChangeDadosOuInfluencia(key)
    }

    return (

        <Paper style={{ borderRadius: "6px", marginTop: `${isMobile ? "0px" : "-30px"}` }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <Grid container style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                    <Grid alignItems="flex-start" style={{ marginRight: "20px" }}>
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuInfluencia}
                            tipo={"dadosinfluencia"}
                            backgroundColor={changeDadosOuInfluencia === "dadosinfluencia" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuInfluencia === "influencia" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuInfluencia === "influencia" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"DADOS"}
                        />
                    </Grid>
                    <Grid alignItems="flex-start">
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuInfluencia}
                            tipo={"influencia"}
                            backgroundColor={changeDadosOuInfluencia === "influencia" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuInfluencia === "dadosinfluencia" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuInfluencia === "dadosinfluencia" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"AÇÕES DE INFLUÊNCIA"}
                        />
                    </Grid>
                </Grid>
                {
                    changeDadosOuInfluencia === "dadosinfluencia" ?
                        <ParlamentarAcoesInfluenciaDados id_parlamentar={props.id_parlamentar} parlamentar ={props.parlamentar} /> :
                        <ParlamentarAcoesInfluenciaInfluencia id_parlamentar={props.id_parlamentar} />
                }
            </TabPanel>
        </Paper>

    )
}
{/* <TabPanel value={props.activeTab} index={props.index}>
    <div style={{ fontFamily: `${pallet.fontFamily.general}` }}>
        Ações de Influência
    </div>
</TabPanel> */}

export default ParlamentarCarouselTabsAcoesInfluencia;