import { Button,Chip } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { usePallet } from '../contexts/PalletContext';



const ButtonChip = (props: {

    label: string,
    active: string,
    style?: React.CSSProperties,
    onClick: (event: any) => void,
}) => {
    const { pallet } = usePallet();


    return (
        <Button
            onClick={(e) => { props.onClick(e) }}
            // key={props.item.key}
            title={props.label}
            style={{
                height: "60px",
                minWidth: "100px",
                

            }}
        >
                    <Chip style={{
                                textTransform:"none",
                                fontSize: "12px",
                                height: "50px",
                                width: "100%",
                                fontWeight: 600,
                                padding: "10px",
                                fontFamily: "Montserrat",
                                // fontStyle: "normal",        
                                borderRadius: "39px",
                                justifyContent: "center",
                                alignItems: "center",
                                color: `${props.active === 'sim' ? "white" : pallet.backgroundColorPrimary}`,
                                marginLeft: "2px",
                                marginRight: "5px",
                                marginTop: "2px",
                                marginBottom: "5px",
                                border: `1px solid ${props.active === 'sim'? pallet.backgroundColorQuintenary : "#D8D8D8"}`,
                                backgroundColor: `${props.active === 'sim' ? pallet.backgroundColorQuintenary : "#FFFFFF"}`
                    }}
                        key={1}
                        label={props.label}
                    />

           

        </Button>
    )
}



export default ButtonChip;