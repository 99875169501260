import * as React from 'react';
import TextField from '@mui/material/TextField';
import useStyles from './layout/Styles';
import { usePallet } from '../contexts/PalletContext';
import { InputAdornment } from '@mui/material';
import { fontFamily } from '@mui/system';



export default function InputTextField(
    props: {
        value: any,
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
        onBlur?: (e: React.FocusEvent<HTMLElement>) => void,
        id: string,
        label: string,
        type?: string,
        name?: string,
        placeholder?: string,
        focused?: boolean,
        startAdornment?: React.ReactNode,
        maxLenght?:number
        disabled?:boolean
    }) {

    const classes = useStyles();
    const { pallet } = usePallet();

    return (
        <TextField
            name={props.name ? props.name : ""}
            type={props.type ? props.type : ""}
            placeholder={props.placeholder ? props.placeholder : ""}
            value={props.value}
            // focused={props.focused ? props.focused : false}
            onChange={props.onChange}
            onBlur={props.onBlur}
            style={{ width: "100%", fontFamily: `${pallet.general.fontFamily}` }}
            id={props.id}
            disabled={props.disabled ? true : false}
            label={props.label}
            variant="outlined"
            inputProps={{ maxLength: props.maxLenght ? props.maxLenght : false, style:{fontSize: 14.5, fontFamily: `${pallet.general.fontFamily}`} }}
            InputProps={{
                classes: {
                    notchedOutline: classes.notchedOutline,
                },
                startAdornment: (
                    <InputAdornment position="start">
                        {props.startAdornment ? props.startAdornment : ""}
                    </InputAdornment>
                )
            }}
            InputLabelProps={{
                style: { color: "grey" }
            }}
        />
    );
}