import React, { useState, useEffect } from 'react';
import useStyles from '../../../components/layout/Styles';
import { Grid, Divider, Typography, Paper, Box } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import DateRangeSelector from '../../../components/DateRangeSelector';
// import styled from "styled-components";
import EmptyError from '../../../components/EmptyError';
import CalendarOutlineIcon from '@mui/icons-material/CalendarTodayOutlined'; import { positions } from '@mui/system';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { subYears } from 'date-fns';
import CircularLoading from '../../../components/CircularLoading';
;

const PieChartParlamentarDiscurso = (props: {
    masterTitle: string,
    titleChart?: string,
    labels: Array<{ key: string, data: number, color: string }>,
    dtIni: Date,
    dtFin: Date,
    onFilterChange: (dtIni: Date, dtFin: Date) => void,
    loading?: boolean,
}) => {


    const classes = useStyles();
    const { pallet } = usePallet();

    const height = 250;
    const width = 400;
    // const dtIni = subYears(new Date(), 1);
    // const dtFin = new Date();
    const onChangeDate = (dtIni: Date, dtFin: Date) => {
        props.onFilterChange(dtIni, dtFin)
    }

    // React.useEffect(() => {

    // }, [])

    const data = {
        labels: props.labels.map((i) => { return (i.key) }),
        datasets: [{
            label: props.titleChart,
            data: props.labels.map((i) => { return (i.data) }),
            backgroundColor: props.labels.map((i) => { return (i.color) }),
            hoverBackgroundColor: props.labels.map((i) => { return (i.color) }),
            borderWidth: 0
        }],

    };
    const options = {
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: true,
                color: "#FFFFFF",
                // backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 2,
                font: {
                    size: 15,
                    weight: 600
                },
                formatter: (value, context) => {
                    if (value === 0) {
                        return ""
                    }
                    return " " + Math.round((value / (context.dataset.data.reduce((a, b) => a + b, 0) / 100))) + '% '; //Retornar porcentagem
                }
            }
        },
        maintainAspectRatio: false,
        segmentShowStroke: false
    };

    return (
        <Paper>
            <div className={classes.paper} style={{ backgroundColor: `${pallet.backgroundColorTertiary}` }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Typography
                        style={{
                            color: `${pallet.general.color1}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            paddingRight: "20px"
                        }}>
                        {props.masterTitle}
                    </Typography>
                </Grid>
            </div>
            <Divider />
            <div className={classes.paper}>
                <Grid container>
                    <Grid xs={12} sm={12} md={6} lg={6} item alignItems="left" justifyContent="space-between">
                        <Typography
                            style={{
                                color: `${pallet.general.color4}`,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: 600,
                                paddingRight: "20px"
                            }}>
                            {props?.titleChart}
                        </Typography>
                    </Grid>
                    <Grid xs={3} item
                        style={{
                            paddingTop: '10px',
                        }}
                    >
                        <Grid container alignItems="center" direction="row"

                            style={{
                                marginLeft: `${isMobile ? "40%" : "90%"}`,
                                height: "53px",
                                minWidth: `${isMobile ? "255px" : "265px"}`,
                                maxWidth: `${isMobile ? "100%" : "280px"}`,
                                background: "rgba(75, 17, 150, 0.2)",
                                borderRadius: '5px',
                                justifyContent: `${isMobile ? "flex-start" : "center"}`
                            }}
                        >
                            <Grid >
                                <CalendarOutlineIcon style={{
                                    color: `${pallet.color.secundary.font}`,
                                    marginTop: '6px',
                                    opacity: "0% !important",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    marginLeft: `${isMobile ? "10px" : "5px"}`
                                }} />
                            </Grid>

                            <Grid
                                style={{
                                    color: `${pallet.color.secundary.font}`,
                                    fontWeight: 400,
                                }} >
                                <DateRangeSelector color={pallet.color.secundary.font}
                                    // defaultValue={dtIni}
                                    startDate={props.dtIni}
                                    endDate={props.dtFin}
                                    onChange={onChangeDate}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            {props.labels[0].data === 0 && props.labels[1].data === 0 && props.labels[2].data === 0 ?

                props.loading
                    ?

                    <CircularLoading
                        size={80}
                        top={"90%"}
                        left={"50%"}
                        zIndex={2}
                    />

                    :

                    <Grid
                        style={{
                            color: `${pallet.textColorQuaternary}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "150px",
                            marginTop: "20px",
                        }}
                    >
                        <EmptyError title="Ops!" description="Ainda não temos dados suficientes para gerar resultados." />
                    </Grid>

                :

                <Grid>
                    <Box display="flex" justifyContent="center" style={{ paddingTop: "30px", paddingBottom: "30px" }} >
                        <Doughnut
                            width={width}
                            height={height}
                            data={data}
                            options={options}
                            plugins={[ChartDataLabels]}
                        />
                    </Box>

                    <Divider />
                    <Box justifyContent="flex-start" style={{ paddingTop: "5px", paddingRight: "20", paddingBottom: "18px", paddingLeft: "25px" }} >
                        {props.labels.map(i => (
                            <Grid container key={i.key} style={{ marginTop: `${isMobile ? "7px" : "13px"}` }}>
                                <div style={{
                                    backgroundColor: `${i.color}`,
                                    width: "25px", height: "5px",
                                    borderRadius: "3px",
                                    marginTop: "8px",
                                    marginRight: "12px"
                                }} />
                                <div style={{
                                    marginRight: "12px",
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.subtitle
                                }}>{i.key}</div>
                                <div style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontWeight: pallet.fontWeight.lighter,
                                    color: `${pallet.color.quaternary.font}`
                                }}>{i.data}</div>
                            </Grid>
                        ))}
                    </Box>
                </Grid>}

        </Paper>

    )

}

export default PieChartParlamentarDiscurso;
