import * as React from 'react';
import Button from '@mui/material/Button';
// import styled from "styled-components";
import { usePallet } from '../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { useAlert } from '../contexts/AlertContext';



export default function SolidButton(props: {
    onClick(event): void,
    color: string,
    backgroundColor: string,
    fontSize: string,
    title: string,
    onChange?: () => void,
    disabled?: boolean, 
}) {
    const { pallet } = usePallet();
    const { NewAlert } = useAlert();

    const camposNaoPreenchidos = () => {
        NewAlert('error', 'Existem campos vazios, preencha e salve novamente');
    }

    return (
        <>
            <Button disabled={props.disabled ? true : false } onClick={props.onClick} onChange={props.onChange} variant="contained"
                style={{
                    color: `${props.color}`,
                    backgroundColor: `${props.disabled ? "#D8D8D8" : props.backgroundColor}`,
                    fontSize: `${props.fontSize}`,
                    fontWeight: pallet.general.weight_600,
                    fontFamily: `${pallet.general.fontFamily}`,
                    padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,
                }}
            >
                {props.title}
            </Button>
        </>
    );
}