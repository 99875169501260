import { Box, Button, Divider, Grid, Icon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material";
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import MenuButtomIconLeft from '../../../components/MenuButtonAccordion'
import { usePallet } from '../../../contexts/PalletContext';
import { styled, alpha } from "@mui/material/styles";
import React from "react";
import Paper from '@mui/material/Paper';
import { MoreHoriz } from "@material-ui/icons";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import DeleteIcon from "@mui/icons-material/Delete";
import AcontecimentosProposicaoEdicao from "../../proposicao/_components/acontecimentos/AcontecimentosProposicaoEdicao";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { isMobile } from "react-device-detect";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../../contexts/AlertContext';
import AgendaService from "../../../services/AgendaService";
import { format } from "date-fns";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import moment from 'moment-timezone';





const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    ".MuiListItemText-primary": {
        fontSize: 0
    },
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "0px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));




export default function CardApi(props: { hora: string, eventos: any, idAnchorEl: number, loadEventos: () => void }) {
    const { pallet } = usePallet();
    const { NewAlert } = useAlert();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const [color, setColor] = React.useState();
    const navigate = useNavigate();




    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };


    const deletarEvento = (id) => {
        AgendaService.Delete(id)
            .then((res) => {
                if (res) {
                    handleClose();
                    NewAlert("success", "Evento apagado com sucesso");
                    props?.loadEventos()
                } else {
                    NewAlert(
                        "error",
                        "Erro ao apagar, tente novamente"
                    );
                }
            })
        // .then((x) => loadAcontecimentos(props?.id_proposicao));
    };

    const checkDateToColor = (date) => {
        let dateComing = date.split(" ")[0]; // Ex: 2023/05/17
        let dateToday = moment(new Date()).format("yyyy/MM/DD");
        let timeComming = date.split(" ")[1]; // Ex: 18:00:00
        let timeNow = moment(new Date()).format("HH:mm:ss");

        if (dateComing < dateToday) {
            return ('passado')
        };
        if (dateComing <= dateToday && timeComming < timeNow) {
            return ('passado')
        };
        if (dateComing >= dateToday && timeComming >= timeNow) {
            return ('atual')
        };
    }

    const changeColorByTime = (date, checkApi) => {
        let dateComing = date.split(" ")[0]; // Ex: 2023/05/17
        let dateToday = moment(new Date()).format("yyyy/MM/DD");
        let timeComming = date.split(" ")[1]; // Ex: 18:00:00
        let timeNow = moment(new Date()).format("HH:mm:ss");

        if (dateComing <= dateToday && timeComming < timeNow) {
            return '#9d9d9d' // passado
        } else if (dateComing >= dateToday && timeComming >= timeNow && !checkApi) {
            return '#86A239' // atual + cadastrado true
        } else if (dateComing > dateToday && !checkApi) {
            return '#86A239' // atual + cadastrado true
        } else if (dateComing === dateToday && timeComming > timeNow && checkApi) {
            return pallet.textColorTertiary // atual + api true
        } else if (dateComing > dateToday && checkApi) {
            return pallet.textColorTertiary // atual + api true
        } else {
            return '#9d9d9d' // passado
        };
    }

    const textChangeColorByTime = (date, checkApi) => {
        let dateComing = date.split(" ")[0]; // Ex: 2023/05/17
        let dateToday = moment(new Date()).format("yyyy/MM/DD");
        let timeComming = date.split(" ")[1]; // Ex: 18:00:00
        let timeNow = moment(new Date()).format("HH:mm:ss");

        if (dateComing < dateToday) {
            return '#9d9d9d' // passado
            // return 'blue' // passado
        } else if (dateComing === dateToday && timeComming < timeNow) {
            return '#9d9d9d' // passado
            // return 'black' // passado
        } else if (dateComing > dateToday) {
            return 'black' // atual
        } else if (dateComing === dateToday && timeComming > timeNow) {
            return 'black' // atual
        };
    }

    return (
        <Grid style={{
            borderTop: "1px solid #EAEAEA",
            borderRight: "1px solid #EAEAEA",
            borderBottom: "1px solid #EAEAEA",
            // borderLeft: `5px solid ${props?.eventos?.agenda_data_inicio < moment(new Date()).format("yyyy-MM-ddTHH:mm:ss.ssss") ? '#cccccc' : props?.eventos?.agenda_integrada ? pallet.backgroundColorPrimary : '#86A239'}`,
            borderLeft: `5px solid ${changeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
            marginTop: "10px",
            marginBottom: "20px",
            borderRadius: "2px"

        }}>
            <Grid container justifyContent={'space-evenly'} >
                {/* ------------------ Abaixo: Icon e title do card:::::::::::::: */}
                <Grid item xs={9} lg={11} style={{ backgroundColor: '#F9F9F9' }}>
                    <Grid container>
                        {isMobile ?
                            null
                            :
                            <Grid
                                alignItems="center"
                                justifyContent="center"
                                style={{
                                    backgroundColor: `${'#e2e2e2'}`,
                                    paddingLeft: "13px",
                                    paddingRight: "13px",
                                    paddingTop: "13px",
                                    paddingBottom: "5px",
                                    // color: `${moment(props?.eventos?.agenda_data_inicio).format("yyyy-MM-ddTHH:mm:ss.ssss") < moment(new Date()).format("yyyy-MM-ddTHH:mm:ss.ssss") ? '#9d9d9d' : props?.eventos?.agenda_integrada  ? pallet.backgroundColorQuintenary : '#86A239'}`,
                                    color: `${changeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
                                }}
                            >
                                {!props?.eventos?.agenda_integrada && props?.eventos?.acontecimentosCount === '0' ? <InsertInvitationIcon /> : props?.eventos?.agenda_integrada ? <AccountBalanceIcon /> : <TextSnippetIcon />}
                            </Grid>
                        }
                        <Grid
                            alignItems="center"
                            justifyContent="center"
                            style={{
                                paddingLeft: "13px",
                                paddingTop: "13px",
                                paddingBottom: "5px",
                                fontWeight: pallet.general.weight_600,
                                color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
                            }}
                        >
                            {!props?.eventos?.agenda_integrada && props?.eventos?.acontecimentosCount === '0' ? props?.eventos?.casas_nome + ' - Evento Cadastrado' : !props?.eventos?.agenda_integrada && props?.eventos?.acontecimentosCount !== '0' ? props?.eventos?.casas_nome + ' - Evento de Acontecimento' : props?.eventos?.casas_nome}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} lg={1} style={{ backgroundColor: '#F9F9F9' }}>
                    <Grid >
                        {props?.eventos?.agenda_integrada || props?.eventos?.acontecimentosCount === '1'
                            ?
                            null
                            :
                            <Grid
                                style={{
                                    marginTop: `${isMobile ? "0px" : "4px"}`,
                                    paddingRight: "10px",
                                }}
                            >
                                <Button
                                    id={`button-${props?.idAnchorEl}`}
                                    aria-controls={
                                        anchorEl ? `mmenu-${props?.idAnchorEl}` : undefined
                                    }
                                    disableElevation
                                    variant="contained"
                                    size="small"
                                    style={{
                                        backgroundColor: '#F3F3F3',
                                        textTransform: "capitalize",
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.fontWeight.button,
                                        fontSize: `${pallet.fontSize.text_10}`,
                                        color: `${pallet.color.secundary.font}`,
                                        width: "25px",
                                        height: "40px",
                                    }}
                                    onClick={(e) => handleClick(e, props?.idAnchorEl)}
                                    endIcon={
                                        <Grid
                                            style={{
                                                fontSize: "30px",
                                                color: `${pallet.general.color4}`,
                                                marginTop: "9px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <MoreHoriz fontSize="inherit" />
                                        </Grid>
                                    }
                                ></Button>
                                <StyledMenu

                                    id={`mmenu-${props?.idAnchorEl}`}
                                    MenuListProps={{
                                        "aria-labelledby": `button-${props?.idAnchorEl}`,
                                    }}
                                    anchorEl={anchorEl}
                                    open={open === props?.idAnchorEl}
                                    onClose={handleClose}
                                >
                                    <MenuItem>
                                        <Button
                                            onClick={() => navigate(`${props?.eventos?.agenda_id}/cadastro`)}
                                            style={{
                                                marginLeft: "-7px",
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.button,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`,
                                                borderRadius: "6px",
                                                textTransform: "capitalize",
                                                paddingRight: "85px",
                                                marginRight: "-10px"
                                            }}>
                                            <EditIcon style={{
                                                color: `${pallet.color.tertiary.font}`,
                                                marginRight: "16px",
                                                fontFamily: `${pallet.fontFamily.general}`,
                                            }} />
                                            <ListItemText>@</ListItemText>
                                            Editar
                                        </Button>
                                    </MenuItem>
                                    <Divider
                                        style={{
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                        }}
                                    />
                                    <MenuItem
                                        style={{
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.fontWeight.button,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            borderRadius: "6px",
                                            padding: "6px",
                                        }}
                                    >
                                        <ButtonDialogConfirmation
                                            styles={{
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.button,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`,
                                                borderRadius: "6px",
                                                textTransform: "capitalize",
                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            // lastPath={"/parlamentar"}
                                            confirmationEvent={() => deletarEvento(props?.eventos?.agenda_id)}
                                            title_button={"Apagar"}
                                            confirmation_button={"Apagar"}
                                            content={"APAGAR EVENTO?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        >
                                            <DeleteIcon
                                                style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    marginRight: "16px",
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                }}

                                            />
                                            <ListItemText>!</ListItemText>
                                        </ButtonDialogConfirmation>
                                    </MenuItem>
                                </StyledMenu>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{ padding: "15px", fontSize: `${isMobile ? "15px" : "18px"}` }}>
                {props?.eventos?.comissoes_sigla || props?.eventos?.comissoes_descricao
                    ?
                    <Grid style={{
                        fontWeight: pallet.general.weight_600,
                        color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
                    }}>
                        {props?.eventos?.comissoes_sigla} - {props?.eventos?.comissoes_descricao}
                    </Grid>
                    :
                    null
                }
                {props?.eventos?.proposicoesCount > 0 ?
                    <Grid container style={{ marginTop: "7px" }}>
                        <Grid style={{ color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`, }}>
                            <ArrowRightAltIcon />
                        </Grid>
                        <Grid style={{
                            paddingTop: '2px',
                            fontSize: `${isMobile ? "13px" : "14px"}`,
                            color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
                        }}>
                            {props?.eventos?.proposicoesCount} {Number(props?.eventos?.proposicoesCount) > 1 ? "Proposições Seguidas" : "Proposição Seguida"}
                        </Grid>
                    </Grid>
                    :
                    null
                }
                {props?.eventos?.interlocutoresCount > 0 ?
                    <Grid container>
                        <Grid style={{ color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`, }}>
                            <ArrowRightAltIcon />
                        </Grid>
                        <Grid style={{
                            fontSize: `${isMobile ? "13px" : "14px"}`,
                            paddingTop: '2px',
                            color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
                        }}>
                            {props?.eventos?.interlocutoresCount} {Number(props?.eventos?.interlocutoresCount) > 1 ? "Interlocutores" : "Interlocutor"}
                        </Grid>
                    </Grid>
                    :
                    null
                }
                {props?.eventos?.acontecimentosCount > 0 ?
                    <Grid container>
                        <Grid style={{ color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`, }}>
                            <ArrowRightAltIcon />
                        </Grid>
                        <Grid style={{
                            fontSize: `${isMobile ? "13px" : "14px"}`,
                            paddingTop: '2px',
                            maxWidth: `${isMobile ? "200px" : 'none'}`,
                            color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
                        }}>
                            {props?.eventos?.acontecimentosCount} {Number(props?.eventos?.acontecimentosCount) > 1 ? "Acontecimentos de Proposição" : "Acontecimento de Proposição"}
                        </Grid>
                    </Grid>
                    :
                    null
                }
                <Grid container style={{ marginTop: `${isMobile ? "10px" : '0px'}` }}>
                    <Grid item xs={1} lg={1}>
                        <DoubleArrowIcon style={{ color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`, }} />
                    </Grid>
                    <Grid item xs={11} lg={11} style={{
                        marginLeft: `${isMobile ? "0px" : "-60px"}`,
                        paddingLeft: `${isMobile ? "5px" : "0px"}`,
                        fontSize: `${pallet.general.size14}`,
                        paddingTop: '2px',
                        wordWrap: "break-word",
                        color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
                    }}>
                        <strong>Local:</strong> {props?.eventos?.agenda_local_nome}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} lg={1}>
                        <DoubleArrowIcon style={{ color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`, }} />
                    </Grid>
                    <Grid item xs={11} lg={11} style={{
                        marginLeft: `${isMobile ? "0px" : "-60px"}`,
                        paddingLeft: `${isMobile ? "5px" : "0px"}`,
                        fontSize: `${pallet.general.size14}`,
                        paddingTop: '2px',
                        wordWrap: "break-word",
                        color: `${textChangeColorByTime(props?.eventos?.agenda_data_inicio, props?.eventos?.agenda_integrada)}`,
                    }}>
                        <strong>Descrição:</strong> {props?.eventos?.agenda_descricao}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

} 