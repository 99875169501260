import { isMobile } from "react-device-detect";

import Paper from "@mui/material/Paper";

import TabPanel from "../../../../components/carousel/TabPanel";

import ProposicaoEmendas from "../emendas/ProposicaoEmendas";


const ProposicaoCarouselTabsEmendas = (props: { id_proposicao: number, proposicao_integrada?: boolean, activeTab: any, index: number, onChange?: any ,propCasa?: number}) => {
    return (
        <TabPanel value={props.activeTab} index={props.index}>
            <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "30px"}` }}>
                <ProposicaoEmendas id_proposicao={props.id_proposicao} index={props.index} activeTab={props.activeTab} proposicao_integrada={props.proposicao_integrada} onChange={props?.onChange} propCasa={props.propCasa}/>
            </Paper>
        </TabPanel>
    )
};

export default ProposicaoCarouselTabsEmendas;
