import * as React from 'react';

import { Box, Grid, Link, MenuItem, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { usePallet } from '../../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import ParlamentarService from '../../../../services/ParlamentarService';
import { Divider } from '@mui/material';
import { useAlert } from "../../../../contexts/AlertContext";
import useStyles from '../../../../components/layout/Styles';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/BootstrapDialogTitle';
import ParlamentarDiscursoService from '../../../../services/ParlamentarDiscursoService';
import { formatDate } from '../../../../utils/dateUtils';
import blankAvatar from '../../../../img/avatar_foto.jpg'
import { parlamentarQualificacoes } from '../../../../models/ParlamentarQualificacoes';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import ProposicaoService from '../../../../services/ProposicaoService';
import { isElementAccessChain } from 'typescript';
import { elementAcceptingRef } from '@mui/utils';

export default function ProposicaoEmendasModal(props: { id: number, posicionamento: string, onChange?: (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void }) {
    const { pallet } = usePallet();
    const [open, setOpen] = React.useState(false);
    const [discurso, setDiscurso] = React.useState<{ data: Date, transcricao: string, nome: string, url_foto: string, posicionamento: string }>();
    const [emenda, setEmenda] = React.useState<{ proposicao: string, texto: string }>();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getItem();
    }, [open])



    const getItem = () => {
        //console.log(" vai abrir com id: ",props.id);
        open &&
            props.id &&
            ProposicaoService
                .GetEmendaTexto(props.id)
                .then(x => setEmenda(x))
            
    }

    const CustomLabelQualificacao: React.FunctionComponent = () => {
        return (
            <div style={{
                color: `${pallet.color.colorBase.labelTextFild}`,
                marginRight: "8px",
                fontFamily: `${pallet.fontFamily.general}`,
            }}>
                QUALIFICAÇÃO
            </div>
        )
    }

    const CustomLabelPosicionamento: React.FunctionComponent = () => {
        return (
            <div style={{
                color: `${pallet.color.colorBase.labelTextFild}`,
                marginRight: "8px",
                fontFamily: `${pallet.fontFamily.general}`,
            }}>
                PROPOSIÇÃO
            </div>
        )
    }

    return (
        <div>
            <Link href="#" onClick={handleClickOpen} underline="none"
                style={{
                    fontWeight: pallet.fontWeight.subtitle,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.secundary.font}`
                }}>
                {'Ver ementa na íntegra'}
            </Link>

            <BootstrapDialog style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
                maxWidth={"md"}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    EMENDA {emenda?.proposicao}
                </BootstrapDialogTitle>

               
                <Divider style={{ marginBottom: "15px" }} />

                <Grid style={{
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size16}`,
                    fontWeight: pallet.general.weight_300,
                    alignItems: "center",
                    lineHeight: "1.6",
                    color: "#1E0736",
                    height: "250px",
                    textAlign: "justify",
                    width: "100%",
                    overflow: "scroll",
                    overflowX: "hidden",
                    padding: `${isMobile ? "10px 15px 10px 15px" : "20px 20px 20px 30px"}`
                }}>
                    {emenda?.texto}
                </Grid>

            </BootstrapDialog>
        </div >
    );
}
