export const parlamentarQualificacoes = [
    {
        value: 'convergente',
        label: 'Convergente',
    },
    {
        value: 'divergente',
        label: 'Divergente',
    },
    {
        value: 'neutro',
        label: 'Neutro',
    },
    {
        value: 'não classificado',
        label: 'Não Classificado',
    },
];