import * as http from '../helpers/http';
import { IProposicao } from '../models/Proposicao.Model';
import { capitalizeName } from '../utils/string.utils';
import { format } from 'date-fns'

class TwitterService {

  GetTwitterBuscaGeral = async (word: String, data: any): Promise<any> => {
    const result = await http.post(`/twitter/busca-geral-twitter`, {texto: word ,data});
    return result
  };

  GetTwitterFavoritados = async (word: any, data: any): Promise<any> => {
    const result = await http.post(`/twitter/favoritados/${word}`, data);
    return result
  };

  TwitterUserValidation = async (data: any): Promise<any> => {
    const result = await http.post(`twitter/uservalidation`, data);
    return result
  };

  TwitterFollowingFlow = async (data: any): Promise<any> => {
    const result = await http.post(`twitter/following`, data);
    return result
  };
}

export default new TwitterService();
