import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../../components/carousel/TabPanel";
import { usePallet } from "../../../../contexts/PalletContext";
import TimeLineCarouselSteps from "../timeline/TimeLineCarouselSteps";


const ProposicaoCarouselTabsTimeLine = (props: { id_proposicao: number, activeTab: any, index: number }) => {
    const { pallet } = usePallet();


    return (
        <>
            <TabPanel value={props.activeTab} index={props.index}>
                <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "0px"}` }}>
                    <TimeLineCarouselSteps id_proposicao={props.id_proposicao} index={props.index} activeTab={props.activeTab} />
                </Paper>
            </TabPanel>
        </>
    )
}


export default ProposicaoCarouselTabsTimeLine;