import React from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import { Grid, FormControl, Select, SelectChangeEvent, Checkbox } from '@mui/material';
import Radio from '@mui/material/Radio';
import MenuItem from '@mui/material/MenuItem';
import { usePallet } from '../../../contexts/PalletContext';
import styled from 'styled-components';
import { IFilterData } from '../../../components/filter/ListFilter';
import { InputLabel } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const SelectedFilter = styled(Select)`
    #select-filter {
        background: #FFFF;
        min-width: 200px;
        height: 9px;
        padding: 7px;
        display: flex;
        align-items: center;

        input, svg {
            display: none;
        }
    }
`;

const Items = styled(MenuItem)`
    padding: 1px;
    margin: unset;
    border-top: 1px solid #DFDFDF;

    &:first-child {
        border-top: unset;
    }

    span.inputLabel {
        font-size: ${(props => props.pallet.general.size14)};
        font-family: ${(props => props.pallet.general.fontFamily)};
        color: ${(props => props.pallet.color.tertiary.font)};
    }
`;

export default function MenuFiltroNotificacoes(props: { itens: IFilterData[], selectedItem?: string, onChange?: (value) => void }) {
    const { pallet } = usePallet();

    const handleChange = (event: SelectChangeEvent) => {
        if (props.onChange) props.onChange(event.target.value);
    };

    return (
        <Grid>
            <FormControl>
                <SelectedFilter
                    id="select-filter"
                    value={props.selectedItem || ""}
                    onChange={handleChange}
                    displayEmpty
                >
                    {props.itens.map((item, index) => {
                        return (
                            <Items
                                key={index}
                                value={item.value}
                                pallet={pallet}
                            >
                                <InputLabel>
                                    <Checkbox
                                        value={item.value}
                                        checked={props.selectedItem === item.value}
                                        checkedIcon={<CheckCircleIcon />}
                                        icon={<RadioButtonUncheckedIcon />}
                                        sx={{
                                            color: `${pallet.color.quaternary.font}`,
                                            '&.Mui-checked': {
                                                color: `${pallet.backgroundColorPrimary}`
                                            },
                                        }}
                                    />
                                    <span className='inputLabel'>{item.label}</span>
                                </InputLabel>

                            </Items >
                        )
                    })}

                </SelectedFilter>
            </FormControl>

        </Grid >

    );
}