import React from 'react';
import { Button } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import ParlamentarService from '../../../services/ParlamentarService';
import { useAlert } from '../../../contexts/AlertContext';

interface IProps {
    id: number,
    seguindo: boolean,
    onClick: () => void,
    marginRight?: any,
    width?: any,
}

function ButtonParlamentarSeguir(props: IProps) {
    const { pallet } = usePallet();
    const { NewAlert } = useAlert()

    const handleClick = (id: number, value: boolean) => {
        ParlamentarService.SetSeguir(id, value).then(x => {
            if (props.onClick) props.onClick();
            NewAlert('success', 'Registro Salvo com sucesso');
        })
    }

    if (!props.id) return (<></>)
    return (
        <div>
            <Button
                onClick={() => handleClick(props.id, !props.seguindo)}
                variant="contained"
                // size="large"
                disableElevation
                style={{
                    height: "40px",
                    marginTop: "10px",
                    marginRight: props.marginRight,
                    backgroundColor: `${props.seguindo ? pallet.color.colorBase.white : pallet.color.primary.background}`,
                    border: `1px solid ${props.seguindo ? pallet.color.primary.background : "none"}`,
                    textTransform: 'capitalize',
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.general.weight_bold,
                    fontSize: `${pallet.general.size12}`,
                    color: `${props.seguindo ? pallet.color.secundary.font : pallet.color.colorBase.white}`,
                    width: props.width
                }}
            >
                {props.seguindo ? "SEGUINDO" : "SEGUIR"}
            </Button>

        </div>
    );
}

export default ButtonParlamentarSeguir;