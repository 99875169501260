import {
    Button, Divider, Grid
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import {
    BootstrapDialog,
    BootstrapDialogTitle
} from "../../../../components/BootstrapDialogTitle";
import useStyles from "../../../../components/layout/Styles";
import SolidButton from "../../../../components/SolidButton";
import { useAlert } from "../../../../contexts/AlertContext";
import { usePallet } from "../../../../contexts/PalletContext";
import { EComentarioModulos, IAcaoPreparatoria, IComentario } from "../../../../models/Proposicao.Model";
import { ILoggedUser } from "../../../../models/User.Model";
import ComentarioService from "../../../../services/ComentarioService";


export default function ComentarioModal(props: {
    onChange?: () => (
        id: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    closeMenu?: () => void;
    reloadLista?: () => void;
    children?: React.ReactNode;
    comentario: IComentario;
    acao: IAcaoPreparatoria;
    listaUsuario: ILoggedUser[];
    resposta?: boolean;
}) {
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();

    const [idReferencia, setIdReferencia] = React.useState<number>();
    const [comentario, setComentario] = React.useState<string>();
    const [userList, setUserList] = useState<Array<number>>([]);
    const [lockSaveButton, setLockSaveButton] = useState<boolean>(true);
    const [lockedUserList, setLockedUserList] = useState<Array<number>>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [newUserList, setNewUserList] = useState<Array<number>>([]);
    const navigate = useNavigate();
    const [idComentario, setIdComenatario] = useState(0);

    const [comentarioNovo, setComentarioNovo] = useState("");
    const handleClickOpenModal = () => {
        setOpenModal(true);
        console.log("Abrir", openModal);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        setComentarioNovo(trocarMencionados(props.comentario.comentario));
        setIdComenatario(Number(props.comentario.id));
    }, []);

    useEffect(() => {
        SaveValidation();
    }, [comentarioNovo]);

    function SaveValidation() {
        if (comentarioNovo === "") {
            setLockSaveButton(false);
        } else setLockSaveButton(true);
    }

    const salvarComentario = () => {        

        if (comentarioNovo) {
            const data = {
                comentario: comentarioNovo,
                // comentario: comentarioNovo.replace(
                //     regex.mencoes,
                //     (...match) => `@@@${match[1]}@@@`
                // ),
                modulo: EComentarioModulos.AcaoPreparatoria,
                //id_referencia: String(props.acao.id)
            };
            ComentarioService.Update(String(props.comentario.id), data).then(
                (res) => {
                    if (res) setIdComenatario(Number(res));
                    NewAlert("success", "Comentário salvo com sucesso.");
                    props.reloadLista();
                }
            );
        }
    };

    const regex = {
        ultimaMencao: /(^| |&nbsp;)@(.[^ ]*)?$/g,
        mencaoAdicionada: /<b data-id="([0-9]+)">(.[^ ]*)<\/b>&nbsp;$/g,
        // mencoes: /<b data-id="([0-9]+)">(.[^ ]*)<\/b>/g,
        mencoes: /<b data-id="([0-9]+)">(.[^&nbsp;]*)<\/b>/g,
        limpar: /<b>(.[^ ]*)?<\/b>$/g,
        limparTags: /(<+(?!b|\/b)([^>]+)>)/ig
    };
    const keys = { enter: 13, space: 32, up: 38, down: 40, backspace: 8 };

    const regexMencoes = /@@@([0-9]+)@@@/g;

    const trocarMencionados = (comentario) =>
        comentario.replace(regexMencoes, (...match) => {
            const id = parseInt(match[1]);

            const usuario = props.listaUsuario?.filter((item) => item.id === id)[0];

            const nome = usuario?.nome ? usuario?.nome : "Usuário não identificado";

            return `<b data-id="${id}">${nome}</b>`;
        });

    const [autocomplete, setAutocomplete] = React.useState(false);
    const [selecionado, setSelecionado] = React.useState(0);
    const indexSelecionado = useRef(selecionado);

    useEffect(() => {
        indexSelecionado.current = selecionado;
    }, [selecionado]);

    const escolherUsuario = (usuario) => {
        if (!usuario) return;

        setAutocomplete(false);
        setSelecionado(0);
        setComentarioNovo(
            comentarioNovo.replace(
                regex.ultimaMencao,
                `$1<b data-id="${usuario.id}">${usuario.nome}</b>&nbsp;`
            )
        );
        console.log("ALARME");
    };

    const listarUsuarios = (usuarios) => {
        let mencao = String(comentarioNovo.match(regex.ultimaMencao));
        mencao =
            mencao && mencao[0].replace(regex.ultimaMencao, "$2").toLowerCase();
        return mencao
            ? usuarios?.filter(({ nome }) => nome.toLowerCase().match(mencao))
            : usuarios;
    };

    const handleOnKeyDown = (event) => {
        const listaDeUsuarios = listarUsuarios(props.listaUsuario);

        // if (event.keyCode === keys.enter) {
        //   event.preventDefault()
        //   return event.shiftKey ? salvar() : escolherUsuario(listaDeUsuarios[selecionado])
        // }

        if (event.keyCode === keys.space && listaDeUsuarios.length === 1) {
            event.preventDefault();
            return escolherUsuario(listaDeUsuarios[indexSelecionado.current]);
        }

        if (
            event.keyCode === keys.backspace &&
            comentarioNovo.match(regex.mencaoAdicionada)
        ) {
            event.preventDefault();
            return setComentarioNovo(
                comentarioNovo.replace(regex.mencaoAdicionada, "")
            );
        }
    };

    const handleOnKeyUp = (e) => {
        const keyCode = e.keyCode;
        const listaDeUsuarios = listarUsuarios(props.listaUsuario);
        const ehUltimo = indexSelecionado.current === listaDeUsuarios.length - 1;

        if (keyCode === keys.up) {
            return setSelecionado(
                indexSelecionado.current && indexSelecionado.current - 1
            );
        }
        if (keyCode === keys.down) {
            return setSelecionado(
                ehUltimo ? indexSelecionado.current : indexSelecionado.current + 1
            );
        }

        setSelecionado(0);
        setAutocomplete(!!e.target.textContent.match(regex.ultimaMencao));
    };

    const handleOnChange = (event) => {
        let newValue = event.target.value.replace(/@@/g, "@").replace(regex.limparTags, "");
        if (newValue.match(regex.limpar))
            newValue = newValue.replace(regex.limpar, "$1");
        setComentarioNovo(newValue);
    };

    const handleOnBlur = () => {
        setTimeout(() => setAutocomplete(false), 500);
    };

    return (
        <Grid>
            <Button
                onClick={handleClickOpenModal}
                style={{
                    fontWeight: pallet.fontWeight.subtitle,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.secundary.font}`,
                }}
            >
                {props.children ? props.children : ""}
            </Button>

            <BootstrapDialog
                style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
                sx={{ ".MuiPaper-root": { width: "100%" } }}
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {props.resposta === true ? "EDITAR RESPOSTA" : "EDITAR COMENTÁRIO"}
                </BootstrapDialogTitle>
                <Grid
                    container
                    style={{
                        paddingTop: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Grid item style={{ width: "100%", position: "relative" }}>
                        {props.listaUsuario && (
                            <>
                                <span
                                    style={{
                                        position: "absolute",
                                        fontSize: "12px",
                                        left: "10px",
                                        top: "-9.5px",
                                        backgroundColor: "white",
                                        color: "grey",
                                        padding: "0px 5px",
                                    }}
                                >
                                    COMENTÁRIO
                                </span>
                                <div
                                    style={{
                                        border: "1px solid lightgrey",
                                        borderRadius: "3px",
                                    }}
                                    className="MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-multiline"
                                >
                                    <ContentEditable
                                        style={{
                                            width: "100%",
                                            backgroundColor: " white !important",
                                            margiLeft: "5px",
                                            padding: "10px",
                                            outline: "0px solid transparent",
                                            zIndex: 1,
                                        }}
                                        id={"comentario"}
                                        className="MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-multiline"
                                        html={comentarioNovo}
                                        onChange={handleOnChange}
                                        onKeyDown={handleOnKeyDown}
                                        onKeyUp={handleOnKeyUp}
                                        onBlur={handleOnBlur}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: `${autocomplete ? "block" : "none"}`,
                                        height: "100px",
                                        overflow: "auto",
                                        boxShadow: "3px 3px 3px 0px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: "white !important",
                                        borderRadius: "0px 0px 3px 3px",
                                    }}
                                >
                                    <ul
                                        className={`dropdown-menu`}
                                        style={{
                                            backgroundColor: "white !important",
                                            border: "none",
                                            marginLeft: "5px",
                                            padding: "0px",
                                        }}
                                    >
                                        {listarUsuarios(props.listaUsuario).map(
                                            ({ id, nome }, i) => (
                                                <li
                                                    className={`dropdown-item `} //
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: i === selecionado ? 700 : 300,
                                                    }}
                                                    onClick={() => escolherUsuario({ id, nome })}
                                                >
                                                    {nome}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </>
                        )}
                    </Grid>
                </Grid>
                {props.resposta === false ? (
                    <>
                        <Grid style={{ marginTop: "50px" }}>
                            <Divider variant="fullWidth" />
                        </Grid>

                        <Grid
                            style={{
                                marginBottom: `${isMobile ? "8px" : "0px"}`,
                                marginTop: `${isMobile ? "8px" : "20px"}`,
                                paddingRight: "20px",
                                paddingLeft: "20px",
                            }}
                        >
                            <Grid
                                style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`,
                                }}
                            ></Grid>
                            <Grid className="container">
                                <AnexosComponent
                                    label=""
                                    modulo={EAnexoModulos.Comentario}
                                    id_referencia={Number(props.comentario.id)}
                                    //onAfterUpload={handleAfterUpload}
                                    canEdit={true}
                                    autosave={true}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    ""
                )}
                <Grid>
                    <Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                </Grid>
                <Grid container style={{ justifyContent: "flex-end" }}>
                    <Grid
                        item
                        style={{
                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                            paddingRight: `${isMobile ? "20px" : "40px"}`,
                            textAlignLast: "end",
                            padding: `${isMobile ? "10px 20px 10px 20px" : "20px 20px 20px 20px"
                                }`,
                        }}
                    >
                        <SolidButton
                            onClick={salvarComentario}
                            color={`${pallet.textColorPrimary}`}
                            onChange={salvarComentario}
                            backgroundColor={`${pallet.backgroundColorPrimary}`}
                            fontSize={`${pallet.general.size14}`}
                            title={"SALVAR"}
                            disabled={false}
                        />
                    </Grid>
                </Grid>
            </BootstrapDialog>
        </Grid>
    );
}
