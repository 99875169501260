import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, Divider, ListItemText, Link } from '@mui/material';
import { usePallet } from "../../contexts/PalletContext";
import InputDatePicker from '../../components/InputDatePicker';
import InputMultilineTextField from '../../components/InputMultilineTextField';
import AnexosComponent, { EAnexoModulos } from '../../components/Anexos';
import SolidButton from '../../components/SolidButton';
import { isMobile } from 'react-device-detect';
import EditIcon from '@mui/icons-material/Edit';
import ProposicaoAcontecimentosService from '../../services/ProposicaoAcontecimentosService';
import { useAlert } from '../../contexts/AlertContext';
import RoomIcon from '@mui/icons-material/Room';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InputTextField from '../../components/InputTextField';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { formatDate } from '../../utils/dateUtils';
import { format } from 'date-fns';
import ButtonIconSalvar from '../../components/ButtonIconSalvar';
import ButtonDialogConfirmation from '../../components/ButtonDialogConfirmation';
import * as Yup from "yup";
import CitacoesService from '../../services/CitacoesService';
import { useAuthorization } from '../../contexts/AuthorizationContext';
import UsuarioService from '../../services/UsuarioService';
import Alert from '@mui/material/Alert';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogs(props: {
    email?: string
    statusSenha: (info: boolean) => void;
    closeMenu?: () => void
}) {
    const { NewAlert } = useAlert();
    const [open, setOpen] = React.useState(false);
    const { pallet } = usePallet();
    const [dateAcontecimentos, setDateAcontecimentos] = React.useState();
    const [dataAcontecimentosAgenda, setDataAcontecimentosAgenda] = React.useState();
    const [chave, setChave] = React.useState("");
    const [chaveRetorno, setChaveRetorno] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [senha, setSenha] = React.useState("");
    const [senhaConf, setSenhaConf] = React.useState("");
    const [onSave, setOnSave] = React.useState(false)
    const [idRef, setIdRef] = React.useState()
    const [idAgRef, setIdAgRef] = React.useState<string>()
    const [data, setData] = React.useState({});
    const [horas, setHoras] = React.useState<string>();
    const [local, setLocal] = React.useState<string>();
    const [error, setError] = React.useState<Array<{ name: string, err: string }>>([]);
    const [noChangePassword, setNoChangePassword] = React.useState<boolean>(false);




    const KeywordSchema = Yup.object({
        chave: Yup.string().required({ name: "chave", err: "Digite a chave" }).min(6),
        senha: Yup.string().required({ name: "senha", err: "Digite a senha" }).min(6),
        senhaConf: Yup.string().required({ name: "senhaConf", err: "Digite a a senha novamente para conforma-lá" }).min(6),
    });

    const handleClickOpen = async () => {
        const data = {
            email: props.email,
        }

        await UsuarioService.UpdateSenha(data).then((res) => {
            if (res) {
                NewAlert("success", "Favor checar email cadastrado.");
            } else {
                NewAlert(
                    "error",
                    "Email não cadastrado."
                );
            }
        })

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setChave('');
        setSenha('');
        setSenhaConf('');
    };


    const handleChangeChave = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChave(event.target.value);
    }
    const handleChangeSenha = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSenha(event.target.value);
    }
    const handleChangeSenhaConf = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSenhaConf(event.target.value);
    }

    const postAlteraSenha = async () => {
        const data = {

            email: props.email,
            password: senha,
            password_temp: chave

        }
        // data && KeywordSchema
        //     .validate(data, { abortEarly: false })
        //     .catch(err => setError(() => err.errors));
        // setData(data)
        // KeywordSchema.validate(data).catch(function (err) {
        // });
        // if (KeywordSchema.isValidSync(data)) {
        if (senha === senhaConf) {

            await UsuarioService.AlteraSenha(data).then((res) => {
                if (res !== -100) {
                    props.statusSenha(true)
                    handleClose()
                } else {
                    props.statusSenha(false)
                    setNoChangePassword(true)
                    setTimeout(() => {
                        setNoChangePassword(false)
                    }, 5000);
                }
            })


        } else {
            props.statusSenha(false)
            setNoChangePassword(true)
            setTimeout(() => {
                setNoChangePassword(false)
            }, 5000);
        }


    }


    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red', fontSize: '11px', marginTop: "5px", }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    const { user } = useAuthorization();

    const erroAlteracaoPerfil = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil."
        );
    }




    return (
        <div>
            <Link onClick={() => handleClickOpen()} >
                <Grid
                    style={{ fontSize: "11px", marginTop: '10px', cursor: "pointer" }}
                >
                    Esqueci a senha
                </Grid>
            </Link>


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Grid container>
                        <Grid item style={{
                            fontFamily: `${pallet.fontFamily}`,
                            fontSize: `${pallet.general.size18}`,
                            color: `${pallet.textColorSecondary}`,
                            padding: "12px",
                            marginTop: "7px",
                        }}>
                            ALTERAR SENHA
                        </Grid>
                        <Grid item>
                            {noChangePassword ?
                                <Alert severity="error">'Erro, verifique os campos.'</Alert>
                                : null}
                        </Grid>
                    </Grid>

                </BootstrapDialogTitle>

                <DialogContent dividers style={{ padding: "0px" }} >


                    <Grid container style={{ fontFamily: pallet.fontFamily.general, padding: "25px 20px 20px 40px" }}>

                        {"Uma chave foi enviada para o email " + props.email + ", favor inseri-la no campo abaixo e digite a nova senha nos outros campos."}
                        <Grid style={{ marginTop: "20px" }} item lg={12} xs={12}>
                            <InputTextField
                                type={"text"}
                                value={chave}
                                name="chave"
                                onChange={handleChangeChave}
                                id={"chave"}
                                label={"CHAVE"}
                            />
                        </Grid>
                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                            <ErrorMessage name='keyword' errors={error} />
                        </Grid>
                        <Grid style={{ marginTop: "20px" }} item lg={12} xs={12}>
                            <InputTextField
                                type={"text"}
                                value={senha}
                                name="senha"
                                onChange={handleChangeSenha}
                                id={"nova-senha"}
                                label={"NOVA SENHA"}
                            />
                        </Grid>
                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                            <ErrorMessage name='keyword' errors={error} />
                        </Grid>
                        <Grid style={{ marginTop: "20px" }} item lg={12} xs={12}>
                            <InputTextField
                                type={"text"}
                                value={senhaConf}
                                name="senha"
                                onChange={handleChangeSenhaConf}
                                id={"nova-senha_repetida"}
                                label={"DIGITE A SENHA NOVAMENTE"}
                            />
                        </Grid>
                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                            <ErrorMessage name='keyword' errors={error} />
                        </Grid>


                    </Grid>

                </DialogContent>
                <DialogActions style={{
                    padding: "30px",
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`
                }}>
                    <ButtonDialogConfirmation
                        styles={{
                            borderRadius: "6px 6px 0px 0px",
                            marginRight: "20px",
                            textTransform: "capitalize",
                            color: `${pallet.general.color1}`,
                        }}
                        title={"CONFIRMAÇÃO"}
                        title_button={"Cancelar"}
                        content={"CANCELAR ALTERAÇÃO?"}
                        // message={"Esta ação não poderá ser desfeita."}
                        confirmation_button={"Cancelar"}
                        confirmationEvent={() => {
                            handleClose()
                        }}
                    />
                    <SolidButton
                        onClick={postAlteraSenha}
                        color={`${pallet.textColorPrimary}`}
                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                        fontSize={`${pallet.general.size14}`}
                        title={"ALTERAR"}
                    />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}