import { Grid } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect"



export default function LineTitle(props: { text: string, path?: string, dateRange?: any, esfera?: 'string', type?: string }) {
    const { pallet } = usePallet();
    const navigate = useNavigate();


    return (
        <Grid onClick={() => window.open(props.path)} container style={{ paddingLeft: '40px', paddingRight: '40px', cursor: 'pointer', marginBottom: '25px',display: "flex" }}>
            <Grid style={{
                backgroundColor: "#DED3FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: `${isMobile ? "80px" : "60px"}`,
                width: '14%',
                borderTopLeftRadius: '7px',
                borderBottomLeftRadius: '7px',
            }}>

                {(props.type === "proposicao" || props.type === "proposicao_est" || props.type === "proposicao_mun") ? (
                    <AssignmentIcon
                        style={{ color: `${pallet.general.color1}` }}
                    />
                ) : (
                    <AccountBoxIcon
                        style={{ color: `${pallet.general.color1}` }}
                    />
                )} 

                {/* <AssignmentIcon
                    style={{ color: `${pallet.general.color1}` }}
                /> */}
                
            </Grid>
            <Grid  style={{
                background: `${pallet.backGroundPageColorPrimary}`,
                border: "1px solid #EAEAEA",
                display: "flex",
                alignItems: "center",
                justifyContent: 'flex-start',
                height: `${isMobile ? "80px" : "60px"}`,
                borderTopRightRadius: '7px',
                borderBottomRightRadius: '7px',
                paddingLeft: '20px',
                width: '86%'
            }}>
                {props.text}
            </Grid>
        </Grid>
    )
}