import { MoreHoriz } from "@material-ui/icons";
import ChatIcon from "@mui/icons-material/Chat";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar, Button, Divider, Grid,
  Menu,
  MenuItem,
  MenuProps,
  styled
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import { isMobile } from "react-device-detect";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import ButtonDialogConfirmation from "../../../../components/ButtonDialogConfirmation";
import useStyles from "../../../../components/layout/Styles";
import { useAlert } from "../../../../contexts/AlertContext";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import { usePallet } from "../../../../contexts/PalletContext";
import {
  EComentarioModulos, IAcaoPreparatoria, IComentario
} from "../../../../models/Proposicao.Model";
import { ILoggedUser } from "../../../../models/User.Model";
import ComentarioService from "../../../../services/ComentarioService";
import { formatDate } from "../../../../utils/dateUtils";
import ComentarioModal from "./ComentarioModal";
import RespostaComentario from "./RespostaComentario";

export default function ComentarioBox(props: {
  acao: IAcaoPreparatoria;
  comentario: IComentario;
  listaUsuario: ILoggedUser[];
  reloadLista: () => void;
}) {
  const { pallet } = usePallet();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState();
  const classes = useStyles();

  const [comentarioNovo, setComentarioNovo] = useState("");
  const [idComentarioNovo, setIdComenatarioNovo] = useState(
    props.comentario.id
  );
  const [listaUsuarioFiltrado, setListaUsuarioFiltrado] =
    useState<ILoggedUser[]>();

  const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
    setOpen(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(undefined);
  };

  const { NewAlert } = useAlert();

  const deletarComentario = (idComentario: number) => {
    ComentarioService.Delete(String(idComentario))
      .then((res) => {
        if (res) {
          NewAlert("success", "Comentário apagado com sucesso");
          handleClose();
        } else {
          NewAlert("error", "Tente novamente");
        }
      })
      .then((x) => props.reloadLista());
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      minWidth: 135,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "5px 0px",
        minWidth: 140,
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          // fontSize: 18,
        },
      },
    },
  }));

  const regex = {
    ultimaMencao: /(^| |&nbsp;)@(.[^ ]*)?$/g,
    mencaoAdicionada: /<b data-id="([0-9]+)">(.[^ ]*)<\/b>&nbsp;$/g,
    // mencoes: /<b data-id="([0-9]+)">(.[^ ]*)<\/b>/g,
    mencoes: /<b data-id="([0-9]+)">(.[^]*)<\/b>/g,
    limpar: /<b>(.[^ ]*)?<\/b>$/g,
    limparTags: /(<+(?!b|\/b)([^>]+)>)/ig
  };
  const keys = { enter: 13, space: 32, up: 38, down: 40, backspace: 8 };

  const { user } = useAuthorization();

  const regexMencoes = /@@@([0-9]+)@@@/g;

  const trocarMencionados = (comentario) =>
    comentario.replace(regexMencoes, (...match) => {
      const id = parseInt(match[1]);

      const usuario = props.listaUsuario?.filter((item) => item.id === id)[0];

      const nome = usuario?.nome ? usuario?.nome : "Usuário não identificado";

      return `<b data-id="${id}">${nome}</b>`;
    });

  const [autocomplete, setAutocomplete] = React.useState(false);
  const [selecionado, setSelecionado] = React.useState(0);
  const indexSelecionado = useRef(selecionado);
  const comentarioRef = useRef(comentarioNovo);
  const [lista, setLista] = React.useState<Array<IComentario>>([]);
  const [respostaPageIndex, setRespostaPageInedex] = React.useState<number>(1);

  useEffect(() => {
    indexSelecionado.current = selecionado;
  }, [selecionado]);

  useEffect(() => {
    comentarioRef.current = comentarioNovo;
  }, [comentarioNovo]);

  useEffect(() => {
    if (props.comentario.respostas > 0) {
      const temLista = lista.length;
      if (!temLista) loadResposta(1);
    }
  }, [props.comentario]);

  useEffect(() => {
    setListaUsuarioFiltrado(props.listaUsuario);
  }, []);

  const escolherUsuario = (usuario) => {
    if (!usuario) return;

    setAutocomplete(false);
    setListaUsuarioFiltrado(props.listaUsuario);
    setSelecionado(0);
    setComentarioNovo(
      comentarioNovo.replace(
        regex.ultimaMencao,
        `$1<b data-id="${usuario.id}">${usuario.nome}</b>&nbsp;`
      )
    );
  };

  const listarUsuarios = (usuarios) => {
    if (usuarios) {
      let mencao = String(comentarioNovo.match(regex.ultimaMencao));
      mencao =
        mencao && mencao[0].replace(regex.ultimaMencao, "$2").toLowerCase();
      return mencao
        ? usuarios.filter(({ nome }) => nome.toLowerCase().match(mencao))
        : usuarios;
    }
    return [];
  };

  const salvarComentario = () => {
    if (comentarioRef.current && comentarioRef.current.search("data-id") > 0) {

      const data = {
        // comentario: comentarioNovo,
        // comentario: comentarioRef.current.replace(
        //   regex.mencoes,
        //   (...match) => `@@@${match[1]}@@@`
        // ),
        comentario: comentarioRef.current,
        modulo: EComentarioModulos.AcaoPreparatoria,
        // id_referencia: String(props.acao.id)
        id_comentario: idComentarioNovo ? idComentarioNovo : null,
      }

      ComentarioService.Create(String(props.acao.id), data).then((res) => {
        NewAlert("success", "Comentário salvo com sucesso.");
        setComentarioNovo("");
        //loadResposta(1);
        props.reloadLista();
      });
    }else {
      NewAlert("error", "Favor mencionar ao menos um usuário com o '@' ao responder o comentário.");
    }
  };

  const handleOnKeyDown = (event) => {
    const listaDeUsuarios = listarUsuarios(listaUsuarioFiltrado);
    if (event.keyCode === keys.enter) {
      event.preventDefault();
      return !event.shiftKey ? salvarComentario() : true;
    }

    if (event.keyCode === keys.space && listaDeUsuarios.length === 1) {
      event.preventDefault();
      return escolherUsuario(listaDeUsuarios[indexSelecionado.current]);
    }

    if (
      event.keyCode === keys.backspace &&
      comentarioNovo.match(regex.mencaoAdicionada)
    ) {
      event.preventDefault();

      // return setComentarioNovo(
      //   comentarioNovo.replace(regex.mencaoAdicionada, "")
      // );
      return true
    }
  };

  const handleOnKeyUp = (e) => {
    const keyCode = e.keyCode;
    const listaDeUsuarios = listarUsuarios(listaUsuarioFiltrado);
    const ehUltimo = indexSelecionado.current === listaDeUsuarios.length - 1;

    if (keyCode === keys.up) {
      return setSelecionado(
        indexSelecionado.current && indexSelecionado.current - 1
      );
    }
    if (keyCode === keys.down) {
      return setSelecionado(
        ehUltimo ? indexSelecionado.current : indexSelecionado.current + 1
      );
    }

    setSelecionado(0);
    setAutocomplete(!!e.target.textContent.match(regex.ultimaMencao));
  };

  const handleOnChange = (event) => {
    let newValue = event.target.value.replace(/@@/g, "@").replace(regex.limparTags, "");
    
    if (newValue.match(regex.limpar))
    newValue = newValue.replace(regex.limpar, "$1");
    setComentarioNovo(newValue);

    if (autocomplete) {
      let ultimaMencao = String(newValue.match(regex.ultimaMencao))
        .replace("@", "")
        .trim();
      if (
        ultimaMencao !== "" &&
        ultimaMencao !== null &&
        ultimaMencao !== "null"
      ) {
        const novaLista = props.listaUsuario.filter(
          (i) => i.nome.indexOf(ultimaMencao.toUpperCase()) === 0
        );
        setListaUsuarioFiltrado(novaLista ? novaLista : props.listaUsuario);
      } else {
        setListaUsuarioFiltrado(props.listaUsuario);
      }
    }
  };

  const handleOnBlur = () => {
    setTimeout(() => setAutocomplete(false), 500);
  };

  const responderComentario = (usuario: { id: number; nome: string }) => {
    setComentarioNovo(`<b data-id="${usuario.id}">${usuario.nome}</b>&nbsp;`);
  };

  const loadResposta = (pageIndex?: number) => {
    let newIndex = pageIndex ? pageIndex : respostaPageIndex + 1;
    if (!pageIndex) setRespostaPageInedex(respostaPageIndex + 1);
    ComentarioService.getResposta(
      String(props.comentario.id),
      [{ key: "modulo", value: EComentarioModulos.AcaoPreparatoria }],
      newIndex,
      3
    ).then((res) => {
      setLista(newIndex === 1 ? res.data : lista.concat(res.data));
    });
  };

  const reloadResposta = async () => {
    loadResposta(1);
    props.reloadLista();
  };

  return (
    <Grid
      container
      style={{
        marginTop: "35px",
        marginRight: `${isMobile ? "20px" : "60px"}`,
        marginLeft: `${isMobile ? "20px" : "60px"}`,
        border: "1px solid #EAEAEA",
        borderRadius: "5px",
        width: "100%",
        backgroundColor: pallet.backgroundColorTertiary,
      }}
    >
      <Grid
        style={{
          padding: `${isMobile ? "10px 10px 0px 10px" : "25px 25px 0px 25px"}`,
        }}
        item
        lg={12}
        xs={12}
        container
      >
        <Grid item>
          <Avatar src={props.comentario.foto_autor} />
        </Grid>
        <Grid style={{ marginLeft: `${isMobile ? "5px" : "15px"}` }} item>
          <Grid
            item
            style={{
              fontWeight: pallet.general.weight_600,
              wordBreak: "break-word",
            }}
          >
            {props.comentario.autor}
          </Grid>
          <Grid item>
            {formatDate(new Date(props.comentario.feito_em), "dd/MM/yyyy")}
          </Grid>
        </Grid>
        <Grid style={{ marginLeft: "auto" }}>
          <Button
            id={`button-${props.comentario.id}`}
            aria-controls={
              anchorEl ? `mmenu-${props.comentario.id}` : undefined
            }
            disableElevation
            variant="contained"
            size="medium"
            style={{
              backgroundColor: pallet.backgroundColorTertiary,
              fontFamily: `${pallet.fontFamily.general}`,
              fontWeight: pallet.fontWeight.lighter,
              fontSize: `${pallet.fontSize.titleWidgets}`,
              color: `${pallet.general.color4}`,
              borderRadius: "6px",
              width: "25px",
              height: "20px",
              zIndex: 10,
            }}
            onClick={(e) => handleClick(e, props.comentario.id)}
            endIcon={
              <Grid
                style={{
                  fontSize: "30px",
                  marginTop: "9px",
                  marginRight: "10px",
                }}
              >
                <MoreHoriz fontSize="inherit" />
              </Grid>
            }
          ></Button>
          <StyledMenu
            style={{
              border: "1px solid lightgrey",
              borderRadius: "6px",
            }}
            id={`mmenu-${props.comentario.id}`}
            MenuListProps={{
              "aria-labelledby": `button-${props.comentario.id}`,
            }}
            anchorEl={anchorEl}
            open={open === props.comentario.id}
            onClose={handleClose}
          >
            <ComentarioModal
              reloadLista={() => props.reloadLista()}
              acao={props.acao}
              listaUsuario={props.listaUsuario}
              comentario={props.comentario}
              resposta={false}
            >
              <MenuItem
                style={{
                  fontFamily: `${pallet.fontFamily.general}`,
                  fontWeight: pallet.fontWeight.button,
                  fontSize: `${pallet.general.size14}`,
                  color: `${pallet.color.tertiary.font}`,
                  borderRadius: "6px",
                  padding: "7px",
                  textTransform: "none",
                }}
              >
                <EditIcon
                  style={{
                    color: `${pallet.color.tertiary.font}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    marginRight: "16px",
                    // width: "50px"
                  }}
                />
                <div style={{ fontSize: 0 }}>@</div>Editar
              </MenuItem>
            </ComentarioModal>

            <Grid>
              <Divider
                variant="fullWidth"
                style={{
                  paddingTop: `${isMobile ? "2px" : "5px"}`,
                  marginBottom: `${isMobile ? "2px" : "5px"}`,
                }}
              />
            </Grid>

            <ButtonDialogConfirmation
              styles={{
                fontFamily: `${pallet.fontFamily.general}`,
                fontWeight: pallet.fontWeight.button,
                fontSize: `${pallet.general.size14}`,
                color: `${pallet.color.tertiary.font}`,
                borderRadius: "6px 6px 0px 0px",
                textTransform: "capitalize",
              }}
              title={"CONFIRMAÇÃO"}
              title_button={""}
              confirmation_button={"Apagar"}
              content={"APAGAR COMENTÁRIO?"}
              message={"Esta ação não poderá ser desfeita."}
              confirmationEvent={() =>
                deletarComentario(Number(props.comentario.id))
              }
            >
              <MenuItem
                style={{
                  fontFamily: `${pallet.fontFamily.general}`,
                  fontWeight: pallet.fontWeight.button,
                  fontSize: `${pallet.general.size14}`,
                  color: `${pallet.color.tertiary.font}`,
                  borderRadius: "6px",
                  padding: "7px",
                }}
              >
                <DeleteIcon
                  style={{
                    color: `${pallet.color.tertiary.font}`,
                    marginRight: "16px",
                    fontFamily: `${pallet.fontFamily.general}`,
                  }}
                />
                Deletar
              </MenuItem>
            </ButtonDialogConfirmation>
          </StyledMenu>
        </Grid>
      </Grid>
      <Grid
        style={{
          padding: `${isMobile ? "10px" : "25px"}`,
          wordBreak: "break-word",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: trocarMencionados(props.comentario.comentario),
          }}
        />
      </Grid>
      <Grid
        xs={12}
        lg={12}
        style={{
          marginBottom: `${isMobile ? "8px" : "0px"}`,
          marginTop: `${isMobile ? "8px" : "20px"}`,
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <Grid
          style={{
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.general.size12}`,
            color: `${pallet.color.tertiary.font}`,
          }}
        ></Grid>
        <Grid className="container">
          <AnexosComponent
            label=""
            id_referencia={Number(props.comentario.id)}
            modulo={EAnexoModulos.Comentario}
            //onAfterUpload={handleAfterUpload}
            canEdit={false}
            autosave={false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          paddingTop: "5px",
          paddingBottom: "5px",
          borderTop: "1px solid #EAEAEA",
          borderBottom: "1px solid #EAEAEA",
          width: "100%",
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            fontFamily: pallet.fontFamily.general,
            paddingRight: "20px",
            color: pallet.general.color4,
            textTransform: "none",
            fontWeight: "normal",
            cursor: "default"
          }}
          sx={{
            "&:hover": {
              backgroundColor: pallet.backgroundColorTertiary,
            },
          }}
          startIcon={<ChatIcon />}
        >
          Comentários (
          {props.comentario.respostas ? props.comentario.respostas : "0"})
        </Button>
      </Grid>

      <Grid style={{ marginLeft: "6px", marginTop: "6px" }}>
        {Number(props.comentario.respostas / 3) > respostaPageIndex ? (
          <>
            <Button
              style={{
                textTransform: "none",
                fontFamily: pallet.fontFamily.general,
                fontWeight: pallet.general.weight_600,
                color: pallet.general.color1,
              }}
              onClick={() => loadResposta()}
            >
              Ver mais
            </Button>
          </>
        ) : (
          ""
        )}
      </Grid>
      {props.comentario.respostas &&
        lista.map((i) => {
          return (
            <>
              <RespostaComentario
                responderComentario={(usuario) => responderComentario(usuario)}
                acao={props.acao}
                comentario={i}
                reloadLista={() => {
                  reloadResposta();
                }}
                listaUsuario={props.listaUsuario}
              />
            </>
          );
        })}

      <Grid
        style={{
          width: "100%",
          padding: `${isMobile ? "5px" : "20px"}`,
          display: "flex",
        }}
        item
      >
        <Grid style={{ width: "43px", flexShrink: 0, marginRight: "3px" }} item>
          <Avatar src={user.url_foto} />
        </Grid>
        <Grid
          style={{
            width: `${isMobile ? "85%" : "95%"}`,
            wordBreak: "break-word",
          }}
          item
        >
          {props.listaUsuario && (
            <>
              <span
                style={{
                  position: "absolute",
                  fontSize: "12px",
                  left: "10px",
                  top: "-9.5px",
                  backgroundColor: "white",
                  color: "grey",
                  padding: "0px 5px",
                }}
              >
                COMENTÁRIO
              </span>
              <div
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "3px",
                }}
                className="MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-multiline"
              >
                <ContentEditable
                  style={{
                    width: "100%",
                    backgroundColor: " white !important",
                    margiLeft: "5px",
                    padding: "10px",
                    outline: "0px solid transparent",
                    zIndex: 1,
                  }}
                  id={`comentario-${props.comentario.id}`}
                  key={`comentario-${props.comentario.id}`}
                  className="MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-multiline"
                  html={comentarioNovo}
                  onChange={handleOnChange}
                  onKeyDown={handleOnKeyDown}
                  onKeyUp={handleOnKeyUp}
                  onBlur={handleOnBlur}
                />
              </div>
              <div
                style={{
                  display: `${autocomplete ? "block" : "none"}`,
                  height: "100px",
                  overflow: "auto",
                  boxShadow: "3px 3px 3px 0px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "white !important",
                  borderRadius: "0px 0px 3px 3px",
                }}
              >
                <ul
                  className={`dropdown-menu`}
                  style={{
                    backgroundColor: "white !important",
                    border: "none",
                    marginLeft: "5px",
                    padding: "0px",
                  }}
                >
                  {listarUsuarios(
                    listaUsuarioFiltrado
                      ? listaUsuarioFiltrado
                      : props.listaUsuario
                  ).map(({ id, nome }, i) => (
                    <li
                      className={`dropdown-item `} //
                      style={{
                        cursor: "pointer",
                        fontWeight: i === selecionado ? 700 : 300,
                      }}
                      onClick={() => escolherUsuario({ id, nome })}
                    >
                      {nome}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
