const getCacheKey = (config) => {
    const { url, method, data } = config;
    const bodyHash = data ? JSON.stringify(data) : '';
    return `${method}:${url}:${bodyHash}`;
};

const isExpired = (cachedItem) => {
    return cachedItem && (new Date().getTime() - cachedItem.timestamp) > cachedItem.expiry;
};

export const getFromCache = (config) => {
    const cacheKey = getCacheKey(config);
    const cachedItem = JSON.parse(sessionStorage.getItem(cacheKey));

    if (cachedItem && !isExpired(cachedItem)) {
        return cachedItem.response;
    }

    sessionStorage.removeItem(cacheKey);
    return null;
};

export const saveToCache = (config, response, expiry) => {
    const cacheKey = getCacheKey(config);
    const cachedItem = {
        response,
        timestamp: new Date().getTime(),
        expiry,
    };
    sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem));
};
