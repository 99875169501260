import * as http from '../helpers/http';

class TabelasService {
    getTabelaPartidoTodos = async (): Promise<Array<string>> => {
        const result = await http.get('/tabelas/partidos');
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaPartidoAtual = async (): Promise<Array<string>> => {
        const result = await http.get('/tabelas/partidos/atual');
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaPartidoOutros = async (): Promise<Array<string>> => {
        const result = await http.get('/tabelas/partidos/outros');
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaAmbitos = async (casa: number): Promise<Array<{ id: number, sigla: string, descricao: string, resumo: string }>> => {
        const result = await http.get(`/tabelas/parlamentar/ambitos/${casa}`);
        if (result.success) { return result.data }
        return undefined;
    };

    getTabelaAmbitosTipo = async (casa: number): Promise<Array<{ id: number, sigla: string, descricao: string, resumo: string }>> => {
        const result = await http.get(`/tabelas/parlamentar/ambitos-tipo/${casa}`);
        if (result.success) { return result.data }
        return undefined;
    };

    getAmbitosById = async (id: number): Promise<Array<{ id: number, sigla: string, descricao: string }>> => {
        const result = await http.get(`/tabelas/comissoes/${id}`);
        if (result.success) {
            return result.data
        }
        return undefined;
    };

    getAmbitoBySiglaOrgao = async (sigla: string): Promise<Array<{ id: number, sigla: string, descricao: string }>> => {
        const result = await http.get(`/tabelas/comissao/${sigla}`);
        if (result.success) {
            return result.data
        }
        return undefined;
    };

    getTabelaAmbitosToProp = async (casa: number): Promise<Array<{ id: number, sigla: string, descricao?: string, ambitoId: number }>> => {
        const result = await http.get(`/tabelas/parlamentar/ambitos-to-prop/${casa}`);
        if (result.success) { return result.data }
        return undefined;
    };

    getTabelaEstados = async (): Promise<Array<{ id: number, sigla: string, nome: string }>> => {
        const result = await http.get('/tabelas/estados');
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaMunicipios = async (uf: string): Promise<Array<{ id: number, sigla: string, nome: string }>> => {
        const result = await http.get(`/tabelas/municipios/${uf}`);
        if (result.success) { return result.data }
        return undefined;
    };

    getTabelaEstadosById = async (id: number): Promise<Array<{ sigla: string }>> => {
        const result = await http.get(`/tabelas/estados-id/${id}`);
        if (result.success) {
            return result.data
        }
        return undefined;
    };
    getTabelaMunicipiosById = async (id: number): Promise<Array<{ nome: string }>> => {
        const result = await http.get(`/tabelas/municipios-id/${id}`);
        if (result.success) {

            return result.data
        }
        return undefined;
    };

    getTabelaMunicipiosByName = async (nome: string): Promise<Array<{ id: number }>> => {
        const result = await http.get(`/tabelas/municipiosNome/${nome}`);
        if (result.success) {
            return result.data
        }
        return undefined;
    };

    getTabelaEstadoId = async (nome: string): Promise<Array<{ id: number }>> => {
        const result = await http.get(`/tabelas/estadosSigla/${nome}`);

        if (result.success) { return result.data }
        return undefined;
    };


    getTabelaProfissoes = async (): Promise<Array<{ id: number, profissao: string }>> => {
        const result = await http.get(`/tabelas/profissoes`);
        if (result.success) { return result.data }
        return undefined;
    };

    getTabelaDepartamentos = async (): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/departamentos`);
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaEscolaridades = async (): Promise<Array<{ id: number, escolaridade: string }>> => {
        const result = await http.get(`/tabelas/escolaridades`);
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaTramitacaoSituacoes = async (): Promise<Array<{ codsituacao: number, descsituacao: string }>> => {
        const result = await http.get(`/tabelas/tramitacaosituacao`);
        if (result.success) { return result.data }
        return undefined;
    };
    getProposicaoTipos = async (): Promise<Array<string>> => {
        const result = await http.get(`/tabelas/proposicao/tipos`);
        if (result.success) {
            return result.data.split(',').map((i) => {
                return (
                    i.replace('[', '').replace(']', '').replace('"', '').replace('"', '').replace(' ', '')
                )
            })
        }
        return undefined;
    };
    getProposicaoTemasLegislativos = async (casa?: number): Promise<Array<{ id: number, codigo: string, casa: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/proposicao/temas-legislativo/${casa}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getProposicaoGrupoTemas = async (): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/proposicao/grupotemas`);
        if (result.success) {
            return result.data
        }
        return undefined;
    };
    getProposicaoTemas = async (id?: number): Promise<Array<{ id: number, descricao: string, id_grupo: number }>> => {
        const result = await http.get(`/tabelas/proposicao/temas/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getProposicaoTemasGeral = async (id?: number): Promise<Array<{ id: number, descricao: string, id_grupo: number }>> => {
        const result = await http.get(`/tabelas/proposicao/temas_geral/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getProposicaoTemasTopico = async (id?: number) => {
        const result = await http.get(`/tabelas/proposicao/${id}/topico`);
        if (result.success) { return result.data }
        return undefined;
    };
    getProposicaoTemasByGrupo = async (grupo_id?: number): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/proposicao/temas/grupo/${grupo_id}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getProposicaoSubTemas = async (id?: number): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/proposicao/subtemas/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getProposicaoSubTemasByTema = async (tema_id?: number): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/proposicao/subtemas/tema/${tema_id}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getProposicaoTopicosBySubtema = async (subtema_id?: number): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/proposicao/topicos/subtema/${subtema_id}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaInterlocutores = async (): Promise<Array<any>> => {
        const result = await http.get('/tabelas/interlocutores');
        if (result.success) { return result.data }
        return undefined;
    };

    getTabelaCasasByUf = async (uf: string): Promise<Array<{ id: number, sigla: string, nome: string }>> => {
        const result = await http.get(`/tabelas/casas/${uf}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaCasasEstaduais = async (): Promise<Array<{ id: number, sigla: string, nome: string }>> => {
        const result = await http.get(`/tabelas/casas-estaduais/`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };

    getTabelaCasasMunicipais = async (id: number): Promise<Array<{ id: number, sigla: string, nome: string }>> => {
        const result = await http.get(`/tabelas/casas-municipais/${id}`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };
    getTabelaCasasMunicipaisByUf = async (uf: string): Promise<Array<{ id: number, sigla: string, nome: string }>> => {
        const result = await http.get(`/tabelas-uf/casas-municipais/${uf}`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };
    getTabelaCasasEstaduaisLabel = async (id: number): Promise<Array<{ id: number, sigla: string, nome: string }>> => {
        const result = await http.get(`/tabelas/casas-estaduais/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getTabelaCasasMunicipaisLabel = async (id: number): Promise<Array<{ id: number, sigla: string, nome: string }>> => {
        const result = await http.get(`/tabelas/casas-municipais-label/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };

    getDescricaoGrupos = async (id: number): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/descricao/grupos/${id}`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };
    getDescricaoTemas = async (id: number): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/descricao/temas/${id}`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };
    getDescricaoSubtemas = async (id: number): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/descricao/subtemas/${id}`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };


    getDescricaotopicos = async (id: number): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/tabelas/descricao/topicos/${id}`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };

    getVotoEmSeparado = async (id_proposicao: number): Promise<Array<string>> => {
        const result = await http.get(`/tabelas/voto-em-separado/${id_proposicao}`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };

    getProposicaoDeleted = async (id_proposicao: number): Promise<Array<any>> => {
        const result = await http.get(`/tabelas/proposicao-deleted/${id_proposicao}`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };

    getAcontecimentoStatus = async (): Promise<Array<any>> => {
        const result = await http.get(`/tabelas/acontecimentosStatus`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };

    getResultadosAcoes = async (): Promise<Array<any>> => {
        const result = await http.get(`/tabelas/resultadosAcoes`);
        if (result.success) {
            return result.data
        };
        return undefined;
    };
};

export default new TabelasService();
