import * as React from "react";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionSummary, Grid, Icon, SvgIconProps, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { usePallet } from "../contexts/PalletContext";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { borderRight, fontSize } from "@mui/system";
//import { options } from "../helpers/http";
//import AccordionSummary from "@mui/material/AccordionSummary";
//import AccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
}));

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//   <MuiAccordionSummary style={{flexFlow:"row-reverse"}}
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark"
//       ? "rgba(255, 255, 255, .05)"
//       : "rgba(0, 0, 0, .03)",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

export default function DropDownMenu(props: {
  icon: SvgIconProps;
  title: string;
  subTitles?: Array<{ subkey?: number; namesubTitle?: string; url?: string, hide?: boolean }>;
  url?: string;
  index?: number;
  nomenudrop: number;
  idButtonSelected?: number;
  onChange(key: number);
  open?: boolean;
  hide?: boolean;
}) {
  const { pallet } = usePallet();

  const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-top: 0px;
    flex-flow: row-reverse;
    .MuiAccordionSummary-content {
      flex-direction: column;
    }
  `;

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    fontFamily: `${pallet.fontFamily.general}`,
    fontSize: `${pallet.general.size14}`,
    fontColor: `${pallet.textColorQuaternary}`,
  }));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    props.onChange(props.nomenudrop);
  };
  const handleClick2 = () => {
    props.onChange(props.nomenudrop);
  };
  const handleClose = () => {
    setAnchorEl(null);
    props.onChange(undefined);
  };

  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState<string | false>(props.title);

  useEffect(() => {
    if (props.open) {
      setExpanded(false);
      props.onChange(undefined);
    }
  }, [props.open, anchorEl]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      newExpanded === false
        ? props.onChange(undefined)
        : props.onChange(props.nomenudrop);
    };

  /*  
const contentDetail = {
         props.sublabel.map(item => {
            <Grid style={{border:"1px solid red"}} onClick={() => navigate(`${item.path}`)}>
                {item.title}
            </Grid>
        })
    }*/

  if (props.open) {
    return (
      <Grid>

        {props.subTitles ?
          <Accordion
            expanded={expanded === props.title}
            onChange={handleChange(props.title)}
          // onClick={handleClick2}
          >
            <Grid
              style={{
                borderRadius: "5px",
                width: "300px",
                backgroundColor: `${props.idButtonSelected === props.index
                  ? pallet.backgroundColorQuaternary
                  : "white"
                  }`,
                marginLeft: "10px",
              }}
            >
              <AccordionSummary
                sx={{ height: "15px", textAlign: "center" }}
                expandIcon={props.open ? <ExpandMoreIcon /> : ""}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >

                <Grid
                  style={{
                    color: `${props.idButtonSelected === props.index
                      ? pallet.general.color2
                      : pallet.general.color4
                      }`,
                  }}
                  onClick={!props.subTitles ? () => console.log("éqoijdiasod") : () => console.log("asdasdasdsad")}
                >
                  <div>
                    {" "}
                    <Tooltip title={props.title} arrow>
                      <Icon>{props.icon}</Icon>
                    </Tooltip>

                  </div>

                </Grid>

                <Grid
                  style={{
                    fontSize: pallet.general.size14,
                    fontFamily: pallet.fontFamily.general,
                    fontWeight: "bold",
                    marginLeft: "10px",
                    paddingRight: "50px",
                    justifyContent: "center",
                    paddingTop: "3px",
                    color: `${props.idButtonSelected === props.index
                      ? pallet.general.color2
                      : pallet.general.color4
                      }`,
                  }}
                  onClick={!props.subTitles ? () => navigate(props.url) : () => true}
                >
                  {props.title}
                </Grid>
              </AccordionSummary>
            </Grid>
            <AccordionDetails>
              <Grid>
                <Grid>
                  {props?.subTitles?.map((x, index) => {
                    return (
                      <Grid
                        style={{
                          display: `${x.hide ? 'none' : 'block'}`,
                          marginLeft: "16%",
                          marginBottom: "10%",
                          fontSize: pallet.general.size14,
                          fontFamily: pallet.fontFamily.general,
                          fontWeight: "bold",
                          color: pallet.general.color4,
                          cursor: "pointer",
                        }}
                        key={index}
                        onClick={() => navigate(x.url)}
                      >
                        {x.namesubTitle}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion >
          :
          <Accordion
            expanded={false}
            onChange={handleChange(props.title)}
          // onClick={handleClick2}
          >
            <Grid
              style={{
                borderRadius: "5px",
                width: "300px",
                backgroundColor: `${props.idButtonSelected === props.index
                  ? pallet.backgroundColorQuaternary
                  : "white"
                  }`,
                marginLeft: "10px",
              }}
            >
              <AccordionSummary
                sx={{ height: "15px", textAlign: "center" }}
                expandIcon={""}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid
                  style={{
                    color: `${props.idButtonSelected === props.index
                      ? pallet.general.color2
                      : pallet.general.color4
                      }`,
                  }}
                  onClick={!props.subTitles ? () => console.log("éqoijdiasod") : () => console.log("asdasdasdsad")}
                >
                  <div>
                    {" "}
                    <Tooltip title={props.title} arrow>
                      <Icon>{props.icon}</Icon>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid
                  style={{
                    fontSize: pallet.general.size14,
                    fontFamily: pallet.fontFamily.general,
                    fontWeight: "bold",
                    marginLeft: "10px",
                    paddingRight: "50px",
                    justifyContent: "center",
                    paddingTop: "3px",
                    color: `${props.idButtonSelected === props.index
                      ? pallet.general.color2
                      : pallet.general.color4
                      }`,
                  }}
                  onClick={!props.subTitles ? () => navigate(props.url) : () => true}
                >
                  {props.title}
                </Grid>
              </AccordionSummary>
            </Grid>

          </Accordion >

        }

      </Grid >
    );
  } else {
    return (
      <div>
        <Button
          sx={{
            color: `${props.idButtonSelected === props.index
              ? pallet.general.color2
              : pallet.general.color4
              }`,
            paddingLeft: "0px",
            marginBottom: "6px",
          }}
          id="demo-positioned-button"
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={props.subTitles ? handleClick : () => navigate(props.url)}
        >
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "5px",
              backgroundColor: `${props.idButtonSelected === props.index
                ? pallet.backgroundColorQuaternary
                : "white"
                }`,
            }}
          >
            <Tooltip title={props.title} arrow>
              <Icon>{props.icon}</Icon>
            </Tooltip>
          </div>
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: 5,
            horizontal: -60,
          }}
        >
          <Grid
            style={{
              marginTop: "-8px",
              marginBottom: "-8px",
              minWidth: "190px",
            }}
          >
            <MenuItem
              sx={{
                "&:hover": {
                  backgroundColor: pallet.color.secundary,
                },
                fontSize: pallet.general.size14,
                fontFamily: pallet.fontFamily.general,
                fontWeight: pallet.general.weight_600,
                marginTop: "0px",
                paddingTop: "10px",
                paddingBottom: "10px",
                // marginLeft:"10px",
                //verticalAlign: "middle",
                backgroundColor: pallet.color.secundary,
                color: pallet.textColorSecondary,
              }}
            >
              {props.title}
            </MenuItem>
            {props.subTitles && props.subTitles.map((x, index) => {
              return (
                <MenuItem
                  sx={{
                    display: `${x.hide ? 'none' : 'block'}`,
                    "&:hover": {
                      backgroundColor: "white",
                    },
                    fontSize: pallet.general.size14,
                    fontFamily: pallet.fontFamily.general,
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontWeight: pallet.general.weight_300,
                    // marginLeft:"10px",
                    verticalAlign: "middle",
                    color: pallet.general.color4,
                  }}
                  onClick={() => navigate(x.url)}
                >
                  {x.namesubTitle}
                </MenuItem>
              );
            })}
          </Grid>
        </Menu>
      </div>
    );
  }
}
