import React from 'react';
import { useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { Grid } from '@mui/material';
import { usePallet } from '../../contexts/PalletContext';
import ContentCut from '@mui/icons-material/ContentCut';
import { withStyles } from "@material-ui/core/styles";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';


// const SubItem = () => {
//     return (
//         <>
//             <p>
//                 Ola sera?
//             </p>
//         </>
//     )
// }



export default function MenuItem({ menu }) {
    const { pallet } = usePallet();

/*
    const ListItemConteudo = withStyles( theme => ({
        root: {
            fontFamily:pallet.fontFamily.general,
            color:pallet.general.color4, 
            fontSize:pallet.fontSize.titleWidgets,
        }
      }))(ListItemText);
  */


    const navigate = useNavigate();
   
    return (
       
            <Grid style={{marginLeft:"10px"}} >
                <ListItem sx={{'&:hover': {
                                            backgroundColor: "white",
                                },
                                        fontFamily:pallet.fontFamily.general,
                                        color:pallet.general.color4, 
                                        fontSize: pallet.general.size14,
                                        marginTop:"13px",
                                        fontWeight:"bold"
                                        }} key={menu.key}  button onClick={() => navigate(menu.url)} >
                   
                    <Grid style={{display: "flex"}}><Grid>{menu.icon}</Grid>
                                                    <Grid style={{marginLeft:"10px"}}> {menu.title}</Grid>
                    </Grid>

         
                </ListItem>
                {/* <Grid justifyContent="center" style={{ marginTop: "-5px", display: "flex" }} >
                    <SubItem />
                </Grid> */}
            </Grid>

     
    )

    
  
}