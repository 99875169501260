import * as React from 'react';
import TextField from '@mui/material/TextField';
import useStyles from './layout/Styles';
import { usePallet } from '../contexts/PalletContext';
import { InputAdornment } from '@mui/material';



export default function InputTextFieldSimple(
    props: {
        value: any,
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
        id: string,
        label: string,
        type?: string,
        name?: string,
        placeholder?: string,
        focused?: boolean,
        startAdornment?: React.ReactNode,
        maxLenght?:number
    }) {

    const classes = useStyles();
    const { pallet } = usePallet();

    return (
        <TextField
            name={props.name ? props.name : ""}
            type={props.type ? props.type : ""}
            placeholder={props.placeholder ? props.placeholder : ""}
            value={props.value}
            focused={props.focused ? props.focused : false}
            onChange={props.onChange}
            style={{ width: "100%" }}
            id={props.id}
            label={props.label}
            variant="outlined"
            inputProps={{ maxLength: props.maxLenght ? props.maxLenght : false }}
            InputProps={{
                classes: {
                    notchedOutline: classes.notchedOutline,
                },
                style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size14}`,
                    fontWeight: pallet.general.weight_100,
                }
            }}
            InputLabelProps={{
                style: { color: "grey" }
            }}
        />
    );
}