import * as http from '../helpers/http';

class CitacoesService {
    getCitacoes = async (pageIndex: number, pageSize: number = 8): Promise<any> => {
        const result = await http.get(`/citacoes?pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) { return result.data }
        return undefined;
    };
    getCitacoesUnico = async (id: number): Promise<any> => {
        const result = await http.get(`/citacoes/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };


    Create = async (data: any): Promise<number> => {
        const result = await http.post(`/citacoes`, data);
        if (result.success) { return result.data }
        return 0;
    };

    Update = async (data: any, idCitacao: number): Promise<boolean> => {
        const result = await http.put(`/citacoes/deletar/${idCitacao}`, data);
        if (result.success) { return result.success }
        return false;
    };
    UpdateGeral = async (data: any, id: number): Promise<boolean> => {
        const result = await http.put(`/citacoes/${id}`, data);
        if (result.success) { return result.success }
        return false;
    };


}

export default new CitacoesService();
