import {
    Grid,
    Typography,
    Button,
    Box,
} from "@mui/material";
import useStyles from "../../components/layout/Styles";
import { usePallet } from "../../contexts/PalletContext";
import styled from "styled-components";
import Main from "../main/Main";
import filtersBackgroundImage from "../../../src/img/background_filters_dashboard.png";
import { isMobile } from "react-device-detect";
import BackupTableIcon from '@mui/icons-material/BackupTable';
import CarouselCustom from '../../components/carousel/CarouselCustom';
import UploadFilesService from "../../services/UploadFiles.service";
import { download } from "../../helpers/http";
import React, { useEffect, useState } from "react";
import { useAlert } from "../../contexts/AlertContext";
import CircularLoading from "../../components/CircularLoading";

const FilterButton = styled(Button)`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	text-transform: capitalize;
	color: #160342;
	border: 0;
	font-size: 14px;
	line-height: 17px;
	background: rgba(75, 17, 150, 0.1);
	border-radius: 6px;
	padding: 10px;
	width: 100%;
	height: 100%;
	:first-child {
		margin-right: 10px;
	}
    `;

const TitleText = styled(Typography)`
    color: ${(props) => props.pallet.textColorQuaternary};
    font-weight: ${(props) => props.pallet.general.weight_bold};
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1px;
    `;

const CarouselTab = styled(CarouselCustom)`
    .customTab{
        width: 500px;
        background-color: red !important;
    }
    `;

export default function RelatorioGeral() {
    const classes = useStyles();
    const { pallet } = usePallet();
    const [message, setMessage] = React.useState<string>(null);
    const { NewAlert } = useAlert();
    const [loadingGeralFederais, setLoadingGeralFederais] = useState(false);
    const [loadingGeralEstaduaisMunicipais, setLoadingGeralEstaduaisMunicipais] = useState(false);
    
    const downloadRoot = async (nome:string, setLoading = (e) => e) => {
        NewAlert('info', `Download do arquivo ${nome} em andamento...`);
        setLoading(true);
        UploadFilesService.download(nome, true)
            .then((resp) => {
                if (resp.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link?.setAttribute("download", nome);
                    document.body.appendChild(link);
                    link?.click();
                    link?.parentNode?.removeChild(link);
                    NewAlert('success', `Download do arquivo ${nome} finalizado com sucesso!`);
                    setLoading(false);
                } else {
                    NewAlert('error', `Erro ao realizar o download do arquivo ${nome}.`);
                    setLoading(false);
                }
            }).catch((err) => {
                NewAlert('error', `Erro ao realizar o download do arquivo ${nome}.`);
                console.error(err);
                setLoading(false);
            });
    }

    return (
        <div className={classes.root}>
            <Main>
                <>
                    <Grid maxWidth={"100%"} container>
                        <Grid item xs={12} key="TitleText">
                            <TitleText pallet={pallet}>Relatório Geral</TitleText>
                        </Grid>

                        <Grid container style={{ paddingTop: "30px" }}>
                            <Typography display={message} variant="subtitle2" className='upload-message error'>{message}</Typography>
                            <Grid
                                item
                                container
                                xs={12}
                                style={{
                                    backgroundImage: `url(${filtersBackgroundImage})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    padding: "25px",
                                    borderRadius: "5px",
                                    boxShadow:
                                        "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                }}
                            >
                                
                                <Grid item></Grid>

                                <Grid item style={{ height: '50px', width: '100%', display: `${isMobile ? "block" : "flex"}` }}>
                                    {loadingGeralFederais ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                                    <FilterButton
                                        startIcon={<BackupTableIcon />}
                                        onClick={() => { downloadRoot('ProposicoesFederais.xlsx', setLoadingGeralFederais); }}
                                    >
                                        Extrair Relatório Geral Federal
                                    </FilterButton>
                                    }
                                </Grid>

                                <Grid item style={{ marginTop: '25px', height: '50px', width: '100%', display: `${isMobile ? "block" : "flex"}` }}>
                                    {loadingGeralEstaduaisMunicipais ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                                    <FilterButton
                                        startIcon={<BackupTableIcon />}
                                        onClick={() => { downloadRoot('ProposicoesEstaduaisMunicipais.xlsx', setLoadingGeralEstaduaisMunicipais); }}
                                    >
                                        Extrair Relatório Geral Estadual e Municipal
                                    </FilterButton>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </Main>
        </div >
    );
}
