import React, { useEffect } from 'react';
import { useAuthorization } from "./AuthorizationContext";
import NotificationsContextService, { INotificationsContextResult } from '../services/NotificationsContextService';

interface INotificationsProviderProps { children: React.ReactNode; }

const Context = React.createContext<INotificationsContextResult>(undefined);

const NotificationsProvider = ({ children }: INotificationsProviderProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [newNotifications, setNewNotifications] = React.useState<any>();
  const [totalNotificacoesTodas, setTotalNotificacoesTodas] = React.useState<any>();
  const [totalNotificacoesProposicao, setTotalNotificacoesProposicao] = React.useState<any>();
  const [totalNotificacoesInterlocutor, setTotalNotificacoesInterlocutor] = React.useState<any>();
  const [totalNotificacoesCitacoes, setTotalNotificacoesCitacoes] = React.useState<any>();
  const [totalNotificacoesTwitter, setTotalNotificacoesTwitter] = React.useState<any>();
  const [totalNotificacoesAgenda, setTotalNotificacoesAgenda] = React.useState<any>();

  const [notificacaoSummary, setNotificacaoSummary] = React.useState<any>();
  const [notificacaoPendencies, setNotificacaoPendencies] = React.useState<any>();
  const [notificacaoPendenciesDepartament, setNotificacaoPendenciesDepartament] = React.useState<any>();

  const { user } = useAuthorization();

  const [page, setPage] = React.useState<number>(0);
  const mudarPagina = () => {
    setPage((Oldpage) => Oldpage + 1)
  };

  const getData = (pagina?: number, filtro?: any) => {
    if (!pagina) {
      pagina = 1
    };

    if (loading) return;

    setLoading(true);
    NotificationsContextService.GetAll(filtro, 8 * pagina, pagina) //TODO Alterar para só as novas depois
      .then(result => {
        if (result) {
          setNewNotifications(result);
          setTotalNotificacoesTodas(result.countByCategory["TODOS"]);
          setTotalNotificacoesProposicao(result.countByCategory["PROPOSIÇÃO"]);
          setTotalNotificacoesInterlocutor(result.countByCategory["INTERLOCUTOR"]);
          setTotalNotificacoesAgenda(result.countByCategory["AGENDA"]);
          setTotalNotificacoesCitacoes(result.countByCategory["CITAÇÕES"]);
          setTotalNotificacoesTwitter(result.countByCategory["TWITTER"]);
        };
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  const getDataPendencias = () => {
    NotificationsContextService.GetPendencies() //TODO Alterar para só as novas depois
      .then(result => {
        if (result) setNotificacaoPendencies(result?.data)
      });
  };

  const getDataPendenciasDepartamento = () => {
    NotificationsContextService.GetPendenciesDepartament() //TODO Alterar para só as novas depois
      .then(result => {
        if (result) setNotificacaoPendenciesDepartament(result.data)
      });
  };

  useEffect(() => {
    if (!user) return;

    NotificationsContextService.GetSummary() //TODO Alterar para só as novas depois
      .then(result => {

        if (result) setNotificacaoSummary(result.data)
      });

    NotificationsContextService.GetPendencies() //TODO Alterar para só as novas depois
      .then(result => {
        if (result) setNotificacaoPendencies(result?.data)
      });

    NotificationsContextService.GetPendenciesDepartament() //TODO Alterar para só as novas depois
      .then(result => {
        if (result) setNotificacaoPendenciesDepartament(result.data)
      });
  }, [user]
  );

  return (
    <Context.Provider value={{ newNotifications, totalNotificacoesTodas, totalNotificacoesProposicao, totalNotificacoesInterlocutor, totalNotificacoesAgenda, totalNotificacoesCitacoes, totalNotificacoesTwitter, notificacaoSummary, notificacaoPendencies, notificacaoPendenciesDepartament, getData, mudarPagina, getDataPendencias, getDataPendenciasDepartamento }}>
      {children}
    </Context.Provider >
  );
};

export { NotificationsProvider };
export const useNotifications = () => React.useContext(Context);