import React from 'react';
import { Grid, Divider } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import NumbersPanelWidget from '../../../components/NumbersPanelWidget';


const DadosPendencias = (props: { valueMinhas: number, valueDepartamento: number, titleMinhas: string, linkMinhas: string, titleDepartamento: string, linkDepartamento: string }) => {
    const { pallet } = usePallet();
    return (
        <div>
            <Grid container justifyContent="flex-start" style={{color:`${pallet.general.color1}`}}>
                <Grid item xs={6}>
                    <NumbersPanelWidget
                        value={props.valueMinhas}
                        title={props.titleMinhas}
                        link={props.linkMinhas}
                    />
                </Grid>
                <Grid item container xs={6} >
                    <Divider orientation="vertical" />
                    <Grid>
                        <NumbersPanelWidget
                            value={props.valueDepartamento}
                            title={props.titleDepartamento}
                            link={props.linkDepartamento}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}


export default DadosPendencias;