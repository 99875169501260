import React from 'react';
import { Grid } from '@mui/material';
import ImpactoFinanceiroData from './ImpactoFinandeiroData';


const IndicadoresImpactoFinanceiro = (props: { valor_positivo: number, valor_neutro?: number, valor_negativo: number,valor_nao_mensuravel?: number,linkPositivo?: string,linkNegativo?: string,linkNeutro?: string,linkNaoMensuravel?: string}) => {
    return (
        <div>
            <Grid container justifyContent="normal">
                <Grid item key="Positivo">
                    <ImpactoFinanceiroData key="Positivo" title={"Positivo"} value={props.valor_positivo} link={props.linkPositivo} />
                </Grid>
                {/* <Grid item key="Neutro">
                    <ImpactoFinanceiroData key="Neutro" title={"Neutro"} value={props.valor_neutro} link={props.linkNeutro}/>
                </Grid> */}
                <Grid item key="Negativo">
                    <ImpactoFinanceiroData key="Negativo" title={"Negativo"} value={props.valor_negativo} link={props.linkNegativo}/>
                </Grid>
                {/* <Grid item key="NaoMensuravel">
                    <ImpactoFinanceiroData key="Negativo" title={"Não Mensurável"} value={props.valor_nao_mensuravel} link={props.linkNaoMensuravel}/>
                </Grid> */}
            </Grid>
        </div>
    )
}

export default IndicadoresImpactoFinanceiro;