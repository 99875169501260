import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import useStyles from "../../../../components/layout/Styles";
import { usePallet } from "../../../../contexts/PalletContext";
import Main from "../../../main/Main";
import TCTemasCorporativosGrupoList from "./TCTemasCorporativosGrupoList";
import filtersBackgroundImage from '../../../../../src/img/OrangeBackgroundHeader.png';
import { IListInfo } from "../../../../models/IListInfo";
import ButtonIconSalvar from "../../../../components/ButtonIconSalvar";
import { useNavigate } from "react-router-dom";
import TCService from "../../../../services/TCService";
import { IFilterData } from "../../../../components/filter/ListFilter";


//## StyledCompenents
const FilterArea = styled(Paper)`
	width: 100%;
	/* height: 55px; */
	margin-top: 16px;
	margin-bottom: 10px;
	padding: 16px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background-color: #ffff;
`;

const FilterButton = styled(Button)`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	text-transform: capitalize;
	color: #160342;
	border: 0;
	font-size: 14px;
	line-height: 17px;
	background: rgba(75, 17, 150, 0.1);
	border-radius: 6px;
	padding: 10px;
	width: 100%;
	height: 100%;

	:first-child {
		margin-right: 10px;
	}
`;

const ContainerHead = styled(Grid)`
	margin-bottom: 10px;
	margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
	margin-bottom: 30px;
`;

const PageTitle = styled(Grid)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-weight: ${(props) => props.pallet.general.weight_bold};
	font-size: 24px;
	font-family: ${(props) => props.pallet.general.fontFamily};
`;

const ContainerFilter = styled(Grid)`
	/* width: 10%; */
	width: 100%;
	margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
	justify-content: ${isMobile ? "center" : "space-between"};
    flex-direction: column;
`;

const ContainerCard = styled(Grid)`
	margin-top: 20px;

`;

const PaperContent = styled(Paper)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
	margin-top: 40px;
	margin-bottom: 40px;
	justify-content: center;
`;

export default function TemasCorporativosLista() {
	const { pallet } = usePallet();
	const classes = useStyles();
	const navigate = useNavigate();

	const [currentPageSize, setCurrentPageSize] = useState<number>(50);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [filter, setFilter] = useState({ key: "visualizacao", label: "Todos", value: "T" });
	const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

	const [lista, setLista] = useState([]);
	const [listaDescricao, setListaDescricao] = useState<string[]>([]);
	const [busca, setBusca] = useState<IFilterData[]>();
	const [descricaoParcial, setDescricaoParcial] = useState<string>("");

	//## Paginação
	const [infoList, setInfoList] = useState<IListInfo>({
		pageIndex: 1,
		pageSize: 8,
		pageItens: 0,
		totalItens: 0,
		totalPages: 0,
	});

	useEffect(() => {
		if (descricaoParcial && descricaoParcial.trim().length > 2) {
			TCService.getGrupos(currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "descricaoP", value: descricaoParcial }]))
				.then(async res => {
					if (res) {
						const data = [];

						res.data.map(item => data.push({
							key: item.grupo_id,
							//label: item.descricao + (item.casa === 1 ? ' [Senado Federal]' : (item.casa === 2 ? ' [Câmara dos Deputados]' : '')),
							label: item.grupo_descricao,
							value: item.grupo_id
						}))

						setListaDescricao(data.map(item => { return item.label }));
						// setListaDescricao(() => [...data]);
						setBusca(() => [...data]);
					}
				});
		} else if (descricaoParcial === "") {
			loadGrupoList();
		}
	}, [descricaoParcial]);

	useEffect(() => {
		if (currentPage > 0) loadGrupoList();
	}, [currentPage, currentPageSize, currentFilter]);

	const loadGrupoList = () => {
		if (filter) {
			//currentFilter = { "key": "visualizacao", "value": filter.value }
		}

		TCService.getGrupos(currentPageSize, currentPage, JSON.stringify(currentFilter)) // force break
			.then(async (res) => {

				if (res) {
					setLista(res.data);
					setInfoList(res.listInfo);
				}
			});
	};


	return (
		<div className={classes.root}>
			<Main>
				<>
					<ContainerHead container>
						{/* <Paper style={{ borderRadius: "7px", width: "100%", height: "40%" }}> */}


						<ContainerSubHead container alignItems="center" justifyContent="space-between">

							<PageTitle pallet={pallet} >Temas Corporativos</PageTitle>

						</ContainerSubHead>
						{/* </Paper> */}



						<Grid container>
						</Grid>
						<Grid
							style={{
								width: isMobile ? "60%" : "100%",
							}}
						/>

						<ContainerFilter style={{}}>
							<FilterArea>
								<Grid style={{ width: `${isMobile ? "70%" : "65%"}`, display: "flex", alignItems: "center" }}>
									{/* <FilterQuantity
											list={infoList}
											currentPageSize={currentPageSize}
											onChange={handleOnChangeItensPerPageSize}
										/>&nbsp;&nbsp;<strong>[ {filter.label} ]</strong> */}
									<strong>
										<span style={{ color: pallet.general.color1 }}>
											{infoList?.totalItens ? infoList?.totalItens : "1"}
										</span> Grupo(s)
									</strong>
								</Grid>

								<Grid style={{ width: `${isMobile ? "30%" : "35%"}`, display: "flex", justifyContent: "flex-end" }}>

									<ButtonIconSalvar
										onClick={() => navigate('/classificacaodetemas/cadastrar/G')}
										tipo={"cadastrar_grupo"}
										backgroundColor={pallet.backgroundColorPrimary}
										border={"1px solid lightgrey"}
										fontColor={pallet.textColorPrimary}
										fontSize={pallet.general.size12}
										icon={""}
										title={"CADASTRAR"}
									/>

								</Grid>
							</FilterArea>
						</ContainerFilter>

					</ContainerHead>
					<ContainerBody container>
						<Grid item style={{ width: "100%" }}>

							<Paper style={{
								borderRadius: "6px",
								paddingTop: `${isMobile ? "0px" : "0px"}`
							}}>

								<TCTemasCorporativosGrupoList />

							</Paper>

						</Grid>
					</ContainerBody>
					<ContainerFooter container>
					</ContainerFooter>

				</>
			</Main>
		</div>
	);
}
