
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/system/Box';
import { usePallet } from '../contexts/PalletContext';
import styled from "styled-components";
import useStyles from '../components/layout/Styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { proposicaoPosicionamento } from '../models/Proposicao.Model';


export interface IComboItem {
    value: string,
    label: string
}
interface IProps {
    name: string,
    label?: string,
    placeholder?: string,
    helperText?: React.ReactNode,
    value?: string,
    defaultValue?: string,
    focused?: boolean,
    className?: string,
    inputValue?: string,
    style?: React.CSSProperties,
    menuStyle?: React.CSSProperties,
    itens?: Array<string>,
    startAdornment?: React.ReactNode,
    clear?: boolean,
    onChange?: (value: string) => void,
    onSelect?: (value: string) => void,
    onSubmit?: () => void,
    onKeyPress?: (value) => void
}

export default function ComboBoxSemSelect(props: IProps) {

    const classes = useStyles();
    const { pallet } = usePallet();
    const [value, setValue] = React.useState<string | null>(props.value || null);
    const [inputValue, setInputValue] = React.useState('');
    const navigate = useNavigate();

    const handleChange = (value: string | null) => {
        setInputValue(value);
        props?.onChange && props.onChange(value);
    };

    const handleKeyPress = async (e) => {
        props.onKeyPress && props.onKeyPress(e);
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="on"
            style={props?.style}
            >
            <div>
                <TextField
                    fullWidth
                    label={props.label ? props.label : null}
                    placeholder={props.placeholder}
                    variant='outlined'
                    onChange={(event) => handleChange(event?.target?.value)}
                    onKeyPress={(event) => handleKeyPress(event)}
                    defaultValue={props?.defaultValue}
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline
                        },
                        style: {
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_100,
                            background: `${pallet.backgroundColorSecondary}`,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                {props.startAdornment ? props.startAdornment : <SearchIcon />}
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        </Box>
    );
}
