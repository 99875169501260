import * as React from 'react';
import styled from 'styled-components';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { usePallet } from '../contexts/PalletContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Chip, Grid, Paper, Typography, Divider } from '@mui/material';
import { isTemplateTail } from 'typescript';
import { isMobile } from 'react-device-detect';
import { arraysEquals } from '../utils/array.utils';
import { number } from 'yup';


// const { pallet } = usePallet();

const GridContainer = styled(Grid)`
overflow-y:scroll; 
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 5px;
width: auto;
min-height: auto;
max-height: 250px;
`;

const StyledBox = styled.div`
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;    
`;

const Container = styled(Grid)`
    width: 100%;
`;

const Area = styled(Paper)`
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 4px;
    text-transform: capitalize;
    height: auto;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffff;
    box-shadow: none;
    align-content: center;
    
`;
const LabelNested = styled(Typography)`
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #2b29299d;
    background-color: #ffff;
    box-shadow: none;
    align-content: center;
    `;

const FilterTag = styled(Chip)`
                            
        max-width: ${isMobile ? "220px" : "100%"};
        font-size: 12px;
        font-weight: 600;
        background-color:${(props => props.status > 0 ? "#E0E0E0" : "rgba(224, 205, 248, 1)")};
        border: none;
        color:${(props => props.status > 0 ? "#9B9B9B" : "#4B1196")};
        padding: 10px;
        font-family: Montserrat;
        font-style: normal;        
        border-radius: 39px;
        justify-content: center;
        align-items: center;
        margin-left: 2px;
        margin-right: 5px;
        margin-top: 2px;
        margin-bottom: 5px;
`;

const TagContainer = styled(Grid)`
        padding-right: 10px;
`;
// export interface ICheckBoxNestedChild {
//     id: number,
//     label: string,
//     value: boolean
// }

export interface ICheckBoxNestedItemListaUnica {
    id: number,
    label: string,
    value: boolean
}

export interface ICheckBoxNestedItemChip {
    id: number,
    label: string,
}

const InputDropDownCheckBoxNestedChild = (props: 
        { item: ICheckBoxNestedItemListaUnica, 
            key:number,
            onChange?:(event: any,label:string ,ids: number) => void, 
            onChangeChip?: (event: any,label:string ,ids: number) => void,
            lockedItems?: Array<number> }) => {
    const [openDropDown, setOpenDropDown] = React.useState<boolean>(false);
    const { pallet } = usePallet();

  


    // const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const res = props.item.map(item => { return { id: item.id, label: item.label, value: event.target.checked } })
    //     props.onChange({ id: props.item.id, items: res })
    // };
    
    const [idCheck, setsetIdCHeck] = React.useState(props.item.id);
    const [chekedVal, setChekedVal] = React.useState(props.item.value);


    React.useEffect(() => {

        setChekedVal(props.item.value)
    }, [props.item])
    
    const handleChangeOne = (event: boolean,label?: string, id?: number) => {
        if (chekedVal === true) {
            setChekedVal(false)
        }else{
            setChekedVal(true)
            
        }

        props.onChange(event, label, id)
        props.onChangeChip(event,label,id)




    };

    const checkLocked = (id: number) => {
        if (!props.lockedItems) return false
        const items = props.lockedItems.filter(lock => lock === id)

        if (items.length > 0) return true
        return false
    }


    return (
        <>
            <Grid container alignItems={"baseline"} style={{ width: "100%" }}  >
                <Grid item style={{
                    width: '4px',
                    height: '45px',
                    paddingLeft: `${isMobile ? "10px " : "0px"}`
                }}>
                    <Button style={{ width: '5px', height: '10px', }} onClick={() => setOpenDropDown(!openDropDown)}>
                    </Button>
                </Grid>
                <Grid item style={{ width: "94%" }} >
                    <FormControlLabel sx={{ color: `${pallet.textColorTertiary}`, }}
                        label={`${props.item.label}`} //props.item.items.filter(i => !!i.value).length
                        control={
                            <Checkbox
                                sx={{
                                    color: `${pallet.textColorTertiary}`,
                                    marginRight: `${isMobile ? "15px " : "25px"}`,

                                }}
                                color={"default"}
                                // key={index}
                                key={props.key}
                                id={String(props.item.id)}
                                checked={chekedVal}
                                onChange={(e) => handleChangeOne(e.target.checked,props.item.label, props.item.id)}
                                // disabled={checkLocked(item.id)}
                            />}

                    />


                    {/* <Box sx={{
                        marginLeft: '50px',
                        display: 'flex',
                        textTransform: "capitalize",
                        flexFlow: "column",

                    }}
                    >
                        {openDropDown && props.item.items.map((item, index) =>
                            <FormControlLabel style={{ color: `${pallet.textColorQuaternary}` }}
                                key={index} label={item.label} control={
                                    <Checkbox
                                        sx={{ color: `${pallet.textColorTertiary}` }}
                                        color={"default"}
                                        key={index}
                                        id={String(item.id)}
                                        checked={item.value}
                                        onChange={handleChangeOne}
                                        disabled={checkLocked(item.id)}
                                    />}
                            />)}
                    </Box> */}


                    
                </Grid>
            </Grid>

        </>
    )
}

export function CheckBoxNestedListaUnicaFilter(props: {
    items: Array<ICheckBoxNestedItemListaUnica>,
    selectedItems?:Array<ICheckBoxNestedItemChip>,
    // lockedItems?: Array<number>,
    onChange: (event: any,label: string ,ids: number) => void,
    label?: string,
    editar?: boolean,
}):
    JSX.Element {
    const [openArea, setOpenArea] = React.useState<boolean>(false);
    const [parent, setParent] = React.useState<Array<ICheckBoxNestedItemListaUnica>>([]);
    const [selected, setSelected] = React.useState([{id: 0, label: ''}]);

    



    // const handleChange = React.useCallback(() => {
    //     if (props.onChange && parent.length > 0) {
    //         props.onChange({ list: parent, selected: selected })
    //     }

    // }, [selected])

    // React.useEffect(() => {
    //     handleChange();

    // }, [selected])

    // React.useEffect(() => {

    //     const lista = props.items.map(item => {

    //         return {
    //             id: item.id,
    //             label: item.label,
    //             value: item.value
    //     }});

    //     setParent(() => [...lista])


    // }, [props.selectedItems])



    // React.useEffect(() => {
    //     const array: number[] = []
    //     parent.map(item => {

    //             if (item.value)
    //                 array.push(item.id)

    //     })
    //     if (!arraysEquals(selected, array))
    //         setSelected(() => [...array])

    // }, [parent])

    React.useEffect(() => {
        
        if(props.selectedItems) {
          setSelected(props.selectedItems)
        }
        
        const itensTratados = props.items?.map((i) => {
            
            return {
                id: i.id,
                label: i.label,
                value: props?.selectedItems?.filter(p => p.id === i.id).length > 0 ? true : false
                
                
            }}
            
            )
            
            setParent(itensTratados)
            

    }, [props.items])




    const handleChangeChip = (event: any,label:string ,id: number) => {
        if (event === true) {
            selected.push({id,label})
        }
        else {
            selected.splice(selected.indexOf({id, label}), 1)
        }


        setSelected(() => [...selected])
        
    }



    const handleOnDelete = (id: number,label:string) => {

        selected.splice(selected.indexOf({id, label}), 1)
        props.onChange(false,label ,id)
        setSelected(() => [...selected])

        const res: Array<ICheckBoxNestedItemListaUnica> = props.items.map(i => {
            
            return {
                id: i.id,
                label: i.label,
                value: selected?.filter(p => p.id === i.id).length > 0 ? true : false
                
                
            }})

        setParent(() => [...res]);


    }
    // const handleOnChange = (data) => {
    //     const res = parent.map((item, index) => item.id === data.id ? { id: item.id, label: item.label, value: data.value } : item)
    //     setParent(() => [...res]);
    // }

    // const checkLocked = (id: number) => {
    //     if (!props.lockedItems) return false
    //     const items = props.lockedItems.filter(lock => lock === id)

    //     if (items.length > 0) return true
    //     return false
    // }


    return (
        <Container>
            <Area onClick={() => setOpenArea(!openArea)}>
            {selected?.filter(p => p.id != 0).map((item, index) => {
                     return ( 

                         
                         <TagContainer>
                            {/* CHIPS */}
                         {props.editar ?
                            <FilterTag
                                key={1}
                                label={item.label}
                                onDelete={() => handleOnDelete(item.id,item.label)}
                                // disabled={checkLocked(item.id)}
                            />
                            :

                            <FilterTag
                            key={1}
                            label={item.label}
 
                        />
                      }
                        </TagContainer>




                     ) 
                 })} 


            </Area>
            {props.editar ?
            <div >
                <GridContainer >
                    {openArea && parent?.map((item, index) =>
                        <InputDropDownCheckBoxNestedChild
                            key={index}
                            item={item}
                            onChange={props.onChange}
                            onChangeChip={handleChangeChip}
                            // lockedItems={props.lockedItems}
                        />
                    )}
                </GridContainer>
            </div>

            :
            ''        }
        </Container>

    )
}