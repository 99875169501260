
import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import ParlamentarTweetsList from "./ParlamentarTweetsList";

const ParlamentarCarouselTabsTwitter = (props: { twitterUsername: String, activeTab: any, index: number, loading?: boolean, permissaoUsuario?: number }) => {

    return (
        <div>
            <TabPanel value={props.activeTab} index={props.index}>
                <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "0px"}`, border: '1px solid white'}}>
                    <ParlamentarTweetsList username={props?.twitterUsername} />
                </Paper>
            </TabPanel>
        </div>
    )
}


export default ParlamentarCarouselTabsTwitter;