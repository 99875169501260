
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/system/Box';
import { usePallet } from '../contexts/PalletContext';
import styled from "styled-components";
import useStyles from '../components/layout/Styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export interface IComboItem {
    value: string,
    label: string
}
interface IProps {
    name: string,
    label?: string,
    placeholder?: string,
    helperText?: React.ReactNode,
    value?: string,
    focused?: boolean,
    className?: string,
    inputValue?: string,
    style?: React.CSSProperties,
    menuStyle?: React.CSSProperties,
    itens?: Array<string>,
    startAdornment?: React.ReactNode,
    clear?: boolean,
    onChange?: (value: string) => void,
    onSelect?: (value: string) => void,
    onSubmit?: () => void,
}

export default function ComboBox(props: IProps) {

    const classes = useStyles();
    const { pallet } = usePallet();
    const [value, setValue] = React.useState<string | null>(props.value || null);
    const [inputValue, setInputValue] = React.useState('');

    const handleChange = (value: string | null) => {
        setInputValue(value)
        props.onChange && props.onChange(value)
    };


    const handleSelect = (value: any) => {
        setValue(value)
        props.onSelect && props.onSelect(value)
    };
    return (
        <Box
            component="form"
            noValidate
            autoComplete="on"
        >
            <div>
                <Autocomplete
                    clearIcon={props.clear}
                    selectOnFocus
                    disablePortal
                    id={`combo-box-${props.name}`}
                    fullWidth
                    noOptionsText
                    clearOnBlur
                    value={value}
                    inputValue={inputValue}
                    options={props.itens ? props.itens : []}
                    sx={{ width: '100%' }}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            label={props.label ? props.label : null}
                            placeholder={props.placeholder}
                            variant='outlined'
                            {...params}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                },
                                style: {
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontSize: `${pallet.general.size14}`,
                                    fontWeight: pallet.general.weight_100,
                                    background: `${pallet.backgroundColorSecondary}`
                                },
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {props.startAdornment ? props.startAdornment : <SearchIcon />}
                                    </InputAdornment>
                                )
                            }}
                        />
                    }
                    onInputChange={(event, newInputValue: string, clear) => { handleChange(newInputValue); }}
                    onChange={(event: any, newValue: string | null) => { handleSelect(newValue) }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            // Previne o comportamento padrão do 'Enter'.
                            event.preventDefault();
                            if (props.onSubmit) props.onSubmit()
                        }
                    }
                    }
                />
            </div>
        </Box>
    );
}