import * as React from 'react';
import Button from '@mui/material/Button';
import { usePallet } from '../contexts/PalletContext';


export default function ButtonOutLine(props: { onClick(): void }) {
  const { pallet } = usePallet();


  return (
    <Button style={{
      backgroundColor: `${pallet.backGroundPageColorSecondary}`,
      color: `${pallet.textColorSecondary}`,
      border: `1px solid ${pallet.textColorSecondary}`,
      fontWeight: pallet.general.weight_600,
      padding: "10px 20px 10px 20px",
      fontFamily: `${pallet.general.fontFamily}`,
    }} variant="outlined" onClick={props.onClick}>
      VOLTAR
    </Button>
  );
}