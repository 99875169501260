import * as Yup from "yup";

const TCCadastroSubtemasCorporativoSchema = Yup.object({
    descricao: Yup.string().required({ name: "nome", err: "Nome é obrigatório" }),
    ativo: Yup.bool().required({ name: "status", err: "Status é obrigatório" }),
    id_tema: Yup.number().typeError({ name: "tema", err: "Tema é obrigatório" }).required({ name: "tema", err: "Tema é obrigatório" }),
    // id_prioridade: Yup.number().typeError({ name: "prioridade", err: "prioridade é obrigatória" }).required({ name: "prioridade", err: "prioridade é obrigatória" }),
    id_subtema: Yup.number().optional(),
});

export default TCCadastroSubtemasCorporativoSchema;
