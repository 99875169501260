import ButtonCount from '../../../components/ButtonCount';
import { usePallet } from '../../../contexts/PalletContext';
import ButtonCountPendencia from './ButtonCountPendencia';

export interface IFiltroNotificacaoProps {
    key: string,
    label: string,
    active: boolean,
}

export function NotificacoesListPendencia(props: {
    filtros: IFiltroNotificacaoProps[],
    totalMinhaInt?: number,
    totalDeptInt?: number,
    totalMinhaProp?: number,
    totalDeptProp?: number,
    activeTab: number,
    onChange: (event: any) => void,
}): JSX.Element {
    const { pallet } = usePallet();


    let totalMinha 
    let totalDept 
    
    if (props.activeTab === 0) {
        totalMinha =  props.totalMinhaProp
        totalDept =  props.totalDeptProp
    } else if(props.activeTab === 1) {
        totalMinha =  props.totalMinhaInt
        totalDept =  props.totalDeptInt
    }

    return (
        <>
            {props.filtros?.map((item,i) => {
                return (
                    <>
                        <ButtonCountPendencia
                            item={item}
                            active={item.active}
                            total={ i === 0 ? totalMinha : totalDept }
                            onClick={(e) => props.onChange(e)}
                            style={{
                                backgroundColor: `${item.active ? pallet.textColorQuintenary : "#FFFFFF"}`,
                                //flexDirection: "row-reverse",
                                //justifyContent: "space-between"    
                            }}
                        />
                     
                    </>
                )
            })}
        </>
    )
}


export default NotificacoesListPendencia;