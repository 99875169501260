import * as http from '../helpers/http';

class FavoritosService {
    getFavoritos = async (tipo:string, pageIndex: number, pageSize: number = 8, filter?: any): Promise<any> => {
        const result = await http.get(`/favoritos?pageSize=${pageSize}&pageIndex=${pageIndex}&tipo=${tipo}&filter=${filter}`);
        if (result.success) { return result.data }
        return undefined;
    };


    getFavoritoUnico = async (id:number, modulo: string): Promise<any> => {
        const result = await http.get(`/favoritos/unico/${id}/${modulo}`);
        if (result.success) { return result.data }
        return undefined;
    };

    getFavoritadosInterlocutor = async (word: string, data: any): Promise<any> => {
        const result = await http.post(`/interlocutor/favoritados-interlocutor`, { texto: word, data });
        if (result.success) { return result.data }
        return undefined;
    };


    Create = async (data: any): Promise<number> => {
        const result = await http.post(`/favoritos`, data);
        if (result.success) { return result.data }
        return 0;
    };

    Delete = async (item: any, id?: number, origin?: boolean, tipo?: String): Promise<boolean> => {
        const data = {
            id_modulo: item,
            id: id,
            tipo: tipo
        };

        if(origin){
            const result = await http.post(`/favoritos/deletarTwitter`, item);
            if (result.success) { return result.success }
            return false;
        }else{
            const result = await http.post(`/favoritos/deletar`, data);
            if (result.success) { return result.success }
            return false;
        };
    };
};

export default new FavoritosService();
