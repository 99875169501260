import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import EmptyFavoriteImage from '../img/EmptyFavorite.png';
import { usePallet } from '../contexts/PalletContext';
import { isMobile } from "react-device-detect";
import StarBorderIcon from '@mui/icons-material/StarBorder';

function EmptyFavorite(props: { title: string, description?: string, subdescription?: string }) {
    const { pallet } = usePallet();
    return (
      <>
    <Grid style={{width:"100%",display: "flex",justifyContent:'center',padding:"50px"}}>
        <Grid>
            <img width={isMobile ? "300px" : "auto"} src={EmptyFavoriteImage}/>
            <Grid style={{alignContent:"center",textAlign: "center"}}>
                <h2><Grid style={{fontWeight:pallet.general.weight_600}}> {props.title?props.title:""}</Grid></h2>
                <Grid >
                    <Grid style={{textAlign: "center",display:"inline-block"}}>{props.description?props.description:""}</Grid>
                    <Grid style={{display:"inline-block"}}><StarBorderIcon sx={{color: `${pallet.general.color2}`}} fontSize="small"/></Grid><Grid style={{display:"inline-block"}}> {props.subdescription?props.subdescription:""}</Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    </>
    );
  
  }
  
  export default EmptyFavorite;

