import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { usePallet } from '../../contexts/PalletContext';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { Button, TextField, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { faRecordVinyl } from '@fortawesome/free-solid-svg-icons';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import ClearIcon from '@mui/icons-material/Clear';
import { useAuthorization } from "../../contexts/AuthorizationContext";
import eventBus from '../../services/EventBus';




const FilterRightDrawer = forwardRef((props: {
    children: React.ReactNode,
    open: boolean,
    toogle: () => void,
    customEvent?: () => void,
    filtrarAction?: () => void,
    filter?: any,
    saveFilter?: (string) => void,
    limparFiltroSalvo?: () => void,
    ocultarBotaoSalvar?: boolean,
}) => {
    const { getConfig } = useAuthorization();
    const { pallet } = usePallet();
    const [statusSalvarFiltro, setStatusSalvarFiltro] = useState(false);
    const [tituloValue, setTituloValue] = useState('');
    const [blockToTilter, setBlockToFilter] = useState(false);

    const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
        props.toogle()
    };

    const changeTitulo = (event) => {
        setTituloValue(event.target.value);
    }

    const blockFunctionTrue = () => {
        setBlockToFilter(true);
    };

    const blockFunctionFalse = () => {
        setBlockToFilter(false);
    };

    // Emissão do evento para ativar as funcoes de block do botao filtrar 
    eventBus.emit('setBlockFilterFuncTrue', blockFunctionTrue);
    eventBus.emit('setBlockFilterFuncFalse', blockFunctionFalse);
    return (
        <div>
            <React.Fragment key={'right'}>
                <SwipeableDrawer
                    anchor={'right'}
                    open={props.open}
                    onClose={toggleDrawer()}
                    onOpen={toggleDrawer()}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: props.open ? 'relative' : 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1000, // Ajuste conforme necessário
                            backgroundColor: props.open ? 'transparent' : '#fff',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        {statusSalvarFiltro
                            ?
                            <Slide direction="down" in={statusSalvarFiltro} timeout={300}>
                                <Grid container
                                    style={{
                                        padding: '15px',
                                    }}
                                >
                                    <Grid xl={7} lg={7}>
                                        <TextField
                                            fullWidth
                                            variant='outlined'
                                            id={`titulo_filtro_proposicao_lista`}
                                            label={'Título do Filtro'}
                                            value={tituloValue}
                                            onChange={(e) => changeTitulo(e)}
                                            style={{ width: '99%', backgroundColor: 'white', fontFamily: pallet.general.fontFamily }}
                                        />
                                    </Grid>
                                    <Grid xl={5} lg={5}>
                                        <Grid container>
                                            <Grid style={{ margin: '0px 2px 0px 2px' }}>
                                                <Button disabled={tituloValue !== '' ? false : true}
                                                    style={{
                                                        backgroundColor: `${tituloValue !== '' ? pallet.backgroundColorQuintenary : '#e1e1e1'}`,
                                                        color: `${tituloValue !== '' ? pallet.general.color1 : 'grey'}`,
                                                        fontWeight: pallet.general.weight_500,
                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                        opacity: props?.filter.length === 0 ? 0.6 : 1.0,
                                                        cursor: props?.filter.length === 0 ? 'not-allowed' : 'pointer',
                                                        fontSize: pallet.general.size12,
                                                        height: '55px',
                                                    }}
                                                    onClick={() => props.saveFilter(tituloValue)}>
                                                    <CheckIcon style={{ fontSize: '22px' }} />
                                                </Button>
                                            </Grid>
                                            <Grid style={{ margin: '0px 2px 0px 2px' }}>
                                                <Button disabled={props?.filter.length === 0 ? true : false} style={{
                                                    backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                    color: `${pallet.general.color1}`,
                                                    fontWeight: pallet.general.weight_500,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    opacity: props?.filter.length === 0 ? 0.6 : 1.0,
                                                    cursor: props?.filter.length === 0 ? 'not-allowed' : 'pointer',
                                                    fontSize: pallet.general.size12,
                                                    height: '55px'
                                                }}
                                                    onClick={() => setStatusSalvarFiltro(false)}
                                                >
                                                    <UndoIcon style={{ fontSize: '22px' }} />
                                                </Button>
                                            </Grid>
                                            <Grid style={{ margin: '0px 0px 0px 2px' }}>
                                                <Button disabled={props?.filter.length === 0 ? true : false} style={{
                                                    backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                    color: `${pallet.general.color1}`,
                                                    fontWeight: pallet.general.weight_500,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    opacity: props?.filter.length === 0 ? 0.6 : 1.0,
                                                    cursor: props?.filter.length === 0 ? 'not-allowed' : 'pointer',
                                                    fontSize: pallet.general.size12,
                                                    height: '55px'
                                                }}
                                                    onClick={toggleDrawer()}
                                                >
                                                    <ClearIcon style={{ fontSize: '22px' }} />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Slide>
                            :
                            null
                        }

                        {!statusSalvarFiltro
                            ?
                            <Slide direction="down" in={!statusSalvarFiltro} timeout={300}>
                                <Grid container
                                    style={{
                                        padding: '15px',
                                    }}
                                    justifyContent={'space-between'}
                                >
                                    <Grid>
                                        <Button disabled={props?.filter.length === 0 ? true : false} style={{
                                            backgroundColor: `${pallet.general.color3}`,
                                            color: `${pallet.general.color1}`,
                                            fontWeight: pallet.general.weight_500,
                                            padding: "10px 20px 10px 20px",
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            opacity: props?.filter.length === 0 ? 0.6 : 1.0,
                                            cursor: props?.filter.length === 0 ? 'not-allowed' : 'pointer',
                                            fontSize: pallet.general.size12,
                                        }}
                                            onClick={() => props.customEvent ? (props.customEvent(), props.limparFiltroSalvo()) : true}>
                                            <FontAwesomeIcon style={{ marginTop: '3px', fontSize: '18px', marginRight: '10px' }} icon={faEraser} />
                                            Limpar
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        <Button disabled={props?.filter.length === 0 ? true : false} style={{
                                            backgroundColor: `${pallet.general.color2}`,
                                            color: `${pallet.general.color3}`,
                                            fontWeight: pallet.general.weight_500,
                                            padding: "10px 20px 10px 20px",
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            opacity: props?.filter.length === 0 || blockToTilter ? 0.6 : !blockToTilter ? 1.0 : 0.6,
                                            cursor: props?.filter.length === 0 ? 'not-allowed' : 'pointer',
                                            fontSize: pallet.general.size12,
                                        }}
                                            onClick={() => {
                                                if (!blockToTilter) {
                                                    props.filtrarAction();
                                                    props.toogle();
                                                }
                                            }}>
                                            <FontAwesomeIcon style={{ marginTop: '3px', fontSize: '18px', marginRight: '10px' }} icon={faSlidersH} />
                                            Filtrar
                                        </Button>
                                    </Grid>
                                    {getConfig()?.habPropFiltroMult
                                        ?
                                        !props.ocultarBotaoSalvar ?
                                            <Grid>
                                                <Button disabled={props?.filter.length === 0 ? true : false} style={{
                                                    backgroundColor: `${pallet.backgroundColorQuintenary}`,
                                                    color: `${pallet.general.color1}`,
                                                    fontWeight: pallet.general.weight_500,
                                                    padding: "10px 20px 10px 20px",
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    opacity: props?.filter.length === 0 ? 0.6 : 1.0,
                                                    cursor: props?.filter.length === 0 ? 'not-allowed' : 'pointer',
                                                    fontSize: pallet.general.size12,
                                                }}
                                                    onClick={() => setStatusSalvarFiltro(true)}>
                                                    <FontAwesomeIcon style={{ marginTop: '3px', fontSize: '18px', marginRight: '10px' }} icon={faRecordVinyl} />
                                                    Salvar Filtro
                                                </Button>
                                            </Grid>
                                            :
                                            null
                                        :
                                        null
                                    }
                                    <Grid>
                                        <IconButton
                                            aria-label="close"
                                            onClick={toggleDrawer()}
                                            sx={{
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                            style={{ marginRight: "15px" }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Slide>
                            :
                            null
                        }

                    </div>

                    <Grid style={{
                        // pra quem for alterar o style, o 100vh equivale 100% da altura da janela do navegador... Se altura for 700px, 100vh = 700px.
                        height: 'calc(100vh - 80px)',
                        overflowY: 'auto',
                    }}>
                        {props.children}
                    </Grid>

                </SwipeableDrawer>
            </React.Fragment>
        </div >
    );
});
export default FilterRightDrawer;