import React, { useState } from "react";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import Typography from "@material-ui/core/Typography";
import CalendarOutlineIcon from "@mui/icons-material/CalendarTodayOutlined";
import {
  Box,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import { isMobile } from "react-device-detect";

import NotificationsIcon from "@mui/icons-material/Notifications";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import IndicadoresImpactoFinanceiro from "./_components/IndicadoresImpactoFinanceiro";
import NotificacaoPanelPendencia from "./_components/NotificacaoPanelPendencia";
import { IFiltroNotificacaoProps } from "./_components/NotificacoesList";
import IndicatoresPrioridade from "./_components/IndicadoresPrioridade";
import SummaryPanelWidget from "./_components/SummaryPanelWidget";
import NotificacaoPanel from "./_components/NotificacaoPanel";

import DateRangeSelector from "../../components/DateRangeSelector";
import { IFilterData } from "../../components/filter/ListFilter";
import PieChartInfoCard from "../../components/PieChartInfoCard";
import useStyles from "../../components/layout/Styles";
import Copyright from "../../components/Copyright";
import InfoCard from "../../components/InfoCard";

import { useAuthorization } from "../../contexts/AuthorizationContext";
import { useNotifications } from "../../contexts/NotificationsContext";
import { useDashboard } from "../../contexts/DashboardContext";
import { usePallet } from "../../contexts/PalletContext";

import { INotificationsContextResultData } from "../../services/NotificationsContextService";
import ProposicaoService from "../../services/ProposicaoService";

import filtersBackgroundImage from "../../../src/img/background_filters_dashboard.png";

import { formatDate } from "../../utils/dateUtils";

import styled from "styled-components";

import moment from 'moment-timezone';
import { subYears } from "date-fns";

const FloatMenu = styled(Grid)`
  display: flex;
  flex-direction: column;
  position: absolute;
  /* left: 7%; */
  /* top: 40%; */
  bottom: 10px;
  width: min-content;
  z-index: 2;

  @media (min-width: 1080px) {
    left: 7%;
  }

  @media (min-width: 1860px) {
    left: 17%;
  }

  .icon-button {
    color: #4b1196;
    background-color: #ded3ff;
    width: 40px;
    margin: 5px 0;
    border-radius: 6px;
  }
`;


const HeaderPaper = styled(Paper)`
  background-size: cover;
  /* background-position: center; */
  justify-content: center;
  height: 80px;
  padding: 0 15px;
`;


const HeaderContentPaper = styled(Paper)`
  justify-content: center;
  padding: 15px;
  margin-bottom: 15px;
`;


const HeaderText = styled(Typography)`
  font-weight: ${(props) => props.pallet.fontWeight.subtitle};
  font-size: ${(props) => props.pallet.fontSize.titleWidgets};
  font-family: ${(props) => props.pallet.fontFamily.general};
  color: ${(props) => props.pallet.color.colorBase.greyText};
`;


const TitleText = styled(Typography)`
  color: ${(props) => props.pallet.textColorQuaternary};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;


const PaperContent = styled(Paper)`
  margin-bottom: 20px;

  .customScroll {
    /* width */
    &::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f2f2f2;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #4b1196;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4b1196;
    }
  }
`;


export default function Workspace() {
  const [listNotification, setListNotification] =
    React.useState<Array<INotificationsContextResultData>>();
  const [filtrosNotificacao, setFiltrosNotificacao] = React.useState<
    Array<IFiltroNotificacaoProps>
  >([
    { key: "todos", label: "TODOS", total: 0, active: true },
    { key: "PROPOSIÇÃO", label: "PROPOSIÇÃO", total: 0, active: false },
    { key: "interlocutor", label: "INTERLOCUTORES", total: 0, active: false },
    { key: "agenda", label: "AGENDA", total: 0, active: false },
    { key: "CITAÇÕES", label: "CITAÇÕES", total: 0, active: false },
    { key: "TWITTER", label: "TWITTER", total: 0, active: false }
  ]);
  const [listPendencia, setListPendencia] = React.useState<Array<INotificationsContextResultData>>();
  const [listPendenciaDepartamento, setListPendenciaDepartamento] = React.useState<Array<INotificationsContextResultData>>();
  const [filtrosPendencias, setFiltrosPendencias] = React.useState<
    Array<IFiltroNotificacaoProps>
  >([
    { key: "minhas", label: "MINHAS", total: 0, active: true },
    { key: "departamento", label: "UNIDADE DE NEGÓCIO", total: 0, active: false }
  ]);
  const [nomeAba, setNomeAba] = useState("todos");
  const [limparCheck, setLimparCheck] = useState(false);
  const [chave, setChave] = useState(true);
  const [dtIni, setDtIni] = React.useState<Date>(moment().subtract(1, 'months').toDate());
  const [dtFin, setDtFin] = React.useState<Date>(moment().toDate());
  const [filtro, setFiltro] = useState<string>('TODOS');
  const [filtroTitulo, setFiltroTitulo] = useState<string>('MINHAS');
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);
  const [parSegTot, setParSegTot] = useState(0)

  const { setPeriod } = useDashboard();

  const [EsfSel, setEsfSel] = useState<string>("F");
  const notifications = useNotifications();
  const { hasAccess } = useAuthorization();
  const { user } = useAuthorization();
  const { pallet } = usePallet();
  const classes = useStyles();

  const [sumaryFedUN, setSumaryFedUN] = useState({
    ativas: 0,
    inativas: 0,
  });

  const [sumaryEstUN, setSumaryEstUN] = useState({
    ativas: 0,
    inativas: 0,
  });

  const [sumaryMunUN, setSumaryMunUN] = useState({
    ativas: 0,
    inativas: 0,
  });

  const [sumaryPendencia, setSumaryPendencia] = useState({
    minhas: 0,
    departamento: 0,
  });

  const [sumaryFedMC, setSumaryFedMC] = useState({
    designacaoRelator: 0,
    aguardanoParecerDoRelator: 0,
    prazoParaEmenda: 0,
    aguardaDeliberacao: 0,
  });

  const [sumaryEstMC, setSumaryEstMC] = useState({
    designacaoRelator: 0,
    aguardanoParecerDoRelator: 0,
    prazoParaEmenda: 0,
    aguardaDeliberacao: 0,
  });

  const [sumaryMunMC, setSumaryMunMC] = useState({
    designacaoRelator: 0,
    aguardanoParecerDoRelator: 0,
    prazoParaEmenda: 0,
    aguardaDeliberacao: 0,
  });

  const [sumaryFedPS, setSumaryFedPS] = useState({
    contrario: 0,
    contrarioComEmenda: 0,
    favoravel: 0,
    favoravelParecerRelator: 0,
    favoravelParecerRelatorEmenda: 0,
    contrarioParecerRelator: 0,
    contrarioParecerRelatorEmenda: 0,
    favoravelComEmenda: 0,
    neutro: 0,
    pendente: 0,
    indefinido: 0,
  });

  const [sumaryEstPS, setSumaryEstPS] = useState({
    contrario: 0,
    contrarioComEmenda: 0,
    favoravel: 0,
    favoravelParecerRelator: 0,
    favoravelParecerRelatorEmenda: 0,
    contrarioParecerRelator: 0,
    contrarioParecerRelatorEmenda: 0,
    favoravelComEmenda: 0,
    neutro: 0,
    pendente: 0,
    indefinido: 0,

  });

  const [sumaryMunPS, setSumaryMunPS] = useState({
    contrario: 0,
    contrarioComEmenda: 0,
    favoravel: 0,
    favoravelParecerRelator: 0,
    favoravelParecerRelatorEmenda: 0,
    contrarioParecerRelator: 0,
    contrarioParecerRelatorEmenda: 0,
    favoravelComEmenda: 0,
    neutro: 0,
    pendente: 0,
    indefinido: 0,

  });

  const [sumaryFedQI, setSumaryFedQI] = useState({
    convergente: 0,
    divergente: 0,
    naoClassificado: 0,
    neutro: 0,
  });

  const [sumaryEstQI, setSumaryEstQI] = useState({
    convergente: 0,
    divergente: 0,
    naoClassificado: 0,
    neutro: 0,
  });

  const [sumaryMunQI, setSumaryMunQI] = useState({
    convergente: 0,
    divergente: 0,
    naoClassificado: 0,
    neutro: 0,
  });

  const [sumaryFedCA, setSumaryFedCA] = useState({
    proposicoesCamara: 0,
    proposicoesCongresso: 0,
    proposicoesSenado: 0,
  });

  const [sumaryFedAI, setSumaryFedAI] = useState({
    exito: 0,
    exitoParcial: 0,
    semExito: 0,
    pendente: 0,
  });

  const [sumaryEstAI, setSumaryEstAI] = useState({
    exito: 0,
    exitoParcial: 0,
    semExito: 0,
    pendente: 0,
  });

  const [sumaryMunAI, setSumaryMunAI] = useState({
    exito: 0,
    exitoParcial: 0,
    semExito: 0,
    pendente: 0,
    semPrioridade: 0

  });

  const [sumaryFedPR, setSumaryFedPR] = useState({
    minima: 0,
    baixa: 0,
    media: 0,
    alta: 0,
    maxima: 0,
    semPrioridade: 0
  });

  const [sumaryEstPR, setSumaryEstPR] = useState({
    minima: 0,
    baixa: 0,
    media: 0,
    alta: 0,
    maxima: 0,
    semPrioridade: 0
  });

  const [sumaryMunPR, setSumaryMunPR] = useState({
    minima: 0,
    baixa: 0,
    media: 0,
    alta: 0,
    maxima: 0,
    semPrioridade: 0
  });

  const [sumaryFedPRTO, setSumaryFedPRTO] = useState({
    minima: 0,
    baixa: 0,
    media: 0,
    alta: 0,
    maxima: 0,
    semPrioridade: 0
  });

  const [sumaryEstPRTO, setSumaryEstPRTO] = useState({
    minima: 0,
    baixa: 0,
    media: 0,
    alta: 0,
    maxima: 0,
    semPrioridade: 0
  });

  const [sumaryMunPRTO, setSumaryMunPRTO] = useState({
    minima: 0,
    baixa: 0,
    media: 0,
    alta: 0,
    maxima: 0,
    semPrioridade: 0
  });

  const [totalMinhaInt, setTotalMinhaInt] = useState(0)
  const [totalMinhaProp, setTotalMinhaProp] = useState(0)
  const [totalDeptInt, setTotalDeptInt] = useState(0)
  const [totalDeptProp, setTotalDeptProp] = useState(0)

  const [sumaryFedIM, setSumaryFedIM] = useState({
    neutro: 0,
    positivo: 0,
    negativo: 0,
    naoMensuravel: 0
  });

  const [sumaryEstIM, setSumaryEstIM] = useState({
    neutro: 0,
    positivo: 0,
    negativo: 0,
    naoMensuravel: 0
  });

  const [sumaryMunIM, setSumaryMunIM] = useState({
    neutro: 0,
    positivo: 0,
    negativo: 0,
    naoMensuravel: 0
  });

  const primeiroLoadNotificacoes = (pagina?: number) => {
    // notifications?.getData(pagina, currentFilter)
  };

  if (chave) { primeiroLoadNotificacoes() };

  const reloadNotificacoes = async (pagina?: number) => {
    if (nomeAba === 'todos') {
      // await notifications?.getData(pagina, [{ key: 'modulo', label: undefined, value: 'TODOS' }])
      setChave(false);
    } else if (nomeAba === 'proposição') {
      // await notifications?.getData(pagina, [{ key: 'modulo', label: undefined, value: 'PROPOSIÇÃO' }])
      setChave(false);
    } else if (nomeAba === 'interlocutor') {
      // await notifications?.getData(pagina, [{ key: 'modulo', label: undefined, value: 'INTERLOCUTOR' }])
      setChave(false);
    } else if (nomeAba === 'agenda') {
      // await notifications?.getData(pagina, [{ key: 'modulo', label: undefined, value: 'AGENDA' }])
      setChave(false);
    } else if (nomeAba === 'citações') {
      // await notifications?.getData(pagina, [{ key: 'modulo', label: undefined, value: 'CITAÇÕES' }])
      setChave(false);
    } else if (nomeAba === 'twitter') {
      // await notifications?.getData(pagina, [{ key: 'modulo', label: undefined, value: 'TWITTER' }])
      setChave(false);
    }
  };

  const reloadNotificacoesPendencias = () => {
    notifications.getDataPendencias()
    notifications.getDataPendenciasDepartamento()
  };

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleChange =
    (panel: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? true : false);
    };

  const scrollToElement = (id_element: string) => {
    const offset = 75; //Tamanho do meno superior
    const bodyRect = document.querySelector("main").scrollTop;
    const elementRect =
      document.getElementById(id_element).getBoundingClientRect().top - offset;
    const elementPosition = bodyRect - elementRect * -1;

    document.querySelector("main").scrollTo({
      top: elementPosition,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    setChave(false);

    setTotalMinhaInt(notifications?.notificacaoPendencies?.filter(itemFiltrado => itemFiltrado.modulo === "INTERLOCUTOR").length)
    setTotalMinhaProp(notifications?.notificacaoPendencies?.filter(itemFiltrado => itemFiltrado.modulo === "PROPOSIÇÃO").length)
    setTotalDeptInt(notifications?.notificacaoPendenciesDepartament?.filter(itemFiltrado => itemFiltrado.modulo === "INTERLOCUTOR").length)
    setTotalDeptProp(notifications?.notificacaoPendenciesDepartament?.filter(itemFiltrado => itemFiltrado.modulo === "PROPOSIÇÃO").length)

    setListNotification(notifications?.newNotifications?.data);
    setListPendencia(filtroTitulo === 'MINHAS' ? notifications?.notificacaoPendencies : notifications?.notificacaoPendenciesDepartament);
    setListPendenciaDepartamento(notifications?.notificacaoPendenciesDepartament)
    setFiltrosNotificacao([
      {
        key: "todos",
        label: "TODOS",
        total: notifications?.totalNotificacoesTodas,
        active: filtro === 'TODOS' ? true : false,
      },
      {
        key: "PROPOSIÇÃO",
        label: "PROPOSIÇÃO",
        total: notifications?.totalNotificacoesProposicao,
        active: filtro === 'PROPOSIÇÃO' ? true : false,
      },
      {
        key: "interlocutor",
        label: "INTERLOCUTOR",
        total: notifications?.totalNotificacoesInterlocutor,
        active: filtro === 'INTERLOCUTOR' ? true : false,
      },
      {
        key: "agenda",
        label: "AGENDA",
        total: notifications?.totalNotificacoesAgenda,
        active: filtro === 'AGENDA' ? true : false,
      },
      {
        key: "CITAÇÕES",
        label: "CITAÇÕES",
        total: notifications?.totalNotificacoesCitacoes,
        active: filtro === 'CITAÇÕES' ? true : false,
      },
      {
        key: "TWITTER",
        label: "TWITTER",
        total: notifications?.totalNotificacoesTwitter,
        active: filtro === 'TWITTER' ? true : false,
      },
    ]);
    setFiltrosPendencias([
      {
        key: "minhas",
        label: "MINHAS",
        total: notifications?.notificacaoPendencies?.length,
        active: filtroTitulo === 'MINHAS' ? true : false,
      },
      {
        key: "departamento",
        label: "UNIDADE DE NEGÓCIO",
        total: notifications?.notificacaoPendenciesDepartament?.length,
        active: filtroTitulo === 'UNIDADE DE NEGÓCIO' ? true : false,
      },
    ]);
  }, [notifications]);

  React.useEffect(() => {
    dateRangeSet(subYears(new Date(), 1), new Date());
    setChave(false);
  }, []);


  React.useEffect(() => {
    setChave(false);
    reloadNotificacoes();
  }, [currentFilter]);

  const dateRangeSet = (startDate: Date, endDate: Date) => {
    if (endDate !== null) {
      setPeriod(startDate, endDate);
    };
  };

  const handleEsfera = (event, newSelEsf) => {
    setEsfSel(newSelEsf);
    //setCasa("Todas")
    //setUf("0")
    //setMunicipio("0")
  };

  const onChangeNotificationFilter = async (event: any) => {
    setLimparCheck(true)
    setNomeAba(event.target.title.toLowerCase());
    setFiltrosNotificacao(
      filtrosNotificacao.map((note) => {
        if (note.key.toLowerCase() === event.target.title.toLowerCase())
          note.active = true;
        else if (note.active) note.active = false;
        return note;
      })
    );
    setTimeout(() => {
      setLimparCheck(false)
    }, 500);

    let newFilter = currentFilter.filter(filtro => filtro.key !== 'modulo');
    newFilter.push({ key: 'modulo', label: event.target.key, value: event.target.title })
    setFiltro(event.target.title)
    setCurrentFilter(newFilter)
  };

  const onChangePendenciasFilter = (event: any) => {
    setFiltrosPendencias(
      filtrosPendencias.map((note) => {
        if (note.label.toLowerCase() === event.target.title.toLowerCase()) {
          note.active = true;
          setFiltroTitulo(event.target.title);
        } else if (note.active) note.active = false;
        return note;
      })
    );

    setListPendencia(
      event.target.title === "MINHAS"
        ? notifications.notificacaoPendencies
        : event.target.title === "UNIDADE DE NEGÓCIO" ? notifications?.notificacaoPendenciesDepartament
          :
          null
    );
  };

  const removeNotificacao = async (id_notificacao: number) => {
    setListNotification(listNotification.filter((x) => x.id !== id_notificacao));
    await primeiroLoadNotificacoes()
  };

  const onChangeDate = (dtIni: Date, dtFim: Date) => {
    setDtIni(dtIni);
    setDtFin(dtFin);
  };

  /*
    Carregar Gráficos Dados Gerais
  */
  React.useEffect(() => {
    setTimeout(() => {
      ProposicaoService.GetSummaryWorkSpaceSeguidosPendencias(dtIni, dtFin, [
        { key: "esfera", value: "Federal" },
        { key: "legislatura", value: 57 }
      ]).then((res) => {
        setSumaryPendencia(res?.pendencias);
        setParSegTot(res?.parlamentaresSeguidos);
      })
    }, 1000);

    if (expanded) {
      if (EsfSel === "F") {
        ProposicaoService.GetSummaryWorkSpaceDadosGerais(dtIni, dtFin, [
          { key: "esfera", value: "Federal" },
          { key: "legislatura", value: 57 }
        ]).then((res) => {
          if (res) {
            setSumaryFedUN(res.universo);
            setSumaryFedCA(res.casas);
            setSumaryFedAI(res.acoesInfluencia);
            setSumaryFedMC(res.momentosCriticos);
            setSumaryFedPS(res.posicionamento);
            setSumaryFedQI(res.qualificacaoParlamentar);
            setSumaryFedPR(res.prioridadeSeparada);
            setSumaryFedPRTO(res.prioridadeValor);
            setSumaryFedIM(res.impactoFinanceiro);
          }
        });
      } else if (EsfSel === "E") {
        ProposicaoService.GetSummaryWorkSpaceDadosGerais(dtIni, dtFin, [
          { key: "esfera", value: "Estadual" },
        ]).then((res) => {
          if (res) {
            setSumaryEstUN(res.universo);
            setSumaryEstAI(res.acoesInfluencia);
            setSumaryEstMC(res.momentosCriticos);
            setSumaryEstPS(res.posicionamento);
            setSumaryEstQI(res.qualificacaoParlamentar);
            setSumaryEstPR(res.prioridadeSeparada);
            setSumaryEstPRTO(res.prioridadeValor);
            setSumaryEstIM(res.impactoFinanceiro);
          }
        });
      } else {
        ProposicaoService.GetSummaryWorkSpaceDadosGerais(dtIni, dtFin, [
          { key: "esfera", value: "Municipal" },
        ]).then((res) => {
          if (res) {
            setSumaryMunUN(res.universo);
            setSumaryMunAI(res.acoesInfluencia);
            setSumaryMunMC(res.momentosCriticos);
            setSumaryMunPS(res.posicionamento);
            setSumaryMunQI(res.qualificacaoParlamentar);
            setSumaryMunPR(res.prioridadeSeparada);
            setSumaryMunPRTO(res.prioridadeValor);
            setSumaryMunIM(res.impactoFinanceiro);
          }
        });
      }
    }
  }, [EsfSel, dtIni, dtFin, expanded]);

  return !hasAccess("dashboard") ? (
    <></>
  ) : (
    <div>
      {/* <Main> */}
      <Grid>
        <FloatMenu container>
          <IconButton
            className={"icon-button"}
            onClick={() => {
              scrollToElement("notificacao");
            }}
          >
            <NotificationsIcon />
          </IconButton>
          <IconButton
            className={"icon-button"}
            onClick={() => {
              scrollToElement("pendencias");
            }}
          >
            <DoubleArrowIcon />
          </IconButton>
          <IconButton
            className={"icon-button"}
            onClick={() => {
              scrollToElement("dashboard");
            }}
          >
            <EqualizerIcon />
          </IconButton>
        </FloatMenu>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HeaderPaper
              className={classes.paper}
              style={{
                backgroundImage: `url(${filtersBackgroundImage})`,
              }}
            >
            </HeaderPaper>
            <HeaderContentPaper
              className={classes.paper}
              style={{
                textTransform: "uppercase",
              }}
            >
              <HeaderText pallet={pallet}>Bem Vindo,</HeaderText>
              <Grid item xs={12} key="TitleText">
                <TitleText pallet={pallet}>{user.nome}</TitleText>
              </Grid>
              <Grid
                container
                xs={12}
                style={{
                  marginTop: "10px",
                  flexWrap: user.id_permissao !== 4 ? "nowrap" : null,
                }}
              >
                {user.id_permissao !== 4 ?
                  <SummaryPanelWidget
                    title="INTERLOCUTORES SEGUIDOS"
                    items={[
                      {
                        value: parSegTot,
                        label: "Total",
                        link: '/interlocutor?filtro=[{"key": "seguindo", "label": "SEGUINDO: Sim", "value": "Sim"}]'
                      },
                    ]}
                  /> : null
                }
                <div style={{ width: "9%" }} />
                <SummaryPanelWidget
                  title="PENDÊNCIAS DE NOTA TÉCNICA"
                  items={[
                    {
                      value: sumaryPendencia?.minhas, label: "Minhas",
                      link: '/proposicao?filtro=[{"key":"pendenciasMinhas","label":"Pendências","value":"true"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true}]'
                    },
                    {
                      value: sumaryPendencia?.departamento,
                      label: "Unidade de negócio",
                      link: '/proposicao?filtro=[{"key":"pendenciasDepartamento","label":"Pendências da unidade de negócio","value":"true"}]'
                    },
                  ]}
                />
              </Grid>
            </HeaderContentPaper>
          </Grid>
          {user.id_permissao !== 4 ?
            <Grid item xs={12}>
              <NotificacaoPanel
                id={"notificacao"}
                title={"Notificações"}
                subtitle={"nos últimos 5 dias"}
                filters={filtrosNotificacao}
                onChange={onChangeNotificationFilter}
                list={listNotification}
                reloadList={reloadNotificacoes}
                mudaDePagina={notifications?.mudarPagina}
                filtro={"T"}
                removeNotificacao={removeNotificacao}
                limparCheck={limparCheck}
              />
            </Grid> : null
          }

          <Grid item xs={12}>
            <NotificacaoPanelPendencia
              id={"pendencias"}
              title={"Pendências"}
              filters={filtrosPendencias}
              onChange={onChangePendenciasFilter}
              list={listPendencia}
              totalMinhaInt={totalMinhaInt}
              totalDeptInt={totalDeptInt}
              totalMinhaProp={totalMinhaProp}
              totalDeptProp={totalDeptProp}
              reloadList={reloadNotificacoesPendencias}
            />
          </Grid>
          {user.id_permissao !== 4 ?
            <Grid item xs={12}>
              <PaperContent className={classes.paper}>
                <Accordion
                  expanded={expanded === true}
                  onChange={handleChange(true)}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                      height: "10px",
                      borderRadius: "5px",
                      paddingLeft: `${isMobile ? "5px" : "20px"}`,
                      paddingRight: `${isMobile ? "5px" : "20px"}`,
                      flexFlow: "row-reverse",
                    }}
                  >
                    <TitleText pallet={pallet}>
                      Dados Gerais
                    </TitleText>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      fontFamily: `${pallet.general.fontFamily}`,
                    }}
                  >
                    {expanded ?
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 17px 17px",
                          }}
                          id={"dashboard"}
                        >
                        </Grid>

                        {/* Inicio de fato Dados Gerais */}

                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                          <Grid container style={{ paddingLeft: "12px" }}>
                            <Grid
                              item
                              container
                              xs={12}
                              style={{
                                backgroundImage: `url(${filtersBackgroundImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                padding: "25px",
                                borderRadius: "5px",
                                boxShadow:
                                  "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                              }}
                            >
                              <Grid item>
                                <Grid style={{ marginBottom: "5px" }}>
                                  <Typography
                                    style={{
                                      color: `${pallet.color.secundary.font}`,
                                      fontSize: `${pallet.fontSize.titleWidgets}`,
                                      fontFamily: `${pallet.fontFamily.general}`,
                                      fontWeight: pallet.fontWeight.subtitle,
                                    }}
                                  >
                                    PERÍODO
                                  </Typography>
                                </Grid>

                                <Grid
                                  container
                                  alignItems="center"
                                  direction="row"
                                  style={{
                                    maxWidth: `${isMobile ? "100%" : "300px"}`,
                                    width: "250px",
                                    background: "#4B1196",
                                    borderRadius: "5px",
                                    justifyContent: `${isMobile ? "flex-start" : "center"
                                      }`,
                                  }}
                                >
                                  <Grid item>
                                    <CalendarOutlineIcon
                                      style={{
                                        color: `${pallet.color.primary.font}`,
                                        marginTop: "6px",
                                        fontSize: "17px",
                                        marginRight: "0px",
                                        marginLeft: `${isMobile ? "6px" : "5px"}`,
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      paddingRight: "10px",
                                      color: `${pallet.color.primary.font}`,
                                    }}
                                  >
                                    <DateRangeSelector
                                      startDate={dtIni}
                                      endDate={dtFin}
                                      onChange={onChangeDate}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  marginLeft: `${isMobile ? "0px" : "30px"}`,
                                  marginTop: `${isMobile ? "10px" : "0px"}`,
                                }}
                              >
                                <Grid style={{ marginBottom: "5px" }}>
                                  <Typography
                                    style={{
                                      color: `${pallet.color.secundary.font}`,
                                      fontSize: `${pallet.fontSize.titleWidgets}`,
                                      fontFamily: `${pallet.fontFamily.general}`,
                                      fontWeight: pallet.fontWeight.subtitle,
                                    }}
                                  >
                                    ESFERAS
                                  </Typography>
                                </Grid>

                                <Grid container alignItems="center" direction="row">
                                  <Grid item>
                                    <Box display="block">
                                      <Grid
                                        style={{
                                          backgroundColor: "#fcfcfc",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        <ToggleButtonGroup
                                          value={EsfSel}
                                          exclusive={true}
                                          onChange={handleEsfera}
                                          aria-label="sphere"
                                          size="small"
                                        >
                                          <ToggleButton
                                            value="F"
                                            aria-label="device"
                                            sx={{
                                              "&.Mui-selected": {
                                                color: `${pallet.textColorPrimary}`,
                                                backgroundColor: "#4B1196",
                                              },
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                fontSize: "14px",
                                                textTransform: "capitalize",
                                                paddingLeft: "4px",
                                                paddingRight: "4px",
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.general.weight_600,
                                              }}
                                            >
                                              Federal
                                            </Typography>
                                          </ToggleButton>

                                          <ToggleButton
                                            value="E"
                                            aria-label="device"
                                            sx={{
                                              "&.Mui-selected": {
                                                color: `${pallet.textColorPrimary}`,
                                                backgroundColor: `${pallet.backgroundColorPrimary}`,
                                              },
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                fontSize: "14px",
                                                textTransform: "capitalize",
                                                paddingLeft: "4px",
                                                paddingRight: "4px",
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.general.weight_600,
                                              }}
                                            >
                                              Estadual
                                            </Typography>
                                          </ToggleButton>

                                          <ToggleButton
                                            value="M"
                                            aria-label="device"
                                            sx={{
                                              "&.Mui-selected": {
                                                color: `${pallet.textColorPrimary}`,
                                                backgroundColor: `${pallet.backgroundColorPrimary}`,
                                              },
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                fontSize: "14px",
                                                textTransform: "capitalize",
                                                paddingLeft: "4px",
                                                paddingRight: "4px",
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.general.weight_600,
                                              }}
                                            >
                                              Municipal
                                            </Typography>
                                          </ToggleButton>
                                        </ToggleButtonGroup>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {user.id_permissao !== 4 ?
                            <Grid item xs={12} style={{ paddingTop: "40px" }}>
                              <Paper className={classes.paper}>
                                <InfoCard title={"IMPACTO FINANCEIRO"} />
                                <Grid item xs={12} key="IndicadoresImpactoFinanceiro">
                                  <IndicadoresImpactoFinanceiro
                                    linkPositivo={
                                      EsfSel === "F" ?
                                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                        formatDate(dtIni, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtIni, "MM/dd/yyyy") +
                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                        formatDate(dtFin, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtFin, "MM/dd/yyyy") +
                                        '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Positivo","value":2}]'
                                        : EsfSel === "E" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Positivo","value":2}]'
                                          :
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Positivo","value":2}]'
                                    }

                                    linkNegativo={
                                      EsfSel === "F" ?
                                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                        formatDate(dtIni, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtIni, "MM/dd/yyyy") +
                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                        formatDate(dtFin, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtFin, "MM/dd/yyyy") +
                                        '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Negativo","value":3}]'
                                        : EsfSel === "E" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Negativo","value":3}]'
                                          :
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Negativo","value":3}]'
                                    }
                                    // linkNaoMensuravel={
                                    //   EsfSel === "F" ?
                                    //     '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                    //     formatDate(dtIni, "dd/MM/yyyy") +
                                    //     '","value" : "' +
                                    //     formatDate(dtIni, "MM/dd/yyyy") +
                                    //     '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                    //     formatDate(dtFin, "dd/MM/yyyy") +
                                    //     '","value" : "' +
                                    //     formatDate(dtFin, "MM/dd/yyyy") +
                                    //     '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Não Mensurável","value":4}]'
                                    //     : EsfSel === "E" ?
                                    //       '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                    //       formatDate(dtIni, "dd/MM/yyyy") +
                                    //       '","value" : "' +
                                    //       formatDate(dtIni, "MM/dd/yyyy") +
                                    //       '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                    //       formatDate(dtFin, "dd/MM/yyyy") +
                                    //       '","value" : "' +
                                    //       formatDate(dtFin, "MM/dd/yyyy") +
                                    //       '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Não Mensurável","value":4}]'
                                    //       :
                                    //       '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                    //       formatDate(dtIni, "dd/MM/yyyy") +
                                    //       '","value" : "' +
                                    //       formatDate(dtIni, "MM/dd/yyyy") +
                                    //       '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                    //       formatDate(dtFin, "dd/MM/yyyy") +
                                    //       '","value" : "' +
                                    //       formatDate(dtFin, "MM/dd/yyyy") +
                                    //       '"},{"key":"impactoFinanceiro","label":"IMPACTO FINANCEIRO: Não Mensurável","value":4}]'
                                    // }

                                    valor_positivo={
                                      EsfSel === "F" ?
                                        sumaryFedIM?.positivo
                                        : EsfSel === "E" ?
                                          sumaryEstIM?.positivo
                                          :
                                          sumaryMunIM?.positivo
                                    }
                                    // valor_neutro={
                                    //   EsfSel === "F" ?
                                    //     sumaryFedIM?.neutro
                                    //     : EsfSel === "E" ?
                                    //       sumaryEstIM?.neutro
                                    //       :
                                    //       sumaryEstIM?.neutro
                                    // }
                                    valor_negativo={
                                      EsfSel === "F" ?
                                        sumaryFedIM?.negativo
                                        : EsfSel === "E" ?
                                          sumaryEstIM?.negativo
                                          :
                                          sumaryMunIM?.negativo
                                    }
                                  // valor_nao_mensuravel={
                                  //   EsfSel === "F" ?
                                  //     sumaryFedIM?.naoMensuravel
                                  //     : EsfSel === "E" ?
                                  //       sumaryEstIM?.naoMensuravel
                                  //       :
                                  //       sumaryMunIM?.naoMensuravel
                                  // }
                                  />
                                </Grid>
                              </Paper>
                            </Grid> : null
                          }
                          {user.id_permissao !== 4 ?
                            <Grid item xs={12} style={{ paddingTop: "40px" }}>
                              <Paper className={classes.paper}>
                                <InfoCard title={"PRIORIDADE"} />
                                <Grid item xs={12}>
                                  <div>
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                      style={{ color: `${pallet.general.color1}` }}
                                    >
                                      <IndicatoresPrioridade
                                        value={
                                          EsfSel === "F" ?
                                            sumaryFedPRTO.semPrioridade
                                            : EsfSel === "E" ?
                                              sumaryEstPRTO.semPrioridade
                                              :
                                              sumaryMunPRTO.semPrioridade
                                        }
                                        title={"Não priorizada"}
                                        link={
                                          EsfSel === "F" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"},{"key":"prioridade","label":"PRIORIDADE: Sem prioridade","value":0}]'
                                            : EsfSel === "E" ?
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Sem prioridade","value":0}]'
                                              :
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Sem prioridade","value":0}]'

                                        }
                                        tag={EsfSel === "F" ? sumaryFedPR.semPrioridade.toString()
                                          : EsfSel === "E" ? sumaryEstPR.semPrioridade.toString()
                                            : sumaryMunPR.semPrioridade.toString()
                                        }
                                      />
                                      <IndicatoresPrioridade
                                        value={
                                          EsfSel === "F" ?
                                            sumaryFedPRTO.minima
                                            : EsfSel === "E" ?
                                              sumaryEstPRTO.minima
                                              :
                                              sumaryMunPRTO.minima
                                        }
                                        title={"Mínima"}
                                        link={
                                          EsfSel === "F" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"},{"key":"prioridade","label":"PRIORIDADE: Mínima","value":1}]'
                                            : EsfSel === "E" ?
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Mínima","value":1}]'
                                              :
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Mínima","value":1}]'
                                        }
                                        tag={EsfSel === "F" ? sumaryFedPR.minima.toString()
                                          : EsfSel === "E" ? sumaryEstPR.minima.toString()
                                            : sumaryMunPR.minima.toString()
                                        }
                                      />
                                      <IndicatoresPrioridade
                                        value={
                                          EsfSel === "F" ?
                                            sumaryFedPRTO.baixa
                                            : EsfSel === "E" ?
                                              sumaryEstPRTO.baixa
                                              :
                                              sumaryMunPRTO.baixa
                                        }
                                        title={"Baixa"}
                                        link={
                                          EsfSel === "F" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"},{"key":"prioridade","label":"PRIORIDADE: Baixa","value":2}]'
                                            : EsfSel === "E" ?
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Baixa","value":2}]'
                                              :
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Baixa","value":2}]'
                                        }
                                        tag={
                                          EsfSel === "F" ?
                                            sumaryFedPR.baixa.toString()
                                            : EsfSel === "E" ?
                                              sumaryEstPR.baixa.toString()
                                              :
                                              sumaryMunPR.baixa.toString()
                                        }
                                      />
                                      <IndicatoresPrioridade
                                        value={
                                          EsfSel === "F" ?
                                            sumaryFedPRTO.media
                                            : EsfSel === "E" ?
                                              sumaryEstPRTO.media
                                              :
                                              sumaryMunPRTO.media
                                        }
                                        title={"Média"}
                                        link={

                                          EsfSel === "F" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"},{"key":"prioridade","label":"PRIORIDADE: Média","value":3}]'
                                            : EsfSel === "E" ?
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Média","value":3}]'
                                              :
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Média","value":3}]'
                                        }
                                        tag={
                                          EsfSel === "F" ?
                                            sumaryFedPR.media.toString()
                                            : EsfSel === "E" ?
                                              sumaryEstPR.media.toString()
                                              :
                                              sumaryMunPR.media.toString()
                                        }
                                      />
                                      <IndicatoresPrioridade
                                        value={
                                          EsfSel === "F" ?
                                            sumaryFedPRTO.alta
                                            : EsfSel === "E" ?
                                              sumaryEstPRTO.alta
                                              :
                                              sumaryMunPRTO.alta
                                        }
                                        title={"Alta"}
                                        link={
                                          EsfSel === "F" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"},{"key":"prioridade","label":"PRIORIDADE: Alta","value":4}]'
                                            : EsfSel === "E" ?
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Alta","value":4}]'
                                              :
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Alta","value":4}]'
                                        }
                                        tag={
                                          EsfSel === "F" ?
                                            sumaryFedPR.alta.toString()
                                            : EsfSel === "E" ?
                                              sumaryEstPR.alta.toString()
                                              :
                                              sumaryMunPR.alta.toString()
                                        }
                                      />
                                      <IndicatoresPrioridade
                                        value={
                                          EsfSel === "F" ?
                                            sumaryFedPRTO.maxima
                                            : EsfSel === "E" ?
                                              sumaryEstPRTO.maxima
                                              :
                                              sumaryMunPRTO.maxima
                                        }
                                        title={"Máxima"}
                                        link={
                                          EsfSel === "F" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"},{"key":"prioridade","label":"PRIORIDADE: Máxima","value":5}]'
                                            : EsfSel === "E" ?
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Máxima","value":5}]'
                                              :
                                              '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                              formatDate(dtIni, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtIni, "MM/dd/yyyy") +
                                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                              formatDate(dtFin, "dd/MM/yyyy") +
                                              '","value" : "' +
                                              formatDate(dtFin, "MM/dd/yyyy") +
                                              '"},{"key":"prioridade","label":"PRIORIDADE: Máxima","value":5}]'
                                        }
                                        tag={
                                          EsfSel === "F" ?
                                            sumaryFedPR.maxima.toString()
                                            : EsfSel === "E" ?
                                              sumaryEstPR.maxima.toString()
                                              :
                                              sumaryMunPR.maxima.toString()
                                        }
                                      />
                                    </Grid>
                                  </div>
                                </Grid>
                              </Paper>
                            </Grid> : null
                          }
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            xs={12}
                            style={{ paddingTop: "40px" }}
                          >
                            <PieChartInfoCard
                              title="UNIVERSO"
                              labels={[
                                {
                                  key: "Ativos",
                                  data:
                                    EsfSel === "F"
                                      ? sumaryFedUN?.ativas
                                      : EsfSel === "E"
                                        ? sumaryEstUN?.ativas
                                        : sumaryMunUN?.ativas,
                                  color: `${pallet.color.charts.green}`,
                                  url: EsfSel === "F" ?
                                    '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                                    : EsfSel === "E" ?
                                      '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                                      :
                                      '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                                },
                                {
                                  key: "Inativos",
                                  data:
                                    EsfSel === "F"
                                      ? sumaryFedUN?.inativas
                                      : EsfSel === "E"
                                        ? sumaryEstUN?.inativas
                                        : sumaryMunUN?.inativas,
                                  color: `${pallet.color.charts.red}`,
                                  url: EsfSel === "F" ?
                                    '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false}]'
                                    : EsfSel === "E" ?
                                      '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false}]'
                                      :
                                      '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false}]'
                                },
                              ]}
                            />
                          </Grid>
                          {EsfSel === "F" ? (
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              xs={12}
                              style={{ paddingTop: "40px" }}
                            >
                              <PieChartInfoCard
                                title="CASAS LEGISLATIVAS"
                                labels={[
                                  {
                                    key: "Câmara dos Deputados",
                                    data: sumaryFedCA?.proposicoesCamara,
                                    color: `${pallet.color.charts.green}`,
                                    url:
                                      '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: Câmara","value":"CD" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]',
                                  },
                                  {
                                    key: "Senado Federal",
                                    data: sumaryFedCA?.proposicoesSenado,
                                    color: `${pallet.color.charts.purple}`,
                                    url:
                                      '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: Senado","value":"SF" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]',
                                  },
                                  {
                                    key: "Congresso Nacional",
                                    data: sumaryFedCA?.proposicoesCongresso,
                                    color: `${pallet.color.charts.red}`,
                                    url:
                                      '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: Congresso","value":"CN" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]',
                                  },
                                ]}
                              />
                            </Grid>
                          ) : null
                          }
                          {
                            user.id_permissao !== 4 ?
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                xs={12}
                                style={{ paddingTop: "40px" }}
                              >
                                <PieChartInfoCard
                                  title="AÇÕES DE INFLUÊNCIA"
                                  labels={[
                                    {
                                      key: "Êxito",
                                      //data: sumaryFedAI?.exito,
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedAI?.exito
                                          : EsfSel === "E"
                                            ? sumaryEstAI?.exito
                                            : sumaryMunAI?.exito,
                                      color: `${pallet.color.charts.green}`,
                                      url:
                                        EsfSel === "F"
                                          ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E"
                                            ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1",}{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]',
                                    },
                                    {
                                      key: "Êxito Parcial",
                                      //data: sumaryFedAI?.exitoParcial,
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedAI?.exitoParcial
                                          : EsfSel === "E"
                                            ? sumaryEstAI?.exitoParcial
                                            : sumaryMunAI?.exitoParcial,
                                      color: `${pallet.color.charts.red}`,
                                      url:
                                        EsfSel === "F"
                                          ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E"
                                            ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]',
                                    },
                                    {
                                      key: "Sem Êxito",
                                      //data: sumaryFedAI?.semExito,
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedAI?.semExito
                                          : EsfSel === "E"
                                            ? sumaryEstAI?.semExito
                                            : sumaryMunAI?.semExito,
                                      color: `${pallet.color.charts.blue}`,
                                      url:
                                        EsfSel === "F"
                                          ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E"
                                            ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]',
                                    },
                                    {
                                      key: "Pendente",
                                      //data: sumaryFedAI?.pendente,
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedAI?.pendente
                                          : EsfSel === "E"
                                            ? sumaryEstAI?.pendente
                                            : sumaryMunAI?.pendente,
                                      color: `${pallet.color.charts.yellow}`,
                                      url:
                                        EsfSel === "F"
                                          ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E"
                                            ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]',
                                    },
                                  ]}
                                />
                              </Grid> : null
                          }
                          {user.id_permissao !== 4 ?
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              xs={12}
                              style={{ paddingTop: "40px" }}
                            >
                              <PieChartInfoCard
                                title="MOMENTOS CRÍTICOS"
                                labels={[
                                  {
                                    key: "Aguardando Designação do Relator",
                                    //data: sumaryFedMC?.designacaoRelator,
                                    data:
                                      EsfSel === "F"
                                        ? sumaryFedMC?.designacaoRelator
                                        : EsfSel === "E"
                                          ? sumaryEstMC?.designacaoRelator
                                          : sumaryMunMC?.designacaoRelator,
                                    color: `${pallet.color.charts.green}`,
                                    url:
                                      EsfSel === "F" ?
                                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Designação do Relator","value": "ADR"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                        formatDate(dtIni, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtIni, "MM/dd/yyyy") +
                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                        formatDate(dtFin, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtFin, "MM/dd/yyyy") +
                                        '"}]'
                                        : EsfSel === "E" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Designação do Relator","value": "ADR"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          :
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Designação do Relator","value": "ADR"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                  },
                                  {
                                    key: "Aguardando Parecer do Relator",
                                    //data: sumaryFedMC?.aguardanoParecerDoRelator,
                                    data:
                                      EsfSel === "F"
                                        ? sumaryFedMC?.aguardanoParecerDoRelator
                                        : EsfSel === "E"
                                          ? sumaryEstMC?.aguardanoParecerDoRelator
                                          : sumaryMunMC?.aguardanoParecerDoRelator,
                                    color: `${pallet.color.charts.red}`,
                                    url:
                                      EsfSel === "F" ?
                                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Parecer do Relator","value": "APR"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                        formatDate(dtIni, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtIni, "MM/dd/yyyy") +
                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                        formatDate(dtFin, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtFin, "MM/dd/yyyy") +
                                        '"}]'
                                        : EsfSel === "E" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Parecer do Relator","value": "APR"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          :
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Parecer do Relator","value": "APR"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                  },
                                  {
                                    key: "Prazo para emendas",
                                    //data: sumaryFedMC?.prazoParaEmenda,
                                    data:
                                      EsfSel === "F"
                                        ? sumaryFedMC?.prazoParaEmenda
                                        : EsfSel === "E"
                                          ? sumaryEstMC?.prazoParaEmenda
                                          : sumaryMunMC?.prazoParaEmenda,
                                    color: `${pallet.color.charts.blue}`,
                                    url:
                                      EsfSel === "F" ?
                                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Prazo para Emendas","value": "PE"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                        formatDate(dtIni, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtIni, "MM/dd/yyyy") +
                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                        formatDate(dtFin, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtFin, "MM/dd/yyyy") +
                                        '"}]'
                                        : EsfSel === "E" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Prazo para Emendas","value": "PE"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          :
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Prazo para Emendas","value": "PE"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                  },
                                  {
                                    key: "Aguardando Deliberação",
                                    //data: sumaryFedMC?.aguardaDeliberacao,
                                    data:
                                      EsfSel === "F"
                                        ? sumaryFedMC?.aguardaDeliberacao
                                        : EsfSel === "E"
                                          ? sumaryEstMC?.aguardaDeliberacao
                                          : sumaryMunMC?.aguardaDeliberacao,
                                    color: `${pallet.color.charts.yellow}`,
                                    url:
                                      EsfSel === "F" ?
                                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Deliberação","value": "AD"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                        formatDate(dtIni, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtIni, "MM/dd/yyyy") +
                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                        formatDate(dtFin, "dd/MM/yyyy") +
                                        '","value" : "' +
                                        formatDate(dtFin, "MM/dd/yyyy") +
                                        '"}]'
                                        : EsfSel === "E" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Deliberação","value": "AD"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          :
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Deliberação","value": "AD"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                  },
                                ]}
                              />
                            </Grid> : null
                          }
                          {
                            user.id_permissao !== 4 ?
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                xs={12}
                                style={{ paddingTop: "40px" }}
                              >
                                <PieChartInfoCard
                                  title="POSICIONAMENTO"
                                  labels={[
                                    {
                                      key: "Neutro",
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.neutro
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.neutro
                                            : sumaryMunPS?.neutro,
                                      color: `${pallet.charts.purple}`,
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Neutro", "value": ' +
                                          3 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Neutro", "value": ' +
                                            3 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Neutro", "value": ' +
                                            3 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Favorável",
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.favoravel
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.favoravel
                                            : sumaryMunPS?.favoravel,
                                      color: `${pallet.color.charts.green}`,
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": ' +
                                          5 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": ' +
                                            5 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": ' +
                                            5 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Favorável com Emenda",
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.favoravelComEmenda
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.favoravelComEmenda
                                            : sumaryMunPS?.favoravelComEmenda,
                                      color: `${pallet.charts.blue}`,
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável com Emenda", "value": ' +
                                          4 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável com Emenda", "value": ' +
                                            4 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável com Emenda", "value": ' +
                                            4 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Favorável ao parecer do relator",
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.favoravelParecerRelator
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.favoravelParecerRelator
                                            : sumaryMunPS?.favoravelParecerRelator,
                                      color: '#C71585',
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator", "value": ' +
                                          6 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator", "value": ' +
                                            6 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator", "value": ' +
                                            6 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Favorável ao parecer do relator com emenda",
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.favoravelParecerRelatorEmenda
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.favoravelParecerRelatorEmenda
                                            : sumaryMunPS?.favoravelParecerRelatorEmenda,
                                      color: '#B8860B',
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator com emenda", "value": ' +
                                          7 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator com emenda", "value": ' +
                                            7 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator com emenda", "value": ' +
                                            7 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Contrário ao parecer do relator",
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.contrarioParecerRelator
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.contrarioParecerRelator
                                            : sumaryMunPS?.contrarioParecerRelator,
                                      color: '#facb74',
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator", "value": ' +
                                          8 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator", "value": ' +
                                            8 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator", "value": ' +
                                            8 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Contrário ao parecer do relator com emenda",
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.contrarioParecerRelatorEmenda
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.contrarioParecerRelatorEmenda
                                            : sumaryMunPS?.contrarioParecerRelatorEmenda,
                                      color: '#000000',
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator com emenda", "value": ' +
                                          8 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator com emenda", "value": ' +
                                            8 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator com emenda", "value": ' +
                                            8 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Contrário",
                                      //data: sumaryFedPS?.contrario,
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.contrario
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.contrario
                                            : sumaryMunPS?.contrario,
                                      color: `${pallet.color.charts.red}`,
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário", "value": ' +
                                          1 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário", "value": ' +
                                            1 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário", "value": ' +
                                            1 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Contrário com Emenda Saneadora",
                                      //data: sumaryFedPS?.contrarioComEmenda,
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.contrarioComEmenda
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.contrarioComEmenda
                                            : sumaryMunPS?.contrarioComEmenda,
                                      color: `${pallet.charts.orange}`,
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário com Emenda Saneadora", "value": ' +
                                          2 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário com Emenda Saneadora", "value": ' +
                                            2 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário com Emenda Saneadora", "value": ' +
                                            2 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Pendente",
                                      //data: sumaryFedPS?.pendente,
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.pendente
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.pendente
                                            : sumaryMunPS?.pendente,
                                      color: `${pallet.color.charts.yellow}`,
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Pendente", "value": ' +
                                          99 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Pendente", "value": ' +
                                            99 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Pendente", "value": ' +
                                            99 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                    {
                                      key: "Indefinido",
                                      data:
                                        EsfSel === "F"
                                          ? sumaryFedPS?.indefinido
                                          : EsfSel === "E"
                                            ? sumaryEstPS?.indefinido
                                            : sumaryMunPS?.indefinido,
                                      color: `${pallet.color.charts.lightGrey}`,
                                      url:
                                        EsfSel === "F" ?
                                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Indefinido", "value": ' +
                                          0 +
                                          '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                          formatDate(dtIni, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtIni, "MM/dd/yyyy") +
                                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                          formatDate(dtFin, "dd/MM/yyyy") +
                                          '","value" : "' +
                                          formatDate(dtFin, "MM/dd/yyyy") +
                                          '"}]'
                                          : EsfSel === "E" ?
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Indefinido", "value": ' +
                                            0 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                            :
                                            '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "posicionamento", "label": "POSICIONAMENTO: Indefinido", "value": ' +
                                            0 +
                                            '},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                            formatDate(dtIni, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtIni, "MM/dd/yyyy") +
                                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                            formatDate(dtFin, "dd/MM/yyyy") +
                                            '","value" : "' +
                                            formatDate(dtFin, "MM/dd/yyyy") +
                                            '"}]'
                                    },
                                  ]}
                                />
                              </Grid> : null
                          }
                          {user.id_permissao !== 4 ?
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              xs={12}
                              style={{ paddingTop: "40px" }}
                            >
                              <PieChartInfoCard
                                title="QUALIFICAÇÃO - INTERLOCUTOR"
                                labels={[
                                  {
                                    key: "Convergente",
                                    //data: sumaryFedQI?.convergente,
                                    data:
                                      EsfSel === "F"
                                        ? sumaryFedQI?.convergente
                                        : EsfSel === "E"
                                          ? sumaryEstQI?.convergente
                                          : sumaryMunQI?.convergente,
                                    color: `${pallet.color.charts.green}`,
                                    url:
                                      EsfSel === "F" ?
                                        '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"}]'
                                        : EsfSel === "E" ?
                                          '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"}]'
                                          : '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"}]'
                                  },
                                  {
                                    key: "Divergente",
                                    //data: sumaryFedQI?.divergente,
                                    data:
                                      EsfSel === "F"
                                        ? sumaryFedQI?.divergente
                                        : EsfSel === "E"
                                          ? sumaryEstQI?.divergente
                                          : sumaryMunQI?.divergente,
                                    color: `${pallet.color.charts.red}`,
                                    url:
                                      EsfSel === "F" ?
                                        '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"}]'
                                        : EsfSel === "E" ?
                                          '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"}]'
                                          :
                                          '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"}]'
                                  },
                                  {
                                    key: "Neutro",
                                    //data: sumaryFedQI?.neutro,
                                    data:
                                      EsfSel === "F"
                                        ? sumaryFedQI?.neutro
                                        : EsfSel === "E"
                                          ? sumaryEstQI?.neutro
                                          : sumaryMunQI?.neutro,
                                    color: `${pallet.color.charts.lightGrey}`,
                                    url:
                                      EsfSel === "F" ?
                                        '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"}]'
                                        : EsfSel === "E" ?
                                          '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"}]'
                                          :
                                          '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"}]'
                                  },
                                  {
                                    key: "Não Classificado",
                                    data:
                                      EsfSel === "F"
                                        ? sumaryFedQI?.naoClassificado
                                        : EsfSel === "E"
                                          ? sumaryEstQI?.naoClassificado
                                          : sumaryMunQI?.naoClassificado,
                                    color: `${pallet.color.charts.purple}`,
                                    url:
                                      EsfSel === "F" ?
                                        '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"}]'
                                        : EsfSel === "E" ?
                                          '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"}]'
                                          :
                                          '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"}]'
                                  },
                                ]}
                              />
                            </Grid> : null
                          }
                        </Grid>
                      </> : null
                    }
                  </AccordionDetails>
                </Accordion>
              </PaperContent>
            </Grid>
            :
            null}
          {" "}
          {/*aqui Fim Dados Gerais*/}
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Grid>
      {/* </Main> */}
    </div>
  );
}


