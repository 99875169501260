import React, { useState, useEffect } from 'react';
import sty from "styled-components";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, TextField, Typography, Button, Dialog, DialogTitle, IconButton, Autocomplete } from '@mui/material';
import useStyles from '../../../../components/layout/Styles';
import { usePallet } from '../../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import TabelasService from '../../../../services/TabelasService';
import { formatDate } from '../../../../utils/dateUtils';
// import { styled } from '@mui/material/styles';
import styled from 'styled-components';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DeadLineDate from '../../../../components/DeadLineDate';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import CloseIcon from '@mui/icons-material/Close';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ProposicaoAcaoDeInfluenciaService from '../../../../services/ProposicaoAcaoDeInfluenciaService';
import format from 'date-fns/format';
import UsuarioService from '../../../../services/UsuarioService'
import { useAlert } from '../../../../contexts/AlertContext';
import InputMultilineTextField from '../../../../components/InputMultilineTextField';
import SolidButton from '../../../../components/SolidButton';
import { ICheckBoxNestedItem, InputDropDownCheckBoxNested } from '../../../../components/CheckBoxNested';
import { arraysEquals } from '../../../../utils/array.utils';
import { useNavigate, useParams } from 'react-router';
import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import InputDatePicker from '../../../../components/InputDatePicker';
import { truncate } from 'fs';
import ProposicaoAcaoInfluenciaCreateSchema from '../../models/ProposicaoAcaoInfluenciaCreateSchema';
import EditIcon from '@mui/icons-material/Edit';
import ParlamentarService from '../../../../services/ParlamentarService';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
}));

const casasLegislativas = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Câmara', label: 'Câmara dos Deputados', value: 2 },
    { key: 'Senado', label: 'Senado Federal', value: 1 },
    { key: 'Congresso', label: 'Congresso Nacional', value: 3 },
    { key: 'Assembleia', label: 'Assembléia Legislativa', value: 4 },
    { key: 'Municipais', label: 'Câmaras Municipais', value: 5 },
]

const DotPosicionamento = sty.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: ${isMobile ? "4px" : "25px"};
`;

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, textTransform: 'uppercase', }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton >
            ) : null}
        </DialogTitle >
    );
};

export default function ProposicaoAcaoInfluenciaEdicao(props: {
    id_acao_influencia?: number,
    prazo?: Date,
    resultado?: number,
    responsavel?: string,
    influenciaveis?: Array<{
        id: number,
        nome: string
    }>,
    casa?: number,
    ambitoId?: number,
    descricao?: string
    propDescricao: string

    onChange?: (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    reloadList?: () => void,
    closeMenu?: () => void,
    // idParlamentar?: number,
    // lista?: Array<any>,
}) {

    const { pallet } = usePallet();
    const { NewAlert } = useAlert();
    const navigate = useNavigate();
    const [idReferencia, setIdReferencia] = React.useState<any>();
    const [open, setOpen] = useState<boolean>(false)
    const [prioridade, setPrioridade] = useState('');
    const classes = useStyles();
    const [assunto, setAssunto] = useState('');
    const [objetivo, setObjetivo] = useState('');
    const [departamento, setDepartamento] = useState<Array<ICheckBoxNestedItem>>();
    const [userList, setUserList] = useState<Array<number>>([]);
    const [lockedUserList, setLockedUserList] = useState<Array<number>>([]);
    const [onSave, setOnSave] = React.useState(false)
    const [error, setError] = useState<Array<{ name: string, err: string }>>([])
    const [data, setData] = useState({});

    const [prazo, setPrazo] = useState(Date);
    const [resultado, setResultado] = useState<number>();
    const [responsavel, setResponsavel] = useState();
    const [responsavelNome, setResponsavelNome] = useState('');
    const [responsaveisList, setResponsaveisList] = useState([{ id: "", label: "", value: "" }]);
    const [influenciaSobreOptions, setInfluenciaSobreOptions] = useState([]);
    const [influenciaSobre, setInfluenciaSobre] = useState([]);
    const [casaSelecionada, setCasaSelecionada] = React.useState<Number>(undefined);
    const [ambitoSelecionado, setAmbitoSelecionado] = React.useState<Number>();
    const [ambitos, setAmbitos] = React.useState([]);
    const [descricao, setDescricao] = useState("");
    const [trocaCasa, setTrocaCasa] = useState(false);








    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }


    const corResultado = (value: string) => {
        switch (value) {
            case "semExito":
                return `${pallet.charts.red}`;
            case "pendente":
                return `${pallet.charts.yellow}`;
            case "exitoParcial":
                return `${pallet.charts.blue}`;
            case "exito":
                return `${pallet.charts.green}`;
            default:
                return 'white';
        }
    };


    function LabelHandleResultado(props: {
        resultado: string;
        bg: string;
    }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corResultado(`${props?.bg}`)} />
                    </Grid>
                    <Grid item>{props?.resultado}</Grid>
                </Grid>
            </>
        );
    }


    const resultadoItens = [
        {
            key: 1,
            label: (
                <LabelHandleResultado
                    resultado={"Pendente"}
                    bg={"pendente"}
                />
            ),
            value: 4,
        },
        {
            key: 2,
            label: (
                <LabelHandleResultado
                    resultado={"Sem Êxito"}
                    bg={"semExito"}
                />
            ),
            value: 2,
        },
        {
            key: 3,
            label: (
                <LabelHandleResultado
                    resultado={"Êxito Parcial"}
                    bg={"exitoParcial"}
                />
            ),
            value: 3,
        },
        {
            key: 4,
            label: (
                <LabelHandleResultado
                    resultado={"Êxito"}
                    bg={"exito"}
                />
            ),
            value: 1,
        },
    ];

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    React.useEffect(() => {
        UsuarioService.GetList().then(async res => {
            const responsaveis = res?.map(usuario => {
                return {
                    id: usuario.id,
                    name: usuario.nome + (" - ") + truncate(usuario.departamento.nome, 20),
                    value: usuario.id,
                }
            })
            setResponsaveisList(responsaveis);
        },
        )
        TabelasService.getTabelaInterlocutores().then(async res => {
            setInfluenciaSobreOptions(res)
        },
        )
    }, []);


    React.useEffect(() => {
        UsuarioService.GetByDpto().then(async res => {
            const comboItens = res.map(departamento => {
                return {
                    id: departamento.id,
                    label: departamento.nome,
                    items: departamento.usuarios.map(usuario => {
                        return {
                            id: usuario.id,
                            label: usuario.nome,
                            value: false
                        }
                    })
                }
            })
            setDepartamento(comboItens)
        },
        )
    }, []);

    async function handleOnAfterUpload() {
        if (idReferencia) {
            setIdReferencia(undefined);
            setOnSave(false)
        }
    }

    const handleResultadoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResultado(Number(event.target.value))
    };

    const handleResponsavelChange = (event) => {
        setResponsavel(event.target.value)
        setResponsavelNome(event.target.name)
    };

    const handleInfluenciaSobreChange = (event, values) => {
        setInfluenciaSobre(values.map((i) => { return (i.value ? i.value : i.id) }));
        //TODO: Ajustar o back porque tem duas formas do Values chegar, as tags chegal com estrutura diferente do que esta sendo registrado
    }

    const handleCasaLegislativaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmbitos([])
        setAmbitoSelecionado(undefined)
        setTrocaCasa(true)
        setInfluenciaSobre([])
        setInfluenciaSobreOptions([])
        setCasaSelecionada(parseInt(event.target.value))
        TabelasService.getTabelaAmbitosTipo(Number(event.target.value)).then(async (res) => {
            const data = [];
            data.push({ key: "", label: "-- Selecione -- ", value: "" });
            res?.map((item) =>
                data.push({
                    key: item.id,
                    label: item.sigla + " - " + item.resumo,
                    value: item.id,
                })
            );
            setAmbitos(() => [...data]);

        }).then(() => setTrocaCasa(false));
    };

    const handleAmbitoChange = (event) => {
        setAmbitoSelecionado(event.target.value)
    }

    const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value)
    };

    const handlePrazoChange = (prazo) => {
        setPrazo(prazo)
    };

    const handleClickOpen = async (id) => {
        await ProposicaoAcaoDeInfluenciaService.GetEspecifico(props?.id_acao_influencia).then(res => {
            setPrazo(res?.data[0]?.acao_influencia_prazo)
            setResultado(res?.data[0]?.acao_influencia_resultado)
            setResponsavel(res?.data[0]?.acao_influencia_idResponsavel)
            setInfluenciaSobre(res?.data?.influenciaveis)
            setCasaSelecionada(res?.data[0]?.acao_influencia_casa_legislativa)
            TabelasService.getTabelaAmbitosTipo(parseInt(res?.data[0]?.acao_influencia_casa_legislativa)).then(
                res => {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map(item => data.push({ key: item?.sigla, label: item.sigla + " - " + item.resumo, value: item.id }))
                    setAmbitos(() => [...data]);
                })
            setAmbitoSelecionado(res?.data[0]?.acao_influencia_ambito_id)
            setDescricao(res?.data[0]?.acao_influencia_descricao)
        })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // props.reloadList();
    };

    const editarAcaoInfluencia = async () => {
        setOnSave(true)

        const idInfluenciaveis = influenciaSobre.map(x => x.id)
        const data = {
            prazo: !prazo ? new Date : prazo,
            resultado: resultado,
            responsavelId: responsavel,
            influenciaSobre: idInfluenciaveis[0] === undefined ? influenciaSobre : idInfluenciaveis,
            casa: casaSelecionada,
            ambito: casaSelecionada === 4 || casaSelecionada === 5 ? 0 : ambitoSelecionado,
            descricao: descricao,
            // propDescricao: props?.propDescricao,
        }

        setError(() => [])
        data && ProposicaoAcaoInfluenciaCreateSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        ProposicaoAcaoInfluenciaCreateSchema.validate(data).catch(function (err) {
        });

        if ((ProposicaoAcaoInfluenciaCreateSchema.isValidSync(data)) || (casaSelecionada === 4 && ambitoSelecionado === undefined) || (casaSelecionada === 5 && ambitoSelecionado === undefined)) {

            await ProposicaoAcaoDeInfluenciaService.Update(data, props?.id_acao_influencia).then(res => {
                if (res) {
                    NewAlert('success', 'Ação de Influência editada com sucesso');
                    if (props.reloadList) {
                        props.reloadList();
                    }
                    handleClose();
                } else {
                    NewAlert('error', 'Verifique os campos e tente outra vez');

                }
            })
                .then(data => {
                    // props?.onChange()

                })
        } else {
            NewAlert('error', 'Algum campo está incorreto ou vazio, verifique!');
        }
    }

    const filterOptions = (event) => {
        if (event.target.value && event.target.value.trim().length > 1) {
            ParlamentarService.GetPartialNameWithId(
                event.target.value,
                casaSelecionada === 1 ? [{ key: 'casa', value: '1' }] :
                    casaSelecionada === 2 ? [{ key: 'casa', value: '2' }] :
                        casaSelecionada === 3 ? [{ key: 'casa', value: '1' }, { key: 'casa', value: '2' }] :
                            casaSelecionada === 4 ? [{ key: 'esfera', value: 'Estadual' }] :
                                casaSelecionada === 5 ? [{ key: 'esfera', value: 'Municipal' }] :
                                    []
            ).then(async res =>
                setInfluenciaSobreOptions(res ? res : null)
            );
        }
    };

    const { user } = useAuthorization();

    const erroAlteracaoPerfil = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil."
        );
    }

    return (
        <div>
            {/* <Link href="#" onClick={handleClickOpen} underline="none"
                style={{
                    // fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    marginRight: "30px"


                }}>
                {'Editar'}
            </Link> */}

            <Button onClick={user.id_permissao !== 3 ? handleClickOpen : erroAlteracaoPerfil}
                style={{
                    marginLeft: "-7px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    paddingRight: "85px",
                    marginRight: "-10px"
                }}>
                <EditIcon style={{ marginRight: "13px" }} />
                <div style={{ fontSize: 0 }}>@</div>Editar
            </Button>

            <BootstrapDialog style={{ paddingLeft: `${isMobile ? "5px" : "10px"}`, paddingRight: `${isMobile ? "5px" : "10px"}`, borderRadius: "6px 6px 0px 0px" }}
                maxWidth={"md"}
                onClose={handleClose}
                aria-labelledby="customized-dialog"
                open={open}

            >
                <Grid container xl={12} lg={12} xs={12} sx={{
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    height: `${isMobile ? "50px" : "80px"}`,
                    borderRadius: "6px",
                    boxShadow: "0px 10px 20px rgba(31, 32, 65, 0.05)"
                }}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <Grid style={{
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: pallet.general.weight_bold,
                            color: `${pallet.color.tertiary.font}`,
                            fontSize: `${pallet.general.size18}`,
                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                            padding: "4px 0px 0px 26px",
                        }}>
                            EDITAR AÇÃO DE INFLUÊNCIA
                        </Grid>
                    </BootstrapDialogTitle>

                </Grid>
                <Grid>
                    < Divider variant="fullWidth" />
                </Grid>
                <Grid >
                    <Grid container spacing={4} style={{ padding: "25px 20px 20px 20px" }}>
                        <Grid item lg={6} xs={12}>
                            <InputDatePicker
                                onChange={handlePrazoChange}
                                label={"DATA"}
                                inputFormat={"dd/MM/yyyy"}
                                value={prazo}
                                defaultValue={new Date(prazo)}
                                id={"casa-acaoInfluencia"}
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='prazo' errors={error} />
                            </Grid>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <SelectTextFieldA
                                options={resultadoItens}
                                // placeholder={"Selecione departamento ou usuário"}
                                value={resultado}
                                onChange={handleResultadoChange}
                                label={"RESULTADO"}
                                id={"resultado-acaoInfluencia"}
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='resultado' errors={error} />
                            </Grid>
                        </Grid>
                        <Grid item lg={12} xs={12} >
                            <SelectTextFieldA
                                value={responsavel}
                                onChange={handleResponsavelChange}
                                options={responsaveisList}
                                label={"RESPONSÁVEL"}
                                id={"responsavel-acaoInfluencia"}
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='responsavel' errors={error} />
                            </Grid>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <SelectTextFieldA
                                value={casaSelecionada}
                                options={casasLegislativas}
                                label={"CASA LEGISLATIVA"}
                                id={"casa-acaoInfluencia"}
                                onChange={handleCasaLegislativaChange}
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='casa' errors={error} />
                            </Grid>
                        </Grid>
                        {casaSelecionada && trocaCasa === false
                            ?
                            <Grid item lg={12} xs={12}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={influenciaSobreOptions}
                                    // getOptionLabel={(option) => (option.nome ? option?.nome + (' - ') + option?.siglaPartido + (' - ') + option?.tratamento : option.label)}
                                    getOptionLabel={(option) => (option.nome ? option?.nome + (' - ') + option?.siglaPartido : option.label)}
                                    defaultValue={influenciaSobre}
                                    onInputChange={filterOptions}
                                    onChange={handleInfluenciaSobreChange}
                                    renderInput={(params) =>
                                        <TextField
                                            fullWidth
                                            label="INFLUÊNCIA SOBRE"
                                            placeholder="Busque o interlocutor"
                                            variant='outlined'
                                            {...params}
                                            InputLabelProps={{
                                                style: {
                                                    color: "grey"
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                },
                                                style: {
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    fontWeight: pallet.general.weight_100,
                                                    background: `${pallet.backgroundColorSecondary}`
                                                },
                                                ...params.InputProps,
                                            }}
                                        />
                                    }
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='influenciaSobre' errors={error} />
                                </Grid>
                            </Grid>
                            :
                            null
                        }

                        {casaSelecionada && casaSelecionada !== 4 && casaSelecionada !== 5 && casaSelecionada !== 0 ?
                            <Grid item lg={6} xs={12}>
                                <SelectTextFieldA
                                    value={ambitoSelecionado}
                                    onChange={handleAmbitoChange}
                                    options={ambitos}
                                    label={"ÂMBITOS"}
                                    id={"ambitos-acaoInfluencia"}
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='ambito' errors={error} />
                                </Grid>                            </Grid>
                            :
                            null
                        }

                        <Grid item lg={12} xs={12}>
                            <InputMultilineTextField
                                type={"text"}
                                maxRows={4}
                                value={descricao}
                                defaultValue={descricao}
                                onChange={handleDescricaoChange}
                                id={"descricao-proposicao"}
                                label="DESCRIÇÃO"
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='descricao' errors={error} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid>
                    < Divider variant="fullWidth" style={{ paddingTop: "30px", marginBottom: "20px" }} />
                </Grid>
                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 15px 10px 15px"}`, width: "100%", minWidth: '100%' }}>
                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                        <Grid className="container">
                            <AnexosComponent
                                label={''}
                                // id_referencia={props.idRef}
                                id_referencia={props?.id_acao_influencia}
                                modulo={EAnexoModulos.ProposicaoAcaoInfluencia}
                                canEdit={true}
                                autosave={true}
                                isAnexosTitle={"ANEXOS"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" sx={{
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    height: `${isMobile ? "50px" : "80px"}`,
                    borderRadius: "6px",
                    boxShadow: "0px 10px 20px rgba(31, 32, 65, 0.05)"


                }}>
                    <Grid container alignItems="center" justifyContent="end"
                        style={{
                            paddingRight: `${isMobile ? "20px" : "0px"}`,
                            textAlignLast: "end",
                            padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,

                        }}>
                        <Grid item>
                            <ButtonDialogConfirmation
                                styles={{
                                    borderRadius: "6px 6px 0px 0px",
                                    marginRight: "20px",
                                    textTransform: "capitalize",
                                    color: `${pallet.general.color1}`,
                                }}
                                title={"CONFIRMAÇÃO"}
                                title_button={"Cancelar"}
                                content={"CANCELAR EDIÇÃO?"}
                                message={"Esta ação não poderá ser desfeita."}
                                confirmation_button={"Cancelar"}
                                confirmationEvent={() => {
                                    handleClose()
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <SolidButton
                                onClick={editarAcaoInfluencia}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={"SALVAR"}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </BootstrapDialog>
        </div >
    );
}
