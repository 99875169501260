import { EAnexoModulos } from '../components/Anexos';
import * as http from '../helpers/http';
import { capitalizeName } from '../utils/string.utils';
import UploadFilesService from './UploadFiles.service';

class ParlamentarService {

    SetSeguir = async (id: number, value: boolean): Promise<any> => {
        const result = await http.put(`/parlamentar/${id}/seguir`, { seguindo: value });
        if (result.success) { return result.data }
        return undefined;
    };

    SetQualificacao = async (id: number, value: string): Promise<any> => {
        const result = await http.put(`/parlamentar/${id}/qualificacao`, { qualificacao: value });
        if (result.success) { return result.data }
        return undefined;
    };

    SetObservacao = async (id: number, value: string): Promise<any> => {
        const result = await http.put(`/parlamentar/${id}/observacao`, { observacao: value });
        if (result.success) { return result.data }
        return undefined;
    };

    Get = async (id: number): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };

    GetMin = async (id: number): Promise<any> => {
        const result = await http.get(`/parlamentar-nome/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };

    GetSummary = async (filtro: any): Promise<any> => {
        const result = await http.get(`/parlamentar/dashboard?filter=${JSON.stringify(filtro)}`);
        if (result.success) {
            return result.data;
        }
        return undefined;
    }

    GetPartialName = async (name: string, filter: { key: string, value: any }[]): Promise<any> => {
        const pageSize: number = 50;
        const pageIndex: number = 1;
        const newFilter = [...filter, { key: 'nomeP', value: name }];
        const result = await http.get(`/parlamentar?filter=${JSON.stringify(newFilter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            const res = result.data.data.map(item => capitalizeName(item.nome))
            return res
        }
        return undefined;
    };

    GetPartialNameWithId = async (name: string, filter: { key: string, value: any }[]): Promise<any> => {
        const pageSize: number = 10;
        const pageIndex: number = 1;
        const newFilter = [...filter, { key: 'nomeP', value: name }];
        const result = await http.get(`/parlamentar?filter=${JSON.stringify(newFilter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            const res = result.data.data.map(item => {
                return {
                    "label": item.sigla_partido ? capitalizeName(item.nome) + " - " + item.sigla_partido + " - " + item.tratamento : capitalizeName(item.nome),
                    "value": item.id,
                    "key": capitalizeName(item.nome)
                }
            })
            return res

        }
        return undefined;
    };


    GetPartialNameWithTratamento = async (name: string, filter: { key: string, value: any }[]): Promise<any> => {
        const pageSize: number = 10;
        const pageIndex: number = 1;
        const newFilter = [...filter, { key: 'nomeP', value: name }];
        const result = await http.get(`/parlamentar?filter=${JSON.stringify(newFilter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            const res = result.data.data.map(item => {
                return {
                    "label": item.tratamento && item.sigla_partido ?
                        item.tratamento + " - " + capitalizeName(item.nome) + " " + item.sigla_partido :
                        item.sigla_partido ?
                            capitalizeName(item.nome) + " " + item.sigla_partido :
                            capitalizeName(item.nome),
                    "value": item.id,
                    "key": capitalizeName(item.nome)
                }
            })
            return res

        }
        return undefined;
    };

    GetElkGeralInterlocutor = async (word: string, data: any): Promise<any> => {
        const result = await http.post(`/interlocutor/busca-geral-interlocutor`, { texto: word, data });
        if (result.success) { return result.data }
        return undefined;
    };


    GetElkGeralDiscurso = async (word: string, data: any): Promise<any> => {
        const result = await http.post(`/interlocutor/busca-geral-discurso`, { texto: word, data });
        if (result.success) { return result.data }
        return undefined;
    };


    GetList = async (filter?: any, pageIndex?: number, pageSize: number = 8): Promise<any> => {
        const result = await http.get(`/parlamentar?filter=${JSON.stringify(filter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                dataCards: result.data.data.map(item => {
                    return {
                        id: item.id,
                        nome: item.nome,
                        mandatoTipo: item?.tratamento,
                        partido: item?.sigla_partido,
                        estadoFederacao: item?.uf_exercicio,
                        seguindo: item.cliente ? item.cliente?.seguindo : false,
                        qualificacao: item.cliente ? item.cliente?.qualificacao.toLowerCase() : "não classificado",
                        statusMandato: item?.tratamento === "Senador " || item?.tratamento === undefined ? item?.situacaoReal : item?.situacao,
                        urlImg: item.url_foto ? item.url_foto.replace('http://', 'https://') : undefined,
                        observacao: item.cliente ? item.cliente.observacao : ''
                    }
                })
            }
        }
        return undefined;
    }

    Create = async (data: any): Promise<any> => {

        let imagem = undefined;

        if (data.imagem) {
            imagem = data.imagem;
            delete data.imagem;
        }

        const result = await http.post(`/parlamentar`, data);

        if (result.success) {
            const id = result.data;
            if (imagem && id && id > 0) {

                const filename = await UploadFilesService
                    .upload([imagem], EAnexoModulos.ParlamentarImagem, id)
                    .then(files => files.data[0].filename)
                    .catch((err) => {
                        console.error(err);
                    })

                if (filename) {
                    await http.put(`/parlamentar/${id}`, { pessoal: { foto: filename } });
                }
            }
            return result.data
        }
        return undefined;
    };

    Delete = async (id: number): Promise<boolean> => {
        const result = await http.del(`/parlamentar/${id}`);
        if (result.success) { return true }
        return false;
    };

    Update = async (id: number, data: any): Promise<any> => {

        let imagem = undefined;

        if (data.imagem) {
            imagem = data.imagem;
            delete data.imagem;
        }

        const result = await http.put(`/parlamentar/${id}`, data);
        if (result.success) {
            const id = result.data;
            if (imagem && id && id > 0) {
                const filename = await UploadFilesService
                    .upload([imagem], EAnexoModulos.ParlamentarImagem, 3825)
                    .then(files => files.data[0].filename)
                    .catch((err) => {
                        console.error(err);
                    })

                if (filename) {
                    await http.put(`/parlamentar/${id}`, { pessoal: { foto: filename } });
                }
            }
            return result.data
        }
        // if (result.success) { return true }
        // return false;
        return undefined;
    };

}

export default new ParlamentarService();
