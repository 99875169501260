import * as http from '../helpers/http';

class ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService {

    Get = async (id_acao_preparatoria: number): Promise<any> => {
        const result = await http.get(`/proposicao/acao-preparatoria/${id_acao_preparatoria}/nota-tecnica-colaborativa`);
        if (result.success) {
            return result.data.data
        }
        return undefined;
    }
    Update = async (data: any, id_acao_preparatoria: number,): Promise<boolean> => {
        const result = await http.put(`/proposicao/acao-preparatoria/${id_acao_preparatoria}/nota-tecnica-colaborativa`, data);
        if (result.success) { return result.success }
        return false;
    };

    Delete = async (id_acao_preparatoria: number): Promise<boolean> => {
        const result = await http.del(`/proposicao/acao-preparatoria/${id_acao_preparatoria}/nota-tecnica-colaborativa`);
        if (result.success) { return true }
        return false;
    };

    PostComments = async (id_nota_tecnica_colaborativa: number, data: any): Promise<number> => {
        const result = await http.post(`/proposicao/acao-preparatoria/nota-tecnica-colaborativa/${id_nota_tecnica_colaborativa}/comment`, data);
        if (result.success) { return result.data[0] }
        return 0;
    };

    DeleteComments = async (id_nota_tecnica_colaborativa: number): Promise<boolean> => {
        const result = await http.del(`/proposicao/acao-preparatoria/${id_nota_tecnica_colaborativa}/nota-tecnica-colaborativa`);
        if (result.success) { return true }
        return false;
    };

    PostSubComments = async (id_nota_colab: number, data: any): Promise<number> => {
        const result = await http.post(`/proposicao/acao-preparatoria/nota-tecnica-colaborativa/comment/${id_nota_colab}`, data);
        if (result.success) { return result.data[0] }
        return 0;
    };

    GetComments = async (id_nota_tecnica_colaborativa: number): Promise<any> => {
        const result = await http.get(`/proposicao/acao-preparatoria/nota-tecnica-colaborativa/${id_nota_tecnica_colaborativa}/comments`);
        if (result.success) {
            return result.data
        }
        return undefined;
    }

    GetSubComments = async (): Promise<any> => {
        const result = await http.get(`/proposicao/acao-preparatoria/nota-tecnica-colaborativa/comment`);

        if (result.success) {
            return result.data
        }
        return undefined;
    }

    DeleteSubComment = async (id: number): Promise<boolean> => {
        const result = await http.put(`/proposicao/acao-preparatoria/nota-tecnica-colaborativa/comment/sub-comment/${id}`, null);
        if (result.success) { return result.success }
        return false;
    };


}
export default new ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService();
