import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import DoughnutChartParlamentarProposicao from "./DoughnutChartParlamentarProposicao";
import ParlamentarProposicaoService from "../../../services/ParlamentarProposicaoService";
import { IParlamentar } from "../../../models/Parlamentar.Model";


export default function ParlamentarProposicoesDados(props: { id_parlamentar: number, parlamentar?: IParlamentar,permissaoUsuario?: number }) {
    const { pallet } = usePallet();
    const [loading, setLoading] = useState(true);

    const [sumary, setSumary] = useState({ total: 0, seguindo: 0, posicionamento: [] });

    const posicionamentoColor = (value: string) => {
        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }

    const getPosicionamentoCount = (value: string) => {
        const posicionamentoCount = sumary.posicionamento.filter(item => item.proposicao_cliente_posicionamentos === value)
        if (posicionamentoCount.length) {
            return posicionamentoCount[0].count
        }
        else {
            return 0
        }
    }

    useEffect(() => {
        getListProposicaoParlamentar()
        // eslint-disable-next-line
    }, [props.id_parlamentar])


    const getListProposicaoParlamentar = useCallback(() => {
        ParlamentarProposicaoService.GetSummary(props.id_parlamentar).then(async res => {
            if (res && res.success) {
                // console.log("cade meu dado, ", res)
                setSumary(res.data);
                setLoading(false)

            }
        })

    }, [props.id_parlamentar])

    return (

        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
      
                <Grid item xl={12} lg={12} xs={12}>
                    <DoughnutChartParlamentarProposicao
                        loading={loading}
                        masterTitle={"PROPOSIÇÕES DO INTERLOCUTOR"}
                        titleChart={"POSICIONAMENTO DAS PROPOSIÇÕES SEGUIDAS"}
                        total={sumary.total}
                        seguindo={sumary.seguindo}
                        permissaoUsuario={props.permissaoUsuario}
                        labels=
                        {[
                            {
                                key: "Favorável", data: getPosicionamentoCount("Favorável"), color: posicionamentoColor("Favorável"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar?.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": 5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

       
                            },
                            {
                                key: "Favorável ao parecer do relator", data: getPosicionamentoCount("Favorável ao parecer do relator"), color: posicionamentoColor("Favorável ao parecer do relator"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar?.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator", "value": 6},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

       
                            },
                            {
                                key: "Favorável ao parecer do relator com emenda", data: getPosicionamentoCount("Favorável ao parecer do relator com emenda"), color: posicionamentoColor("Favorável ao parecer do relator com emenda"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar?.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator com emenda", "value": 7},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

       
                            },
                            {
                                key: "Contrário ao parecer do relator", data: getPosicionamentoCount("Contrário ao parecer do relator"), color: posicionamentoColor("Contrário ao parecer do relator"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar?.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator", "value": 8},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

       
                            },
                            {
                                key: "Contrário ao parecer do relator com emenda", data: getPosicionamentoCount("Contrário ao parecer do relator com emenda"), color: posicionamentoColor("Contrário ao parecer do relator com emenda"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar?.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator com emenda", "value": 9},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

       
                            },
                            {
                                key: "Contrário", data: getPosicionamentoCount("Contrário"), color: posicionamentoColor("Contrário"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário", "value": 1},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                   
                            },
                            {
                                key: "Contrário com Emenda Saneadora", data: getPosicionamentoCount("Contrário com Emenda Saneadora"), color: posicionamentoColor("Contrário com Emenda Saneadora"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário com Emenda Saneadora", "value": 2},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },
                            {
                                key: "Neutro", data: getPosicionamentoCount("Neutro"), color: posicionamentoColor("Neutro"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Neutro", "value": 3},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },
                            {
                                key: "Favorável com Emenda Saneadora", data: getPosicionamentoCount("Favorável com Emenda Saneadora"), color: posicionamentoColor("Favorável com Emenda Saneadora"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável com Emenda Saneadora", "value": 4},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },
                            {
                                key: "Pendente", data: getPosicionamentoCount("Pendente"), color: posicionamentoColor("Pendente"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Pendente", "value": 99},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },

                            {
                                key: "Favorável ao parecer do relator", data: getPosicionamentoCount("Favorável ao parecer do relator"), color: posicionamentoColor("Favorável ao parecer do relator"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator", "value": 6},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },

                            {
                                key: "Favorável ao parecer do relator com emenda", data: getPosicionamentoCount("Favorável ao parecer do relator com emenda"), color: posicionamentoColor("Favorável ao parecer do relator com emenda"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator com emenda", "value": 7},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },

                            {
                                key: "Contrário ao parecer do relator", data: getPosicionamentoCount("Contrário ao parecer do relator"), color: posicionamentoColor("Contrário ao parecer do relator"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator", "value": 8},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },

                            {
                                key: "Contrário ao parecer do relator com emenda", data: getPosicionamentoCount("Contrário ao parecer do relator com emenda"), color: posicionamentoColor("Contrário ao parecer do relator com emenda"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator com emenda", "value": 9},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },
                            {
                                key: "Indefinido", data: getPosicionamentoCount("Indefinido"), color: posicionamentoColor("Indefinido"),
                                url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Indefinido", "value": 0},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                            },
                        ]}
                        links=
                        {[
                            {
                                key: 0, url:
                                        '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + `${props?.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"}]'

                            },
                            {
                                key: 1, url:
                                        '/proposicao?filtro=[{ "key": "visualizacao", "label": "VISUALIZAÇÃO: Seguidas", "value": "Seguidas" },{"key":"autorI","label":"Autor: ' + `${props.parlamentar.nome}` + '","value":"' + props.id_parlamentar + '"}]'

                            }

                        ]}
                    />
                </Grid>

            </Grid>
        </div>
    )
}