
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/system/Box';
import { usePallet } from '../contexts/PalletContext';
import styled from "styled-components";
import useStyles from '../components/layout/Styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export interface IComboItem {
    value: string,
    label: string
}
interface IProps {
    label?: string,
    placeholder?: string,
    startAdornment?: React.ReactNode,
    onChange?: (value: string) => void,
}

export default function Search(props: IProps) {

    const classes = useStyles();
    const { pallet } = usePallet();
    const [inputValue, setInputValue] = React.useState('');

    const handleChange = (value: any) => {
        // setInputValue(value)
        props.onChange && props.onChange(value)
    };
    return (
        <Box
            component="form"
            noValidate
            autoComplete="on"
        >
            <div>
                <TextField
                    fullWidth
                    label={props.label ? props.label : null}
                    placeholder={props.placeholder}
                    variant='outlined'
                    onChange={(e) => handleChange(e.target.value)}
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline
                        },
                        style: {
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_100,
                            background: `${pallet.backgroundColorSecondary}`
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                {props.startAdornment ? props.startAdornment : <SearchIcon />}
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        </Box>
    );
}