import { Button, Icon, Grid } from '@mui/material';
import React from 'react';
import { usePallet } from '../contexts/PalletContext';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';





export default function ButtonAdd(
    props: {

    }) {

    const { pallet } = usePallet();

    return (
        <Button
            className="btn-choose"
            variant="outlined"
            component="span"
            style={{
                fontFamily: `${pallet.fontFamily.general}`,
                fontSize: `${pallet.fontSize.text_10}`,
                fontWeight: pallet.general.weight_bold,
                backgroundColor: `${pallet.backgroundColorQuintenary}`,
                borderColor: `${pallet.backgroundColorQuintenary}`,
                color: `${pallet.textColorTertiary}`,
                padding: `${isMobile ? "10px 18px 10px 18px" : "10px 18px 10px 18px"}`,
            }}


        >
            < AddCircleIcon style={{ marginRight: "7px" }} />
            ANEXAR
        </Button>
    )
}


