import React, { useState } from "react";

import { isMobile } from "react-device-detect";

import { format } from 'date-fns';

import { styled, alpha } from "@mui/material/styles";

import {
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
} from "@mui/material";
import { Box, Pagination, Stack } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ProposicaoResultadosCreateSchema from "../../models/ProposicaoResultadosCreateSchema";

import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import { usePallet } from "../../../../contexts/PalletContext";
import { useAlert } from "../../../../contexts/AlertContext";

import ProposicaoResultadosService from "../../../../services/ProposicaoResultadosService";

import InputMultilineTextField from "../../../../components/InputMultilineTextField";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import FilterQuantity from "../../../../components/filter/FilterQuantity";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import InputDatePicker from "../../../../components/InputDatePicker";
import CircularLoading from "../../../../components/CircularLoading";
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
import useStyles from "../../../../components/layout/Styles";
import SolidButton from "../../../../components/SolidButton";
import { PreText } from "../../../../components/InfoCard";


const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    ".MuiListItemText-primary": {
        fontSize: 0
    },
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "0px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));


const resultadoItens: IListFilterInputItem[] = [
    { key: "resultado", label: "Tramitando", value: "Tramitando" },
    { key: "resultado", label: "Finalizado", value: "Finalizado" },
];


const statusItens: IListFilterInputItem[] = [
    { key: "status", label: "Positivo", value: "Positivo" },
    { key: "status", label: "Negativo", value: "Negativo" },
    { key: "status", label: "Neutro", value: "Neutro" },
];

const posicionamentoResultadoItens: IListFilterInputItem[] = [
    { key: "posicionamentoResultado", label: "Neutro", value: "Neutro" },
    { key: "posicionamentoResultado", label: "Contrário", value: "Contrario" },
    { key: "posicionamentoResultado", label: "Favorável", value: "Favoravel" },
];


export interface IListFilterInputItem {
    key: string,
    label: string,
    value: string,
};


export interface IFileInfo {
    name: string;
    filename: string;
    size: number;
    new: boolean;
    file: any;
};


export default function ProposicaoResultados(props: {
    id_proposicao: number;
    index: number;
    activeTab: number;
}) {
    const { NewAlert } = useAlert();
    const classes = useStyles();
    const { pallet } = usePallet();
    const [loading, setLoading] = useState(false);
    const [resultados, setResultados] = useState<Array<any>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [infoList, setInfoList] = useState<any>();
    const [dataResultados, setDataResultados] = useState();
    const [descricao, setDescricao] = useState("");
    const [idReferencia, setIdReferencia] = React.useState<number>();
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1);
    };
    const [error, setError] = useState<Array<{ name: string; err: string }>>([]);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingAfterSalvar, setLoadingAfterSalvar] = useState(false);
    const [expandedAcordion, setExpandedAcordion] = useState(false);

    const [resultado, setResultado] = useState("");
    const [status, setStatus] = useState("");
    const [posicionamento, setPosicionamento] = useState("");

    function formatDate(dateString: string, formatString: string): string {
        const date = new Date(dateString);
        return format(date, formatString);
    };

    const ErrorMessage = (props: {
        name: string;
        errors: Array<{ name: string; err: string }>;
    }) => {
        const itemError = props.errors.filter((err) => err.name === props.name);
        return (
            <div style={{ color: "red" }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        );
    };

    const handleDataResultadosChange = (dateAc) => {
        setDataResultados(dateAc);
    };

    const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value);
    };

    const loadingListAfterSalvar = () => {
        setLoadingAfterSalvar(true);

        loadResultados(props.id_proposicao);

        setTimeout(() => {
            setLoadingAfterSalvar(false);
        }, 1000);
    };

    async function loadResultados(id_proposicao: number) {
        setLoading(true);
        await ProposicaoResultadosService.GetList(
            id_proposicao,
            currentPage,
            currentPageSize
        ).then((resultados) => {
            if (resultados && resultados.listInfo) {
                setInfoList(() => {
                    return { ...resultados.listInfo };
                });
                setLoading(false);
            };
            if (resultados && resultados.lista) {
                setResultados(() => {
                    return [...resultados.lista];
                });
                setLoading(false);
            };
        });
    };

    React.useEffect(() => {
        if (!idReferencia && props.id_proposicao) {
            setDataResultados(undefined);
            setDescricao("");

            loadResultados(props.id_proposicao);
        };
    }, [idReferencia]);

    async function handleOnAfterUpload() {
        if (idReferencia) {
            setIdReferencia(undefined);
        };

        setTimeout(() => {
            loadingListAfterSalvar();
        }, 400);
    };

    const setPage = (event: object, page: number) => setCurrentPage(page);

    const erroAlteracaoPerfil = () => {
        NewAlert("error", "Não é possível realizar está ação com este perfil.");
    };

    const postResultados = async (e) => {
        e.preventDefault();

        const data = {
            date: dataResultados ? dataResultados : new Date(),
            descricao: descricao,
            responsavel: user.nome,
            resultado: resultado,
            status: status,
            posicionamento: posicionamento
        };

        setError(() => []);

        data && ProposicaoResultadosCreateSchema.validate(data, {
            abortEarly: false,
        }).catch((err) => setError(() => err.errors));

        ProposicaoResultadosCreateSchema.validate(data).catch(function (err) { });

        if (ProposicaoResultadosCreateSchema.isValidSync(data)) {
            await ProposicaoResultadosService.Create(data, props?.id_proposicao, 'p').then((res) => {
                let idResultado = res;
                if (res && res > 0) {
                    setIdReferencia(idResultado);
                    setLoadingForm(true);
                    setLoading(true);

                    NewAlert("success", "Resultado cadastrado com sucesso");

                    setResultado("");
                    setStatus("");
                    setPosicionamento("");
                    setDescricao("");

                    loadingListAfterSalvar();

                    setTimeout(() => {
                        setIdReferencia(undefined);
                        setLoadingForm(false);
                        setLoading(false);
                    }, 100);
                } else {
                    NewAlert("error", "Erro ao cadastrar, verifique os campos e salve novamente");
                    setLoadingForm(false);
                    setLoading(false);
                };
            });
        } else {
            NewAlert("error", "Algum campo está incorreto ou vazio, verifique!");
            setLoadingForm(false);
            setLoading(false);
        };
    };

    const handleChangeAcordion = (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedAcordion(!ativo);
    };

    const handleResultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResultado(event.target.value)
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatus(event.target.value)
    };

    const handlePosicionamentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosicionamento(event.target.value)
    };

    const { user } = useAuthorization();

    return (
        <>
            <div style={{ fontFamily: `${pallet.fontFamily.general}` }}>
                {loadingForm ? (
                    <CircularLoading size={80} top={"50%"} left={"50%"} zIndex={2} />
                ) : (
                    <Grid
                        style={{
                            paddingLeft: `${isMobile ? "0px" : "30px"}`,
                            paddingRight: `${isMobile ? "0px" : "30px"}`,
                            paddingBottom: `${isMobile ? "20px" : "45px"}`,
                        }}
                    >
                        <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }} onChange={handleChangeAcordion(expandedAcordion)}>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        style={{ color: `${pallet.color.primary.font}` }}
                                    />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    backgroundColor: `${pallet.color.primary.background}`,
                                    borderRadius: "5px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                    flexFlow: "row-reverse",
                                    height: "64px",
                                }}
                            >
                                <Typography
                                    className={classes.heading}
                                    style={{
                                        color: `${pallet.color.primary.font}`,
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontSize: `${pallet.fontSize.titleWidgets}`,
                                        fontWeight: pallet.fontWeight.subtitle,
                                        marginLeft: "10px",
                                    }}
                                >
                                    NOVA ATIVIDADE
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                }}
                            >
                                <Grid style={{ padding: "25px 20px 20px 20px" }}>
                                    <Grid item>
                                        <Grid item xl={6} lg={4} xs={12}>
                                            <InputDatePicker
                                                onChange={handleDataResultadosChange}
                                                label={"DATA"}
                                                inputFormat={"dd/MM/yyyy"}
                                                value={dataResultados}
                                                defaultValue={dataResultados}
                                                id={"dataResultados-proposicao"}
                                            />
                                        </Grid>

                                        <Grid item style={{ marginTop: "20px" }} xl={6} lg={6} xs={12}>
                                            <InputMultilineTextField
                                                type={"text"}
                                                maxRows={4}
                                                value={user.nome}
                                                defaultValue={user.nome}
                                                onChange={() => { }}
                                                id={"responsavel"}
                                                label="RESPONSÁVEL"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid spacing={2} container>
                                        <Grid item style={{ marginTop: "20px" }} lg={12} xs={12}>
                                            <InputMultilineTextField
                                                type={"text"}
                                                maxRows={4}
                                                value={descricao}
                                                defaultValue={descricao}
                                                onChange={handleDescricaoChange}
                                                id={"descricao-proposicao"}
                                                label="DESCRIÇÃO"
                                            />
                                            <Grid
                                                style={{
                                                    color: "red",
                                                    fontSize: "10px",
                                                    marginTop: "2px",
                                                    marginLeft: "2px",
                                                }}
                                            >
                                                <ErrorMessage name="descricao" errors={error} />
                                            </Grid>
                                        </Grid>

                                        <Grid item style={{ marginTop: "5px" }} xl={4} lg={4} xs={12}>
                                            <SelectTextFieldA
                                                value={resultado}
                                                onChange={handleResultChange}
                                                options={resultadoItens}
                                                label={"RESULTADO"}
                                                id={"resultado"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='id_responsavel' errors={error} />
                                            </Grid>
                                        </Grid>

                                        <Grid item style={{ marginTop: "5px" }} xl={4} lg={4} xs={12}>
                                            <SelectTextFieldA
                                                value={status}
                                                onChange={handleStatusChange}
                                                options={statusItens}
                                                label={"STATUS"}
                                                id={"status"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='status' errors={error} />
                                            </Grid>
                                        </Grid>

                                        {/* <Grid item style={{ marginTop: "5px" }} xl={4} lg={4} xs={12}>
                                            <SelectTextFieldA
                                                value={posicionamento}
                                                onChange={handlePosicionamentoChange}
                                                options={posicionamentoResultadoItens}
                                                label={"POSICIONAMENTO"}
                                                id={"posicionamento"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='status' errors={error} />
                                            </Grid>
                                        </Grid> */}
                                    </Grid>


                                </Grid>
                            </AccordionDetails>
                            <Grid style={{ paddingBottom: "10px" }}>
                                <Divider />
                            </Grid>

                            <Grid style={{ padding: "20px 18px 20px 18px" }}>
                                <Grid
                                    xs={12}
                                    lg={12}
                                    style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                                >
                                    <Grid
                                        style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.general.size12}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            marginLeft: "9px",
                                        }}
                                    >
                                        ANEXOS
                                    </Grid>
                                    <Grid className="container">
                                        <AnexosComponent
                                            label=""
                                            modulo={EAnexoModulos.Resultados}
                                            id_referencia={idReferencia}
                                            canEdit={true}
                                            autosave={true}
                                            onAfterUpload={handleOnAfterUpload}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid>
                                <Divider />
                            </Grid>

                            <Grid
                                container
                                justifyContent="flex-end"
                                style={{
                                    backgroundColor: `${pallet.backgroundColorTertiary}`,
                                    border: "1px solid #EAEAEA",
                                    padding: "35px 30px 35px 30px",
                                }}
                            >
                                <Grid>
                                    <SolidButton
                                        onClick={user.id_permissao !== 3 ? postResultados : erroAlteracaoPerfil}
                                        color={`${pallet.textColorPrimary}`}
                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                        fontSize={`${pallet.general.size14}`}
                                        title={"SALVAR"}
                                    />
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>
                )}

                {
                    resultados?.length ? (
                        ""
                    ) : !expandedAcordion && (
                        <Grid
                            style={{
                                color: `${pallet.textColorQuaternary}`,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: 600,
                                textAlign: "center",
                                paddingBottom: "150px",
                                marginTop: "20px",
                            }}
                        >
                            <EmptyErrorDoc
                                title="Comece registrando uma nova atividade"
                                description="Você ainda não registrou nenhuma atividade para este parlamentar."
                                subdescription={" "}
                            />
                        </Grid>
                    )
                }


                <Grid
                    style={{
                        padding: `${isMobile ? "5px 5px 0px 15px" : "0px 35px 20px 40px"
                            }`,
                    }}
                >
                    {infoList?.totalItens > 4 ? (
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                            <FilterQuantity
                                list={infoList}
                                currentPageSize={currentPageSize}
                                onChange={handleOnChangeItensPerPageSize}
                            />
                        </Grid>
                    ) : null}
                    <Box style={{ marginTop: "15px" }}>
                        <Stepper orientation="vertical">
                            {resultados?.map((item, index) => (
                                <Step expanded active={false}>
                                    <StepLabel
                                        icon={
                                            <Grid container>
                                                <div
                                                    style={{
                                                        marginTop: "-8px",
                                                        marginLeft: "-7px",
                                                        borderRadius: "50%",
                                                        width: "40px",
                                                        height: "40px",
                                                        position: "relative",
                                                        boxShadow:
                                                            "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                        backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                            marginTop: "7px",
                                                            color: `${pallet.backGroundPaperColorPrimary}`,
                                                        }}
                                                    >
                                                        {item.usuario?.split(" ")[0].substring(0, 2)}
                                                    </div>
                                                </div>
                                            </Grid>
                                        }
                                        optional={
                                            <Grid container justifyContent={"space-between"}>
                                                <Grid>
                                                    <Grid
                                                        container
                                                        style={{
                                                            fontSize: "12px",
                                                            fontFamily: `${pallet.general.fontFamily}`,
                                                        }}
                                                    >
                                                        <Grid item style={{ marginRight: "5px" }}>
                                                            {formatDate(item.data, "dd/MM/yyyy")}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    >
                                        {item.usuario}
                                    </StepLabel>
                                    <StepContent>
                                        <Grid style={{
                                            fontSize: pallet.general.size12,
                                            fontFamily: `${pallet.fontFamily.general}`,
                                        }}>
                                            {item.responsavel ?
                                                <Grid>
                                                    <span style={{ fontWeight: 'bold' }}>Responsável:</span> {item.responsavel}
                                                </Grid> : null
                                            }
                                            {item.resultado ?
                                                <Grid>
                                                    <span style={{ fontWeight: 'bold' }}>Resultado:</span> {item.resultado}
                                                </Grid> : null
                                            }
                                            {item.status ?
                                                <Grid>
                                                    <span style={{ fontWeight: 'bold' }}>Status:</span> {item.status}
                                                </Grid> : null
                                            }
                                            {item.posicionamento ?
                                                <Grid>
                                                    <span style={{ fontWeight: 'bold' }}>Posicionamento:</span> {
                                                        item.posicionamento === 'Contrario' ? 'Contrário' : item.posicionamento === 'Favoravel' ? 'Favorável' : item.posicionamento
                                                    }
                                                </Grid> : null
                                            }
                                        </Grid>
                                        <Grid
                                            whiteSpace={"normal"}
                                            textOverflow={"ellipsis"}
                                            style={{
                                                alignItems: "center",
                                                border: "1px solid #EAEAEA",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            <Grid>
                                                <Typography
                                                    style={{
                                                        wordBreak: "break-all",
                                                        whiteSpace: "pre-wrap",
                                                        padding: "10px",
                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                        fontSize: "13px",
                                                        overflowWrap: "break-word",
                                                    }}
                                                >
                                                    <PreText>{item.descricao}</PreText>
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        wordBreak: "break-all",
                                                        padding: "10px",
                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                        fontSize: "13px",
                                                        overflowWrap: "break-word",
                                                    }}
                                                >
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{
                                            marginTop: "10px"
                                        }}>
                                            <Grid className="container">
                                                <AnexosComponent
                                                    label={""}
                                                    modulo={EAnexoModulos.Resultados}
                                                    id_referencia={item.id}
                                                    canEdit={false}
                                                    autosave={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Grid
                        container
                        justifyContent="center"
                        style={{
                            marginTop: "40px",
                            marginBottom: "40px",
                            paddingBottom: `${isMobile ? "40px" : "0px"}`,
                        }}
                    >
                        {infoList?.totalItens > 4 ? (
                            <Stack spacing={2}>
                                <Pagination
                                    size={isMobile ? "small" : "large"}
                                    onChange={setPage}
                                    count={infoList?.totalPages}
                                    page={currentPage}
                                    shape="rounded"
                                />
                            </Stack>
                        ) : null}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
