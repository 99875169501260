import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobile } from 'react-device-detect';
import { formatDate } from '../../../utils/dateUtils';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AnexosComponent, { EAnexoModulos } from '../../../components/Anexos';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { borderRadius } from '@mui/system';
import styled from "styled-components";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 6px 4px 6px;
    /* margin-bottom: 7px; */
    line-height: 15px;
    letter-spacing: 1px;
`;

const ChipStyleDetail = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;    
    //color: ${(props => props.pallet.general.color4)};
    padding: 4px 6px 4px 0px;
    /* margin-bottom: 7px; */
    line-height: 15px;
    letter-spacing: 1px;
`;


const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 5px;
    margin-top: 3px;
`;

function AccordionTextDataAcaoInfluenciaParlamentarDetail(props: { listadetail: Array<any> }): JSX.Element {


    const { pallet } = usePallet();
    const classes = useStyles();

    const resultadoColor = (value: number) => {
        switch (value) {
            case 1:
                return `${pallet.charts.green}`;
            case 2:
                return `${pallet.charts.red}`;
            case 3:
                return `${pallet.charts.blue}`;
            case 4:
                return `${pallet.charts.yellow}`;
            case 5:
                return `${pallet.charts.yellow}`;
            default:
                return `${pallet.charts.yellow}`;
        }
    }

    // const tagDiscursoCor = (value: string) => {
    //     switch (value.toLowerCase()) {
    //         case "divergente":
    //             return `${pallet.charts.red}`;
    //         case "convergente":
    //             return `${pallet.charts.green}`;
    //         case "neutro":
    //             return `${pallet.charts.lightGrey}`;
    //         default:
    //             return `${pallet.charts.blue}`;

    //     }
    // }

    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: 68px;
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
`;

    return (
        <>
            {props.listadetail?.map(item => {
                return (


                    <Grid key={0} style={{ marginBottom: "10px" }}>

                        <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }} >
                            {/* <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    // backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                    height: "10px",
                                    borderRadius: "5px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}
                            > */}
                            <AccordionSum style={{
                                paddingLeft: `${isMobile ? "5px" : "20px"}`
                            }}
                                expandIcon={<ArrowDropDownIcon
                                    style={{
                                        color: `${pallet.general.color2}`,
                                        fontSize: `${pallet.general.size16}`
                                    }}
                                />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                                <Grid container style={{ marginLeft: "6px" }}>
                                    <Grid>
                                        <CalendarTodayOutlinedIcon style={{ color: `${pallet.backgroundColorPrimary}`, width: "16px", marginBottom: "-7px", marginRight: "5px" }} />
                                    </Grid>

                                    <Typography className={classes.heading} style={{
                                        color: `${pallet.textColorTertiary}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size12}`,
                                        fontWeight: pallet.general.weight_600,
                                        marginTop: "4px",
                                        marginRight: "10px"
                                    }}>
                                        {/* 14/09/2019 */}
                                        {formatDate(new Date(item?.prazo), 'dd/MM/yyyy')}
                                    </Typography>

                                    <ChipStyle pallet={pallet}>
                                        <Grid container>
                                            <DotStyle bg={resultadoColor(item.resultado.id)}>
                                            </DotStyle>
                                            <Grid>
                                                {item.resultado.descricao ? item.resultado.descricao : "Não Classificado"}
                                            </Grid>                                            
                                        </Grid>
                                    </ChipStyle>

                                </Grid>
                            </AccordionSum>

                            {/* </AccordionSummary> */}

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`
                            }}>

                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 20px 10px 5px"}`, width: "100%" }}>
                                    <Grid xl={12}>
                                        <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: "10px" }}>
                                            <Grid xs={12} lg={2} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    RESPONSÁVEL
                                                </Grid>

                                                <Grid style={{ marginBottom: "10px" }}>
                                                    {/* Lucas Dias */}
                                                    {item?.responsavel?.nome}
                                                </Grid>


                                            </Grid>
                                            <Grid xs={12} lg={2} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    PRAZO
                                                </Grid>

                                                <Grid style={{ marginBottom: "10px" }}>
                                                    {/* 14/09/2022 - 15H */}
                                                    {formatDate(new Date(item?.prazo), 'dd/MM/yyyy - H')}H
                                                </Grid>

                                            </Grid>
                                            <Grid xs={12} lg={2} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    CASA LEGISLATIVA
                                                </Grid>

                                                <Grid container style={{ marginBottom: "10px" }}>
                                                    {/* Congresso */}
                                                    {item?.casa?.nome}
                                                </Grid>

                                            </Grid>
                                            <Grid xs={12} lg={2} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    ÂMBITO
                                                </Grid>
                                                <Grid container style={{ marginBottom: "10px" }}>
                                                    {/* Federal */}
                                                    {item?.ambito?.sigla}
                                                    {/* teste sigla */}
                                                </Grid>

                                            </Grid>
                                            <Grid xs={12} lg={2} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    RESULTADO
                                                </Grid>

                                                <Grid container >

                                                    {/* Pendente */}
                                                    <ChipStyleDetail pallet={pallet}>
                                                        <Grid container>
                                                            <DotStyle bg={resultadoColor(item.resultado.id)}>
                                                            </DotStyle>
                                                            <Grid>
                                                                {item.resultado.descricao ? item.resultado.descricao : "Não Classificado"}
                                                            </Grid>
                                                        </Grid>
                                                    </ChipStyleDetail>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xl={12} container justifyContent="space-between">
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <Grid item style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.fontSize.text_10}`,
                                                color: `${pallet.color.secundary.font}`
                                            }}>
                                                DESCRIÇÃO
                                            </Grid>

                                            <Grid style={{
                                                fontWeight: pallet.general.weight_300,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`,
                                                wordWrap: "break-word"
                                            }}
                                            >
                                                {item?.descricao}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {item.anexos.length > 0 ?
                                        <Divider style={{ paddingTop: "30px" }} />
                                        :
                                        null
                                    }
                                    {item.anexos.length > 0 ?
                                        <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: "20px" }}>
                                            <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    ANEXOS
                                                </Grid>

                                                <Grid style={{ marginTop: "10px" }}>

                                                    <Grid lg={12} className="container">

                                                        <AnexosComponent
                                                            label=''
                                                            modulo={EAnexoModulos.AcaoInfluencia}
                                                            id_referencia={item?.id}
                                                            canEdit={false}
                                                            autosave={true} />

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                    }


                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataAcaoInfluenciaParlamentarDetail;

function NewAlert(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
