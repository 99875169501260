import React from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as EmptyStateDoc } from '../img/emptyStateDoc.svg';
import { usePallet } from "../contexts/PalletContext";




function EmptyErrorDoc(props: { title: string, description?: string, subdescription?: string }) {
  const { pallet } = usePallet();
  return (
    <>
      <EmptyStateDoc />
      <h2><Grid style={{fontWeight:pallet.general.weight_600}}> {props.title?props.title:""}</Grid></h2>
      <Grid>{props.description?props.description:""}</Grid>
      <Grid>{props.subdescription?props.subdescription:""}</Grid>
    </>
  );

}

export default EmptyErrorDoc;
