import * as http from '../helpers/http';

class ProposicaoAcaoPreparatoriaService {

    GetList = async (id: number, filtro: any, pageIndex: number, pageSize: number = 8): Promise<any> => {

        const result = await http.get(`/proposicao/${id}/acao-preparatoria?filter=${JSON.stringify(filtro)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalNoFilter: result.data.totalNoFilter,
                    totalPages: result.data.totalPages
                },
                lista: result.data.data

            }
        }
        return undefined;
    }

    Create = async (data: any, id: number): Promise<any> => {
        const result = await http.post(`/proposicao/${id}/acao-preparatoria`, data);
        if (result.success) { return result.data }
        return 0;
    }

    Get = async (id: number): Promise<any> => {
        const result = await http.get(`/proposicao/acao-preparatoria/${id}`);
        if (result.success) { return result }
        return undefined;
    };

    Update = async (data: any, id: number,): Promise<boolean> => {
        const result = await http.put(`/proposicao/acao-preparatoria/${id}`, data);
        if (result.success) { return result.success }
        return false;
    };

    Delete = async (id: number): Promise<boolean> => {
        const result = await http.del(`/proposicao/acao-preparatoria/${id}`);
        if (result.success) { return true }
        return false;
    };


}


export default new ProposicaoAcaoPreparatoriaService();
