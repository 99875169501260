import { Divider, Grid } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import ComboBox from "../../../components/ComboBox";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import { IFilterData } from "../../../components/filter/ListFilter";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import React, { useEffect, useState } from "react";
import PieChartInfoCard from "../../../components/PieChartInfoCard";
import PieChartInfoInsight from "./PieChartInfoInsight";
import Barchart from "../../../components/BarChart"
import InsightService from "../../../services/InsightService";
import { format, subYears } from "date-fns";
import EmptyError from "../../../components/EmptyError";


export function AccordionContentKey2(props: {
  // filtros?: IFiltroTipoProps[],
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string,
  onChange?: (event: any) => void;
}) {

  const { pallet } = usePallet();
  const [listaInterlocutores, setListaInterlocutores] = React.useState([])



  React.useEffect(() => {

    if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {

      InsightService.GetKey2(

        format(new Date(props.dtIni,), "yyyy-MM-dd"),
        format(new Date(props.dtFin), "yyyy-MM-dd")
      ).then((res) => {
        if (res) {
          setListaInterlocutores(res.data)
        }
      });

    }

  }, [props.dtIni, props.dtFin]);
  return (
    <>
      {listaInterlocutores.length > 0 ?
        <Grid>
          <Barchart dtIni={props.dtIni} dtFin={props.dtFin} listaDados={listaInterlocutores} />
        </Grid>
        :
        <Grid
          style={{
            color: `${pallet.textColorQuaternary}`,
            fontSize: `${pallet.fontSize.titleWidgets}`,
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: 600,
            textAlign: "center",
            paddingBottom: "98px",
            marginTop: "20px",
          }}
        >
          <EmptyError title="Ops!" description="Ainda não temos dados suficientes para gerar resultados." />
        </Grid>


      }
    </>

  )
}

export default AccordionContentKey2;
