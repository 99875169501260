import React from 'react';
import styled from 'styled-components';
import { Grid, Tooltip } from '@mui/material';
// import blankAvatar from '../../../img/avatar_foto.jpg'
import ImageLoader from '../../../components/ImageLoader';


// const StyledImageLoader = styled(ImageLoader)`
//     width: ${(props) => false ? "130px" : "260px"};
//     margin-left: ${(props) => false ? "0px" : "-65px"};
//     margin-top: ${(props) => false ? "0px" : "-45px"};
//     //opacity: ${(props) => false ? "0px" : "70%"};    
// `

////////////////////////////////////////////////////////////////////
//  Avatar e Tooltip do card de Lista do parlamentar
////////////////////////////////////////////////////////////////////
const ParlamentarCardAvatar = (props: { item: any, pallet: any, idPermissao?: number }) => {
    return (
        
            props.idPermissao !== 4 ? 

            <Tooltip title={props.item.qualificacao} arrow placement={"bottom"}>
            <Grid container justifyContent="center">
                <div style={{ marginTop: "30px" }}>
                    <ImageLoader
                        src={props.item.urlImg}
                        pallet={props.pallet}
                        canEdit={false}
                    //onLoad={onImageSelect}
                    />
                </div>
            </Grid>
        </Tooltip>

        :

        <Grid container justifyContent="center">
        <div style={{ marginTop: "30px" }}>
            <ImageLoader
                src={props.item.urlImg}
                pallet={props.pallet}
                canEdit={false}
            //onLoad={onImageSelect}
            />
        </div>
    </Grid>
            
        


    )
}

export default ParlamentarCardAvatar;