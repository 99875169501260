import * as http from '../helpers/http';

class ParlamentarAmbitoService {

    GetList = async (id: number, filter: any, pageIndex: number, pageSize: number): Promise<any> => {
        //const result = await http.get(`/parlamentar/${id}/ambitos?filtro=${filtro}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        let dynamicPageSize = pageSize ? pageSize : 4
        filter = filter ? filter : []
        const result = await http.get(`/parlamentar/${id}/ambitos?pageSize=${dynamicPageSize}&pageIndex=${pageIndex}&filter=${JSON.stringify(filter)}`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                lista: result.data.data
            }
        }
        return undefined;
    }

    GetListInsight = async (id: number,dtIni: any, dtFin: any, filter: any, pageIndex: number, pageSize: number): Promise<any> => {
        let dynamicPageSize = pageSize ? pageSize : 4
        filter = filter ? filter : []
        const result = await http.get(`/parlamentar/${id}/${dtIni}/${dtFin}/ambitosInsight?pageSize=${dynamicPageSize}&pageIndex=${pageIndex}&filter=${JSON.stringify(filter)}`);
      
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                lista: result.data.data
            }
        }
        return undefined;
    }

    GetSummary = async (id: number): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}/ambitos/summary`);
        if (result.success) {
            return result.data;
        }
        return undefined;
    }


    GetSummaryList = async (id: number): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}/ambitos/summaryList`);
        if (result.success) {
            return result.data;
        }
        return undefined;
    }

}

export default new ParlamentarAmbitoService();
