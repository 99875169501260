import PrivateRoute from '../../components/PrivateRoute';
import InteligenciaArtificialLista from './InteligenciaArtificial.Lista';
import IATemasRelacionadosConsulta from './_components/IA.TemasRelacionados.Consulta';

export function InteligenciaArtificialRoutes() {
    return (
        <>
            <PrivateRoute path="/ia" element={<InteligenciaArtificialLista />} />
            <PrivateRoute path="/ia/relacionar-temas" element={<IATemasRelacionadosConsulta />} />
            <PrivateRoute path="/ia/relacionar-temas/:idTema" element={<IATemasRelacionadosConsulta />} />
        </>
    );
}

