import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { usePallet } from '../../contexts/PalletContext';
import useStyles from '../../components/layout/Styles';
import { Grid, MenuItem, TextField } from '@mui/material';
import { IListInfo } from '../../models/IListInfo';
import { isMobile } from 'react-device-detect';


const FilterQuantityGrid = styled(Typography)`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    
    >span {
        color: #9B9B9B;
        font-weight: 500;
        font-size: 12px;
    }
    >strong {

        &:first-child {
            font-weight: bold;
        }
    }
`;

const FilterQuantityInput = styled(TextField)`
     margin: 0 10px;
 
     .MuiOutlinedInput-input {
         width: 30px;
         padding-top: 10px;
         padding-bottom: 10px;
 
         svg {
         }
    }
`;


const FilterQuantity = (props: { list: IListInfo, currentPageSize: number, onChange: (data: any) => void }) => {
    const { pallet } = usePallet();
    const classes = useStyles();

    const InputProps = {
        classes: {
            notchedOutline: classes.notchedOutline,
        },
        style: {
            fontFamily: `${pallet.general.fontFamily}`,
            fontSize: `${pallet.general.size14}`,
            fontWeight: pallet.general.weight_100,
            color: `${pallet.textColorQuaternary}`,
            background: `${pallet.backGroundPageColorSecondary}`,
            paddingTop: "10",
            paddingBottom: "10",
        }
    };

    return (
        <FilterQuantityGrid >
            <span className="filterTitle">
                EXIBINDO
            </span>
            <FilterQuantityInput
                select
                value={props.currentPageSize}
                onChange={props.onChange}
                id="setPageSize"
                variant="outlined"
                InputProps={InputProps}
            >
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
                <MenuItem value="300">300</MenuItem>
                <MenuItem value="500">500</MenuItem>
            </FilterQuantityInput>
            {/* {<strong>{infoList.pageItens}</strong>} */}
            <strong> / {props.list?.totalItens ? props.list?.totalItens : 0} </strong>
        </FilterQuantityGrid>
    );
};

export default FilterQuantity;
