import * as React from 'react';

import { Box, Grid, Link, MenuItem, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { usePallet } from '../../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import { useAlert } from "../../../../contexts/AlertContext";
import useStyles from '../../../../components/layout/Styles';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/BootstrapDialogTitle';


import { useAuthorization } from '../../../../contexts/AuthorizationContext';
import DiarioService from '../../../../services/DiarioService';

export default function BuscaGeralDiarioModal(props: { id: number}) {
    const { pallet } = usePallet();
    const [open, setOpen] = React.useState(false);
    const [texto, setTexto] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // getItem();

        if(open) {
            DiarioService.GetTextoDiario(String(props.id)).then(async (res) => {
                console.log("REDJSAJDLKSAJ",res)
                setTexto(res?.data[0]?._source?.texto);
              });
    
        }
        

    }, [open])

    const { user } = useAuthorization();



    const { NewAlert } = useAlert();
    const permissaoErro = () => {
  
        NewAlert("error","Não é possível realizar está ação com este perfil.")
        }



    return (
        <div>
            <Link href="#" onClick={handleClickOpen} underline="none"
                style={{
                    fontWeight: pallet.fontWeight.subtitle,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.secundary.font}`
                }}>
                {'Ver texto completo'}
            </Link>

            <BootstrapDialog style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
                maxWidth={"lg"}
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}                
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    DIÁRIO
                </BootstrapDialogTitle>

        



                <Divider style={{ marginBottom: "15px" }} />

                <Grid style={{
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size16}`,
                    fontWeight: pallet.general.weight_300,
                    alignItems: "center",
                    lineHeight: "1.6",
                    color: "#1E0736",
                    height: "250px",
                    textAlign: "justify",
                    width: "100%",
                    overflow: "scroll",
                    overflowX: "hidden",
                    padding: `${isMobile ? "10px 15px 10px 15px" : "20px 20px 20px 30px"}`
                }}>
                    {texto}
                </Grid>

            </BootstrapDialog>
        </div >
    );
}
