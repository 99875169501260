import * as Yup from "yup";

const ParlamentarCadastroSchema = Yup.object({
    esfera:  Yup.string().nullable(true).required({ name: "esfera", err: "Escolha de esfera é obrigatória" }),
    poder: Yup.string(),
    pessoal: Yup.object({
        nome: Yup.string().required({ name: "nome", err: "Nome é obrigatório" }),
        apelido: Yup.string().required({ name: "apelido", err: "Apelido é obrigatótio" }),
        cpf: Yup.string().nullable(true).optional(),
        foto: Yup.string().nullable(true).optional(),
        uri: Yup.string().url({ name: "url", err: "URL inválida" }).nullable(true).optional(),
        sexo: Yup.string().nullable(true).optional(),
        municipio: Yup.string().nullable(true).optional(),
        escolaridade: Yup.string().nullable(true).optional(),
        uf: Yup.string().nullable(true).optional(),
        dataNascimento: Yup.date().nullable(true).optional(),
        dataFalecimento: Yup.date().nullable(true).optional(),
    }),
    profissional: Yup.object({
        partido: Yup.string().nullable(true).optional(),
        cargo: Yup.string().nullable(true).optional(),
        uf: Yup.string().nullable(true).optional(),
        municipio: Yup.string().nullable(true).optional(),
        situacao: Yup.string().nullable(true).optional(),
        endereco: Yup.mixed().nullable(true).optional(),
        legislatura: Yup.number().nullable(true).optional(),
    }),
    telefones: Yup.array().of(Yup.string()).nullable(true).optional(),
    emails: Yup.array().of(Yup.string().email({ name: "email", err: "Email inválido" })).nullable(true).optional(),
    tipo: Yup.string().nullable(true).required({ name: "tipo", err: "Em que tipo seu cadastrado se enquadra?" }),
    profissoes: Yup.array().of(Yup.string()).nullable(true).optional(),
    cliente: Yup.object({
        seguindo: Yup.boolean(),
        notificar: Yup.boolean().nullable(true).optional(),
        qualificacao: Yup.string().required({ name: "qualificacao", err: "A qualificação é obrigatória" }),
        observacao: Yup.mixed().nullable(true).optional(),
    }),
    social: Yup.object({
        rede1: Yup.string().url({ name: "facebook", err: "URL inválida, é necessário o formato 'https ou http" }).nullable(true).optional(),
        rede2: Yup.string().url({ name: "instagram", err: "URL inválida, é necessário o formato 'https ou http" }).nullable(true).optional(),
        rede3: Yup.string().nullable(true).optional(),
    }),
    imagem: Yup.object().shape({ file: Yup.object().shape({ name: Yup.string() }) }).nullable(true).optional()
});


export default ParlamentarCadastroSchema;