import React from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import { Menu, FormControl, Divider, IconButton, MenuItem, ListItemIcon, ListItemText, Switch, FormGroup, FormControlLabel } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useNavigate } from 'react-router-dom';
import { usePallet } from '../../../../contexts/PalletContext';
import { useAlert } from "../../../../contexts/AlertContext";
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import NotificationsContextService, { INotificationsContextResultData } from '../../../services/NotificationsContextService';
import { Create } from '@material-ui/icons';
import RuleIcon from '@mui/icons-material/Rule';
import TCService from '../../../../services/TCService';
import TCTemaCorporativoGrupoEdicao from '../Grupo/TCTemaCorporativoGrupoEdicao';
import TCTemaCorporativoSubTemaEdicao from './TCTemaCorporativo.SubTema.Edicao';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';

export interface IRegistroGrupo {
  idregister: number,
  ativo: boolean,
}


const MenuNotification = styled(Menu)`
li {
  font-family: ${(props => props.pallet.fontFamily.general)};
  font-weight: ${(props => props.pallet.fontWeight.lighter)};
  font-size: ${(props => props.pallet.fontSize.titleWidgets)};}

  a {
    display: flex;
    color: #1E0736;
    text-decoration: none;
  }

  svg {
    color: #1E0736;
  }

  .MuiDivider-fullWidth {
    margin: 3px 0px;
  }
  .MuiFormControlLabel-labelPlacementEnd {
    min-width: 60px;
    display: flex;
    flex-flow: row-reverse;
    margin-left: unset;
    margin-right: unset;

    span:last-child {
      margin-right: auto;
    }
  }
`;

export default function TCMenuButtonIconEditActiveSubTema(props: {
  loading?: boolean,
  index: any,
  idregister: number,
  seativo: boolean,
  path?: string,
  onChange?: () => void
}) {
  const { pallet } = usePallet();
  const navigate = useNavigate();
  const { NewAlert } = useAlert();

  const [checked, setChecked] = React.useState<boolean>(!!props.seativo);

  const handleOnChangeActive = (id_reg: number, index?: any) => {

    TCService
      .putSubTemaActive(id_reg, { data: { ativo: !props.seativo } })
      .then((res) => {

        if (res) {
          NewAlert("success", "Registro alterado.");
          setChecked(!!props.seativo)
          props.onChange()
        } else {
          NewAlert("error", "Houve um erro. Tente novamente.");
        }
      })
  };

  const { user } = useAuthorization();

  const erroAlteracaoPerfil = () => {
    NewAlert(
      "error",
      "Não é possível realizar está ação com este perfil."
    );
  }

  return (
    <PopupState variant="popover" popupId="notification-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <IconButton
            {...bindTrigger(popupState)}
          >
            <MoreHorizIcon />
          </IconButton>
          <MenuNotification {...bindMenu(popupState)} pallet={pallet}>
            <FormControl component="fieldset" onChange={() => { return true }} style={{ minWidth: "195px" }}>
              <MenuItem>
                <TCTemaCorporativoSubTemaEdicao
                  idregister={props.idregister}
                  reloadList={props.onChange}
                  closeMenu={popupState.close}
                />
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <RuleIcon fontSize="small" />
                </ListItemIcon>
                <FormGroup>
                  <FormControlLabel control={
                    <Switch
                      checked={props.seativo}
                      onChange={() => user.id_permissao !== 3 ?
                        handleOnChangeActive(props.idregister, props.index) : erroAlteracaoPerfil
                      }
                    />
                  } label={
                    <ListItemText>{props.seativo ? "Ativo" : "Inativo"}</ListItemText>
                  } />
                </FormGroup>

              </MenuItem>

            </FormControl>
          </MenuNotification>
        </React.Fragment>
      )}
    </PopupState>

  );
}