import styled from "styled-components";

import { Button } from '@mui/material';

import { usePallet } from '../contexts/PalletContext';


const ButtonLayout = styled(Button)`
  height: 35px;
  margin-top: 8px;
  text-transform: capitalize;
  font-family: ${(props => props.pallet.fontFamily.general)};
  font-weight: ${(props => props.pallet.general.weight_bold)};
  font-size: ${(props => props.pallet.general.size12)};

`;

interface IProps {
  id: number,
  status: boolean,
  onClick: () => void,
  marginRight?: any,
  width?: any,
};

export default function ButtonNaoSeguir(props: IProps) {
  const { pallet } = usePallet();

  const handleClick = () => {
    props.onClick();
  };

  return (
    <>
      <ButtonLayout
        disabled={!(props.status == null || props.status)}
        onClick={() => handleClick()}
        variant="contained"
        pallet={pallet}
        disableElevation
        style={{
          marginRight: props.marginRight,
          width: props.width ? props.width : "100%",
          backgroundColor: `${props.status == null || props.status ? pallet.color.primary.background : pallet.color.colorBase.white} `,
          border: `1px solid ${props.status == null || props.status ? "none" : pallet.color.primary.background} `,
          color: `${props.status == null || props.status ? pallet.color.colorBase.white : pallet.color.secundary.font} `,
        }}
      >
        {props.status == null || props.status ? "NÃO SEGUIR" : "NÃO SEGUINDO"}
      </ButtonLayout>
    </>
  );
}
