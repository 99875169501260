import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, TextField, Typography, Button, Dialog, DialogTitle, IconButton } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { formatDate } from '../../../utils/dateUtils';
// import { styled } from '@mui/material/styles';
import styled from 'styled-components';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DeadLineDate from '../../../components/DeadLineDate';
import AnexosComponent, { EAnexoModulos } from '../../../components/Anexos';
import CloseIcon from '@mui/icons-material/Close';
import SelectTextFieldA from '../../../components/SelectTextFieldA';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ParlamentarDemandaService from '../../../services/ParlamentarDemandaService';
import format from 'date-fns/format';
import InputTextField from '../../../components/InputTextField';
import UsuarioService from '../../../services/UsuarioService'
import { useAlert } from '../../../contexts/AlertContext';
import InputMultilineTextField from '../../../components/InputMultilineTextField';
import SolidButton from '../../../components/SolidButton';
import { ICheckBoxNestedItem, InputDropDownCheckBoxNested } from '../../../components/CheckBoxNested';
import { arraysEquals } from '../../../utils/array.utils';
import { useNavigate, useParams } from 'react-router';
import ParlamentarDemandaSchemaYup from '../models/ParlamentarDemandasSchema';
import ButtonDialogConfirmation from '../../../components/ButtonDialogConfirmation';
import ParlamentarDemandasSchema from '../models/ParlamentarDemandasSchema';
import EditIcon from '@mui/icons-material/Edit';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, textTransform: 'uppercase', }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton >
            ) : null}
        </DialogTitle >
    );
};

export default function ParlamentarDemandasEdicao(props: {
    idDemanda?: number,
    onChange?: (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    reloadList?: () => void,
    closeMenu?: () => void,
    idParlamentar?: number,
    lista?: Array<any>,
    idPermissao?: number
}) {

    const { pallet } = usePallet();
    const { NewAlert } = useAlert();
    const navigate = useNavigate();
    const [idReferencia, setIdReferencia] = React.useState<any>();
    const [open, setOpen] = useState<boolean>(false)
    const [idDemanda, setIdDemanda] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [posicionamentos, setPosicionamentos] = useState<Array<number>>([]);
    const [infoList, setInfoList] = useState<any>();
    const [demanda, setDemanda] = useState({});
    const [prazo, setPrazo] = useState(Date);
    const [prioridade, setPrioridade] = useState('');
    const classes = useStyles();
    const [assunto, setAssunto] = useState('');
    const [objetivo, setObjetivo] = useState('');
    const [status, setStatus] = useState('');
    const [conclusao, setConclusao] = useState('');
    const [departamento, setDepartamento] = useState<Array<ICheckBoxNestedItem>>();
    const [userList, setUserList] = useState<Array<number>>([]);
    const [lockedUserList, setLockedUserList] = useState<Array<number>>([]);
    const [onSave, setOnSave] = React.useState(false)
    const [error, setError] = useState<Array<{ name: string, err: string }>>([])
    const [data, setData] = useState({});


    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }


    React.useEffect(() => {
        UsuarioService.GetByDpto().then(async res => {
            const comboItens = res.map(departamento => {
                return {
                    id: departamento.id,
                    label: departamento.nome,
                    items: departamento.usuarios.map(usuario => {
                        return {
                            id: usuario.id,
                            label: usuario.nome,
                            value: false
                        }
                    })
                }
            })
            setDepartamento(comboItens)
        },
        )
    }, []);

    async function handleOnAfterUpload() {
        if (idReferencia) {
            setIdReferencia(undefined);
            setOnSave(false)
        }
    }


    const handleAssuntoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAssunto(event.target.value)
    };

    const handlePrazoChange = (prazoData) => {
        setPrazo(prazoData)
    };

    const handleObjetivoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setObjetivo(event.target.value)
    };

    const handlePrioridadeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrioridade(event.target.value)
    };

    const handleClickOpen = (id) => {
        ParlamentarDemandaService.Get(props.idDemanda).then(res => {

            setPrazo(res.data.prazo)
            setAssunto(res.data.assunto)
            setObjetivo(res.data.objetivo)
            setPrioridade(res.data.prioridade)
            setUserList(res.data.posicionamentos.map(item => {
                return item.id_responsavel;
            }))
            setLockedUserList(res.data.posicionamentos.map(item => {
                if (item.status === "Preenchido")
                    return item.id_responsavel;
            }))
        })

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.reloadList();
    };

    const editarNovaDemanda = () => {
        setOnSave(true)

        const data = {
            prazo: prazo ? new Date(prazo) : null,
            assunto: assunto,
            objetivo: objetivo,
            prioridade: prioridade,
            posicionamentos: userList,
        }
        setError(() => [])
        data && ParlamentarDemandasSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));
        

        setData(data)

        ParlamentarDemandaService.Update(data, props?.idDemanda).then(res => {
            if (res) {
                NewAlert('success', 'Demanda atualizada com sucesso');
                if (props.reloadList) {
                    props.reloadList();
                }
                handleClose();
            } else {
                NewAlert('error', 'Verifique os campos e tente outra vez');

            }
        })
            .then(data => {
                // props?.onChange()

            })
    }

    const handleSetUserList = React.useCallback((data) => {
        if (!arraysEquals(userList, data.selected)) {

            setDepartamento(() => [...data.list])

            setUserList(data.selected)
        }
    }, [departamento])

    const DotPrioridade = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 25px;
`;
    const corPrioridade = (value: string) => {

        switch (value) {
            case 'Máxima':
                return `${pallet.charts.red}`;
            case 'Baixa':
                return `${pallet.charts.blue}`;
            case 'Média':
                return `${pallet.charts.yellow}`;
            case 'Alta':
                return `${pallet.charts.purple}`;
            case 'Mínima':
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }
    function LabelHandle(props: { prioridade: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPrioridade bg={corPrioridade(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.prioridade}
                    </Grid>
                </Grid>
            </>
        )
    }

    const prioridades = [
        { key: 1, label: <LabelHandle prioridade={"Mínima"} bg={"Mínima"} />, value: "Mínima" },
        { key: 2, label: <LabelHandle prioridade={"Baixa"} bg={"Baixa"} />, value: "Baixa" },
        { key: 3, label: <LabelHandle prioridade={"Média"} bg={"Média"} />, value: "Média" },
        { key: 4, label: <LabelHandle prioridade={"Alta"} bg={"Alta"} />, value: "Alta" },
        { key: 5, label: <LabelHandle prioridade={"Máxima"} bg={"Máxima"} />, value: "Máxima" },
    ]


    
    const permissaoErro = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil.")

    }

    return (
        <div>
            {/* <Link href="#" onClick={handleClickOpen} underline="none"
                style={{
                    // fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    marginRight: "30px"


                }}>
                {'Editar'}
            </Link> */}

            <Button onClick={props.idPermissao !== 3 ?  handleClickOpen : permissaoErro}
                style={{
                    marginLeft: "-7px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    paddingRight: "85px",
                    marginRight: "-10px"
                }}>
                <EditIcon style={{ marginRight: "14px" }} />
                <div style={{ fontSize: 0 }}>@</div>Editar
            </Button>

            <BootstrapDialog style={{ paddingLeft: `${isMobile ? "5px" : "10px"}`, paddingRight: `${isMobile ? "5px" : "10px"}`, borderRadius: "6px 6px 0px 0px", }}
                maxWidth={"md"}
                onClose={handleClose}
                aria-labelledby="customized-dialog"
                open={open}

            >
                <Grid container xl={12} lg={12} xs={12} sx={{
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    height: `${isMobile ? "50px" : "80px"}`,
                    borderRadius: "6px",
                    boxShadow: "0px 10px 20px rgba(31, 32, 65, 0.05)"
                }}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <Grid style={{
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: pallet.general.weight_bold,
                            color: `${pallet.color.tertiary.font}`,
                            fontSize: `${pallet.general.size18}`,
                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                            padding: "4px 0px 0px 26px",
                        }}>
                            Editar Demanda
                        </Grid>
                    </BootstrapDialogTitle>

                </Grid>
                <Grid>
                    < Divider variant="fullWidth" />
                </Grid>
                <Grid >
                    <Grid container xl={12} lg={12} xs={12} style={{
                        paddingTop: "20px",
                        paddingLeft: `${isMobile ? "10px" : "20px"}`,
                        paddingRight: `${isMobile ? "10px" : "20px"}`,
                    }}>
                        <Grid container spacing={3}
                            style={{ paddingLeft: `${isMobile ? "5px" : "10px"}`, paddingRight: `${isMobile ? "5px" : "10px"}` }}
                        >
                            <Grid item lg={6} xs={12} style={{
                                paddingRight: `${isMobile ? "0px" : "30px"}`,
                                // paddingBottom: `${isMobile ? "15px" : "30px"}`,
                                paddingTop: `${isMobile ? "15px" : "10px"}`,
                            }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}
                                    style={{
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.general.weight_600,
                                        color: `${pallet.general.color1}`,
                                    }}>
                                    <DeadLineDate
                                        // focused={true}
                                        onChange={handlePrazoChange}
                                        label={"PRAZO"}
                                        minDate={new Date}
                                        inputFormat={"dd/MM/yyyy"}
                                        value={prazo}
                                        id={"prazo-demandas"}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item lg={6} xs={12} style={{
                                // paddingBottom: `${isMobile ? "15px" : "30px"}`,
                                paddingTop: `${isMobile ? "0px" : "10px"}`,
                            }}>
                                <SelectTextFieldA
                                    value={prioridade}
                                    onChange={handlePrioridadeChange}
                                    options={prioridades}
                                    label={"PRIORIDADE"}
                                    id={"prioridade-demandas"}
                                />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <InputMultilineTextField
                                    type={"text"}
                                    // focused={true}
                                    // placeholder={"Digite"}
                                    maxRows={4}
                                    value={assunto}
                                    defaultValue={assunto}
                                    onChange={handleAssuntoChange}
                                    id={"assunto-demandas"}
                                    label="ASSUNTO"
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "15px" }}>
                                    <ErrorMessage name='assunto' errors={error} />
                                </Grid>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <InputMultilineTextField
                                    type={"text"}
                                    // placeholder={"Digite"}
                                    maxRows={4}
                                    value={objetivo}
                                    defaultValue={objetivo}
                                    onChange={handleObjetivoChange}
                                    id={"descricao-demandas"}
                                    label="OBJETIVO"
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "15px" }}>
                                    <ErrorMessage name='objetivo' errors={error} />
                                </Grid>
                            </Grid>

                            <Grid xl={2} lg={2} xs={12} container style={{
                                paddingLeft: `${isMobile ? "25px" : "30px"}`
                            }}
                                justifyContent="flex-start"
                                alignItems="center">
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid>
                        < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "30px"}`, marginBottom: `${isMobile ? "15px" : "30px"}` }} />
                    </Grid>

                    <Typography className={classes.heading} style={{
                        color: `${pallet.color.secundary}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontSize: `${pallet.general.size12}`,
                        fontWeight: pallet.general.weight_600,
                        alignItems: "center",
                        letterSpacing: "1px",
                        borderRadius: "2px",
                        textTransform: "uppercase",
                        marginLeft: `${isMobile ? "25px" : "25px"}`,
                        marginBottom: `${isMobile ? "10px" : "10px"}`,

                    }}>
                        Solicitar Posicionamento de
                    </Typography>
                    <Grid item lg={12} xs={12}
                        style={{
                            paddingLeft: `${isMobile ? "15px" : "24px"}`,
                            paddingRight: `${isMobile ? "15px" : "24px"}`
                        }}
                    >
                        {departamento &&
                            <>
                                <InputDropDownCheckBoxNested
                                    items={departamento}
                                    onChange={handleSetUserList}
                                    label={"Solicitacao de"}
                                    selectedItems={userList}
                                    lockedItems={lockedUserList}

                                />
                            </>
                        }
                    </Grid>
                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "15px" }}>
                        <ErrorMessage name='posicionamentos' errors={error} />
                    </Grid>
                    <Grid>
                        < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "20px" : "40px"}` }} />
                    </Grid>
                    <Grid style={{
                        paddingTop: `${isMobile ? "15px" : "30px"}`,
                        paddingLeft: `${isMobile ? "15px" : "30px"}`,
                        paddingRight: `${isMobile ? "15px" : "30px"}`,
                    }}>
                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid style={{
                                fontWeight: pallet.fontWeight.subtitle,
                                fontSize: `${pallet.general.size12}`,
                                color: `${pallet.color.tertiary.font}`,

                            }}>
                                ANEXOS
                            </Grid>
                            <Grid style={{ marginTop: "10px" }}>
                                <Grid className="container">

                                    <AnexosComponent
                                        label={''}
                                        modulo={EAnexoModulos.DemandasParlamentar}
                                        id_referencia={props.idDemanda}
                                        save={onSave}
                                        onAfterUpload={handleOnAfterUpload}
                                        canEdit={true}
                                        autosave={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    < Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                </Grid>
                <Grid container alignItems="center" sx={{
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    height: `${isMobile ? "50px" : "80px"}`,
                    borderRadius: "6px",
                    boxShadow: "0px 10px 20px rgba(31, 32, 65, 0.05)"


                }}>
                    <Grid container alignItems="center" justifyContent="end"
                        style={{
                            paddingRight: `${isMobile ? "20px" : "0px"}`,
                            textAlignLast: "end",
                            padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,

                        }}>
                        <Grid item>
                            <ButtonDialogConfirmation
                                styles={{
                                    borderRadius: "6px 6px 0px 0px",
                                    marginRight: "20px",
                                    textTransform: "capitalize",
                                    color: `${pallet.general.color1}`,
                                }}
                                title={"CONFIRMAÇÃO"}
                                title_button={"Cancelar"}
                                content={"CANCELAR CADASTRO?"}
                                message={"Esta ação não poderá ser desfeita."}
                                confirmation_button={"Cancelar"}
                                confirmationEvent={() => {
                                    handleClose()
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <SolidButton
                                onClick={editarNovaDemanda}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={"SALVAR"}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </BootstrapDialog>
        </div >
    );
}
