import * as http from '../helpers/http';

class IAService {
  setTemasRelacionados = async (id_tema: number, temas_relacionados: number[]): Promise<any> => {
    const result = await http.post(`/ia/temas-relacionados/${id_tema}`, temas_relacionados);

    if (result.success) {
      return result.data
    }
    return undefined;
  };

  getTemasRelacionados = async (id?: number): Promise<any> => {
    const result = await http.get(`/ia/temas-relacionados/${id}`);
    if (result.success) { return result.data }
    return undefined;
  };

  getTemas = async (pageSize?: Number, pageIndex?: Number, filter?: string): Promise<any> => {
    const result = await http.get(`/ia/temas-relacao?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
    if (result.success) {
      return {
        success: true,
        listInfo: {
          pageSize: result.data.pageSize,
          pageIndex: result.data.pageIndex,
          pageItens: result.data.pageItens,
          totalItens: result.data.totalItens,
          totalPages: result.data.totalPages
        },
        data: result.data.data
      }
    }
    return undefined;
  };

  UpdateTreinamento = async (data: any, id: number): Promise<boolean> => {
    const result = await http.put(`/ia/treinamento/${id}`, data);
    if (result.success) { return result.success }
    return false;
  };

  delete = async (id: number): Promise<boolean> => {
    const result = await http.del(`/ia/treinamento/${id}`);
    if (result.success) { return result.success }
    return false;
  };

  Create = async (data: any): Promise<number> => {
    const result = await http.post(`/ia/treinamento`, data);
    if (result.success) { return result.data }
    return 0;
  };

  getTreinamentoList = async (pageSize?: Number, pageIndex?: Number, filter?: string): Promise<any> => {
    const result = await http.get(`/ia/treinamento-list?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
    if (result.success) {
      return {
        success: true,
        listInfo: {
          pageSize: result.data.pageSize,
          pageIndex: result.data.pageIndex,
          pageItens: result.data.pageItens,
          totalItens: result.data.totalItens,
          totalPages: result.data.totalPages
        },
        data: result.data.data
      }
    }
    return undefined;
  };

}

export default new IAService();
