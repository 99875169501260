import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import TabPanel from "../../../components/carousel/TabPanel";
import { usePallet } from "../../../contexts/PalletContext";
import ParlamentarDemandasDados from "./ParlamentarDemandasDados";
import ParlamentarDemandasDemanda from "./ParlamentarDemandasDemanda";

const ParlamentarCarouselTabsDemandas = (props: { id_parlamentar: number, activeTab: any, index: number,idPermissao?: number }) => {

    const { pallet } = usePallet();

    const [changeDadosOuDemanda, setChangeDadosOuDemanda] = React.useState("dadosdemanda")

    const trocaParaDadosOuDemanda = (key: string) => {
        setChangeDadosOuDemanda(key)
    }
    
    return (

        <Paper style={{ borderRadius: "6px", marginTop: `${isMobile || props.idPermissao === 4 ? "0px" : "-30px"}` }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <Grid container style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                    <Grid alignItems="flex-start" style={{ marginRight: "20px" }}>
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuDemanda}
                            tipo={"dadosdemanda"}
                            backgroundColor={changeDadosOuDemanda === "dadosdemanda" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuDemanda === "demanda" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuDemanda === "demanda" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"DADOS"}
                        />
                    </Grid>
                    <Grid alignItems="flex-start">
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuDemanda}
                            tipo={"demanda"}
                            backgroundColor={changeDadosOuDemanda === "demanda" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuDemanda === "dadosdemanda" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuDemanda === "dadosdemanda" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"DEMANDAS"}
                        />
                    </Grid>
                </Grid>
                {
                    changeDadosOuDemanda === "dadosdemanda" ?
                        <ParlamentarDemandasDados id_parlamentar={props.id_parlamentar}  /> :
                        <ParlamentarDemandasDemanda id_parlamentar={props.id_parlamentar} idPermissao={props.idPermissao} />
                }
            </TabPanel>
        </Paper>

        // <TabPanel value={props.activeTab} index={props.index}>
        //     <div style={{ fontFamily: `${pallet.fontFamily.general}` }}>
        //         Demandas e conteúdo
        //     </div>
        // </TabPanel>
    )
}

export default ParlamentarCarouselTabsDemandas;