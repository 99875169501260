import * as Yup from "yup";

const ProposicaoEmendaCreateSchema = Yup.object({
       dataApresentacao: Yup.date().nullable(true).optional(),
       ementa: Yup.string().required({ name: "ementa", err: "Campo com preenchimento obrigatório" }),
       numero: Yup.string().required({ name: "numero", err: "Campo com preenchimento obrigatório" }),
       posicionamento: Yup.string().required({ name: "posicionamento", err: "Campo com preenchimento obrigatório" })
});


export default ProposicaoEmendaCreateSchema;