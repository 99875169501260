import { Grid, Divider } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import ComboBox from "../../../components/ComboBox";
import { IFilterData } from "../../../components/filter/ListFilter";
import ProposicaoService from "../../../services/ProposicaoService";
import React from "react";
import { format } from "date-fns";
import InsightService from "../../../services/InsightService";

export function AccordionContentKey10(props: {
  // filtros?: IFiltroTipoProps[],
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string,
  titleFederal?: string,
  originFederal?: string,
  onChange?: (event: any) => void;
}) {
  const ErrorMessage = (props: {
    name: string;
    errors: Array<{ name: string; err: string }>;
  }) => {
    const itemError = props.errors.filter((err) => err.name === props.name);
    return (
      <div style={{ color: "red", fontSize: "11px", marginTop: "5px" }}>
        {itemError.length === 1 && itemError[0].err}
      </div>
    );
  };

  const [error, setError] = useState<Array<{ name: string; err: string }>>([]);
  const { pallet } = usePallet();
  const [listaDescricao, setListaDescricao] = useState<any[]>([]);
  const [descricaoParcial, setDescricaoParcial] = useState<any>();
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const [aprovadas, setAprovadas] = React.useState<number>();
  const [rejeitadas, setRejeitadas] = React.useState<number>();
  const [idProposicao, setIdproposicao] = React.useState<string>();

  const handleOnChangeDescricaoParcial = (data: any) => {
    if (data !== descricaoParcial) setDescricaoParcial(data)

  };

  useEffect(() => {
    const texto = listaDescricao.filter((x) => x.label === descricaoParcial);
    if (texto.length > 0) {

      loadProposicaoList();
    } else if (descricaoParcial && descricaoParcial.trim().length > 2) {

      ProposicaoService.GetPartialDescriptionAndHouse(
        descricaoParcial,
        currentFilter,
        true
      ).then(async (res) => {
        if (res)
          setListaDescricao(
            res.map((item) => {
              if (item.id_casa) {
                switch (item.id_casa) {
                  case 1:
                    return { value: item.id, label: item.descricao + " - " + "Senado Federal" };
                  case 2:
                    return { value: item.id, label: item.descricao + " - " + "Câmara dos Deputados" };
                  case 3:
                    return { value: item.id, label: item.descricao + " - " + "Congresso Nacional" };
                  default:
                    return { value: item.id, label: item.descricao };
                }
              }
            })
          );
      });
    } else if (descricaoParcial === "") {
      loadProposicaoList();
    }
  }, [descricaoParcial]);



  const loadProposicaoList = () => {
    ProposicaoService.GetList() // force break
      .then(async (res) => {
        if (res && res.success) {
        }
      });
  };

  function descricaoCasa(desc: string) {

    if (desc?.search("-") !== -1 &&
      desc?.search("-") !== null &&
      desc?.search("-") !== undefined)

      return desc?.slice(
        desc?.search("-") + 2,
        desc?.length
      )
    else

      return "Não definida"
  }

  const esferaConv = (value: any) => {
    switch (value) {
      case 'F':
        return 1;
      case 'E':
        return 2;
      case 'M':
        return 3;

    }
  }
  const handleOnSelect = (data: any) => {
    if (data) {
      setIdproposicao(String(data.value));
    }
  };

  React.useEffect(() => {
    if (idProposicao) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey10(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(idProposicao)
        ).then((res) => {
          if (res?.data) {
            setAprovadas(0)
            setRejeitadas(0)
            setAprovadas(res?.data?.aprovadas[0]?.total_aprovadas)
            setRejeitadas(res?.data?.rejeitadas[0]?.total_rejeitadas)
          }

        });
      }
    }
  }, [props.dtIni, props.dtFin, idProposicao]);


  return (
    <Grid>
      <Grid>
        <Grid
          style={{
            color: `${pallet.textColorQuaternary}`,
            fontFamily: `${pallet.general.fontFamily}`,
            fontSize: `${pallet.general.size14}`,
            fontWeight: pallet.general.weight_600,
            marginTop: "30px",
            marginBottom: "10px",
            marginLeft: "24px",
            marginRight: "24px",
          }}
        >
          PROPOSIÇÃO
        </Grid>
        <Grid
          style={{
            marginBottom: "30px",
            marginLeft: "24px",
            marginRight: "24px",
          }}
          item
          md={6}
          xs={12}
          lg={6}
        >
          <ComboBox
            name="filtroDescricao"
            itens={listaDescricao}
            clear={false}
            onChange={handleOnChangeDescricaoParcial}
            onSelect={handleOnSelect}
            placeholder="Buscar proposição"
          />
          <ErrorMessage name="pauta" errors={error} />
        </Grid>
        <Grid>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid
          style={{ marginTop: "20px", marginLeft: "24px", marginRight: "24px" }}
        >
          <Grid
            style={{
              color: `${pallet.textColorQuaternary}`,
              fontFamily: `${pallet.general.fontFamily}`,
              fontSize: `${pallet.general.size14}`,
              fontWeight: pallet.general.weight_600,
              marginBottom: "20px",
            }}
          >
            APROVAÇÃO
          </Grid>
          <Grid>
            <Grid
              container
              style={{
                color: `${pallet.textColorQuaternary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size12}`,
                fontWeight: pallet.general.weight_600,
                // marginBottom: "20px",
                height: "79px",
                backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                border: "1px solid #EAEAEA",
                borderRadius: "6px 6px 0px 0px",
                paddingLeft: "24px",
              }}
            >
              <Grid lg={6} xs={5} alignSelf={"center"} item>
                CASA LEGISLATIVA
              </Grid>
              {isMobile ? (
                <Grid>
                  <Divider
                    orientation={"vertical"}
                    variant="fullWidth"
                    style={{
                      marginRight: `${isMobile ? "18px" : "0px"}`,
                      paddingLeft: `${isMobile ? "6px" : "0px"}`,
                    }}
                  />
                </Grid>
              ) : null}

              <Grid alignSelf={"center"} xs={5} lg={6} item>
                TEMPO MÉDIO
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                color: `${pallet.textColorQuaternary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size12}`,
                fontWeight: pallet.general.weight_500,
                marginBottom: "20px",
                height: "63px",
                border: "1px solid #EAEAEA",
                paddingLeft: "24px",
              }}
            >
              <Grid lg={6} xs={5} alignSelf={"center"} item>
                {descricaoCasa(descricaoParcial)}
              </Grid>
              {isMobile ? (
                <Grid>
                  <Divider
                    orientation={"vertical"}
                    variant="fullWidth"
                    style={{
                      marginRight: `${isMobile ? "18px" : "0px"}`,
                      paddingLeft: `${isMobile ? "6px" : "0px"}`,
                    }}
                  />
                </Grid>
              ) : null}
              <Grid alignSelf={"center"} lg={6} xs={5} item>
                {aprovadas ? aprovadas : 0}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "20px", marginLeft: "24px", marginRight: "24px" }}
        >
          <Grid
            style={{
              color: `${pallet.textColorQuaternary}`,
              fontFamily: `${pallet.general.fontFamily}`,
              fontSize: `${pallet.general.size14}`,
              fontWeight: pallet.general.weight_600,
              marginBottom: "20px",
            }}
          >
            REJEIÇÃO
          </Grid>
          <Grid>
            <Grid
              container
              style={{
                color: `${pallet.textColorQuaternary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size12}`,
                fontWeight: pallet.general.weight_600,
                // marginBottom: "20px",
                height: "79px",
                backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                border: "1px solid #EAEAEA",
                borderRadius: "6px 6px 0px 0px",
                paddingLeft: "24px",
              }}
            >
              <Grid lg={6} xs={5} alignSelf={"center"} item>
                CASA LEGISLATIVA
              </Grid>
              {isMobile ? (
                <Grid>
                  <Divider
                    orientation={"vertical"}
                    variant="fullWidth"
                    style={{
                      marginRight: `${isMobile ? "18px" : "0px"}`,
                      paddingLeft: `${isMobile ? "6px" : "0px"}`,
                    }}
                  />
                </Grid>
              ) : null}
              <Grid alignSelf={"center"} lg={6} xs={5} item>
                TEMPO MÉDIO
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                color: `${pallet.textColorQuaternary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size12}`,
                fontWeight: pallet.general.weight_500,
                marginBottom: "40px",
                height: "63px",
                border: "1px solid #EAEAEA",
                paddingLeft: "24px",
              }}
            >
              <Grid lg={6} xs={5} alignSelf={"center"} item>
                {descricaoCasa(descricaoParcial)}
              </Grid>
              {isMobile ? (
                <Grid>
                  <Divider
                    orientation={"vertical"}
                    variant="fullWidth"
                    style={{
                      marginRight: `${isMobile ? "18px" : "0px"}`,
                      paddingLeft: `${isMobile ? "6px" : "0px"}`,
                    }}
                  />
                </Grid>
              ) : null}
              <Grid alignSelf={"center"} lg={6} xs={5} item>
                {rejeitadas ? rejeitadas : 0}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AccordionContentKey10;
