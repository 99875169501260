
import * as React from 'react';
import { InputProps as StandardInputProps } from '@mui/material/Input';
import { InputLabelProps } from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { usePallet } from '../contexts/PalletContext';
import useStyles from './layout/Styles';


interface IProps {
    itens?: Array<{
        value: string,
        label: string,
        key?: string,
        bg?: string,
        posicionamente?: string,
        impacto?: string
    }>,
    name?: string,
    placeholder?: string,
    options?: Array<any>,
    label?: React.ReactNode,
    helperText?: React.ReactNode,
    defaultValue?: any,
    value?: string,
    focused?: boolean,
    className?: string,
    style?: React.CSSProperties,
    menuStyle?: React.CSSProperties,
    onChange?: (value: string) => void,
    //InputProps?: Partial<StandardInputProps>,
    InputLabelProps?: Partial<InputLabelProps>,

}


export default function SelectTextFields(props: IProps) {
    const { pallet } = usePallet();
    const classes = useStyles();
    const [currentValue, setCurrentValue] = React.useState(props.value || '');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
        props.onChange && props.onChange(event.target.value)
    };

    return (
        <Box
            component="form"
            // sx={{
            //     '& .MuiTextField-root': { m: 1, width: '25ch' },
            // }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id={`outlined-select-${props?.name}`}
                    select
                    fullWidth
                    variant="outlined"
                    defaultValue={'0'}
                    label={props?.label}
                    placeholder={props.placeholder ? "Selecione" : ""}
                    value={props?.value ? props?.value : currentValue}
                    focused={props?.focused}
                    onChange={handleChange}
                    className={props?.className}
                    style={props?.style}
                    helperText={props?.helperText}
                    InputProps={{
                        style: { fontFamily: `${pallet.general.fontFamily}` },
                        classes: { notchedOutline: classes.notchedOutline }
                    }}
                    InputLabelProps={props?.InputLabelProps}
                >
                    {props?.itens?.map((item) => (
                        <MenuItem key={item?.value} value={item?.value} style={{
                            fontFamily: `${pallet.fontFamily.general}`,
                            ...props?.menuStyle
                        }}>
                            {item?.label}
                        </MenuItem>
                    ))}
                    {props.options?.map((option) => (
                        <MenuItem placeholder={props.placeholder ? props.placeholder : ""}
                            style={{ fontFamily: `${pallet.general.fontFamily}`, maxWidth: '600px', whiteSpace: 'pre-line' }}
                            key={option.value ? option.value : option.label}
                            value={option.value}>
                            {option.label} {option.name}

                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </Box>
    );
}