import React from 'react';
import { Grid } from '@mui/material';
import ImpactoFinanceiroData from './ImpactoFinandeiroData';


const IndicadoresImpactoFinanceiro = (props: { valor_positivo: number, valor_neutro: number, valor_negativo: number }) => {
    return (
        <div>
            <Grid container justifyContent="space-between">
                <Grid item key="Positivo">
                    <ImpactoFinanceiroData key="Positivo" title={"Positivo"} value={props.valor_positivo} />
                </Grid>
                <Grid item key="Neutro">
                    <ImpactoFinanceiroData key="Neutro" title={"Neutro"} value={props.valor_neutro} />
                </Grid>
                <Grid item key="Negativo">
                    <ImpactoFinanceiroData key="Negativo" title={"Negativo"} value={props.valor_negativo} />
                </Grid>
            </Grid>
        </div>
    )
}

export default IndicadoresImpactoFinanceiro;