import * as http from '../helpers/http';
import { writeStore } from '../helpers/persistence'
import { ILoggedUser } from '../models/User.Model';

class AuthorizationService {
    GetPassEmail = async (email: String): Promise<any> => {
        const result = await http.get(`/login/${email}`);
        if (result && result.data.length > 0) {
            return result.data[0].user_email;
        } else {
            return undefined;
        }
    };

    me = async (): Promise<ILoggedUser | undefined> => {
        const res: any = await http.get('/me');
        if (!res) {
            this.logout();
        }
        return res && res.success ? res.data : undefined;
    };

    config = async (): Promise<any> => {
        const res: any = await http.get('/config');
        return res;
    };

    login = (email: String, password: String): Promise<ILoggedUser | undefined> => {
        return new Promise(async (resolve, reject) => {
            const res: any = await http.post('/login', { email, password });
            if (res && res.success) {
                if (res.data.auth.token) await writeStore('token', res.data.auth.token);
                if (res.data.auth.refreshToken) await writeStore('refresh-token', res.data.auth.refreshToken);
                return resolve(res.data.user as ILoggedUser);
            }
            return reject(undefined);
        });
    }

    logout = async (): Promise<boolean> => {
        return true;
    };
}

export default new AuthorizationService();
