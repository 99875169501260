import React, { useCallback, useEffect, useState } from "react";
import { Divider, Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import AccordionTextDataDiscursoParlamentar from "./AccordionTextDataDiscursoParlamentar";
import PaginationSaveState from "../../../components/PaginationSaveState";
import { IListInfo } from "../../../models/IListInfo";
import ParlamentarMenuFiltroTipo from "../../../components/ParlamentarMenuFiltroTipo";
import { Visibility } from "@material-ui/icons";
import ParlamentarDiscursoService from "../../../services/ParlamentarDiscursoService";
import { IFilterData } from "../../../components/filter/ListFilter";
import FilterQuantity from '../../../components/filter/FilterQuantity';
import AccordionDiscursoNovo from "./AccordionDiscursoNovo";
import { IParlamentar } from "../../../models/Parlamentar.Model";
import CircularLoading from "../../../components/CircularLoading";

export default function ParlamentarDiscursosDiscurso(props: { id_parlamentar: number, idPermissao?: number, parlamentar?: IParlamentar }) {
    const { pallet } = usePallet();
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1)
    };
    const [filtro, setFiltro] = useState<IFilterData[]>([{ key: 'qualificacao', label: 'Qualificação', value: 'T' }]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [currentPage, currentPageSize, filtro])

    const getList = useCallback(() => {
        const filter = filtro.map(item => { return { key: item.key, value: item.value } });

        if (currentPage && currentPage > 0) {
            ParlamentarDiscursoService.GetList(props.id_parlamentar, filter, currentPage, currentPageSize).then(async res => {
                if (res && res.success) {
                    setLista(res.lista);
                    setInfoList(res.listInfo);
                    setTimeout(() => {
                        setLoading(false);
                        //setLoadingAfterSalvar(false)
                    }, 1000);
                }
            })
        }
    }, [props.id_parlamentar, currentPage, currentPageSize, filtro, loading])

    const handleChangeNovoDiscurso = () => {
        setLoading(true);
        getList();
    };


    const setPage = (p: number) => setCurrentPage(p);
    const setFilter = (f: any) => setFiltro([{ key: f.target.name, label: f.target.name, value: f.target.value }]);

    return (

        <div>
            {loading === true ?

                <CircularLoading
                    size={80}
                    top={"50%"}
                    left={"50%"}
                    zIndex={2}
                />
                :

                <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                    <Grid item xl={12} lg={12} xs={12}>
                        <Grid container direction="row" alignItems="baseline" style={{
                            paddingLeft: "20px",
                            paddingTop: `${isMobile ? "10px" : "0px"}`,
                            marginBottom: `${isMobile ? "-10px" : "0px"}`
                        }} >
                            <Grid direction="row" alignItems="baseline" style={{
                                // paddingLeft: `${isMobile ? "30px" : "45px"}`,
                                // paddingRight: `${isMobile ? "30px" : "50px"}`,
                                // paddingTop: `${isMobile ? "10px" : "10px"}`,
                                // marginBottom: `${isMobile ? "0px" : "0px"}`,
                                width: "100%"
                            }} >
                                {/* //Novo Discurso */}
                                {props.parlamentar.casa > 3 ?
                                    <AccordionDiscursoNovo
                                        onClick={getList}
                                        id_parlamentar={props?.id_parlamentar}
                                        onChange={handleChangeNovoDiscurso}
                                        lista={infoList}
                                        idPermissao={props.idPermissao}
                                    />

                                    :

                                    null
                                }

                            </Grid>
                            <Grid>

                            </Grid>
                            {((infoList?.totalItens > 0) || ((props.parlamentar.casa < 3)&&(infoList?.totalItens == 0))) &&
                                <Grid container item alignItems="baseline" xs={6} >
                                    <Grid style={{ marginRight: "8px" }}>
                                        <Grid style={{
                                            fontSize: "18px",
                                            color: `${pallet.textColorTertiary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>

                                            {infoList?.totalItens}
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <Grid item style={{
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontSize: "14px",
                                            fontWeight: pallet.general.weight_600,
                                            paddingTop: "5px"
                                        }}>
                                            {infoList?.totalItens > 1 ? "DISCURSOS" : "DISCURSO"}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {((infoList?.totalItens > 0) || ((props.parlamentar.casa < 3)&&(infoList?.totalItens == 0))) &&
                                <Grid item xs={6}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Grid style={{ paddingRight: `${isMobile ? "21px" : "21px"}` }}>
                                                <ParlamentarMenuFiltroTipo title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            }
                            {infoList?.totalItens > 4 ?
                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "20px" }}>
                                    <FilterQuantity
                                        list={infoList}
                                        currentPageSize={currentPageSize}
                                        onChange={handleOnChangeItensPerPageSize}
                                    />
                                </Grid>
                                : null}

                        </Grid>
                        <Grid style={{ paddingLeft: `${isMobile ? "20px" : "20px"}`, paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: "20px", paddingBottom: "20px" }}>
                            {loading ?
                                <CircularLoading
                                    size={80}
                                    top={"50%"}
                                    left={"50%"}
                                    zIndex={2}
                                />

                                :
                                <AccordionTextDataDiscursoParlamentar lista={lista} idPermissao={props.idPermissao} reloadList={getList} parlamentarCasa={props.parlamentar.casa} />
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                        <PaginationSaveState
                            name={'ParlamentarDiscursoList'}
                            pages={infoList?.totalPages}
                            page={currentPage}
                            onChange={setPage}
                        />
                    </Grid>
                </Grid>
            }
        </div>

    )
}