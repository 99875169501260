import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import GerUsuariosTabsDepartamentos from "./GerUsuariosTabsDepartamentos";
import GerUsuariosTabsHistAtividades from "./GerUsuariosTabsHistAtividades";
import GerUsuariosTabsCentralAjuda from "./GerUsuariosTabsCentralAjuda";


const GerUsuariosCarouselTabsCentralAjuda = (props: { id_user: number, activeTab: any, index: number, onChange?: () => void }) => {

    return (

        <Paper style={{ borderRadius: "6px", marginTop: `${isMobile ? "0px" : "-30px"}` }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <GerUsuariosTabsCentralAjuda id_user={props.id_user} index={props.index} activeTab={props.activeTab} onChange={props.onChange} />
            </TabPanel>
        </Paper>

    )
}

export default GerUsuariosCarouselTabsCentralAjuda;