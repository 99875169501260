import React from "react";
import { usePallet } from "../contexts/PalletContext";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { Grid, Paper, Typography } from "@mui/material";
import { ViewArraySharp } from "@material-ui/icons";



const dataMap = [
  {
    id: 0,
    name: "Jan",
    pv: 0
  },
  {
    id: 1,
    name: "Fev",
    pv: 0

  },
  {
    id: 2,
    name: "Mar",
    pv: 0

  },
  {
    id: 3,
    name: "Abr",
    pv: 0

  },
  {
    id: 4,
    name: "Mai",
    pv: 0

  },
  {
    id: 5,
    name: "Jun",
    pv: 0

  },
  {
    id: 6,
    name: "Jul",
    pv: 0

  },
  {
    id: 7,
    name: "Ago",
    pv: 0

  },
  {
    id: 8,
    name: "Set",
    pv: 0

  },
  {
    id: 9,
    name: "Out",
    pv: 0

  },
  {
    id: 10,
    name: "Nov",
    pv: 0

  },
  {
    id: 11,
    name: "Dez",
    pv: 0

  }
];


export default function App(props: {
  dataresult: Array<{ ano: string, mes: string, qtd: string }>
}) {

  const { pallet } = usePallet();

  var tooltip;

  dataMap.map((item) => {
    item.pv = 0;
  });


  props.dataresult.map(item => {
      var ind = Number(item.mes);
      ind = (ind===0?1:ind);
      //console.log(" conversaooo ",ind);
      dataMap[ind - 1].pv = Number(item.qtd);
    });

  const CustomTooltip = ({ active, payload, label }: { active: any, payload: any, label: any }) => {

    if (active) {

      return (

        <Paper
          style={{
            fontSize: `${pallet.general.size14}`, padding: "10px", background: `${pallet.backgroundColorTertiary}`
          }}

        >

          {`${payload[0].value}`}

        </Paper>


      );

    }

    return null;

  };

  return (



    <ResponsiveContainer width={'100%'} height={300}>
      <LineChart
        data={dataMap}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid horizontal={true} vertical={false} />
        <XAxis dataKey="name" tick={{ fontFamily: `${pallet.fontFamily}`, fontSize: `${pallet.general.size12}` }} />
        <YAxis tick={{ fontFamily: `${pallet.fontFamily}`, fontSize: `${pallet.general.size12}` }} allowDecimals={false} />
        <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />
        <Line
          type="monotone"
          dataKey="pv"
          stroke={`${pallet.general.color1}`}
          //dot={{stroke: `${pallet.general.color1}`, strokeWidth: 10, r: 0.5 }}
          dot={{ fill: `${pallet.general.color1}` }}
          activeDot={{ r: 0 }} />
      </LineChart>
    </ResponsiveContainer>

  );
}
