import React, { useCallback, useEffect, useState } from "react";
import { Grid, Pagination, Stack, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import useStyles from '../../../components/layout/Styles';
import { formatDate } from "../../../utils/dateUtils";
import ParlamentarOutrosService from "../../../services/ParlamentarOutrosService";
import { IListInfo } from "../../../models/IListInfo";
import PaginationSaveState from "../../../components/PaginationSaveState";

export default function ParlamentarOutrosMandatos(props: { id_parlamentar: number }) {
    const { pallet } = usePallet();
    const classes = useStyles();
    
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [currentPage])

    const getList = useCallback(() => {
        if (currentPage && currentPage > 0)
            
            ParlamentarOutrosService.GetMandatos(props.id_parlamentar, currentPage, 8)
            .then(async res => {
                setLista(res.data)
            })
    }, [props.id_parlamentar, currentPage])

    const setPage = (p: number) => setCurrentPage(p);

    return (
        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Grid style={{ padding: `${isMobile ? "5px 5px 0px 15px" : "0px 20px 20px 40px"}` }}>
                        <Box sx={{ maxWidth: "400px" }}>
                            <Stepper orientation="vertical">
                                {lista?.map((item, index) => {
                                    return (
                                        <Step key={index} expanded active={false}>
                                            <StepLabel icon={
                                                <Grid style={{
                                                    background:  `${pallet.backgroundColorQuaternary}`,
                                                    width: "35px",
                                                    height: "35px",
                                                    borderRadius: "999px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginLeft: "-5px", 
                                                    color: index===0?"#4B1196":"#1E0736"
                                                }}>
                                                    <HowToRegOutlinedIcon />
                                                </Grid>
                                            } >
                                                <Typography className={classes.heading} style={{
                                                    // color: `${pallet.textColorTertiary}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size18}`,
                                                    fontWeight: pallet.general.weight_600,
                                                    marginTop: "4px",
                                                    color: index===0?"#4B1196":"#1E0736"
                                                }}>
                                                    {formatDate(new Date(item.inicio), 'yyyy')} - {item.final ? formatDate(new Date(item.final), 'yyyy') : ' Presente'}
                                                </Typography>
                                            </StepLabel>
                                            <StepContent>
                                                <Typography style={{
                                                    padding: "10px",
                                                    boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: "16px",
                                                    background: index===0?`${pallet.backgroundColorQuaternary}`:"#F9F9F9"
                                                }}>
                                                    <Grid>
                                                        <div style={{ fontWeight: pallet.general.weight_600, color: "#1E0736" }}>
                                                            {item?.cargo}
                                                        </div>

                                                    </Grid>
                                                    <Grid>
                                                        <div style={{ fontWeight: pallet.fontWeight.lighter, color: `${pallet.general.color2}` }}>
                                                            {item.partido ? item.partido + '/' + item?.uf : <></>}
                                                        </div>
                                                    </Grid>
                                                </Typography>
                                            </StepContent>
                                        </Step>

                                    )
                                })}
                            </Stepper>
                        </Box>
                        <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                            <PaginationSaveState
                                name={'ParlamentarOutrosList'}
                                pages={infoList?.totalPages}
                                page={currentPage}
                                onChange={setPage}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}