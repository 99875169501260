import { Grid, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
// import ReactToPrint from 'react-to-print';
import PieChartRelatorio from '../PieChartRelatorio';
import { usePallet } from "../../../../contexts/PalletContext";
import styled from 'styled-components';
import legis_agenda from "../../../../img/logo_legis_alta.png";
import PrintIcon from '@mui/icons-material/Print';
import { isMobile, isWindows } from 'react-device-detect';
import legis_agenda2 from "../../../../img/legis_agenda2.png";
import { fontSize } from '@mui/system';
import RelatorioService from "../../../../services/RelatoriosService";
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/utils';
import CircularLoading from '../../../../components/CircularLoading';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../utils/dateUtils';



export default function RelatorioAcoesRealizadasPorPeriodo() {
    const { pallet } = usePallet();
    const { dtIni } = useParams() as any;
    const { dtFin } = useParams() as any;
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();


    let filter = useQuery().get('filter');
    const [filtro, setFiltro] = useState(filter)
    const [countTotAi, setCountTotAi] = useState<number>()
    const [countTotAp, setCountTotAp] = useState<number>()

    const [dataAI, setDataAI] = useState([])
    const [dataAP, setDataAP] = useState([])


    //let esferaTipo = filter.substring(27, 29)
    let filtrotitle = []

    try { filtrotitle = JSON.parse(filter) } catch { filtrotitle = [] };

    let esferaTipo = (filtrotitle.length > 0 ? filtrotitle[0].value : "Geral")

    const Imprimir = () => {
        window.print()
    }

    React.useEffect(() => {
        setLoading(true)

        if (
            String(format(new Date(dtIni), "yyyy")).slice(0, 1) !== "0" &&
            String(format(new Date(dtFin), "yyyy")).slice(0, 1) !== "0"
        ) {

            RelatorioService.GetProposicoesAcaoPeriodo(
                format(new Date(dtIni), "yyyy-MM-dd"),
                format(new Date(dtFin), "yyyy-MM-dd 23:59:00.000"),
                String(filtro)
            ).then(async res => {
                await setCountTotAi(Number(res?.data?.totais?.acao_influencia))
                await setCountTotAp(Number(res?.data?.totais?.acao_preparatoria))

                await setDataAI(res?.data?.listas?.acao_influencia)
                await setDataAP(res?.data?.listas?.acao_preparatoria)

                await setLoading(false)
            })
        }
    }, []);

    const CheckEsfera = (select) => {
        // console.log(select)
        switch (select) {
            case 'F':
                return 'Federal'
            case 'E':
                return 'Estadual'
            case 'M':
                return 'Municipal'
            default:
                return 'Federal'
        }
    }

    // console.log(filter.substring(27, 29))    
    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <img style={{ width: '100px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                Relatório / {esferaTipo}
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Ações (Preparatórias e de Influencias) Realizadas por Período
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            <tr >
                                <td>
                                    {/* if countCD countSF countCN nao forem undefined */}
                                    {loading ?
                                        <CircularLoading
                                            size={100}
                                            top={isMobile ? "37%" : "40%"}
                                            left={isMobile ? "6%" : "16%"}
                                            zIndex={2}
                                        />
                                        :
                                        <Grid style={{
                                            width: '990px',
                                            paddingLeft: '5px',
                                            marginTop: '30px'
                                        }}>
                                            {/* {console.log("dtIni", dtIni, "dtFin", dtFin, "countCD", countCD, "countSF", countSF, "countCN", countCN)}
                                            {console.log(isNaN(countCD))} */}
                                            <PieChartRelatorio
                                                title="PERÍODO DE APRESENTAÇÃO"
                                                dtIni={dtIni}
                                                dtFin={dtFin}
                                                labels={[
                                                    {
                                                        key: "Ação Preparatória",
                                                        data: Number(countTotAp),
                                                        color: `${pallet.color.charts.green}`,
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"acaoPreparatoria","label":"Ação preparatória","value":"15"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        
                                                        key: "Ação de Influência",
                                                        data: Number(countTotAi),
                                                        color: `${pallet.color.charts.red}`,
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key": "acaoInfluencia", "label":"AÇÕES DE INFLUÊNCIA", "value": 15},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    }

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{ marginTop: '70px', }}>
                                    TOTAL: {countTotAp}
                                </Grid>
                                <Grid style={{

                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600,
                                    color: `${pallet.color.secundary.font}`
                                }}>
                                    | AÇÃO PREPARATÓRIA
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_bold
                                        }}>
                                            TIPO DE PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_bold
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataAP.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '50px',
                                                    borderTop: `1px solid #e0e0e0`,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    const dtIniFormatted = formatDate(new Date(dtIni), "MM/dd/yyyy");
                                                    const dtFinFormatted = formatDate(new Date(dtFin), "MM/dd/yyyy");
                                                    const dateString = `${dtIniFormatted} até ${dtFinFormatted}`;
                                                    const filtro = encodeURIComponent(JSON.stringify([
                                                        { "key": "esfera", "label": `ESFERA: ${esferaTipo}`, "value": `${esferaTipo}` },
                                                        { "key": "tipo", "label": `TIPO: ${x.tipo_proposicao}`, "value": x.tipo_proposicao },
                                                        { "key": "isAcaoPreparatoria", "label": `AÇÕES PREPARATÓRIAS: ${dateString}`, "value": {"inicio": `${dtIniFormatted}`, "fim": `${dtFinFormatted}`}},
                                                    ]));

                                                    window.open(`/proposicao?filtro=${filtro}&relatorioNome=Ações (Preparatórias e de Influencias) Realizadas por Período`, '_blank');
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.tipo_proposicao}
                                                </Grid>
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.count}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{ marginTop: '70px', }}>
                                    TOTAL: {countTotAi}
                                </Grid>
                                <Grid style={{
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600,
                                    color: `${pallet.color.secundary.font}`
                                }}>
                                    | AÇÃO DE INFLUÊNCIA
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_bold
                                        }}>
                                            TIPO DE PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_bold
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataAI.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '50px',
                                                    borderTop: `1px solid #e0e0e0`,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    const dtIniFormatted = formatDate(new Date(dtIni), "MM/dd/yyyy");
                                                    const dtFinFormatted = formatDate(new Date(dtFin), "MM/dd/yyyy");
                                                    const dateString = `${dtIniFormatted} até ${dtFinFormatted}`;
                                                    const filtro = encodeURIComponent(JSON.stringify([
                                                        { "key": "esfera", "label": "ESFERA: Federal", "value": "Federal" },
                                                        { "key": "tipo", "label": `TIPO: ${x.tipo_proposicao}`, "value": x.tipo_proposicao },
                                                        { "key": "isRelAcaoInfluencia", "label": `AÇÕES DE INFLUÊNCIA: ${dateString}`, "value": {"inicio": `${dtIniFormatted}`, "fim": `${dtFinFormatted}`}},
                                                    ]));

                                                    window.open(`/proposicao?filtro=${filtro}&relatorioNome=Ações (Preparatórias e de Influencias) Realizadas por Período`, '_blank');
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.tipo_proposicao}
                                                </Grid>
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.count}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}


                    </Grid>
                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
