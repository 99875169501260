import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, TextField, Typography } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { CalendarToday } from '@material-ui/icons';
import { formatDate } from '../../../utils/dateUtils';
import ParlamentarDiscursosModal from './ParlamentarDiscursosModal';
import styled from "styled-components";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAlert } from "../../../contexts/AlertContext";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useState, useEffect } from "react";
import { parlamentarQualificacoes } from '../../../models/ParlamentarQualificacoes';
import ParlamentarDiscursoService from '../../../services/ParlamentarDiscursoService';
import SelectTextFieldA from '../../../components/SelectTextFieldA';
import ButtonDialogConfirmation from '../../../components/ButtonDialogConfirmation';
import DeleteIcon from "@mui/icons-material/Delete";
import AnexosComponent, { EAnexoModulos } from '../../../components/Anexos';

function AccordionTextDataDiscursoParlamentar(props: {
    lista?: Array<any>
    idPermissao?: number
    reloadList: () => void,
    parlamentarCasa?: number
}) {

    const { NewAlert } = useAlert();
    const { pallet } = usePallet();
    const classes = useStyles();
    const [lista, setLista] = useState<Array<any>>(props.lista);

    useEffect(() => {
        setLista(props.lista)
    },
        [props.lista]
    )


    const setDiscursoLista = (id: number, qualificacao: string) => {
        const objIndex = lista.findIndex((object => object.id === id))
        if (objIndex >= 0) {
            lista[objIndex].qualificacao = qualificacao
            setLista(lista)
        }
    }

    const tagDiscursoCor = (value: string) => {
        if (value) {
            value = value.toLowerCase();
        }
        switch (value) {
            case "divergente":
                return `${pallet.charts.red}`;
            case "convergente":
                return `${pallet.charts.green}`;
            case "neutro":
                return `${pallet.charts.lightGrey}`;
            default:
                return `${pallet.charts.blue}`;
        }
    }
    const CustomLabelQualificacao: React.FunctionComponent = () => {

        return (
            <div style={{
                color: `${pallet.color.colorBase.labelTextFild}`,
                fontFamily: `${pallet.fontFamily.general}`,
                marginRight: "8px"
            }}>
                QUALIFICAÇÃO
            </div>
        )
    }

    const handleDiscursoQualificacaoChange = (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        ParlamentarDiscursoService.SetQualificacao(id, event.target.value).then(x => {
            setDiscursoLista(id, event.target.value);
            NewAlert('success', 'Registro Salvo com sucesso');

        });

        return event.target.value
    }

    const deletarDiscurso = async (id_discurso: number) => {
    await ParlamentarDiscursoService.Delete(id_discurso).then((res) => {
		if (res) {
		NewAlert("success", "O discurso foi descartado com sucesso.");

		props.reloadList();



		} else {
		NewAlert(
			"error",
			"Erro ao deletar, tente novamente."
		);
		}
	});
	
}

    const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 6px 4px 6px;
    /* margin-bottom: 7px; */
    line-height: 15px;
    letter-spacing: 1px;
`;

    const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 5px;
    margin-top: 3px;
`;
    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: ${isMobile ? "100px" : "70px"};
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
`;

const permissaoErro = () => {
    NewAlert("error","Não é possível realizar está ação com este perfil.")

}
    return (
        <>

            {lista?.map(item => {
                return (
                    <Grid key={item.id} style={{ marginBottom: "20px" }}>
                        <Accordion elevation={0} style={{ border: "1px solid #EAEAEA", background: `${pallet.backGroundPageColorPrimary}` }} >
                            <AccordionSum style={{
                                paddingLeft: `${isMobile ? "5px" : "20px"}`
                            }}
                                expandIcon={<ArrowDropDownIcon
                                    style={{
                                        color: `${pallet.general.color2}`,
                                        fontSize: `${pallet.general.size16}`,
                                    }}
                                />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container direction="row" alignItems="center">

                                    {/* <------------DATA E HORA-------> */}
                                    <Grid container xl={3} lg={3} item style={{
                                        maxWidth: `${isMobile ? "210px" : "250px"}`
                                    }}
                                    >
                                        <Grid>
                                            <CalendarToday style={{
                                                color: `${pallet.backgroundColorPrimary}`,
                                                height: "22px",
                                                width: "20px",
                                                marginRight: "10px",
                                                marginTop: "3px",
                                                marginLeft: `${isMobile ? "0px" : "5px"}`
                                            }} />
                                        </Grid>
                                        <Grid >
                                            <Typography className={classes.heading} style={{
                                                color: `${pallet.textColorQuaternary}`,
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size14}`,
                                                fontWeight: pallet.general.weight_600,
                                            }}>
                                                <Grid container
                                                    style=
                                                    {{
                                                        marginTop: "5px",
                                                    }}>
                                                    {formatDate(new Date(item.data), 'dd/MM/yyyy')}
                                                    <Grid>
                                                        <AccessAlarmsIcon
                                                            style={{
                                                                color: `${pallet.backgroundColorPrimary}`,
                                                                height: "20px",
                                                                width: "20px",
                                                                marginLeft: "20px",
                                                                marginRight: "10px"
                                                            }}
                                                        />
                                                    </Grid>
                                                    {formatDate(new Date(item.data), 'HH:MM')}
                                                </Grid>
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {/* ----DESCRICAO E LABEL---- */}

                                    <Grid container xl={9} lg={9} xs={9} item style={{
                                        marginLeft: `${isMobile ? "0px" : "-40px"}`,
                                        maxWidth: `${isMobile ? "210px" : "750px"}`

                                    }}
                                    >
                                        <Grid style={{
                                            marginRight: "10px",
                                        }}>
                                            <Typography className={classes.heading} style={{
                                                color: `${pallet.textColorTertiary}`,
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size12}`,
                                                fontWeight: pallet.general.weight_600,
                                                marginTop: "4px",
                                                // marginRight: "10px"
                                            }}>
                                                {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "10px"}`, textOverflow:"elipsis", whiteSpace: "nowrap" }}> | </span>}
                                                {item?.descricao?.substring(0,70)}...
                                            </Typography>
                                        </Grid>
                                        {props.idPermissao !== 4 ? 
                                        <Grid  item>
                                            <ChipStyle pallet={pallet}>
                                                <Grid container >
                                                    <DotStyle bg={tagDiscursoCor(item.qualificacao)}>
                                                    </DotStyle>
                                                    <Grid>
                                                        {item.qualificacao ? item.qualificacao : "Não Classificado"}
                                                    </Grid>
                                                </Grid>
                                            </ChipStyle>
                                        </Grid>
                                        :
                                        null
                                        }

                                    </Grid>
                                </Grid>

                                {/* <Grid item justifyContent="end" style={{ paddingTop: "4px" }}>
                                    <Grid style={{ marginTop: "10px", marginBottom: "15px" }}>
                                        <ButtonIconLeftTransp
                                            icon={<CloseIcon fontSize="small" />}
                                            title={"Descartar"}

                                        />
                                    </Grid>
                                </Grid> */}

                            </AccordionSum>

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                background: `${pallet.backGroundPageColorSecondary}`

                            }}>
                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "20px 20px 10px 20px"}`, width: "100%" }}>
                                    <Grid xl={12} container justifyContent="space-between">
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <Grid style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.general.size12}`,
                                                color: `${pallet.color.tertiary.font}`
                                            }}>
                                                SUMÁRIO
                                            </Grid>

                                            <Grid style={{
                                                marginTop: "10px",
                                                fontWeight: pallet.general.weight_300,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`
                                            }}
                                            >
                                                {item.sumario}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: "30px", paddingBottom: "20px" }}>
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <Grid container alignItems="center" style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.general.size12}`,
                                                color: `${pallet.color.secundary.font}`
                                            }}>
                                                <ArrowRightAltIcon style={{ marginRight: "5px" }}></ArrowRightAltIcon>                                                
                                                {item.transcricao && <ParlamentarDiscursosModal id={item.id}
                                                    qualificacao={item.qualificacao}
                                                    onChange={(id, e) => props.idPermissao !== 3 ? handleDiscursoQualificacaoChange(id, e) : NewAlert("error","Não é possível realizar está ação com este perfil.")} />}
                                            </Grid>
                                            {/* <Grid style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.fontSize.text_10}`,
                                                color: `${pallet.color.secundary.font}`
                                            }}>
                                                {item.url && <Link href={item.url.replace('http://', 'https://')} >Pronunciamento</Link>}
                                            </Grid> */}
                                        </Grid>
                                    </Grid>

                                        {props.idPermissao !== 4 ?
                                        <>
                                    <Divider />
                                    <Grid style={{ padding: `${isMobile ? "20px 5px 10px 0px" : "20px 20px 10px 0px"}`, width: "100%" }}>
                                        <Grid style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.general.size12}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            paddingBottom: "20px"
                                        }}>
                                            REGISTRO
                                        </Grid>
                                       
                                       <Grid >
                                       <Box component="form">
                                           <SelectTextFieldA
                                               value={item.qualificacao ? item.qualificacao.toLowerCase() : ""}
                                               onChange={(e) => props.idPermissao !== 3 ? handleDiscursoQualificacaoChange(item.id, e) : permissaoErro}
                                               options={parlamentarQualificacoes}
                                               id={"qualificacao" + item.id}
                                               label={"QUALIFICAÇÃO"}

                                           />
                                       </Box>
                                       <Box>
                                       </Box>
                                   </Grid>
                                    </Grid>
                                   </>
                                        :
                                        null
                                        } 
                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                        <Grid className="container">
                            <AnexosComponent
                                label={''}
                                id_referencia={item.id}
                                modulo={EAnexoModulos.Discurso}
                                canEdit={false}
                                autosave={false}
                                isAnexosTitle={"ANEXOS"}

                            />
                        </Grid>
                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {props.parlamentarCasa > 3 ?
                                        <Grid style={{position:'relative',
                                        left: isMobile ? "250px" : "1000px",
                                        top: isMobile ? "-65px" : "-55px",
                                        zIndex:5
                                        }}>
                                            <Grid style={{position: 'relative', height: 'auto'}}>
                                        <Grid style={{    position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '128px',
                        bottom: '0',
                        height: '100%'}}>
                                        <ButtonDialogConfirmation
                                            styles={{
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.button,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`,
                                                borderRadius: "6px",
                                                textTransform: "capitalize",
                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            // lastPath={"/parlamentar"}
                                            confirmationEvent={() =>props.idPermissao !== 3 ? deletarDiscurso(item.id) : NewAlert(
                                                "error",
                                                "Não é possível realizar está ação com este perfil.")}
                                            title_button={""}
                                            confirmation_button={"Apagar"}
                                            content={"DESCARTAR DISCURSO?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        >
                                            <DeleteIcon
                                                style={{
                                                    color: `${pallet.general.color4}`,
                                                    marginRight: "16px",
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                }}

                                            />
                                         </ButtonDialogConfirmation>
                                         </Grid>
                                        </Grid>
                                        </Grid>
                                        :
                                        null
                                        }
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataDiscursoParlamentar;

function NewAlert(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
