import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { isValid } from "date-fns";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { usePallet } from '../contexts/PalletContext';
import ptBRLocale from "date-fns/locale/pt-BR";
import useStyles from '../../src/components/layout/Styles';

const DateRangeSelector = (props: { startDate: Date, endDate?: Date, color?: string, onChange?: (dtIni: Date, dtFin: Date) => void }) => {
    const { pallet } = usePallet();
    const [value, setValue] = React.useState<DateRange<Date>>([props.startDate, props.endDate]);
    const [tempValue, setTempValue] = React.useState<DateRange<Date>>([props.startDate, props.endDate]);
    const classes = useStyles();

    const LabelOff: React.FunctionComponent = () => {
        return <div style={{ marginBottom: '-5px' }}></div>;
    };

    useEffect(() => {
        if (
            value[0] !== null &&
            value[1] !== null &&
            isValid(value[0]) &&
            isValid(value[1]) &&
            value[1] >= value[0]
        ) {
            props.onChange && props.onChange(value[0], value[1]);
        }
    }, [value]);

    const handleBlur = () => {
        setValue(tempValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setValue(tempValue); // Atualiza o valor definitivo ao pressionar Enter
        }
    };

    return (
        <Grid container>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                <DateRangePicker
                    inputFormat="dd/MM/yyyy"
                    startText=""
                    endText=""
                    value={tempValue} // Usamos tempValue para capturar a digitação
                    onChange={(newValue) => {
                        setTempValue(newValue); // Armazena o valor temporário ao digitar ou selecionar no painel
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextField
                                size="small"
                                {...startProps}
                                variant="outlined"
                                label={<LabelOff />}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown} // Escuta o evento de tecla
                                InputProps={{
                                    style: {
                                        maxWidth: '125px',
                                        color: `${props.color ? props.color : pallet.color.primary.font}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size12}`,
                                        fontWeight: pallet.general.weight_600,
                                        letterSpacing: '2px',
                                    },
                                    classes: {
                                        notchedOutline: classes.textFieldOutlineOff
                                    }
                                }}
                                InputLabelProps={{
                                    style: { color: props.color ? props.color : "#4B1196" }
                                }}
                            />
                            <Box
                                sx={{
                                    color: `${props.color ? props.color : pallet.color.primary.font}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontSize: `${pallet.general.size12}`,
                                    fontWeight: pallet.general.weight_600,
                                    marginLeft: '-15px',
                                    marginRight: '5px',
                                }}
                            >
                                à
                            </Box>
                            <TextField
                                size="small"
                                {...endProps}
                                variant="outlined"
                                label={<LabelOff />}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown} // Escuta o evento de tecla também no campo de fim
                                InputProps={{
                                    style: {
                                        maxWidth: '125px',
                                        color: `${props.color ? props.color : pallet.color.primary.font}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size12}`,
                                        fontWeight: pallet.general.weight_600,
                                        letterSpacing: "2px",
                                        marginRight: '-35px',
                                    },
                                    classes: {
                                        notchedOutline: classes.textFieldOutlineOff
                                    }
                                }}
                                InputLabelProps={{
                                    style: { color: props.color ? props.color : "#4B1196" }
                                }}
                            />
                        </React.Fragment>
                    )}
                />
            </LocalizationProvider>
        </Grid>
    );
};

export default DateRangeSelector