import { Box, Button, ButtonGroup, Grid, Paper } from "@mui/material";
import { useQuery } from '../../utils/utils';
import React from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { isMobile } from "react-device-detect";
import ButtonCadastrarEvento from "../../components/ButtonCadastrarEvento";
import useStyles from "../../components/layout/Styles";
import { usePallet } from "../../contexts/PalletContext";
import Main from "../main/Main";
import AgendaCarouselDias from "./_components/AgendaCarouselDias";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Visibility } from "@material-ui/icons";
import { IFilterData } from "../../components/filter/ListFilter";
import AgendaMenuFiltroTipo from "../../components/AgendaMenuFiltroTipo";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GerarPDFMenu from "../../components/GerarPDFMenu";
import AgendaService from "../../services/AgendaService";
import { addDays, format, subDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import ParlamentarService from "../../services/ParlamentarService";
import CircularLoading from '../../components/CircularLoading';
import { useAuthorization } from "../../contexts/AuthorizationContext";
import { useAlert } from "../../contexts/AlertContext";
import moment from 'moment-timezone';


export default function Agenda() {
    let dataInicial = useQuery().get('dataInicial');
    dataInicial = dataInicial ?
        dataInicial.split("/")[2] + "-" + dataInicial.split("/")[1] + "-" + dataInicial.split("/")[0]
        :
        null;

    const { pallet } = usePallet();
    const classes = useStyles();
    const [filtro, setFiltro] = React.useState<any>('T');
    const [dtIni, setDtIni] = React.useState<Date>(dataInicial ? new Date(moment(dataInicial).format("yyyy/MM/DD")) : new Date());
    const [dtFin, setDtFin] = React.useState<Date>(addDays(dataInicial ? new Date(moment(dataInicial).format("yyyy/MM/DD")) : new Date(), 6));
    const [monthDateFin, setMonthDateFin] = React.useState("");
    const [yearDateFin, setYearDateFin] = React.useState("");
    const [listaEventos, setListaEventos] = React.useState();
    const [totalCount, setTotalCount] = React.useState<number>();
    const [nomeFiltro, setNomeFiltro] = React.useState("Todos Eventos");
    const [relatorio, setRelatorio] = React.useState();
    const [influencia, setInfluencia] = React.useState([]);
    const [loading, setloading] = React.useState(false)


    // Define range de data padrao, baseado na data de hoje + 7 dias (Formando uma semana)
    React.useEffect(() => {

        let monthFin = format(dtFin, "MMMM", { locale: ptBR });
        let yearFin = format(dtFin, "yyyy", { locale: ptBR });

        setDtIni(dtIni);
        setDtFin(dtFin);
        setMonthDateFin(monthFin);
        setYearDateFin(yearFin);
        loadEventos(dtIni, dtFin, filtro);
        loadJornal(dtIni, dtFin, filtro);
    }, [dtIni, dtFin, filtro]);

    const ChangeDateRange = (arrowDirection) => {
        if (arrowDirection === "next") {
            let iniAdd = addDays(dtIni, 7);
            let finAdd = addDays(dtFin, 7);
            let monthFinAdd = format(finAdd, "MMMM");
            setDtIni(iniAdd);
            setDtFin(finAdd);
            setMonthDateFin(monthFinAdd);
        } else {
            let iniAdd = subDays(dtIni, 7);
            let finAdd = subDays(dtFin, 7);
            let monthFinAdd = format(finAdd, "MMMM");
            setDtIni(iniAdd);
            setDtFin(finAdd);
            setMonthDateFin(monthFinAdd);
        }
    };

    const setFilter = (f: any) => {
        setFiltro(f.target.value);
        if (f.target.value === "T") {
            setNomeFiltro("Todos Eventos");
        }
        if (f.target.value === "SF") {
            setNomeFiltro("Senado");
        }
        if (f.target.value === "CD") {
            setNomeFiltro("Câmara");
        }
        if (f.target.value === "CN") {
            setNomeFiltro("Congresso");
        }
        if (f.target.value === "O") {
            setNomeFiltro("Outros");
        }
        if (f.target.value === "G") {
            setNomeFiltro("Geral");
        }
    };

    async function loadEventos(dtIni: any, dtFin: any, tipo: any) {
        await AgendaService.GetList(format(dtIni, "yyyy-MM-dd"), format(dtFin, "yyyy-MM-dd 23:59:00.000"), tipo).then((eventos) => {
            setListaEventos(eventos?.lista?.data?.itens);
            setTotalCount(eventos?.lista?.data?.total);
        });
    }

    async function loadJornal(dtIni: any, dtFin: any, tipo: any) {
        await AgendaService.GetListRelatorio(format(dtIni, "yyyy-MM-dd"), format(dtFin, "yyyy-MM-dd 23:59:00.000"), tipo).then((jornal) => {
            //   setRelatorio()

            let blank =
                jornal?.informacoes?.data[2]?.eventos[1]?.pautas[0]?.proposicoes[0]
                    .acoes_influencia[0]?.acao_influencia_influenciaSobre;



        });
    }

    const reloadEventos = () => {
        loadEventos(dtIni, dtFin, filtro);
    };

    const { user } = useAuthorization();

    const { NewAlert } = useAlert();

    return (
        <div className={classes.root}>
            <Main>
                <>
                    <Grid
                        container
                        justifyContent="space-between"
                        style={{ marginBottom: "40px" }}
                    >
                        <Grid
                            item
                            style={{
                                paddingTop: "10px",
                                fontFamily: `${pallet.general.fontFamily}`,
                                fontWeight: pallet.general.weight_600,
                                fontSize: "24px",
                            }}
                        >
                            Agenda
                        </Grid>
                        <Grid item onClick={() => user.id_permissao === 3 ?  NewAlert("error",
                                    "Não é possível realizar está ação com este perfil.") : null}>
                            <ButtonCadastrarEvento path={user.id_permissao !== 3 ? "/agenda/cadastro" : ""} />
                        </Grid>
                    </Grid>
                    <Paper>
                        <Grid container style={{ padding: "20px" }}>
                            <Grid item>
                                <ButtonGroup disableElevation variant="contained">
                                    <Button
                                        onClick={() => ChangeDateRange("after")}
                                        style={{
                                            height: "50px",
                                            backgroundColor: "#F2F2F2",
                                            borderColor: "none",
                                        }}
                                    >
                                        <ArrowLeftIcon style={{ color: "black" }} />
                                    </Button>
                                    <Button
                                        onClick={() => ChangeDateRange("next")}
                                        style={{
                                            height: "50px",
                                            backgroundColor: "#F2F2F2",
                                            borderColor: "none",
                                        }}
                                    >
                                        <ArrowRightIcon style={{ color: "black" }} />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    marginLeft: `${isMobile ? "10px" : "20px"}`,
                                    marginTop: `${isMobile ? "8px" : "0px"}`,
                                }}
                            >
                                <Grid
                                    style={{
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontWeight: pallet.general.weight_bold,
                                        fontSize: `${isMobile ? "12px" : "14px"}`,
                                    }}
                                >
                                    SEMANA DE
                                </Grid>
                                <Grid
                                    style={{
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontWeight: pallet.general.weight_bold,
                                        fontSize: `${isMobile ? "13px" : "20px"}`,
                                    }}
                                >
                                    {`${format(dtIni, "dd")} a ${format(
                                        dtFin,
                                        "dd"
                                    )} de ${monthDateFin} ${yearDateFin}`}
                                </Grid>
                            </Grid>
                        </Grid>
                        <hr style={{ border: "1px solid #efefef" }} />
                        <Grid
                            container
                            justifyContent="space-between"
                            direction="row"
                            alignItems={"center"}
                            style={{ padding: "15px" }}
                        >
                            <Grid
                                item
                                style={{
                                    marginLeft: "10px",
                                    marginBottom: `${isMobile ? "10px" : "0px"}`,
                                }}
                            >
                                <Grid container>
                                    <Grid
                                        style={{
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontWeight: pallet.general.weight_bold,
                                            fontSize: "12px",
                                            color: `${pallet.general.color2}`,
                                        }}
                                    >
                                        TOTAL DE EVENTOS NA SEMANA:
                                    </Grid>
                                    <Grid
                                        style={{
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontWeight: pallet.general.weight_bold,
                                            fontSize: "12px",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        {/* {pallet.general.fontFamily} */}
                                        {totalCount > 1
                                            ? totalCount + " EVENTOS"
                                            : totalCount + " EVENTO"}{" "}
                                        [{nomeFiltro}]
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid style={{ marginRight: "15px",marginBottom: isMobile ? "10px" : "0px" }}>
                                        <AgendaMenuFiltroTipo
                                            title={"Visualizar"}
                                            onChange={setFilter}
                                            icon={<Visibility />}
                                        />
                                    </Grid>
                                    <Grid>
                                        {/* <GerarPDFMenu dataInicio={dtIni} dataFim={dtFin} filtro={filtro}  icon={<PictureAsPdfIcon />} /> */}
                                        {dtIni && dtFin && filtro ?
                                            <GerarPDFMenu
                                                dtIni={dtIni}
                                                dtFin={dtFin}
                                                tipo={filtro}
                                                icon={<PictureAsPdfIcon />}
                                                idPermissao={user.id_permissao}
                                            />
                                            // <CircularLoading
                                            //     size={100}
                                            //     top={isMobile ? "37%" : "40%"}
                                            //     left={isMobile ? "6%" : "16%"}
                                            //     zIndex={2}
                                            // />
                                            :
                                            <CircularLoading
                                                size={100}
                                                top={isMobile ? "37%" : "40%"}
                                                left={isMobile ? "6%" : "16%"}
                                                zIndex={2}
                                            />
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper style={{ marginTop: "40px" }}>
                        <Grid container style={{ padding: "15px" }}>
                            <AgendaCarouselDias
                                listaDeEventos={listaEventos}
                                dateStartReference={dtIni}
                                loadEventos={reloadEventos}
                            />
                        </Grid>
                    </Paper>
                </>
            </Main>
        </div>
    );
}