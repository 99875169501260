import * as React from 'react';
import TextField from '@mui/material/TextField';
import useStyles from './layout/Styles';
import ptBRLocale from "date-fns/locale/pt-BR";
import { usePallet } from '../contexts/PalletContext';
import ProposicaoService from '../services/ProposicaoService';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';



export default function DeadLineDate(
    props: {
        value: string,
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
        id: string,
        label: string,
        inputFormat: string,
        defaultValue?: any,
        focused?: boolean,
        minDate?: any
    }) {

    const classes = useStyles();
    const { pallet } = usePallet();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale} >
            <MobileDatePicker
                inputFormat={props.inputFormat}
                minDate={props?.minDate}
                // disableOpenPicker
                label={props.label}
                value={props?.value ? props?.value : props.defaultValue}
                onChange={props.onChange}
                renderInput={(params) => <TextField {...params}
                    focused={props.focused ? props.focused : false}
                    style={{
                        width: "100%",
                        alignContent: "flex-start",
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size16}`,
                        fontWeight: pallet.general.weight_500,
                    }}
                    id={props.id}
                    variant="outlined"
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: <CalendarTodayIcon style={{ marginRight: "10px", }} />,
                        style: {
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_500,
                        }

                    }}
                    InputLabelProps={{
                        style: { color: "grey" }
                    }}
                />}
            />
        </LocalizationProvider>
    );
}