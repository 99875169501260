import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import DoughnutParlamentarDemandas from "./DoughnutParlamentarDemandas";
import ParlamentarAmbitoService from "../../../services/ParlamentarAmbitoService";
import SmallIconButton from "../../../components/SmallIconButton";
import { subYears } from "date-fns";
import ParlamentarDemandaService from "../../../services/ParlamentarDemandaService";

export default function ParlamentarDemandasDados(props: { id_parlamentar: number }) {
    const { pallet } = usePallet();
    const [sumary, setSumary] = useState({ titular: 0, suplente: 0 });
    const [filtrores, setFiltrores] = useState<Array<Date>>([subYears(new Date(), 1), new Date()]);

    const [loading, setLoading] = useState(true);

    const [countExito, setCountExito] = useState<any>();
    const [countExitoParcial, setCountExitoParcial] = useState<any>();
    const [countSemExito, setCountSemExito] = useState<any>();
    const [countPendente, setCountPendente] = useState<any>();


    useEffect(() => {
        getDemandasSummary();
        // eslint-disable-next-line 
    }, [props.id_parlamentar])

    const getDemandasSummary = useCallback(() => {
        ParlamentarDemandaService.GetSummary(props.id_parlamentar).then(async res => {
            if (res) {
                let pendente = res.filter(item => item.resultado === 0 || item.resultado === null ).length === 0 ? 0 : parseInt(res.filter(item => item.resultado === 0 || item.resultado === null)[0]?.count)
                let exito = res.filter(item => item.resultado === 1 ).length === 0 ? 0 : parseInt(res.filter(item => item.resultado === 1)[0]?.count)
                let semExito = res.filter(item => item.resultado === 2).length === 0 ? 0 : parseInt(res.filter(item => item.resultado === 2)[0]?.count)
                let exitoParcial = res.filter(item => item.resultado === 3).length === 0 || null || undefined || NaN ? 0 : parseInt(res.filter(item => item.resultado === 3)[0]?.count)
                
                setCountExito(exito?exito:0)
                setCountExitoParcial(exitoParcial?exitoParcial:0)
                setCountSemExito(semExito?semExito:0)
                setCountPendente(pendente?pendente:0)

                //             setLoading(false)
            }
        })
    }, [props.id_parlamentar])

    return (

        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                    <DoughnutParlamentarDemandas
                        masterTitle={"ÍNDICE DE RESULTADO DE DEMANDAS"}
                        // loading={loading}
                        //titleChart={`Total: ${sumary?.titular + sumary?.suplente}`}
                        titleChart=""
                        labels={[
                            { key: "Êxito", data: countExito, color: `${pallet.charts.green}` },
                            { key: "Êxito Parcial", data: countExitoParcial, color: `${pallet.charts.purple}` },
                            { key: "Sem Êxito", data: countSemExito, color: `${pallet.charts.red}` },
                            { key: "Pendente", data: countPendente, color: `${pallet.charts.yellow}` },
                        ]}
                    // dtIni={ subYears(new Date(), 1)}
                    // dtFin={ new Date()}
                    // onFilterChange={setFilterDateRange}
                    />
                </Grid>
            </Grid>

        </div>
    )
}