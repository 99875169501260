import { Accordion, AccordionDetails, Button, Checkbox, Grid, Pagination, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import useStyles from "../../components/layout/Styles";
import { usePallet } from "../../contexts/PalletContext";
import Main from "../main/Main";
import TwitterIcon from '../../img/twitter_button.jpg';
// import filtersBackgroundImage from '../../../../../src/img/OrangeBackgroundHeader.png';
import { IListInfo } from "../../models/IListInfo";
import ButtonIconSalvar from "../../components/ButtonIconSalvar";
import { useNavigate } from "react-router-dom";
import TCService from "../../services/TCService";
import { IFilterData } from "../../components/filter/ListFilter";

import FilterQuantity from "../../components/filter/FilterQuantity";
import EmptyError from "../../components/EmptyError";
import EmptyErrorDocCarousel from "../../components/EmptyErrorDocCarousel";
import EmptyErrorDesk from "../../components/EmptyErrorDesk";
import ComboBox from "../../components/ComboBox";
import EmptyFavorite from "../../components/EmptyErrorFavorite";
import filtersBackgroundImage from "../../../src/img/background_filters_dashboard.png";
import TiposList, { IFiltroTipoProps } from "../insights/_components/TiposLista";
import FavoritosService from "../../services/FavoritosService";
import { formatDate } from "../../utils/dateUtils";
import { useAlert } from "../../contexts/AlertContext";
import ParlamentarDiscursosFavoritosModal from "./_components/ParlamentarDiscursosFavoritosModal";
import ComboBoxBusca from "../../components/ComboBoxBusca";
import { useAuthorization } from "../../contexts/AuthorizationContext";
import NewsService from "../../services/NewsService";
import CircularLoading from "../../components/CircularLoading";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import moment from "moment-timezone";
import TwitterService from "../../services/TwitterService";
import ParlamentarService from "../../services/ParlamentarService";
import ParlamentarCard from "../parlamentar/_components/ParlamentarCard";
import DownloadIcon from '@mui/icons-material/Download';
import UploadFilesService from "../../services/UploadFiles.service";


moment.tz.setDefault(process.env.TIMEZONE || 'America/Sao_Paulo');

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


//## StyledCompenents
const FilterArea = styled(Paper)`
	width: 100%;
	/* height: 55px; */
	margin-top: 16px;
	margin-bottom: 10px;
	padding: 16px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background-color: #ffff;
`;

const FilterButton = styled(Button)`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	text-transform: capitalize;
	color: #160342;
	border: 0;
	font-size: 14px;
	line-height: 17px;
	background: rgba(75, 17, 150, 0.1);
	border-radius: 6px;
	padding: 10px;
	width: 100%;
	height: 100%;

	:first-child {
		margin-right: 10px;
	}
`;

const ContainerHead = styled(Grid)`
	margin-bottom: 10px;
	margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
	margin-bottom: 30px;
`;

const PageTitle = styled(Grid)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-weight: ${(props) => props.pallet.general.weight_bold};
	font-size: 24px;
	font-family: ${(props) => props.pallet.general.fontFamily};
`;

const ContainerFilter = styled(Grid)`
	/* width: 10%; */
	width: 100%;
	margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
	justify-content: ${isMobile ? "center" : "space-between"};
    flex-direction: column;
`;

const ContainerCard = styled(Grid)`
	margin-top: 20px;
	margin-left: 8px;
`;

const PaperContent = styled(Paper)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
	margin-top: 40px;
	margin-bottom: 40px;
	justify-content: center;
`;

interface ICitacaoResult {
	descricao: string;
	id: any;
}

export default function Favoritos() {
	const { pallet } = usePallet();
	const classes = useStyles();
	const navigate = useNavigate();
	const { NewAlert } = useAlert();
	const { user, getConfig } = useAuthorization();


	const [currentPageSize, setCurrentPageSize] = useState<number>(50);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [filter, setFilter] = useState({ key: "visualizacao", label: "proposicao", value: "P" });
	const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);
	const [lista, setLista] = React.useState<any>([])
	const [listaTwitter, setListaTwitter] = React.useState<any>([])
	const [listaNews, setListaNews] = React.useState<any>([])
	const [listaDescricao, setListaDescricao] = useState<string[]>([]);
	const [busca, setBusca] = useState<IFilterData[]>();
	const [descricaoParcial, setDescricaoParcial] = useState<string>("");
	const [open, setOpen] = React.useState();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [starLoading, setStarLoading] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState(null);

	const setPage = (event: object, page: number) => setCurrentPage(page);

	//## Paginação
	const [infoList, setInfoList] = useState<IListInfo>({
		pageIndex: 1,
		pageSize: 8,
		pageItens: 0,
		totalItens: 0,
		totalPages: 0,
	});

	const handleOnChangeItensPerPageSize = (data: any) => {
		setCurrentPageSize(Number(data.target.value));
		setCurrentPage(1);
	};

	const [tipoAtual, SetTipoAtual] = React.useState("PROPOSIÇÕES")

	useEffect(() => {
		setLoading(true)
		setLista([])
		loadFavoritos()
	}, [currentPage, currentPageSize, tipoAtual, descricaoParcial]);

	const [tipo, setTipo] = React.useState<Array<IFiltroTipoProps>>([
		// { key: "todos", label: "TODOS", active: true },
		{ key: "proposicao", label: "PROPOSIÇÕES", active: true },
		{ key: "interlocutor", label: "INTERLOCUTORES", active: false },
		{ key: "discurso", label: "DISCURSOS", active: false, hide: getConfig()?.semElastic },
		{ key: "news", label: "NOTÍCIAS", active: false },
		{ key: "twitter", label: "TWITTER", active: false, hide: getConfig()?.semElastic },
		{ key: "diario", label: "DOU", active: false, hide: getConfig()?.semElastic || !getConfig()?.habDou },
	]);

	const onChangeTipo = async (event: any) => {

			let temp = event.target.title !== "DIÁRIO" ? event.target.title : "DOU";

			setTipo(
				tipo.map((x) => {
					if (x.label.toLowerCase() === temp.toLowerCase()) {
						x.active = true;
						SetTipoAtual(event.target.title);
					}
					else if (x.active) x.active = false;
					return x;
				})
			);
			setCurrentPage(1)


		

	};

	async function loadFavoritos() {
		// setLoading(true)
		if (tipoAtual && tipoAtual === 'NOTÍCIAS') {
			const data = {
				filter: [],
				pageSize: 100,
				pageIndex: 1
			}
			await NewsService.favoriteList(data).then((res) => {
				if (res) {
					setListaNews(res.data)
					setLoading(false)
				}
			})
		} else if (tipoAtual && tipoAtual === 'INTERLOCUTORES') {
			const dataInfoList = {
				pageIndex: currentPage - 1,
				pageSize: currentPageSize,
			};
			await FavoritosService.getFavoritadosInterlocutor(descricaoParcial ? descricaoParcial : undefined, dataInfoList)
				.then(res => {
					if (res) {
						setLista(res.data.map(item => {
							return {
								id: item.id,
								nome: item.nome,
								mandatoTipo: item?.tratamento,
								partido: item?.sigla_partido,
								estadoFederacao: item?.uf_exercicio,
								seguindo: item.cliente ? item.cliente?.seguindo : false,
								qualificacao: item.cliente ? item.cliente?.qualificacao.toLowerCase() : "não classificado",
								statusMandato: item?.tratamento === "Senador " || item?.tratamento === undefined ? item?.situacaoReal : item?.situacao,
								urlImg: item.url_foto ? item.url_foto.replace('http://', 'https://') : undefined,
								observacao: item.cliente ? item.cliente.observacao : '',
								id_favoritado: item.id_favoritado,
								status_favorito: item.status_favorito
							}
						}));
						setInfoList({
							pageIndex: res.pageIndex,
							pageItens: res.pageItens,
							pageSize: res.pageSize,
							totalItens: res.totalItens,
							totalPages: res.totalPages
						});
						setLoading(false)
					};
				});
		} else if (tipoAtual && tipoAtual === 'TWITTER') {

			const data = {
				pageIndex: currentPage - 1,
				pageSize: currentPageSize,
			}
			await TwitterService.GetTwitterFavoritados(descricaoParcial ? descricaoParcial : undefined, data).then((res) => {
				if (res) {
					setInfoList(res.data.listInfo)
					setListaTwitter(res.data.data)
					setLoading(false)
				}
			});
			setLoading(false)
		} else {
			await FavoritosService.getFavoritos(tipoAtual, currentPage, currentPageSize, JSON.stringify([{ key: 'descricaoP', label: "Descrição", value: descricaoParcial }]))
				.then(async (res) => {
					console.log("OLHA O AUSTE",res)
					if (res) {
						setLista(res.data);
						setInfoList(res.listInfo);
						setLoading(false)
					}
				});
		}
	};

	const qualificacaoAjuste = (value: string) => {
		switch (value) {
			case "Convergente":
				return "convergente";
			case "Divergente":
				return "divergente";
			case "Neutro":
				return "neutro";
			case "Não Classificado":
				return "não classificado";
			default:
				return "Não definido";
		}
	};

	const removerFavorito = async (id_modulo: number, id: number) => {
		await FavoritosService.Delete(id_modulo, id, false, tipoAtual).then((res) => {
			if (res) {
				NewAlert("success", "O item foi removido dos favoritos com sucesso.");
				loadFavoritos();
			} else {
				NewAlert(
					"error",
					"Erro ao favoritar, verifique os campos e salve novamente."
				);
			}
		});

	}

	const handleBuscaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTimeout(() => {

			if (event.target.value.length > 2) {
				setDescricaoParcial(event.target.value)
			} else {
				setDescricaoParcial("")
			}
		}, 1000);
	}

	const favoriteNews = async (url) => {
		await NewsService.desfavorite(url).then((res) => {
			if (res) {
				NewAlert("success", "O item foi desfavoritado com sucesso.");
				loadFavoritos()
			} else {
				NewAlert("error", "Erro ao desfavoritar, tente novamente.");
			}
		})
	}

	const openTweetLink = (username, tweetId) => {
		window.open(`https://twitter.com/${username}/status/${tweetId}`, '_blank').focus();
	};

	const openInterlocutor = (id_parlamentar) => {
		window.open(`/interlocutor/${id_parlamentar}`, '_blank').focus();
	};

	const favoritandoTwitter = async (modulo, id_modulo, status) => {
		const data = {
			modulo: modulo,
			id_modulo: id_modulo
		};
		setStarLoading(true);
		await FavoritosService.Delete(data, 0, true).then((res) => {
			if (res) {
				NewAlert("success", "O item foi removido dos favoritos com sucesso.");
				loadFavoritos();
				setStarLoading(false);
			} else {
				NewAlert(
					"error",
					"Erro ao Deletar, tente novamente."
				);
				setStarLoading(false);
			}
		});

	}

	const desfavoritandoInterlocutores = async (modulo, id_modulo) => {
		const data = {
			modulo: modulo,
			id_modulo: id_modulo
		};
		setStarLoading(true);
		await FavoritosService.Delete(data, 0, true).then((res) => {
			if (res) {
				NewAlert("success", "O item foi removido dos favoritos com sucesso.");
				loadFavoritos();
				setStarLoading(false);
			} else {
				NewAlert(
					"error",
					"Erro ao Deletar, tente novamente."
				);
				setStarLoading(false);
			}
		});

	}


	const downloadRoot = async (nome:string, setLoading = (e) => e) => {

        // let nome_reconstruido
        // let nome_reconstruido_extra =  nome.match(/\/([^/]+\.pdf)\?/);

        // nome_reconstruido = ((nome_reconstruido_extra && nome_reconstruido_extra[1]) || 'dou');
               NewAlert('info', `Download do arquivo ${nome} em andamento...`);
        setLoading(true);
        UploadFilesService.download(nome, true)
            .then((resp) => {

                console.log("O RESP!!!!!!!!!!!!!!!",resp)
                if (resp.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link?.setAttribute("download", nome);
                    document.body.appendChild(link);
                    link?.click();
                    link?.parentNode?.removeChild(link);
                    NewAlert('success', `Download do arquivo ${nome} finalizado com sucesso!`);
                    setLoading(false);
                } else {
                    NewAlert('error', `Erro ao realizar o download do arquivo ${nome}.`);
                    setLoading(false);
                }
            }).catch((err) => {
                NewAlert('error', `Erro ao realizar o download do arquivo ${nome}.`);
                console.error(err);
                setLoading(false);
            });
    }
	return (
		<div className={classes.root}>
			<Main>
				<>
					<ContainerHead container>
						<ContainerSubHead container alignItems="center" justifyContent="space-between">

							<PageTitle pallet={pallet} >Favoritos</PageTitle>

						</ContainerSubHead>
						<ContainerBody container>
							<Grid style={{ width: "100%" }}>
								<ComboBoxBusca
									name="filtroFavoritos"
									onChange={handleBuscaChange}
									placeholder="Buscar"
								/>

							</Grid>
							{tipoAtual && tipoAtual === "NOTÍCIAS"
								?
								null
								:
								<Grid item style={{ marginTop: '30px', marginLeft: '40px' }}>
									<FilterQuantity
										list={infoList}
										currentPageSize={currentPageSize}
										onChange={handleOnChangeItensPerPageSize}
									/>
								</Grid>
							}

							<Grid item style={{ width: "100%", marginTop: "30px" }}>

								<Paper style={{
									borderRadius: "7px",
									paddingBottom: '20px',
								}}>


									{
										!isMobile ?

											<Grid
												style={{

													backgroundSize: "cover",
													borderRadius: "7px 7px 0px 0px",
													padding: "25px 25px 25px 50px",
													width: "100%",
													backgroundImage: `url(${filtersBackgroundImage})`,
												}}
											>
												<TiposList filtros={tipo} onChange={onChangeTipo} />
											</Grid>
											:

											<Grid container flex-direction={'column'} textAlign={'center'} display={'inline-grid'}
												style={{
													backgroundSize: "cover",
													borderRadius: "7px 7px 0px 0px",
													padding: "25px",
													width: "100%",
													backgroundImage: `url(${filtersBackgroundImage})`,
												}}
											>
												<TiposList filtros={tipo} onChange={onChangeTipo} />
											</Grid>
									}


									{
										loading
											?
											<CircularLoading
												size={80}
												top={"90%"}
												left={"50%"}
												zIndex={2}

											/>
											:
											infoList.totalPages <= 0
												?
												<EmptyFavorite title="Ops! Nenhum item favoritado." description="Clique na" subdescription="para favoritar" />
												:
												tipoAtual && tipoAtual === 'NOTÍCIAS'
													?
													listaNews ? listaNews.map((xc, index) => {
														return (
															<div id="content">
																<Grid style={{
																	paddingLeft: "40px", paddingRight: "40px", paddingTop: "30px",
																	fontFamily: `${pallet.general.fontFamily}`
																}}>
																	<Grid container justifyContent={'space-between'} style={{ padding: "10px", backgroundColor: `${pallet.backGroundPageColorPrimary}`, borderRadius: '7px' }}>
																		<Grid>
																			<div key={index} style={{ cursor: "pointer", marginTop: "0px", marginLeft: "0px", marginRight: '-30px' }}>
																				<Checkbox
																					onChange={() => favoriteNews(xc.urlnews)}
																					{...label}
																					icon={xc && xc.favoritado_em ? xc.favoritado_em ? <StarIcon /> : <StarBorderIcon />
																						:
																						<CircularLoading
																							positionCustom={true}
																							size={20}
																							top={isMobile ? "37%" : "50%"}
																							left={isMobile ? "6%" : "45%"}
																							zIndex={2}
																						/>
																					}
																					checkedIcon={<StarIcon />}
																					style={{ color: `${pallet.general.color2}` }}
																				/>
																			</div>
																		</Grid>
																		<Grid item style={{ maxWidth: '450px' }}>
																			<Grid container>
																				{xc.autor
																					?
																					<Grid>
																						{xc.autor}
																					</Grid>
																					:
																					<Grid style={{ fontStyle: 'italic', color: "lightgray" }}>
																						Desconhecido
																					</Grid>
																				}

																				<Grid style={{ paddingLeft: "5px", paddingRight: "5px" }}>
																					|
																				</Grid>
																				<Grid>
																					{moment(xc.datapublicado).format('DD/MM/YYYY [às] HH:mm')}
																				</Grid>
																			</Grid>
																			<Grid style={{ fontWeight: 900, width: "450px" }}>
																				{xc.titulo}
																			</Grid>
																			<Grid style={{ marginTop: "10px", fontWeight: 100, width: "450px" }}>
																				{xc.descricao}
																			</Grid>
																			<Grid style={{ marginTop: "10px", fontWeight: 100, width: "450px" }}>
																				<a href={xc.urlnews} target="_blank">Continue lendo!</a>
																			</Grid>
																		</Grid>
																		<Grid item>
																			<div style={{ width: "500px", height: "250px", backgroundImage: `url(${xc.urlnewsimg})`, backgroundSize: "cover" }} />
																			{/* : "https://originalnutts.com.br/wp-content/uploads/2017/06/wood-blog-placeholder.jpg" */}
																		</Grid>
																	</Grid>
																</Grid>
															</div>
														)
													})
														:
														null
													:

													loading
														?
														<CircularLoading
															size={80}
															top={"90%"}
															left={"50%"}
															zIndex={2}

														/>
														:
														infoList.totalPages <= 0
															?
															<EmptyFavorite title="Ops! Nenhum item favoritado." description="Clique na" subdescription="para favoritar" />
															:
															tipoAtual === 'PROPOSIÇÕES' || tipoAtual === 'DISCURSOS'
																?
																lista && lista.map((i) => {
																	return (
																		<Grid style={{ padding: "30px" }}>
																			{i.modulo && i.data_proposicao ?
																				<Grid style={{
																					fontFamily: `${pallet.general.fontFamily}`,
																					fontSize: `${pallet.fontSize.titleWidgets}`,
																					fontWeight: pallet.general.weight_bold,
																					color: "#9B9B9B",
																					marginLeft: "20px"
																				}}>
																					CATEGORIA - {i.modulo === "proposicao" ? formatDate(new Date(i.data_proposicao), "dd/MM/yyyy") :
																						formatDate(new Date(i.data_discurso), "dd/MM/yyyy")}
																				</Grid>
																				:
																				null}

																			<Grid style={{
																				fontFamily: `${pallet.general.fontFamily}`,
																				fontSize: `${pallet.fontSize.titleWidgets}`,
																				fontWeight: pallet.general.weight_bold,
																				color: `${pallet.general.color2}`,
																				marginLeft: "20px",
																				marginTop: "10px"
																			}}>
																				<Grid style={{ cursor: "pointer", display: "inline-block" }}
																					onClick={() => user.id_permissao !== 3 ? removerFavorito(i.id_modulo, i.id) : NewAlert(
																						"error",
																						"Não é possível realizar está ação com este perfil."
																					)}><StarIcon fontSize="small" /></Grid>
																				<span>&nbsp;&nbsp;</span>
																				<Grid onClick={i.modulo === "proposicao" ?
																					() => window.open('/proposicao/' + i.id_modulo, "_blank") :
																					() => window.open('/interlocutor/' + i.parlamentar_id, "_blank")}
																					style={{ cursor: "pointer", display: "inline-block" }}>
																					<span style={{ textDecoration: "underline" }}>{
																						i.modulo === "proposicao" ? i.prop_descricao :
																							i.parlamentar_nome}</span> {i.modulo === "interlocutor-discurso" && i.siglaPartido ? " (" + i.siglaPartido + ")" : ""}</Grid>
																			</Grid>
																			<Grid style={{
																				fontFamily: `${pallet.general.fontFamily}`,
																				fontSize: `${pallet.fontSize.titleWidgets}`,
																				marginLeft: "20px",
																				marginTop: "10px"
																			}}>
																				{i.modulo === "proposicao" ? i.ementa :
																					i.modulo === "interlocutor-discurso" && i.discurso_transcricao ?
																						<>
																							{/* <Grid>i.discurso_sumario </Grid> */}
																							<ParlamentarDiscursosFavoritosModal id={i.id_modulo} transcricao={i.discurso_transcricao} qualificacao={qualificacaoAjuste(i.qualificacao_discurso)} idPermissao={user.id_permissao} />
																						</>

																						:
																						<a target="_blank" href={i.discurso_url}>{i.discurso_url}</a>}
																			</Grid>

																		</Grid>


																	)

																})
																:
																null
									}
									{
										tipoAtual === 'INTERLOCUTORES'
											?
											loading
												?
												<CircularLoading
													size={80}
													top={"90%"}
													left={"50%"}
													zIndex={2}

												/>
												:
												<Grid container style={{ justifyContent: isMobile ? 'space-evenly' : '' }}>
													{lista && lista.map((item, index) => (
														<ContainerCard key={item.id}>
															<ParlamentarCard
																key={item.id_favoritado}
																onClick={() => loadFavoritos()}
																item={item}
																index={index}
																from={'favoritados'}
																favoritadoDelete={() => desfavoritandoInterlocutores("interlocutor", item.id)}
															/>
														</ContainerCard>
													))}
												</Grid>
											:
											null

									}
									{
										tipoAtual === 'TWITTER'
											?
											loading
												?
												<CircularLoading
													size={80}
													top={"90%"}
													left={"50%"}
													zIndex={2}

												/>
												:
												listaTwitter.length > 0
													?
													listaTwitter.map((xc, index) => {
														return (
															<Grid style={{ marginBottom: "10px", marginLeft: `${isMobile ? "0px" : "200px"}`, marginRight: `${isMobile ? "0px" : "200px"}`, }}>
																<Accordion expanded elevation={0} style={{ border: "1px solid #EAEAEA" }} >
																	<AccordionDetails style={{
																		fontFamily: `${pallet.general.fontFamily}`
																	}}>
																		<Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 15px 0px 5px"}`, width: "100%" }}>

																			<Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>

																				<Grid container justifyContent="space-between">
																					<Grid item>
																						<Grid container>
																							<div key={index} style={{ cursor: "pointer", marginTop: "5px", marginLeft: "-8px" }}
																								onClick={(e) => user.id_permissao !== 3
																									?
																									(favoritandoTwitter('twitter', xc._source.tweet_id, xc._source.favoritado),
																										setSelectedIndex(index))
																									:
																									NewAlert("error", "Não é possível realizar está ação com este perfil")
																								}
																							>

																								{
																									selectedIndex === index
																										?
																										starLoading
																											?
																											<CircularLoading
																												positionCustom={true}
																												size={42}
																												top={isMobile ? "37%" : "100%"}
																												left={isMobile ? "6%" : "100%"}
																												zIndex={2}
																											/>
																											:
																											user.id_permissao !== 3 ?
																												<Checkbox
																													{...label}
																													icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
																													checkedIcon={<StarIcon />}
																													style={{ color: `${pallet.general.color2}` }}
																												/>
																												:
																												<Checkbox
																													{...label}
																													icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
																													checkedIcon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
																													style={{ color: `${pallet.general.color2}` }}
																												/>
																										:
																										user.id_permissao !== 3 ?
																											<Checkbox
																												{...label}
																												icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
																												checkedIcon={<StarIcon />}
																												style={{ color: `${pallet.general.color2}` }}
																											/>
																											:
																											<Checkbox
																												{...label}
																												icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
																												checkedIcon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
																												style={{ color: `${pallet.general.color2}` }}
																											/>
																								}
																							</div>
																							<Grid onClick={() => openTweetLink(xc._source.username, xc._source.tweet_id)} style={{ cursor: 'pointer', marginRight: '10px', marginTop: '7px' }}>
																								<img
																									style={{ width: '40px' }}
																									src={TwitterIcon}
																									alt="twitter_button"
																								/>
																								{/* <TwitterIcon /> */}
																							</Grid>
																							<Typography className={classes.heading} style={{
																								color: `${pallet.color.tertiary.font}`,
																								fontFamily: `${pallet.general.fontFamily}`,
																								fontSize: `${pallet.general.size12}`,
																								fontWeight: pallet.general.weight_600,
																								marginTop: "4px",
																								display: "flex",
																								alignItems: "center"
																							}}>
																								{formatDate(new Date(xc._source.postado_em), "dd/MM/yyyy HH:mm")}
																							</Typography>
																						</Grid>
																					</Grid>
																					<Grid item style={{ marginTop: '13px', cursor: 'pointer' }} onClick={() => openInterlocutor(xc._source.id_parlamentar)}>
																						<Grid style={{ fontSize: '13px', fontWeight: 900 }} >
																							{xc._source.tratamento ? xc._source.tratamento : null} {xc._source.nome_parlamentar ? xc._source.nome_parlamentar : null}
																						</Grid>
																						<Grid style={{ fontSize: '11px' }}>
																							{xc._source.username}
																						</Grid>
																					</Grid>
																				</Grid>
																			</Grid>
																			<Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "10px"}`, fontSize: '12px' }}>
																				<Grid style={{ marginTop: "10px" }}>
																					{xc._source.text}
																				</Grid>
																			</Grid>
																			{xc._source.url ?
																				<Grid style={{ textAlign: 'center' }}>
																					<img style={{ maxWidth: `${isMobile ? "280px" : "300px"}` }} src={`${xc._source.url}`} alt={'media'} />
																				</Grid>
																				:
																				null}
																		</Grid>
																	</AccordionDetails>
																</Accordion>
															</Grid >
														)

													})
													:
													null
											:
											null

									}
									{
										(tipoAtual === 'DOU' ||  tipoAtual === 'DIÁRIO') ?
											
		

											lista.map((i) => {
												return (
													<Grid style={{ padding: "30px" }}>


														<Grid style={{
															fontFamily: `${pallet.general.fontFamily}`,
															fontSize: `${pallet.fontSize.titleWidgets}`,
															fontWeight: pallet.general.weight_bold,
															color: `${pallet.general.color2}`,
															// marginLeft: "20px",
															// marginTop: "10px"
														}}>
															<Grid container>
																<Grid style={{ cursor: "pointer", marginTop: "5px", marginLeft: "-8px" }}
																	onClick={() => user.id_permissao !== 3 ? removerFavorito(i.id_modulo, i.id) : NewAlert(
																		"error",
																		"Não é possível realizar está ação com este perfil."
																	)}><StarIcon fontSize="small" /></Grid>

																<Grid>
																	<Grid style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center" }} onClick={() => {downloadRoot(i?.arquivo_destino,setLoading);}}>
																		<div>  SEÇÃO {i?.secao}  {i?.edicaoExtra ? "| EDIÇÃO " + i?.edicaoExtra : null}</div><div style={{ marginTop: "5px" }}><DownloadIcon style={{ color: `${pallet.general.color2}` }} /></div>
																	</Grid>
																	<Grid container style={{ fontWeight: 900, fontSize: "10px", color: `${pallet.general.color2}`, marginLeft: "10px" }}>
																		<Grid style={{ marginRight: "2px" }}>
																			Publicado em
																		</Grid>
																		<Grid>
																			{moment(i?.publicadoEm).format('DD/MM/YYYY')}
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>

														</Grid>
														<Grid style={{ fontSize: "13px" }}>
															<p>
																{i?.resumo}
															</p>
														</Grid>

													</Grid>


												)

											})

											:

											null


									}
								</Paper>
							</Grid>

						</ContainerBody>

					</ContainerHead>
					{tipoAtual && tipoAtual === "NOTÍCIAS"
						?
						null
						:
						<ContainerFooter container>
							{infoList?.totalItens > 4 ? (
								<Stack spacing={2}>
									<Pagination
										size={isMobile ? "small" : "large"}
										onChange={setPage}
										count={infoList?.totalPages}
										page={currentPage}
										shape="rounded"
									/>
								</Stack>
							) : null}
						</ContainerFooter>
					}
				</>
			</Main>
		</div>
	);
}
