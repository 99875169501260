// import React from 'react';
import useStyles from '../../../components/layout/Styles';
import { Grid, Divider, Typography, Paper, Box } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import CircularLoading from '../../../components/CircularLoading';
import EmptyErrorDoc from '../../../components/EmptyErrorDoc';
import EmptyError from '../../../components/EmptyError';
// import Tooltip from '@mui/material/Tooltip';

const DoughnutParlamentarDemandas = (props: {
    masterTitle: string,
    titleChart?: string,
    labels: Array<{ key: string, data: any, color: string }>,
    total?: number,
    seguindo?: number,
    loading?: boolean,
}) => {


    const classes = useStyles();
    const { pallet } = usePallet();
    const [loading, setLoading] = useState(true);

    const height = 250;
    const width = 400;


    const dtIni = new Date();
    const dtFin = new Date();

    // React.useEffect(() => {
    //     dateRangeSet(subYears(new Date(), 5), new Date())
    //     // eslint-disable-next-line
    // }, []);

    // const dateRangeSet = (startDate: Date, endDate: Date) => {
    //     if (endDate !== null) {
    //         setPeriod(startDate, endDate)
    //     }
    // }

    const data = {
        labels: props.labels.map((i) => { return (i.key) }),
        datasets: [{
            label: props.titleChart,
            data: props.labels.map((i) => { return (i.data) }),
            backgroundColor: props.labels.map((i) => { return (i.color) }),
            hoverBackgroundColor: props.labels.map((i) => { return (i.color) }),
            borderWidth: 0
        }],

    };
    const options = {
        plugins: {
            datalabels: {
                display: true,
                color: "#FFFFFF",
                // backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 2,
                font: {
                    size: 15,
                    weight: 600
                },
                formatter: (value, context) => {
                    if (value === 0) {
                        return ""
                    }
                    return " " + Math.round((value / (context.dataset.data.reduce((a, b) => a + b, 0) / 100))) + '% '; //Retornar porcentagem
                }
            },
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        segmentShowStroke: false
    };


    return (
        <Paper>
            <div className={classes.paper} style={{ backgroundColor: `${pallet.backgroundColorTertiary}` }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Typography
                        style={{
                            color: `${pallet.backgroundColorPrimary}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            paddingRight: "20px"
                        }}>
                        {props.masterTitle}
                    </Typography>
                </Grid>
            </div>
            <Divider />
            <div className={classes.paper}>
                <Grid container>
                    <Grid xs={6} item alignItems="left" justifyContent="space-between">
                        <Typography
                            style={{
                                color: `${pallet.general.color4}`,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: 600,
                                paddingRight: "20px"
                            }}>
                            {props.titleChart}
                        </Typography>
                    </Grid>
                    <Grid xs={6} item>

                        {/* <Grid container justifyContent="flex-end">
                            <SmallIconButton
                                title1={"PERÍODO"}
                                title2={`${format(dtIni, 'dd-MM-yyyy')} à ${format(dtFin, 'dd-MM-yyyy')}`}
                                icon={'calendar_today'}
                                onClick={null} //{dateRangeSet}
                                startDate={dtIni}
                                endDate={dtFin}
                            />
                        </Grid> */}
                    </Grid>
                </Grid>
            </div>

            {props.labels[0].data === 0 && props.labels[1].data === 0 && props.labels[2].data === 0 && props.labels[3].data === 0 ?

                props.loading
                    ?

                    <CircularLoading
                        size={80}
                        top={"90%"}
                        left={"50%"}
                        zIndex={2}
                    />

                    :

                    <Grid
                        style={{
                            color: `${pallet.textColorQuaternary}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "150px",
                            marginTop: "20px",
                        }}
                    >
                        <EmptyError title="Ops!" description="Ainda não temos dados suficientes para gerar resultados." />
                    </Grid>

                :
                <Grid>
                    <Box display="flex" justifyContent="center" style={{ paddingTop: "30px", paddingBottom: "30px" }} >
                        <Doughnut
                            width={width}
                            height={height}
                            data={data}
                            options={options}
                            plugins={[ChartDataLabels]}
                        />
                    </Box>

                    <Divider />
                    <Box justifyContent="flex-start" style={{ paddingTop: "5px", paddingRight: "20", paddingBottom: "18px", paddingLeft: "25px" }} >
                        {props.labels.map(i => (

                            <Grid container key={i.key} style={{ marginTop: `${isMobile ? "7px" : "13px"}` }}>
                                <div style={{
                                    backgroundColor: `${i.color}`,
                                    width: "25px", height: "5px",
                                    borderRadius: "3px",
                                    marginTop: "8px",
                                    marginRight: "12px"
                                }} />
                                <div style={{
                                    marginRight: "12px",
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.subtitle
                                }}>{i.key}</div>
                                <div style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontWeight: pallet.fontWeight.lighter,
                                    color: `${pallet.color.quaternary.font}`
                                }}>{i.data}</div>
                            </Grid>
                        ))}
                    </Box>
                </Grid>}
        </Paper>

    )

}

export default DoughnutParlamentarDemandas;

function PieArcLabelFormat(v: any): import("react").ReactNode {
    throw new Error('Function not implemented.');
}
function setPeriod(startDate: Date, endDate: Date) {
    throw new Error('Function not implemented.');
}

