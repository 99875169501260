import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import GerUsuariosTabsUsuarios from "./GerUsuariosTabsUsuarios";


const GerUsuariosCarouselTabsUsuarios = (props: { id_user: number, activeTab: any, index: number,onChange: () => void }) => {

    return (
        <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "30px"}`, }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <GerUsuariosTabsUsuarios id_user={props.id_user} index={props.index} activeTab={props.activeTab} onChange={props.onChange} />
            </TabPanel>
        </Paper>
    )
}

export default GerUsuariosCarouselTabsUsuarios;