import * as http from '../helpers/http';

class ParlamentarDemandaPosicionamentoService {

    GetList = async (id: number, filtro: any, pageIndex: number, pageSize: number = 8): Promise<any> => {
        const result = await http.get(`/parlamentar/demanda${id}/posicionamento/?filter=${JSON.stringify(filtro)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                lista: result.data.data
            }
        }
        return undefined;
    }
    Update = async (data: any, id: number,): Promise<boolean> => {
        const result = await http.put(`/parlamentar/demandas/posicionamento/${id}`, data);
        if (result.success) { return result.success }
        return false;
    };

    Delete = async (id: number): Promise<boolean> => {
        const result = await http.del(`/parlamentar/demandas/posicionamento/${id}`);
        if (result.success) { return true }
        return false;
    };



}
export default new ParlamentarDemandaPosicionamentoService();
