import * as http from '../helpers/http';

class DiarioService {

    GetElkGeralDiario = async (word: string, data: any): Promise<any> => {

        const result = await http.post(`/diario/busca-geral`, { texto: word, data });
        if (result.success) { return result.data }
        return undefined;
    };

    GetTextoDiario = async (id: string): Promise<any> => {
        const result = await http.get(`/diario/${id}/texto`);
        if (result.success) { return result.data }
        return undefined;
    };


}

export default new DiarioService();
