import React, { useEffect, useState } from 'react';
import styledC from "styled-components";
import Main from '../main/Main';
import useStyles from '../../components/layout/Styles';
import { usePallet } from '../../contexts/PalletContext';
import { Box, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import SolidButton from '../../components/SolidButton';
import ButtonDialogConfirmation from '../../components/ButtonDialogConfirmation';
import uuid from 'uuid/dist/v1';
import InputTextField from '../../components/InputTextField';
import InputTextFieldTags from '../../components/InputTextFieldTags';
import SelectTextFieldA from '../../components/SelectTextFieldA'
import TabelasService from '../../services/TabelasService';
import { useAlert } from '../../contexts/AlertContext';
import InputMultilineTextField from '../../components/InputMultilineTextField'
import InputDatePicker from '../../components/InputDatePicker'
import ImageLoader from '../../components/ImageLoader';
import ParlamentarService from '../../services/ParlamentarService';
import { parlamentarQualificacoes } from "../../models/ParlamentarQualificacoes";
import ParlamentarCadastroSchema from './models/ParlamentarCadastroSchema';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CircularLoading from '../../components/CircularLoading';
import { IListFilterInputItem } from '../../components/filter/ListFilterItems';
import ParlamentarCadastroPROCOMSchema from './models/ParlamentarCadastroPROCOMSchema';
import TwitterService from '../../services/TwitterService';
import ProposicaoService from '../../services/ProposicaoService';



// const TitlePage = styledC(Grid)`
//         color: ${props => props.pallet.textColorSecondary};
//         font-weight: ${props => props.pallet.general.weight_600};
//         font-size: "24px";
//         font-family: ${props => props.pallet.general.fontFamily};                
//         margin-top: "30px";
// `;

const Title = styledC(Grid)`
        color: ${(props => props.pallet.textColorSecondary)};
        font-weight: ${(props => props.pallet.general.weight_600)};
        font-size: 14px;
        font-family: ${(props => props.pallet.general.fontFamily)};
        margin-bottom: 20px;
`;

const CheckBoxText = styledC(Grid)`
        color: ${(props => props.checkText.textColorSecondary)};
        font-weight: ${(props => props.checkText.general.weight_100)};
        font-size: 14px;
        font-family: ${(props => props.checkText.general.fontFamily)};
        margin-bottom: 20px;
        padding-Top: 20px;
`;


const LabelFalecido = () => {
    const { pallet } = usePallet();
    return (
        <>
            <CheckBoxText checkText={pallet}>
                Falecido(a)
            </CheckBoxText>
        </>
    )
}

const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
    const itemError = props.errors.filter(err => err.name === props.name);
    return (
        <div style={{ color: 'red' }}>
            {itemError.length === 1 && itemError[0].err}
        </div>
    )
}

const LabelSeguir = (props: { errors: Array<{ name: string, err: string }> }) => {
    const { pallet } = usePallet();
    return (
        <>
            <CheckBoxText checkText={pallet}>
                Seguir interlocutor
            </CheckBoxText>
        </>
    )
}
const LabelNotificacoes = () => {
    const { pallet } = usePallet();
    return (
        <>
            <CheckBoxText checkText={pallet}>
                Notificações Ativadas
            </CheckBoxText>
        </>
    )
}


// const SmallAvatar = styled(Avatar)(({ theme }) => ({
//     width: 22,
//     height: 22,
//     border: `2px solid ${theme.palette.background.paper}`,
// }));

// const defaultFilter = [
//     { key: 'esfera', label: 'Esfera Federal', value: 'Federal' },
//     { key: 'legislatura', label: 'Legislatura 56', value: '56' },
//     { key: 'situacao', label: 'Exercício', value: 'Exercício' }
// ]

const juduciario = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Juiz', label: 'Juiz', value: 'Juiz' },
    { key: 'Promotor de justiça', label: 'Promotor de justiça', value: 'Promotor de justiça' },
    { key: 'Desembargador', label: 'Desembargador', value: 'Desembargador' },
    { key: 'Procurador', label: 'Procurador', value: 'Procurador' },
    { key: 'Defensor Público', label: 'Defensor Público', value: 'Defensor Público' }
]

const executivo = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Presidente', label: 'Presidente', value: 'Presidente' },
    { key: 'Vice presidente', label: 'Vice presidente', value: 'Vice presidente' },
    { key: 'Ministro', label: 'Ministro', value: 'Ministro' },
    { key: 'Secretario', label: 'Secretario', value: 'Secretario' }
]

const legislativo = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Congressista', label: 'Congressista', value: 'Congressista' },
    { key: 'Secretario', label: 'Secretario', value: 'Secretario' },
    { key: 'Assessor', label: 'Assessor', value: 'Assessor' },
]

const executivoEstadual = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Governador', label: 'Governador', value: 'Governador' },
    { key: 'Vide Governador', label: 'Vice Governador', value: 'Vice Governador' },
    { key: 'Secretário', label: 'Secretário', value: 'Secretário' },
    { key: 'Assessor', label: 'Assessor', value: 'Assessor' }
]

const executivoMunicipal = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Prefeito', label: 'Prefeito', value: 'Prefeito' },
    { key: 'Vide Prefeito', label: 'Vice Prefeito', value: 'Vice Prefeito' },
    { key: 'Secretário', label: 'Secretário', value: 'Secretário' }
]

const legislativoEstadual = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Deputado', label: 'Deputado', value: 'Deputado' },
    { key: 'Secretário', label: 'Secretário', value: 'Secretário' },
    { key: 'Assessor', label: 'Assessor', value: 'Assessor' }
]

const legislativoMunicipal = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Vereador', label: 'Vereador', value: 'Vereador' },
]

const escolaridadeItens = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Fundamental - Completo', label: 'Fundamental - Completo', value: 'Fundamental - Completo' },
    { key: 'Médio - Incompleto', label: 'Médio - Incompleto', value: 'Médio - Incompleto' },
    { key: 'Médio - Completo', label: 'Médio - Completo', value: 'Médio - Completo' },
    { key: 'Superior - Incompleto', label: 'Superior - Incompleto', value: 'Superior Incompleto' },
    { key: 'Superior - Completo', label: 'Superior - Completo', value: 'Superior' },
    { key: 'Pós-graduação - Incompleto', label: 'Pós-graduação - Incompleto', value: 'Pós-graduação - Incompleto' },
    { key: 'Pós-graduação - Completo', label: 'Pós-graduação - Completo', value: 'Pós-graduação - Completo' },
    { key: 'Mestrado - Incompleto', label: 'Mestrado - Incompleto', value: 'Mestrado - Incompleto' },
    { key: 'Mestrado - Completo', label: 'Mestrado - Completo', value: 'Mestrado - Completo' },
    { key: 'Doutorado - Incompleto', label: 'Doutorado - Incompleto', value: 'Doutorado - Incompleto' },
    { key: 'Doutorado - Completo', label: 'Doutorado - Completo', value: 'Doutorado - Completo' },
]

const situacaoItens = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Afastado', label: 'Afastado', value: 'Afastado' },
    { key: 'Exercício', label: 'Exercício', value: 'Exercício' },
    { key: 'Fim de Mandato', label: 'Fim de Mandato', value: 'Fim de Mandato' },
    { key: 'Licença', label: 'Licença', value: 'Licença' },
    { key: 'Vacância', label: 'Vacância', value: 'Vacância' },
    { key: 'Suplência', label: 'Suplência', value: 'Suplência' },
    { key: 'Falecido', label: 'Falecido(a)', value: 'Falecido' },
]

const poderItens = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Legislativo', label: 'Legislativo', value: 'L' },
    { key: 'Executivo', label: 'Executivo', value: 'E' },
    { key: 'Judiciário', label: 'Judiciário', value: 'J' },
]

const poderSenadoCamaraItens = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Legislativo', label: 'Legislativo', value: 'L' },
    { key: 'Judiciário', label: 'Judiciário', value: 'J' },
]

const poderMunicipalItens = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Legislativo', label: 'Legislativo', value: 'L' },
    { key: 'Executivo', label: 'Executivo', value: 'E' },
]

const ListaEsfera = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 1, label: 'Federal', value: 'F' },
    { key: 2, label: 'Estadual', value: 'E' },
    { key: 3, label: 'Municipal', value: 'M' },
];


const ListaEsferaProcom = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 1, label: 'Assembleia', value: 'A' },
    { key: 2, label: 'Estadual', value: 'E' },
    { key: 3, label: 'Municipal', value: 'M' },
];



const getQualificacaoValue = (qualificacao: string): string => {
    if (qualificacao) {
        switch (qualificacao.toLowerCase()) {
            case 'divergente':
                return 'D'
            case 'neutro':
                return 'N'
            case 'convergente':
                return 'C'
            default:
                return 'NQ'
        }
    }
}


export default function ParlamentarCadastro() {

    const { idInter } = useParams() as any;
    const navigate = useNavigate();
    const classes = useStyles();
    const { pallet } = usePallet();
    const { NewAlert } = useAlert();
    const [id, setId] = useState(0);
    const [idInterlocutorEdit, setIdInterlocutorEdit] = useState(idInter);
    const [arrayGetInterlocutor, setArrayGetInterlocutor] = useState({});

    const [imageGet, setImageGet] = useState('');
    const [nome, setNome] = useState('');
    const [data, setData] = useState({});
    const [uri, setUri] = useState('');
    const [qualificacao, setQualificacao] = useState('');
    const [observacoes, setObservacoes] = useState('');
    const [apelido, setApelido] = useState('');
    const [dataNascimento, setDataNascimento] = useState(Date);
    const [dataFalecimento, setDataFalecimento] = useState(Date);
    const [ufNascimento, setUfNascimento] = useState('');
    const [arrayUfNascimento, setArrayUfNascimento] = useState([]);
    const [municipioNascimento, setMunicipioNascimento] = useState('');
    const [arrayMunicipioNascimento, setArrayMunicipioNascimento] = useState([]);
    const [escolaridade, setEscolaridade] = useState('');
    const [endereco, setEndereco] = useState('');
    const [esfera, setEsfera] = useState('');
    const [esferaProcom, setEsferaProcom] = useState('');
    const [casaEstadual, setCasaEstadual] = useState('');
    const [casaMunicipal, setCasaMunicipal] = useState('');
    const [casaEstadoItens, setCasaEstadosItens] = React.useState<Array<IListFilterInputItem>>([]);
    const [casaMunicipiosItens, setCasaMunicipiosItens] = React.useState<Array<IListFilterInputItem>>([])
    const [legislatura, setLegislatura] = useState(undefined);
    const [partido, setPartido] = useState('');
    const [arrayPartido, setArrayPartido] = useState([]);
    const [estadoAtuacao, setEstadoAtuacao] = useState('');
    const [ufAtuacao, setUfAtuacao] = useState('');
    const [arrayUfAtuacao, setArrayUfAtuacao] = useState([]);
    const [municipioAtuacao, setMunicipioAtuacao] = useState('');
    const [arraymunicipioAtuacao, setArraymunicipioAtuacao] = useState([]);
    const [situacao, setSituacao] = useState('');
    const [poder, setPoder] = useState('');
    const [cargo, setCargo] = React.useState('');
    const [inputEmails, setInputEmails] = useState('');
    const [arrayEmails, setArrayEmails] = useState<any[]>([]);
    const [inputProfissao, setInputProfissao] = useState('');

    const [facebookUrl, setFacebookUrl] = useState('');
    const [instagramUrl, setInstagramUrl] = useState('');
    const [twitter, setTwitter] = useState('');
    const [twitterAlert, setTwitterAlert] = useState('');
    const [buttonFree, setButtonFree] = useState(false);
    const [falecido, setFalecido] = useState(false);
    const [seguir, setSeguir] = useState(false);
    const [ativarNotificacoes, setAtivarNotificacoes] = useState(false);
    const [arrayProfissao, setArrayProfissao] = useState<any[]>([]);
    const [inputTelefone, setInputTelefone] = useState('');
    const [arrayTelefone, setArrayTelefone] = useState<any[]>([]);
    const [error, setError] = useState<Array<{ name: string, err: string }>>([])

    const [imageSelected, setImageSelected] = useState({})
    const [loading, setLoading] = useState(false)

    const [casaUpdate, setCasaUpdate] = useState<string>("")

    const [casaFederal, setCasaFederal] = React.useState<string>('');
    const [casaFederalItens, setCasaFederalItens] = React.useState<Array<IListFilterInputItem>>([
        { key: '0', label: '-- Selecione -- ', value: "" },
        { key: '1', label: 'Senado Federal', value: '1' },
        { key: '2', label: 'Câmara dos Deputados', value: '2' },
        { key: '3', label: 'Congresso Nacional', value: '3' },
    ]);
    const [tipoParlamentar, setTipoParlamentar] = React.useState<string>('');
    const [tipoParlamentarItens, setTipoParlamentarItens] = React.useState<Array<IListFilterInputItem>>([
        { key: '0', label: '-- Selecione -- ', value: "" },
        { key: '1', label: 'Politico', value: 'politico' },
        { key: '2', label: 'PROCOM', value: 'procom' },
    ]);




    const [municipioProcom, setMunicipioProcom] = useState('');
    const [arrayMunicipioProcom, setArrayMunicipioProcom] = useState([]);
    const [municipioProcomSigla, setMunicipioProcomSigla] = useState(0);
    const [ufAtuacaoProcom, setUfAtuacaoProcom] = useState('');
    const [arrayUfAtuacaoProcom, setArrayUfAtuacaoProcom] = useState([]);
    const [ufAtuacaoProcomId, setUfAtuacaoProcomId] = useState(0);



    React.useEffect(() => {

        if (idInterlocutorEdit && idInterlocutorEdit !== undefined) {
            setLoading(true)
            ParlamentarService
                .Get(Number(idInterlocutorEdit))
                .then(res => {
                    setTipoParlamentar(res?.tipo)
                    if (res.tipo === 'procom') {
                        setUfAtuacaoProcom(res.uf_exercicio)
                        setMunicipioProcom(res.municipioExercicio)
                        setEsferaProcom(res.esfera)
                    }
                    setImageGet(res.url_foto)
                    setArrayGetInterlocutor(res)
                    setNome(res.nome_civil)
                    setApelido(res.nome)
                    setDataNascimento(res.data_nascimento)
                    setUfNascimento(res.uf_nascimento)
                    if (res.data_falecimento === undefined) {
                        setFalecido(false)
                    } else {
                        setFalecido(true)
                    }
                    setDataFalecimento(res.data_falecimento ? res.data_falecimento : null)
                    TabelasService.getTabelaMunicipios(res.uf_nascimento).then(
                        res => {
                            if (res) {
                                const data = []
                                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                                res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.nome }))
                                setArrayMunicipioNascimento(() => [...data]);


                            }
                        })
                    setMunicipioNascimento(res.municipio_nascimento)
                    setEscolaridade(res.escolaridade)
                    setArrayEmails(res.emails.map(x => {
                        return (
                            { id: uuid(), tagLabel: x.label, origin: x.origin }
                        )
                    }))
                    handleAddProfissao(res.profissoes)
                    setArrayProfissao(res.profissoes.map(x => {
                        return (
                            { id: uuid(), tagLabel: x.label, origin: x.origin }
                        )
                    }))
                    setArrayTelefone(res.telefones.map(x => {
                        return (
                            { id: uuid(), tagLabel: x.label, origin: x.origin }
                        )
                    }))
                    setEndereco(res.endereco)
                    setLegislatura(res.legislatura)
                    setPartido(res.sigla_partido)
                    setCargo(res.tratamento)
                    if (res.tipo === 'politico') {
                        setUfAtuacao(res.uf_exercicio)
                        setEsfera(res.esfera)
                        setMunicipioAtuacao(res.municipioExercicio)
                    }

                    TabelasService.getTabelaMunicipios(res.uf_exercicio).then(
                        res => {
                            if (res) {
                                const data = []
                                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                                res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.nome }))
                                setArraymunicipioAtuacao(() => [...data]);
                                setArrayMunicipioProcom(() => [...data]);
                            }
                        })
                    setSituacao(res.situacao)
                    setEscolaridade(res.escolaridade)
                    setCasaUpdate(res.casa)
                    if (res.esfera === "A") {
                        setUfAtuacaoProcomId(res.casa)

                    }
                    if (res.esfera === "F") {
                        setCasaFederal(res.casa)

                    }
                    if (res.esfera === "E") {
                        setCasaEstadual(res.casa)
                        setUfAtuacaoProcomId(res.casa)
                    }
                    if (res.esfera === "M") {
                        setCasaMunicipal(res.casa)
                        setMunicipioProcomSigla(res.casa)
                    }
                    setPoder(res.poder)
                    setUri(res.uri)
                    setQualificacao(res?.cliente?.qualificacao === "Convergente" ? "convergente" : res?.cliente?.qualificacao === "Divergente" ? "divergente" : res?.cliente?.qualificacao === "Neutro" ? "neutro" : null)
                    setObservacoes(res?.cliente?.observacao)
                    setFacebookUrl(res?.social?.rede1)
                    setInstagramUrl(res?.social?.rede2)
                    if (res?.social?.rede3 && (res.social.rede3.includes('http') || res.social.rede3.includes('https') || res.social.rede3.includes('@') || res.social.rede3.includes('twitter.com') || res.social.rede3.includes('/'))) {
                        setTwitter(res?.social?.rede3.split('/').pop());
                        setTwitterAlert('Escreva o username, não coloque url')
                        setButtonFree(true)
                    } else {
                        setTwitter(res?.social?.rede3)
                        setButtonFree(false)
                    }
                    setSeguir(res?.cliente?.seguindo)
                    setLoading(false)
                });

        }

        arrayPartido.length === 0 && TabelasService.getTabelaPartidoTodos().then(
            res => {
                if (res) {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map((item, index) => data.push({ key: index, label: item, value: item }))
                    setArrayPartido(() => [...data]);
                }
            })
        arrayPartido.length === 0 && TabelasService.getTabelaEstados().then(
            res => {
                if (res) {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.sigla }))
                    setArrayUfNascimento(() => [...data]);
                    setArrayUfAtuacao(() => [...data]);
                    setArrayUfAtuacaoProcom(() => [...data]);
                }
            })




    }, []);

    function enviarCasa(esfera: string, tipo: string) {

        let numeroCasa: number
        if (tipo === 'politico') {

            if (esfera === 'E') {
                numeroCasa = Number(casaEstadual)
            }
            else if (esfera === 'M') {
                numeroCasa = Number(casaMunicipal)
            }
            else if (esfera === 'F') {
                numeroCasa = Number(casaFederal)
            }
        } else if (tipo === 'procom') {

            if (esfera === 'E') {
                numeroCasa = ufAtuacaoProcomId
            }
            else if (esfera === 'M') {
                numeroCasa = municipioProcomSigla
            }
            else if (esfera === 'A') {
                numeroCasa = ufAtuacaoProcomId
            }

        }


        return numeroCasa

        // casa: esfera === 'E' ? Number(casaEstadual) : esfera === 'M' ? Number(casaMunicipal) : esfera === 'F' ? Number(casaFederal) : null,



    }


    const pacoteSalvar = () => {
        // setLoading(true)

        // CAPTURA DE DADOS DOS CAMPOS
        const data = {
            tipo: tipoParlamentar,
            esfera: esfera ? esfera : esferaProcom ? esferaProcom : null,
            poder: poder,
            pessoal: {
                nome: nome,
                apelido: apelido,
                cpf: null,
                foto: null,
                uri: uri,
                sexo: 'N',
                municipio: municipioNascimento,
                escolaridade: escolaridade,
                uf: ufNascimento,
                dataNascimento: (dataNascimento ? new Date(dataNascimento) : null),
                dataFalecimento: (
                    falecido ? new Date(dataFalecimento) : null
                ),
            },
            profissional: {
                // casa: esfera === 'E' ? Number(casaEstadual) : esfera === 'M' ? Number(casaMunicipal) : esfera === 'F' ? Number(casaFederal) : null,
                casa: enviarCasa(esfera ? esfera : esferaProcom ? esferaProcom : null, tipoParlamentar) === 0 ? null : enviarCasa(esfera ? esfera : esferaProcom ? esferaProcom : null, tipoParlamentar),
                partido: partido,
                cargo: cargo,
                uf: esfera === 'M' ? ufAtuacao : esfera === 'E' ? estadoAtuacao : esferaProcom === 'E' || esferaProcom === 'A' || esferaProcom === 'M' ? ufAtuacaoProcom : null,
                municipio: esfera === 'M' ? municipioAtuacao : esferaProcom === 'M' ? municipioProcom : null,
                situacao: situacao,
                endereco: endereco,
                legislatura: legislatura
            },
            telefones: arrayTelefone && arrayTelefone.map(item => item.tagLabel),
            emails: arrayEmails && arrayEmails.filter(x => x.origin === 1).map(item => item.tagLabel),
            profissoes: arrayProfissao && arrayProfissao.filter(x => x.origin === 1).map(item => item.tagLabel),
            cliente: {
                seguindo: seguir,
                notificar: ativarNotificacoes,
                qualificacao: getQualificacaoValue(qualificacao),
                observacao: observacoes
            },
            social: {
                rede1: facebookUrl,
                rede2: instagramUrl,
                rede3: twitter
            },
            imagem: imageSelected
        }

        setError(() => []);
        data && ParlamentarCadastroSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));
        setData(data);
        ParlamentarCadastroSchema.validate(data).catch(function (err) { });

        if (ParlamentarCadastroSchema.isValidSync(data)) {
            if (twitter) {
                TwitterService.TwitterUserValidation({ username: twitter }).then(res => {
                    if (res && res.data.data) {
                        if (tipoParlamentar === 'politico') {
                            if (idInter) {
                                // EDICAO
                                ParlamentarService.Update(idInter, data).then(res => {
                                    if (res && res > 0) {
                                        setId(res)
                                        NewAlert('success', 'Interlocutor editado com sucesso')
                                        navigate(`/interlocutor/${idInter}`)
                                    } else {
                                        NewAlert('error', 'Verifique os campos e salve outra vez');
                                    }
                                });
                            } else {
                                // CADASTRO
                                setButtonFree(true)
                                ParlamentarService.Create(data).then(res => {
                                    if (res && res > 0) {
                                        setId(res)
                                        NewAlert('success', 'Interlocutor cadastrado com sucesso')
                                        navigate("/interlocutor")
                                    } else if (res === -1) {
                                        setButtonFree(false)
                                        NewAlert('error', 'O parlamentar já existe.');
                                    }
                                    else {
                                        setButtonFree(false)
                                        NewAlert('error', 'Verifique os campos e salve outra vez');
                                    }
                                });
                            };
                        } else if (tipoParlamentar === 'procom') {
                            if (idInter) {
                                // EDICAO
                                ParlamentarService.Update(idInter, data).then(res => {
                                    if (res && res > 0) {
                                        setId(res)
                                        NewAlert('success', 'Interlocutor editado com sucesso')
                                        navigate(`/interlocutor/${idInter}`)
                                    } else {
                                        NewAlert('error', 'Verifique os campos e salve outra vez');
                                    }
                                });
                            } else {
                                // CADASTRO
                                ParlamentarService.Create(data).then(res => {
                                    if (res && res > 0) {
                                        setId(res)
                                        setButtonFree(true)
                                        NewAlert('success', 'Interlocutor cadastrado com sucesso')
                                        navigate("/interlocutor")
                                    } else {
                                        NewAlert('error', 'Verifique os campos e salve outra vez');
                                    }
                                });
                            };
                        };
                    } else {
                        NewAlert('error', 'Username Twitter não existe');
                    };
                });
            } else {
                if (tipoParlamentar === 'politico') {
                    if (idInter) {
                        // EDICAO
                        ParlamentarService.Update(idInter, data).then(res => {
                            if (res && res > 0) {
                                setId(res)
                                NewAlert('success', 'Interlocutor editado com sucesso')
                                navigate(`/interlocutor/${idInter}`)
                            } else {
                                NewAlert('error', 'Verifique os campos e salve outra vez');
                            }
                        });
                    } else {
                        // CADASTRO
                        setButtonFree(true)
                        ParlamentarService.Create(data).then(res => {
                            if (res && res > 0) {
                                setId(res)
                                NewAlert('success', 'Interlocutor cadastrado com sucesso')
                                navigate("/interlocutor")
                            } else if (res === -1) {
                                setButtonFree(false)
                                NewAlert('error', 'O parlamentar já existe.');
                            }
                            else {
                                setButtonFree(false)
                                NewAlert('error', 'Verifique os campos e salve outra vez');
                            }
                        });
                    };
                } else if (tipoParlamentar === 'procom') {
                    if (idInter) {
                        // EDICAO
                        ParlamentarService.Update(idInter, data).then(res => {
                            if (res && res > 0) {
                                setId(res)
                                NewAlert('success', 'Interlocutor editado com sucesso')
                                navigate(`/interlocutor/${idInter}`)
                            } else {
                                NewAlert('error', 'Verifique os campos e salve outra vez');
                            };
                        });
                    } else {
                        // CADASTRO
                        ParlamentarService.Create(data).then(res => {
                            if (res && res > 0) {
                                setId(res)
                                setButtonFree(true)
                                NewAlert('success', 'Interlocutor cadastrado com sucesso')
                                navigate("/interlocutor")
                            } else {
                                NewAlert('error', 'Verifique os campos e salve outra vez');
                            };
                        });
                    };
                };
            };
        } else {
            NewAlert('error', 'Favor preencher os campos obrigatórios');
        };
    };


    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNome(event.target.value.trim())
    };

    const handleApelidoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApelido(event.target.value.trim())
    };

    const handleDataNascimentoChange = (dateNascimento) => {
        setDataNascimento(dateNascimento)
    };

    const handleDataFalecimentoChange = (dateFalecimento) => {
        setDataFalecimento(dateFalecimento)
    };

    const handleUfNascimentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUfNascimento(event.target.value)
        TabelasService.getTabelaMunicipios(event.target.value).then(
            res => {
                if (res) {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.nome }))
                    setArrayMunicipioNascimento(() => [...data]);
                }
            })
    };

    const handleMunicipioNascimentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMunicipioNascimento(event.target.value)
    };

    const handleMunicipioProcomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMunicipioProcom('')
        setMunicipioProcom(event.target.value)


        TabelasService.getTabelaMunicipiosByName(event.target.value).then(
            res => {
                if (res) {
                    setMunicipioProcomSigla(res[0].id)
                }
            })
    };


    const handleEscolaridadeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEscolaridade(event.target.value)
    };



    const handleEsferaProcomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEsferaProcom('')
        setEsferaProcom(event.target.value)
        if (event.target.value === "A") {
            setUfAtuacaoProcom(undefined)
            setMunicipioProcom(undefined)
        }
        if (event.target.value === "E") {
            setUfAtuacaoProcom(undefined)
            setMunicipioProcom(undefined)
        }
        if (event.target.value === "M") {
            setUfAtuacaoProcom(undefined)
            setMunicipioProcom(undefined)
        }
    };





    const handleEsferaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEsfera(undefined)
        setEsfera(event.target.value)
        if (event.target.value === "F") {
            setMunicipioAtuacao(undefined)
            setCasaEstadual(undefined)
            setCasaMunicipal(undefined)
            setUfAtuacao(undefined)
            setEstadoAtuacao(undefined)
            setPoder(undefined)
            setCargo(undefined)
            setSituacao(undefined)

        }
        if (event.target.value === "E") {
            setMunicipioAtuacao(undefined)
            setCasaFederal(undefined)
            setCasaMunicipal(undefined)
            setUfAtuacao(undefined)
            setPoder(undefined)
            setCargo(undefined)
            setSituacao(undefined)
            setPartido(undefined)
            setLegislatura(undefined)
        }
        if (event.target.value === "M") {
            setCasaFederal(undefined)
            setCasaEstadual(undefined)
            setPoder(undefined)
            setSituacao(undefined)
            setCargo(undefined)
            setPartido(undefined)
            setLegislatura(undefined)
        }
    };

    const handlePoderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPoder(event.target.value)
        setCargo(undefined)
        setSituacao(undefined)

    };

    const handleCargoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCargo(event.target.value)
    };

    const handleCasaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCasaFederal(event.target.value)
        setCasaEstadual(undefined)
    };

    React.useEffect(() => {
        setEsfera('')
        setEsferaProcom('')
    }, [tipoParlamentar]);

    const handleTipoInterlocutorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setEsfera('M')
        // setEsferaProcom('M')
        setMunicipioProcom(undefined)
        setUfAtuacaoProcom(undefined)
        setMunicipioAtuacao(undefined)
        setCasaFederal(undefined)
        setCasaMunicipal(undefined)
        setUfAtuacao(undefined)
        setPoder(undefined)
        setCargo(undefined)
        setSituacao(undefined)
        setPartido(undefined)
        setLegislatura(undefined)
        setUri(undefined)

        setTipoParlamentar(event.target.value)

    };


    const handleLegislaturaChange = (event: React.ChangeEvent<any>) => {
        setLegislatura(event.target.value)
    };

    const handlePartidoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPartido(event.target.value)
    };

    const handleUfAtuacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUfAtuacao(event.target.value)
    };


    const handleUfAtuacaoProcomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUfAtuacaoProcom('')
        setUfAtuacaoProcom(event.target.value)
        TabelasService.getTabelaMunicipios(event.target.value).then(
            res => {
                if (res) {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.nome }))
                    setArrayMunicipioProcom(() => [...data]);
                }
            })


        TabelasService.getTabelaEstadoId(event.target.value).then(
            res => {
                if (res) {
                    setUfAtuacaoProcomId(res[0].id)
                }
            })


    };

    const handleSituacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSituacao(event.target.value)
    };

    const handleEnderecoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndereco(event.target.value)
    };

    const handleUriChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUri(event.target.value)
    };

    const handleQualificacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQualificacao(event.target.value);
    };

    const handleObservacoesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setObservacoes(event.target.value)
    };

    const handleFacebookChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFacebookUrl(event.target.value)
    };

    const handleInstagramChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInstagramUrl(event.target.value)
    };

    const handleTwitterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.includes('http') || event.target.value.includes('https') || event.target.value.includes('@') || event.target.value.includes('twitter.com') || event.target.value.includes('/')) {
            setTwitter(event.target.value.split('/').pop());
            setTwitterAlert('Escreva o username, não coloque url')
            setButtonFree(true)
        } else {
            setTwitter(event.target.value);
            setButtonFree(false)
        }
    };

    const handleFalecidoChange = (event: React.ChangeEvent<any>) => {
        setFalecido(event.target.checked)
        if (event.target.checked === false) {
            setDataFalecimento(null)
        }
    };

    const handleSeguirChange = (event: React.ChangeEvent<any>) => {
        setSeguir(event.target.checked)
    };

    const handleAtivarNotificacoesChange = (event: React.ChangeEvent<any>) => {
        setAtivarNotificacoes(event.target.checked)
    };

    // --------------------- ARRAY DE EMAILS --------------------

    const handleAddEmails = async (e) => {
        if (inputEmails === "") return
        // e.preventDefault()
        setArrayEmails([...arrayEmails, { id: uuid(), tagLabel: inputEmails, origin: 1 }])
        setInputEmails('')
        // setArrayEmailsSend([...arrayEmails, { id: uuid(), nome: inputEmails }])
    }

    const deleteTagEmail = (id) => {
        //faço uma copia do array antigo e add em um novo array
        const newArrayEmails = arrayEmails.slice(0)
        //Pego o index do array escolhido para exclusao da lista
        const index = newArrayEmails.findIndex(arrayEmails => arrayEmails.id === id)
        //removo a palavra selecionada apartir do seu index do array
        newArrayEmails.splice(index, 1)
        //add o novo array ao estado atual do componente
        setArrayEmails([...newArrayEmails])
    };


    // --------------------- ARRAY DE PROFISSOES --------------------

    const handleAddProfissao = async (e) => {
        if (inputProfissao === "") return
        // e.preventDefault()
        setArrayProfissao([...arrayProfissao, { id: uuid(), tagLabel: inputProfissao, origin: 1 }])
        setInputProfissao('')
    }

    const deleteTagProfissao = (id) => {
        //faço uma copia do array antigo e add em um novo array
        const newArrayProfissao = arrayProfissao.slice(0)
        //Pego o index do array escolhido para exclusao da lista
        const index = newArrayProfissao.findIndex(arrayProfissao => arrayProfissao.id === id)
        //removo a palavra selecionada apartir do seu index do array
        newArrayProfissao.splice(index, 1)
        //add o novo array ao estado atual do componente
        setArrayProfissao([...newArrayProfissao])
    };


    // --------------------- ARRAY DE TELEFONES --------------------

    const handleAddTelefone = async (e) => {
        if (inputTelefone === "") return
        // e.preventDefault()
        setArrayTelefone([...arrayTelefone, { id: uuid(), tagLabel: inputTelefone }])
        setInputTelefone('')
    }

    const deleteTagTelefone = (id) => {
        //faço uma copia do array antigo e add em um novo array
        const newArrayTelefone = arrayTelefone.slice(0)
        //Pego o index do array escolhido para exclusao da lista
        const index = newArrayTelefone.findIndex(arrayTelefone => arrayTelefone.id === id)
        //removo a palavra selecionada apartir do seu index do array
        newArrayTelefone.splice(index, 1)
        //add o novo array ao estado atual do componente
        setArrayTelefone([...newArrayTelefone])
    };

    const onImageSelect = (file) => {
        setImageSelected(file)
    }


    const handleCasaEstadosOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCasaEstadual(event.target.value)
        setCasaMunicipal(undefined)
    };

    const handleCasaMunicipiosOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCasaMunicipal(event.target.value)
    };

    React.useEffect(() => {
        if (ufAtuacao) {
            TabelasService.getTabelaCasasMunicipaisByUf(String(ufAtuacao))
                .then(async res => {
                    if (res) {
                        const data = []
                        data.push({ key: "", label: '-- Selecione -- ', value: "" });
                        res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
                        setCasaMunicipiosItens(() => [...data]);
                    }
                });
        }
    }, [ufAtuacao]);

    React.useEffect(() => {
        if (esfera === 'E') {
            TabelasService.getTabelaCasasEstaduais()
                .then(async res => {
                    if (res) {
                        const data = []
                        data.push({ key: "", label: '-- Selecione --', value: "" });
                        res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
                        setCasaEstadosItens(() => [...data]);
                    }
                });
        }
    }, [esfera]);

    React.useEffect(() => {
        if (casaEstadual) {
            TabelasService.getTabelaEstadosById(Number(casaEstadual))
                .then(async res => {
                    if (res) {
                        setEstadoAtuacao(res[0].sigla)
                    }
                });
        }
    }, [casaEstadual]);

    React.useEffect(() => {
        if (casaMunicipal) {
            TabelasService.getTabelaMunicipiosById(Number(casaMunicipal))
                .then(async res => {
                    if (res) {
                        setMunicipioAtuacao(res[0].nome)
                    }
                });
        }
    }, [casaMunicipal]);


    return (
        <div className={classes.root}>
            <Main>
                <Grid>
                    {loading
                        ?
                        <CircularLoading
                            size={100}
                            top={isMobile ? "37%" : "40%"}
                            left={isMobile ? "6%" : "16%"}
                            zIndex={2}
                        />
                        :
                        <Grid>
                            <Grid style={{
                                color: `${pallet.textColorSecondary}`,
                                fontWeight: pallet.general.weight_600,
                                fontSize: "24px",
                                fontFamily: `${pallet.general.fontFamily}`,
                                marginTop: "30px"
                            }}>
                                {idInterlocutorEdit && idInterlocutorEdit !== undefined ? "Editar Interlocutor" : "Cadastrar Interlocutor"}
                            </Grid>
                            <Grid style={{ backgroundColor: `${pallet.backGroundPageColorSecondary}`, padding: `${isMobile ? "15px" : "40px"}`, marginTop: "30px" }}>
                                <Title pallet={pallet}>
                                    FOTO DE PERFIL
                                </Title>
                                <Grid container alignItems="center" direction="row">
                                    <Grid item>
                                        <ImageLoader
                                            src={imageGet}
                                            pallet={pallet}
                                            canEdit={true}
                                            onLoad={onImageSelect}
                                        />
                                    </Grid>
                                    <Grid item style={{
                                        marginLeft: `${isMobile ? "0px" : "40px"}`,
                                        marginTop: `${isMobile ? "10px" : "0px"}`
                                    }}>
                                        <Grid container>
                                            <Grid item style={{
                                                fontSize: `${pallet.general.size12}`,
                                                fontWeight: pallet.general.weight_600,
                                                color: `${pallet.textColorSecondary}`
                                            }}>
                                                Tamanho máximo:
                                            </Grid>
                                            <Grid item style={{
                                                fontSize: `${pallet.general.size12}`,
                                                fontWeight: pallet.general.weight_100,
                                                color: `${pallet.textColorSecondary}`,
                                                marginLeft: "3px"
                                            }}>
                                                2MB
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item style={{
                                                fontSize: `${pallet.general.size12}`,
                                                fontWeight: pallet.general.weight_600,
                                                color: `${pallet.textColorSecondary}`
                                            }}>
                                                Formatos Suportados:
                                            </Grid>
                                            <Grid item style={{
                                                fontSize: `${pallet.general.size12}`,
                                                fontWeight: pallet.general.weight_100,
                                                color: `${pallet.textColorSecondary}`,
                                                marginLeft: "3px"
                                            }}>
                                                JPG, PNG
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box component="form" style={{ marginTop: "30px" }}>
                                    <Title pallet={pallet}>
                                        DADOS PESSOAIS
                                    </Title>
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} xs={12} >
                                            <InputTextField
                                                type={"text"}
                                                value={nome}
                                                name="nome"
                                                onChange={handleNameChange}
                                                id={"nome-parlamentar"}
                                                label={"NOME"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='nome' errors={error} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} xs={12} >
                                            <InputTextField
                                                type={"text"}
                                                name="apelido"
                                                value={apelido}
                                                onChange={handleApelidoChange}
                                                id={"apelido-parlamentar"}
                                                label={"APELIDO"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='apelido' errors={error} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <InputDatePicker

                                                onChange={handleDataNascimentoChange}
                                                label={"DATA DE NASCIMENTO"}
                                                inputFormat={"dd/MM/yyyy"}
                                                value={dataNascimento}
                                                id={"dataNascimento-parlamentar"}
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <SelectTextFieldA
                                                value={ufNascimento}
                                                onChange={handleUfNascimentoChange}
                                                options={arrayUfNascimento}
                                                label={"ESTADO DE NASCIMENTO"}
                                                id={"ufNasc-parlamentar"}
                                            />
                                        </Grid>
                                        {ufNascimento ?
                                            <Grid item lg={4} xs={12}>
                                                <SelectTextFieldA
                                                    value={municipioNascimento}
                                                    onChange={handleMunicipioNascimentoChange}
                                                    options={arrayMunicipioNascimento}
                                                    label={"MUNICÍPIO DE NASCIMENTO"}
                                                    id={"municipioNasc-parlamentar"}
                                                />
                                            </Grid>
                                            :
                                            null}
                                        <Grid item lg={4} xs={12}>
                                            <SelectTextFieldA
                                                value={escolaridade}
                                                onChange={handleEscolaridadeChange}
                                                options={escolaridadeItens}
                                                label={"ESCOLARIDADE"}
                                                id={"escolaridade-parlamentar"}
                                            />
                                        </Grid>
                                        <Grid xl={2} lg={2} xs={12} container style={{
                                            paddingLeft: `${isMobile ? "25px" : "30px"}`
                                        }}
                                            justifyContent="flex-start"
                                            alignItems="center">
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox
                                                    onChange={handleFalecidoChange}
                                                    checked={falecido}
                                                />} label={<LabelFalecido />} />
                                            </FormGroup>
                                        </Grid>
                                        {falecido === true ?
                                            <Grid item lg={4} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <InputDatePicker
                                                        onChange={handleDataFalecimentoChange}
                                                        label={"DATA DE FALECIMENTO"}
                                                        inputFormat={"dd/MM/yyyy"}
                                                        value={dataFalecimento}
                                                        id={"dataFalecimento-parlamentar"}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            :
                                            null
                                        }
                                        <Grid item lg={12} xs={12}>
                                            <InputTextFieldTags
                                                onDelete={(id) => deleteTagProfissao(id)}
                                                onChange={(e) => setInputProfissao(e.target.value)}
                                                arrayItems={arrayProfissao}
                                                onKeyUp={(e) => { if (e.which === 13) { handleAddProfissao(e) } }}
                                                onBlur={(e) => handleAddProfissao(e)}
                                                label={"PROFISSÕES"}
                                                type={'text'}
                                                placeholder={'Ex: Engenheiro Civil'}
                                            />
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <InputTextFieldTags
                                                name={"email"}
                                                onDelete={(id) => deleteTagEmail(id)}
                                                onChange={(e) => setInputEmails(e.target.value)}
                                                arrayItems={arrayEmails}
                                                onKeyUp={(e) => { if (e.which === 13) { handleAddEmails(e) } }}
                                                onBlur={(e) => handleAddEmails(e)}
                                                label={"EMAILS"}
                                                type={'email'}
                                                placeholder={'Ex: nome@provedor.com.br'}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='email' errors={error} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <InputTextFieldTags
                                                onDelete={(id) => deleteTagTelefone(id)}
                                                onChange={(e) => setInputTelefone(e.target.value)}
                                                arrayItems={arrayTelefone}
                                                onKeyUp={(e) => { if (e.which === 13) { handleAddTelefone(e) } }}
                                                onBlur={(e) => handleAddTelefone(e)}
                                                label={"TELEFONES"}
                                                type={'number'}
                                                placeholder={'Ex: 11988888888 (Somente número)'}
                                            />
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <InputTextField
                                                type={"text"}
                                                value={endereco}
                                                onChange={handleEnderecoChange}
                                                id={"endereco-parlamentar"}
                                                label={"ENDEREÇO"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box component="form" style={{ marginTop: "30px" }}>
                                    <Title pallet={pallet}>
                                        ATUAÇÃO
                                    </Title>
                                    <Grid container spacing={3}>
                                        {idInterlocutorEdit && idInterlocutorEdit !== undefined ?

                                            <Grid item lg={4} xs={12}>
                                                <SelectTextFieldA
                                                    value={tipoParlamentar
                                                        // idInterlocutorEdit && idInterlocutorEdit !== undefined ? casaUpdate : casaFederal
                                                    }
                                                    onChange={handleTipoInterlocutorChange}
                                                    options={tipoParlamentarItens}
                                                    label={"TIPO"}
                                                    id={"tipo-parlamentar"}
                                                    disabled={true}
                                                />
                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                    <ErrorMessage name='tipo' errors={error} />
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid item lg={4} xs={12}>
                                                <SelectTextFieldA
                                                    value={tipoParlamentar
                                                        // idInterlocutorEdit && idInterlocutorEdit !== undefined ? casaUpdate : casaFederal
                                                    }
                                                    onChange={handleTipoInterlocutorChange}
                                                    options={tipoParlamentarItens}
                                                    label={"TIPO"}
                                                    id={"tipo-parlamentar"}
                                                />
                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                    <ErrorMessage name='tipo' errors={error} />
                                                </Grid>
                                            </Grid>
                                        }

                                        {tipoParlamentar === 'politico' ?
                                            <Grid container item spacing={3} >
                                                <Grid item lg={4} xs={12}>
                                                    <SelectTextFieldA
                                                        name={"esfera"}
                                                        value={esfera}
                                                        onChange={handleEsferaChange}
                                                        options={ListaEsfera}
                                                        label={"ESFERA"}
                                                        id={"esfera-parlamentar"}
                                                    />
                                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                        <ErrorMessage name='esfera' errors={error} />
                                                    </Grid>
                                                </Grid>
                                                {esfera === "F" ?
                                                    <Grid item lg={4} xs={12}>
                                                        <SelectTextFieldA
                                                            value={idInterlocutorEdit && idInterlocutorEdit !== undefined ? casaUpdate : casaFederal}
                                                            onChange={handleCasaChange}
                                                            options={casaFederalItens}
                                                            label={"CASA"}
                                                            id={"casa-parlamentar"}
                                                        />
                                                    </Grid>
                                                    :
                                                    null
                                                }
                                                {esfera === "M" ?
                                                    <Grid item lg={4} xs={12}>
                                                        <SelectTextFieldA
                                                            value={ufAtuacao}
                                                            onChange={handleUfAtuacaoChange}
                                                            options={arrayUfAtuacao}
                                                            label={"ESTADO DE ATUAÇÂO"}
                                                            id={"ufAtuacao-parlamentar"}
                                                        />
                                                    </Grid> : null}
                                                {
                                                    esfera === "E" ?
                                                        <Grid item lg={4} xs={12}>
                                                            <SelectTextFieldA
                                                                name={"casaEstadual"}
                                                                value={casaEstadual}
                                                                onChange={handleCasaEstadosOnChange}
                                                                options={casaEstadoItens}
                                                                label={"CASA"}
                                                                id={"casaEstadual"}
                                                            // focused={true}
                                                            // placeholder={"Selecione"}
                                                            />
                                                        </Grid>
                                                        : null
                                                }
                                                {esfera === "M" && ufAtuacao ? (
                                                    <Grid item lg={4} xs={12}>
                                                        <SelectTextFieldA
                                                            name={"casaMunicipios"}
                                                            value={idInterlocutorEdit && idInterlocutorEdit !== undefined ? casaUpdate : casaMunicipal}
                                                            onChange={handleCasaMunicipiosOnChange}
                                                            options={casaMunicipiosItens}
                                                            label={"CASA"}
                                                            id={"casaMunicipios"}
                                                        // focused={true}
                                                        // placeholder={"Selecione"}
                                                        />
                                                    </Grid>

                                                ) : null}

                                                <Grid item lg={4} xs={12}>
                                                    <SelectTextFieldA
                                                        value={poder}
                                                        onChange={handlePoderChange}
                                                        options={esfera === "M" ? poderMunicipalItens : casaFederal === "1" || casaFederal === "2" ? poderSenadoCamaraItens : poderItens}
                                                        label={"PODER"}
                                                        id={"poder-parlamentar"}
                                                    />
                                                </Grid>
                                                {poder === "" ? null :
                                                    <Grid item lg={4} xs={12}>
                                                        <SelectTextFieldA
                                                            value={cargo}
                                                            onChange={handleCargoChange}
                                                            options={
                                                                esfera === "M" && poder === "L" ?
                                                                    legislativoMunicipal : esfera === "M" && poder === "E" ?
                                                                        executivoMunicipal : esfera === "E" && poder === "L" ?
                                                                            legislativoEstadual : esfera === "E" && poder === "E" ?
                                                                                executivoEstadual : esfera === "E" && poder === "J" ?
                                                                                    juduciario : esfera === "F" && poder === "L" ?
                                                                                        legislativo : esfera === "F" && poder === "E" ? executivo : juduciario
                                                            }
                                                            label={"CARGO"}
                                                            id={"cargo-parlamentar"}
                                                        />
                                                    </Grid>
                                                }
                                                {esfera === "F" ?
                                                    <Grid item lg={4} xs={12}>
                                                        <InputTextField
                                                            type={"number"}
                                                            value={legislatura}
                                                            onChange={handleLegislaturaChange}
                                                            label={"LEGISLATURA"}
                                                            id={"legislatura-parlamentar"}
                                                        />
                                                    </Grid>
                                                    :
                                                    null
                                                }

                                                <Grid item lg={4} xs={12}>
                                                    <SelectTextFieldA
                                                        value={partido}
                                                        onChange={handlePartidoChange}
                                                        options={arrayPartido}
                                                        label={"PARTIDO"}
                                                        id={"Partido-parlamentar"}
                                                    />
                                                </Grid>

                                                <Grid item lg={4} xs={12}>
                                                    <SelectTextFieldA
                                                        value={situacao}
                                                        onChange={handleSituacaoChange}
                                                        options={situacaoItens}
                                                        label={"SITUAÇÃO"}
                                                        id={"situacao-parlamentar"}
                                                    />
                                                </Grid>
                                                <Grid item lg={12} xs={12}>
                                                    <InputTextField
                                                        type={"url"}
                                                        name={"url"}
                                                        value={uri}
                                                        onChange={handleUriChange}
                                                        id={"urlBiografia-parlamentar"}
                                                        label={"URL BIOGRAFIA COMPLETA"}
                                                    />
                                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                        <ErrorMessage name='url' errors={error} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>



                                            :



                                            tipoParlamentar === 'procom' ?

                                                <Grid container item spacing={3} >
                                                    <Grid item lg={4} xs={12}>
                                                        <SelectTextFieldA
                                                            name={"esferaProcom"}
                                                            value={esferaProcom}
                                                            onChange={handleEsferaProcomChange}
                                                            options={ListaEsferaProcom}
                                                            label={"ESFERA"}
                                                            id={"esfera-tipo-parlamentar"}
                                                        />
                                                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                            <ErrorMessage name='esfera' errors={error} />
                                                        </Grid>
                                                    </Grid>
                                                    {esferaProcom === "A" ?
                                                        <Grid item lg={4} xs={12}>
                                                            <SelectTextFieldA
                                                                value={ufAtuacaoProcom}
                                                                onChange={handleUfAtuacaoProcomChange}
                                                                options={arrayUfAtuacaoProcom}
                                                                label={"ESTADO DE ATUAÇÂO"}
                                                                id={"ufAtuacao-parlamentar"}
                                                            />
                                                        </Grid>
                                                        :
                                                        null
                                                    }
                                                    {esferaProcom === "E" ?
                                                        <Grid item lg={4} xs={12}>
                                                            <SelectTextFieldA
                                                                value={ufAtuacaoProcom}
                                                                onChange={handleUfAtuacaoProcomChange}
                                                                options={arrayUfAtuacaoProcom}
                                                                label={"ESTADO DE ATUAÇÂO"}
                                                                id={"ufAtuacao-parlamentar"}
                                                            />
                                                        </Grid>
                                                        :
                                                        null
                                                    }
                                                    {esferaProcom === "M" ?
                                                        <Grid item lg={4} xs={12}>
                                                            <SelectTextFieldA
                                                                value={ufAtuacaoProcom}
                                                                onChange={handleUfAtuacaoProcomChange}
                                                                options={arrayUfAtuacaoProcom}
                                                                label={"ESTADO DE ATUAÇÂO"}
                                                                id={"ufAtuacao-parlamentar"}
                                                            />
                                                        </Grid> : null}

                                                    {esferaProcom === "M" && ufAtuacaoProcom ?
                                                        <Grid item lg={4} xs={12}>
                                                            <SelectTextFieldA
                                                                value={municipioProcom}
                                                                onChange={handleMunicipioProcomChange}
                                                                options={arrayMunicipioProcom}
                                                                label={"MUNICÍPIO DE NASCIMENTO"}
                                                                id={"municipioNasc-parlamentar"}
                                                            />
                                                        </Grid>
                                                        :
                                                        null}
                                                </Grid> :
                                                null
                                        }
                                    </Grid>
                                </Box>
                                <Box component="form" style={{ marginTop: "30px" }}>
                                    <Title pallet={pallet}>
                                        MENU DO INTERLOCUTOR
                                    </Title>
                                    <Grid container spacing={3}>
                                        <Grid item lg={4} xs={12}>
                                            <SelectTextFieldA
                                                value={qualificacao}
                                                onChange={handleQualificacaoChange}
                                                options={parlamentarQualificacoes}
                                                label={"QUALIFICAÇÃO"}
                                                id={"qualificacao-parlamentar"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='qualificacao' errors={error} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <InputMultilineTextField
                                                type={"text"}
                                                maxRows={4}
                                                value={observacoes}
                                                onChange={handleObservacoesChange}
                                                id={"observacao-parlamentar"}
                                                label="OBSERVAÇÕES"
                                            />
                                        </Grid>

                                    </Grid>
                                </Box>
                                <Box component="form" style={{ marginTop: "30px" }}>
                                    <Title pallet={pallet}>
                                        REDES SOCIAIS
                                    </Title>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <InputTextField
                                                type={"url"}
                                                value={facebookUrl}
                                                onChange={handleFacebookChange}
                                                id={"facebook-parlamentar"}
                                                label={"FACEBOOK"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='facebook' errors={error} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <InputTextField
                                                type={"url"}
                                                value={instagramUrl}
                                                onChange={handleInstagramChange}
                                                id={"instagram-parlamentar"}
                                                label={"INSTAGRAM"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='instagram' errors={error} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <InputTextField
                                                type={"url"}
                                                value={twitter}
                                                onChange={handleTwitterChange}
                                                id={"twitter-parlamentar"}
                                                label={"TWITTER"}
                                            />
                                            {/* <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                {twitterAlert && twitterAlert !== null && twitterAlert !== ''
                                                    ?
                                                    <ErrorMessage name='twitter' errors={[{ name: 'twitter', err: twitterAlert }]} />
                                                    :
                                                    <ErrorMessage name='twitter' errors={error} />
                                                }
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid style={{ marginTop: "50px" }}>
                                    <Title pallet={pallet}>
                                        CONFIGURAÇÕES
                                    </Title>
                                </Grid>
                                <Grid>
                                    <FormGroup style={{ marginTop: "-15px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={handleSeguirChange}
                                                    checked={seguir}
                                                />
                                            }
                                            label={
                                                <LabelSeguir errors={error} />
                                            }
                                        />
                                        <FormControlLabel style={{ marginTop: "-15px" }} control={<Checkbox
                                            onChange={handleAtivarNotificacoesChange}
                                            checked={ativarNotificacoes}
                                        />} label={<LabelNotificacoes />} />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="end" style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                                <Grid item style={{ marginRight: "20px" }}>
                                    <ButtonDialogConfirmation
                                        styles={{
                                            borderRadius: "6px 6px 0px 0px",
                                            // marginRight: "20px",
                                            textTransform: "capitalize",
                                            color: `${pallet.general.color1}`,
                                            lineHeight: "18px",
                                            fontSize: `${pallet.general.size14}`,

                                        }}
                                        title={"CONFIRMAÇÃO"}
                                        title_button={"Cancelar"}
                                        confirmation_button={"Cancelar"}
                                        content={"CANCELAR CADASTRO?"}
                                        message={"Esta ação não poderá ser desfeita."}
                                    />
                                </Grid>
                                <Grid item>
                                    <SolidButton
                                        onClick={pacoteSalvar}
                                        disabled={buttonFree}
                                        color={`${pallet.textColorPrimary}`}
                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                        fontSize={`${pallet.general.size14}`}
                                        title={"SALVAR"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Main>
        </div >
    );
}