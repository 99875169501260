import { locale } from "core-js";
import React, { useEffect, useState } from "react";


function Relogio() {
    const [clockState, setClockState] = useState(null)

    useEffect(() => {
        setInterval(() => {
            const d = new Date();
            setClockState(d.toLocaleTimeString("pt-BR",{timeStyle: 'short',timeZone:'America/Sao_Paulo'},));
        
      },1000);
    },[]);

    return <div>{clockState}</div>

}

export default Relogio



  