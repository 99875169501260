import * as React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


const Accordion = styled(MuiAccordion)`
    border: 1px solid rgba(0, 0, 0, 0.12);
    &:not(:last-child) {
        border-bottom: 0;
    }
    &:before {
        display: none;
    }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
    /* flex-direction: row-reverse; */
    .MuiAccordionSummary-content p {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: #1E0736;
    }
    .MuiAccordionSummary-expandIconWrapper {
        color: #1E0736;
        .Mui-expanded {
            transform: rotate(90deg);
        }
    }
    .MuiAccordionSummary-content {
        margin-left: 5px;
    }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
    background-color: #F9F9F9;
    padding: 5px;
    border-top: 1px solid rgba(0, 0, 0, .125);
`;


const FilterItemPanelUsuario = (props: { children: React.ReactNode, name: string, label: string, panel: string, expanded?: boolean, onOpen?:any; }) => {
   
    return (
        <Accordion expanded={props.expanded ===  true} onChange={props.onOpen} disableGutters elevation={0} square>
            <AccordionSummary aria-controls={`${props.name}d-content`} id={`${props.name}d-header`}
                expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: '1.9rem' }} />}>
                <Typography>{props.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container style={{ paddingLeft: '10px' }}>
                    {props.children}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default FilterItemPanelUsuario;