import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import ConteudoSexta from "./ConteudoSexta";

const AgendaCarouselTabsSexta = (props: { activeTab: any, index: number,  dateStartReference: Date, eventListTodayMaisQuatro: Array<any>, loadEventos: () => void, loading: boolean }) => {

    return (
        <Grid style={{ borderRadius: "6px"}}>
            <TabPanel value={props.activeTab} index={props.index}>
                <ConteudoSexta index={props.index} activeTab={props.activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisQuatro={props?.eventListTodayMaisQuatro} loadEventos={props?.loadEventos} loading={props?.loading} />
            </TabPanel>
        </Grid>
    )
}

export default AgendaCarouselTabsSexta;