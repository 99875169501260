import * as Yup from "yup";

const ProposicaoCadastroSchema = Yup.object({

    id_casa: Yup.string().required({ name: "casa", err: "'Casa Legistlativa' é um campo obrigatório"}).min(1,{name: "uf", err: "É necessário preencher 'Unidade da Federação' e 'Casa Legistlativa' "} ) ,
    tipo: Yup.string().required({ name: "tipo", err: "'Tipo de documento' é um campo obrigatório" }),
    numero: Yup.number().required({ name: "numero", err: "'Número' é um campo obrigatório" }),
    data_apresentacao: Yup.date().required({ name: "data", err: "O campo 'Data' é um campo obrigatório" }),
    situacao: Yup.object(
        {
            id: Yup.string().required({ name: "situacao", err: "O campo 'Situação' é um campo obrigatório" }),
            descricao: Yup.string().required(),
        }
    ),

    ementa: Yup.string().required({ name: "ementa", err: "O campo 'Ementa' é um campo obrigatório" }),
    uri: Yup.string().url({ name: "link-proposta", err: "URL inválida, é necessário o formato 'https ou http" }).nullable(true).optional(),
    uri_teor: Yup.string().url({ name: "inteiro-teor", err: "URL inválida, é necessário o formato 'https ou http" }).nullable(true).optional(),

    autores: Yup.array().of(
        Yup.object({
            id: Yup.number().nullable(true).optional(),
            id_parlamentar: Yup.number().nullable(true).optional(),
            nome: Yup.string().required(),
        })
    ).required().min(1, { name: "autores", err: "É necessário informar pelo menos um Autor" })
});


export default ProposicaoCadastroSchema;
