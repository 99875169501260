import React, { useCallback, useEffect, useState } from 'react';
import styled from "styled-components";
import Main from '../main/Main';
import useStyles from '../../components/layout/Styles';
import ParlamentarCard from './_components/ParlamentarCard';
import PaginationSaveState from '../../components/PaginationSaveState';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { isMobile } from 'react-device-detect';
import { IListInfo } from '../../models/IListInfo';
import ParlamentarListFilterItens from './_components/ParlamentarListFilterItens';
import ComboBox from '../../components/ComboBox';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '../../utils/utils';
import ParlamentarService from '../../services/ParlamentarService';
import ButtonOutLine from '../../components/ButtonOutline';
import { usePallet } from '../../contexts/PalletContext';
import EmptyError from '../../components/EmptyError';
import FilterQuantity from '../../components/filter/FilterQuantity';
import ListFilter, { IFilterData } from '../../components/filter/ListFilter';
import { emptyStore, writeStore } from '../../helpers/persistence';
import { useAuthorization } from '../../contexts/AuthorizationContext';
import ButtonOutlineReadOnly from '../../components/ButtonOutlineReadOnly';
import CircularLoading from '../../components/CircularLoading';




const FilterArea = styled(Paper)`
    width: 100%;
    /* height: 55px; */
    margin-top: 16px;
    margin-bottom: 10px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffff;
`;



const FilterButton = styled(Button)`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    color:  #160342;
    border: 0;
    font-size: 14px;
    line-height: 17px;
    background: rgba(75, 17, 150, 0.1);
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    height: 100%;
`;

const ContainerHead = styled(Grid)`
    margin-bottom: 10px;
    margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
    margin-bottom: 30px;
`;

const ContainerCombo = styled(Grid)`
  width: 100%;
  margin-bottom: 5px;
`;

const ContainerFilter = styled(Grid)`
  /* width: 10%; */
  width: 100%;
  margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
  justify-content: ${isMobile ? 'center' : 'space-between'};
`;

const ContainerCard = styled(Grid)`
  margin-top: 20px;
`;

const ContainerFooter = styled(Grid)`
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
`;

export default function ParlamentarLista() {
    const { pallet } = usePallet();

    const classes = useStyles();

    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>({ pageIndex: 1, pageSize: 8, pageItens: 0, totalItens: 0, totalPages: 0 });
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);
    const [nome, setNome] = useState<string>();
    const [nomeParcial, setNomeParcial] = useState<string>('');
    const [listaNomes, setListaNomes] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const [openFilter, setOpenFilter] = React.useState<boolean>(false);
    const handleToggleFilter = () => {
        if (qry) {
            setQry(null);
        }
        setOpenFilter(!openFilter);
    }

    const [qry, setQry] = useState(useQuery().get('filtro'));



    const defaultFilter: IFilterData[] = [
        { key: 'esfera', label: 'ESFERA: Federal', value: 'Federal' },
        { key: 'legislatura', label: 'LEGISLATURA: 57', value: '57' },
    ]

    const filterName = 'ParlamentarListCurrentPage';

    const setQueryFilter = () => {
        if (qry) {
            try {
                const filter = JSON.parse(qry);
                setCurrentFilter(() => [...filter])
                writeStore(filterName, filter);
            } catch { }
        }
    }

    useEffect(() => {
        if (qry)
            emptyStore(filterName).then(x => setQueryFilter())
    }, []);

    useEffect(() => {
        loadParlamentarList();
    }, [currentPage, currentPageSize]);

    const changeFiltrarStatus = () => {
        loadParlamentarList();
    };

    useEffect(() => {
        nome && loadParlamentarList();
    }, [nome]);


    useEffect(() => {
        if (nomeParcial && nomeParcial.trim().length > 2) {
            ParlamentarService.GetPartialName(nomeParcial, currentFilter)
                .then(async res => {
                    setListaNomes(res)
                }
                );
        }
        else if (nomeParcial === '') {
            loadParlamentarList();
        }
    }, [nomeParcial]);


    // const loadParlamentarList = useCallback(() => {
    const loadParlamentarList = () => {
        setLoading(true)
        setTimeout(() => {
            if ((!nome || nome.trim() === '') && (!currentFilter || currentFilter.length === 0 || currentPage <= 0)) {
                setLista([]);
                setInfoList({ pageIndex: 1, pageSize: currentPageSize, pageItens: 0, totalItens: 0, totalPages: 0 });
            } else {
                const filter = currentFilter ? currentFilter.map(item => { return { key: item.key, value: item.value } }) : [];
                if (nome && nome.trim() !== '') {
                    filter.push({ key: 'nome', value: nome });
                    setNome(() => undefined)
                }
                ParlamentarService.GetList(filter, currentPage, currentPageSize)
                    .then(async res => {
                        setLoading(false)
                        if (res && res.success) {
                            setLista(res.dataCards);
                            setInfoList(res.listInfo);
                        }
                    });
            }
        }, 2000);
    }

    const handleOnChangeFilter = async (data: IFilterData[]) => {
        if (qry === null) {
            currentFilter.pop()
            setCurrentFilter(data);
        }
    }

    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value))
    }
    const { user } = useAuthorization();

    const apagaAmbitoPorCasa = () => {
        const casaCheck = currentFilter.some(objeto => objeto.hasOwnProperty('key') && objeto.key === 'casa');
        if (!casaCheck) {
            currentFilter.filter(objeto => objeto.key !== 'casa')
        }
    }

    return (
        <div className={classes.root} >
            <Main>
                <>
                    <ContainerHead container >
                        <ContainerSubHead container alignItems="center" justifyContent="space-between">
                            <Grid style={{
                                color: `${pallet.textColorQuaternary}`,
                                fontWeight: pallet.general.weight_bold,
                                fontSize: "24px",
                                fontFamily: `${pallet.general.fontFamily}`
                            }}>
                                Interlocutores
                            </Grid>
                            <Grid>
                                {
                                    user.id_permissao === 3 ?
                                        <ButtonOutlineReadOnly path="Não é possível realizar está ação com este perfil." />
                                        : user.id_permissao === 4 ?
                                            null
                                            :
                                            <ButtonOutLine
                                                path="/interlocutor/cadastro"
                                            />

                                }
                            </Grid>
                        </ContainerSubHead>
                        <Grid container>
                            <Grid style={{ width: '100%' }}>
                                <ComboBox
                                    name='filtroNome'
                                    itens={listaNomes}
                                    onChange={setNomeParcial}
                                    onSelect={setNome}
                                    placeholder="Buscar"
                                />
                            </Grid>
                        </Grid>
                        <Grid style={{
                            width: isMobile ? '60%' : '100%',
                        }} />
                        <ContainerFilter>
                            <FilterArea >
                                <Grid style={{ width: isMobile ? "70%" : "90%" }}>
                                    <FilterQuantity list={infoList} currentPageSize={currentPageSize} onChange={handleOnChangeItensPerPageSize} />
                                </Grid>
                                <Grid style={{ width: isMobile ? "30%" : "9%", display: "flex" }}>
                                    <FilterButton
                                        startIcon={<FontAwesomeIcon icon={faSlidersH} />}
                                        onClick={handleToggleFilter}
                                    >
                                        Filtrar
                                        {/* <FilterButtonLabel>Filtro</FilterButtonLabel> */}
                                    </FilterButton>
                                </Grid>
                                <ListFilter
                                    ocultarBotaoSalvar={true}
                                    apagaAmbitoPorCasa={apagaAmbitoPorCasa}
                                    filtrarAction={changeFiltrarStatus}
                                    name={'ParlamentarListCurrentFilter'}
                                    filter={currentFilter}
                                    defaultFilter={defaultFilter}
                                    open={openFilter}
                                    onToogle={handleToggleFilter}
                                    onChangeFilter={handleOnChangeFilter}
                                >
                                    <ParlamentarListFilterItens
                                        filter={currentFilter}
                                        onChangeFilter={handleOnChangeFilter}
                                        idPermissao={user.id_permissao}
                                    />

                                </ListFilter>
                            </FilterArea>
                        </ContainerFilter>

                        {/* </Grid> */}
                    </ContainerHead>
                    <ContainerBody container>
                        {loading
                            ?
                            <CircularLoading
                                size={80}
                                top={"50%"}
                                left={"50%"}
                                zIndex={2}

                            />
                            :
                            <>
                                {lista && lista.map(item => (
                                    <ContainerCard key={item.id}>
                                        <ParlamentarCard
                                            key={item.id}
                                            onClick={loadParlamentarList}
                                            item={item}
                                        />
                                    </ContainerCard>
                                ))}
                                {!lista.length && (
                                    <Paper
                                        style={{
                                            color: `${pallet.textColorQuaternary}`,
                                            fontSize: `${pallet.fontSize.titleWidgets}`,
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: 600,
                                            paddingTop: "10%",
                                            paddingBottom: "10%",
                                            width: "100%",
                                            textAlign: "center",
                                            backgroundColor: "#ffff",
                                        }}
                                    >
                                        <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" />
                                    </Paper>
                                )}
                            </>
                        }
                    </ContainerBody>
                    <ContainerFooter container>
                        <PaginationSaveState
                            name={filterName}
                            pages={infoList?.totalPages}
                            page={currentPage}
                            onChange={setCurrentPage}
                        />
                    </ContainerFooter>
                </>
            </Main>
        </div >
    );
}

