import PrivateRoute from '../../components/PrivateRoute';
import Favoritos from './Favoritos';


export function FavoritosRoutes(): JSX.Element {   
    return (
        <>             
            <PrivateRoute path="/favoritos" element={<Favoritos />} />         
        </>
    );
};
