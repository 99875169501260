import * as Yup from "yup";

const ProposicaoAcaoInfluenciaCreateSchema = Yup.object({
    casa: Yup.number().required({ name: "casa", err: "Campo com preenchimento obrigatório" }),
    // ambito: Yup.number().when("casa", {
    //     is: (val) => val !== null,
    //     then: Yup.number().required({ name: "ambito", err: "Campo com preenchimento ambito" })
    // }),
    ambito: Yup.number(),
    descricao: Yup.string().required({ name: "descricao", err: "Campo com preenchimento obrigatório" }),
    influenciaSobre: Yup.array().of(Yup.number()).required().min(1, { name: "influenciaSobre", err: "Campo com preenchimento obrigatório" }),
    prazo: Yup.date().nullable(true).optional(),
    propDescricao: Yup.string(),
    responsavelId: Yup.number().required({ name: "responsavel", err: "Campo com preenchimento obrigatório" }),
    resultado: Yup.number().required({ name: "resultado", err: "Campo com preenchimento obrigatório" }),
});


export default ProposicaoAcaoInfluenciaCreateSchema;