import { Button, Checkbox, Grid, Paper, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import SearchTextField from "../../../components/SearchTextField";
import useStyles from "../../../components/layout/Styles";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import SolidButton from "../../../components/SolidButton";
import { useAlert } from '../../../contexts/AlertContext';
import { useNavigate } from "react-router-dom";
import { usePallet } from "../../../contexts/PalletContext";
import IAService from "../../../services/IAService";
import TabelasService from "../../../services/TabelasService";
import Main from "../../main/Main";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

//## StyledCompenents
const ContainerHead = styled(Grid)`
	margin-bottom: 10px;
	margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
	margin-bottom: 30px;
`;

const PageTitle = styled(Grid)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-weight: ${(props) => props.pallet.general.weight_bold};
	font-size: 24px;
	font-family: ${(props) => props.pallet.general.fontFamily};
`;


const ContainerBody = styled(Grid)`
	justify-content: ${isMobile ? "center" : "space-between"};
    flex-direction: column;
`;


const PaperContent = styled(Paper)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding: 4% 5% 5% 5%;
	width: 100%;
	text-align: center;
	background-color: #ffff;

	#combo-box-tema-id {
			background-color: #F2F2F2;
			color: ${(props) => props.pallet.general.color1};
    	-webkit-text-fill-color: ${(props) => props.pallet.general.color1};
			font-weight: bold;
			font-size: 14px;
	}
`;

const PaperSelectBox = styled(Paper)`
	text-align: left;
	padding: 0 20px 20px;

	.customScroll { 
		padding-right: 10px;
        
        &::-webkit-scrollbar { // width
            width: 20px;
        }
        &::-webkit-scrollbar-track { // Track
            background: #F2F2F2;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb { // Handle
            background: #4B1196; 
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover { // Handle on hover
            background: #4B1196; 
        }

				label {
					display: block;
					svg {
						display: none;
					}
					span {
					color: #1E0736;
					font-weight: 600;
					font-size: 12px;
				}}

				.MuiFormControlLabel-labelPlacementEnd {
					padding: 5px 0;

					&:hover {
						color: #4B1196;
						background-color: #E0CDF8;
						display: flex;

						&::after {
							content: "";
							
							border: solid #4B1196;
							border-width: 0 3px 3px 0;
							padding: 3px;
							transform: rotate(-45deg);
							-webkit-transform: rotate(-45deg);
							margin-left: auto;
							margin-right: 10px;
						}
					}
				}
    }
`;

const SelectedTema = styled(Grid)`
    align-items: center;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    margin: 10px 0;
		padding: 0 0 0 15px;

    div.not-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        /* width: 89%; */
        flex-direction: row;
        color: #1E0736;
        font-size: 12px;
				flex-grow: 1;
        
        strong {
          font-size: 17px;
          margin: 0 20px;
        }
        p {
          font-size: 17px;
          font-weight: bold;
          color: #4B1196;
          margin: 0 10px 0 0;
        }
    }
    div.not-header-icon {
        /* background-color: #F2F2F2; */
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 0px 0 4px;

        svg {
            color: #9B9B9B;
        }
    }
  `;

export default function IATemasRelacionadosConsulta() {
	const { pallet } = usePallet();
	const classes = useStyles();
	const navigate = useNavigate();
	const { NewAlert } = useAlert();

	const { idTema } = useParams();

	const [listaTemas, setListaTemas] = useState([]);
	const [listaTemasBusca, setListaTemasBusca] = useState([]);
	const [id_tema, setId_Tema] = React.useState<number>(Number(idTema));
	const [listaTemasRelacionados, setListaTemasRelacionados] = useState([]);
	const [temasRelacionadosArray, setTemasRelacionadosArray] = useState<Array<number>>([]);


	useEffect(() => {
		loadTemasList()
	}, [id_tema]);
	

	const loadTemasList = () => {
		TabelasService.getProposicaoTemasLegislativos() // force break
			.then(async (res) => {
				if (res) {
					const data = []
					res.map(item => data.push({
						key: item.id,
						label: item.descricao + (item.casa === 1 ? ' [Senado Federal]' : (item.casa === 2 ? ' [Câmara dos Deputados]' : '')),
						value: item.id
					}))
					setListaTemas(() => [...data]);
					setListaTemasBusca(() => [...data]);
				}
			});
		if (id_tema) {
			IAService.getTemasRelacionados(id_tema)
				.then(async (res) => {
					if (res) {
						const data = []
						res.map(item => data.push({ key: item.id_tema_relacionado, label: item.relacionado_descricao, value: item.id_tema_relacionado }))
						setListaTemasRelacionados(() => [...data]);
						setTemasRelacionadosArray(res.map(x => { return x.id_tema_relacionado }));
					}
				});
		}
	};


	const handleTemaOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setId_Tema(Number(event.target.value));
	};

	const handleTemaOnClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = JSON.parse(event.target.value);
		setTemasRelacionadosArray([...temasRelacionadosArray, Number(newValue.value)]);
		setListaTemasRelacionados([...listaTemasRelacionados, newValue]);
	};

	const handleTemaRemove = (value: string) => {
		setTemasRelacionadosArray(temasRelacionadosArray.filter(x => x !== Number(value)));
		setListaTemasRelacionados(listaTemasRelacionados.filter(x => x.value !== Number(value)));
	};

	const handleSearchOnChange = (value: string) => {
		if (value) setListaTemasBusca(listaTemas.filter(x => x.label.toLowerCase().indexOf(value.toLowerCase().trim()) >= 0))
		else setListaTemasBusca(listaTemas)
	}

	const saveRelationship = () => {
		console.log('temasRelacionadosArray: ', temasRelacionadosArray)
		console.log('id_tema: ', id_tema);
		IAService.setTemasRelacionados(id_tema, temasRelacionadosArray)
			.then(res => {
				if (res && res > 0) {
					NewAlert('success', 'Temas relacionados com sucesso.')
					navigate(`/ia/`);

				} else {
					NewAlert('error', 'Erro ao relacionar temas, verifique se esta preenchido corretamente');
				}
			});
	}

	return (
		<div className={classes.root}>
			<Main>
				<>
					<ContainerHead container>
						<ContainerSubHead container alignItems="center" justifyContent="space-between">
							<PageTitle pallet={pallet}>Relacionar Temas</PageTitle>
						</ContainerSubHead>
					</ContainerHead>
					<ContainerBody container>
						<Grid item style={{ width: "100%" }}>
							<PaperContent pallet={pallet} >
								<Grid item style={{ textAlign: "left" }}>
									<h3 style={{ fontSize: "14px" }}>TEMA A RELACIONAR</h3>
									<SelectTextFieldA
										name={"combo-box-tema-id"}
										value={id_tema}
										onChange={handleTemaOnChange}
										options={listaTemas}
										label={""}
										id={"combo-box-tema-id"}
										focused={true}
										placeholder={"Selecione"}
										disabled={idTema ? true : false}

									/>
								</Grid>
								<Grid container>
									<PaperSelectBox style={{ width: "50%" }}>
										<h3 style={{ fontSize: "14px" }}>TEMAS DISPONÍVEIS</h3>
										<SearchTextField
											onChange={(value) => handleSearchOnChange(value)}
											placeholder="Buscar"
										/>
										<div className="customScroll" style={{ maxHeight: "300px", overflow: "auto", marginTop: "20px" }}>
											{listaTemasBusca &&
												listaTemasBusca.filter(item => temasRelacionadosArray.indexOf(item.value) == -1).map((item) => (
													<>
														{id_tema !== item.value && (
															<FormControlLabel
																control={
																	<Checkbox onChange={handleTemaOnClick} value={JSON.stringify(item)} />
																}
																label={item.label}
															/>
														)}
													</>
												))}
										</div>
									</PaperSelectBox>
									<PaperSelectBox style={{ width: "50%" }}>
										<h3 style={{ fontSize: "14px" }}>TEMAS SELECIONADOS</h3>
										<div className="customScroll" style={{ maxHeight: "370px", overflow: "auto", marginTop: "20px" }}>
											{listaTemasRelacionados &&
												listaTemasRelacionados.map((item) => (
													<SelectedTema container>
														<div className="not-header">
															{item.label}
														</div>
														<div className="not-header-icon">
															<Button
																key={item.value}
																value={item.value}
																onClick={() => handleTemaRemove(item.value)}
															>
																<CloseIcon />
															</Button>
														</div>
													</SelectedTema>
												))}
										</div>
									</PaperSelectBox>
								</Grid>
								<Grid container alignItems="center" justifyContent="flex-end" style={{ marginTop: "20px" }}>
									<ButtonDialogConfirmation
										styles={{
											borderRadius: "6px 6px 0px 0px",
											marginRight: "20px",
											textTransform: "capitalize",
											color: `${pallet.general.color1}`,
											lineHeight: "18px",
											fontSize: `${pallet.general.size14}`,

										}}
										title={"CONFIRMAÇÃO"}
										title_button={"Cancelar"}
										confirmation_button={"Cancelar"}
										content={"CANCELAR CADASTRO?"}
										message={"Esta ação não poderá ser desfeita."}
										lastPath={"/ia"}
									/>
									<SolidButton
										onClick={saveRelationship}
										// onChange={() => { }}
										color={`${pallet.textColorPrimary}`}
										backgroundColor={`${pallet.backgroundColorPrimary}`}
										fontSize={`${pallet.general.size14}`}
										title={"SALVAR"}
									/>
								</Grid>
							</PaperContent>
						</Grid>
					</ContainerBody>
				</>
			</Main >
		</div >
	);
}
