import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as EmptyState } from '../../../img/emptyCorrelata.svg';
import { Button, fabClasses } from '@mui/material';
import { ArrowForwardIos } from '@material-ui/icons';

import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';


import { useAlert } from '../../../contexts/AlertContext';
import { usePallet } from '../../../contexts/PalletContext';
import CircularLoading from '../../../components/CircularLoading';
import ProposicaoService from '../../../services/ProposicaoService';

function EmptyErrorTemaCorporativoList(props: { title: string, description?: string, tipotc?: string, onChange?: () => void }) {
    const { pallet } = usePallet();

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { NewAlert } = useAlert();

    const gerarCorrelatas = () => {

        setLoading(true);
        // ProposicaoService.GerarCorrelata(props.id_proposicao).then(res => {
        //     setLoading(false);
        //     if (res > 0) {
        //         //setLoading(false);
        //         props?.onChange()
        //     } else {
        //         NewAlert('error', 'Para essa proposição não existem informações suficientes para gerar correlatas !');
        //     }
        //     //setLoading(false);
        // }
        // )

        //setLoading(false);
    }

    return (
        <>
            {
                loading === true

                    ?

                    <CircularLoading
                        size={80}
                        top={"90%"}
                        left={"50%"}
                        zIndex={2}

                    />

                    :
                    <Grid>

                        <EmptyState />
                        <h2>{props.title ? props.title : "Os itens cadastrados aparecerão aqui"}</h2>
                        <Grid>{props.description ? props.description : "Cadastre aqui"}</Grid>



                        <Grid container xs={12} justifyContent="center" style={{ marginTop: "20px" }}>
                            {/* <Button
                                onClick={() => { navigate(`/ia`) }}
                                variant="contained"
                                size="large"
                                style={{
                                    textTransform: 'uppercase',
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.general.size12}`,
                                    fontWeight: pallet.general.weight_300,
                                    border: "none",
                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                    color: `${pallet.textColorPrimary}`
                                }}
                                endIcon={<ArrowForwardIos style={{
                                    color: `${pallet.textColorPrimary}`,
                                    width: "10px"
                                }}

                                />}
                            >
                                TEMAS RELACIONADOS
                            </Button> 
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            */}
                            <Button
                                onClick={props?.tipotc === "G" ? () => navigate('/classificacaodetemas/cadastrar/G') : props?.tipotc === "T" ? () => navigate('/classificacaodetemas/cadastrar/T') : props?.tipotc === "TP" ? () => navigate('/classificacaodetemas/cadastrar/TP') : () => navigate('/classificacaodetemas/cadastrar/S')}
                                variant="contained"
                                size="large"
                                style={{
                                    textTransform: 'uppercase',
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.general.size12}`,
                                    fontWeight: pallet.general.weight_300,
                                    border: "none",
                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                    color: `${pallet.textColorPrimary}`
                                }}
                            // startIcon={<RefreshIcon style={{
                            //     color: `${pallet.textColorPrimary}`
                            // }}
                            // />}
                            >
                                CADASTRAR
                            </Button>
                        </Grid>

                    </Grid>
            }

        </>
    );

}

export default EmptyErrorTemaCorporativoList;
