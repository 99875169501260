import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { usePallet } from "../../../../contexts/PalletContext";
import useStyles from "../../../../components/layout/Styles";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import Avatar from "@mui/material/Avatar";
import { IProposicao } from "../../../../models/Proposicao.Model";
import styled from 'styled-components';
import imagemPP from '../../../../img/pp.jpg'

export const ProposicaoConsultaAutores = (props: {
  proposicao: IProposicao;
  permissaoUsuario?: number
}) => {
  const { pallet } = usePallet();
  const classes = useStyles();


  const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 10px;
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 11px 4px 11px;
    margin-top: 4px;
    line-height: 15px;
    letter-spacing: 1px;
  `;

  const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 9px;
    margin-top: 3px;
  `;

  return (
    <Grid
      style={{
        paddingLeft: `${isMobile ? "10px" : "50px"}`,
        paddingRight: `${isMobile ? "10px" : "50px"}`,
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Accordion defaultExpanded={true} elevation={0} style={{ border: "1px solid #EAEAEA" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
            height: "10px",
            borderRadius: "5px",
            paddingLeft: `${isMobile ? "5px" : "20px"}`,
            paddingRight: `${isMobile ? "5px" : "20px"}`,
            flexFlow: "row-reverse",
          }}
        >
          <Typography
            className={classes.heading}
            style={{
              color: `${pallet.textColorTertiary}`,
              fontFamily: `${pallet.general.fontFamily}`,
              fontSize: `${pallet.general.size14}`,
              fontWeight: pallet.general.weight_600,
              marginLeft: `${isMobile ? "5px" : "10px"}`,
            }}
          >
            <Grid style={{ display: "flex" }}>
              AUTORES&nbsp;&nbsp;&nbsp;{" "}
              <div
                style={{
                  width: "30px",
                  borderRadius: "10px",
                  textAlign: "center",
                  backgroundColor: pallet.backgroundColorQuaternary,
                }}
              >
                {props.proposicao?.autores?.length}

              </div>
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            fontFamily: `${pallet.general.fontFamily}`,
          }}
        >
          <Grid container spacing={0} style={{ width: "100%" }}>
            <Grid
              item
              lg={12}
              xs={12}
              style={{
                fontWeight: pallet.general.weight_600,
                fontSize: pallet.general.size14,
                marginTop: "10px",
                marginBottom: '25px',
                marginLeft: `${isMobile ? "5px" : "20px"}`,
              }}
            >
              APRESENTADO POR
            </Grid>
            <Grid spacing={3} item container style={{ maxHeight: "400px", overflowY: "auto" }}>
              {props.proposicao?.autores?.map((i) => {

                return (
                  <Grid style={{ display: "flex" }} item lg={6} xs={12}>
                    <Grid style={{ marginLeft: `${isMobile ? "5px" : "20px"}` }}>
                      <Avatar src={i.nome === "Presidência da República" ? imagemPP : i.url_foto} />
                    </Grid>

                    <Grid style={{ display: "flex" }}>
                      <Grid
                        style={{
                          // marginTop: "8px",
                          marginLeft: `${isMobile ? "5px" : "18px"}`,
                          fontSize: pallet.general.size14,
                          display: "flex", flexWrap: "wrap"
                        }}
                      >
                        <Grid style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: `${isMobile ? "column" : "row"}`,
                          flexWrap: "nowrap"
                        }}>
                          {/* <div> */}
                          {i.id_parlamentar ?
                            <Link
                              style={{
                                fontWeight: pallet.general.weight_600,
                                color: pallet.general.color1,
                                textDecoration: "unset",
                                marginRight: "5px",
                              }}
                              target="_blank"
                              href={"/interlocutor/" + i.id_parlamentar}
                            >
                              {i.nome}
                            </Link>
                            : i.nome}
                          {/* </div> */}
                          <Grid>
                            {i.tratamento ? "(" : ""}
                            {i.tratamento ? i.tratamento : ""}
                            {i.sigla_partido ? " - " : ""}
                            {i.sigla_partido ? i.sigla_partido : ""}
                            {i.sigla_partido ? " / " : ""}
                            {i.sigla_partido ? i.uf_exercicio : ""}
                            {i.tratamento ? ")" : ""}
                          </Grid>
                        </Grid>
                        {props.permissaoUsuario !== 4 ?
                        <ChipStyle pallet={pallet}>
                          <Grid style={{ display: "flex", justifyContent: "space-between " }} >
                            <DotStyle bg={i.qualificacao?.toLowerCase() === "convergente" ? pallet.color.charts.green :
                              i.qualificacao?.toLowerCase() === "divergente" ? pallet.color.charts.red :
                                i.qualificacao?.toLowerCase() === "neutro" ? pallet.color.charts.lightGrey :
                                  pallet.color.charts.blue}>
                            </DotStyle>
                            <Grid>
                              {i.qualificacao ? i.qualificacao : "Não Classificado"}
                            </Grid>
                          </Grid>
                        </ChipStyle>
                        :
                        null 
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
