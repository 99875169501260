import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { format } from 'date-fns';
import moment from "moment-timezone";

import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarOutlineIcon from '@mui/icons-material/CalendarTodayOutlined';

import { Grid, InputAdornment, Paper, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';
import DeleteIcon from '@mui/icons-material/Delete';
import AccordionActions from '@mui/material/AccordionActions';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';

import filtersBackgroundImage from '../../../src/img/background_filters_dashboard.png';

import NotificationsContextService, { INotificationsContextResultData } from '../../services/NotificationsContextService';

import ProposicaoNotificationFilterItens from "../proposicao/_components/filtro/ProposicaoNotificationsFilterItens";
import { IFiltroNotificacaoProps, NotificacoesList } from '../workspace/_components/NotificacoesList';
import MenuFiltroNotificacoes from './_components/MenuFiltroNotificacoes';
import NotificacaoCard from '../workspace/_components/NotificacaoCard';

import ButtonDialogConfirmation from '../../components/ButtonDialogConfirmation';
import PaginationSaveState from '../../components/PaginationSaveState';
import ComboBoxBuscaKeyUp from '../../components/ComboBoxBuscaKeyUp';
import FilterQuantity from '../../components/filter/FilterQuantity';
import DateRangeSelector from '../../components/DateRangeSelector';
import { IFilterData } from '../../components/filter/ListFilter';
import CircularLoading from '../../components/CircularLoading';
import SolidButton from '../../components/SolidButton';
import useStyles from '../../components/layout/Styles';
import EmptyError from '../../components/EmptyError';

import { useAuthorization } from '../../contexts/AuthorizationContext';
import { usePallet } from '../../contexts/PalletContext';
import { useAlert } from '../../contexts/AlertContext';

import { IListInfo } from '../../models/IListInfo';

import Main from '../main/Main';

moment.tz.setDefault(process.env.TIMEZONE || 'America/Sao_Paulo');

const TitleText = styled(Typography)`
    color: ${(props => props.pallet.textColorQuaternary)};
    font-weight: ${(props => props.pallet.general.weight_bold)};
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1px;
    margin-bottom: 25px;
    padding-left: 17px;
`;

const Pagination = styled(Grid)`
    justify-content: center;
    margin: 20px 0;
`;

const ButtonLimpar = styled(Typography)`
    align-self: center; 
    font-size: 14px;
    color: #4B1196;
    cursor: pointer;
    margin-left: 20px; 
`;

const ButtonLimparDisable = styled(Typography)`
    align-self: center; 
    font-size: 14px;
    color: #727272;
    margin-left: 20px; 
`;


const styleCreateModalFilter = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    borderRadius: '5px', // Borda arredondada
    border: 'none', // Remove a borda preta
    transition: 'all 0.5s ease' // Transição mais lenta
};

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 width: 100%;
 text-align: center;
 background-color: #ffff;
 heigth: 100%;
`;

interface Filtro {
    key: string;
    label: string;
    value: string;
};

export default function CentralNotificacaoList() {
    const [infoList, setInfoList] = useState<IListInfo>({
        pageIndex: 1,
        pageSize: 8,
        pageItens: 0,
        totalItens: 0,
        totalPages: 0,
    });
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [listNotification, setListNotification] = React.useState<Array<INotificationsContextResultData>>();
    const [filtrosNotificacao, setFiltrosNotificacao] = React.useState<Array<IFiltroNotificacaoProps>>([
        { key: 'modulo', label: 'TODOS', total: 0, active: true },
        { key: 'modulo', label: 'PROPOSIÇÃO', total: 0, active: false },
        { key: 'modulo', label: 'INTERLOCUTOR', total: 0, active: false },
        { key: 'modulo', label: 'AGENDA', total: 0, active: false },
        { key: 'modulo', label: 'CITAÇÕES', total: 0, active: false },
        { key: 'modulo', label: 'TWITTER', total: 0, active: false },
    ]);

    const [notifications, setNotifications] = React.useState<Array<INotificationsContextResultData>>();
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    // const [currentFilter, setCurrentNewFilter] = useState<IFilterData[]>();
    const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([
        { key: "dtIni", label: "Data Inicial", value: moment().subtract(1, 'week').toDate() },
        { key: "dtFin", label: "Data Final", value: moment().toDate() },
        { key: 'status', label: 'Ativados', value: 'A' }
    ]);
    const [checked, setChecked] = useState<boolean>(false);
    const [filtro, setFiltro] = useState<string>('ATIVADOS');
    const [modulo, setModulo] = useState<any>({ key: 'modulo', label: 'todos', value: 'TODOS' });

    const [exp, setExp] = useState<boolean>(false);
    
    const [limpar, setLimpar] = useState<boolean>(false);
    const [arquivado, setArquivado] = useState<boolean>();
    const [arquivou, setArquivou] = useState<string>('')
    const [loading, setLoading] = React.useState(false)
    const [loadingCount, setLoadingCount] = React.useState(false)
    const [desligaLoadingClickAba, setDesligaLoadingClickAba] = React.useState(0)

    const [dtIni, setDtIni] = React.useState<Date>(moment().subtract(1, 'week').toDate());
    const [dtFin, setDtFin] = React.useState<Date>(moment().toDate());
    const [status, setStatus] = React.useState("A");
    const [descricao, setDescricao] = React.useState("");

    const [arrayIdsI, setArrayIdsI] = React.useState<Array<any>>([{
        id: 0,
        status: false,
    }]);
    const [arrayIdsII, setArrayIdsII] = React.useState<Array<number>>([]);

    const statusItems: IFilterData[] = [
        { key: "status", label: "Todos", value: "T" },
        { key: "status", label: "Arquivados", value: "ARQ" },
        { key: "status", label: "Ativados", value: "A" },
        { key: "status", label: "Desativadas", value: "D" }
    ];

    const [value, setValue] = React.useState('');

    useEffect(() => {
        setListNotification(notifications);
        if (notifications?.length > 0) {
            for (var i = 0; i < notifications?.length; i++) {
                arrayIdsI.push({ id: notifications[i].id, status: false })
            }
        }
    }, [notifications]);

    useEffect(() => {
        handleUpdateListFilter();
    }, []);

    const loadNotificacaoList = async (paramCurrentFilter: any = [], paramCurrentNewFilter: any = []) => {
        setLoading(true);
        setLoadingCount(true)
        if (String(format((dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(dtFin), "yyyy")).slice(0, 1) !== '0') {
            // setFiltrosNotificacao([])
            const isValidFilter = (item: any) => item && item.key && item.value;
            const useFilter = await paramCurrentFilter.length > 0 && paramCurrentNewFilter.length > 0
                ? [
                    // Remove os filtros antigos que têm a mesma chave dos novos
                    ...paramCurrentFilter
                        .filter(current => !paramCurrentNewFilter.some(newItem => newItem.key === current.key))
                        .filter(isValidFilter),  // Filtra itens válidos
                    ...paramCurrentNewFilter.filter(isValidFilter)  // Adiciona novos filtros válidos
                ]
                : paramCurrentFilter.length > 0
                    ? paramCurrentFilter.filter(isValidFilter)  // Filtra itens válidos
                    : currentFilter;
            setCurrentFilter(useFilter)

            NotificationsContextService.GetAll(useFilter, currentPageSize, currentPage, 'central')
                .then((res) => {
                    console.log("CHEOGU O RES?",res)
                    if (res) {
                        setArrayIdsI([]);
                        setArrayIdsII([]);
                        setArquivou("");
                        setNotifications(res?.data);
                        setInfoList({
                            pageIndex: res?.pageIndex,
                            pageItens: res?.pageItens,
                            pageSize: res?.pageSize,
                            totalItens: res?.totalItens,
                            totalPages: res?.totalPages
                        });
                        setFiltrosNotificacao(filtrosNotificacao.map(item => {
                            item.total = Number(res?.countByCategory[item.label] || 0)
                            return item
                        }));

                        setExp(false);
                    };
                });
        };
    };


    const pressBuscaNotificacao = (event: any) => {
        if (event?.key === 'Enter') {
            let newFilter = currentFilter.filter(filtro => filtro.key !== 'busca');
            console.log("newFilter",newFilter)
            setDescricao(event.target.value);
            setLoadingCount(false)
            setDesligaLoadingClickAba(desligaLoadingClickAba + 1)
            if (event.target.value.length > 2) {
                newFilter.push({ key: "busca", label: "Busca", value: event.target.value });
                setCurrentFilter(newFilter);
            } 
            loadNotificacaoList(newFilter);
            // doneTyping();
          }
        
      }

    const { user, getConfig } = useAuthorization();

    const handleLimpar = () => {
        setLimpar(true)
        handleFalse()

        arrayIdsI.map((a) => {
            a.status = false
        })
    };

    const handleFalse = () => {
        setChecked(false)
        setLimpar(false)
    };

    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
    };

    const onChangeNotificationFilter = (event: any) => {
        setLoadingCount(true);
        // setFiltrosNotificacao([]);
            
    
            setFiltrosNotificacao(filtrosNotificacao.map(note => {
                if (note.label.toLowerCase() === event.target.title.toLowerCase()) {
                    note.active = true
                }
                else if (note.active) note.active = false
                return note
            }));

            let newFilter = currentFilter.filter(filtro => filtro.key !== 'modulo');
            newFilter.push({ key: 'modulo', label: event.target.key, value: event.target.title });
            setModulo({ key: 'modulo', label: event.target.key, value: event.target.title });

            setCurrentPage(1);
            setFiltro(event.target.title);

            setCurrentFilter(newFilter);
            loadNotificacaoList(newFilter);

            localStorage.setItem('filterSelectedJson', JSON.stringify(newFilter));
            setDesligaLoadingClickAba(desligaLoadingClickAba + 1)


    };

    const onChangeDate = (dtIni: Date, dtFin: Date) => {
        setDtIni(new Date(dtIni));
        setDtFin(new Date(dtFin));

        let newFilter = [
            ...currentFilter.map(item => {
                if (item.key === "dtIni") item.value = dtIni;
                if (item.key === "dtFin") item.value = dtFin;

                return item;
            })
        ];

        let filterJson = localStorage.getItem('filterSelectedJson');
        let filterValue = localStorage.getItem('filterSelectedValue');

        let parseFilter: any = [{}];
        if (filterJson !== null && filterJson !== "undefined") {
            parseFilter = JSON.parse(filterJson);
            if ('filtro' in parseFilter) {
                parseFilter = parseFilter.filtro
            };
            newFilter = [
                ...newFilter,
                ...parseFilter.filter(newItem =>
                    !newFilter.some(existingItem => existingItem.key === newItem.key)
                ),
            ];

            const moduloIndex = currentFilter.findIndex(item => item.key === "modulo");
            if (moduloIndex >= 0) {
                // Se existir, atualiza o label e value
                currentFilter[moduloIndex].label = modulo.label;
                currentFilter[moduloIndex].value = modulo.value;
            } else {
                // Se não existir, adiciona o novo objeto
                currentFilter.push(modulo);
            };
        };

        let filterSelectec = filterValue !== null && filterValue !== "undefined" ? filterValue : filterSelectedValue;

        setFilterSelectedValue(filterSelectec);
        setCurrentFilter(newFilter);
        loadNotificacaoList(newFilter);
    };

    const onChangeStatus = (status: string) => {
        setStatus(status);

        if (status === "") {
            setCurrentFilter(currentFilter.filter(x => x.key !== "status"));
        } else {

            const statusFilter = statusItems.filter(x => x.value === status)[0] as IFilterData;

            if (statusFilter) {
                const newFilter = [
                    ...currentFilter.filter(x => x.key !== "status"),
                    { key: 'status', label: statusFilter.label, value: statusFilter.value } // Garantindo o formato correto
                ];

                setCurrentFilter(newFilter);
                loadNotificacaoList(newFilter);
                setLoadingCount(false)
                setDesligaLoadingClickAba(desligaLoadingClickAba + 1)
            }
        }
    };

    const handleBuscaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value);
        setLoadingCount(false)
        setDesligaLoadingClickAba(desligaLoadingClickAba + 1)
    };

    const passarIds = (event: any, id: number) => {
        arrayIdsI.map((a) => {
            if (a.id === id && a.status === false) {
                a.status = true;
            } else if (a.id === id && a.status === true) {
                a.status = false;
            };
        });
    };

    const handleDesarquivada = async () => {
        setLoading(true);

        arrayIdsI.map((a) => {
            if (a.status === true) {
                arrayIdsII.push(a.id)
            };
        })

        for (var i = 0; i < arrayIdsII.length; i++) {
            await NotificationsContextService.Desarquivar(arrayIdsII[i]).then((res) => { })
        };

        setTimeout(() => {
            setLoading(false);
        }, 500);

        setArquivado(true);
        setArquivou("DELETE");

        NewAlert('success', 'Notificações desarquivadas com sucesso!');

        loadNotificacaoList(currentFilter, currentFilter);
    };

    const handleArquivado = async () => {
        setLoading(true);

        arrayIdsI.map((a) => {
            if (a.status === true) { arrayIdsII.push(a.id) };
        });

        for (var i = 0; i < arrayIdsII.length; i++) {
            await NotificationsContextService.Arquivar(arrayIdsII[i]).then((res) => { });
        };

        setTimeout(() => {
            setLoading(false);
        }, 500);
        setArquivado(true);
        setArquivou("DELETE");

        NewAlert('success', 'Notificações selecionadas arquivadas com sucesso!');

        loadNotificacaoList(currentFilter, currentFilter);
    };

    const handleArquivarTodas = async () => {
        NewAlert('success', 'Por favor, aguarde. Estamos arquivando suas notificações! Este processo pode demorar um pouco.');
        setLoading(true);

        await NotificationsContextService.ArquivarTodas(filtro, dtIni, dtFin).then((res) => { });

        setArquivado(true);
        setArquivou("DELETE");

        loadNotificacaoList(currentFilter, currentFilter);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    var typingTimer;

    const handleBuscaKeyUp = (e) => {
        if (typingTimer) clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, 1000);
        setLoadingCount(false)
        setDesligaLoadingClickAba(desligaLoadingClickAba + 1)
    };

    const handleBuscaKeyDown = (e) => {
        clearTimeout(typingTimer);
        setLoadingCount(false)
        setDesligaLoadingClickAba(desligaLoadingClickAba + 1)
    };

    function doneTyping() {
        let newFilter = currentFilter.filter(filtro => filtro.key !== 'busca');

        if (descricao.length > 2) {
            newFilter.push({ key: "busca", label: "Busca", value: descricao });
            setCurrentFilter(newFilter);
        } else {
            setCurrentFilter(newFilter);
        };

        let filterJson = localStorage.getItem('filterSelectedJson');
        let filterValue = localStorage.getItem('filterSelectedValue');

        let parseFilter: any = JSON.parse(filterJson);
        if (Array.isArray(parseFilter)) {
            parseFilter = parseFilter
        } else {
            parseFilter = parseFilter?.filtro
        };

        filterJson = filterJson !== null ? parseFilter : currentFilter
        setFilterSelectedValue(filterValue !== null ? filterValue : filterSelectedValue);
        setCurrentPage(1);
        loadNotificacaoList(newFilter, filterJson);
        setDesligaLoadingClickAba(desligaLoadingClickAba + 1);
        setLoadingCount(false);

    };

    const [openModalCreateFilter, setOpenModalCreateFilter] = React.useState(false);
    const [openModalEditFilter, setOpenModalEditFilter] = React.useState(false);
    const [idFilterEdit, setIdFilterEdit] = React.useState("");
    const [filterSelectedValue, setFilterSelectedValue] = useState("Nenhum filtro selecionado");
    const [newFilterN, setNewFilterN] = useState([]);

    const handleClose = () => {
        setOpenModalCreateFilter(false);
    };

    
    useEffect(() => {
        loadNotificacaoList(currentFilter, currentFilter);
        setTimeout(() => {
            setLoading(false);
            setLoadingCount(false)
               setDesligaLoadingClickAba(desligaLoadingClickAba + 1);
        }, 1000);
    }, [currentPage,currentPageSize]);

    

    const handleSaveFilter = async () => {
        const checkEsferaIndex = currentFilter.findIndex(obj => obj.key === "esfera");
        const checkTituloIndex = currentFilter.findIndex(obj => obj.key === "titulo");

        if (currentFilter[checkEsferaIndex].value.length > 0 || currentFilter[checkTituloIndex].value.length > 0) {
            if (currentFilter.length > 0 && checkEsferaIndex >= 0) {
                const esferaValueSelected = currentFilter[checkEsferaIndex].value;
                if (esferaValueSelected.includes("Federal")) {
                    const checkEstadoIndex = currentFilter.findIndex(obj => obj.key === "estado");
                    if (checkEstadoIndex >= 0) { currentFilter.splice(checkEstadoIndex, 1); };

                    const checkMunicipioIndex = currentFilter.findIndex(obj => obj.key === "municipio");
                    if (checkMunicipioIndex >= 0) { currentFilter.splice(checkMunicipioIndex, 1); };
                } else {
                    const checkCasaIndex = currentFilter.findIndex(obj => obj.key === "casa");
                    if (checkCasaIndex >= 0) { currentFilter.splice(checkCasaIndex, 1); };

                    if (checkEsferaIndex === 1) { currentFilter.reverse(); };
                };

                const hasMunicipios = currentFilter.find(item => item.key === "esfera").value.includes("Municipal");
                if (!hasMunicipios) {
                    const checkMunicipioIndex = currentFilter.findIndex(obj => obj.key === "municipio");
                    if (checkMunicipioIndex >= 0) { currentFilter.splice(checkMunicipioIndex, 1); };
                };
            };

            if (openModalEditFilter) {
                await NotificationsContextService.UpdateFiltro(idFilterEdit, currentFilter).then((res) => {
                    NewAlert('success', 'Filtro alterado com sucesso!');
                    loadNotificacaoList(currentFilter, currentFilter);
                    handleUpdateListFilter(res[0]);
                }).catch(() => {
                    NewAlert("error", "Não foi possivel alterar o filtro, tente novamente.");
                });
            } else {
                await NotificationsContextService.SalvarFiltro(currentFilter).then((res) => {
                    NewAlert('success', 'Filtro salvo com sucesso!');
                    loadNotificacaoList(currentFilter, currentFilter);
                    handleUpdateListFilter(res[0]);
                }).catch(() => {
                    NewAlert("error", "Não foi possivel salvar o filtro, tente novamente.");
                });
            };

            setOpenModalCreateFilter(false);
        };
    };

    const handleOnChangeFilter = async (data: IFilterData[]) => {
        setCurrentFilter(data);
    };

    const [filters, setFilters] = React.useState<Array<any>>([]);

    const handleUpdateListFilter = async (id: number = undefined) => {
        await NotificationsContextService.Filtros().then(res => {
            if (res !== undefined) {
                const dadosFormatados = res.map(dado => {
                    return {
                        id: dado.id,
                        filtro: JSON.parse(dado.filtro) as Filtro[]
                    };
                });

                const dadosComLabel = dadosFormatados.map(dado => {
                    const label = dado.filtro.map(item => item.label).join(", ");
                    const teste = dado.filtro.find(item => item.key === "titulo");
                    return {
                        id: dado.id,
                        label: label,
                        filtro: dado.filtro,
                        titulo: teste !== undefined ? teste.label : label
                    };
                });

                setFilters(dadosComLabel);
                if (id) {
                    let itemFilter = dadosComLabel.find(item => item.id === id);
                    let itemTitulo = itemFilter.filtro.find(item => item.key === "titulo");

                    setFilterSelectedValue(itemTitulo !== undefined ? itemTitulo?.label : itemFilter.label);

                    localStorage.setItem('filterSelectedValue', itemTitulo !== undefined ? itemTitulo?.label : itemFilter.label);
                    localStorage.setItem('filterSelectedJson', JSON.stringify(itemFilter));
                };
            };
        });
    };

    const handleChangeFilter = async (event) => {
        const id: number = Number(event.target.name);
        const value = event.target.value;
        let valueSelect = value;
        let filter_selected: any = [{}];

        if (value !== "Nenhum filtro selecionado") {
            filter_selected = filters.find(item => item.id === id);
            if (filter_selected) {
                filter_selected = filter_selected.filtro;

                if (!filter_selected.some(item => item.key === "dtIni")) {
                    filter_selected.push({ key: "dtIni", value: dtIni });
                }

                if (!filter_selected.some(item => item.key === "dtFin")) {
                    filter_selected.push({ key: "dtFin", value: dtFin });
                }

                setNewFilterN(filter_selected);

                let itemTitulo = filter_selected.find(item => item.key === "titulo");
                valueSelect = itemTitulo !== undefined ? itemTitulo?.label : event.target.value;

                const moduloIndex = filter_selected.findIndex(item => item.key === "modulo");
                if (moduloIndex >= 0) {
                    filter_selected[moduloIndex].label = "";
                    filter_selected[moduloIndex].value = modulo.value;
                } else {
                    filter_selected.push(modulo);
                };
            };
        } else {
            if (!filter_selected.some(item => item.key === "dtIni")) {
                filter_selected.push({ key: "dtIni", value: dtIni });
            }

            if (!filter_selected.some(item => item.key === "dtFin")) {
                filter_selected.push({ key: "dtFin", value: dtFin });
            }

            const moduloIndex = filter_selected.findIndex(item => item.key === "modulo");
            if (moduloIndex >= 0) {
                filter_selected[moduloIndex].label = "";
                filter_selected[moduloIndex].value = modulo.value;
            } else {
                filter_selected.push(modulo);
            };
        };

        loadNotificacaoList(filter_selected);
        setFilterSelectedValue(valueSelect);
        setCurrentFilter(filter_selected);

        localStorage.setItem('filterSelectedValue', valueSelect);
        localStorage.setItem('filterSelectedJson', JSON.stringify(filter_selected));
    };

    const handleOpen = () => {
        setCurrentFilter([]);
        setOpenModalCreateFilter(true);
        setOpenModalEditFilter(false);
    };

    const handleDelete = async (id) => {
        if (id) {
            setIdFilterEdit("");
            await NotificationsContextService.DeleteFiltro(id).then((res) => {
                handleUpdateListFilter();
                NewAlert('success', 'Filtro deletado com sucesso!');
            }).catch(() => {
                NewAlert("error", "Não foi possivel deletar o filtro, tente novamente.");
            });
        };
    };

    const handleEdit = (id, filter) => {
        if (id && filter) {
            setIdFilterEdit(id);
            setCurrentFilter(filter);
            setOpenModalCreateFilter(true);
            setOpenModalEditFilter(true);
        };
    };

    const openDetected = (isExpended) => {
        setExp(isExpended);
    };


    return (
        <div className={classes.root}>
            <Main>
                <>
                    <Grid container spacing={2} >
                        <TitleText pallet={pallet}>
                            Central de Notificações
                        </TitleText>

                        <Grid container style={{ paddingLeft: "12px" }}>
                            <Grid
                                item
                                container
                                xs={12}
                                style={{
                                    backgroundImage: `url(${filtersBackgroundImage})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    padding: "25px",
                                    borderRadius: "5px",
                                    boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                }}
                            >
                                <Grid item>
                                    <Grid style={{ marginBottom: "5px" }}>
                                        <Typography
                                            style={{
                                                color: `${pallet.color.secundary.font}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.subtitle,
                                            }}
                                        >
                                            PERÍODO
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        alignItems="center"
                                        direction="row"
                                        style={{
                                            background: "#4B1196",
                                            borderRadius: "5px",
                                            justifyContent: `${isMobile ? "flex-start" : "center"}`,
                                            padding: "0 10px",
                                        }}
                                    >
                                        <Grid item>
                                            <CalendarOutlineIcon
                                                style={{
                                                    color: `${pallet.color.primary.font}`,
                                                    marginTop: "6px",
                                                    fontSize: "17px",
                                                    marginRight: "0px",
                                                    marginLeft: `${isMobile ? "6px" : "5px"}`
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                paddingRight: "10px",
                                                color: `${pallet.color.primary.font}`,
                                            }}
                                        >
                                            <DateRangeSelector
                                                startDate={dtIni}
                                                endDate={dtFin}
                                                onChange={onChangeDate}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marginLeft: "40px" }}>
                                    <Grid style={{ marginBottom: "5px" }}>
                                        <Typography
                                            style={{
                                                color: `${pallet.color.secundary.font}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.subtitle,
                                            }}
                                        >
                                            VISUALIZAR
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        alignItems="center"
                                        direction="row"
                                        style={{
                                            maxWidth: `${isMobile ? "100%" : "300px"}`,
                                            width: "250px",
                                        }}
                                    >
                                        <MenuFiltroNotificacoes
                                            itens={statusItems}
                                            selectedItem={status}
                                            onChange={onChangeStatus} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <FilterQuantity
                                list={infoList}
                                currentPageSize={currentPageSize}
                                onChange={handleOnChangeItensPerPageSize}
                            />
                        </Grid>

                <Grid item xs={12}>
                <Grid style={{ width: "100%", marginTop: "20px", marginBottom: "30px" }}>
                                {/* <ComboBoxBuscaKeyUp
                                    idCampo='buscaKeyUp'
                                    name="filtroFavoritos"
                                    onKeyUp={handleBuscaKeyUp}
                                    onKeyDown={handleBuscaKeyDown}
                                    onChange={handleBuscaChange}
                                    placeholder="Buscar"
                                /> */}
                <TextField
                  // placeholder={`Digite${selectedInput === 'buscaGeral' ? ' + ENTER' : ' a proposição + ENTER'}`}
                  placeholder={`Digite + ENTER`}
                  fullWidth
                  variant="outlined"
                  defaultValue={value}
                  onKeyPress={pressBuscaNotificacao}
                //   style={{ width: isMobile ? '250px' : '400px', display: 'inline-block' }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    },
                    style: {
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size14}`,
                      fontWeight: pallet.general.weight_100,
                      background: `${pallet.backgroundColorSecondary}`
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                            </Grid>

                            <Grid container xs={12}
                                style={{
                                    backgroundColor: "#F9F9F9",
                                    padding: "10px 17px",
                                    borderRadius: "5px 5px 0px 0px",
                                    boxShadow: "rgb(0 0 0 / 10%) 1px 0.5px 4px 0px, rgb(0 0 0 / 9%) 0px 0px 0px 0px",
                                }}
                            >
                                <NotificacoesList filtros={filtrosNotificacao} desligaLoading={setLoadingCount} desligaLoadingClickAba={desligaLoadingClickAba} loading={loadingCount} onChange={onChangeNotificationFilter} />
                            </Grid>

                            {getConfig()?.habNotFiltroMult ?
                                <div>
                                    <Modal
                                        open={openModalCreateFilter}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={styleCreateModalFilter}>
                                            <ProposicaoNotificationFilterItens
                                                filter={currentFilter}
                                                onChangeFilter={handleOnChangeFilter}
                                                // onChangeFilter={handleChangeFilter}
                                                idPermissao={user.id_permissao}
                                            />

                                            <br></br>

                                            <Grid container justifyContent="end" style={{ padding: "15px 0px 15px 0px" }}>
                                                <Grid item style={{ marginRight: `${isMobile ? "5px" : "20px"}` }}>
                                                    <SolidButton
                                                        onClick={() => handleSaveFilter()}
                                                        color={`${pallet.textColorPrimary}`}
                                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                                        fontSize={`${pallet.general.size14}`}
                                                        title={"SALVAR"}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <SolidButton
                                                        onClick={() => handleClose()}
                                                        color={`${pallet.textColorSecondary}`}
                                                        backgroundColor={`${pallet.backgroundColorSecondary}`}
                                                        fontSize={`${pallet.general.size14}`}
                                                        title={"FECHAR"}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Modal>
                                </div> : null
                            }

                            <Grid item xs={12}
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    padding: "10px 17px",
                                    borderRadius: "0px 0px 5px 5px",
                                    boxShadow: "rgb(0 0 0 / 10%) 1px 0.5px 4px 0px, rgb(0 0 0 / 9%) 0px 0px 0px 0px",
                                }}
                            >
                                {getConfig()?.habNotFiltroMult ?
                                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} style={{
                                        width: "100%",
                                        fontFamily: pallet.fontFamily.general,
                                        position: "relative" // adiciona isso para o pai ter posição relativa
                                    }}>
                                        <div style={{
                                            position: "absolute",
                                            zIndex: 1000,
                                            width: "100%",
                                            paddingTop: '5px'
                                        }}> {/* Adicione este wrapper */}
                                            <Accordion
                                                expanded={exp}
                                                sx={{ boxShadow: 'none' }}
                                                onChange={(event, isExpended) => openDetected(isExpended)}
                                                style={{
                                                    width: "100%",
                                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Adiciona uma sombra
                                                    backgroundColor: "white", // Adiciona uma cor de fundo
                                                }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel3-content"
                                                    id="panel3-header"
                                                >
                                                    <span style={{ fontWeight: pallet.general.weight_600 }}>Filtro: </span>
                                                    <span style={{ marginLeft: '5px' }}>{filterSelectedValue}</span>
                                                </AccordionSummary>
                                                <AccordionDetails style={{
                                                    width: "100%"
                                                }}>
                                                    <FormControl style={{
                                                        width: "100%"
                                                    }}>
                                                        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            name="radio-buttons-group"
                                                            value={filterSelectedValue}
                                                            onChange={handleChangeFilter}
                                                        >
                                                            <Grid container style={{
                                                                backgroundColor: "#f9f9f9",
                                                                padding: "8px",
                                                                marginBottom: "5px",
                                                                width: "100%",
                                                                borderRadius: "5px"
                                                            }}>
                                                                <FormControlLabel value="Nenhum filtro selecionado" control={<Radio />} label="Nenhum" id="nenhum" />
                                                            </Grid>

                                                            {filters?.length > 0 ?
                                                                filters?.map((item, index) => {
                                                                    return (
                                                                        <Grid container key={index} style={{
                                                                            backgroundColor: "#f9f9f9",
                                                                            padding: "8px",
                                                                            marginBottom: "5px",
                                                                            width: "100%",
                                                                            borderRadius: "5px"
                                                                        }}>
                                                                            <Grid>
                                                                                <FormControlLabel
                                                                                    value={item.titulo}
                                                                                    control={<Radio />}
                                                                                    label={
                                                                                        <Grid style={{ wordWrap: "break-word" }}>{item.titulo}</Grid>
                                                                                    }
                                                                                    id={item.id}
                                                                                    name={item.id}
                                                                                />
                                                                            </Grid>

                                                                            <IconButton aria-label="edit" onClick={() => handleEdit(item.id, item.filtro)}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                            <IconButton aria-label="delete" onClick={() => handleDelete(item.id)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    )
                                                                }) : null
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </AccordionDetails>
                                                <AccordionActions>
                                                    <Button onClick={() => handleOpen()} style={{
                                                        alignSelf: "center",
                                                        fontSize: "14px",
                                                        color: "#4B1196",
                                                        cursor: "pointer",
                                                        marginLeft: "20px",
                                                    }}>CRIAR</Button>
                                                </AccordionActions>
                                            </Accordion>
                                        </div>
                                    </Grid>

                                    :

                                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} style={{
                                        width: "100%",
                                        fontFamily: pallet.fontFamily.general,
                                        position: "relative"
                                    }} />
                                }

                                {status === 'T' || status === 'D' ?
                                    <Grid style={{
                                        alignItems: 'right',
                                        display: 'flex',
                                        margin: '10px 15px 25px 0px',
                                        justifyContent: 'right'
                                    }}>
                                    </Grid> : null
                                }

                                {listNotification?.length === 0 && status === 'A' ?
                                    <Grid style={{
                                        alignItems: 'right',
                                        display: 'flex',
                                        margin: '10px 15px 10px 0px',
                                        justifyContent: 'right'
                                    }}>
                                        <ButtonLimparDisable style={{ marginRight: '10px' }}>Limpar</ButtonLimparDisable>

                                        <Button
                                            size="large"
                                            sx={{
                                                backgroundColor: '#0048ba',
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontSize: `${pallet.general.size12}`,
                                                background: '#FFFFFF',
                                                letterSpacing: "1px",
                                                border: `${"1px solid lightgrey"}`,
                                                borderRadius: "5px",
                                                fontWeight: pallet.general.weight_600,
                                                padding: "10px",
                                                marginLeft: '10px',
                                                color: '#FF69B4'
                                            }}
                                            disabled
                                        >
                                            ARQUIVAR SELECIONADAS
                                        </Button>

                                        <Button
                                            size="large"
                                            sx={{
                                                backgroundColor: '#e32636',
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                color: `${pallet.textColorSecondary}`,
                                                fontSize: `${pallet.general.size12}`,
                                                background: '#FFFFFF',
                                                letterSpacing: "1px",
                                                border: `${"1px solid lightgrey"}`,
                                                borderRadius: "5px",
                                                fontWeight: pallet.general.weight_600,
                                                padding: "10px",
                                                marginLeft: '10px'

                                            }}
                                            disabled
                                        >
                                            <div style={{ fontSize: 0 }}>@</div>ARQUIVAR TODAS
                                        </Button>
                                    </Grid> : null
                                }

                                {listNotification?.length > 0 && status === 'A' ?
                                    <Grid style={{
                                        alignItems: 'right',
                                        display: 'flex',
                                        margin: '10px 15px 10px 0px',
                                        justifyContent: 'right'
                                    }}>
                                        <ButtonLimpar onClick={handleLimpar} style={{ marginRight: '10px' }}>Limpar</ButtonLimpar>
                                        <ButtonDialogConfirmation
                                            size={'large'}
                                            styles={{
                                                "&:hover": {
                                                    backgroundColor: '#4B1196',
                                                },
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                color: `${pallet.textColorPrimary}`,
                                                fontSize: `${pallet.general.size12}`,
                                                background: '#4B1196',
                                                letterSpacing: "1px",
                                                border: `${"1px solid lightgrey"}`,
                                                borderRadius: "5px",
                                                fontWeight: pallet.general.weight_600,
                                                padding: "10px",
                                                marginLeft: '10px'

                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            // lastPath={"/central-notificacoes"}
                                            confirmationEvent={() => user.id_permissao !== 3 ? handleArquivado() : NewAlert(
                                                "error",
                                                "Não é possível realizar está ação com este perfil.")}
                                            title_button={"ARQUIVAR SELECIONADAS"}
                                            confirmation_button={"Arquivar"}
                                            content={"DESEJA REALMENTE ARQUIVAR AS NOTIFICAÇÕES SELECIONADAS?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        />

                                        <ButtonDialogConfirmation
                                            size={'large'}
                                            styles={{
                                                "&:hover": {
                                                    backgroundColor: '#4B1196',
                                                },
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                color: `${pallet.textColorPrimary}`,
                                                fontSize: `${pallet.general.size12}`,
                                                background: '#4B1196',
                                                letterSpacing: "1px",
                                                border: `${"1px solid lightgrey"}`,
                                                borderRadius: "5px",
                                                fontWeight: pallet.general.weight_600,
                                                padding: "10px",
                                                marginLeft: '10px'

                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            // lastPath={"/central-notificacoes"}
                                            confirmationEvent={() => user.id_permissao !== 3 ? handleArquivarTodas() : NewAlert(
                                                "error",
                                                "Não é possível realizar está ação com este perfil.")}
                                            title_button={"ARQUIVAR TODAS"}
                                            confirmation_button={"Arquivar"}
                                            content={"DESEJA REALMENTE ARQUIVAR TODAS AS NOTIFICAÇÕES?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        />
                                    </Grid> : null
                                }

                                {listNotification?.length === 0 && status === 'ARQ' ?
                                    <Grid style={{
                                        alignItems: 'right',
                                        display: 'flex',
                                        margin: '10px 15px 10px 0px',
                                        justifyContent: 'right'
                                    }}>
                                        <ButtonLimparDisable style={{ marginRight: '10px' }}>Limpar</ButtonLimparDisable>

                                        <Button
                                            size="large"
                                            sx={{
                                                backgroundColor: '#0048ba',
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontSize: `${pallet.general.size12}`,
                                                background: '#FFFFFF',
                                                letterSpacing: "1px",
                                                border: `${"1px solid lightgrey"}`,
                                                borderRadius: "5px",
                                                fontWeight: pallet.general.weight_600,
                                                padding: "10px",
                                                marginLeft: '10px',
                                                color: '#FF69B4'
                                            }}
                                            disabled
                                        >
                                            DESARQUIVAR SELECIONADAS
                                        </Button>
                                    </Grid> : null
                                }

                                {listNotification?.length > 0 && status === 'ARQ' ?
                                    <Grid style={{
                                        alignItems: 'right',
                                        display: 'flex',
                                        margin: '10px 15px 10px 0px',
                                        justifyContent: 'right'
                                    }}>
                                        <ButtonLimpar onClick={handleLimpar} style={{ marginRight: '10px' }}>Limpar</ButtonLimpar>
                                        <ButtonDialogConfirmation
                                            size={'large'}
                                            styles={{
                                                "&:hover": {
                                                    backgroundColor: '#4B1196',
                                                },
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                color: `${pallet.textColorPrimary}`,
                                                fontSize: `${pallet.general.size12}`,
                                                background: '#4B1196',
                                                letterSpacing: "1px",
                                                border: `${"1px solid lightgrey"}`,
                                                borderRadius: "5px",
                                                fontWeight: pallet.general.weight_600,
                                                padding: "10px",
                                                marginLeft: '10px'

                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            // lastPath={"/central-notificacoes"}
                                            confirmationEvent={() => user.id_permissao !== 3 ? handleDesarquivada() : NewAlert(
                                                "error",
                                                "Não é possível realizar está ação com este perfil.")}
                                            title_button={"DESARQUIVAR SELECIONADAS"}
                                            confirmation_button={"Desarquivar"}
                                            content={"DESEJA REALMENTE DESARQUIVAR AS NOTIFICAÇÕES SELECIONADAS?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        />
                                    </Grid> : null
                                }

                                <br></br>

                                {loadingCount === true ?
                                    <CircularLoading
                                        size={80}
                                        top={"50%"}
                                        left={"50%"}
                                        zIndex={100}
                                    />

                                    :

                                    listNotification?.map((item, index) => {
                                        return <NotificacaoCard
                                            notification={item}
                                            limpar={limpar}
                                            onChange={handleLimpar}
                                            checked={checked}
                                            idsFlow={passarIds}
                                            arquivado={arquivado}
                                            status={status}
                                            central={true}
                                            index={index}
                                            reloadList={loadNotificacaoList}
                                            arquivou={arquivou}
                                            idPermissao={user.id_permissao}
                                            filtro={status}
                                        />
                                    })}

                                {listNotification?.length === 0 && (
                                    <>
                                        <PaperContent pallet={pallet} style={{ marginTop: '40px' }}>
                                            <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" />
                                        </PaperContent>
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        <Pagination container xs={12}>
                            <PaginationSaveState
                                name={"CentralNotificacaoList"}
                                pages={infoList?.totalPages}
                                page={currentPage}
                                onChange={setCurrentPage}
                            />
                        </Pagination>
                    </Grid>
                </>
            </Main>
        </div >
    );
};
