import { useCallback, useEffect, useState } from "react";
import useStyles from '../../../../components/layout/Styles';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography, Paper, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../../contexts/PalletContext';
import AccordionTextDataApensadasProposicao from "./AccordionTextDataApensadasProposicao";
import PaginationSaveState from "../../../../components/PaginationSaveState";
import { IListInfo } from "../../../../models/IListInfo";
import { Visibility } from "@material-ui/icons";
import CircularLoading from "../../../../components/CircularLoading";
import { IFilterData } from "../../../../components/filter/ListFilter";
import ProposicaoMenuFiltroApensadas from "./ProposicaoMenuFiltroApensadas";
import ProposicaoService from "../../../../services/ProposicaoService";
import styled from "styled-components";
import EmptyError from "../../../../components/EmptyError";
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
import EmptyErrorDocCarousel from "../../../../components/EmptyErrorDocCarousel";
import AccordionNovaApensada from "../apensadas/AccordionNovaApensada"
import FilterQuantity from '../../../../components/filter/FilterQuantity';
import React from "react";
import ComboBox from '../../../../components/ComboBox';
import SolidButton from "../../../../components/SolidButton";
import { useAlert } from "../../../../contexts/AlertContext";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAuthorization } from "../../../../contexts/AuthorizationContext";


const PaperContent = styled(Paper)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
`;


export default function ProposicaoApensadas(props: {
    id_proposicao: number,
    index: number,
    activeTab: number,
    proposicao_integrada?: boolean
    onChange?: () => void,

}) {
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [filtro, setFiltro] = useState<IFilterData[]>([{ key: 'tipo_relacao', label: 'Tipo', value: 'T' }]);
    const [opfiltro, setOpfiltro] = useState("Tudo");
    const [loading, setLoading] = useState(true);
    const [proposicao, setProposicao] = useState([]);
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1)
    };
    const [expandedAcordion, setExpandedAcordion] = useState(false);

    const [proposicoesList, setProposicoesList] = useState<string[]>([]);
    const [apensada, setApensada] = useState<string>("");
    const [apensadaParcial, setApensadaParcial] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const { user } = useAuthorization();
    const [lockSaveButton, setLockSaveButton] = useState<boolean>(true);

    const handleProposicaoApensadaChange = (event: any) => {
        if (event)
            setApensada(event.value)
        else
            setApensada("")
    };


    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [filtro])

    useEffect(() => {
        if (currentPage > 0)
            getList()

    }, [currentPage, currentPageSize, filtro]);



    const getList = useCallback(() => {
        const filter = filtro.map(item => { return { key: item.key, value: item.value } });
        if (currentPage && currentPage > 0) {
            ProposicaoService.GetListApensada(props.id_proposicao, filter, currentPage, currentPageSize).then(async res => {
                console.log("OLHA O RES",res)
            
                if (res && res.success) {
                    setLista(res.listData);
                    setInfoList(res.listInfo);
                    setLoading(false);
                }
            })
        }


    }, [props.id_proposicao, currentPageSize, currentPage, filtro])


    useEffect(() => {
        if (apensadaParcial && apensadaParcial.trim().length > 2) {
            ProposicaoService.GetPartialDescription(apensadaParcial, [], true)
                .then(async res => {
                    if (res) setProposicoesList(res.map((item) => {
                        return {
                            value: item.id,
                            label: item.descricao,
                            key: item.id
                        }
                    }))
                });
        } else if (apensadaParcial === "") {

        }
    }, [apensadaParcial]);

    useEffect(() => {
        SaveValidation();

    }, [apensada])

    function SaveValidation() {
        if (apensada) {
            setLockSaveButton(false)

        } else setLockSaveButton(true)
    }

    const salvarApensada = () => {

        const data = { id_proposicao_relacionada: apensada }

        ProposicaoService.CreateApensada(props?.id_proposicao, data).then(res => {
            if (res && res > 0) {
                setApensada("")
                setApensadaParcial("")
                setProposicoesList([])
                handleProposicaoApensadaChange("")
                NewAlert('success', 'Proposição apensada com sucesso');
                setOpen(false)

            } else {
                NewAlert('error', 'Não foi possível apensar esta proposição');
            }
        })
            .then(data => {
                handleChangeNovaApensada()
                setExpandedAcordion(false)
            })

    }


    const descTipo = (value: string) => {
        switch (value) {
            case "T": return "Tudo";
            case "A": return "Apensadas";
            case "S": return "Associadas";
            case "O": return "Outros";
            default: return "Tudo";
        }
    }
    //const setPage = (p: number) => setCurrentPage(p);
    const setFilter = (f: any) => {
        setOpfiltro(descTipo(f.target.value));
        setFiltro([{ key: f.target.name, label: f.target.name, value: f.target.value }]);
    }

    const handleChangeNovaApensada = () => {
        setLoading(true);
        getList();
    };

    const handleChangeAcordion =
        (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedAcordion(!ativo);
            setOpen(expandedAcordion)
        };


    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: 64px;
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
  `;


  const permissaoErro = () => {
    NewAlert(
        "error",
        "Não é possível realizar está ação com este perfil.")

}

    return (

        <div>
            {
                props.proposicao_integrada === false ?

                    loading === true
                        ?
                        <CircularLoading
                            size={80}
                            top={"90%"}
                            left={"50%"}
                            zIndex={2}

                        />

                        :

                        props.proposicao_integrada === false ?

                            <Grid>

                                <Grid direction="row" alignItems="baseline" style={{
                                    paddingLeft: `${isMobile ? "20px" : "45px"}`,
                                    paddingRight: `${isMobile ? "20px" : "50px"}`,
                                    paddingTop: `${isMobile ? "10px" : "10px"}`,
                                    paddingBottom: `${isMobile ? "0px" : "50px"}`,

                                }} >
                                    {/* //Nova  Apensada */}
                                    <Accordion
                                        disabled={false}
                                        elevation={0}
                                        // expanded={open}
                                        onChange={handleChangeAcordion(expandedAcordion)}

                                        style={{
                                            border: "1px solid #EAEAEA",
                                        }}
                                    >
                                        <AccordionSum
                                            expandIcon={<ArrowDropDownIcon
                                                style={{
                                                    color: `${pallet.general.color3}`,
                                                    fontSize: `${pallet.general.size16}`,

                                                }}
                                            />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                backgroundColor: `${pallet.color.primary.background}`,
                                                borderRadius: "5px",

                                            }}
                                        >
                                            <Typography className={classes.heading} style={{
                                                color: `${pallet.color.primary.font}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontWeight: pallet.fontWeight.subtitle,
                                                alignItems: "center",
                                                letterSpacing: "1px",
                                                marginLeft: "10px",
                                            }}>
                                                RELACIONAR APENSADA A PROPOSIÇÃO
                                            </Typography>
                                        </AccordionSum>
                                        <AccordionDetails style={{
                                            fontFamily: `${pallet.fontFamily.general}`
                                        }}>
                                            <Grid >
                                                <Grid container xl={12} lg={12} xs={12} style={{
                                                    paddingTop: "30px"
                                                }}>
                                                    <Grid container spacing={3}
                                                    // style={{ paddingLeft: `${isMobile ? "0px" : "24px"}`, paddingRight: `${isMobile ? "0px" : "24px"}` }}
                                                    >
                                                        <Grid item lg={12} xs={12} style={{ marginLeft: `${isMobile ? "0px" : "5px"}`, marginRight: `${isMobile ? "0px" : "5px"}` }}>
                                                            <Grid style={{ width: "100%" }}>
                                                                <Typography className={classes.heading} style={{
                                                                    color: `${pallet.color.tertiary.font}`,
                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                                                    fontWeight: pallet.fontWeight.subtitle,
                                                                    alignItems: "center",
                                                                    letterSpacing: "1px",
                                                                    marginLeft: "10px",
                                                                    marginBottom: "10px"
                                                                }}>
                                                                    PROPOSIÇÃO
                                                                </Typography>

                                                                <ComboBox
                                                                    name="filtroprop"
                                                                    value={apensada}
                                                                    // inputValue={apensadaParcial}
                                                                    onChange={setApensadaParcial}
                                                                    onSelect={handleProposicaoApensadaChange}
                                                                    itens={proposicoesList}
                                                                    placeholder="Buscar"

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                        <Grid>
                                            < Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                                        </Grid>
                                        <Grid container alignItems="center" sx={{
                                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                            height: `${isMobile ? "50px" : "98px"}`,
                                            borderRadius: "5px"
                                        }}>
                                            <Grid container item>

                                                <Grid item lg={12} xs={12}
                                                    style=
                                                    {{
                                                        paddingRight: `${isMobile ? "0px" : "40px"}`,
                                                        textAlignLast: "end",
                                                        padding: `${isMobile ? "0px" : "10px 20px 10px 20px"}`,
                                                    }}>
                                                    <SolidButton
                                                        onClick={user.id_permissao !== 3 ? salvarApensada : permissaoErro}
                                                        disabled={lockSaveButton}
                                                        // onChange={props?.reloadList}
                                                        color={`${pallet.textColorPrimary}`}
                                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                                        fontSize={`${pallet.general.size14}`}
                                                        title={"SALVAR"}
                                                    // ativo={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Accordion >
                                    {infoList?.totalItens === undefined && !expandedAcordion || !expandedAcordion && (infoList?.totalItens <= 0 && infoList?.totalNoFilter === 0) ?
                                        <Grid
                                            style={{
                                                color: `${pallet.textColorQuaternary}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: 600,
                                                textAlign: "center",
                                                paddingBottom: "100px",
                                                marginTop: "50px",
                                            }}
                                        >
                                            <EmptyErrorDoc title="Comece relacionando uma apensada"
                                                description="Você ainda não relacionou nenhuma apensada para esta proposição."
                                                subdescription="" />
                                        </Grid> : <Grid >
                                        </Grid>}

                                    {
                                        infoList?.totalItens > 0 || infoList?.totalNoFilter > 0 ?
                                            <Grid item xl={12} lg={12} xs={12} >
                                                <Divider variant="fullWidth" style={{ margin: "30px 0px 30px 0px" }} />
                                                <Grid item xl={12} lg={12} xs={12} style={{ paddingRight: `${isMobile ? "0px" : "30px"}`, paddingLeft: `${isMobile ? "0px" : "30px"}` }}>
                                                    <Grid container direction="row" alignItems="baseline" style={{
                                                        paddingLeft: "20px",
                                                        paddingTop: `${isMobile ? "10px" : "0px"}`,
                                                        marginBottom: `${isMobile ? "-10px" : "0px"}`
                                                    }} >
                                                        <Grid container item alignItems="baseline" xs={6} >
                                                            <Grid style={{ marginRight: "8px" }}>
                                                                <Grid style={{
                                                                    fontSize: "18px",
                                                                    color: `${pallet.textColorTertiary}`,
                                                                    fontWeight: pallet.general.weight_600
                                                                }}>
                                                                    {infoList?.totalItens}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid>
                                                                <Grid item style={{
                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                    fontSize: "14px",
                                                                    fontWeight: pallet.general.weight_600,
                                                                    paddingTop: `${isMobile ? "20px" : "15px"}`,
                                                                    paddingBottom: `${isMobile ? "20px" : "30px"}`,

                                                                }}>
                                                                    {infoList?.totalItens > 1 ? "APENSADAS" : "APENSADA"}
                                                                    {props?.proposicao_integrada === false ?

                                                                        <Grid></Grid>
                                                                        :
                                                                        isMobile ? (<> <br /> [{opfiltro}] </>) :

                                                                            (<> &nbsp; [{opfiltro}]</>)

                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid container justifyContent="flex-end">
                                                                <Grid item>
                                                                    <Grid style={{ paddingRight: `${isMobile ? "21px" : "21px"}` }}>

                                                                        {props?.proposicao_integrada ?
                                                                            <ProposicaoMenuFiltroApensadas title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                                                            :
                                                                            <Grid></Grid>

                                                                        }
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{
                                                        width: "100%",
                                                        paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                                        paddingRight: `${isMobile ? "20px" : "20px"}`,
                                                        paddingTop: "20px",
                                                        paddingBottom: `${isMobile ? "20px" : "40px"}`
                                                    }}
                                                    >
                                                        <AccordionTextDataApensadasProposicao onChange={handleChangeNovaApensada} lista={lista} proposicao_integrada={props.proposicao_integrada} id_proposicao={props.id_proposicao} idPermissao={user.id_permissao}/>
                                                    </Grid>
                                                </Grid>
                                                <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                                                    <PaginationSaveState
                                                        name={'ProposicaoApensadaList'}
                                                        pages={infoList?.totalPages}
                                                        page={currentPage}
                                                        onChange={setCurrentPage}
                                                    />
                                                </Grid>
                                            </Grid>
                                            : <Grid></Grid>}
                                </Grid>


                            </Grid>

                            :

                            <Grid style={{ paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                                <Grid item xl={12} lg={12} xs={12} style={{ paddingRight: `${isMobile ? "0px" : "30px"}`, paddingLeft: `${isMobile ? "0px" : "30px"}` }}>
                                    <Grid container direction="row" alignItems="baseline" style={{
                                        paddingLeft: "30px",
                                        paddingTop: `${isMobile ? "10px" : "0px"}`,
                                        marginBottom: `${isMobile ? "-10px" : "0px"}`
                                    }} >
                                        <Grid container item alignItems="baseline" xs={6} >
                                            <Grid style={{ marginRight: "8px" }}>
                                                <Grid style={{
                                                    fontSize: "18px",
                                                    color: `${pallet.textColorTertiary}`,
                                                    fontWeight: pallet.general.weight_600
                                                }}>
                                                    {infoList?.totalItens}
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <Grid item style={{
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: "14px",
                                                    fontWeight: pallet.general.weight_600,
                                                    paddingTop: `${isMobile ? "20px" : "15px"}`,
                                                    paddingBottom: `${isMobile ? "20px" : "30px"}`,

                                                }}>
                                                    {infoList?.totalItens > 1 ? "APENSADAS" : "APENSADA"} [ {opfiltro} ]
                                                </Grid>
                                            </Grid>
                                            {infoList?.totalItens > 4 ?
                                                <Grid item xs={12} style={{ marginTop: "5px", marginBottom: "20px" }}>
                                                    <FilterQuantity
                                                        list={infoList}
                                                        currentPageSize={currentPageSize}
                                                        onChange={handleOnChangeItensPerPageSize}
                                                    />
                                                </Grid>
                                                : null}
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Grid container justifyContent="flex-end">
                                                <Grid item>
                                                    <Grid style={{ paddingRight: `${isMobile ? "21px" : "21px"}` }}>

                                                        <ProposicaoMenuFiltroApensadas title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{
                                        width: "100%",
                                        paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                        paddingRight: `${isMobile ? "20px" : "20px"}`,
                                        paddingTop: "20px",
                                        // paddingBottom: `${isMobile ? "20px" : "40px"}`
                                    }}
                                    >
                                        {(lista.length > 0) ?
                                            <AccordionTextDataApensadasProposicao onChange={handleChangeNovaApensada} lista={lista} proposicao_integrada={props.proposicao_integrada} id_proposicao={props.id_proposicao} />
                                            :
                                            <PaperContent pallet={pallet}>
                                                <EmptyErrorDocCarousel title="Esta proposição não possui apensadas" description="" />
                                            </PaperContent>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                                    <PaginationSaveState
                                        name={'ProposicaoApensadaList'}
                                        page={currentPage}
                                        pages={infoList?.totalPages}
                                        onChange={setCurrentPage}
                                    />
                                </Grid>

                            </Grid>
                    /////////////////////////////////////
                    // Codigo Renato Resultado de Con flito - Para Verificação
                    ////////////////////////////////////    
                    //         </Grid>
                    //         <Grid style={{ paddingLeft: `${isMobile ? "20px" : "20px"}`, paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: "20px", paddingBottom: "20px" }}>

                    //             <AccordionTextDataApensadasProposicao lista={lista} />

                    //         </Grid>
                    //     </Grid>

                    //     <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                    //         <PaginationSaveState
                    //             name={'ProposicaoApensadaList'}
                    //             pages={infoList?.totalPages}
                    //             page={currentPage}
                    //             onChange={setPage}
                    //         />
                    //     </Grid>
                    // </Grid>
                    // :
                    // (opfiltro === "Tudo") ?
                    //     <PaperContent pallet={pallet}>
                    //         <EmptyErrorDocCarousel title="Esta proposição não possui Apensadas" description="" />
                    //     </PaperContent>
                    :

                    <Grid style={{ paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                        <Grid item xl={12} lg={12} xs={12} style={{ paddingRight: `${isMobile ? "0px" : "30px"}`, paddingLeft: `${isMobile ? "0px" : "30px"}` }}>
                            <Grid container direction="row" alignItems="baseline" style={{
                                paddingLeft: "30px",
                                paddingTop: `${isMobile ? "10px" : "0px"}`,
                                marginBottom: `${isMobile ? "-10px" : "0px"}`
                            }} >
                                <Grid container item alignItems="baseline" xs={6} >
                                    <Grid style={{ marginRight: "8px" }}>
                                        <Grid style={{
                                            fontSize: "18px",
                                            color: `${pallet.textColorTertiary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            {infoList?.totalItens}
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <Grid item style={{
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontSize: "14px",
                                            fontWeight: pallet.general.weight_600,
                                            paddingTop: `${isMobile ? "20px" : "15px"}`,
                                            paddingBottom: `${isMobile ? "20px" : "30px"}`,

                                        }}>
                                            {infoList?.totalItens > 1 ? "APENSADAS" : "APENSADA"} [ {opfiltro} ]
                                        </Grid>
                                    </Grid>
                                    {infoList?.totalItens > 4 ?
                                        <Grid item xs={12} style={{ marginTop: "5px", marginBottom: "20px" }}>
                                            <FilterQuantity
                                                list={infoList}
                                                currentPageSize={currentPageSize}
                                                onChange={handleOnChangeItensPerPageSize}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>


                                <Grid item xs={6}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Grid style={{ paddingRight: `${isMobile ? "21px" : "21px"}` }}>

                                                <ProposicaoMenuFiltroApensadas title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{
                                width: "100%",
                                paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                paddingRight: `${isMobile ? "20px" : "20px"}`,
                                paddingTop: "20px",
                                // paddingBottom: `${isMobile ? "20px" : "40px"}`
                            }}
                            >
                                {(lista.length > 0) ?
                                    <AccordionTextDataApensadasProposicao onChange={handleChangeNovaApensada} lista={lista} proposicao_integrada={props.proposicao_integrada} id_proposicao={props.id_proposicao} />
                                    :
                                    <PaperContent pallet={pallet}>
                                        <EmptyErrorDocCarousel title="Esta proposição não possui apensadas" description="" />
                                    </PaperContent>

                                }
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                            <PaginationSaveState
                                name={'ProposicaoApensadaList'}
                                page={currentPage}
                                pages={infoList?.totalPages}
                                onChange={setCurrentPage}
                            />
                        </Grid>

                    </Grid>
            }
        </div>
    )
}