import {
    Button, Divider, Grid,ListItemText, styled,Chip, Avatar
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import {
    BootstrapDialog,
    BootstrapDialogTitle
} from "../../../../components/BootstrapDialogTitle";
import useStyles from "../../../../components/layout/Styles";
import SolidButton from "../../../../components/SolidButton";
import { useAlert } from "../../../../contexts/AlertContext";
import { usePallet } from "../../../../contexts/PalletContext";
import { EComentarioModulos, IAcaoPreparatoria, IComentario } from "../../../../models/Proposicao.Model";
import { ILoggedUser } from "../../../../models/User.Model";
import ComentarioService from "../../../../services/ComentarioService";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ButtonNoCount from "../../../../components/ButtonNoCount";
import ButtonChip from "../../../../components/ButtonChip";
import { IParlamentar } from "../../../../models/Parlamentar.Model";
import ButtonDialogConfirmation from "../../../../components/ButtonDialogConfirmation";


export default function RelatorioModal(props: {
    parlamentar: IParlamentar,
    onChange?: () => (
        id: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    closeMenu?: () => void;
    reloadLista?: () => void;
    children?: React.ReactNode;
    comentario?: IComentario;
    acao?: IAcaoPreparatoria;
    listaUsuario?: ILoggedUser[];
    resposta?: boolean;
}) {
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();

    const [idReferencia, setIdReferencia] = React.useState<number>();
    const [comentario, setComentario] = React.useState<string>();
    const [userList, setUserList] = useState<Array<number>>([]);
    const [lockSaveButton, setLockSaveButton] = useState<boolean>(true);
    const [lockedUserList, setLockedUserList] = useState<Array<number>>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [newUserList, setNewUserList] = useState<Array<number>>([]);
    const navigate = useNavigate();
    const [idComentario, setIdComenatario] = useState(0);
    const [statusDemandaDados,setStatusDemandaDados] = useState<string>('nao');
    const [statusDemandaLista,setStatusDemandaLista] = useState<string>('nao');
    const [statusAcaoInfluenciaDados,setStatusAcaoInfluenciaDados] = useState<string>('nao');
    const [statusAcaoInfluenciaLista,setStatusAcaoInfluenciaLista] = useState<string>('nao');
    const [statusProposicaoDados,setStatusProposicaoDados] = useState<string>('nao');
    const [statusProposicaoLista,setStatusProposicaoLista] = useState<string>('nao');
    const [statusComissaoLista,setStatusComissaoLista] = useState<string>('nao');
    const [statusDiscursoDados,setStatusDiscursoDados] = useState<string>('nao');
    const [statusDiscursoLista,setStatusDiscursoLista] = useState<string>('nao');
    const [statusOutrosMandatos,setStatusOutrosMandatos] = useState<string>('nao');
    const [statusOutrosLiderancas,setStatusOutrosLiderancas] = useState<string>('nao');
    const [statusOutrosFrentes,setStatusOutrosFrentes] = useState<string>('nao');
    const [statusOutrosEventos,setStatusOutrosEventos] = useState<string>('nao');
    
    const FilterTag = styled(Chip)`
    padding: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;       
    color: #160342;
    background-color: #DED3FF;
    border-radius: 39px;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    margin-right: 2px;
`;

    const [comentarioNovo, setComentarioNovo] = useState("");
    const handleClickOpenModal = () => {
        setOpenModal(true);
        console.log("Abrir", openModal);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    // useEffect(() => {
        
    //     setIdComenatario(Number(props.comentario.id));
    // }, []);

    useEffect(() => {
        SaveValidation();
    }, [comentarioNovo]);



    function SaveValidation() {
        if (comentarioNovo === "") {
            setLockSaveButton(false);
        } else setLockSaveButton(true);
    }

    const changeStatusDemandaDados = () => { 
        if (statusDemandaDados === 'sim') {
            setStatusDemandaDados('nao')
        }else{
            setStatusDemandaDados('sim')
        }
    }
    const changeStatusDemandaLista = () => { 
        if (statusDemandaLista === 'sim') {
            setStatusDemandaLista('nao')
        }else{
            setStatusDemandaLista('sim')
        }
    }
    const changeStatusAcaoOInfluenciaDados = () => { 
        if (statusAcaoInfluenciaDados === 'sim') {
            setStatusAcaoInfluenciaDados('nao')
        }else{
            setStatusAcaoInfluenciaDados('sim')
        }
    }
    const changeStatusAcaoOInfluenciaLista = () => { 
        if (statusAcaoInfluenciaLista === 'sim') {
            setStatusAcaoInfluenciaLista('nao')
        }else{
            setStatusAcaoInfluenciaLista('sim')
        }
    }
    const changeStatusProposicaoDados = () => { 
        if (statusProposicaoDados === 'sim') {
            setStatusProposicaoDados('nao')
        }else{
            setStatusProposicaoDados('sim')
        }
    }
    const changeStatusProposicaoLista = () => { 
        if (statusProposicaoLista === 'sim') {
            setStatusProposicaoLista('nao')
        }else{
            setStatusProposicaoLista('sim')
        }
    }

    const changeStatusComissaoLista = () => { 
        if (statusComissaoLista === 'sim') {
            setStatusComissaoLista('nao')
        }else{
            setStatusComissaoLista('sim')
        }
    }

    const changeStatusDiscursoDados = () => { 
        if (statusDiscursoDados === 'sim') {
            setStatusDiscursoDados('nao')
        }else{
            setStatusDiscursoDados('sim')
        }
    }

    const changeStatusDiscursoLista = () => { 
        if (statusDiscursoLista === 'sim')  {
            setStatusDiscursoLista('nao')
        }else{
            setStatusDiscursoLista('sim')
        }
    }

    const changeStatusOutrosMandatos = () => { 
        if (statusOutrosMandatos === 'sim') {
            setStatusOutrosMandatos('nao')
        }else{
            setStatusOutrosMandatos('sim')
        }
    }
    
    const changeStatusOutrosLiderancas = () => { 
        if (statusOutrosLiderancas === 'sim') {
            setStatusOutrosLiderancas('nao')
        }else{
            setStatusOutrosLiderancas('sim')
        }
    }


    const changeStatusOutrosFrentes = () => { 
        if (statusOutrosFrentes === 'sim') {
            setStatusOutrosFrentes('nao')
        }else{
            setStatusOutrosFrentes('sim')
        }
    }

    const changeStatusOutrosEventos = () => { 
        if (statusOutrosEventos === 'sim') {
            setStatusOutrosEventos('nao')
        }else{
            setStatusOutrosEventos('sim')
        }
    }





    const [autocomplete, setAutocomplete] = React.useState(false);
    const [selecionado, setSelecionado] = React.useState(0);
    const indexSelecionado = useRef(selecionado);

    useEffect(() => {
        indexSelecionado.current = selecionado;
    }, [selecionado]);









    return (
        <Grid>
            <Button
                onClick={handleClickOpenModal}
                style={{
                    // fontWeight: pallet.fontWeight.subtitle,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.general.color4}`,
                }}
            >
                        <PictureAsPdfIcon style={{
          color: `${pallet.color.tertiary.font}`,
          marginRight: "10px",
          fontFamily: `${pallet.fontFamily.general}`,
        }} />
        <ListItemText>º</ListItemText>
        GERAR .PDF
                
            </Button>

            <BootstrapDialog
                style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
                sx={{ ".MuiPaper-root": { maxWidth: "600px" } }}
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    GERAR .PDF
                </BootstrapDialogTitle>
                <Grid style={{marginBottom:"10px",
                              marginLeft:"7px",
                              fontFamily: "Montserrat", 
                              fontSize: "12px",
                              fontWeight:600,
                              paddingTop: "30px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              display:'inline-block'
                              }}>
                  <Grid style={{display: "flex",
    alignItems: "center"}}><Grid style={{display:'inline-block'}}><Avatar src={props?.parlamentar?.url_foto} /></Grid><Grid style={{display:'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;{props?.parlamentar?.nome}</Grid></Grid>
                </Grid>
                <Divider variant="fullWidth"/>
                <Grid style={{maxHeight:"400px",overflowY: "auto" }}>
                <Grid
                    container
                    style={{
                        
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >


                </Grid>
                <Grid
                    container
                    style={{
                        paddingTop: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Grid style={{marginBottom:"10px",marginLeft:"7px",fontFamily: "Montserrat", fontSize: "12px",fontWeight:600}}>
                        DEMANDAS
                    </Grid>
                    <Grid item style={{ width: "100%"}}>
                    <ButtonChip
                      onClick={changeStatusDemandaDados}
                      label={"Dados"}
                      active={statusDemandaDados}
                    />
                    <ButtonChip
                      onClick={changeStatusDemandaLista}
                      label={"Lista"}
                      active={statusDemandaLista}
                    />

                  </Grid>
                </Grid>
                <Grid
                    container
                    style={{
                        paddingTop: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Grid style={{marginBottom:"10px",marginLeft:"7px",fontFamily: "Montserrat", fontSize: "12px",fontWeight:600}}>
                        AÇÕES DE INFLUÊNCIA
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                    <ButtonChip
                      onClick={changeStatusAcaoOInfluenciaDados}
                      label={"Dados"}
                      active={statusAcaoInfluenciaDados}
                    />
                    <ButtonChip
                      onClick={changeStatusAcaoOInfluenciaLista}
                      label={"Lista"}
                      active={statusAcaoInfluenciaLista}
                    />

                  </Grid>
                </Grid>
                <Grid
                    container
                    style={{
                        paddingTop: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Grid style={{marginBottom:"10px",marginLeft:"7px",fontFamily: "Montserrat", fontSize: "12px",fontWeight:600}}>
                        PROPOSIÇÕES
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                    <ButtonChip
                      onClick={changeStatusProposicaoDados}
                      label={"Dados"}
                      active={statusProposicaoDados}
                    />
                    <ButtonChip
                      onClick={changeStatusProposicaoLista}
                      label={"Lista"}
                      active={statusProposicaoLista}
                    />

                  </Grid>
                </Grid>
                <Grid
                    container
                    style={{
                        paddingTop: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Grid style={{marginBottom:"10px",marginLeft:"7px",fontFamily: "Montserrat", fontSize: "12px",fontWeight:600}}>
                        COMISSÕES ATUAIS
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                    <ButtonChip
                      onClick={changeStatusComissaoLista}
                      label={"Lista"}
                      active={statusComissaoLista}
                    />

                  </Grid>
                </Grid>
                <Grid
                    container
                    style={{
                        paddingTop: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Grid style={{marginBottom:"10px",marginLeft:"7px",fontFamily: "Montserrat", fontSize: "12px",fontWeight:600}}>
                        DISCURSOS
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                    <ButtonChip
                      onClick={changeStatusDiscursoDados  }
                      label={"Dados"}
                      active={statusDiscursoDados}
                    />
                    <ButtonChip
                      onClick={changeStatusDiscursoLista}
                      label={"Lista"}
                      active={statusDiscursoLista}
                    />

                  </Grid>
                </Grid>
                <Grid
                    container
                    style={{
                        paddingTop: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Grid style={{marginBottom:"10px",marginLeft:"7px",fontFamily: "Montserrat", fontSize: "12px",fontWeight:600}}>
                        OUTROS
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                    <ButtonChip
                      onClick={changeStatusOutrosMandatos  }
                      label={"Mandatos"}
                      active={statusOutrosMandatos}
                    />
                       <ButtonChip
                      onClick={changeStatusOutrosLiderancas}
                      label={"Lideranças"}
                      active={statusOutrosLiderancas}
                    />
                    <ButtonChip
                      onClick={changeStatusOutrosFrentes}
                      label={"Frentes"}
                      active={statusOutrosFrentes}
                    />
                    
                     <ButtonChip
                      onClick={changeStatusOutrosEventos}
                      label={"Eventos"}
                      active={statusOutrosEventos}
                    />

                  </Grid>
                </Grid>
                </Grid>



               

                <Grid>
                    <Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                </Grid>
                <Grid container style={{ justifyContent: "flex-end" }}>
                    <Grid
                        item
                        style={{
                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                            paddingRight: `${isMobile ? "20px" : "40px"}`,
                            textAlignLast: "end",
                            padding: `${isMobile ? "10px 20px 10px 20px" : "20px 20px 20px 20px"}`,
                            display:"flex"
                        }}
                    >
                        <ButtonDialogConfirmation
                        styles={{
                            borderRadius: "6px 6px 6px 6px",
                            marginRight: "20px",
                            textTransform: "capitalize",
                            color: `${pallet.textColorPrimary}`,
                            backgroundColor: `${pallet.backgroundColorPrimary}` ,
                            fontWeight: pallet.general.weight_600,
                            fontFamily: `${pallet.general.fontFamily}`,
                            padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,
                            "&:hover": {
                                backgroundColor: `${pallet.backgroundColorPrimary}`,
                            },
                            
                        }}
                        title={"CONFIRMAÇÃO"}
                        title_button={"Cancelar"}
                        content={"Cancelar relatório ?"}
                        message={""}
                        confirmation_button={"Cancelar"}
                        confirmationEvent={() => {
                            handleClose()
                        }}
                    />
                  {/* onClick={() => window.open(`/interlocutor/relatorio/${props?.parlamentar.id}/${statusDemandaDados}/${statusDemandaLista}/${statusAcaoInfluenciaDados}/${statusAcaoInfluenciaLista}/${statusProposicaoDados}/${statusProposicaoLista}/${statusComissaoLista}/${statusDiscursoDados}/${statusDiscursoLista} `    , "_blank")} */}

                        <SolidButton 
                            onClick={() => window.open(`/interlocutor/relatorio/${props?.parlamentar.id}/${statusDemandaDados}/${statusDemandaLista}/${statusAcaoInfluenciaDados}/${statusAcaoInfluenciaLista}/${statusProposicaoDados}/${statusProposicaoLista}/${statusComissaoLista}/${statusDiscursoDados}/${statusDiscursoLista}/${statusOutrosFrentes}/${statusOutrosEventos}`, "_blank")}
                                                                                                       
                            color={`${pallet.textColorPrimary}`}
                            onChange={() => window.open(`/interlocutor/relatorio/${props?.parlamentar.id}/${statusDemandaDados}/${statusDemandaLista}/${statusAcaoInfluenciaDados}/${statusAcaoInfluenciaLista}/${statusProposicaoDados}/${statusProposicaoLista}/${statusComissaoLista}/${statusDiscursoDados}/${statusDiscursoLista}/${statusOutrosFrentes}/${statusOutrosEventos}`, "_blank")}
                                                                            

                            backgroundColor={`${pallet.backgroundColorPrimary}`}
                            fontSize={`${pallet.general.size14}`}
                            title={"GERAR PDF"}
                            disabled={false}
                        />
                    </Grid>
                </Grid>
            </BootstrapDialog>
        </Grid>
    );
}
