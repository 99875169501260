import React, { useCallback, useEffect, useState } from "react";
import Main from "../../../main/Main";
import useStyles from "../../../../components/layout/Styles";
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Menu, MenuItem, MenuProps, Paper, Typography } from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import { IProposicao } from "../../../../models/Proposicao.Model";
import { CalendarToday, MoreHoriz, Visibility } from "@material-ui/icons";
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
import { formatDate } from "../../../../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import GridAlerta from "../../../../components/GridAlerta";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import { useAlert } from "../../../../contexts/AlertContext";

export default function ProposicaoAcaoPreparatoriaLista(props: {
    index?: number,
    activeTab?: number,
    proposicao?: IProposicao,
    id_proposicao?: number,
    onChange?: () => void,
    lista?: Array<any>,

}): JSX.Element {

    const classes = useStyles();
    const navigate = useNavigate();
    const { pallet } = usePallet();
    const { id } = useParams();
    const { user } = useAuthorization();

    const LabelText = styled(Grid)`
    font-weight: ${(props => props.pallet.general.weight_600)};
    font-size: ${(props => props.pallet.general.size12)};
    color: ${(props => props.pallet.textColorQuaternary)};
    padding-bottom:${isMobile ? "5px" : "10px"};
`;

    const AccordionSum = styled(AccordionSummary)`
    border-radius: 6px 6px 0px 0px;
    justify-content: center;
    text-transform: uppercase;
    height: ${isMobile ? "auto" : "66px"};
    background: ${pallet.backGroundPageColorPrimary};
    padding: 0px !important;
    `;

    const StatusDot = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-right: 10px;
    `;

    const statusColor = (value: string) => {
        switch (value) {
            case 'Concluída':
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.yellow}`;
        }
    }

    return (
        <>
            {props.lista?.map(item => {
                return (
                    <Grid spacing={2} style={{ paddingBottom: "24px" }} >
                        <Accordion expanded={true} elevation={0}

                            style={{
                                border: "1px solid #EAEAEA",
                                maxWidth: "100%",
                                borderRadius: "6px",
                                // cursor: "pointer",

                            }}
                        >
                            <AccordionSum
                                onClick={() => navigate(`/proposicao/acao-preparatoria/${item?.id}`)}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container direction="row" alignItems="center" style={{
                                    paddingLeft: "5px",
                                }} >
                                    <Grid container item style={{ marginLeft: "20px" }}>
                                        <Grid container direction="row" >
                                            <Grid xs={12} container item alignItems="center">
                                                <Grid item>
                                                    <CalendarToday
                                                        style={{
                                                            color: `${pallet.backgroundColorPrimary}`,
                                                            height: "22px",
                                                            marginTop: "5px",
                                                            width: "20px",
                                                            marginRight: `${isMobile ? "10px" : "10px"}`,
                                                            marginLeft: `${isMobile ? "0px" : "5px"}`
                                                        }}
                                                    />
                                                </Grid>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.textColorQuaternary}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size12}`,
                                                    fontWeight: pallet.general.weight_bold,
                                                }}>
                                                    PRAZO : &nbsp;

                                                </Typography>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.textColorQuaternary}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    fontWeight: pallet.general.weight_600,
                                                }}>
                                                    <Grid item alignContent="center"
                                                        style=
                                                        {{
                                                            marginRight: `${isMobile ? "0" : "10px"}`
                                                        }}>
                                                        {formatDate(new Date(item.prazo), 'dd/MM/yyyy')}&nbsp;&nbsp;
                                                    </Grid>
                                                </Typography>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.textColorQuaternary}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size12}`,
                                                    fontWeight: pallet.general.weight_bold,
                                                }}>
                                                    | &nbsp; RESPONSÁVEL : &nbsp;

                                                </Typography>
                                                <Grid item
                                                    style=
                                                    {{
                                                        color: `${pallet.textColorTertiary}`,
                                                        marginRight: `${isMobile ? "0" : "10px"}`,
                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                        fontSize: `${pallet.general.size12}`,
                                                        fontWeight: pallet.general.weight_bold,
                                                    }}>
                                                    {item.responsavel}
                                                </Grid>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.textColorQuaternary}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size12}`,
                                                    fontWeight: pallet.general.weight_bold,
                                                }}>
                                                    | &nbsp; DATA CRIAÇÃO : &nbsp;

                                                </Typography>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.textColorQuaternary}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    fontWeight: pallet.general.weight_600,
                                                }}>
                                                    <Grid item alignContent="center"
                                                        style=
                                                        {{
                                                            marginRight: `${isMobile ? "0" : "10px"}`
                                                        }}>
                                                        {formatDate(new Date(item.criado_em), 'dd/MM/yyyy')}&nbsp;&nbsp;
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionSum>
                            <Divider />
                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                paddingLeft: `${isMobile ? "20px" : "30px"}`,
                                marginTop: `${isMobile ? "10px" : "15px"}`,
                            }}>
                                <Grid xs={12} xl={12} container item style={{
                                }}>
                                    <LabelText pallet={pallet}>
                                        STATUS DA AÇÃO PREPARATÓRIA
                                    </LabelText>
                                    <Grid container alignItems={"center"} xs={12}
                                        style={{
                                            paddingBottom: `${isMobile ? "10px" : "25px"}`,
                                        }}
                                    >
                                        <Grid>
                                            <StatusDot bg={statusColor(item?.status)} />
                                        </Grid>
                                        <Grid>
                                            {item.status}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} xl={12} container item >
                                    <LabelText pallet={pallet}>
                                        NOTAS TÉCNICAS PREENCHIDAS
                                    </LabelText>
                                </Grid>
                                <Grid container xs={12} xl={12} >
                                    <Grid item style={{
                                        color: `${pallet.textColorTertiary}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size12}`,
                                        fontWeight: pallet.general.weight_bold,
                                    }}>
                                        {item.totalNotaTecPreench} /&nbsp;
                                    </Grid>
                                    <Grid item style={{
                                        color: `${pallet.textColorQuaternary}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size12}`,
                                        fontWeight: pallet.general.weight_bold,
                                        paddingBottom: `${isMobile ? "5px" : "25px"}`,
                                    }}>
                                        {item.totalNotaTec}

                                    </Grid>

                                    {
                                        // VERIFICA SE JA FOI PREENCHIDA A NT E SE O STATUS DA AÇAO PREPARATORIA É "EM ANDAMENTO" E SE O USUARIO LOGADO NÃO É O RESPONSAVEL
                                        item?.notas?.filter(i => i.nota_tecnica_id_responsavel === user?.id &&
                                            i.nota_tecnica_status === 1 &&
                                            item?.id === i.nota_tecnica_id_acao_preparatoria).length > 0 &&
                                            item?.status === "Em andamento" &&
                                            item?.id_responsavel !== user?.id

                                            ?

                                            <Grid style={{ marginTop: "20px" }} container>
                                                <GridAlerta tipo="" descricao="TUDO CERTO! VOCÊ NÃO POSSUI PENDÊNCIAS PARA ESTA AÇÃO PREPARATÓRIA." />
                                            </Grid>

                                            :

                                            // VERIFICA SE JA FOI PREENCHIDA A NT E SE O STATUS DA AÇAO PREPARATORIA É "EM ANDAMENTO" QUANDO USUARIO LOGADO É RESPONSAVEL DA ACAO PREPARATORIA
                                            item?.notas?.filter(i => i.nota_tecnica_id_responsavel === user?.id &&
                                                i.nota_tecnica_status === 1 &&
                                                item?.id === i.nota_tecnica_id_acao_preparatoria).length > 0 &&
                                                item?.status === "Em andamento"

                                                ?

                                                <Grid style={{ marginTop: "20px" }} container>
                                                    <GridAlerta tipo="error" descricao="HÁ PENDÊNCIAS PARA ESTA AÇÃO PREPARATÓRIA." />
                                                </Grid>

                                                :

                                                // VERIFICA SE JA FOI PREENCHIDA A NT DO USUARIO LOGADO
                                                item?.notas?.filter(i => i.nota_tecnica_id_responsavel === user?.id &&
                                                    i.nota_tecnica_status === 1 &&
                                                    item?.id === i.nota_tecnica_id_acao_preparatoria).length > 0

                                                    ?

                                                    <Grid style={{ marginTop: "20px" }} container>
                                                        <GridAlerta tipo="" descricao="TUDO CERTO! VOCÊ NÃO POSSUI PENDÊNCIAS PARA ESTA AÇÃO PREPARATÓRIA." />
                                                    </Grid>

                                                    :

                                                    // VERIFICA SE NÃO FOI PREENCHIDA A NT DO USUARIO LOGADO 
                                                    item?.notas?.filter(i => i.nota_tecnica_status === 0 &&
                                                        item?.id === i.nota_tecnica_id_acao_preparatoria &&
                                                        i.nota_tecnica_id_responsavel === user?.id).length > 0

                                                        ?

                                                        <Grid style={{ marginTop: "20px" }} container>
                                                            <GridAlerta tipo="error" descricao="VOCÊ AINDA NÃO ADICIONOU UMA NOTA TÈCNICA PARA ESTA AÇÃO PREPARATÓRIA." />
                                                        </Grid>

                                                        :

                                                        // QUANDO O USUARIO LOGADO É O RESPONSAVEL DA ACAO PREPARATORIA E AINDA ESTA COM O STATUS "EM ANDAMENTO"- EXISTEM PENDENCIAS DE NT
                                                        item?.status === "Em andamento" &&
                                                            item?.id_responsavel === user?.id

                                                            ?

                                                            <Grid style={{ marginTop: "20px" }} container>
                                                                <GridAlerta tipo="error" descricao="HÁ PENDÊNCIAS PARA ESTA AÇÃO PREPARATÓRIA." />
                                                            </Grid>

                                                            :
                                                            // QUANDO O USUARIO LOGADO É O RESPONSAVEL DA ACAO PREPARATORIA E AINDA ESTA COM O STATUS "EM ANDAMENTO"- EXISTEM PENDENCIAS DE NT
                                                            item?.status === "Em andamento" &&
                                                                user?.id_permissao !== 4

                                                                ?

                                                                <Grid style={{ marginTop: "20px" }} container>
                                                                    <GridAlerta tipo="error" descricao="HÁ PENDÊNCIAS PARA ESTA AÇÃO PREPARATÓRIA." />
                                                                </Grid>

                                                                :
                                                                <Grid></Grid>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}