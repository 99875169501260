import * as React from 'react';
import styled from "styled-components";
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import FilterRightDrawer from './FilterRightDrawer';
import { Grid, ListItem, Typography } from '@mui/material';
import { readStore, writeStore } from '../../helpers/persistence';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { usePallet } from '../../contexts/PalletContext';


export interface IFilterData {
    key: string;
    label: string;
    value: any;
    data?: any;
    id?: any;
    cod?: any;
}

interface IFilterProps {
    apagaAmbitoPorCasa?: () => void,
    filtrarAction?: () => void,
    saveFilter?: (string) => void,
    name: string,
    children: React.ReactNode,
    filter: IFilterData[],
    defaultFilter?: IFilterData[],
    open: boolean,
    onToogle: () => void,
    onChangeFilter: (data: IFilterData[]) => void,
    triggerAutoFIltro?: () => void,
    limparFiltroSalvo?: () => void,
    ocultarBotaoSalvar?: boolean
}

const FilterArea = styled(Paper)`
        width: 100%;
        height: 100%;
        display: flex;
        background: #ffff;
        justify-content: left;
        flex-wrap: wrap;
        list-style: none;
        padding: 1;
        margin: 0;
`;

const FilterTag = styled(Chip)`
        padding: 10px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;       
        color: #160342;
        background-color: #DED3FF;
        border-radius: 39px;
        justify-content: center;
        align-items: center;
        margin-left: 2px;
        margin-right: 2px;
`;

const FilterText = styled(Typography)`
    align-self: center; 
    margin: 0 16px 0 0; 
    font-weight: 600;
    font-size: 14px; 
    letter-spacing: 1px;
`;

const ButtonLimpar = styled(Typography)`
    align-self: center; 
    font-size: 14px;
    color: #4B1196;
    cursor: pointer;
    margin-left: 20px; 
`;


export default function ListFilter(props: IFilterProps) {
    const { pallet } = usePallet();
    const [filtros, setFiltros] = React.useState(props.filter);

    React.useEffect(() => {
        setFiltros(
            props.filter.filter(x => x.value.length > 0)
        )
    }, [props.filter]);

    React.useEffect(() => {
        readStore(props.name).then(filter => {
            if (filter?.length) props.onChangeFilter(filter);
            else if (props.defaultFilter) props.onChangeFilter(props.defaultFilter)
            else props.onChangeFilter([])
        });
    }, []);

    React.useEffect(() => {
        if (props.filter && Array.isArray(props.filter))
            writeStore(props.name, props.filter);
    }, [props.filter]);

    const handleOnDelete = (key: string) => {
        if (key === 'casa') {
            props.apagaAmbitoPorCasa();
        };
        props.onChangeFilter(props.filter.filter(item => item.key !== key))
    };

    const clearFilter = () => {
        props.onChangeFilter([]);
        props.apagaAmbitoPorCasa();
    };

    const getPriorityLabel = (value) => {
        switch (value) {
            case '1': return "PRIORIDADE: Mínima";
            case '2': return "PRIORIDADE: Baixa";
            case '3': return "PRIORIDADE: Média";
            case '4': return "PRIORIDADE: Alta";
            case '5': return "PRIORIDADE: Máxima";
            default: return "PRIORIDADE: Indefinida";
        }
    };

    const getPositioningLabel = (value) => {
        switch (value) {
            case '1': return "POSICIONEMNTO: Contrário";
            case '2': return "POSICIONEMNTO: Contrário com emenda saneadora";
            case '3': return "POSICIONEMNTO: Neutro";
            case '4': return "POSICIONEMNTO: Favorável com emenda";
            case '5': return "POSICIONEMNTO: Favorável";
            case '6': return "POSICIONEMNTO: Favorável ao parecer do relator";
            case '7': return "POSICIONEMNTO: Favorável ao parecer do relator com emenda";
            case '8': return "POSICIONEMNTO: Contrário ao parecer do relator";
            case '9': return "POSICIONEMNTO: Contrário ao parecer do relator com emenda";
            case '99': return "POSICIONEMNTO: Pendente";
            case '0': return "POSICIONEMNTO: Indefinido";
            default: return value;
        }
    };

    return (
        <>
            {!props.filter || props.filter.length === 0
                ?
                <Grid style={{ width: '100%', height: '100%' }} >
                    {props.open ?
                        <FilterRightDrawer
                            ocultarBotaoSalvar={props.ocultarBotaoSalvar}
                            saveFilter={props.saveFilter}
                            toogle={props.onToogle}
                            open={props.open}
                            customEvent={clearFilter}
                            filter={props.filter.filter(x => x.value.length > 0)}
                        >
                            {props.children}
                        </FilterRightDrawer> : <></>
                    }

                    <Grid container style={{ width: '100%', height: '100%' }}>
                        {props.filter.filter(x => x.value.length > 0)?.map((data, index) => {
                            return (
                                <Grid item key={index}>
                                    <ListItem key={index}>
                                        <FilterTag key={index}
                                            label={data.label}
                                            onDelete={() => handleOnDelete(data.key)}
                                        />
                                    </ListItem>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                :
                <Grid style={{ width: '100%', height: '100%' }} >
                    {props.open ?
                        <FilterRightDrawer
                            ocultarBotaoSalvar={props.ocultarBotaoSalvar}
                            limparFiltroSalvo={props.limparFiltroSalvo}
                            filtrarAction={props.filtrarAction}
                            saveFilter={props.saveFilter}
                            toogle={props.onToogle}
                            open={props.open}
                            customEvent={clearFilter}
                            filter={props.filter.filter(x => x.value.length > 0)}
                        >
                            {props.children}
                        </FilterRightDrawer> : <></>
                    }
                </Grid>

            }
        </>

    );
}