import React from 'react';
import Grid from '@mui/material/Grid';
import { isMobile } from 'react-device-detect';

function ErrorMessage(props: { title?: string, description?: string }) {
  return (
    <>
      <h2>{props.title ? props.title : ""}</h2>
      <Grid style={{ textAlignLast: "center", marginBottom: "20px", marginLeft: `${isMobile ? "10px" : "0px"}` }}>{props.description ? props.description : ""}</Grid>
    </>
  );

}

export default ErrorMessage;
