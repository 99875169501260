import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Divider,
    Grid,
    ListItemText,
    Menu,
    MenuItem,
    MenuProps,
} from "@mui/material";
import useStyles from "../../../components/layout/Styles";
import { usePallet } from "../../../contexts/PalletContext";
import { useAlert } from "../../../contexts/AlertContext";
import { isMobile } from "react-device-detect";
import { styled, alpha } from "@mui/material/styles";
import styledC from 'styled-components';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";
import { MoreHoriz } from "@material-ui/icons";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import TreinamentoIAEdicao from "./TreinamentoIAEdicao";
import IAService from "../../../services/IAService";



const AccordionSum = styledC(AccordionSummary)`
  border-radius: 5px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: ${isMobile ? "2px" : "10px"};
  padding-top: ${isMobile ? "2px" : "10px"};
  height: ${isMobile ? "auto" : "68px"};
  flex-flow: row-reverse;
  background-color: ${(props) => props.pallet.backGroundPaperColorPrimary};

  .MuiAccordionSummary-content {
    flex-direction: column;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`;

const RightButton = styledC(Grid)`
  position: absolute;
  right: 20px;
`;

const NomeProposicao = styledC(Grid)`
  // position: absolute;
  color: ${(props) => props.pallet.textColorQuaternary};
  font-family: ${(props) => props.pallet.general.fontFamily};
  font-size: ${(props) => props.pallet.general.size12};
  font-weight: ${(props) => props.pallet.general.weight_600};
  text-transform: uppercase;
  cursor: pointer;
  strong {
    color: ${(props) => props.pallet.textColorTertiary};
  }
`;

const ChipStyle = styledC(Grid)`
  background-color: ${(props) => props.pallet.backGroundPageColorSecondary};
  text-transform: uppercase;
  border-radius: 100px;
  font-size: 12px;
  border: 1px solid ${(props) => props.pallet.general.color4};
  color: ${(props) => props.pallet.general.color4};
  padding: 4px 11px 4px 11px;
  /* margin-bottom: 7px; */
  line-height: 15px;
  letter-spacing: 1px;
`;

const DotStyle = styledC.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 9px;
  margin-top: 3px;
`;



const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    ".MuiListItemText-primary": {
        fontSize: 0
    },
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "0px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export default function AccordionTreinamentoIALista(props: {
    item?: any;
    id?: number;
    onClick?: () => void;
    idPermissao?: number
}): JSX.Element {
    const { pallet } = usePallet();
    const { NewAlert } = useAlert();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();


    const prioridadeColor = (value: number) => {
        switch (value) {
            case 5:
                return `${pallet.charts.red}`;
            case 4:
                return `${pallet.charts.blue}`;
            case 3:
                return `${pallet.charts.yellow}`;
            case 2:
                return `${pallet.charts.purple}`;
            case 1:
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const prioridadeLabel = (value: number) => {
        switch (value) {
            case 1:
                return 'Mínima';
            case 2:
                return 'Baixa';
            case 3:
                return 'Média';
            case 4:
                return 'Alta';
            case 5:
                return 'Máxima';
            default:
                return 'Indefinido';
        }
    };

    const posicionamentoColor = (value: number) => {
        switch (value) {
            case 1:
                return `${pallet.charts.red}`;
            case 2:
                return `${pallet.charts.orange}`;
            case 3:
                return `${pallet.charts.purple}`;
            case 4:
                return `${pallet.charts.blue}`;
            case 5:
                return `${pallet.charts.green}`;
            case 6:
                return '#C71585';
            case 7:
                return '#B8860B';
            case 8:
                return '#facb74';
            case 9:
                return '#facb74';
            case 99:
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const posicionamentoLabel = (value: number) => {
        switch (value) {
            case 1:
                return `CONTRÁRIO`;
            case 2:
                return `CONTRÁRIO COM EMENDA SANEADORA`;
            case 3:
                return `NEUTRO`;
            case 4:
                return `FAVORÁVEL COM EMENDA SANEADORA`;
            case 5:
                return `FAVORÁVEL`;
            case 6:
                return `FAVORÁVEL AO PARECER DO RELATOR`;
            case 7:
                return `FAVORÁVEL AO PARECER DO RELATOR COM EMENDA`;
            case 8:
                return `CONTRÁRIO AO PARECER DO RELATOR`;
            case 9:
                return `CONTRÁRIO AO PARECER DO RELATOR COM EMENDA`;
            case 99:
                return `PENDENTE`;
            default:
                return `INDEFINIDO`;
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };

    const deletarEvento = (id) => {
        IAService.delete(id)
            .then((res) => {
                if (res) {
                    handleClose();
                    NewAlert("success", "Texto Treinamento IA apagado com sucesso");
                    props?.onClick()
                } else {
                    NewAlert(
                        "error",
                        "Erro ao apagar, tente novamente"
                    );
                }
            })
        // .then((x) => loadAcontecimentos(props?.id_proposicao));
    };


    return (
        <>
            <Grid
                key={0}
                style={{
                    // marginBottom: "10px",
                    position: "relative",
                }}
            >
                <Accordion
                    elevation={0}
                    style={{
                        border: "1px solid #EAEAEA",
                        height: "auto",
                        // paddingBottom: "12px",
                        maxWidth: `${isMobile ? "344px" : "1153px"}`,
                        background: `${pallet.backGroundPageColorPrimary}`
                    }}
                >
                    <AccordionSum
                        pallet={pallet}
                        style={{
                            paddingLeft: `${isMobile ? "5px" : "20px"}`,
                            paddingRight: "10px",
                            background: `${pallet.backGroundPageColorPrimary}`,
                            paddingBottom: "10px"
                        }}

                        expandIcon={
                            <ArrowDropDownIcon
                                style={{
                                    color: `${pallet.general.color2}`,
                                    fontSize: `${pallet.general.size16}`,
                                }}
                            />
                        }
                        aria-controls="panel1a-content"
                        id={`panel1a-header-${props.item.id}`}
                    >
                        <Grid
                            container
                            style={{
                                marginLeft: "20px",
                                alignItems: "center",
                                display: "flex",
                                maxWidth: `${isMobile ? '225px' : '890px'}`,
                                wordBreak: "break-word",
                                whiteSpace: `${isMobile ? undefined : 'nowrap'}`,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <NomeProposicao pallet={pallet}>
                                <strong>{props?.item.apelido}</strong>
                            </NomeProposicao>
                        </Grid>
                        <Grid
                            container
                            style={{
                                paddingTop: "5px",
                                paddingLeft: "18px",
                            }}
                        >
                            <Grid style={{ marginRight: "14px" }}>
                                <ChipStyle pallet={pallet}>
                                    <Grid container>
                                        <DotStyle
                                            bg={prioridadeColor(
                                                props?.item?.prioridade
                                            )}
                                        ></DotStyle>
                                        <Grid style={{ fontSize: "10px" }}>
                                            {prioridadeLabel(props?.item?.prioridade)}
                                        </Grid>
                                    </Grid>
                                </ChipStyle>
                            </Grid>
                            <Grid>
                                <ChipStyle pallet={pallet}>
                                    <Grid container>
                                        <DotStyle
                                            bg={posicionamentoColor(
                                                props?.item?.posicionamento
                                            )}
                                        ></DotStyle>
                                        <Grid style={{ fontSize: "10px" }}>
                                            {posicionamentoLabel(props?.item?.posicionamento)}
                                        </Grid>
                                    </Grid>
                                </ChipStyle>
                            </Grid>
                        </Grid>
                    </AccordionSum>
                    <Divider style={{ marginTop: "15px" }} />

                    <AccordionDetails
                        style={{
                            fontFamily: `${pallet.general.fontFamily}`,
                            padding: "20px 25px 17px",
                            backgroundColor: 'white',
                        }}
                    >
                        <Grid style={{ fontSize: `${pallet.general.size12}`, fontWeight: pallet.general.weight_bold }}>
                            CONTEXTO
                        </Grid>
                        <Grid style={{ fontSize: `${pallet.general.size14}`, wordBreak: "break-word" }}>
                            {props?.item?.texto}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <RightButton
                    style={{
                        position: "absolute",
                        right: isMobile ? "-5px" : "20px",
                        top: isMobile ? "5px" : "13px",
                    }}
                >
                    <Grid
                        style={{
                            marginTop: `${isMobile ? "0px" : "4px"}`,
                            paddingRight: "10px",
                        }}
                    >
                        <Button
                            id={`button-${props?.item?.id}`}
                            aria-controls={
                                1 ? `mmenu-${props?.item?.id}` : undefined
                            }
                            disableElevation
                            variant="contained"
                            size="small"
                            style={{
                                background: `${pallet.backGroundPageColorPrimary}`,
                                textTransform: "capitalize",
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: pallet.fontWeight.button,
                                fontSize: `${pallet.fontSize.text_10}`,
                                color: `${pallet.color.secundary.font}`,
                                width: "25px",
                                height: "40px",
                            }}
                            onClick={(e) => handleClick(e, props?.item?.id)}
                            endIcon={
                                <Grid
                                    style={{
                                        fontSize: "30px",
                                        color: `${pallet.general.color4}`,
                                        marginTop: "9px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <MoreHoriz fontSize="inherit" />
                                </Grid>
                            }
                        ></Button>

                        <StyledMenu
                            id={`mmenu-${props?.item?.id}`}
                            MenuListProps={{
                                "aria-labelledby": `button-${props?.item?.id}`,
                            }}
                            anchorEl={anchorEl}
                            open={open === props?.item?.id}
                            onClose={handleClose}
                        >
                            <MenuItem>
                                <MenuItem>
                                    <TreinamentoIAEdicao
                                        apelido={props?.item?.apelido}
                                        prioridade={props?.item?.prioridade}
                                        posicionamento={props?.item?.posicionamento}
                                        texto={props?.item?.texto}
                                        reloadList={() => props?.onClick()}
                                        closeMenu={() => handleClose()}
                                        id={props?.item?.id}
                                        idPermissao={props.idPermissao}
                                    />
                                </MenuItem>
                            </MenuItem>
                            <Divider
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                }}
                            />
                            <MenuItem
                                style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.button,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    borderRadius: "6px",
                                    padding: "6px",
                                }}
                            >
                                <ButtonDialogConfirmation
                                    styles={{
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.fontWeight.button,
                                        fontSize: `${pallet.general.size14}`,
                                        color: `${pallet.color.tertiary.font}`,
                                        borderRadius: "6px",
                                        textTransform: "capitalize",
                                    }}
                                    title={"CONFIRMAÇÃO"}
                                    lastPath={"/ia"}
                                    // confirmationEvent={() => deletarEvento(props?.eventos?.agenda_id)}
                                    confirmationEvent={() =>props.idPermissao !== 3 ? deletarEvento(props?.item?.id) : NewAlert("error","Não é possível realizar está ação com este perfil.")}
                                    title_button={"Apagar"}
                                    confirmation_button={"Apagar"}
                                    content={"APAGAR TEXTO TREINAMENTO?"}
                                    message={"Esta ação não poderá ser desfeita."}
                                >
                                    <DeleteIcon
                                        style={{
                                            color: `${pallet.color.tertiary.font}`,
                                            marginRight: "16px",
                                            fontFamily: `${pallet.fontFamily.general}`,
                                        }}

                                    />
                                    <ListItemText>!</ListItemText>
                                </ButtonDialogConfirmation>
                            </MenuItem>
                        </StyledMenu>
                    </Grid>
                </RightButton>
            </Grid >
        </>
    );
}

