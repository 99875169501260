import PrivateRoute from '../../components/PrivateRoute';
import TemasCorporativosLista from './_components/Grupo/TemasCorporativos.Lista';
import TemasCorporativosTemaLista from './_components/Tema/TemasCorporativos.Tema.Lista';
import TCTemasCorporativosSubTemaLista from './_components/SubTema/TCTemasCorporativos.SubTema.Lista';
import TCTemasCorporativosTopicoLista from './_components/Topico/TCTemasCorporativos.Topico.Lista';
import TCCadastroTemaCorporativo from './_components/TCCadastroTemaCorporativo';




export function TemaCorporativoRoutes() {
    return (
        <>
            <PrivateRoute path="/classificacaodetemas" element={<TemasCorporativosLista />} />
            <PrivateRoute path="/classificacaodetemas/cadastrar/:idAbaActive" element={<TCCadastroTemaCorporativo />} />
            <PrivateRoute path="/classificacaodetemas/tema/:idGrupoParm" element={<TemasCorporativosTemaLista />} />
            <PrivateRoute path="/classificacaodetemas/subtema/:idTemaParm" element={<TCTemasCorporativosSubTemaLista />} />
            <PrivateRoute path="/classificacaodetemas/topico/:idSubtemaParm" element={<TCTemasCorporativosTopicoLista />} />
        </>
    );
}

