import * as http from '../helpers/http';
import { IPallet } from '../models/Pallet.Model';

class PaletContextService {
    execute = async (): Promise<IPallet> => {
        const result = await http.get('/schema');
        if (result.success) { return result.data }
        return undefined;
    };
}

export default new PaletContextService();
