import React from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import { Grid, Button, Icon, Menu, FormControl, Divider } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { usePallet } from '../../../../contexts/PalletContext';
import useStyles from '../../../../components/layout/Styles';


export default function ProposicaoMenuFiltroApensadas(props: { title: string, icon: any, onChange: (value) => void }) {
    const [selectedValue, setSelectedValue] = React.useState('a');
    const classes = useStyles();
    const { pallet } = usePallet();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const controlProps = (item: string) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    const Tudo = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Todos
            </div>
        )
    }
    const Outros = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Outros
            </div>
        )
    }
    const Apensadas = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Apensadas
            </div>
        )
    }

    const Associadas = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Associadas
            </div>
        )
    }


    return (
        <Grid>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button
                            {...bindTrigger(popupState)}
                            // onClick={openDatePicker}
                            variant="contained"
                            size="large"
                            style={{
                                textTransform: 'uppercase',
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontSize: `${pallet.general.size12}`,
                                fontWeight: pallet.general.weight_bold,
                                border: "none",
                                backgroundColor: `${pallet.backgroundColorQuintenary}`,
                                color: `${pallet.textColorTertiary}`
                            }}
                            startIcon={
                                <Icon style={{
                                    color: `${pallet.color.secundary.font}`
                                }}
                                >{props.icon}</Icon>}
                        >
                            {props.title}
                        </Button>
                        {/* {props.itens.map(item, index => {
                            <Menu {...bindMenu(popupState)} style={{ marginTop: "50px", }}>
                                <MenuItem key={index} onClick={popupState.close} style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.lighter,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                }}>{item[index]}</MenuItem>
                            </Menu>
                        })} */}


                        <Menu {...bindMenu(popupState)} >
                            <FormControl component="fieldset" onChange={props.onChange} style={{ minWidth: "200px" }}>
                                {/* <FormLabel component="legend">Gender</FormLabel> */}

                                <RadioGroup
                                    aria-label="gender"
                                    defaultValue="tudo"
                                    name="tipo_relacao"
                                    id="tipo_relacao"
                                >


                                    <FormControlLabel style={{ paddingLeft: "20px" }} value="T" control={<Radio onClick={popupState.close} sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Tudo />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value="A" control={<Radio onClick={popupState.close} sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Apensadas />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value="S" control={<Radio onClick={popupState.close} sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Associadas />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value="O" control={<Radio onClick={popupState.close} sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Outros />} />


                                </RadioGroup>
                            </FormControl>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>

        </Grid >


    );
}