import FilterQuantity from "../../../../components/filter/FilterQuantity";
import { Button } from '@material-ui/core';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Grid, Paper, Typography, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import ComboBox from "../../../../components/ComboBox";
import useStyles from "../../../../components/layout/Styles";
import { usePallet } from "../../../../contexts/PalletContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconLabelButtons from '../../../../components/ButtonIconSalvar';
import { IListInfo } from '../../../../models/IListInfo';
import IAService from "../../../../services/IAService";
import EmptyError from '../../../../components/EmptyError';
import PaginationSaveState from '../../../../components/PaginationSaveState';
import { useNavigate } from "react-router-dom";
import TemaCorporativoCard from "../Grupo/TemaCorporativoCard";
import { IFilterData } from "../../../../components/filter/ListFilter";
import { arraysEquals } from "../../../../utils/array.utils";
import TCService from "../../../../services/TCService";
import TCCorporativoSubTemaCard from "./TCCorporativoSubTemaCard";
import filtersBackgroundImage from "../../../../img/background_filters_dashboard.png";
import SummaryPanelWidget from "../../../workspace/_components/SummaryPanelWidget";
import { info } from "console";
import ButtonIconSalvar from "../../../../components/ButtonIconSalvar";
import MenuButtonIconNoText from "../../../../components/MenuButtonIconNoText";
import { MoreHoriz } from "@material-ui/icons";
import EditIcon from '@mui/icons-material/Edit'
import TCMenuButtonIconEditActiveTema from "../Tema/TCMenuButtonIconEditActive.Tema";
import EmptyErrorTemaCorporativoList from "../EmptyErrorTemaCorporativoList";

//## StyledCompenents
const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styled(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;

const ContainerFilter = styled(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;

const HeaderPaper = styled(Paper)`
  background-size: cover;
  /* background-position: center; */
  justify-content: center;
  height: 80px;
  padding: 0 15px;
`;

const HeaderContentPaper = styled(Paper)`
  justify-content: center;
  padding: 15px;
  margin-bottom: 15px;
`;

const HeaderText = styled(Typography)`
  font-weight: ${(props) => props.pallet.fontWeight.subtitle};
  font-size: ${(props) => props.pallet.fontSize.text_10};
  font-family: ${(props) => props.pallet.fontFamily.general};
  color: ${(props) => props.pallet.color.colorBase.greyText};
`;

const HeaderTextSub = styled(Typography)`
  font-weight: ${(props) => props.pallet.fontWeight.subtitle};
  font-size: ${(props) => props.pallet.fontSize.text_10};
  font-family: ${(props) => props.pallet.fontFamily.general};
  color: ${(props) => props.pallet.color.colorBase.greyText};
`;

const TitleText = styled(Typography)`
  color: ${(props) => props.pallet.textColorQuaternary};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const TitleTextSub = styled(Typography)`
  color: ${(props) => props.pallet.backgroundColorPrimary};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  font-size: 14px;  
  letter-spacing: 1px;
  text-transform: uppercase;
`;


export interface IListInfoSubTema {
  id: number,
  descricao: string,
  descricaoGrupo: string,
  idGrupo: number,
  ativo: boolean,
  totalSubTemas: number,
  totalSubTemasAtivos: number,
  totalTopicos: number,
  totalTopicosAtivos: number
}

export default function TCTemasCorporativosSubTemaList(props: { idTema: number, loading?: () => void }) {

  const { pallet } = usePallet();
  const navigate = useNavigate();

  //## Paginação
  const [infoList, setInfoList] = useState<IListInfo>({
    pageIndex: 1,
    pageSize: 8,
    pageItens: 0,
    totalItens: 0,
    totalPages: 0,
  });

  //## InfoSubTema
  const [infoSubTema, setInfoSubTema] = useState<IListInfoSubTema>({
    id: 0,
    descricao: "",
    descricaoGrupo: "",
    idGrupo: 0,
    ativo: true,
    totalSubTemas: 0,
    totalSubTemasAtivos: 0,
    totalTopicos: 0,
    totalTopicosAtivos: 0
  });

  const [currentPageSize, setCurrentPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState({ key: "visualizacao", label: "Todos", value: "T" });
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const filterName = 'TemasCorporativosSubTemaListCurrentPage';
  // const filterItens = [
  //   { key: "visualizacao", label: "Tudo", value: "T" },
  //   { key: "visualizacao", label: "Relacionados", value: "R" },
  //   { key: "visualizacao", label: "Não Relacionados", value: "NR" },
  // ]

  //## Dados
  const [lista, setLista] = useState([]);
  const [listaDescricao, setListaDescricao] = useState<string[]>([]);
  const [busca, setBusca] = useState<IFilterData[]>();
  const [descricaoParcial, setDescricaoParcial] = useState<string>("");
  const [listaInfoTema, setListaInfoTema] = useState([]);
  const [loading, setLoading] = React.useState(false);


  useEffect(() => {
    if (descricaoParcial && descricaoParcial.trim().length > 2) {
      TCService.getSubTemas(props?.idTema, currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "descricaoP", value: descricaoParcial }]))
        .then(async res => {
          if (res) {
            const data = [];
            res.data.map(item => data.push({
              key: item.subtema_id,
              label: item.subtema_descricao,
              value: item.subtema_id
            }))
            setListaDescricao(data.map(item => { return item.label }));
            setBusca(() => [...data]);
            props.loading();
          }
        });
    } else if (descricaoParcial === "") {
      loadSubTemaList();
    }
  }, [descricaoParcial]);

  useEffect(() => {
    if (currentPage > 0) loadSubTemaList();
  }, [currentPage, currentPageSize, currentFilter]);


  useEffect(() => {
    loadSubTemaList();
  }, []);

  const loadSubTemaList = () => {
    setLoading(true);
    TCService.getSubTemas(props?.idTema, currentPageSize, currentPage, JSON.stringify(currentFilter)) // force break
      .then(async (res) => {
        if (res) {
          setLista(res.data);
          setInfoList(res.listInfo);
          setInfoSubTema(res.listInfoTema);
          props.loading();
        }
      });
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  };

  //## Funções'
  const handleOnChangeItensPerPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageSize(Number(event.target.value));
  };

  const handleOnChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter: IFilterData = JSON.parse(event.target.value);
    setFilter(selectedFilter);
    setCurrentFilter([...currentFilter.filter(x => x.key !== selectedFilter.key), selectedFilter]);
  };

  const handleOnSelectDescricaoParcial = async (tema: string) => {
    const selecao = busca.filter(x => x.label === tema);
    if (selecao.length) {
      //navigate(`/tc/grupo/${selecao[0].value}`);
      await TCService.GetSubTema(selecao[0].value).then(res => {

        //setChecked(res?.data[0].grupo_ativo)
        //setNome(res?.data[0].grupo_descricao)
        //setIdRegistro(res?.data[0].grupo_id)

        if (res) {
          setLista(res.data);
          //setInfoList(res.listInfo);
          setInfoSubTema(res.listInfoTema);
        }
      });


    }
  };


  return (
    <>

      <Grid container>
        <Grid style={{ width: "100%", margin: "20px 40px" }}>
          <ComboBox
            name="filtroSubTemaCorporativo"
            itens={listaDescricao}
            onChange={setDescricaoParcial}
            onSelect={handleOnSelectDescricaoParcial}
            placeholder="Buscar"
          />
        </Grid>
        <Grid item xs={12}>
          <ContainerBody container style={{ marginTop: "20px" }}>
            {lista &&
              lista.map((item, index) => (
                <TCCorporativoSubTemaCard
                  index={index}
                  loading={loading}
                  subtema_id={item.subtema_id}
                  subtema_descricao={item.subtema_descricao}
                  subtema_seativo={item.subtema_ativo}
                  onChange={loadSubTemaList}

                />
              ))}
            {!lista.length && (
              <PaperContent pallet={pallet}>
                {/* <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" /> */}
                <EmptyErrorTemaCorporativoList title="Os itens cadastrados aparecerão aqui" tipotc="S" description="Cadastre Subtemas" />
              </PaperContent>
            )}
          </ContainerBody>

          <ContainerFooter container>
            <PaginationSaveState
              name={filterName}
              pages={infoList?.totalPages}
              page={currentPage}
              onChange={setCurrentPage}
            />
          </ContainerFooter>
        </Grid>
      </Grid>


    </>
  )
}