import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';
import useStyles from '../../components/layout/Styles';
import { AppBarCustom } from './AppBarCustom';
import { DrawerCustom } from './DrawerCustom';
import { DrawerNotificacao } from './DrawerNotificacao';

export default function Main(props: { children: React.ReactChild; }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleToggleOpen = () => setOpen(!open);

    const [openNotificacao, setOpenNotificacao] = React.useState(false);
    const handleToggleNotificacao = () => setOpenNotificacao(!openNotificacao);

    return (
        <>  

            <AppBarCustom classes={classes} open={open} handleToggleOpen={handleToggleOpen} openNotificacao={openNotificacao} toggleNotificacao={handleToggleNotificacao} />
           
            <DrawerCustom classes={classes} open={open} handleToggleOpen={handleToggleOpen} />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    {props.children}
                </Container>
            </main>
            {/* <DrawerNotificacao classes={classes} open={openNotificacao} handleToggleOpen={handleToggleNotificacao} /> */}

        </>
    )
}

Main.propTypes = {
    children: PropTypes.node,
};