import * as React from 'react';
import { Grid, Link, TextField, Typography, Button, InputAdornment, Dialog, DialogTitle, IconButton } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import { useAlert } from "../../../contexts/AlertContext";
import useStyles from '../../../components/layout/Styles';
import ParlamentarDemandaService from '../../../services/ParlamentarDemandaService';
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from '@mui/icons-material/Close';
import SelectTextFieldA from '../../../components/SelectTextFieldA';
import { CalendarToday } from '@material-ui/icons';
import InputMultilineTextField from '../../../components/InputMultilineTextField';
import InputTextField from '../../../components/InputTextField';
import { ICheckBoxNestedItem, InputDropDownCheckBoxNested } from '../../../components/CheckBoxNested';
import SolidButton from '../../../components/SolidButton';
import styled from "styled-components";
import UsuarioService from '../../../services/UsuarioService';
import { arraysEquals } from '../../../utils/array.utils';
import AnexosComponent, { EAnexoModulos } from '../../../components/Anexos';
import { IPosicionamento } from './AccordionTextDataDemandaParlamentarDetail';
import FormGroup from '@mui/material/FormGroup';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ParlamentarDemandaPosicionamentoService from '../../../services/ParlamentarDemandaPosicionamentoService';
import ImpactoFinanceiroData from '../../dashboard/_components/ImpactoFinandeiroData';
import { formatDate } from '../../../utils/dateUtils';
import ButtonIconLeft from '../../../components/ButtonIconLeft';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ParlamentarPosicionamentoSchema from '../models/ParlamentaPosicionamentoSchema';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../components/BootstrapDialogTitle';

export interface IPosicionamentoModal {
    departamento?: string,
    id: number,
    id_demanda: number,
    id_responsavel: number,
    impacto?: string,
    posicionamento?: string,
    responsavel?: string,
    status?: string,
    tipo_impacto?: number,
    observacao?: string,
    confirmacao?: string,
    data_recebimento?: Date,
}

const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
`;

const GridColumn = styled(Grid)`
  padding: ${isMobile ? "8px 4px 8px 4px" : "15px"};  
  overflow-wrap: "break-word";

`;

export default function ParlamentarDemandasPosicionamentos(
    props: {
        onChange?: () => (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
        closeMenu?: () => void,
        reloadList?: () => void,
        children?: React.ReactNode,
        posicionamento: IPosicionamentoModal,
        responsaveis: Array<number>,

    }) {

    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();

    const [idReferencia, setIdReferencia] = React.useState<number>();
    const [posicionamento, setPosicionamento] = useState<string>();
    const [tipoImpacto, setTipoImpacto] = useState<string>();
    const [impacto, setImpacto] = useState<string>();
    const [titleDialog, setTitleDialog] = useState<string>("ADICIONAR POSICIONAMENTO");
    const [observacao, setObservacao] = useState<string>();
    const [departamento, setDepartamento] = useState<Array<ICheckBoxNestedItem>>();
    const [userList, setUserList] = useState<Array<number>>([]);
    const [responsavel, setResponsavel] = useState<string>();
    const [confirmacao, setConfirmacao] = useState<boolean>(false)
    const [lockSaveButton, setLockSaveButton] = useState<boolean>(true)
    const [dataRecebimento, setDataRecebimento] = useState(Date);
    const [status, setStatus] = useState<string>()
    const [lockedUserList, setLockedUserList] = useState<Array<number>>([]);
    const [open, setOpen] = React.useState(false);
    const [newUserList, setNewUserList] = useState<Array<number>>([]);
    const [error, setError] = useState<Array<{ name: string, err: string }>>([])
    const [data, setData] = useState({});

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleEditar = () => {
        setStatus("Pendente");
        setTitleDialog("EDITAR POSICIONAMENTO")

    };

    useEffect(() => {
        SaveValidation();

    }, [posicionamento, newUserList, tipoImpacto, observacao])

    function SaveValidation() {
        if (posicionamento && tipoImpacto && observacao) {
            setLockSaveButton(false)

        }
        else if ((newUserList.length > 0) && observacao) {
            setLockSaveButton(false)
        }
        else setLockSaveButton(true)
    }

    useEffect(() => {
        if (open) {
            UsuarioService.GetByDpto().then(async res => {
                const comboItens = res.map(departamento => {
                    return {
                        id: departamento.id,
                        label: departamento.nome,
                        items: departamento.usuarios.map(usuario => {
                            return {
                                id: usuario.id,
                                label: usuario.nome,
                                value: false
                            }
                        })
                    }
                })
                setDepartamento(comboItens)
                setUserList(props.responsaveis)
                setLockedUserList(props.responsaveis)
            },
            )

            if (props.posicionamento.status === "Preenchido") {
                setTipoImpacto(String(props.posicionamento.tipo_impacto))
                setPosicionamento(String(props.posicionamento.posicionamento))
                setImpacto(String(props.posicionamento.impacto))
                setResponsavel(String(props.posicionamento.responsavel))
                setStatus(String(props.posicionamento.status))
                setObservacao(String(props.posicionamento.observacao ? props.posicionamento.observacao : ""))
                setConfirmacao(Boolean(props.posicionamento.confirmacao))
                setDataRecebimento(formatDate(new Date(props.posicionamento.data_recebimento), 'dd/MM/yyyy'))
            }
        }
    }, [open])

    const handleSetUserList = React.useCallback((data) => {
        if (!arraysEquals(userList, data.selected)) {

            setDepartamento(() => [...data.list])
            setUserList(data.selected)
            const deleteFrom = new Set(props.responsaveis)
            const newValue = data.selected.filter((user) => {
                return !deleteFrom.has(user)
            })
            setNewUserList(newValue)
        }
    }, [departamento])

    const corPosicionamento = (value: string) => {

        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }

    function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.posicionamento}
                    </Grid>
                </Grid>
            </>
        )
    }

    const corImpacto = (value: string) => {

        switch (value) {
            case 'Indiferente':
                return `${pallet.charts.lightGrey}`;
            case 'Positivo':
                return `${pallet.charts.green}`;
            case 'Negativo':
                return `${pallet.charts.red}`;
            case 'Não Mensurável':
                return `${pallet.charts.purple}`;
            default:
                return `${pallet.charts.yellow}`;
        }
    }

    function LabelImpactoHandle(props: { impacto: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corImpacto(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.impacto}
                    </Grid>
                </Grid>
            </>
        )
    }

    const posicionamentosItens = [
        { key: 0, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "Neutro" },
        { key: 1, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "Contrário com emenda saneadora" },
        { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "Contrário" },
        { key: 3, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "Favorável com emenda saneadora" },
        { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "Favorável" },
        { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "Favorável ao parecer do relator" },
        { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "Favorável ao parecer do relator com emenda" },
        { key: 7, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "Contrário ao parecer do relator" },
        { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "Contrário ao parecer do relator com emenda" },
        { key: 8, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "Pendente" },
        { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "Indefinido" },
    ]

    const impactoFinanceiroItens = [
        { key: 0, label: <LabelImpactoHandle impacto={"Não Mensurável"} bg={"Não Mensurável"} />, value: "Não Mensurável" },
        { key: 1, label: <LabelImpactoHandle impacto={"Indiferente"} bg={"Indiferente"} />, value: "Indiferente" },
        { key: 2, label: <LabelImpactoHandle impacto={"Positivo"} bg={"Positivo"} />, value: "Positivo" },
        { key: 3, label: <LabelImpactoHandle impacto={"Negativo"} bg={"Negativo"} />, value: "Negativo" },
    ]

    const handleImpactoValorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImpacto(event.target.value)
    };

    const handleJustificativaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setObservacao(event.target.value)
    };

    const handlePosicionamentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosicionamento(event.target.value)
    };

    const handleImpactoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTipoImpacto(event.target.value)
    };

    const handleConfirmacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = !!event.target.checked;
        setConfirmacao(value)
    };

    function handleAfterUpload() {
    }

    const salvarPosicionamento = () => {

        const data = {
            impacto: tipoImpacto,
            posicionamento: posicionamento,
            impactoValor: impacto,
            observacao: observacao,
            responsavel: newUserList,
            confirmacao: confirmacao,
            status: "Preenchido",
        }
        setError(() => [])
        data && ParlamentarPosicionamentoSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        ParlamentarDemandaPosicionamentoService.Update(data, props.posicionamento.id).then(res => {
            console.log("data", data);
            if (res) {
                NewAlert('success', 'Posicionamento salvo com sucesso');
                if (props.reloadList) {
                    props.reloadList();
                }
                if (props?.closeMenu) {
                    props?.closeMenu();
                }
                setOpen(false);
            } else {
                NewAlert('error', 'Verifique os campos e tente outra vez');
            }
        })
            .then(data => {

            })
    }

    const ChipDepartamento = styled(Grid)`
    background-color: #E0CDF8;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    color: ${(pallet.general.color4)};
    text-align: center;
    letter-spacing: 1px;
    padding: 8px 16px 8px 16px;
    font-weight: ${pallet.fontWeight.subtitle};
    font-size: ${pallet.general.size12};
    color: ${pallet.color.tertiary.font};
    margin: ${isMobile ? "5px 5px 5px 5px" : "30px 10px 10px 20px"};
`;

    const NomeResponsavel = styled(Grid)`
    font-size: ${pallet.general.size14};
    color: ${pallet.color.secundary.font};
    padding-left: 10px;
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    line-height: 17px;
    letter-spacing: 1px;
    margin: ${isMobile ? "5px 5px 5px 5px" : "20px 0px 0px 0px"};
`;

    const EditarButton = styled(ButtonIconLeft)`
    color: ${pallet.general.color4};
    background-color:rgba(216, 216, 216, 0.3);
    font-size: ${pallet.general.size12};
    min-height: 45px;
    border-radius: 5px;
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    letter-spacing: 1px;
    margin-left: 15px; 
    `;

    const PositiveIcon = styled(AddCircleIcon)`
    color: #4CD964;
    font-size: ${pallet.general.size18};
    font-weight: ${pallet.general.weight_600};
    margin-right: 10px;
    height: 15px;
    width: 15px;
    vertical-align: text-top;
    `;

    const NegativeIcon = styled(RemoveCircleIcon)`
    color: #F63854;
    font-size: ${pallet.general.size18};
    font-weight: ${pallet.general.weight_600};
    margin-right: 10px;
    height: 15px;
    width: 15px;
    vertical-align: text-top;
    `;


    return (
        <div style={{ width: "100%" }}>
            <Link href="#" onClick={handleClickOpen} underline="none"
                style={{
                    fontWeight: pallet.fontWeight.subtitle,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.secundary.font}`,
                }}>
                {props.children ? props.children : ''}
            </Link>

            <BootstrapDialog style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                {status !== "Preenchido" && (
                    <>
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            {titleDialog}
                        </BootstrapDialogTitle>

                        <Grid style={{ width: "100%" }}>
                            <Grid container xl={12} lg={12} xs={12} style={{ paddingTop: "20px" }}>
                                <Grid container
                                    style={{ paddingLeft: `${isMobile ? "0px" : "24px"}`, paddingRight: `${isMobile ? "0px" : "24px"}` }}
                                >
                                    <GridColumn item md={5} xs={12}>
                                        <SelectTextFieldA
                                            options={posicionamentosItens}
                                            // placeholder={"Selecione departamento ou usuário"}
                                            value={posicionamento}
                                            onChange={handlePosicionamentoChange}
                                            label={"POSICIONAMENTO DO GESTOR"}
                                            id={"responsavel-posicionamento"}
                                        />
                                    </GridColumn>
                                    <GridColumn item md={4} xs={12} >
                                        <SelectTextFieldA
                                            options={impactoFinanceiroItens}
                                            placeholder={"Selecione unidade de negócio ou usuário"}
                                            value={tipoImpacto}
                                            onChange={handleImpactoChange}
                                            label={"IMPACTO FINANCEIRO"}
                                            id={"impacto-demandas"}
                                        />
                                    </GridColumn>
                                    <GridColumn item md={3} xs={12} >
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            focused={true}
                                            value={impacto}
                                            onChange={handleImpactoValorChange}
                                            label="VALOR DO IMPACTO"
                                            id={"impacto-valor-demandas"}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AttachMoneyIcon />
                                                    </InputAdornment>
                                                ),
                                                inputMode: 'decimal',
                                                style: { fontFamily: `${pallet.general.fontFamily}` },
                                                classes: { notchedOutline: classes.notchedOutline },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: "gray"
                                                }
                                            }}
                                            style={{ width: "100%" }}
                                        />
                                    </GridColumn>
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='impacto' errors={error} />
                                    </Grid>
                                    <GridColumn item md={12} xs={12}>
                                        <InputMultilineTextField
                                            type={"text"}
                                            focused={true}
                                            maxRows={4}
                                            value={observacao}
                                            onChange={handleJustificativaChange}
                                            label="ARGUMENTOS PARA JUSTIFICAR POSICIONAMENTO"
                                            id={"justificativa-demandas"}
                                        />
                                    </GridColumn>
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='observacao' errors={error} />
                                    </Grid>
                                    <Grid>
                                        < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "30px"}`, marginBottom: `${isMobile ? "15px" : "30px"}` }} />
                                    </Grid>

                                    <Typography className={classes.heading} style={{
                                        color: `${pallet.color.secundary}`,
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontSize: `${pallet.general.size12}`,
                                        fontWeight: pallet.general.weight_600,
                                        alignItems: "center",
                                        letterSpacing: "1px",
                                        borderRadius: "2px",
                                        textTransform: "uppercase",
                                        marginLeft: `${isMobile ? "10px" : "25px"}`,
                                        marginBottom: `${isMobile ? "10px" : "10px"}`,

                                    }}>
                                        ADICIONAR ANÁLISE DE
                                    </Typography>
                                    <Grid item lg={12} xs={12}
                                        style={{
                                            paddingLeft: `${isMobile ? "8px" : "16px"}`,
                                            paddingRight: `${isMobile ? "8px" : "16px"}`
                                        }}
                                    >
                                        {departamento &&
                                            <>
                                                <InputDropDownCheckBoxNested
                                                    items={departamento}
                                                    onChange={handleSetUserList}
                                                    label={"Solicitacao de"}
                                                    selectedItems={userList}
                                                    lockedItems={lockedUserList}
                                                />
                                            </>
                                        }
                                    </Grid>
                                    <Grid style={{ marginLeft: "16px", paddingTop: "15px", }}>
                                        <FormGroup style={{ fontFamily: `${pallet.fontFamily.general}` }}>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    defaultChecked={confirmacao}
                                                    value={confirmacao}
                                                    onChange={handleConfirmacaoChange}
                                                    style={{ color: `${pallet.color.secundary.font}` }} />
                                            }
                                                label="Confirmo que o(s) diretor(es) departamental(is) estão cientes" />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "40px"}` }} />
                            </Grid>
                            <Grid style={{
                                paddingTop: `${isMobile ? "15px" : "40px"}`,
                                paddingLeft: `${isMobile ? "10px" : "24px"}`,
                                paddingRight: `${isMobile ? "0px" : "24px"}`,
                            }}>
                                <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                    <Grid style={{
                                        fontWeight: pallet.fontWeight.subtitle,
                                        fontSize: `${pallet.general.size12}`,
                                        color: `${pallet.color.tertiary.font}`,

                                    }}>
                                        ANEXOS
                                    </Grid>

                                    <Grid style={{ marginTop: "10px" }}>
                                        <Grid className="container">
                                            <AnexosComponent
                                                label=''
                                                modulo={EAnexoModulos.DemandasPosicionamento}
                                                id_referencia={props.posicionamento.id}
                                                // onAfterUpload={handleAfterUpload}
                                                canEdit={true}
                                                autosave={true}
                                            />

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid>
                            < Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                        </Grid>
                        <Grid container alignItems="center" sx={{
                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                            height: `${isMobile ? "50px" : "98px"}`,
                            borderRadius: "5px"

                        }}>
                            <Grid container>

                                <Grid item lg={12} xs={12}
                                    style=
                                    {{
                                        paddingRight: `${isMobile ? "20px" : "40px"}`,
                                        textAlignLast: "end",
                                        padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,

                                    }}>
                                    <SolidButton
                                        onClick={salvarPosicionamento}
                                        color={`${pallet.textColorPrimary}`}
                                        onChange={props?.reloadList}
                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                        fontSize={`${pallet.general.size14}`}
                                        title={"SALVAR"}
                                        disabled={lockSaveButton}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                {status === "Preenchido" && (
                    <>
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            POSICIONAMENTO
                        </BootstrapDialogTitle>
                        <Grid container>
                            <Grid xs={12} container direction={`${isMobile ? "column" : "row"}`} alignItems={"center"}
                                style={{ maxWidth: "1200px" }}
                            >
                                <Grid item >
                                    <ChipDepartamento style={{
                                    }}>
                                        {props.posicionamento.departamento}
                                    </ChipDepartamento>
                                </Grid>
                                <Grid item>
                                    <NomeResponsavel>
                                        {responsavel}
                                    </NomeResponsavel>
                                </Grid>

                                <Grid item
                                    style={{
                                        marginLeft: `${isMobile ? "" : "auto"}`,
                                        marginRight: `${isMobile ? "0" : "20px"}`,
                                        marginTop: `${isMobile ? "5px" : "15px"}`,
                                    }}
                                >
                                    <EditarButton
                                        title={"Editar"}
                                        icon={<EditIcon
                                        />}
                                        onClick={handleEditar}
                                    />
                                </Grid>
                            </Grid>
                            <Grid>
                                < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "15px"}`, marginBottom: `${isMobile ? "15px" : "15px"}` }} />
                            </Grid>
                            <Grid style={{ width: "100%" }}>
                                <Grid container xl={12} lg={12} style={{ paddingTop: "20px" }}>
                                    <Grid container>
                                        <Grid container style={{ paddingLeft: `${isMobile ? "10px" : "24px"}`, paddingRight: `${isMobile ? "10px" : "24px"}` }}>
                                            <Grid item xl={3} lg={3} xs={12}>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: `${pallet.general.size12}`,
                                                    fontWeight: pallet.general.weight_600,
                                                    alignItems: "center",
                                                    letterSpacing: "1px",
                                                    borderRadius: "2px",
                                                    textTransform: "uppercase",
                                                    marginBottom: `${isMobile ? "10px" : "15px"}`,

                                                }}>
                                                    RECEBIDO EM
                                                </Typography>
                                                <Grid display={"inline-flex"}>
                                                    <Grid item display={"inline-flex"}>
                                                        <CalendarToday style={{
                                                            color: `${pallet.general.color1}`,
                                                            height: "22px",
                                                            width: "20px",
                                                            marginRight: "10px",
                                                            marginLeft: `${isMobile ? "0px" : "0px"}`
                                                        }} />
                                                        <Grid item >
                                                            {dataRecebimento}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: `${pallet.general.size12}`,
                                                    fontWeight: pallet.general.weight_600,
                                                    alignItems: "center",
                                                    letterSpacing: "1px",
                                                    borderRadius: "2px",
                                                    textTransform: "uppercase",
                                                    marginTop: `${isMobile ? "10px" : "0px"}`,
                                                    marginBottom: `${isMobile ? "5px" : "15px"}`,

                                                }}>
                                                    POSICIONAMENTO
                                                </Typography>
                                                <Grid item
                                                    style={{
                                                        paddingLeft: `${isMobile ? "0px" : "0px"}`,
                                                        // marginRight: `${isMobile ? "5px" : "10px"}`
                                                    }}
                                                >
                                                    {posicionamentosItens.filter(item => item.value === posicionamento)[0].label}
                                                </Grid>
                                            </Grid>
                                            <Grid item xl={3} lg={3} xs={12}>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: `${pallet.general.size12}`,
                                                    fontWeight: pallet.general.weight_600,
                                                    alignItems: "center",
                                                    letterSpacing: "1px",
                                                    borderRadius: "2px",
                                                    textTransform: "uppercase",
                                                    marginTop: `${isMobile ? "10px" : "0px"}`,
                                                    marginBottom: `${isMobile ? "5px" : "15px"}`,

                                                }}>
                                                    IMPACTO FINANCEIRO
                                                </Typography>
                                                <Grid item
                                                    style={{
                                                        paddingLeft: `${isMobile ? "0px" : "0px"}`,
                                                        paddingRight: `${isMobile ? "0px" : "0px"}`
                                                    }}
                                                >
                                                    {impactoFinanceiroItens.filter(item => item.value === tipoImpacto)[0].label}
                                                </Grid>
                                            </Grid>
                                            <Grid item xl={2} lg={2} xs={12}>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: `${pallet.general.size12}`,
                                                    fontWeight: pallet.general.weight_600,
                                                    alignItems: "center",
                                                    letterSpacing: "1px",
                                                    borderRadius: "2px",
                                                    textTransform: "uppercase",
                                                    marginTop: `${isMobile ? "10px" : "0px"}`,
                                                    marginBottom: `${isMobile ? "5px" : "15px"}`,

                                                }}>
                                                    VALOR DO IMPACTO
                                                </Typography>
                                                <Grid item
                                                    style={{
                                                        paddingLeft: `${isMobile ? "0px" : "0px"}`,
                                                        // marginRight: `${isMobile ? "5px" : "0px"}`
                                                    }}
                                                >
                                                    {tipoImpacto === "Positivo" ?

                                                        (<><PositiveIcon /> {"R$"}  {impacto} </>)
                                                        :
                                                        (<><NegativeIcon /> {"R$"}  {impacto} </>)
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xl={12} lg={12} xs={12} padding={0}>
                                            < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "30px"}`, marginBottom: `${isMobile ? "15px" : "30px"}` }} />
                                        </Grid>

                                        <Grid style={{ paddingLeft: `${isMobile ? "10px" : "24px"}`, paddingRight: `${isMobile ? "10px" : "24px"}` }}>
                                            <Typography className={classes.heading} style={{
                                                color: `${pallet.color.tertiary.font}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontSize: `${pallet.general.size12}`,
                                                fontWeight: pallet.general.weight_600,
                                                alignItems: "center",
                                                letterSpacing: "1px",
                                                borderRadius: "2px",
                                                textTransform: "uppercase",
                                                marginBottom: `${isMobile ? "10px" : "15px"}`,

                                            }}>
                                                ARGUMENTO PARA JUSTIFICAR POSIÇÃO
                                            </Typography>

                                            <Grid item lg={12} xs={12}>
                                                {observacao}
                                            </Grid>
                                        </Grid>
                                        <Grid>
                                            < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "30px"}`, marginBottom: `${isMobile ? "15px" : "30px"}` }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "40px"}` }} />
                                </Grid>
                                <Grid style={{
                                    paddingTop: `${isMobile ? "15px" : "40px"}`,
                                    paddingLeft: `${isMobile ? "10px" : "24px"}`,
                                    paddingRight: `${isMobile ? "10px" : "24px"}`,
                                    paddingBottom: `${isMobile ? "0px" : "24px"}`,
                                }}>
                                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                        <Grid style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.general.size12}`,
                                            color: `${pallet.color.tertiary.font}`,

                                        }}>
                                            ANEXOS
                                        </Grid>

                                        <Grid style={{ marginTop: "10px" }}>
                                            <Grid className="container">
                                                <AnexosComponent
                                                    label=''
                                                    modulo={EAnexoModulos.DemandasPosicionamento}
                                                    id_referencia={props.posicionamento.id}
                                                    canEdit={false}
                                                    autosave={false}
                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
                }
            </BootstrapDialog >
        </div >
    );
}
