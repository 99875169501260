import React from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { usePallet } from '../../../contexts/PalletContext';
import { Grid, Paper, Checkbox } from '@mui/material';
import ButtonParlamentarSeguir from './ButtonParlamentarSeguir';
import ParlamentarCardAvatar from './ParlamentarCardAvatar';
import ParlamentarCardStatus from './ParlamentarCardStatus';
import ParlamentarCardInfo from './ParlamentarCardInfo';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useAuthorization } from '../../../contexts/AuthorizationContext';
import CircularLoading from '../../../components/CircularLoading';
import { isMobile } from "react-device-detect";
import StarIcon from '@mui/icons-material/Star';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ContainerPaper = styled(Paper)`
    border-radius: 7px;
    width: 278px;
    height: 390px;
    justify-content: center;
`;
const ContainerPaperEc = styled(Paper)`
    border-radius: 7px;
    width: 280px;
    height: 310px;
    justify-content: center;
`;

const ContainerTop = styled(Grid)`
    height: 308px;
`;

const ContentCard = styled.div`
    cursor: pointer;
`;

const ContentCardHeader = styled.div`
    height: 95px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-image: ${(props => props.bg)};
`;

const ContentCardParlamentar = styled.div`
    padding-right: 135px;
    padding-left: 15px;
    margin-top: 90px;
`;

const ContentStatus = styled(Grid)`
    min-height: 36px;
`;

const ParlamentarCard = (props: {
    item: any,
    onClick?: () => void,
    from?: String,
    index?: any,
    favoriteChangeStatus?: (e: any) => void,
    favoritadoDelete?: () => void
}) => {
    const { pallet } = usePallet();
    const navigate = useNavigate();
    const { user } = useAuthorization();
    const [starLoading, setStarLoading] = React.useState(false)


    let backgroud = pallet.color.ParlamentarQualificacao.naoClassificado;

    switch (props.item.qualificacao?.toLowerCase()) {
        case "convergente":
            backgroud = pallet.color.ParlamentarQualificacao.convergente;
            break;
        case "divergente":
            backgroud = pallet.color.ParlamentarQualificacao.divergente;
            break;
        case "neutro":
            backgroud = pallet.color.ParlamentarQualificacao.neutro;
            break;
    };

    if (user.id_permissao === 4) {
        backgroud = pallet.color.ParlamentarQualificacao.neutro;
    }

    return (
        <>
            <Grid item key={props.item.id}>
                {user.id_permissao !== 4 ?
                    <ContainerPaper>
                        <ContainerTop>
                            <ContentCard style={{ cursor: "pointer" }} >
                                <a href={`/interlocutor/${props.item.id}`}  style={{textDecoration:"none"}}>
                                <ContentCardHeader bg={backgroud}>
                                    <ParlamentarCardAvatar item={props.item} pallet={pallet} idPermissao={user.id_permissao} />
                                </ContentCardHeader>
                                <Grid item xs={12}>
                                    <ContentCardParlamentar>
                                        <Grid >
                                            <Grid item >
                                                <ContentStatus container>
                                                    <ParlamentarCardStatus item={props.item} pallet={pallet} />
                                                </ContentStatus>
                                                <ParlamentarCardInfo item={props.item} pallet={pallet} />
                                            </Grid>
                                        </Grid>
                                    </ContentCardParlamentar>
                                </Grid>
                                </a>
                            </ContentCard>
                        </ContainerTop>
                        <Grid container style={{ padding: '15px', }}>
                            {props.from === 'buscaGeral' || props.from === 'favoritados'
                                ?
                                <div
                                    key={props.index}
                                    style={{ cursor: "pointer", marginTop: "7px", marginLeft: "-8px" }}
                                    onClick={props.favoritadoDelete ? props.favoritadoDelete : props.favoriteChangeStatus}
                                >
                                    {user.id_permissao !== 3
                                        ?
                                        starLoading
                                            ?
                                            <CircularLoading
                                                positionCustom={true}
                                                size={42}
                                                top={isMobile ? "37%" : "100%"}
                                                left={isMobile ? "6%" : "100%"}
                                                zIndex={2}
                                            />
                                            :
                                            <Checkbox
                                                {...label}
                                                icon={
                                                    props.item.status_favorito === "nao_favorito"
                                                        ?
                                                        <StarBorderIcon />
                                                        :
                                                        <StarIcon />
                                                }
                                                checkedIcon={<StarIcon />}
                                                style={{ color: `${pallet.general.color2}` }}
                                            />
                                        :
                                        <Checkbox
                                            {...label}
                                            icon={
                                                props.item.status_favorito === "nao_favorito"
                                                    ?
                                                    <StarBorderIcon />
                                                    :
                                                    <StarIcon />
                                            }
                                            checkedIcon={
                                                props.item.status_favorito === "nao_favorito"
                                                    ?
                                                    <StarBorderIcon />
                                                    :
                                                    <StarIcon />
                                            }
                                            style={{ color: `${pallet.general.color2}` }}
                                        />
                                    }
                                </div>
                                :
                                null
                            }
                            <ButtonParlamentarSeguir
                                key={props.item.id}
                                onClick={props.onClick}
                                id={props.item.id}
                                seguindo={!!props.item.seguindo}
                                width={props.from === 'buscaGeral' || props.from === 'favoritados' ? '200px' : isMobile ? "85px" : "248px"}
                            />
                        </Grid>
                    </ContainerPaper>

                    :
                    <ContainerPaperEc>
                        <ContainerTop>
                            <ContentCard style={{ cursor: "pointer" }}>
                            <a href={`/interlocutor/${props.item.id}`} style={{textDecoration:"none"}}>
                                <ContentCardHeader bg={backgroud}>
                                    <ParlamentarCardAvatar item={props.item} pallet={pallet} idPermissao={user.id_permissao} />
                                </ContentCardHeader>
                                <Grid item xs={12}>
                                    <ContentCardParlamentar>
                                        <Grid >
                                            <Grid item >
                                                <ContentStatus container>
                                                    <ParlamentarCardStatus item={props.item} pallet={pallet} />
                                                </ContentStatus>
                                                <ParlamentarCardInfo item={props.item} pallet={pallet} />
                                            </Grid>
                                        </Grid>
                                    </ContentCardParlamentar>
                                </Grid>
                                </a>
                            </ContentCard>
                        </ContainerTop>
                    </ContainerPaperEc>
                }
            </Grid>
        </>
    )
}

export default ParlamentarCard;



