import React, { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect"
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { usePallet } from "../../../../contexts/PalletContext";
import useStyles from "../../../../components/layout/Styles";
import { IProposicao } from '../../../../models/Proposicao.Model';
import TabelasService from '../../../../services/TabelasService';
import SelectTextField from '../../../../components/SelectTextField';
import ProposicaoService from '../../../../services/ProposicaoService';
import { useAlert } from '../../../../contexts/AlertContext';
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import styled from 'styled-components';
import maqui from './../../../../img/maqui.gif';



export const ProposicaoConsultaClassificacaoDeTema = (props: {
    proposicao: IProposicao;
    ia: any,
    onChange: () => void;
    idPermissao: number;
}) => {
    const { getConfig } = useAuthorization();
    const { pallet } = usePallet();
    const classes = useStyles();
    const [grupoItens, setGrupoItens] = React.useState([]);
    const [temaItens, setTemaItens] = React.useState([]);
    const [subTemaItens, setSubTemaItens] = React.useState([]);
    const [topicoItens, setTopicoItens] = React.useState([]);
    const { NewAlert } = useAlert();
    const [grupo, setGrupo] = React.useState<string>(undefined);
    const [tema, setTema] = React.useState<string>(undefined);
    const [subtema, setSubtema] = React.useState<string>(props?.proposicao?.cliente?.tema_corporativo ? String(props?.proposicao?.cliente?.tema_corporativo?.subtema_id) : undefined);
    const [topico, setTopico] = React.useState<string>('');

    // Monta itens do select com o retorno da query
    const getItens = (data: { id: number, descricao: string }[]): { value: string, label: string }[] => {
        const ret = data?.map((item) => {
            return {
                "value": String(item.id),
                "label": item.descricao
            };
        });

        return ret;
    };

    React.useEffect(() => {
        setTimeout(() => {
            TabelasService.getProposicaoGrupoTemas().then(
                res => {
                    const data = getItens(res);
                    setGrupoItens(data);
                }
            )
        }, 1000);
    }, []);

    React.useEffect(() => {
        if (grupo) {
            TabelasService.getProposicaoTemasByGrupo(Number(grupo)).then(
                res => {
                    const data = getItens(res)
                    setTemaItens(data);
                }
            )
        };
    }, [grupo]);

    React.useEffect(() => {
        if (tema) {
            TabelasService.getProposicaoSubTemasByTema(Number(tema)).then(
                res => {
                    const data = getItens(res)
                    setSubTemaItens(data);
                }
            )
        };
    }, [tema]);

    React.useEffect(() => {
        if (subtema) {
            TabelasService.getProposicaoTopicosBySubtema(Number(subtema)).then(
                res => {
                    const data = getItens(res)
                    setTopicoItens(data);
                }
            )
        };
    }, [subtema]);

    React.useEffect(() => {
        if (props?.proposicao?.cliente.tema_corporativo?.subtema_id) {
            setTimeout(() => {
                TabelasService.getProposicaoTemasGeral(props?.proposicao?.cliente?.tema_corporativo?.tema_id).then(
                    res => {
                        if (res) {
                            setGrupo(String(res[0]?.id_grupo))
                            setTema(String(props?.proposicao?.cliente?.tema_corporativo?.tema_id));
                        }
                    }
                )
            }, 1000)
        }
    }, [props.proposicao?.cliente?.tema_corporativo?.subtema_id])

    React.useEffect(() => {
        setTimeout(() => {
            TabelasService.getProposicaoTemasTopico(props.proposicao.id).then(res => {
                if (res && res[0] && res[0].id_topico) {
                    setTopico(String(res[0].id_topico))
                };
            });
        }, 1000);
    }, [])

    const onChangeGrupo = (value: string) => {
        if (value) setGrupo(value);
        setTopico(null);
        setSubtema(null);
        setTema(null);
        setTopicoItens([]);
        setSubTemaItens([]);
        setTemaItens([]);
    };

    const onChangeTema = (value: string) => {
        if (value) setTema(value);
        setTopico(null);
        setSubtema(null);
        setTopicoItens([]);
        setSubTemaItens([]);
    };

    const onChangeSubtema = (value: string) => {
        if (value) {
            setSubtema(value);
            ProposicaoService.SetSubtema(props.proposicao.id, Number(value)).then(
                res => {
                    NewAlert('success', 'Classificação por subtema com sucesso.');
                    props.onChange()
                }
            )
        };

        setTopico(null);
        setTopicoItens([]);
    };

    const onChangeTopico = (value: string) => {
        if (value) {
            setTopico(value);
            ProposicaoService.SetTopico(props.proposicao.id, Number(value), Number(subtema)).then(
                res => {
                    NewAlert('success', 'Classificação por tópico com sucesso.');
                    props.onChange()
                }
            )
        };
    };

    const permissaoErro = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil.")
    };

    const DotStyle = styled.div`
        background-color: ${(props => props.bg)};
        border-radius: 50%;
        height: 8px;
        width: 8px;
        margin-right: 9px;
        margin-top: 3px;
    `;

    const ChipStyle = styled(Grid)`
        background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
        text-transform: uppercase;
        border-radius: 100px;
        font-size: 10px;
        border: 1px solid ${(props => props.pallet.general.color4)};
        color: ${(props => props.pallet.general.color4)};
        padding: 4px 11px 4px 11px;
        margin-top: 4px;
        line-height: 15px;
        letter-spacing: 1px;
    `;

    return (
        <Grid style={{
            paddingLeft: `${isMobile ? "10px" : "50px"}`,
            paddingRight: `${isMobile ? "10px" : "50px"}`,
            paddingTop: "10px",
            paddingBottom: "10px"
        }}>
            <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                        height: "10px",
                        borderRadius: "5px",
                        paddingLeft: `${isMobile ? "5px" : "20px"}`,
                        paddingRight: `${isMobile ? "5px" : "20px"}`,
                        flexFlow: "row-reverse",
                    }}
                >
                    <Typography className={classes.heading} style={{
                        color: `${pallet.textColorTertiary}`,
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size14}`,
                        fontWeight: pallet.general.weight_600,
                        marginLeft: "10px",
                    }}>
                        <Grid style={{ display: "flex" }}>CLASSIFICAÇÃO DE TEMA CORPORATIVO</Grid>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{
                    fontFamily: `${pallet.general.fontFamily}`
                }}>
                    <Grid container style={{ width: "100%" }}>
                        {getConfig()?.habIa
                            ?
                            <>
                                {props.ia?.success && props.ia?.response.classificacao
                                    ?
                                    <Grid
                                        item
                                        lg={12}
                                        xl={12}
                                        style={{
                                            fontSize: pallet.general.size12,
                                            margin: "25px 20px 20px 20px",
                                            paddingLeft: "30px",
                                            background: 'linear-gradient(135deg, #3B0E6A, #6a0dad)',
                                            color: '#fff',
                                            padding: '20px',
                                            borderRadius: '8px',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                            transition: 'transform 0.2s',
                                            position: 'relative',
                                        }}
                                    >
                                        <img
                                            src={maqui}
                                            alt="Descrição do GIF"
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                width: '200px',
                                                height: '200px',
                                                zIndex: 10,
                                            }}
                                        />
                                        <Grid
                                            style={{
                                                fontWeight: pallet.general.weight_500,
                                                fontSize: '20px',
                                                fontFamily: 'Montserrat'
                                            }}
                                        >
                                            Sugestão by Maqui
                                        </Grid>
                                        <Grid
                                            style={{ whiteSpace: "pre-wrap", fontWeight: pallet.general.weight_100 }}
                                        >
                                            <Grid style={{ marginLeft: '15px' }}>
                                                <Grid container style={{ marginTop: '20px', fontSize: pallet.general.size12 }}>
                                                    <Grid>
                                                        GRUPO -
                                                    </Grid>
                                                    <Grid style={{ fontWeight: 100, fontFamily: pallet.general.fontFamily, marginLeft: '6px' }}>
                                                        {props.ia?.response.classificacao.classificacao.split(':')[1].trim().split(' ')[0]}
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ marginTop: '15px', fontSize: pallet.general.size12 }}>
                                                    <Grid>
                                                        TEMA -
                                                    </Grid>
                                                    <Grid style={{ fontWeight: pallet.general.weight_300, fontFamily: pallet.general.fontFamily, marginLeft: '6px' }}>
                                                        {props.ia?.response.classificacao.classificacao.split(':')[2].trim().split(' ')[0]}
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ marginTop: '15px', fontSize: pallet.general.size12 }}>
                                                    <Grid>
                                                        SUBTEMA -
                                                    </Grid>
                                                    <Grid style={{ fontWeight: pallet.general.weight_300, fontFamily: pallet.general.fontFamily, marginLeft: '6px' }}>
                                                        {props.ia?.response.classificacao.classificacao.split(':')[3]}
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ marginTop: '15px', fontSize: pallet.general.size14 }}>
                                                    <Grid>
                                                        <button
                                                            style={{
                                                                marginTop: '10px',
                                                                padding: '10px 20px',
                                                                background: 'rgba(255, 255, 255, 0.2)',
                                                                color: '#fff',
                                                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                                                borderRadius: '7px',
                                                                backdropFilter: 'blur(6px)',
                                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                                                                cursor: 'pointer',
                                                                fontSize: '1em',
                                                                fontWeight: pallet.general.weight_100,
                                                                fontFamily: `${pallet.general.fontFamily}`,
                                                                transition: 'background 0.3s, transform 0.2s',
                                                                display: "flex",
                                                                justifyContent: "space-between ",
                                                            }}
                                                            onMouseEnter={(e) => (e.currentTarget.style.background = 'rgba(255, 255, 255, 0.3)')}
                                                            onMouseLeave={(e) => (e.currentTarget.style.background = 'rgba(255, 255, 255, 0.2)')}
                                                        >
                                                            <DotStyle bg={pallet.color.charts.green} style={{ marginTop: '5px' }} />
                                                            <Grid>
                                                                Criar e definir temas sugeridos. By Maqui
                                                            </Grid>
                                                        </button>
                                                    </Grid>
                                                    <Grid>
                                                        <button
                                                            style={{
                                                                marginTop: '10px',
                                                                padding: '10px 20px',
                                                                background: 'rgba(255, 255, 255, 0.2)',
                                                                color: '#fff',
                                                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                                                borderRadius: '7px',
                                                                backdropFilter: 'blur(6px)',
                                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                                                                cursor: 'pointer',
                                                                fontSize: '1em',
                                                                fontWeight: pallet.general.weight_100,
                                                                fontFamily: `${pallet.general.fontFamily}`,
                                                                transition: 'background 0.3s, transform 0.2s',
                                                                display: "flex",
                                                                justifyContent: "space-between ",
                                                                marginLeft: '15px'
                                                            }}
                                                            onMouseEnter={(e) => (e.currentTarget.style.background = 'rgba(255, 255, 255, 0.3)')}
                                                            onMouseLeave={(e) => (e.currentTarget.style.background = 'rgba(255, 255, 255, 0.2)')}
                                                        >
                                                            <DotStyle bg={pallet.color.charts.red} style={{ marginTop: '5px' }} />
                                                            <Grid>
                                                                Descartar e remover temas sugeridos
                                                            </Grid>
                                                        </button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                                }
                            </>
                            :
                            null
                        }

                        <Grid item lg={12} xs={12}
                            style={{
                                marginTop: "10px",
                                marginLeft: "20px",
                                marginBottom: "15px",
                                marginRight: "20px"
                            }}>

                            <SelectTextField
                                name="grupo-select"
                                label="GRUPO"
                                style={{ width: "100%" }}
                                itens={grupoItens}
                                value={grupo}
                                onChange={props.idPermissao !== 3 ? onChangeGrupo : permissaoErro}
                            />
                        </Grid>

                        {grupo && temaItens.length > 0
                            ?
                            <Grid item lg={12} xs={12}
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "20px",
                                    marginBottom: "15px",
                                    marginRight: "20px"
                                }}>
                                <SelectTextField
                                    name="tema-select"
                                    label="TEMA"
                                    style={{ width: "100%" }}
                                    itens={temaItens}
                                    value={tema}
                                    onChange={props.idPermissao !== 3 ? onChangeTema : permissaoErro}
                                />
                                {!tema ?
                                    <Grid style={{ color: 'red', fontSize: '10px' }}>
                                        Obrigatória a escolha do Tema
                                    </Grid>
                                    : null}
                            </Grid>
                            :
                            null
                        }

                        {tema && subTemaItens.length > 0
                            ?
                            <Grid item lg={12} xs={12}
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "20px",
                                    marginBottom: "15px",
                                    marginRight: "20px"
                                }}>
                                <SelectTextField
                                    name="subtema-select"
                                    label="SUBTEMA"
                                    style={{ width: "100%" }}
                                    itens={subTemaItens}
                                    value={subtema}
                                    onChange={props.idPermissao !== 3 ? onChangeSubtema : permissaoErro}
                                />
                                {!subtema ?
                                    <Grid style={{ color: 'red', fontSize: '10px' }}>
                                        Obrigatória a escolha de um subtema para salvar!
                                    </Grid>
                                    : null}
                            </Grid>
                            :
                            null
                        }

                        {subtema && topicoItens.length > 0
                            ?
                            <Grid item lg={12} xs={12}
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "20px",
                                    marginBottom: "15px",
                                    marginRight: "20px"
                                }}>
                                <SelectTextField
                                    name="topico-select"
                                    label="TÓPICO"
                                    style={{ width: "100%" }}
                                    itens={topicoItens}
                                    value={topico}
                                    onChange={props.idPermissao !== 3 ? onChangeTopico : permissaoErro}
                                />
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
};
