import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography, Button } from '@mui/material';
import styled from "styled-components";
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import { MoreHoriz } from "@material-ui/icons"
import { isMobile } from 'react-device-detect';
import { formatDate } from '../../../utils/dateUtils';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import { CalendarToday } from '@material-ui/icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ParlamentarDemandaService from '../../../services/ParlamentarDemandaService';
import AccordionTextDataDemandaParlamentarDetail from './AccordionTextDataDemandaParlamentarDetail';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ParlamentarDemandasEdicao from './ParlamentarDemandasEdicao';
import ButtonDialogConfirmation from '../../../components/ButtonDialogConfirmation';
import { useAlert } from '../../../contexts/AlertContext';
import { useAuthorization } from '../../../contexts/AuthorizationContext';

const StyledMenu = styled((props: MenuProps) => (
    <Menu

        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 135,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '5px 0px',
            minWidth: 140,

        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
            },
        },
    },
}));


function AccordionTextDataDemandaParlamentar(props: {
    lista: Array<{
        assunto?: string,
        autor?: string,
        conclusao?: string,
        prioridade?: string,
        criado_em?: Date,
        departamento?: string,
        objetivo?: string,
        id: number,
        id_autor: number,
        prazo: Date,
        status?: string,
        resultado?: string,
        excluidos?: boolean,
        posicionamentos?: Array<{
            departamento?: string,
            id: number,
            id_demanda: number,
            id_responsavel: number,
            impacto?: string,
            posicionamento?: string,
            responsavel?: string,
            status?: string,
            tipo_impacto?: number,
            observacao?: string,
            confirmacao?: string,
            data_recebimento?: Date,
        }>
    }>,
    onChange?: () => void,
}): JSX.Element {

    const { pallet } = usePallet();
    const classes = useStyles();

    const { NewAlert } = useAlert();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const [excluidos, setExcluidos] =useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1);


    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined)
    };

    const setPage = (event: object, page: number) => setCurrentPage(page);

    async function loadDemandas(idDemanda: number) {
        props.onChange();
    }

    const deletarDemanda = (id) => {
        ParlamentarDemandaService.Delete(id).then(res => {
                if (res) {
                    NewAlert('success', 'Demanda apagada com sucesso')
                    handleClose()
                } else {
                    NewAlert('error', 'Erro ao apagar, verifique os campos e apague novamente');
                }
            })
            .then(x => loadDemandas(id))
    }

    const corPrioridade = (value: string): string => {
        switch (value) {
            case 'Mínima':
                return `${pallet.charts.green}`;
            case 'Baixa':
                return `${pallet.charts.blue}`;
            case 'Média':
                return `${pallet.charts.yellow}`;
            case 'Alta':
                return `${pallet.charts.purple}`;
            case 'Máxima':
                return `${pallet.charts.red}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }

    const DotPrioridade = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 10px;
    margin-top: 3px;
`;

    const ChipPrioridade = styled(Grid)`
    max-width: ${isMobile ? "max-content" : "max-content"};
    place-content: center;
    background-color: ${pallet.backgroundColorSecondary};
    color: #9B9B9B;
    text-transform: uppercase;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${pallet.fontFamily.general};
    font-size: ${pallet.general.size12};
    padding: 5px 8px 5px 8px;
    border: 1px solid #D8D8D8;
    line-height: 15px;
    letter-spacing: 1px;
    font-weight: ${pallet.general.weight_600};
    box-sizing: border-box;  
    margin-bottom: ${isMobile ? "5px" : "5px"};
    margin-top: ${isMobile ? "0px" : "10px"};    
    margin-right: ${isMobile ? "30px" : "10px"};
    
    `;


    const ChipStatus = styled(Grid)`
    max-width: ${isMobile ? "max-content" : "max-content"};
    background-color: ${pallet.backgroundColorSecondary};
    color: #9B9B9B;
    text-transform: uppercase;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${pallet.fontFamily.general};
    font-size: ${pallet.general.size12};
    padding: 5px 8px 5px 8px;
    border: 1px solid #D8D8D8;
    line-height: 15px;
    letter-spacing: 1px;
    font-weight: ${pallet.general.weight_600};
    box-sizing: border-box;  
    margin-bottom: ${isMobile ? "5px" : "5px"};
    margin-top: ${isMobile ? "0px" : "10px"};    
    margin-right: ${isMobile ? "30px" : "10px"};
    `;


    const Assunto = styled.div`
    font-size: ${pallet.general.size12};
    color: ${pallet.color.secundary.font};
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    align-self: center;
    max-height: ${isMobile ? "80px" : "none"};
    max-width: ${isMobile ? "200px" : "550px"}; 
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    margin-left: ${isMobile ? "10px" : "0px"};
    `;

    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    justify-content: center;
    text-transform: uppercase;
    padding-top: 10px;
    height: ${isMobile ? "125px" : "90px"};
    flex-flow: row-reverse;
    background: ${pallet.backGroundPageColorPrimary};
    padding-left: ${isMobile ? "0px" : "20px"};

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
    `;

    const { user } = useAuthorization();

    return (
        <>
            {props.lista?.map(item => {
                return (
                    <Grid key={0} >
                        <Grid
                            textAlign={"end"}
                            style={{ position: "relative", paddingBottom: "24px" }}
                        >

                            <Button
                                disableElevation
                                variant="contained"
                                size="medium"
                                style={{
                                    backgroundColor: `${pallet.backgroundColorTertiary}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.lighter,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    color: `${pallet.color.quaternary.font}`,
                                    borderRadius: "6px",
                                    width: "25px",
                                    position: "absolute",
                                    top: "60px",
                                    height: "20px",
                                    right: "5px",
                                    zIndex: 10,
                                }}
                                onClick={(e) => handleClick(e, item.id)}
                                endIcon={<Grid style={{ fontSize: "30px", marginTop: "9px", marginRight: "10px" }}>
                                    <MoreHoriz fontSize='inherit' />
                                </Grid>
                                }
                            >
                            </Button>
                            <StyledMenu style={{
                                border: "1px solid lightgrey",
                                borderRadius: "6px",
                            }}
                                id={`mmenu-${item.id}`}
                                MenuListProps={{
                                    'aria-labelledby': `button-${item.id}`,
                                }}
                                anchorEl={anchorEl}
                                open={open === item.id}
                                onClose={handleClose}
                            >
                                <MenuItem style={{ borderRadius: "6px", minHeight: "45px" }}>
                                    <ParlamentarDemandasEdicao
                                        idDemanda={item.id}
                                        reloadList={() => loadDemandas(item.id)}
                                        closeMenu={() => handleClose()}
                                        idPermissao ={user.id_permissao}
                                    />
                                </MenuItem>
                                <Grid>
                                    < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "2px" : "5px"}`, marginBottom: `${isMobile ? "2px" : "5px"}` }} />
                                </Grid>
                                <MenuItem
                                    style={{
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.fontWeight.button,
                                        fontSize: `${pallet.general.size14}`,
                                        color: `${pallet.color.tertiary.font}`,
                                        borderRadius: "6px",
                                        padding: "7px",

                                    }}>

                                    <ButtonDialogConfirmation
                                        styles={{
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.fontWeight.button,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            borderRadius: "6px 6px 0px 0px",
                                            textTransform: "capitalize"
                                        }}
                                        title={"CONFIRMAÇÃO"}
                                        title_button={"Apagar"}
                                        confirmation_button={"Apagar"}
                                        content={"APAGAR DEMANDA?"}
                                        message={"Esta ação não poderá ser desfeita."}
                                        confirmationEvent={() => user.id_permissao !== 3 ? deletarDemanda(item.id) :         NewAlert(
                                            "error",
                                            "Não é possível realizar está ação com este perfil.")}

                                    >
                                        <DeleteIcon style={{
                                            color: `${pallet.color.tertiary.font}`,
                                            marginRight: "16px",
                                            fontFamily: `${pallet.fontFamily.general}`,
                                        }} />

                                    </ButtonDialogConfirmation>
                                </MenuItem>
                            </StyledMenu>
                        </Grid >

                        <Grid >
                            <Accordion elevation={0}
                                style={{
                                    border: "1px solid #EAEAEA",
                                    zIndex: 9,
                                    maxWidth: "100%",
                                }}
                            >
                                <AccordionSum expandIcon={<ArrowDropDownIcon
                                    style={{
                                        marginLeft: `${isMobile ? "0px" : "0px"}`,
                                        color: `${pallet.general.color2}`,
                                        fontSize: `${pallet.general.size16}`,

                                    }}
                                />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid container direction="row" alignItems="center" style={{
                                        paddingLeft: "5px",
                                        paddingTop: `${isMobile ? "0" : "10px"}`,
                                        marginBottom: `${isMobile ? "-10px" : "0px"}`
                                    }} >
                                        <Grid container item style={{ marginLeft: "20px" }}>
                                            <Grid container direction="row" >
                                                <Grid xs={12} container item alignItems="flex-start">
                                                    <Grid item>
                                                        <CalendarToday style={{ color: `${pallet.backgroundColorPrimary}`, height: "22px", width: "20px", marginRight: `${isMobile ? "10px" : "10px"}`, marginTop: "3px", marginLeft: `${isMobile ? "0px" : "5px"}` }} />
                                                    </Grid>
                                                    <Typography className={classes.heading} style={{
                                                        color: `${pallet.textColorSecondary}`,
                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                        fontSize: `${pallet.general.size14}`,
                                                        fontWeight: pallet.general.weight_600,
                                                    }}>
                                                        <Grid item alignContent="center"
                                                            style=
                                                            {{
                                                                marginTop: "5px",
                                                                marginRight: `${isMobile ? "0" : "10px"}`
                                                            }}>
                                                            {formatDate(new Date(item.prazo), 'dd/MM/yyyy')}
                                                        </Grid>
                                                    </Typography>
                                                    <Assunto item >
                                                        {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                        {item.assunto}
                                                    </Assunto>
                                                </Grid>
                                                <Grid container item alignSelf="center" >
                                                    <Typography style={{
                                                        color: `${pallet.textColorTertiary}`,
                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                        fontSize: `${pallet.general.size12}`,
                                                        fontWeight: pallet.general.weight_600,
                                                        lineHeight: "15px",
                                                        letterSpacing: "1px",
                                                        marginLeft: "20px",
                                                        paddingBottom: `${isMobile ? "8px" : "0px"}`
                                                    }}>
                                                    </Typography>

                                                </Grid>
                                                <Grid xs={12} xl={12} container item style={{
                                                    paddingBottom: `${isMobile ? "5px" : "10px"}`
                                                }}>
                                                    <ChipStatus xs={12}>
                                                        {item.status}
                                                    </ChipStatus>
                                                    <ChipPrioridade xs={12} pallet={pallet}>
                                                        <Grid container >
                                                            <DotPrioridade bg={corPrioridade(item.prioridade)}>
                                                            </DotPrioridade>
                                                            <Grid>
                                                                {item.prioridade}
                                                            </Grid>
                                                        </Grid>
                                                    </ChipPrioridade>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionSum>

                                <Divider />
                                <AccordionDetails style={{
                                    fontFamily: `${pallet.general.fontFamily}`
                                }}>
                                    <Grid>

                                        <AccordionTextDataDemandaParlamentarDetail
                                            listaPocionamento={item.posicionamentos}
                                            assunto={item.assunto}
                                            autor={item.autor}
                                            departamento={item.departamento}
                                            objetivo={item.objetivo}
                                            idRef={item.id}
                                            resultado={item.resultado}
                                            reloadList={() => loadDemandas(item.id)}
                                            closeMenu={() => handleClose()}
                                        />
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataDemandaParlamentar;
