import { Avatar, Button, Divider, Grid, InputAdornment, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styleds from "styled-components";
import ComboBox from "../../../components/ComboBox";
import { usePallet } from "../../../contexts/PalletContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconLabelButtons from '../../../components/ButtonIconSalvar';
import { IListInfo } from '../../../models/IListInfo';
import EmptyError from '../../../components/EmptyError';
import PaginationSaveState from '../../../components/PaginationSaveState';
import { useNavigate } from "react-router-dom";
import TemaCorporativoCard from "../../temas_corporativos/_components/Grupo/TemaCorporativoCard";
import { IFilterData } from "../../../components/filter/ListFilter";
import TCService from "../../../services/TCService";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import ParlamentarMenuFiltroTipoComissao from "../../../components/ParlamentarMenuFiltroTipoComissao";
import FilterQuantity from "../../../components/filter/FilterQuantity";
import { Visibility } from "@material-ui/icons";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UsuarioService from "../../../services/UsuarioService";
import GerUsuariosMenuFiltroAtivoInativo from "./GerUsuariosMenuFiltroAtivoInativo";
import EditIcon from '@mui/icons-material/Edit';
import GerUsuariosCadastro from "./GerUsuariosCadastro";
import GerDepartamentosCadastro from "./GerDepartamentosCadastro";
import GerUsuariosEdicaoLista from "./GerUsuariosEdicaoLista";
import ImageLoaderList from "../../../components/ImageLoaderList";
import { useAuthorization } from "../../../contexts/AuthorizationContext";
import Menu, { MenuProps } from "@mui/material/Menu";
import {styled, alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import GerUsuariosEdicaoPassWord from "./GerUsuariosEdicaoPassWord";
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';
import useStyles from "../../../components/layout/Styles";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
      elevation={0}
      anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
      }}
      transformOrigin={{
          vertical: "top",
          horizontal: "right",
      }}
      {...props}
  />
))(({ theme }) => ({
  ".MuiListItemText-primary": {
      fontSize: 0
  },
  "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(0),
      minWidth: 180,
      color:
          theme.palette.mode === "light"
              ? "rgb(55, 65, 81)"
              : theme.palette.grey[300],
      boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
          padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
          "& .MuiSvgIcon-root": {
              fontSize: 18,
              color: theme.palette.text.secondary,
              marginRight: theme.spacing(1.5),
          },
          "&:active": {
              backgroundColor: alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity
              ),
          },
      },
  },
}));


//## StyledCompenents
const FilterArea = styleds(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styleds(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;

const ContainerFilter = styleds(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;

const ContainerBody = styleds(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styleds(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styleds(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;
export default function GerUsuariosTabsUsuarios(props: { id_user: number, index: number, activeTab: number,onChange: () => void }) {
  //export default function TCTemasCorporativosGrupoList() {
  const { pallet } = usePallet();
  const navigate = useNavigate();

  //## Paginação
  const [infoList, setInfoList] = useState<IListInfo>({
    pageIndex: 1,
    pageSize: 8,
    pageItens: 0,
    totalItens: 0,
    totalPages: 0,
  });
  const [currentPageSize, setCurrentPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState([{ key: "situacao", label: "Todos", value: "T" }]);
  //const [filtro, setFiltro] = useState<string>('T');
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const filterName = 'TemasRelacionadosListCurrentPage';
  // const filterItens = [
  //   { key: "visualizacao", label: "Tudo", value: "T" },
  //   { key: "visualizacao", label: "Relacionados", value: "R" },
  //   { key: "visualizacao", label: "Não Relacionados", value: "NR" },
  // ]

  const classes = useStyles();

  //## Dados
  const [lista, setLista] = useState([]);
  const [listaDescricao, setListaDescricao] = useState<string[]>([]);
  const [busca, setBusca] = useState<IFilterData[]>();
  const [descricaoParcial, setDescricaoParcial] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState();
  const [selectedInput, setSelectedInput] = React.useState('');
  const [value, setValue] = React.useState('');

  const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
    setOpen(id);
    setAnchorEl(event.currentTarget);
};

  // useEffect(() => {
  //   if (descricaoParcial && descricaoParcial.trim().length > 2) {
  //     UsuarioService.getUsuarios(currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "Descrição", value: descricaoParcial }]))
  //       .then(async res => {
  //         if (res) {
  //           const data = [];
  //           res.data.data.map(item => data.push({
  //             key: item.id,
  //             //label: item.descricao + (item.casa === 1 ? ' [Senado Federal]' : (item.casa === 2 ? ' [Câmara dos Deputados]' : '')),
  //             label: item.nome,
  //             value: item.id
  //           }))

  //           setListaDescricao(data.map(item => { return item.label }));
  //           // setListaDescricao(() => [...data]);
  //           setBusca(() => [...data]);
  //         }
  //       });
  //   } else if (descricaoParcial === "") {
  //     loadUserList();
  //   }
  // }, [descricaoParcial]);

  const descFiltro = (value: string) => {
    switch (value) {
      case "T":
        return "[ Todos ]";
      case "I":
        return "[ Inativo ]";
      case "A":
        return "[ Ativo ]";
    }
  }

  const [opfiltro, setOpfiltro] = useState(descFiltro("T"));

  useEffect(() => {
    if (currentPage > 0) loadUserList();
  }, [currentPage, currentPageSize, currentFilter, filter]);
  const { user } = useAuthorization();
  const loadUserList = () => {

    //const filter = currentFilter ? currentFilter.map(item => { return { key: item.key, label: item.label, value: item.value } }) : [];    
    const f = filter ? filter.map(item => { return { key: item.key, label: item.label, value: item.value } }) : [];

    //==>const f = [{ key: 'tipo', value: filtro }]

    if (descricaoParcial && descricaoParcial.trim() !== "") {
      f.push({ key: 'descricaoParcial', label: 'Descrição', value: descricaoParcial });
      //==>setNome(() => undefined)
    }


    UsuarioService.getUsuarios(currentPageSize, currentPage, JSON.stringify(f)) // force break --JSON.stringify(currentFilter)
      .then(async (res) => {
        // console.log("Usuario resUUUUUUUUUUUU.........", res)
        if (res) {
          setLista(res.data.data);
          setInfoList(res.listInfo);
        }
      });
  };

  //## Funções'
  const handleOnChangeItensPerPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageSize(Number(event.target.value));
  };

  const handleOnChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter: IFilterData = JSON.parse(event.target.value);
    //setFilter(selectedFilter);
    setCurrentFilter([...currentFilter.filter(x => x.key !== selectedFilter.key), selectedFilter]);
  };

  const handleOnSelectDescricaoParcial = async (descNome: string) => {
    const selecao = busca.filter(x => x.label === descNome);


    if (selecao.length) {
      //navigate(`/tc/grupo/${selecao[0].value}`);

      /////////////////////////////////
      //Filtra por Descricao Parcial

      await UsuarioService.Get(selecao[0].value).then(res => {

        //setChecked(res?.data[0].grupo_ativo)
        //setNome(res?.data[0].grupo_descricao)
        //setIdRegistro(res?.data[0].grupo_id)

        if (res) {
          setLista(res);

          //setInfoList(res.listInfo);
        }
      });


    }
  };

  const setFiltro = (f: any) => {
    setFilter([{ key: 'situacao', label: 'Situação', value: f.target.value }]);
    setOpfiltro(descFiltro(f.target.value));
  }

  
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(undefined);
};


const handleSelectChange = (event) => {
  setValue('');
  setSelectedInput(event.target.value);
};


const pressBuscaUsuario = (event: any) => {
  if (event?.key === 'Enter') {
    if(event.target.value) {

      UsuarioService.getUsuarios(currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "Descrição", value: event.target.value }]))
      .then(async res => {
        if (res) {
          setLista(res.data.data);
          setInfoList(res.listInfo);
          
        }
      });

    }else{

      UsuarioService.getUsuarios(currentPageSize, currentPage, '') // force break --JSON.stringify(currentFilter)
      .then(async (res) => {
        // console.log("Usuario resUUUUUUUUUUUU.........", res)
        if (res) {
          setLista(res.data.data);
          setInfoList(res.listInfo);
        }
      });

    }
  }
}

  return (
    <>
      <ContainerHead container>
        {/* <Grid container>
        </Grid>
        <Grid
          style={{
            width: isMobile ? "90%" : "100%",
          }}
        /> */}

        <Grid container direction="row" alignItems="baseline" style={{
          paddingLeft: `${isMobile ? "10px" : "44px"}`,
          paddingTop: `${isMobile ? "10px" : "0px"}`,
          marginBottom: `${isMobile ? "-10px" : "0px"}`
        }} >
          <Grid container item xs={6}>
            <Grid style={{ marginRight: "8px" }}>
              <Grid style={{
                color: `${pallet.textColorTertiary}`,
                fontWeight: pallet.general.weight_600
              }}>
                {infoList?.totalItens}
              </Grid>
            </Grid>

            <Grid>
              <Grid item style={{
                fontFamily: `${pallet.fontFamily.general}`,
                fontSize: "14px",
                fontWeight: pallet.general.weight_600,
                paddingTop: "2px",
                display: isMobile ? "block" : "flex"
              }}>
                {infoList?.totalItens > 1 ? "REGISTROS" : "REGISTRO"} <Grid style={{
                  color: `${pallet.textColorTertiary}`,
                  fontWeight: pallet.general.weight_600
                }}> {opfiltro}</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent= {isMobile ? "center" : "flex-end"}  style={{ paddingRight: `${isMobile ? "21px" : "39px"}` }}>
              <Grid item style={{ paddingRight: `${isMobile ? "5px" : "10px"}`,marginBottom: `${isMobile ? "10px" : "0px"}` }}>
                <GerUsuariosMenuFiltroAtivoInativo title={"Visualizar"} onChange={setFiltro} icon={<Visibility />} />
              </Grid>
              <Grid item>
                {/* <ParlamentarMenuFiltroTipoComissao title={"Cadastrar"} onChange={setFilter} icon={<Visibility />} /> */}
                {/* <ButtonIconSalvar
                  onClick={() => navigate('/tc/cadastrar/G')}
                  tipo={"cadastrar_grupo"}
                  backgroundColor={pallet.backgroundColorPrimary}
                  border={"1px solid lightgrey"}
                  fontColor={pallet.textColorPrimary}
                  fontSize={pallet.general.size12}
                  icon={<PersonAddIcon />}
                  title={"CADASTRAR"}
                /> */}
                <GerUsuariosCadastro onChange={loadUserList} idPermissao={user.id_permissao}/>
                {/* <GerDepartamentosCadastro/> */}
               

              </Grid>
            </Grid>
          </Grid>

        </Grid>

        {/* <ContainerFilter style={{  }}>
          <FilterArea>
            <Grid style={{ width: `${isMobile ? "70%" : "65%"}`, display: "flex", alignItems: "center" }}>              
              <strong>
                <span style={{ color: pallet.general.color1 }}>
                  {infoList?.totalItens ? infoList?.totalItens : "1"}
                </span> Grupo(s)
              </strong>
            </Grid>
            <Grid style={{ width: `${isMobile ? "30%" : "35%"}`, display: "flex", justifyContent: "flex-end" }}>
              
              <ButtonIconSalvar
                onClick={() => navigate('/tc/cadastrar/G')}
                tipo={"cadastrar_grupo"}
                backgroundColor={pallet.backgroundColorPrimary}                                
                border={"1px solid lightgrey"}
                fontColor={pallet.textColorPrimary}
                fontSize={pallet.general.size12}
                icon={""}
                title={"CADASTRAR"}
              />

            </Grid>
          </FilterArea>
        </ContainerFilter> */}
        <Grid style={{ width: "100%", margin: "20px 40px" }}>
          {/* <ComboBox
            name="filtroTCorporativo"
            itens={listaDescricao}
            onChange={setDescricaoParcial}
            onSelect={handleOnSelectDescricaoParcial}
            placeholder="Buscar"
          /> */}


                <TextField
                  // placeholder={`Digite${selectedInput === 'buscaGeral' ? ' + ENTER' : ' a proposição + ENTER'}`}
                  placeholder={`Digite + ENTER`}
                  fullWidth
                  variant="outlined"
                  defaultValue={value}
                  onKeyPress={pressBuscaUsuario}
                  style={{ width: isMobile ? '250px' : '400px', display: 'inline-block' }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    },
                    style: {
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size14}`,
                      fontWeight: pallet.general.weight_100,
                      background: `${pallet.backgroundColorSecondary}`
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

        </Grid>
      </ContainerHead>
      <ContainerBody container style={{ marginTop: "20px" }}>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>NOME</TableCell>
                <TableCell align="left">LOGIN</TableCell>
                <TableCell align="left">E-MAIL</TableCell>
                <TableCell align="left">UNIDADE DE NEGÓCIO</TableCell>
                <TableCell align="left">PERMISSÃO</TableCell>
                <TableCell align="left">STATUS</TableCell>
                <TableCell align="left">DATA EXPIRAÇÃO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
 
              {
               lista.map((resultado, index) => {return (

                
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                  <Button
                                                            id={`button-${resultado.id}`}
                                                            aria-controls={
                                                                anchorEl ? `mmenu-${resultado.id}` : undefined
                                                            }
                                    disableElevation
                                    variant="contained"
                                    size="large"
                                    style={{
                                       
                                        backgroundColor: `${pallet.general.color3}`,
                                        textTransform: "capitalize",
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.fontWeight.button,
                                        fontSize: `${pallet.fontSize.smallButton}`,
                                        color: `${pallet.color.secundary.font}`,
                                        width: "10px",
                                        height: "40px",
                                    }}
                                    onClick={(e) => handleClick(e, resultado.id)}
                                    endIcon={
                                        <Grid
                                            style={{
                                               
                                                fontSize: "30px",
                                                color: `${pallet.general.color4}`,
                                                marginTop: "9px",
                                                marginRight: "5px",
                                            }}
                                        >
                                          <EditIcon style={{ fontSize: "15", color: "grey" }} />
                                        </Grid>
                                    }
                                ></Button>

                                <StyledMenu
                                    id={`mmenu`}
                                    MenuListProps={{
                                        "aria-labelledby": `button`,
                                    }}
                                    anchorEl={anchorEl}
                                    open={open === resultado.id}
                                    onClose={handleClose}
                                >
                                    <MenuItem>
                                    <GerUsuariosEdicaoLista 
                                    reloadList={loadUserList}
                                    id_usuario={resultado.id}
                                     closeMenu={() => handleClose()}
                                     
                                     
                                     />

                                    </MenuItem>
                                    <Divider
                                        style={{
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                        }}
                                    />

                                    <MenuItem>
                                        <GerUsuariosEdicaoPassWord
                                            usuario={resultado}
                                            closeMenu={() => handleClose()}                                            
                                        />                                        
                                    </MenuItem>

                                </StyledMenu>
                        </TableCell>
                  <TableCell align="left">
                    <Grid style={{ marginLeft: `${isMobile ? "5px" : "1px"}` }}>
                      {/* <Avatar src={row.arquivo} /> */}
                      <ImageLoaderList
                        src={resultado.arquivo}
                        pallet={pallet}
                        canEdit={false}
                    />
                    </Grid>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    
                    {resultado.nome}
                  </TableCell>
                  <TableCell align="left">{resultado?.login}</TableCell>
                  <TableCell align="left">{resultado?.email}</TableCell>
                  <TableCell align="left">{resultado?.dep_nome}</TableCell>
                  <TableCell align="left">{resultado?.permissao}</TableCell>
                  <TableCell align="left">{(resultado?.status === true ? "Ativo" : "Inativo")}</TableCell>
                  <TableCell align="left">{moment(resultado?.ts_expiracao).format("DD/MM/YYYY")}</TableCell>
                  
                </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>

        {lista && !lista.length && (
          <PaperContent pallet={pallet}>
            <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" />
          </PaperContent>
        )}
      </ContainerBody>
      <ContainerFooter container>
        <PaginationSaveState
          name={filterName}
          pages={infoList?.totalPages}
          page={currentPage}
          onChange={setCurrentPage}
        />
      </ContainerFooter>
    </>
  )
}