import React, { useEffect } from 'react';

import { Accordion, AccordionDetails, Grid, Typography, Paper, Box } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { formatDate } from '../../../utils/dateUtils';
import EmptyErrorDocCarousel from "../../../components/EmptyErrorDocCarousel";
import styled from "styled-components";
import TwitterIcon from '../../../img/twitter_button.jpg';


const PaperContent = styled(Paper)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
    border: none;
`;




function AccordionTextDataTwitterParlamentar(props: { lista: Array<any> }) {
    const { pallet } = usePallet();
    const classes = useStyles();

    const openTweetLink = (username, tweetId) => {
        window.open(`https://twitter.com/${username}/status/${tweetId}`, '_blank').focus();
    };

    return (
        <>
            {props.lista['tweets']?.length > 0
                ?

                props.lista['tweets']?.map((item, index) => {
                    return (
                        <Grid key={item.id} style={{ marginBottom: "10px", marginLeft: `${isMobile ? "0px" : "200px"}`, marginRight: `${isMobile ? "0px" : "200px"}`, }}>
                            <Accordion expanded elevation={0} style={{ border: "1px solid #EAEAEA" }} >
                                <AccordionDetails style={{
                                    fontFamily: `${pallet.general.fontFamily}`
                                }}>
                                    <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 15px 0px 5px"}`, width: "100%" }}>

                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <Grid container>
                                                <Grid onClick={() => openTweetLink(props.lista['username'], item.tweet_id)} style={{ cursor: 'pointer', marginRight: '10px', marginTop: '7px' }}>
                                                    <img
                                                        style={{ width: '40px' }}
                                                        src={TwitterIcon}
                                                        alt="twitter_button"
                                                    />
                                                    {/* <TwitterIcon /> */}
                                                </Grid>
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size12}`,
                                                    fontWeight: pallet.general.weight_600,
                                                    marginTop: "4px",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                    {formatDate(new Date(item.postado_em), "dd/MM/yyyy HH:mm")} <Typography style={{ color: `${pallet.color.secundary.font}`, marginLeft: "8px", fontWeight: pallet.general.weight_600 }}>{item.legislatura}</Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "10px"}`, fontSize: '12px' }}>
                                            <Grid style={{ marginTop: "10px" }}>
                                                {item.text}
                                            </Grid>
                                        </Grid>
                                        {item.url ?
                                            <Grid style={{ textAlign: 'center' }}>
                                                <img style={{ maxWidth: `${isMobile ? "280px" : "300px"}` }} src={`${item.url}`} alt={'media'} />
                                            </Grid>
                                            :
                                            null}


                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid >
                    )
                })
                :
                <PaperContent elevation={0} pallet={pallet}>
                    <EmptyErrorDocCarousel title="Não encontramos tweets" description="Verifique se o o cadastro do Twitter deste consultado está correto!" />
                </PaperContent>
            }

        </>
    );
}

export default AccordionTextDataTwitterParlamentar;
