import React, { useState } from "react";

import { isMobile } from "react-device-detect";

import { styled, alpha } from "@mui/material/styles";

import {
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Button,
    ListItemText,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { Box, Pagination, Stack } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import RoomIcon from "@mui/icons-material/Room";
import MenuItem from "@mui/material/MenuItem";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EventIcon from "@mui/icons-material/Event";
import { MoreHoriz } from "@material-ui/icons";

import AcontecimentosProposicaoEdicao from "./AcontecimentosProposicaoEdicao";
// import CustomizedDialogs from './DialogEdit';

import ProposicaoAcontecimentosCreateSchema from "../../models/ProposicaoAcontecimentosCreateSchema";

import { usePallet } from "../../../../contexts/PalletContext";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import { useAlert } from "../../../../contexts/AlertContext";

import { formatDate } from "../../../../utils/dateUtils";

import ProposicaoAcontecimentosService from "../../../../services/ProposicaoAcontecimentosService";
import TabelasService from "../../../../services/TabelasService";

import ButtonDialogConfirmation from "../../../../components/ButtonDialogConfirmation";
import InputMultilineTextField from "../../../../components/InputMultilineTextField";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import FilterQuantity from "../../../../components/filter/FilterQuantity";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import InputDatePicker from "../../../../components/InputDatePicker";
// import ButtonIconSalvar from '../../../components/ButtonIconSalvar';
import CircularLoading from "../../../../components/CircularLoading";
import InputTextField from "../../../../components/InputTextField";
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
// import AnexosItemComponent from '../../../components/Anexos';
import useStyles from "../../../../components/layout/Styles";
import SolidButton from "../../../../components/SolidButton";
import { PreText } from "../../../../components/InfoCard";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    ".MuiListItemText-primary": {
        fontSize: 0
    },
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "0px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export interface IFileInfo {
    name: string;
    filename: string;
    size: number;
    new: boolean;
    file: any;
}

export default function ProposicaoAcontecimentos(props: {
    id_proposicao: number;
    index: number;
    activeTab: number;
}) {
    const { NewAlert } = useAlert();
    const classes = useStyles();
    const { pallet } = usePallet();
    const [loading, setLoading] = useState(false);
    const [acontecimentos, setAcontecimentos] = useState<Array<any>>([]);
    const [listaAcoes, setLista] = useState<Array<any>>([]);
    // const listaAcoes = [
    //     {label: '-- Selecione -- ', value: '' },
    //     {label: 'Deliberado pela Comitê', value: 'Deliberado pela Comitê' },
    //     {label: 'Aguardando Despacho', value: 'Aguardando Despacho' },
    //     {label: 'Realizado Contato com Autoridade - Aguardando Resultado', value: 'Realizado Contato com Autoridade - Aguardando Resultado' }
    // ]
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [horas, setHoras] = useState<string>();
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [infoList, setInfoList] = useState<any>();
    const [dataAcontecimentos, setDataAcontecimentos] = useState();
    const [dataAcontecimentosAgenda, setDataAcontecimentosAgenda] = useState();
    const [descricao, setDescricao] = useState("");
    const [idReferencia, setIdReferencia] = React.useState<number>();
    // const [limparAnexosSalvos, setLimparAnexosSalvos] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1);
    };
    const [error, setError] = useState<Array<{ name: string; err: string }>>([]);
    const [data, setData] = useState({});
    const [local, setLocal] = useState<string>();
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingAfterSalvar, setLoadingAfterSalvar] = useState(false);
    const [expandedAcordion, setExpandedAcordion] = useState(false);
    const [poder, setPoder] = useState(null);
    const [acao, setAcao] = useState(null);

    const mascaraTempo = (value) => {
        return value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "$1:$2");
    };

    const ErrorMessage = (props: {
        name: string;
        errors: Array<{ name: string; err: string }>;
    }) => {
        const itemError = props.errors.filter((err) => err.name === props.name);
        return (
            <div style={{ color: "red" }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        );
    };

    React.useEffect(() => {
        if (props.activeTab === props.index) {

            loadAcontecimentos(props.id_proposicao);

            TabelasService.getAcontecimentoStatus().then(async (res) => {
                const data = [];
                data.push({ key: "", label: "-- Selecione -- ", value: null });
                res?.map((item) =>
                    data.push({
                        key: item.descricao,
                        label: item.descricao,
                        value: item.id,
                    })
                );
                setLista(() => [...data]);
            });
        }

    }, [props.activeTab, currentPage, currentPageSize]);

    // React.useEffect(() => {
    //     if (currentPage > 0)
    //     loadAcontecimentos(props.id_proposicao)

    // }, [currentPage, currentPageSize]);

    // React.useEffect(() => {
    //     if (dataAcontecimentos && descricao !== "") {
    //         setCamposPreenchidos(true)
    //     }
    // }, [descricao, dataAcontecimentos]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };

    const handleDataAcontecimentosChange = (dateAc) => {
        setDataAcontecimentos(dateAc);
    };

    const handleChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setLocal(event.target.value);
    };

    const handleDataAcontecimentosAgendaChange = (dataAcEve) => {
        setDataAcontecimentosAgenda(dataAcEve);
    };

    const handleHortaAcontecimentosAgendaChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newTime = mascaraTempo(event.target.value);

        setHoras(newTime);
        if (newTime.split(":")[0] > 23) {
            setHoras("23:");
        } else if (newTime.split(":")[1] > 59) {
            setHoras(newTime.split(":")[0] + ":59");
        }

    };

    const handleDescricaoChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDescricao(event.target.value);
    };

    const loadingListAfterSalvar = () => {
        setLoadingAfterSalvar(true);
        loadAcontecimentos(props.id_proposicao);
        setTimeout(() => {
            setLoadingAfterSalvar(false);
        }, 1000);
    };

    async function loadAcontecimentos(id_proposicao: number) {
        setLoading(true);
        await ProposicaoAcontecimentosService.GetList(
            id_proposicao,
            currentPage,
            currentPageSize
        ).then((acontecimentos) => {
            if (acontecimentos && acontecimentos.listInfo) {
                setInfoList(() => {
                    return { ...acontecimentos.listInfo };
                });
                setLoading(false);
            }
            if (acontecimentos && acontecimentos.lista) {
                setAcontecimentos(() => {
                    return [...acontecimentos.lista];
                });
                setLoading(false);
            }
        });
    }

    React.useEffect(() => {
        if (!idReferencia && props.id_proposicao) {
            setDataAcontecimentos(undefined);
            setDescricao("");
            setDataAcontecimentosAgenda(undefined);
            setHoras("");
            setLocal("");

            loadAcontecimentos(props.id_proposicao);
        }
    }, [idReferencia]);

    async function handleOnAfterUpload() {
        if (idReferencia) {
            setIdReferencia(undefined);
        }
        loadingListAfterSalvar();
    }

    const setPage = (event: object, page: number) => setCurrentPage(page);

    const erroAlteracaoPerfil = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil."
        );
    }

    const postAcontecimentos = async (e) => {
        e.preventDefault();

        const data = {
            date: dataAcontecimentos ? dataAcontecimentos : new Date(),
            descricao: descricao,
            poder: poder,
            acao: acao,
            evento: {
                data_agendamento: dataAcontecimentosAgenda ? formatDate(new Date(dataAcontecimentosAgenda), "yyyy-MM-dd") : "",
                hora_agendamento: horas, //? formatDate(new Date(horas),"HH:mm") : ""
                local_agendamento: local
            },
        };

        setError(() => []);
        data &&
            ProposicaoAcontecimentosCreateSchema.validate(data, {
                abortEarly: false,
            }).catch((err) => setError(() => err.errors));

        setData(data);
        ProposicaoAcontecimentosCreateSchema.validate(data).catch(function (
            err
        ) { });

        if (ProposicaoAcontecimentosCreateSchema.isValidSync(data)) {
            await ProposicaoAcontecimentosService.Create(
                data,
                props?.id_proposicao, 'p'
            ).then((res) => {
                let idAcontecimento = res;
                if (res && res > 0) {
                    setIdReferencia(idAcontecimento);
                    NewAlert("success", "Acontecimento cadastrado com sucesso");
                    setLoading(true);
                    setLoadingForm(true);
                    setTimeout(() => {
                        setIdReferencia(undefined);
                        setLoadingForm(false);
                        setLoading(false);
                    }, 100);
                } else {
                    NewAlert(
                        "error",
                        "Erro ao cadastrar, verifique os campos e salve novamente"
                    );
                }
            }); //.then(x => loadAcontecimentos(props.id_proposicao))
        } else {
            NewAlert("error", "Algum campo está incorreto ou vazio, verifique!");
            setTimeout(() => {
                setLoadingForm(false);
                setLoading(false);
            }, 100);
        }
    };

    const deletarEdicaoAcontecimentos = (id) => {
        ProposicaoAcontecimentosService.Delete(id)
            .then((res) => {
                if (res) {
                    handleClose();
                    NewAlert("success", "Acontecimento apagado com sucesso");
                    setExpandedAcordion(false)
                } else {
                    NewAlert(
                        "error",
                        "Erro ao apagar, verifique os campos e apague novamente"
                    );
                }
            })
            .then((x) => loadAcontecimentos(props?.id_proposicao));
    };

    const handleChangeAcordion =
        (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedAcordion(!ativo);
        };


    const handleChangePoderLegislativo = () => {
        setPoder("Legislativo")
    };


    const handleChangePoderExecutivo = () => {
        setPoder("Executivo")
    };

    const handleAcaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAcao(event.target.value)
    };
    function addtHours(date, hours) {
        date.setHours(date.getHours() + hours);

        return date;
    }

    const { user } = useAuthorization();

    const Legislativo = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}` }}>
                Legislativo
            </div>
        )
    }

    const Executivo = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}` }}>
                Executivo
            </div>
        )
    }
    return (
        <>
            {/* ---------------------------- CONTEÚDO DE ACONTECIMENTOS ------------------------------ */}
            {/* <CustomizedDialogs /> */}
            <div style={{ fontFamily: `${pallet.fontFamily.general}` }}>
                {loadingForm ? (
                    <CircularLoading size={80} top={"50%"} left={"50%"} zIndex={2} />
                ) : (
                    <Grid
                        style={{
                            paddingLeft: `${isMobile ? "0px" : "30px"}`,
                            paddingRight: `${isMobile ? "0px" : "30px"}`,
                            paddingBottom: `${isMobile ? "20px" : "45px"}`,
                        }}
                    >


                        <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }} onChange={handleChangeAcordion(expandedAcordion)}>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        style={{ color: `${pallet.color.primary.font}` }}
                                    />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    backgroundColor: `${pallet.color.primary.background}`,
                                    borderRadius: "5px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                    flexFlow: "row-reverse",
                                    height: "64px",
                                }}
                            >
                                <Typography
                                    className={classes.heading}
                                    style={{
                                        color: `${pallet.color.primary.font}`,
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontSize: `${pallet.fontSize.titleWidgets}`,
                                        fontWeight: pallet.fontWeight.subtitle,
                                        marginLeft: "10px",
                                    }}
                                >
                                    NOVA ATIVIDADE
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                }}
                            >


                                <Grid style={{ padding: "25px 20px 20px 20px" }}>
                                    <Grid item lg={4} xs={12}>
                                        <InputDatePicker
                                            onChange={handleDataAcontecimentosChange}
                                            label={"DATA"}
                                            inputFormat={"dd/MM/yyyy"}
                                            value={dataAcontecimentos}
                                            defaultValue={dataAcontecimentos}
                                            id={"dataAcontecimentos-proposicao"}
                                        />
                                    </Grid>
                                    <Grid item style={{ marginTop: "20px" }} lg={12} xs={12}>
                                        <InputMultilineTextField
                                            type={"text"}
                                            maxRows={4}
                                            value={descricao}
                                            defaultValue={descricao}
                                            onChange={handleDescricaoChange}
                                            id={"descricao-proposicao"}
                                            label="DESCRIÇÃO"
                                        />
                                        <Grid
                                            style={{
                                                color: "red",
                                                fontSize: "10px",
                                                marginTop: "2px",
                                                marginLeft: "2px",
                                            }}
                                        >
                                            <ErrorMessage name="descricao" errors={error} />
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ marginTop: "20px" }} lg={6} xs={12}>
                                        <RadioGroup
                                            aria-label="gender"
                                            defaultValue="tudo"
                                            name="radio-buttons-group"
                                        >
                                            <Grid style={{ display: "flex", flexDirection: "row" }}>
                                                <Grid >
                                                    <FormControlLabel value='Legislativo' control={<Radio onClick={handleChangePoderLegislativo} style={{ color: `${pallet.backgroundColorPrimary}` }} />} label={<Legislativo />} />
                                                </Grid>
                                                <Grid>
                                                    <FormControlLabel value='Executivo' control={<Radio onClick={handleChangePoderExecutivo} style={{ color: `${pallet.backgroundColorPrimary}` }} />} label={<Executivo />} />
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>


                                    <Grid item style={{ marginTop: "20px" }} lg={6} xs={12}>

                                        <SelectTextFieldA
                                            value={acao}
                                            onChange={handleAcaoChange}
                                            options={listaAcoes}
                                            label={"ESCOLHA A AÇÃO"}
                                            id={"acao"}
                                        />
                                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                            <ErrorMessage name='id_responsavel' errors={error} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                            <Grid style={{ paddingBottom: "10px" }}>
                                <Divider />
                            </Grid>

                            <Grid
                                container
                                style={{
                                    fontFamily: pallet.fontFamily.general,
                                    padding: "25px 20px 20px 40px",
                                }}
                            >
                                <Grid
                                    item
                                    lg={12}
                                    xs={12}
                                    style={{
                                        fontSize: pallet.general.size12,
                                        fontWeight: pallet.general.weight_600,
                                    }}
                                >
                                    AGENDA
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    xs={12}
                                    style={{ fontSize: pallet.general.size12 }}
                                >
                                    Crie um evento na agenda para este registro.
                                </Grid>
                                <Grid style={{ marginTop: "20px" }} item container>
                                    <Grid item lg={6} xs={12}>
                                        <Grid
                                            style={{
                                                width: "100%",
                                                paddingRight: `${isMobile ? "0px" : "20px"}`,
                                            }}
                                        >
                                            <InputDatePicker
                                                onChange={handleDataAcontecimentosAgendaChange}
                                                label={"DATA DO EVENTO"}
                                                inputFormat={"dd/MM/yyyy"}
                                                startAdornment={<CalendarTodayIcon />}
                                                defaultValue={null}
                                                placeholder={"Selecione"}
                                                value={dataAcontecimentosAgenda}
                                                id={"dataAcontecimentosAgenda-proposicao"}
                                            />



                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Grid
                                            style={{
                                                width: "100%",
                                                paddingLeft: `${isMobile ? "0px" : "20px"}`,
                                                marginTop: `${isMobile ? "20px" : "0px"}`,
                                            }}
                                        >
                                            <InputTextField
                                                type={"text"}
                                                placeholder={"Digite"}
                                                value={horas}
                                                name="horarioEvento"
                                                onChange={handleHortaAcontecimentosAgendaChange}
                                                id={"horario-evento"}
                                                label={"HORARIO DO EVENTO"}
                                                maxLenght={5}
                                                startAdornment={<AccessAlarmIcon />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid style={{ marginTop: "20px" }} item lg={12} xs={12}>
                                    <InputTextField
                                        type={"text"}
                                        placeholder={"Digite"}
                                        value={local}
                                        name="local"
                                        onChange={handleChangeLocal}
                                        id={"local-evento"}
                                        label={"LOCAL"}
                                        startAdornment={<RoomIcon />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid style={{ padding: "20px 18px 20px 18px" }}>
                                <Grid
                                    xs={12}
                                    lg={12}
                                    style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                                >
                                    <Grid
                                        style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.general.size12}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            marginLeft: "9px",
                                        }}
                                    >
                                        ANEXOS
                                    </Grid>
                                    <Grid className="container">
                                        <AnexosComponent
                                            label=""
                                            modulo={EAnexoModulos.Acontecimentos}
                                            id_referencia={idReferencia}
                                            canEdit={true}
                                            autosave={true}
                                            onAfterUpload={handleOnAfterUpload}
                                        // limparAnexo={limparAnexosSalvos}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Divider />
                            </Grid>
                            <Grid
                                container
                                justifyContent="flex-end"
                                style={{
                                    backgroundColor: `${pallet.backgroundColorTertiary}`,
                                    border: "1px solid #EAEAEA",
                                    padding: "35px 30px 35px 30px",
                                }}
                            >
                                <Grid>


                                    <SolidButton
                                        onClick={user.id_permissao !== 3 ? postAcontecimentos : erroAlteracaoPerfil}
                                        color={`${pallet.textColorPrimary}`}
                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                        fontSize={`${pallet.general.size14}`}
                                        title={"SALVAR"}
                                    />



                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>
                )}
                {

                    acontecimentos?.length ? (
                        ""
                    ) : !expandedAcordion && (
                        <Grid
                            style={{
                                color: `${pallet.textColorQuaternary}`,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: 600,
                                textAlign: "center",
                                paddingBottom: "150px",
                                marginTop: "20px",
                            }}
                        >
                            <EmptyErrorDoc
                                title="Comece registrando uma nova atividade"
                                description="Você ainda não registrou nenhuma atividade para este parlamentar."
                                subdescription={" "}
                            />
                        </Grid>
                    )}

                {loading ? (
                    <CircularLoading size={80} top={"50%"} left={"50%"} zIndex={2} />
                ) : loadingAfterSalvar === true ? (
                    <CircularLoading size={80} top={"50%"} left={"50%"} zIndex={2} />
                ) : acontecimentos?.length ? (
                    <Grid
                        style={{
                            padding: `${isMobile ? "5px 5px 0px 15px" : "0px 35px 20px 40px"
                                }`,
                        }}
                    >
                        {infoList?.totalItens > 4 ? (
                            <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                <FilterQuantity
                                    list={infoList}
                                    currentPageSize={currentPageSize}
                                    onChange={handleOnChangeItensPerPageSize}
                                />
                            </Grid>
                        ) : null}
                        <Box style={{ marginTop: "15px" }}>
                            <Stepper orientation="vertical">
                                {acontecimentos?.map((item, index) => (
                                    <Step expanded active={false}>
                                        <StepLabel
                                            icon={
                                                <Grid container>
                                                    <div
                                                        style={{
                                                            marginTop: "-8px",
                                                            marginLeft: "-7px",
                                                            borderRadius: "50%",
                                                            width: "40px",
                                                            height: "40px",
                                                            position: "relative",
                                                            boxShadow:
                                                                "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                            backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                                marginTop: "7px",
                                                                color: `${pallet.backGroundPaperColorPrimary}`,
                                                            }}
                                                        >
                                                            {item.usuario?.split(" ")[0].substring(0, 2)}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            }
                                            optional={
                                                <Grid container justifyContent={"space-between"}>
                                                    <Grid>
                                                        <Grid
                                                            container
                                                            style={{
                                                                fontSize: "12px",
                                                                fontFamily: `${pallet.general.fontFamily}`,
                                                            }}
                                                        >
                                                            <Grid item style={{ marginRight: "5px" }}>
                                                                {formatDate(new Date(item.data), "dd/MM/yyyy")}
                                                            </Grid>

                                                            {/* <Grid item style={{ marginTop: "-12px", marginRight: "5px", fontSize: "25px" }}>
                                                            •
                                                        </Grid>
                                                        <Grid item style={{}}>
                                                            {formatDate(new Date(item.data), "HH:mm")}
                                                        </Grid> */}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        style={{
                                                            marginTop: "-25px",
                                                            paddingRight: "10px",
                                                            marginBottom: "-30px",
                                                        }}
                                                    >
                                                        <Button
                                                            id={`button-${item.id}`}
                                                            aria-controls={
                                                                anchorEl ? `mmenu-${item.id}` : undefined
                                                            }
                                                            disableElevation
                                                            variant="contained"
                                                            size="large"
                                                            style={{
                                                                backgroundColor: `${pallet.general.color3}`,
                                                                textTransform: "capitalize",
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.fontWeight.button,
                                                                fontSize: `${pallet.fontSize.smallButton}`,
                                                                color: `${pallet.color.secundary.font}`,
                                                                width: "25px",
                                                                height: "40px",
                                                            }}
                                                            onClick={(e) => handleClick(e, item.id)}
                                                            endIcon={
                                                                <Grid
                                                                    style={{
                                                                        fontSize: "30px",
                                                                        color: `${pallet.general.color4}`,
                                                                        marginTop: "9px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                >
                                                                    <MoreHoriz fontSize="inherit" />
                                                                </Grid>
                                                            }
                                                        ></Button>
                                                        <StyledMenu

                                                            id={`mmenu-${item.id}`}
                                                            MenuListProps={{
                                                                "aria-labelledby": `button-${item.id}`,
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open === item.id}
                                                            onClose={handleClose}
                                                        >
                                                            <MenuItem>
                                                                <AcontecimentosProposicaoEdicao
                                                                    idAcontecimento={item.id}
                                                                    reloadList={() =>
                                                                        loadAcontecimentos(props?.id_proposicao)
                                                                    }
                                                                    closeMenu={() => handleClose()}
                                                                    idProposicao={props?.id_proposicao}
                                                                />
                                                            </MenuItem>
                                                            <Divider
                                                                style={{
                                                                    marginTop: "0px",
                                                                    marginBottom: "0px",
                                                                }}
                                                            />
                                                            <MenuItem
                                                                style={{
                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                    fontWeight: pallet.fontWeight.button,
                                                                    fontSize: `${pallet.general.size14}`,
                                                                    color: `${pallet.color.tertiary.font}`,
                                                                    borderRadius: "6px",
                                                                    padding: "6px",
                                                                }}
                                                            >
                                                                <ButtonDialogConfirmation
                                                                    styles={{
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.fontWeight.button,
                                                                        fontSize: `${pallet.general.size14}`,
                                                                        color: `${pallet.color.tertiary.font}`,
                                                                        borderRadius: "6px",
                                                                        textTransform: "capitalize",
                                                                    }}
                                                                    title={"CONFIRMAÇÃO"}
                                                                    // lastPath={"/parlamentar"}
                                                                    confirmationEvent={() =>
                                                                        user.id_permissao !== 3 ? deletarEdicaoAcontecimentos(item.id)
                                                                            : NewAlert(
                                                                                "error",
                                                                                "Não é possível realizar está ação com este perfil."
                                                                            )}
                                                                    title_button={"Apagar"}
                                                                    confirmation_button={"Apagar"}
                                                                    content={"APAGAR ATIVIDADE?"}
                                                                    message={"Esta ação não poderá ser desfeita."}
                                                                >
                                                                    <DeleteIcon
                                                                        style={{
                                                                            color: `${pallet.color.tertiary.font}`,
                                                                            marginRight: "16px",
                                                                            fontFamily: `${pallet.fontFamily.general}`,
                                                                        }}

                                                                    />
                                                                    <ListItemText>!</ListItemText>
                                                                </ButtonDialogConfirmation>
                                                            </MenuItem>
                                                            {/* <MenuItem onClick={() => deletarEdicaoAcontecimentos(item.id)}
                                                            style={{
                                                                paddingTop: "8px",
                                                                paddingBottom: "8px",
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.fontWeight.lighter,
                                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                            }}>
                                                            <DeleteIcon />
                                                            Apagar
                                                        </MenuItem> */}
                                                        </StyledMenu>
                                                    </Grid>
                                                </Grid>
                                            }
                                        >
                                            {item.usuario}
                                        </StepLabel>
                                        <StepContent>
                                            {
                                                item.poder ?
                                                    <Grid style={{


                                                        fontSize: pallet.general.size12,
                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                    }}>
                                                        <Grid>
                                                            <span style={{ fontWeight: 'bold' }}>Poder:</span> {item.poder}
                                                        </Grid>
                                                        <Grid>
                                                            <span style={{ fontWeight: 'bold' }}>Ação:</span> {item.status}
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    null

                                            }
                                            <Grid
                                                whiteSpace={"normal"}
                                                textOverflow={"ellipsis"}
                                                style={{
                                                    alignItems: "center",
                                                    border: "1px solid #EAEAEA",
                                                    borderRadius: "6px",
                                                }}
                                            >
                                                <Grid>


                                                    {item.id_agenda && (
                                                        <Grid
                                                            container
                                                            style={{
                                                                alignItems: "center",
                                                                height: "50px",
                                                                backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                fontSize: pallet.general.size12,
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                borderTopLeftRadius: "6px",
                                                                borderTopRightRadius: "6px",
                                                            }}
                                                        >
                                                            <Grid
                                                                item
                                                                style={{
                                                                    padding: "12.5px",
                                                                    backgroundColor: `${pallet.backgroundColorQuintenary}`,
                                                                    borderTopLeftRadius: "6px",
                                                                }}
                                                            >
                                                                <EventIcon
                                                                    fontSize="small"
                                                                    sx={{ color: `${pallet.general.color1}` }}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    padding: "10px",
                                                                    fontWeight: pallet.general.weight_600,
                                                                }}
                                                            >
                                                                EVENTO
                                                            </Grid>
                                                            <Grid
                                                                style={{
                                                                    padding: `${isMobile
                                                                        ? "10px 1px 10px 5px"
                                                                        : "10px 1px 10px 10px"
                                                                        }`,
                                                                }}
                                                                item
                                                            >
                                                                <CalendarTodayIcon
                                                                    fontSize="small"
                                                                    sx={{ color: `${pallet.general.color1}` }}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                style={{
                                                                    padding: `${isMobile
                                                                        ? "10px 1px 10px 5px"
                                                                        : "10px 1px 10px 10px"
                                                                        }`,
                                                                }}
                                                                item
                                                            >
                                                                {item.data_agendamento ? formatDate(
                                                                    new Date(item?.data_agendamento),
                                                                    "dd/MM/yyyy"
                                                                ) : ""}{" "}
                                                            </Grid>
                                                            <Grid
                                                                style={{
                                                                    padding: `${isMobile
                                                                        ? "10px 1px 10px 5px"
                                                                        : "10px 1px 10px 10px"
                                                                        }`,
                                                                }}
                                                                item
                                                            >
                                                                <AccessAlarmIcon
                                                                    fontSize="small"
                                                                    sx={{ color: `${pallet.general.color1}` }}
                                                                />
                                                            </Grid>{" "}
                                                            <Grid style={{ padding: "10px 1px 10px 5px" }} item>
                                                                {
                                                                    // formatDate(addtHours(new Date(item?.data_agendamento),3)
                                                                    item.data_agendamento ? formatDate(new Date(item?.data_agendamento), "HH:mm") : ""
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    <Typography
                                                        style={{
                                                            wordBreak: "break-all",
                                                            whiteSpace: "pre-wrap",
                                                            padding: "10px",
                                                            fontFamily: `${pallet.general.fontFamily}`,
                                                            fontSize: "13px",
                                                            overflowWrap: "break-word",
                                                        }}
                                                    >
                                                        {item.descricao.search('Observação:') !== -1 ? item.descricao.split('|||').map(a => {
                                                            return (
                                                                <div style={{ wordBreak: "break-word" }}> <span style={{ fontWeight: 'bold' }}>{a.slice(0, a.search(':') + 1)}</span>  {a.slice(0, a.search(':')) === 'Anexo' ? < a href={a.slice(a.search(':') + 2)}>{a.slice(a.search(':') + 2)}</a> : a.slice(a.search(':') + 2)}</div>
                                                            )
                                                        })
                                                            :
                                                            item.descricao
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            wordBreak: "break-all",
                                                            padding: "10px",
                                                            // boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                            fontFamily: `${pallet.general.fontFamily}`,
                                                            fontSize: "13px",
                                                            overflowWrap: "break-word",
                                                        }}
                                                    >
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <Grid className="container">
                                                        <AnexosComponent
                                                            label={""}
                                                            modulo={EAnexoModulos.Acontecimentos}
                                                            id_referencia={item.id}
                                                            canEdit={false}
                                                            autosave={false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        <Grid
                            container
                            justifyContent="center"
                            style={{
                                marginTop: "40px",
                                marginBottom: "40px",
                                paddingBottom: `${isMobile ? "40px" : "0px"}`,
                            }}
                        >
                            {infoList?.totalItens > 4 ? (
                                <Stack spacing={2}>
                                    <Pagination
                                        size={isMobile ? "small" : "large"}
                                        onChange={setPage}
                                        count={infoList?.totalPages}
                                        page={currentPage}
                                        shape="rounded"
                                    />
                                </Stack>
                            ) : null}
                        </Grid>
                    </Grid>
                ) : (
                    ""
                )}
            </div>
        </>
    );
}
