import * as http from '../helpers/http';

class ParlamentarAcontecimentosService {

    GetList = async (id: number, pageIndex: number, pageSize: number = 8): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}/acontecimentos?pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                lista: result.data.data
            }
        }
        return undefined;
    }

    Create = async (data: any, id: number,tag?: string): Promise<number> => {
        const result = await http.post(`/parlamentar/${id}/acontecimentos/${tag}`, data);
        if (result.success) { return result.data }
        return 0;
    };

    Get = async (idAcontecimento: number): Promise<any> => {
        const result = await http.get(`/parlamentar/acontecimentos/${idAcontecimento}`);
        if (result.success) { return result }
        return undefined;
    };

    Update = async (data: any, idParlamentar: number, idAcontecimento: number, tag?: string): Promise<boolean> => {
        const result = await http.put(`/parlamentar/${idParlamentar}/acontecimentos/${idAcontecimento}/${tag}`, data);
        if (result.success) { return result.success }
        return false;
    };

    Delete = async (id: number): Promise<boolean> => {
        const result = await http.del(`/parlamentar/acontecimentos/${id}`);
        if (result.success) { return true }
        return false;
    };


}

export default new ParlamentarAcontecimentosService();
