import PrivateRoute from '../../components/PrivateRoute';
import { NotificationsProvider } from '../../contexts/NotificationsContext';
import Main from '../main/Main';
import Main2 from '../main/Main2';
import Workspace from './Workspace';

export function WorkSpaceRoutes(): JSX.Element {
    return (
        <>
            
            <NotificationsProvider>
            {/* <PrivateRoute path="/" element={< Workspace />} /> */}
            <PrivateRoute path="/workspace" element={<Main2/>} />
            </NotificationsProvider>

        </>
    );
}
