import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { IconButton, Drawer, List, Grid } from "@mui/material";
import { usePallet } from "../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { mainMenu, mainMenuReadOnly } from "./MenuArrayList";
import { mainMenu2 } from "./MenuArrayList";
import { mainMenuEsforco } from "./MenuArrayList";
import { mainMenu2Esforco } from "./MenuArrayList";
import MenuItem from "./MenuItem";
import logo from "../../img/logo-legisnote-roxo.svg";
import DropDownMenu from "../../components/DropDownMenu";
import MenuInferior from "./MenuInferior";
import { useState } from "react";
import Fade from "@mui/material/Fade";
import { useNavigate } from "react-router-dom";
import { useAuthorization } from "../../contexts/AuthorizationContext";

export const DrawerCustom = (props: {
  classes: any;
  open: boolean;
  handleToggleOpen: () => void;
}) => {
  const { pallet } = usePallet();
  const [idButtonClick, setIdButtonClick] = useState<number>();
  const { user } = useAuthorization();
  const { getConfig } = useAuthorization();
  // useEffect(() => {

  // }, [idBotaoClick])

  const changeIdButton = (key) => {
    setIdButtonClick(key);
  };

  const navigate = useNavigate();
  const opcoes1:any = user.id_permissao === 4 ? mainMenuEsforco : user.id_permissao === 3 ? mainMenuReadOnly : mainMenu;
  const opcoes2:any = user.id_permissao === 4 ? mainMenu2Esforco : mainMenu2;

  opcoes1.forEach(o => {
    if (o.hide === 'hideDou') o.hide = getConfig()?.semElastic || !getConfig()?.habDou;
    o.subTitles?.length && o.subTitles.forEach(s => {
      if (s.hide === 'hideDou') s.hide = getConfig()?.semElastic || !getConfig()?.habDou;
    });
  });

  // const useStyles = makeStyles({

  //   paper: {
  //     width: 300
  //   }
  // });

  // const classes = useStyles();

  return (
    <Drawer
      variant={isMobile ? null : "permanent"}
      classes={{
        paper: clsx(
          props.classes.drawerPaper,
          !props.open && props.classes.drawerPaperClose
        ),
      }}
      open={props.open}
      style={{
        fontFamily: pallet.fontFamily.general,
        fontWeight: pallet.fontWeight.text,
        

      }}
    >
      <div className={props.classes.toolbarIcon}>
        <div style={{ marginRight: "107px", cursor: "pointer" }}>
          <img onClick={() => navigate("/")} src={logo} width="135px" />
        </div>
        <IconButton
          sx={{ marginRight: `${props.open === true ? "10px" : "0px"}` }}
          onClick={props.handleToggleOpen}
        >
          {props.open === true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>

      <List>
        {opcoes1.map((item, index) => {
          return (
            <Fade>
              <DropDownMenu
                index={index}
                icon={item.icon}
                title={item.title}
                subTitles={item.subTitles}
                url={item.url}
                open={props.open}
                onChange={changeIdButton}
                idButtonSelected={idButtonClick}
                nomenudrop={item.key}
              />
            </Fade>
          );
        })}
      </List>
      <>
        <hr style={{ width: "100%" }} />
      </>
      {props.open === true ? (
        <List>
          {opcoes2.map((item) => {
            return <MenuItem key={item.key} menu={item} />;
          })}
        </List>
      ) : (
        <List>
          {opcoes2.map((item, i) => {
            return (
              <Grid>
                <MenuInferior
                  i={item.key}
                  icon={item.icon}
                  title={item.title}
                  url={item.url}
                  onChange={changeIdButton}
                  idButtonSelected={idButtonClick}
                  no={item.key}
                />
              </Grid>
            );
          })}
        </List>
      )}
    </Drawer>
  );
};
