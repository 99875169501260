import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import styled from "styled-components";
import { usePallet } from '../../../contexts/PalletContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { isMobile } from 'react-device-detect';
import { Description } from '@material-ui/icons';
import { formatDate } from '../../../utils/dateUtils';
import { useNavigate } from "react-router-dom";

function AccordionTextDataProposicaoParlamentar(props: { lista: Array<any>,permissaoUsuario?: number }) {

    const { pallet } = usePallet();
    const classes = useStyles();
    const navigate = useNavigate();


    const NomeProposicao = styled(Grid)`
  // position: absolute;
  color: ${(props) => props.pallet.textColorQuaternary};
  font-family: ${(props) => props.pallet.general.fontFamily};
  font-size: ${(props) => props.pallet.general.size12};
  font-weight: ${(props) => props.pallet.general.weight_600};
  text-transform: uppercase;
  cursor: pointer;
  strong {
    color: ${(props) => props.pallet.textColorTertiary};
  }
`;
    const posicionamentoColor = (value: number) => {
        switch (value) {
            case 1:
                return `${pallet.charts.red}`;
            case 2:
                return `${pallet.charts.orange}`;
            case 3:
                return `${pallet.charts.purple}`;
            case 4:
                return `${pallet.charts.blue}`;
            case 5:
                return `${pallet.charts.green}`;
            case 6:
                return `${pallet.charts.lightYellow}`;
            case 7:
                return `${pallet.charts.lightRed}`;
            case 8:
                return `${pallet.charts.lightBlue}`;
            case 99:
                return `${pallet.charts.yellow}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }

    }

    const prioridadeColor = (value: string) => {
        switch (value) {
            case 'Máxima':
                return `${pallet.charts.red}`;
            case 'Baixa':
                return `${pallet.charts.blue}`;
            case 'Média':
                return `${pallet.charts.yellow}`;
            case 'Alta':
                return `${pallet.charts.purple}`;
            case 'Mínima':
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }


    const AccordionSum = styled(AccordionSummary)`
        /* height: 10px; */
        border-radius: 5px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0px;
        padding-bottom: 5px;
        flex-flow: row-reverse;

        .MuiAccordionSummary-content {
            flex-direction: column;
        }
        .MuiAccordionSummary-content.Mui-expanded {
            margin: 12px 0;
        }
    `;

    const TitleText = styled(Grid)`
        font-family: ${(props => props.pallet.general.fontFamily)};
        font-weight: ${(props => props.pallet.general.weight_bold)};
        font-size: ${(props => props.pallet.general.size12)};
        color: ${(props => props.pallet.textColorTertiary)};
        margin-top: 4px;
    `;

    const LabelText = styled(Grid)`
        font-weight: ${(props => props.pallet.general.weight_600)};
        font-size: ${(props => props.pallet.fontSize.text_10)};
        color: ${(props => props.pallet.textColorQuaternary)};
    `;

    const StatusChip = styled(Grid)`
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        border-radius: 12px;
        font-size: 12px;
        border: 1px solid ${(props => props.pallet.general.color4)};
        color: ${(props => props.pallet.general.color4)};
        padding: 3px 10px 2px 10px;
        margin-top: 2px;
        /* margin-bottom: 10px; */
        margin-left: 10px;
    `;

    const StatusDot = styled.div`
        background-color: ${(props => props.bg)};
        border-radius: 50%;
        height: 10px;
        width: 10px;
        /* margin-top: 5px; */
        margin-right: 5px;
    `;

    // console.log("CADE MEU ID", props)
    return (
        <>
            {props.lista?.map(item => {
                return (


                    <Grid key={item.id} style={{ marginBottom: "20px" }}>

                        <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }} >
                            <AccordionSum
                                expandIcon={<ArrowDropDownIcon
                                    style={{
                                        color: `${pallet.general.color2}`,
                                        fontSize: `${pallet.general.size16}`
                                    }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container>
                                    <Grid>
                                        <Description style={{ color: `${pallet.backgroundColorPrimary}`, width: "16px", marginBottom: "-7px", marginRight: "5px", marginLeft: "15px" }} />
                                    </Grid>
                                    <Grid>
                                        <NomeProposicao
                                            onClick={() => navigate(`/proposicao/${item.proposicao_id}`)}
                                            pallet={pallet}
                                        >
                                            <strong>{item.proposicao_descricao}</strong>
                                        </NomeProposicao>
                                    </Grid>
                                    <TitleText pallet={pallet} style={{
                                        fontWeight: pallet.general.weight_500,
                                        // marginTop: "4px",
                                        marginLeft: "10px"
                                    }}>
                                        {item.proposicao_cliente_apelido === null ? "" : item.proposicao_cliente_apelido}
                                    </TitleText>

                                </Grid>

                                <Grid container style={{ marginLeft: "5px" }}>
                                    <StatusChip pallet={pallet}>
                                        {item.tipo}
                                    </StatusChip>
                                {props.permissaoUsuario !== 4 ?
                                <>
                                    <StatusChip pallet={pallet}>
                                        <StatusDot bg={prioridadeColor(item.proposicao_cliente_prioridade_descricao)} />
                                        PRIORIDADE {item.proposicao_cliente_prioridade_descricao}
                                    </StatusChip>
                                    <StatusChip pallet={pallet}>
                                        <StatusDot bg={posicionamentoColor(item.proposicao_cliente_posicionamento)} />
                                        POSICIONAMENTO {item.proposicao_cliente_posicionamento_descricao}
                                    </StatusChip>
                                </>
                                    :
                                    null
                                }
                                </Grid>

                            </AccordionSum>

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                color: `${pallet.textColorQuaternary}`
                            }}>
                                {/* <Grid style={{border:"1px solid red"}}>
                                    <Divider />
                                </Grid> */}
                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "20px 20px 10px 20px"}`, width: "100%" }}>
                                    <Grid xl={12}>
                                        <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: "10px" }}>
                                            {/* <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <LabelText pallet={pallet}>
                                                    APELIDO DA PROPOSIÇÃO
                                                </LabelText>

                                                <Grid style={{ marginTop: "10px" }}>
                                                    {item.proposicao_cliente_apelido === null ? "Não Definido" : item.proposicao_cliente_apelido}
                                                </Grid>
                                            </Grid> */}
                                            <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <LabelText pallet={pallet}>
                                                    DATA DE APRESENTAÇÃO
                                                </LabelText>

                                                <Grid style={{ marginTop: "10px" }}>
                                                    {formatDate(new Date(item.proposicao_data_apresentacao), 'dd/MM/yyyy')}
                                                </Grid>

                                            </Grid>
                                            {props.permissaoUsuario !== 4 ?
                                            <>
                                            <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <LabelText pallet={pallet}>
                                                    PRIORIDADE
                                                </LabelText>

                                                <Grid container style={{ marginTop: "10px", alignItems: "center" }}>
                                                    <Grid>
                                                        <StatusDot bg={prioridadeColor(item.proposicao_cliente_prioridade_descricao)} />
                                                    </Grid>
                                                    <Grid>
                                                        {item.proposicao_cliente_prioridade_descricao}
                                                    </Grid>



                                                </Grid>

                                            </Grid>
                                            <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <LabelText pallet={pallet}>
                                                    POSICIONAMENTO
                                                </LabelText>
                                                <Grid container style={{ marginTop: "10px", alignItems: "center" }}>
                                                    <Grid>
                                                        <StatusDot bg={posicionamentoColor(item.proposicao_cliente_posicionamento)} />
                                                    </Grid>

                                                    <Grid>
                                                        {item.proposicao_cliente_posicionamento_descricao}
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                            </>
                                            :
                                            null
                                            }

                                        </Grid>
                                    </Grid>
                                    <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: "30px" }}>
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <LabelText pallet={pallet}>
                                                EMENTA
                                            </LabelText>

                                            <Grid style={{ marginTop: "10px" }}>
                                                {item.proposicao_ementa}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataProposicaoParlamentar;