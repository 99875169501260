import { Grid, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
// import ReactToPrint from 'react-to-print';
import { usePallet } from "../../../contexts/PalletContext";
import styled from 'styled-components';
import legis_agenda from "../../../img/legis_agenda.png";
import legis_agenda2 from "../../../img/legis_agenda2.png";

import PrintIcon from '@mui/icons-material/Print';
import { isMobile, isWindows } from 'react-device-detect';
import { fontSize } from '@mui/system';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../utils/utils';
import CircularLoading from '../../../components/CircularLoading';
import AgendaService from '../../../services/AgendaService';
import { useAuthorization } from "../../../contexts/AuthorizationContext";
import { formatDate } from '../../../utils/dateUtils';
import PlayArrowIcon from "../../../img/play-arrow.png";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';

export default function AgendaRenderPDFJornalNovo() {
    const { pallet } = usePallet();
    const [relatorio, setRelatorio] = React.useState([]);
    let data_agora = new Date();
    let dia = String(data_agora.getDay()).padStart(2, "0");
    let dia_n = String(data_agora.getDate()).padStart(2, "0");
    let mes = String(data_agora.getMonth() + 1);
    let ano = data_agora.getFullYear();
    const { dtIni } = useParams();
    const { dtFin } = useParams();
    const { tipo } = useParams();

    const { hasAccess } = useAuthorization();
    const { user } = useAuthorization();

    React.useEffect(() => {
        // loadJornal(dtIni, dtFin, tipo)

        AgendaService.GetListRelatorio(format(new Date(dtIni), "yyyy-MM-dd"), format(new Date(dtFin), "yyyy-MM-dd 23:59:00.000"), tipo).then((jornal) => {
            // console.log("JORNAL ================ >>>> ", jornal)
            setRelatorio(jornal && jornal.informacoes && jornal.informacoes.data && Array.isArray(jornal.informacoes.data) ? jornal.informacoes.data : []);
        });
    }, []);

    const Imprimir = () => {
        window.print()
    }

    const posicionamentoDescricao = (value: number) => {
        switch (value) {
          case 0:
            return "Indefinido";
          case 1:
            return "Contrário";
          case 2:
            return "Contrário com emenda saneadora";
          case 3:
            return "Neutro";
          case 4:
            return "Favorável com emenda saneadora";
          case 5:
            return "Favorável";
          case 6:
            return "Favorável ao parecer do relator";
          case 7:
            return "Favorável ao parecer do relator com emenda";
          case 8:
            return "Contrário ao parecer do relator";
          case 9:
            return "Contrário ao parecer do relator com emenda";
          case 99: 
            return "Pendente";
          default: 
            return "Indefinido";
        }
      };
    
      const posicionamentoColor = (value: string) => {
        switch (value) {
          case "Contrário":
              return `${pallet.charts.red}`;
          case "Contrário com emenda saneadora":
              return `${pallet.charts.orange}`;
          case "Neutro":
              return `${pallet.charts.purple}`;
          case "Favorável com emenda saneadora":
              return `${pallet.charts.blue}`;
          case "Favorável":
              return `${pallet.charts.green}`;
          case "Pendente":
              return `${pallet.charts.yellow}`;
          case "Indefinido":
              return `${pallet.charts.lightGrey}`;
          case "Favorável ao parecer do relator":
                return '#C71585';
          case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
          case "Contrário ao parecer do relator":
                return '#facb74';
          case "Contrário ao parecer do relator com emenda":
                return '#000000';
          default:
              return `${pallet.charts.lightGrey}`;
      }
      };

    const prioridadeColor = (value: string) => {
        switch (value) {
            case "Máxima":
                return `${pallet.charts.red}`;
            case "Baixa":
                return `${pallet.charts.blue}`;
            case "Média":
                return `${pallet.charts.yellow}`;
            case "Alta":
                return `${pallet.charts.purple}`;
            case "Mínima":
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };


    const prioridadeDescricao = (value: number) => {
        switch (value) {
            case 1:
                return "Mínima";
            case 2:
                return "Baixa";
            case 3:
                return "Média";
            case 4:
                return "Alta";
            case 5:
                return "Máxima";
            default:
                return "Não definido";
        }
    };

    const filtroDeTipo = (value: any) => {
        switch (value) {
            case "SF":
                return "SENADO";
            case "CD":
                return "CÂMARA DOS DEPUTADOS";
            case "CN":
                return "CONGRESSO NACIONAL";
            default:
                return "TUDO";
        }
    };

    const diaSemana = (value: any) => {
        switch (value) {
            case "01":
                return "SEGUNDA-FEIRA";
            case "02":
                return "TERÇA-FEIRA";
            case "03":
                return "QUARTA-FEIRA";
            case "04":
                return "QUINTA-FEIRA";
            case "05":
                return "SEXTA-FEIRA";
            case "06":
                return "SÁBADO";
            default:
                return "DOMINGO";
        }
    };

    const mesAagora = (value: string) => {
        switch (value) {
            case "1":
                return "JANEIRO";
            case "2":
                return "FEVEREIRO";
            case "3":
                return "MARÇO";
            case "4":
                return "ABRIL";
            case "5":
                return "MAIO";
            case "6":
                return "JUNHO";
            case "7":
                return "JULHO";
            case "8":
                return "AGOSTO";
            case "9":
                return "SETEMBRO";
            case "10":
                return "OUTUBRO";
            case "11":
                return "NOVEMBRO";
            case "12":
                return "DEZEMBRO";
        }
    };

    let letra = window.location.href.search("/");
    let ini_end = window.location.href.slice(0, letra);
    let end = window.location.href.slice(letra + 2);
    let letra2 = end.search("/");
    let end2 = end.slice(0, letra2);

    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <span style={{
                                                color: 'white',
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size12}`
                                            }}>Agenda Jornal</span>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <img style={{ width: '150px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                {
                                                    "BRASÍLIA " +
                                                    diaSemana(dia) +
                                                    ", " +
                                                    dia_n +
                                                    " DE " +
                                                    mesAagora(mes) +
                                                    " DE " +
                                                    ano
                                                }
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Agenda do Congresso Nacional
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '15px',
                                                marginTop: '8px'
                                            }}>
                                                {
                                                    "Período entre " +
                                                    formatDate(new Date(dtIni), "dd/MM/yyyy") +
                                                    " a " +
                                                    formatDate(new Date(dtFin), "dd/MM/yyyy")
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>


                            {/* ================= SENADO FEDERAL =======================
                                ========================================================
                                ======================================================== */}
                            {tipo === "SF" || tipo === "1" || tipo === "T" ?
                                <Grid>
                                    {relatorio.filter((k) => k.eventos.find((i) => i.pautas.every((f) => f.proposicoes.length > 0)))
                                        .filter((q) => q.eventos.find((v) => v.agenda_casa_sigla === "SF"))
                                        .map((x, o) => {
                                            return (
                                                <Grid>
                                                    <tr >
                                                        <td>

                                                            <Grid style={{

                                                                width: '990px',
                                                                marginTop: '30px',
                                                                display: o === 0 ? "block" : "none"

                                                            }}>
                                                                <Grid
                                                                    style={{
                                                                        backgroundColor: `${pallet.general.color1}`,
                                                                        width: "100%",
                                                                        height: "2px",
                                                                    }}
                                                                ></Grid>
                                                                <Grid
                                                                    style={{
                                                                        backgroundColor: `${pallet.general.color1}`,
                                                                        width: "370px",
                                                                        height: "80px",
                                                                        marginLeft: "25px",
                                                                        borderBottomLeftRadius: "10px",
                                                                        borderBottomRightRadius: "30px",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Grid style={{ marginBottom: "8px" }}>
                                                                        <img
                                                                            style={{
                                                                                width: "35px",
                                                                                height: "30px",
                                                                                marginTop: "10px",
                                                                                marginLeft: "10px"
                                                                            }}
                                                                            src={PlayArrowIcon}
                                                                        />
                                                                    </Grid>
                                                                    <span
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize: `${pallet.general.size12}`,
                                                                        }}
                                                                    >
                                                                        SENADO FEDERAL
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <Grid

                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: '990px',

                                                                paddingLeft: '25px'
                                                            }}
                                                        >
                                                            <Grid
                                                                style={{
                                                                    height: "38px",
                                                                    width: "35px",
                                                                    borderBottomLeftRadius: "5px",
                                                                    borderTopLeftRadius: "5px",
                                                                    backgroundColor: pallet.backgroundColorQuaternary,
                                                                    alignItems: "center",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >

                                                                <CalendarTodayIcon />
                                                            </Grid>
                                                            <Grid container
                                                                style={{
                                                                    border: '1px solid #E8E8E8',
                                                                    height: "38px",
                                                                    width: "100%",
                                                                    alignContent: 'center'
                                                                }}
                                                            >
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "3px",
                                                                        marginBottom: "3px",
                                                                        marginLeft: "5px",
                                                                    }}

                                                                >
                                                                    {diaSemana(
                                                                        String(new Date(x.dia).getDay()).padStart(2, "0")
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    style={{
                                                                        color: pallet.general.color1,
                                                                        fontSize: pallet.general.size16,
                                                                        marginLeft: "10px",
                                                                        paddingTop: '3px'
                                                                    }}

                                                                >
                                                                    {moment(x.dia).format("DD/MM")}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </tr>
                                                    {x.eventos
                                                        .filter((b) =>
                                                            b.pautas.every((c) => c.proposicoes.length > 0)
                                                        )
                                                        .filter((v) => v.agenda_casa_sigla === "SF")
                                                        .map((y) => {
                                                            return (

                                                                <Grid style={{ paddingLeft: '25px' }} >
                                                                    <tr>
                                                                        <Grid >
                                                                            <Grid
                                                                                style={{
                                                                                    display: y.comissoes_descricao
                                                                                        ? "flex"
                                                                                        : "none",
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginTop: "20px",
                                                                                    color: pallet.general.color1,
                                                                                }}
                                                                            >
                                                                                {y.comissoes_descricao
                                                                                    ? "| " + y.comissoes_descricao
                                                                                    : ""}
                                                                            </Grid>

                                                                            <Grid
                                                                                style={{
                                                                                    marginTop: "10px",
                                                                                    fontSize: pallet.general.size16,
                                                                                }}
                                                                            >
                                                                                Às {y.horario}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </tr>

                                                                    {y.pautas?.map((w) => {
                                                                        return w.proposicoes.length > 0 ? (
                                                                            <Grid
                                                                                style={{
                                                                                    display:
                                                                                        w.proposicoes.length > 0
                                                                                            ? "flex"
                                                                                            : "none",
                                                                                    flexDirection: "column",
                                                                                }}
                                                                            >
                                                                                <Grid

                                                                                    style={{
                                                                                        marginTop: "15px",
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        style={{
                                                                                            borderRadius: "50%",
                                                                                            border: "2px",
                                                                                            borderColor: "#EAE7E7",
                                                                                            width: "9px",
                                                                                            height: "9px",
                                                                                            marginTop: "6px",
                                                                                            backgroundColor: pallet.general.color1,
                                                                                        }}
                                                                                    ></Grid>
                                                                                    <Grid style={{
                                                                                        fontSize: pallet.general.size16,
                                                                                        marginLeft: "5px",
                                                                                        color: pallet.general.color1,
                                                                                    }}
                                                                                    >
                                                                                        {"ITEM " + w.agenda_pauta_item}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {w.proposicoes?.map((z) => {
                                                                                    return (
                                                                                        <Grid
                                                                                            style={{
                                                                                                marginLeft: "15px",
                                                                                                marginBottom: "5px",
                                                                                                // border: '1px solid red'

                                                                                            }}
                                                                                        >

                                                                                            {/* --------- NUMERO DO PROJETO CD ----------- */}
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "5px",

                                                                                                }}
                                                                                            >
                                                                                                {z.cliente.proposicao_cliente_apelido && user.id_permissao != 4 ?
                                                                                                    <Grid>
                                                                                                        <a href={
                                                                                                            ini_end +
                                                                                                            "//" +
                                                                                                            end2 +
                                                                                                            "/proposicao/" +
                                                                                                            z.proposicao_id
                                                                                                        }
                                                                                                        >
                                                                                                            {
                                                                                                                z.proposicao_descricao
                                                                                                            }
                                                                                                        </a>
                                                                                                        <Grid>{" [" + z.cliente.proposicao_cliente_apelido + "] "}</Grid>
                                                                                                        {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                            <Grid style={{ color: "red" }} >
                                                                                                                <Grid>
                                                                                                                    {" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}
                                                                                                                </Grid>
                                                                                                                <Grid>
                                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                        <Grid style={{ color: "red" }} >
                                                                                                                            {"Ementa: " + z.ementa_proposicao_relacionada + ""}
                                                                                                                        </Grid>
                                                                                                                        : null
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                        }
                                                                                                    </Grid> :
                                                                                                    <Grid>
                                                                                                        <Grid>
                                                                                                            <a href={
                                                                                                                ini_end +
                                                                                                                "//" +
                                                                                                                end2 +
                                                                                                                "/proposicao/" +
                                                                                                                z.proposicao_id
                                                                                                            }
                                                                                                            >
                                                                                                                {
                                                                                                                    z.proposicao_descricao + " "
                                                                                                                }
                                                                                                            </a>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >{" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}</Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        <Grid>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >{"Ementa: " + z.ementa_proposicao_relacionada + ""}</Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                }
                                                                                            </Grid>





                                                                                            {/* --------- AUTORES CD ----------- */}

                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                {z.autores.length > 1 ? (
                                                                                                    <Grid container
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size14,
                                                                                                            marginTop: "10px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid item>Autores: </Grid>
                                                                                                        <Grid item>
                                                                                                            {z.autores?.map((k, i) => {
                                                                                                                return (
                                                                                                                    <Grid container>
                                                                                                                        <Grid item style={{ width: '100%' }}>
                                                                                                                            <a href={ini_end + "//" + end2 + "/interlocutor/" + k.proposicao_autores_id_parlamentar}>
                                                                                                                                {z.autores.length > 1 ? ' ' + k.proposicao_autores_nome + ', ' : ' ' + k.proposicao_autores_nome}
                                                                                                                            </a>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                );
                                                                                                            })}
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                ) : z.autores.length == 1 ? (
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size14,
                                                                                                            marginTop: "10px",
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row"

                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid>Autor: </Grid>
                                                                                                        {z.autores?.map((k) => {
                                                                                                            return (
                                                                                                                <a href={
                                                                                                                    ini_end +
                                                                                                                    "//" +
                                                                                                                    end2 +
                                                                                                                    "/interlocutor/" +
                                                                                                                    k.proposicao_autores_id_parlamentar
                                                                                                                }
                                                                                                                >
                                                                                                                    {k.proposicao_autores_nome}
                                                                                                                </a>
                                                                                                            );
                                                                                                        })}
                                                                                                    </Grid>
                                                                                                ) : (
                                                                                                    <Grid
                                                                                                        style={{ display: "none" }}
                                                                                                    ></Grid>
                                                                                                )}
                                                                                            </Grid>


                                                                                            <Grid>

                                                                                                {/* --------- EMENTA CD ----------- */}

                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        marginTop: "10px",
                                                                                                    }}
                                                                                                >
                                                                                                    {z.proposicao_ementa}
                                                                                                </Grid>





                                                                                                {/* --------- PARECER DO RELATOR CD ----------- */}

                                                                                                <Grid>
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            marginTop: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Parecer do relator:   {w.agenda_pauta_parecer_relator !== "" && w.agenda_pauta_parecer_relator !== null && w.agenda_pauta_parecer_relator !== undefined ? w.agenda_pauta_parecer_relator : "Não informado"}
                                                                                                    </Grid>
                                                                                                </Grid>




                                                                                                {/* --------- POSICIONAMENTO CD ----------- */}
                                                                                                {user.id_permissao != 4 ?
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                            marginTop: "4px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                            }}
                                                                                                        >
                                                                                                            Posicionamento:{" "}
                                                                                                        </Grid>

                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                borderRadius: "50%",
                                                                                                                marginRight: "3px",
                                                                                                                marginTop: "7px",
                                                                                                                height: "8px",
                                                                                                                width: "8px",
                                                                                                                marginLeft: "10px",
                                                                                                                backgroundColor: posicionamentoColor(
                                                                                                                    posicionamentoDescricao(
                                                                                                                        z.cliente
                                                                                                                            .proposicao_cliente_posicaoDaOrganizacao
                                                                                                                    )
                                                                                                                ),
                                                                                                            }}
                                                                                                        ></Grid>

                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                            }}
                                                                                                        >
                                                                                                            {posicionamentoDescricao(
                                                                                                                z.cliente
                                                                                                                    .proposicao_cliente_posicaoDaOrganizacao
                                                                                                            )}
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    :
                                                                                                    null
                                                                                                }



                                                                                                {/* --------- POSICIONAMENTO DETALHADO CD ----------- */}
                                                                                                {user.id_permissao != 4 ?
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                            marginTop: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                color: "black",
                                                                                                            }}
                                                                                                        >
                                                                                                            Posicionamento Detalhado:{" "}
                                                                                                            {
                                                                                                                z.acoes_preparatorias.length === 0 ?
                                                                                                                    <Grid style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        color: "black",
                                                                                                                    }}>Não definido</Grid>


                                                                                                                    :
                                                                                                                    <Grid style={{ display: "none" }}></Grid>
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    :
                                                                                                    null}


                                                                                                  

                                                                                                {/* --------- ACOES PREPARATORIA CD ----------- */}

                                                                                                {z.acoes_preparatorias.length > 0 && user.id_permissao != 4 ?
                                                                                                    z.acoes_preparatorias.map((t) => {
                                                                                                        return (

                                                                                                            t.nota_tecnica.map((q) => {
                                                                                                                return (
                                                                                                                    <Grid

                                                                                                                        style={{
                                                                                                                            display: "flex",
                                                                                                                            flexDirection: "row",
                                                                                                                            marginLeft: "14px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {q.nota_tecnica_responsavel ? q.nota_tecnica_departamento + " | " + q.nota_tecnica_responsavel + " " : q.nota_tecnica_departamento + " "}
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                borderRadius: "50%",
                                                                                                                                marginTop: "10px",
                                                                                                                                marginRight: "3px",
                                                                                                                                height: "8px",
                                                                                                                                width: "8px",
                                                                                                                                backgroundColor: posicionamentoColor(
                                                                                                                                    posicionamentoDescricao(
                                                                                                                                        q.nota_tecnica_posicionamento
                                                                                                                                    )
                                                                                                                                ),
                                                                                                                            }}
                                                                                                                        ></Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {posicionamentoDescricao(
                                                                                                                                q.nota_tecnica_posicionamento
                                                                                                                            )},
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                borderRadius: "50%",
                                                                                                                                marginTop: "10px",
                                                                                                                                marginRight: "3px",
                                                                                                                                height: "8px",
                                                                                                                                width: "8px",
                                                                                                                                backgroundColor: prioridadeColor(prioridadeDescricao(q.nota_tecnica_prioridade)),
                                                                                                                            }}
                                                                                                                        ></Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {prioridadeDescricao(q.nota_tecnica_prioridade) ? prioridadeDescricao(q.nota_tecnica_prioridade) : "Não definida"}

                                                                                                                        </Grid>
                                                                                                                    </Grid>




                                                                                                                )
                                                                                                            })


                                                                                                        );
                                                                                                    })
                                                                                                    :
                                                                                                    <Grid style={{ display: "none" }}></Grid>
                                                                                                }

                                                                                                {/* --------- POSICIONAMENTO POR UNIDADE DE NEGOCIO CD ----------- */}
                                                                                                 {z.posicionamento_unidade_negocio.length > 0 ? 
                                                                                                        <Grid item>Posicionamento Unidade de Negocio:  </Grid>
                                                                                                 :
                                                                                                 <Grid item>Posicionamento Unidade de Negocio:  Não possui</Grid>
                                                                                                     }

                                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                                {z.posicionamento_unidade_negocio.length > 0 ? 
                                                                                                            
                                                                                                            <Grid container
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                marginTop: "5px",

                                                                                                            }}
                                                                                                        >
                                                                                                                    
                                                                                                                    <Grid item >
                                                                                                                        {z.posicionamento_unidade_negocio?.map((k, i) => {
                                                                                                                            return (
                                                                                                                                <Grid  container>
                                                                                                                                    <Grid item style={{ width: '100%', display: "flex",
                                                                                                                                   flexDirection: "row",margin:"1px solid red" }}>
                            
                                                                                                                                    {k.unidade_negocio_descricao}    
                                                                                                                                    <Grid                                            
                                                                                                                                    style={{
                                                                                                                                        borderRadius: "50%",
                                                                                                                                        marginRight: "3px",
                                                                                                                                        marginTop: "7px",
                                                                                                                                        height: "8px",
                                                                                                                                        width: "8px",
                                                                                                                                        marginLeft: "10px",
                                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                                            posicionamentoDescricao(
                                                                                                                                                k.unidade_negocio_posicionamento
                                                                                                                                            )
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></Grid>

                                                                                                                                <Grid
                                                                                                                                    style={{
                                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {posicionamentoDescricao(
                                                                                                                                        k.unidade_negocio_posicionamento
                                                                                                                                    )}
                                                                                                                            
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                        )})}
                                                                                                                        
                                                                                                                    </Grid>
                                                                                                        </Grid>
                                                                                                    
                                                                                                                        
                                                                                                                        
                                                                                                                : 
                                                                                                                    <Grid
                                                                                                                        style={{ display: "none" }}
                                                                                                                    ></Grid>}
                                                                                                </Grid>

  
                                                                                                        


                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    );
                                                                                })}
                                                                            </Grid>
                                                                        ) : (
                                                                            <Grid style={{ display: "none" }}></Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            );
                                                        })}



                                                </Grid>
                                            );
                                        })}
                                </Grid>
                                :
                                null
                            }



                            {/* ================= CAMARA DOS DEPUTADOS =================
                                ========================================================
                                ======================================================== */}

                            {tipo === "CD" || tipo === "2" || tipo === "T" ?
                                <Grid>
                                    {relatorio.filter((k) => k.eventos.find((i) => i.pautas.every((f) => f.proposicoes.length > 0)))
                                        .filter((q) => q.eventos.find((v) => v.agenda_casa_sigla === "CD"))
                                        .map((x, o) => {
                                            return (
                                                <Grid>
                                                    <tr >
                                                        <td>
                                                            <Grid style={{

                                                                width: '990px',
                                                                marginTop: '30px',
                                                                display: o === 0 ? "block" : "none"

                                                            }}>
                                                                <Grid
                                                                    style={{
                                                                        backgroundColor: `${pallet.general.color1}`,
                                                                        width: "100%",
                                                                        height: "2px",
                                                                    }}
                                                                ></Grid>
                                                                <Grid
                                                                    style={{
                                                                        backgroundColor: `${pallet.general.color1}`,
                                                                        width: "370px",
                                                                        height: "80px",
                                                                        marginLeft: "25px",
                                                                        borderBottomLeftRadius: "10px",
                                                                        borderBottomRightRadius: "30px",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Grid style={{ marginBottom: "8px" }}>
                                                                        <img
                                                                            style={{
                                                                                width: "35px",
                                                                                height: "30px",
                                                                                marginTop: "10px",
                                                                                marginLeft: "10px"
                                                                            }}
                                                                            src={PlayArrowIcon}
                                                                        />
                                                                    </Grid>
                                                                    <span
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize: `${pallet.general.size12}`,
                                                                        }}
                                                                    >
                                                                        CÂMARA DOS DEPUTADOS
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <Grid

                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: '990px',

                                                                paddingLeft: '25px'
                                                            }}>
                                                            <Grid
                                                                style={{
                                                                    height: "38px",
                                                                    width: "35px",
                                                                    borderBottomLeftRadius: "5px",
                                                                    borderTopLeftRadius: "5px",
                                                                    backgroundColor: pallet.backgroundColorQuaternary,
                                                                    alignItems: "center",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >

                                                                <CalendarTodayIcon />
                                                            </Grid>
                                                            <Grid container
                                                                style={{
                                                                    border: '1px solid #E8E8E8',
                                                                    height: "38px",
                                                                    width: "100%",
                                                                    alignContent: 'center'
                                                                }}
                                                            >
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "3px",
                                                                        marginBottom: "3px",
                                                                        marginLeft: "5px",
                                                                    }}

                                                                >
                                                                    {diaSemana(
                                                                        String(new Date(x.dia).getDay()).padStart(2, "0")
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    style={{
                                                                        color: pallet.general.color1,
                                                                        fontSize: pallet.general.size16,
                                                                        marginLeft: "10px",
                                                                        paddingTop: '3px'
                                                                    }}

                                                                >
                                                                    {moment(x.dia).format("DD/MM")}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </tr>
                                                    {x.eventos
                                                        .filter((b) =>
                                                            b.pautas.every((c) => c.proposicoes.length > 0)
                                                        )
                                                        .filter((v) => v.agenda_casa_sigla === "CD")
                                                        .map((y) => {
                                                            return (
                                                                <Grid style={{ paddingLeft: '25px' }} >
                                                                    <tr>
                                                                        <Grid >
                                                                            <Grid
                                                                                style={{
                                                                                    display: y.comissoes_descricao
                                                                                        ? "flex"
                                                                                        : "none",
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginTop: "20px",
                                                                                    color: pallet.general.color1,
                                                                                }}
                                                                            >
                                                                                {y.comissoes_descricao
                                                                                    ? "| " + y.comissoes_descricao
                                                                                    : ""}
                                                                            </Grid>

                                                                            <Grid
                                                                                style={{
                                                                                    marginTop: "10px",
                                                                                    fontSize: pallet.general.size16,
                                                                                }}
                                                                            >
                                                                                Às {y.horario}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </tr>

                                                                    {y.pautas?.map((w) => {
                                                                        return w.proposicoes.length > 0 ? (
                                                                            <Grid
                                                                                style={{
                                                                                    display:
                                                                                        w.proposicoes.length > 0
                                                                                            ? "flex"
                                                                                            : "none",
                                                                                    flexDirection: "column",
                                                                                }}
                                                                            >
                                                                                <Grid

                                                                                    style={{
                                                                                        marginTop: "15px",
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        style={{
                                                                                            borderRadius: "50%",
                                                                                            border: "2px",
                                                                                            borderColor: "#EAE7E7",
                                                                                            width: "9px",
                                                                                            height: "9px",
                                                                                            marginTop: "6px",
                                                                                            backgroundColor: pallet.general.color1,
                                                                                        }}
                                                                                    ></Grid>
                                                                                    <Grid style={{
                                                                                        fontSize: pallet.general.size16,
                                                                                        marginLeft: "5px",
                                                                                        color: pallet.general.color1,
                                                                                    }}
                                                                                    >
                                                                                        {"ITEM " + w.agenda_pauta_item}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {w.proposicoes?.map((z) => {
                                                                                    return (
                                                                                        <Grid
                                                                                            style={{
                                                                                                marginLeft: "15px",
                                                                                                marginBottom: "5px",
                                                                                                // border: '1px solid red'

                                                                                            }}
                                                                                        >

                                                                                            {/* --------- NUMERO DO PROJETO CD ----------- */}
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "5px",

                                                                                                }}
                                                                                            >
                                                                                                {z.cliente.proposicao_cliente_apelido && user.id_permissao != 4 ?
                                                                                                    <Grid>
                                                                                                        <a href={
                                                                                                            ini_end +
                                                                                                            "//" +
                                                                                                            end2 +
                                                                                                            "/proposicao/" +
                                                                                                            z.proposicao_id
                                                                                                        }
                                                                                                        >
                                                                                                            {
                                                                                                                z.proposicao_descricao
                                                                                                            }
                                                                                                        </a>
                                                                                                        <Grid>{" [" + z.cliente.proposicao_cliente_apelido + "] "}</Grid>
                                                                                                        {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                            <Grid style={{ color: "red" }} >
                                                                                                                <Grid>
                                                                                                                    {" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}
                                                                                                                </Grid>
                                                                                                                <Grid>
                                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                        <Grid style={{ color: "red" }} >
                                                                                                                            {"Ementa: " + z.ementa_proposicao_relacionada + ""}
                                                                                                                        </Grid>
                                                                                                                        : null
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                        }
                                                                                                    </Grid> :
                                                                                                    <Grid>
                                                                                                        <Grid>
                                                                                                            <a href={
                                                                                                                ini_end +
                                                                                                                "//" +
                                                                                                                end2 +
                                                                                                                "/proposicao/" +
                                                                                                                z.proposicao_id
                                                                                                            }
                                                                                                            >
                                                                                                                {
                                                                                                                    z.proposicao_descricao + " "
                                                                                                                }
                                                                                                            </a>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >{" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}</Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        <Grid>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >{"Ementa: " + z.ementa_proposicao_relacionada + ""}</Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                }
                                                                                            </Grid>





                                                                                            {/* --------- AUTORES CD ----------- */}
                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                {z.autores.length > 1 ? (
                                                                                                    <Grid container
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size14,
                                                                                                            marginTop: "10px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid item>Autores: </Grid>
                                                                                                        <Grid item>
                                                                                                            {z.autores?.map((k, i) => {
                                                                                                                return (
                                                                                                                    <Grid container>
                                                                                                                        <Grid item style={{ width: '100%' }}>
                                                                                                                            <a href={ini_end + "//" + end2 + "/interlocutor/" + k.proposicao_autores_id_parlamentar}>
                                                                                                                                {z.autores.length > 1 ? ' ' + k.proposicao_autores_nome + ', ' : ' ' + k.proposicao_autores_nome}
                                                                                                                            </a>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                );
                                                                                                            })}
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                ) : z.autores.length == 1 ? (
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size14,
                                                                                                            marginTop: "10px",
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row"

                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid>Autor: </Grid>
                                                                                                        {z.autores?.map((k) => {
                                                                                                            return (
                                                                                                                <a href={
                                                                                                                    ini_end +
                                                                                                                    "//" +
                                                                                                                    end2 +
                                                                                                                    "/interlocutor/" +
                                                                                                                    k.proposicao_autores_id_parlamentar
                                                                                                                }
                                                                                                                >
                                                                                                                    {k.proposicao_autores_nome}
                                                                                                                </a>
                                                                                                            );
                                                                                                        })}
                                                                                                    </Grid>
                                                                                                ) : (
                                                                                                    <Grid
                                                                                                        style={{ display: "none" }}
                                                                                                    ></Grid>
                                                                                                )}
                                                                                            </Grid>




                                                                                            <Grid>

                                                                                                {/* --------- EMENTA CD ----------- */}

                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        marginTop: "10px",
                                                                                                    }}
                                                                                                >
                                                                                                    {z.proposicao_ementa}
                                                                                                </Grid>





                                                                                                {/* --------- PARECER DO RELATOR CD ----------- */}

                                                                                                <Grid>
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            marginTop: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Parecer do relator:   {w.agenda_pauta_parecer_relator !== "" && w.agenda_pauta_parecer_relator !== null && w.agenda_pauta_parecer_relator !== undefined ? w.agenda_pauta_parecer_relator : "Não informado"}
                                                                                                    </Grid>
                                                                                                </Grid>




                                                                                                {/* --------- POSICIONAMENTO CD ----------- */}
                                                                                                {user.id_permissao != 4 ?
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                            marginTop: "4px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                            }}
                                                                                                        >
                                                                                                            Posicionamento:{" "}
                                                                                                        </Grid>

                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                borderRadius: "50%",
                                                                                                                marginRight: "3px",
                                                                                                                marginTop: "7px",
                                                                                                                height: "8px",
                                                                                                                width: "8px",
                                                                                                                marginLeft: "10px",
                                                                                                                backgroundColor: posicionamentoColor(
                                                                                                                    posicionamentoDescricao(
                                                                                                                        z.cliente
                                                                                                                            .proposicao_cliente_posicaoDaOrganizacao
                                                                                                                    )
                                                                                                                ),
                                                                                                            }}
                                                                                                        ></Grid>

                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                            }}
                                                                                                        >
                                                                                                            {posicionamentoDescricao(
                                                                                                                z.cliente
                                                                                                                    .proposicao_cliente_posicaoDaOrganizacao
                                                                                                            )}
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    :
                                                                                                    null
                                                                                                }



                                                                                                {/* --------- POSICIONAMENTO DETALHADO CD ----------- */}
                                                                                                {user.id_permissao != 4 ?
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                            marginTop: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                color: "black",
                                                                                                            }}
                                                                                                        >
                                                                                                            Posicionamento Detalhado:{" "}
                                                                                                            {
                                                                                                                z.acoes_preparatorias.length === 0 ?
                                                                                                                    <Grid style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        color: "black",
                                                                                                                    }}>Não definido</Grid>


                                                                                                                    :
                                                                                                                    <Grid style={{ display: "none" }}></Grid>
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    :
                                                                                                    null}


    




                                                                                                {/* --------- ACOES PREPARATORIA CD ----------- */}

                                                                                                {z.acoes_preparatorias.length > 0 && user.id_permissao != 4 ?
                                                                                                    z.acoes_preparatorias.map((t) => {
                                                                                                        return (

                                                                                                            t.nota_tecnica.map((q) => {
                                                                                                                return (
                                                                                                                    <Grid

                                                                                                                        style={{
                                                                                                                            display: "flex",
                                                                                                                            flexDirection: "row",
                                                                                                                            marginLeft: "14px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {q.nota_tecnica_responsavel ? q.nota_tecnica_departamento + " | " + q.nota_tecnica_responsavel + " " : q.nota_tecnica_departamento + " "}
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                borderRadius: "50%",
                                                                                                                                marginTop: "10px",
                                                                                                                                marginRight: "3px",
                                                                                                                                height: "8px",
                                                                                                                                width: "8px",
                                                                                                                                backgroundColor: posicionamentoColor(
                                                                                                                                    posicionamentoDescricao(
                                                                                                                                        q.nota_tecnica_posicionamento
                                                                                                                                    )
                                                                                                                                ),
                                                                                                                            }}
                                                                                                                        ></Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {posicionamentoDescricao(
                                                                                                                                q.nota_tecnica_posicionamento
                                                                                                                            )},
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                borderRadius: "50%",
                                                                                                                                marginTop: "10px",
                                                                                                                                height: "8px",
                                                                                                                                marginRight: "3px",
                                                                                                                                width: "8px",
                                                                                                                                backgroundColor: prioridadeColor(prioridadeDescricao(q.nota_tecnica_prioridade)),
                                                                                                                            }}
                                                                                                                        ></Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {prioridadeDescricao(q.nota_tecnica_prioridade) ? prioridadeDescricao(q.nota_tecnica_prioridade) : "Não definida"}

                                                                                                                        </Grid>
                                                                                                                    </Grid>




                                                                                                                )
                                                                                                            })


                                                                                                        );
                                                                                                    })
                                                                                                    :
                                                                                                    <Grid style={{ display: "none" }}></Grid>
                                                                                                }
                                                                                                 {/* --------- POSICIONAMENTO POR UNIDADE DE NEGOCIO CD ----------- */}
                                                                                                 {z.posicionamento_unidade_negocio.length > 0 ? 
                                                                                                        <Grid item>Posicionamento Unidade de Negocio:  </Grid>
                                                                                                 :
                                                                                                 <Grid item>Posicionamento Unidade de Negocio:  Não possui</Grid>
                                                                                                     }

                                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                                {z.posicionamento_unidade_negocio.length > 0 ? 
                                                                                                            
                                                                                                            <Grid container
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                marginTop: "5px",

                                                                                                            }}
                                                                                                        >
                                                                                                                    
                                                                                                                    <Grid item >
                                                                                                                        {z.posicionamento_unidade_negocio?.map((k, i) => {
                                                                                                                            return (
                                                                                                                                <Grid  container>
                                                                                                                                    <Grid item style={{ width: '100%', display: "flex",
                                                                                                                                   flexDirection: "row",margin:"1px solid red" }}>
                            
                                                                                                                                    {k.unidade_negocio_descricao}    
                                                                                                                                    <Grid                                            
                                                                                                                                    style={{
                                                                                                                                        borderRadius: "50%",
                                                                                                                                        marginRight: "3px",
                                                                                                                                        marginTop: "7px",
                                                                                                                                        height: "8px",
                                                                                                                                        width: "8px",
                                                                                                                                        marginLeft: "10px",
                                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                                            posicionamentoDescricao(
                                                                                                                                                k.unidade_negocio_posicionamento
                                                                                                                                            )
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></Grid>

                                                                                                                                <Grid
                                                                                                                                    style={{
                                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {posicionamentoDescricao(
                                                                                                                                        k.unidade_negocio_posicionamento
                                                                                                                                    )}
                                                                                                                            
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                        )})}
                                                                                                                        
                                                                                                                    </Grid>
                                                                                                        </Grid>
                                                                                                    
                                                                                                                        
                                                                                                                        
                                                                                                                : 
                                                                                                                    <Grid
                                                                                                                        style={{ display: "none" }}
                                                                                                                    ></Grid>}
                                                                                                </Grid>
                             
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    );
                                                                                })}
                                                                            </Grid>
                                                                        ) : (
                                                                            <Grid style={{ display: "none" }}></Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            );
                                                        })}



                                                </Grid>
                                            );
                                        })}
                                </Grid>
                                :
                                null
                            }


                            {/* ================= CONGRESSO NACIONAL ===================
                                ========================================================
                                ======================================================== */}

                            {tipo === "CN" || tipo === "3" || tipo === "T" ?
                                <Grid>
                                    {relatorio.filter((k) => k.eventos.find((i) => i.pautas.every((f) => f.proposicoes.length > 0)))
                                        .filter((q) => q.eventos.find((v) => v.agenda_casa_sigla === "CN"))
                                        .map((x, o) => {
                                            return (
                                                <Grid>
                                                    <tr >
                                                        <td>
                                                            <Grid style={{

                                                                width: '990px',
                                                                marginTop: '30px',
                                                                display: o === 0 ? "block" : "none"

                                                            }}>
                                                                <Grid
                                                                    style={{
                                                                        backgroundColor: `${pallet.general.color1}`,
                                                                        width: "100%",
                                                                        height: "2px",
                                                                    }}
                                                                ></Grid>
                                                                <Grid
                                                                    style={{
                                                                        backgroundColor: `${pallet.general.color1}`,
                                                                        width: "370px",
                                                                        height: "80px",
                                                                        marginLeft: "25px",
                                                                        borderBottomLeftRadius: "10px",
                                                                        borderBottomRightRadius: "30px",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Grid style={{ marginBottom: "8px" }}>
                                                                        <img
                                                                            style={{
                                                                                width: "35px",
                                                                                height: "30px",
                                                                                marginTop: "10px",
                                                                                marginLeft: "10px"
                                                                            }}
                                                                            src={PlayArrowIcon}
                                                                        />
                                                                    </Grid>
                                                                    <span
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize: `${pallet.general.size12}`,
                                                                        }}
                                                                    >
                                                                        CONGRESSO NACIONAL
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <Grid

                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: '990px',

                                                                paddingLeft: '25px'
                                                            }}>
                                                            <Grid
                                                                style={{
                                                                    height: "38px",
                                                                    width: "35px",
                                                                    borderBottomLeftRadius: "5px",
                                                                    borderTopLeftRadius: "5px",
                                                                    backgroundColor: pallet.backgroundColorQuaternary,
                                                                    alignItems: "center",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >

                                                                <CalendarTodayIcon />
                                                            </Grid>
                                                            <Grid container
                                                                style={{
                                                                    border: '1px solid #E8E8E8',
                                                                    height: "38px",
                                                                    width: "100%",
                                                                    alignContent: 'center'
                                                                }}
                                                            >
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "3px",
                                                                        marginBottom: "3px",
                                                                        marginLeft: "5px",
                                                                    }}

                                                                >
                                                                    {diaSemana(
                                                                        String(new Date(x.dia).getDay()).padStart(2, "0")
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    style={{
                                                                        color: pallet.general.color1,
                                                                        fontSize: pallet.general.size16,
                                                                        marginLeft: "10px",
                                                                        paddingTop: '3px'
                                                                    }}

                                                                >
                                                                    {moment(x.dia).format("DD/MM")}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </tr>
                                                    {x.eventos
                                                        .filter((b) =>
                                                            b.pautas.every((c) => c.proposicoes.length > 0)
                                                        )
                                                        .filter((v) => v.agenda_casa_sigla === "CN")
                                                        .map((y) => {
                                                            return (

                                                                <Grid style={{ paddingLeft: '25px' }} >
                                                                    <tr>
                                                                        <Grid >
                                                                            <Grid
                                                                                style={{
                                                                                    display: y.comissoes_descricao
                                                                                        ? "flex"
                                                                                        : "none",
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginTop: "20px",
                                                                                    color: pallet.general.color1,
                                                                                }}
                                                                            >
                                                                                {y.comissoes_descricao
                                                                                    ? "| " + y.comissoes_descricao
                                                                                    : ""}
                                                                            </Grid>

                                                                            <Grid
                                                                                style={{
                                                                                    marginTop: "10px",
                                                                                    fontSize: pallet.general.size16,
                                                                                }}
                                                                            >
                                                                                Às {y.horario}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </tr>

                                                                    {y.pautas?.map((w) => {
                                                                        return w.proposicoes.length > 0 ? (
                                                                            <Grid
                                                                                style={{
                                                                                    display:
                                                                                        w.proposicoes.length > 0
                                                                                            ? "flex"
                                                                                            : "none",
                                                                                    flexDirection: "column",
                                                                                }}
                                                                            >
                                                                                <Grid

                                                                                    style={{
                                                                                        marginTop: "15px",
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        style={{
                                                                                            borderRadius: "50%",
                                                                                            border: "2px",
                                                                                            borderColor: "#EAE7E7",
                                                                                            width: "9px",
                                                                                            height: "9px",
                                                                                            marginTop: "6px",
                                                                                            backgroundColor: pallet.general.color1,
                                                                                        }}
                                                                                    ></Grid>
                                                                                    <Grid style={{
                                                                                        fontSize: pallet.general.size16,
                                                                                        marginLeft: "5px",
                                                                                        color: pallet.general.color1,
                                                                                    }}
                                                                                    >
                                                                                        {"ITEM " + w.agenda_pauta_item}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {w.proposicoes?.map((z) => {
                                                                                    return (
                                                                                        <Grid
                                                                                            style={{
                                                                                                marginLeft: "15px",
                                                                                                marginBottom: "5px",
                                                                                                // border: '1px solid red'

                                                                                            }}
                                                                                        >

                                                                                            {/* --------- NUMERO DO PROJETO CD ----------- */}
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "5px",

                                                                                                }}
                                                                                            >
                                                                                                {z.cliente.proposicao_cliente_apelido && user.id_permissao != 4 ?
                                                                                                    <Grid>
                                                                                                        <a href={
                                                                                                            ini_end +
                                                                                                            "//" +
                                                                                                            end2 +
                                                                                                            "/proposicao/" +
                                                                                                            z.proposicao_id
                                                                                                        }
                                                                                                        >
                                                                                                            {
                                                                                                                z.proposicao_descricao
                                                                                                            }
                                                                                                        </a>
                                                                                                        <Grid>{" [" + z.cliente.proposicao_cliente_apelido + "] "}</Grid>
                                                                                                        {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                            <Grid style={{ color: "red" }} >
                                                                                                                <Grid>
                                                                                                                    {" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}
                                                                                                                </Grid>
                                                                                                                <Grid>
                                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                        <Grid style={{ color: "red" }} >
                                                                                                                            {"Ementa: " + z.ementa_proposicao_relacionada + ""}
                                                                                                                        </Grid>
                                                                                                                        : null
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                        }
                                                                                                    </Grid> :
                                                                                                    <Grid>
                                                                                                        <Grid>
                                                                                                            <a href={
                                                                                                                ini_end +
                                                                                                                "//" +
                                                                                                                end2 +
                                                                                                                "/proposicao/" +
                                                                                                                z.proposicao_id
                                                                                                            }
                                                                                                            >
                                                                                                                {
                                                                                                                    z.proposicao_descricao + " "
                                                                                                                }
                                                                                                            </a>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >{" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}</Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        <Grid>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >{"Ementa: " + z.ementa_proposicao_relacionada + ""}</Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                }
                                                                                            </Grid>





                                                                                            {/* --------- AUTORES CD ----------- */}
                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                {z.autores.length > 1 ? (
                                                                                                    <Grid container
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size14,
                                                                                                            marginTop: "10px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid item>Autores: </Grid>
                                                                                                        <Grid item>
                                                                                                            {z.autores?.map((k, i) => {
                                                                                                                return (
                                                                                                                    <Grid container>
                                                                                                                        <Grid item style={{ width: '100%' }}>
                                                                                                                            <a href={ini_end + "//" + end2 + "/interlocutor/" + k.proposicao_autores_id_parlamentar}>
                                                                                                                                {z.autores.length > 1 ? ' ' + k.proposicao_autores_nome + ', ' : ' ' + k.proposicao_autores_nome}
                                                                                                                            </a>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                );
                                                                                                            })}
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                ) : z.autores.length == 1 ? (
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            marginTop: "10px",
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row"

                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid>Autor: </Grid>
                                                                                                        {z.autores?.map((k) => {
                                                                                                            return (
                                                                                                                <a href={
                                                                                                                    ini_end +
                                                                                                                    "//" +
                                                                                                                    end2 +
                                                                                                                    "/interlocutor/" +
                                                                                                                    k.proposicao_autores_id_parlamentar
                                                                                                                }
                                                                                                                >
                                                                                                                    {k.proposicao_autores_nome}
                                                                                                                </a>
                                                                                                            );
                                                                                                        })}
                                                                                                    </Grid>
                                                                                                ) : (
                                                                                                    <Grid
                                                                                                        style={{ display: "none" }}
                                                                                                    ></Grid>
                                                                                                )}
                                                                                            </Grid>


                                                                                            <Grid>

                                                                                                {/* --------- EMENTA CD ----------- */}

                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        marginTop: "10px",
                                                                                                    }}
                                                                                                >
                                                                                                    {z.proposicao_ementa}
                                                                                                </Grid>





                                                                                                {/* --------- PARECER DO RELATOR CD ----------- */}

                                                                                                <Grid>
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            marginTop: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Parecer do relator:   {w.agenda_pauta_parecer_relator !== "" && w.agenda_pauta_parecer_relator !== null && w.agenda_pauta_parecer_relator !== undefined ? w.agenda_pauta_parecer_relator : "Não informado"}
                                                                                                    </Grid>
                                                                                                </Grid>




                                                                                                {/* --------- POSICIONAMENTO CD ----------- */}
                                                                                                {user.id_permissao != 4 ?
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                            marginTop: "4px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                            }}
                                                                                                        >
                                                                                                            Posicionamento:{" "}
                                                                                                        </Grid>

                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                borderRadius: "50%",
                                                                                                                marginRight: "3px",
                                                                                                                marginTop: "7px",
                                                                                                                height: "8px",
                                                                                                                width: "8px",
                                                                                                                marginLeft: "10px",
                                                                                                                backgroundColor: posicionamentoColor(
                                                                                                                    posicionamentoDescricao(
                                                                                                                        z.cliente
                                                                                                                            .proposicao_cliente_posicaoDaOrganizacao
                                                                                                                    )
                                                                                                                ),
                                                                                                            }}
                                                                                                        ></Grid>

                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                            }}
                                                                                                        >
                                                                                                            {posicionamentoDescricao(
                                                                                                                z.cliente
                                                                                                                    .proposicao_cliente_posicaoDaOrganizacao
                                                                                                            )}
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    :
                                                                                                    null
                                                                                                }



                                                                                                {/* --------- POSICIONAMENTO DETALHADO CD ----------- */}
                                                                                                {user.id_permissao != 4 ?
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                            marginTop: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                color: "black",
                                                                                                            }}
                                                                                                        >
                                                                                                            Posicionamento Detalhado:{" "}
                                                                                                            {
                                                                                                                z.acoes_preparatorias.length === 0 ?
                                                                                                                    <Grid style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        color: "black",
                                                                                                                    }}>Não definido</Grid>


                                                                                                                    :
                                                                                                                    <Grid style={{ display: "none" }}></Grid>
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    :
                                                                                                    null}







                                                                                                {/* --------- ACOES PREPARATORIA CD ----------- */}

                                                                                                {z.acoes_preparatorias.length > 0 && user.id_permissao != 4 ?
                                                                                                    z.acoes_preparatorias.map((t) => {
                                                                                                        return (

                                                                                                            t.nota_tecnica.map((q) => {
                                                                                                                return (
                                                                                                                    <Grid

                                                                                                                        style={{
                                                                                                                            display: "flex",
                                                                                                                            flexDirection: "row",
                                                                                                                            marginLeft: "14px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {q.nota_tecnica_responsavel ? q.nota_tecnica_departamento + " | " + q.nota_tecnica_responsavel + " " : q.nota_tecnica_departamento + " "}
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                borderRadius: "50%",
                                                                                                                                marginTop: "10px",
                                                                                                                                marginRight: "3px",
                                                                                                                                height: "8px",
                                                                                                                                width: "8px",
                                                                                                                                backgroundColor: posicionamentoColor(
                                                                                                                                    posicionamentoDescricao(
                                                                                                                                        q.nota_tecnica_posicionamento
                                                                                                                                    )
                                                                                                                                ),
                                                                                                                            }}
                                                                                                                        ></Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {posicionamentoDescricao(
                                                                                                                                q.nota_tecnica_posicionamento
                                                                                                                            )},
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                borderRadius: "50%",
                                                                                                                                marginTop: "10px",
                                                                                                                                marginRight: "3px",
                                                                                                                                height: "8px",
                                                                                                                                width: "8px",
                                                                                                                                backgroundColor: prioridadeColor(prioridadeDescricao(q.nota_tecnica_prioridade)),
                                                                                                                            }}
                                                                                                                        ></Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {prioridadeDescricao(q.nota_tecnica_prioridade) ? prioridadeDescricao(q.nota_tecnica_prioridade) : "Não definida"}

                                                                                                                        </Grid>
                                                                                                                    </Grid>




                                                                                                                )
                                                                                                            })


                                                                                                        );
                                                                                                    })
                                                                                                    :
                                                                                                    <Grid style={{ display: "none" }}></Grid>
                                                                                                }

                                                                                               {/* --------- POSICIONAMENTO POR UNIDADE DE NEGOCIO CD ----------- */}
                                                                                                 {z.posicionamento_unidade_negocio.length > 0 ? 
                                                                                                        <Grid item>Posicionamento Unidade de Negocio:  </Grid>
                                                                                                 :
                                                                                                 <Grid item>Posicionamento Unidade de Negocio:  Não possui</Grid>
                                                                                                     }

                                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                                {z.posicionamento_unidade_negocio.length > 0 ? 
                                                                                                            
                                                                                                            <Grid container
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                marginTop: "5px",

                                                                                                            }}
                                                                                                        >
                                                                                                                    
                                                                                                                    <Grid item >
                                                                                                                        {z.posicionamento_unidade_negocio?.map((k, i) => {
                                                                                                                            return (
                                                                                                                                <Grid  container>
                                                                                                                                    <Grid item style={{ width: '100%', display: "flex",
                                                                                                                                   flexDirection: "row",margin:"1px solid red" }}>
                            
                                                                                                                                    {k.unidade_negocio_descricao}    
                                                                                                                                    <Grid                                            
                                                                                                                                    style={{
                                                                                                                                        borderRadius: "50%",
                                                                                                                                        marginRight: "3px",
                                                                                                                                        marginTop: "7px",
                                                                                                                                        height: "8px",
                                                                                                                                        width: "8px",
                                                                                                                                        marginLeft: "10px",
                                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                                            posicionamentoDescricao(
                                                                                                                                                k.unidade_negocio_posicionamento
                                                                                                                                            )
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></Grid>

                                                                                                                                <Grid
                                                                                                                                    style={{
                                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {posicionamentoDescricao(
                                                                                                                                        k.unidade_negocio_posicionamento
                                                                                                                                    )}
                                                                                                                            
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                        )})}
                                                                                                                        
                                                                                                                    </Grid>
                                                                                                        </Grid>
                                                                                                    
                                                                                                                        
                                                                                                                        
                                                                                                                : 
                                                                                                                    <Grid
                                                                                                                        style={{ display: "none" }}
                                                                                                                    ></Grid>}
                                                                                                </Grid>


                                                                                                

                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    );
                                                                                })}
                                                                            </Grid>
                                                                        ) : (
                                                                            <Grid style={{ display: "none" }}></Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            );
                                                        })}



                                                </Grid>
                                            );
                                        })}

                                </Grid>
                                :
                                null
                            }



                            {/* ====================== OUTROS ==========================
                                ========================================================
                                ======================================================== */}

                            {tipo === "O" || tipo === "T" ?
                                <Grid>
                                    {relatorio.filter((q) =>
                                        q.eventos.find((v) => v.agenda_integrada === false))
                                        .filter((k) => k.eventos.find((i) => i.pautas.every((f) => f.proposicoes.length > 0)))
                                        .filter((q) => q.eventos.find(
                                            (v) =>
                                                v.agenda_casa_sigla !== "SF" &&
                                                v.agenda_casa_sigla !== "CD" &&
                                                v.agenda_casa_sigla !== "CN"
                                        ))
                                        .map((x, o) => {
                                            return (
                                                <Grid>
                                                    <tr >
                                                        <td>
                                                            <Grid style={{

                                                                width: '990px',
                                                                marginTop: '30px',
                                                                display: o === 0 ? "block" : "none"

                                                            }}>
                                                                <Grid
                                                                    style={{
                                                                        backgroundColor: `${pallet.general.color1}`,
                                                                        width: "100%",
                                                                        height: "2px",
                                                                    }}
                                                                ></Grid>
                                                                <Grid
                                                                    style={{
                                                                        backgroundColor: `${pallet.general.color1}`,
                                                                        width: "370px",
                                                                        height: "80px",
                                                                        marginLeft: "25px",
                                                                        borderBottomLeftRadius: "10px",
                                                                        borderBottomRightRadius: "30px",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Grid style={{ marginBottom: "8px" }}>
                                                                        <img
                                                                            style={{
                                                                                width: "35px",
                                                                                height: "30px",
                                                                                marginTop: "10px",
                                                                                marginLeft: "10px"
                                                                            }}
                                                                            src={PlayArrowIcon}
                                                                        />
                                                                    </Grid>
                                                                    <span
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize: `${pallet.general.size12}`,
                                                                        }}
                                                                    >
                                                                        OUTRAS
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <Grid

                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: '990px',

                                                                paddingLeft: '25px'
                                                            }}>
                                                            <Grid
                                                                style={{
                                                                    height: "38px",
                                                                    width: "35px",
                                                                    borderBottomLeftRadius: "5px",
                                                                    borderTopLeftRadius: "5px",
                                                                    backgroundColor: pallet.backgroundColorQuaternary,
                                                                    alignItems: "center",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >

                                                                <CalendarTodayIcon />
                                                            </Grid>
                                                            <Grid container
                                                                style={{
                                                                    border: '1px solid #E8E8E8',
                                                                    height: "38px",
                                                                    width: "100%",
                                                                    alignContent: 'center'
                                                                }}
                                                            >
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "3px",
                                                                        marginBottom: "3px",
                                                                        marginLeft: "5px",
                                                                    }}

                                                                >
                                                                    {diaSemana(
                                                                        String(new Date(x.dia).getDay()).padStart(2, "0")
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    style={{
                                                                        color: pallet.general.color1,
                                                                        fontSize: pallet.general.size16,
                                                                        marginLeft: "10px",
                                                                        paddingTop: '3px'
                                                                    }}

                                                                >
                                                                    {moment(x.dia).format("DD/MM")}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </tr>
                                                    {x.eventos
                                                        .filter((b) =>
                                                            b.pautas.every((c) => c.proposicoes.length > 0)
                                                        )
                                                        .map((y) => {
                                                            return (
                                                                <Grid style={{ paddingLeft: '25px' }} >
                                                                    <tr>
                                                                        <Grid >
                                                                            <Grid
                                                                                style={{
                                                                                    display: y.comissoes_descricao
                                                                                        ? "flex"
                                                                                        : "none",
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginTop: "20px",
                                                                                    color: pallet.general.color1,
                                                                                }}
                                                                            >
                                                                                {y.comissoes_descricao
                                                                                    ? "| " + y.comissoes_descricao
                                                                                    : ""}
                                                                            </Grid>

                                                                            <Grid
                                                                                style={{
                                                                                    marginTop: "10px",
                                                                                    fontSize: pallet.general.size16,
                                                                                }}
                                                                            >
                                                                                Às {y.horario}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </tr>

                                                                    {y.pautas?.map((w) => {
                                                                        return w.proposicoes.length > 0 ? (
                                                                            <Grid
                                                                                style={{
                                                                                    display:
                                                                                        w.proposicoes.length > 0
                                                                                            ? "flex"
                                                                                            : "none",
                                                                                    flexDirection: "column",
                                                                                }}
                                                                            >
                                                                                <Grid

                                                                                    style={{
                                                                                        marginTop: "15px",
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        style={{
                                                                                            borderRadius: "50%",
                                                                                            border: "2px",
                                                                                            borderColor: "#EAE7E7",
                                                                                            width: "9px",
                                                                                            height: "9px",
                                                                                            marginTop: "6px",
                                                                                            backgroundColor: pallet.general.color1,
                                                                                        }}
                                                                                    ></Grid>
                                                                                    <Grid style={{
                                                                                        fontSize: pallet.general.size16,
                                                                                        marginLeft: "5px",
                                                                                        color: pallet.general.color1,
                                                                                    }}
                                                                                    >
                                                                                        {"ITEM " + w.agenda_pauta_item}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {w.proposicoes?.map((z) => {
                                                                                    return (
                                                                                        <Grid
                                                                                            style={{
                                                                                                marginLeft: "15px",
                                                                                                marginBottom: "5px",
                                                                                                // border: '1px solid red'

                                                                                            }}
                                                                                        >

                                                                                            {/* --------- NUMERO DO PROJETO OUTROS ----------- */}
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "5px",

                                                                                                }}
                                                                                            >
                                                                                                {z.cliente.proposicao_cliente_apelido && user.id_permissao != 4 ?
                                                                                                    <Grid>
                                                                                                        <a href={
                                                                                                            ini_end +
                                                                                                            "//" +
                                                                                                            end2 +
                                                                                                            "/proposicao/" +
                                                                                                            z.proposicao_id
                                                                                                        }
                                                                                                        >
                                                                                                            {
                                                                                                                z.proposicao_descricao
                                                                                                            }
                                                                                                        </a>
                                                                                                        <Grid>{" [" + z.cliente.proposicao_cliente_apelido + "] "}</Grid>
                                                                                                        {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                            <Grid style={{ color: "red" }} >
                                                                                                                <Grid>
                                                                                                                    {" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}
                                                                                                                </Grid>
                                                                                                                <Grid>
                                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                        <Grid style={{ color: "red" }} >
                                                                                                                            {"Ementa: " + z.ementa_proposicao_relacionada + ""}
                                                                                                                        </Grid>
                                                                                                                        : null
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                        }
                                                                                                    </Grid> :
                                                                                                    <Grid>
                                                                                                        <Grid>
                                                                                                            <a href={
                                                                                                                ini_end +
                                                                                                                "//" +
                                                                                                                end2 +
                                                                                                                "/proposicao/" +
                                                                                                                z.proposicao_id
                                                                                                            }
                                                                                                            >
                                                                                                                {
                                                                                                                    z.proposicao_descricao + " "
                                                                                                                }
                                                                                                            </a>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >{" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}</Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        <Grid>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >{"Ementa: " + z.ementa_proposicao_relacionada + ""}</Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                }
                                                                                            </Grid>





                                                                                            {/* --------- AUTORES OUTROS ----------- */}

                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                {z.autores.length > 1 ? (
                                                                                                    <Grid container
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size14,
                                                                                                            marginTop: "10px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid item>Autores: </Grid>
                                                                                                        <Grid item>
                                                                                                            {z.autores?.map((k, i) => {
                                                                                                                return (
                                                                                                                    <Grid container>
                                                                                                                        <Grid item style={{ width: '100%' }}>
                                                                                                                            <a href={ini_end + "//" + end2 + "/interlocutor/" + k.proposicao_autores_id_parlamentar}>
                                                                                                                                {z.autores.length > 1 ? ' ' + k.proposicao_autores_nome + ', ' : ' ' + k.proposicao_autores_nome}
                                                                                                                            </a>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                );
                                                                                                            })}
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                ) : z.autores.length == 1 ? (
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size14,
                                                                                                            marginTop: "10px",
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row"

                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid>Autor: </Grid>
                                                                                                        {z.autores?.map((k) => {
                                                                                                            return (
                                                                                                                <a href={
                                                                                                                    ini_end +
                                                                                                                    "//" +
                                                                                                                    end2 +
                                                                                                                    "/interlocutor/" +
                                                                                                                    k.proposicao_autores_id_parlamentar
                                                                                                                }
                                                                                                                >
                                                                                                                    {k.proposicao_autores_nome}
                                                                                                                </a>
                                                                                                            );
                                                                                                        })}
                                                                                                    </Grid>
                                                                                                ) : (
                                                                                                    <Grid
                                                                                                        style={{ display: "none" }}
                                                                                                    ></Grid>
                                                                                                )}
                                                                                            </Grid>



                                                                                            <Grid>

                                                                                                {/* --------- EMENTA OUTROS ----------- */}

                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        marginTop: "10px",
                                                                                                    }}
                                                                                                >
                                                                                                    {z.proposicao_ementa}
                                                                                                </Grid>





                                                                                                {/* --------- PARECER DO RELATOR OUTROS ----------- */}

                                                                                                <Grid>
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            marginTop: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Parecer do relator:   {w.agenda_pauta_parecer_relator !== "" && w.agenda_pauta_parecer_relator !== null && w.agenda_pauta_parecer_relator !== undefined ? w.agenda_pauta_parecer_relator : "Não informado"}
                                                                                                    </Grid>
                                                                                                </Grid>




                                                                                                {/* --------- POSICIONAMENTO OUTROS ----------- */}
                                                                                                {user.id_permissao != 4 ?
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                            marginTop: "4px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                            }}
                                                                                                        >
                                                                                                            Posicionamento:{" "}
                                                                                                        </Grid>

                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                borderRadius: "50%",
                                                                                                                marginRight: "3px",
                                                                                                                marginTop: "7px",
                                                                                                                height: "8px",
                                                                                                                width: "8px",
                                                                                                                marginLeft: "10px",
                                                                                                                backgroundColor: posicionamentoColor(
                                                                                                                    posicionamentoDescricao(
                                                                                                                        z.cliente
                                                                                                                            .proposicao_cliente_posicaoDaOrganizacao
                                                                                                                    )
                                                                                                                ),
                                                                                                            }}
                                                                                                        ></Grid>

                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                            }}
                                                                                                        >
                                                                                                            {posicionamentoDescricao(
                                                                                                                z.cliente
                                                                                                                    .proposicao_cliente_posicaoDaOrganizacao
                                                                                                            )}
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    :
                                                                                                    null
                                                                                                }



                                                                                                {/* --------- POSICIONAMENTO DETALHADO OUTROS ----------- */}
                                                                                                {user.id_permissao != 4 ?
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                            marginTop: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                color: "black",
                                                                                                            }}
                                                                                                        >
                                                                                                            Posicionamento Detalhado:{" "}
                                                                                                            {
                                                                                                                z.acoes_preparatorias.length === 0 ?
                                                                                                                    <Grid style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        color: "black",
                                                                                                                    }}>Não definido</Grid>


                                                                                                                    :
                                                                                                                    <Grid style={{ display: "none" }}></Grid>
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    :
                                                                                                    null}







                                                                                                {/* --------- ACOES PREPARATORIA OUTROS ----------- */}

                                                                                                {z.acoes_preparatorias.length > 0 && user.id_permissao != 4 ?
                                                                                                    z.acoes_preparatorias.map((t) => {
                                                                                                        return (

                                                                                                            t.nota_tecnica.map((q) => {
                                                                                                                return (
                                                                                                                    <Grid

                                                                                                                        style={{
                                                                                                                            display: "flex",
                                                                                                                            flexDirection: "row",
                                                                                                                            marginLeft: "14px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {q.nota_tecnica_responsavel ? q.nota_tecnica_departamento + " | " + q.nota_tecnica_responsavel + " " : q.nota_tecnica_departamento + " "}
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                borderRadius: "50%",
                                                                                                                                marginTop: "10px",
                                                                                                                                marginRight: "3px",
                                                                                                                                height: "8px",
                                                                                                                                width: "8px",
                                                                                                                                backgroundColor: posicionamentoColor(
                                                                                                                                    posicionamentoDescricao(
                                                                                                                                        q.nota_tecnica_posicionamento
                                                                                                                                    )
                                                                                                                                ),
                                                                                                                            }}
                                                                                                                        ></Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {posicionamentoDescricao(
                                                                                                                                q.nota_tecnica_posicionamento
                                                                                                                            )},
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                borderRadius: "50%",
                                                                                                                                marginTop: "10px",
                                                                                                                                height: "8px",
                                                                                                                                marginRight: "3px",
                                                                                                                                width: "8px",
                                                                                                                                backgroundColor: prioridadeColor(prioridadeDescricao(q.nota_tecnica_prioridade)),
                                                                                                                            }}
                                                                                                                        ></Grid>
                                                                                                                        <Grid
                                                                                                                            style={{
                                                                                                                                fontSize: pallet.general.size16,
                                                                                                                                marginTop: "3px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {prioridadeDescricao(q.nota_tecnica_prioridade) ? prioridadeDescricao(q.nota_tecnica_prioridade) : "Não definida"}

                                                                                                                        </Grid>
                                                                                                                    </Grid>




                                                                                                                )
                                                                                                            })


                                                                                                        );
                                                                                                    })
                                                                                                    :
                                                                                                    <Grid style={{ display: "none" }}></Grid>
                                                                                                }
                                                                                                                                                                                                 {/* --------- POSICIONAMENTO POR UNIDADE DE NEGOCIO CD ----------- */}
                                                                                                                                                                                                 {z.posicionamento_unidade_negocio.length > 0 ? 
                                                                                                        <Grid item>Posicionamento Unidade de Negocio:  </Grid>
                                                                                                 :
                                                                                                 <Grid item>Posicionamento Unidade de Negocio:  Não possui</Grid>
                                                                                                     }

                                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                                {z.posicionamento_unidade_negocio.length > 0 ? 
                                                                                                            
                                                                                                            <Grid container
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                marginTop: "5px",

                                                                                                            }}
                                                                                                        >
                                                                                                                    
                                                                                                                    <Grid item >
                                                                                                                        {z.posicionamento_unidade_negocio?.map((k, i) => {
                                                                                                                            return (
                                                                                                                                <Grid  container>
                                                                                                                                    <Grid item style={{ width: '100%', display: "flex",
                                                                                                                                   flexDirection: "row",margin:"1px solid red" }}>
                            
                                                                                                                                    {k.unidade_negocio_descricao}    
                                                                                                                                    <Grid                                            
                                                                                                                                    style={{
                                                                                                                                        borderRadius: "50%",
                                                                                                                                        marginRight: "3px",
                                                                                                                                        marginTop: "7px",
                                                                                                                                        height: "8px",
                                                                                                                                        width: "8px",
                                                                                                                                        marginLeft: "10px",
                                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                                            posicionamentoDescricao(
                                                                                                                                                k.unidade_negocio_posicionamento
                                                                                                                                            )
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></Grid>

                                                                                                                                <Grid
                                                                                                                                    style={{
                                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {posicionamentoDescricao(
                                                                                                                                        k.unidade_negocio_posicionamento
                                                                                                                                    )}
                                                                                                                            
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                        )})}
                                                                                                                        
                                                                                                                    </Grid>
                                                                                                        </Grid>
                                                                                                    
                                                                                                                        
                                                                                                                        
                                                                                                                : 
                                                                                                                    <Grid
                                                                                                                        style={{ display: "none" }}
                                                                                                                    ></Grid>}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    );
                                                                                })}
                                                                            </Grid>
                                                                        ) : (
                                                                            <Grid style={{ display: "none" }}></Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            );
                                                        })}
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                                :
                                null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
