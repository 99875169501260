import * as http from '../helpers/http';

class ParlamentarProposicaoService {

    GetSummary = async (id: number): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}/proposicoes/summary`);
        if (result.success) {
            return {
                success: true,
                data: result.data
            }
        }
        return undefined;
    }

    GetList = async (id: number, pageIndex: number, pageSize: number = 8): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}/proposicoes?pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                lista: result.data.data
            }
        }
        return undefined;
    }


}

export default new ParlamentarProposicaoService();
