import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import useStyles from "../../components/layout/Styles";
import { usePallet } from "../../contexts/PalletContext";
import Main from "../main/Main";
import IACarouselTabs from "./_components/IACarouselTabs";


//## StyledCompenents
const FilterArea = styled(Paper)`
	width: 100%;
	/* height: 55px; */
	margin-top: 16px;
	margin-bottom: 10px;
	padding: 16px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background-color: #ffff;
`;

const FilterButton = styled(Button)`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	text-transform: capitalize;
	color: #160342;
	border: 0;
	font-size: 14px;
	line-height: 17px;
	background: rgba(75, 17, 150, 0.1);
	border-radius: 6px;
	padding: 10px;
	width: 100%;
	height: 100%;

	:first-child {
		margin-right: 10px;
	}
`;

const ContainerHead = styled(Grid)`
	margin-bottom: 10px;
	margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
	margin-bottom: 30px;
`;

const PageTitle = styled(Grid)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-weight: ${(props) => props.pallet.general.weight_bold};
	font-size: 24px;
	font-family: ${(props) => props.pallet.general.fontFamily};
`;

const ContainerFilter = styled(Grid)`
	/* width: 10%; */
	width: 100%;
	margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
	justify-content: ${isMobile ? "center" : "space-between"};
    flex-direction: column;
`;

const ContainerCard = styled(Grid)`
	margin-top: 20px;

`;

const PaperContent = styled(Paper)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
	margin-top: 40px;
	margin-bottom: 40px;
	justify-content: center;
`;

export default function InteligenciaArtificialLista() {
	const { pallet } = usePallet();
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Main>
				<>
					<ContainerHead container>
						<ContainerSubHead container alignItems="center" justifyContent="space-between">
							<PageTitle pallet={pallet}>Inteligência Artificial</PageTitle>
						</ContainerSubHead>

						<Grid container>
						</Grid>
						<Grid
							style={{
								width: isMobile ? "60%" : "100%",
							}}
						/>
					</ContainerHead>
					<ContainerBody container>
						<Grid item style={{ width: "100%" }}>
							<IACarouselTabs />
						</Grid>
					</ContainerBody>
					<ContainerFooter container>
					</ContainerFooter>
				</>
			</Main>
		</div>
	);
}
