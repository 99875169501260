import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import SelectTextField from "../../../components/SelectTextField";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import { IProposicao } from "../../../models/Proposicao.Model";
import { usePallet } from "../../../contexts/PalletContext";
import useStyles from "../../../components/layout/Styles";
import styled from "styled-components";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import InsightService from "../../../services/InsightService";
import EmptyError from "../../../components/EmptyError";
import { format } from "date-fns";
import ErrorMessage from "../../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/dateUtils";

export function AccordionContentKey25(props: {
  proposicao?: IProposicao;
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string
  onChange?: () => void;
}) {
  const { pallet } = usePallet();
  const classes = useStyles();
  const [grupoItens, setGrupoItens] = React.useState([]);
  const [temaItens, setTemaItens] = React.useState([]);
  const [subTemaItens, setSubTemaItens] = React.useState([]);
  const [grupo, setGrupo] = React.useState<string>("");
  const [grupoLabel, setGrupoLabel] = React.useState<string>();
  const [tema, setTema] = React.useState<string>("");
  const [temaLabel, setTemaLabel] = React.useState<string>();
  const [subtema, setSubtema] = React.useState<string>("");
  const [subtemaLabel, setSubtemaLabel] = React.useState<string>();
  const [listaGrupos, setListaGrupos] = React.useState([]);
  const [listaTemas, setListaTemas] = React.useState([]);
  const [listaSubtemas, setListaSubtemas] = React.useState([]);
  const [loading, setLoading] = useState(false);

  // Monta itens do select com o retorno da query
  const getItens = (
    data: { id: number; descricao: string }[]
  ): { value: string; label: string }[] => {
    const ret = data?.map((item) => {
      return {
        value: String(item.id),
        label: item.descricao,
      };
    });
    return ret;
  };


  const tagDiscursoCor = (value: string) => {
    if (value) {
      value = value.toLowerCase();
    }
    switch (value) {
      case "divergente":
        return `${pallet.charts.red}`;
      case "convergente":
        return `${pallet.charts.green}`;
      case "neutro":
        return `${pallet.charts.lightGrey}`;
      default:
        return `${pallet.charts.blue}`;
    }
  };

  React.useEffect(() => {
    TabelasService.getProposicaoGrupoTemas().then((resII) => {
      console.log("RESii",resII)
      const dataII = getItens(resII);
      setLoading(false)
      setGrupoItens(dataII);
    });
  }, []);

  React.useEffect(() => {
    if (grupo) {
      TabelasService.getProposicaoTemasByGrupo(Number(grupo)).then((res) => {
        const data = getItens(res);
        setTemaItens(data);
      });
    }
  }, [grupo]);

  React.useEffect(() => {
    if (tema) {
      TabelasService.getProposicaoSubTemasByTema(Number(tema)).then((res) => {
        const data = getItens(res);
        setSubTemaItens(data);
      });
    }
  }, [tema]);

  const onChangeGrupo = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setGrupo(event.target.value);
      setTema("");
      setSubtema("");
      setListaTemas([])
      setListaSubtemas([])
    }
  };

  const onChangeTema = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setTema(event.target.value);
      setSubtema("");
      setListaSubtemas([])
    }
  };

  const onChangeSubtema = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setSubtema(event.target.value);
    }
  };


  React.useEffect(() => {
    if (grupo) {
      TabelasService.getDescricaoGrupos(Number(grupo)).then((res) => {
        setGrupoLabel("")
        setGrupoLabel(res[0].descricao)
      });
    }
  }, [grupo]);

  React.useEffect(() => {
    if (tema) {
      TabelasService.getDescricaoTemas(Number(tema)).then((resII) => {
        setTemaLabel("")
        setTemaLabel(resII[0].descricao)
      });
    }
  }, [tema]);

  React.useEffect(() => {
    if (subtema) {
      TabelasService.getDescricaoSubtemas(Number(subtema)).then((resIII) => {
        setSubtemaLabel("")
        setSubtemaLabel(resIII[0].descricao)
      });
    }
  }, [subtema]);

  const ChipStyle = styled(Grid)`
  background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
  text-transform: uppercase;
  border-radius: 100px;
 font-size: ${isMobile ? "9px" : "10px"};
  border: 1px solid ${(props => props.pallet.general.color4)};
  color: ${(props => props.pallet.general.color4)};
  padding: 4px 6px 4px 6px;
  line-height: 12px;
  letter-spacing: 1px;
  width: 'auto'; 
 
`;

  const DotStyle = styled.div`
  background-color: ${(props => props.bg)};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: ${isMobile ? "3px" : "5px"};
  margin-top: 2px;
`;

  const Title = styled(Grid)`
  padding-left:  ${isMobile ? '5px' : '24px'};
  padding-top: ${isMobile ? '5px' : '30px'} ;
  background-color: ${pallet.backGroundPageColorPrimary};
  text-transform: uppercase;
  font-size:${pallet.general.size14};
  color: ${pallet.textColorQuaternary};
  font-family: ${pallet.general.fontFamily};
  place-content:  ${isMobile ? 'center' : 'unset'};
  font-weight: ${pallet.general.weight_600};
  height: 79px;
`;

  const esferaConv = (value: any) => {
    switch (value) {
      case 'F':
        return 1;
      case 'E':
        return 2;
      case 'M':
        return 3;


    }
  }

  React.useEffect(() => {
    if (grupo) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey25_26Grupo(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(grupo), esferaConv(props.esfera)
        ).then((res) => {
          if (res) {
            setListaGrupos(res.data)
            setLoading(true)
          }
        });
      }
    }


    if (tema) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey25_26Tema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(grupo), Number(tema), esferaConv(props.esfera)
        ).then((res) => {


          if (res) {
            setListaTemas(res.data)
          }
        });
      }
    }

    if (subtema) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey25_26Subtema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(grupo), Number(tema), Number(subtema), esferaConv(props.esfera)
        ).then((res) => {
          if (res) {
            setListaSubtemas(res.data)
          }
        });
      }
    }

  }, [props.dtIni, props.dtFin, props.esfera, grupo, tema, subtema]);


  const navigate = useNavigate();

  return (
    <Grid>
      <Grid
        container
        style={{
          padding: "40px 15px 10px 15px",
          justifyContent: "space-around",
        }}
      >
        <Grid
          style={{
            padding: `${isMobile ? "0px 0px 10px 0px" : "6px 30px 10px 6px"}`,
          }}
          item
          md={4}
          xs={12}
          lg={4}
        >
          <SelectTextFieldA
            id={"grupo"}
            label="GRUPO"
            options={grupoItens}
            value={grupo}
            onChange={onChangeGrupo}
            focused={true}
            placeholder={"Selecione"}
          />
        </Grid>

        {
          <>
            {grupo ?
              <Grid
                style={{
                  padding: `${isMobile ? "0px 0px 10px 0px" : "6px 30px 30px 0px"}`,
                }}
                item
                md={4}
                xs={12}
                lg={4}
              >
                <SelectTextFieldA
                  id={"tema"}
                  name="tema-select"
                  label="TEMA"
                  options={temaItens}
                  value={tema}
                  onChange={onChangeTema}
                  focused={true}
                  placeholder={"Selecione"}
                />
              </Grid>
              : null
            }
          </>
        }
        {
          <>
            {tema ?
              <Grid
                style={{ padding: `${isMobile ? "0px" : "6px 6px 30px 0px"}` }}
                item
                md={4}
                xs={12}
                lg={4}
              >
                <SelectTextFieldA
                  id={"subtema"}
                  label="SUBTEMA"
                  options={subTemaItens}
                  value={subtema}
                  onChange={onChangeSubtema}
                  focused={true}
                  placeholder={"Selecione"}
                />
              </Grid>
              : null
            }
          </>
        }

      </Grid>
      <Grid style={{ marginBottom: "32px" }}>
        <Divider variant="fullWidth" />
      </Grid>

      {
        <>
          {listaGrupos?.length > 0 ?
            <>
              <Grid>
                <Typography
                  className={classes.heading}
                  style={{
                    color: `${pallet.textColorTertiary}`,
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size14}`,
                    fontWeight: pallet.general.weight_600,
                    marginLeft: "30px",
                  }}
                >
                  <Grid style={{ display: "flex" }}>GRUPO | {grupoLabel}</Grid>
                </Typography>
              </Grid>

              <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
                <Title container style={{
                  borderTop: '1px solid #EAEAEA',
                  borderLeft: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                  borderRadius: '6px 0px 0px 0px'
                }}
                  item lg={6} xs={6}>
                  INTERLOCUTORES
                </Title>
                <Title container item lg={6} xs={6}
                  style={{
                    borderTop: '1px solid #EAEAEA',
                    borderRight: '1px solid #EAEAEA',
                    borderBottom: '1px solid #EAEAEA',
                    borderRadius: '0px 6px 0px 0px'
                  }}>
                  PROPOSIÇÕES
                </Title>
                <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                  {listaGrupos?.map((x, i) => {
                    return (
                      <>
                        <Grid container lg={6} xs={6} style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "5px" : "15px"}`,
                          borderLeft: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                          borderRadius: i + 1 !== listaGrupos.length ? null : '0px 0px 0px 6px',
                          maxWidth: 'none',
                          wordBreak: 'break-word',
                          placeContent: `${isMobile ? 'center' : 'unset'}`,
                          textAlign: "center"
                        }}>
                          <Grid item
                            onClick={() => navigate('/interlocutor/' + x.id_parlamentar)}
                            style={{
                              paddingBottom: '5px',
                              minWidth: '100px',
                              color: `${pallet.textColorTertiary}`,
                              fontFamily: `${pallet.general.fontFamily}`,
                              fontSize: `${pallet.general.size14}`,
                              fontWeight: pallet.general.weight_600,
                              cursor: "pointer",
                              placeContent: `${isMobile ? 'center' : 'unset'}`,
                              textAlign: "center"

                            }}
                          >
                            { x.siglaPartido ? x.nome  + "(" + x.siglaPartido + ")" : x.nome}
                          </Grid>
                          <Grid container style={{ paddingBottom: '10px', placeContent: `${isMobile ? 'center' : 'unset'}` }}
                          >
                            <ChipStyle pallet={pallet}>
                              <Grid container item  >
                                <DotStyle bg={tagDiscursoCor(x.qualificacao)}>
                                </DotStyle>
                                <Grid >
                                  {x.qualificacao}
                                </Grid>
                              </Grid>
                            </ChipStyle>
                          </Grid>
                        </Grid>
                        <Grid container item lg={6} xs={6}
                          onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                            formatDate(props.dtIni, "dd/MM/yyyy") +
                            '","value" : "' +
                            formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                            formatDate(props.dtFin, "dd/MM/yyyy") +
                            '","value" : "' +
                            formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                            '"},{"key":"grupos","label":"GRUPO: ' + grupoLabel + '","value":"' + grupo + '"},{"key":"autorI","label":"AUTOR: ' + x.nome + '","value":"' + x.id_parlamentar + '"}]')}
                          style={{
                            paddingLeft: `${isMobile ? "5px" : "24px"}`,
                            paddingTop: `${isMobile ? "5px" : "15px"}`,
                            borderRight: '1px solid #EAEAEA',
                            borderBottom: '1px solid #EAEAEA',
                            borderRadius: i + 1 !== listaGrupos.length ? null : '0px 0px 6px 0px',
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            placeContent: `${isMobile ? 'center' : 'unset'}`
                          }}>
                          {x.count}
                        </Grid>
                      </>
                    );
                  })}

                </Grid>
              </Grid>
            </>
            :
            loading ?
              <Grid>
                <ErrorMessage description="Não foram encontrados dados para este Insight." />
              </Grid>
              :
              null
          }
        </>
      }
      {
        <>
          {listaTemas.length > 0 ?
            <>
              <Grid>
                <Typography
                  className={classes.heading}
                  style={{
                    color: `${pallet.textColorTertiary}`,
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size14}`,
                    fontWeight: pallet.general.weight_600,
                    marginLeft: "30px",
                  }}
                >
                  <Grid style={{ display: "flex" }}>TEMA | {temaLabel}</Grid>
                </Typography>
              </Grid>

              <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
                <Title container style={{
                  borderTop: '1px solid #EAEAEA',
                  borderLeft: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                  borderRadius: '6px 0px 0px 0px'

                }}
                  item lg={6} xs={6}>
                  INTERLOCUTORES
                </Title>
                <Title container item lg={6} xs={6}
                  style={{
                    borderTop: '1px solid #EAEAEA',
                    borderRight: '1px solid #EAEAEA',
                    borderBottom: '1px solid #EAEAEA',
                    borderRadius: '0px 6px 0px 0px'
                  }}>
                  PROPOSIÇÕES
                </Title>
                <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                  {listaTemas?.map((x, i) => {
                    return (
                      <>
                        <Grid container lg={6} xs={6} style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "5px" : "15px"}`,
                          borderLeft: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                          borderRadius: i + 1 !== listaTemas.length ? null : '0px 0px 0px 6px',
                          maxWidth: 'none',
                          wordBreak: 'break-word',
                          placeContent: `${isMobile ? 'center' : 'unset'}`,
                          textAlign: "center"

                        }}>
                          <Grid item
                            onClick={() => navigate('/interlocutor/' + x.id_parlamentar)}
                            style={{
                              paddingBottom: '5px',
                              color: `${pallet.textColorTertiary}`,
                              fontFamily: `${pallet.general.fontFamily}`,
                              fontSize: `${pallet.general.size14}`,
                              fontWeight: pallet.general.weight_600,
                              cursor: "pointer",
                              minWidth: '100px',
                              placeContent: `${isMobile ? 'center' : 'unset'}`,
                              textAlign: "center"

                            }}
                          >
                           { x.siglaPartido ? x.nome  + "(" + x.siglaPartido + ")" : x.nome}
                          </Grid>
                          <Grid container style={{ paddingBottom: '10px', placeContent: `${isMobile ? 'center' : 'unset'}`, }}
                          >
                            <ChipStyle pallet={pallet}>
                              <Grid container item >
                                <DotStyle bg={tagDiscursoCor(x.qualificacao)}>
                                </DotStyle>
                                <Grid >
                                  {x.qualificacao}
                                </Grid>
                              </Grid>
                            </ChipStyle>
                          </Grid>
                        </Grid>
                        <Grid container item lg={6} xs={6}
                          onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                            formatDate(props.dtIni, "dd/MM/yyyy") +
                            '","value" : "' +
                            formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                            formatDate(props.dtFin, "dd/MM/yyyy") +
                            '","value" : "' +
                            formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                            '"},{"key":"temas","label":"TEMA: ' + temaLabel + '","value":"' + tema + '"},{"key":"autorI","label":"AUTOR: ' + x.nome + '","value":"' + x.id_parlamentar + '"}]')}
                          style={{
                            paddingLeft: `${isMobile ? "5px" : "24px"}`,
                            paddingTop: `${isMobile ? "5px" : "15px"}`,
                            borderRight: '1px solid #EAEAEA',
                            borderBottom: '1px solid #EAEAEA',
                            borderRadius: i + 1 !== listaTemas.length ? null : '0px 0px 6px 0px',
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            placeContent: `${isMobile ? 'center' : 'unset'}`,
                          }}>
                          {x.count}
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </>
            : null
          }
        </>
      }
      {
        <>
          {listaSubtemas.length > 0 ?
            <>
              <Grid>
                <Typography
                  className={classes.heading}
                  style={{
                    color: `${pallet.textColorTertiary}`,
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size14}`,
                    fontWeight: pallet.general.weight_600,
                    marginLeft: "30px",
                  }}
                >
                  <Grid style={{ display: "flex" }}>SUBTEMA | {subtemaLabel}</Grid>
                </Typography>
              </Grid>

              <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
                <Title container style={{
                  borderTop: '1px solid #EAEAEA',
                  borderLeft: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                  borderRadius: '6px 0px 0px 0px'
                }}
                  item lg={6} xs={6}>
                  INTERLOCUTORES
                </Title>
                <Title container item lg={6} xs={6}
                  style={{
                    borderTop: '1px solid #EAEAEA',
                    borderRight: '1px solid #EAEAEA',
                    borderBottom: '1px solid #EAEAEA',
                    borderRadius: '0px 6px 0px 0px'
                  }}>
                  PROPOSIÇÕES
                </Title>
                <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                  {listaSubtemas?.map((x, i) => {
                    return (
                      <>
                        <Grid container lg={6} xs={6} style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "5px" : "15px"}`,
                          borderLeft: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                          borderRadius: i + 1 !== listaSubtemas.length ? null : '0px 0px 0px 6px',
                          maxWidth: 'none',
                          wordBreak: 'break-word',
                          placeContent: `${isMobile ? 'center' : 'unset'}`,
                          textAlign: "center"
                        }}>
                          <Grid item
                            onClick={() => navigate('/interlocutor/' + x.id_parlamentar)}
                            style={{
                              paddingBottom: '5px',
                              color: `${pallet.textColorTertiary}`,
                              fontFamily: `${pallet.general.fontFamily}`,
                              fontSize: `${pallet.general.size14}`,
                              fontWeight: pallet.general.weight_600,
                              cursor: "pointer",
                              minWidth: '100px',
                              textAlign: "center",
                              placeContent: `${isMobile ? 'center' : 'unset'}`,
                            }}
                          >
                           { x.siglaPartido ? x.nome  + "(" + x.siglaPartido + ")" : x.nome}
                          </Grid>
                          <Grid container style={{ paddingBottom: '10px', placeContent: `${isMobile ? 'center' : 'unset'}`, }}
                          >
                            <ChipStyle pallet={pallet}>
                              <Grid container item >
                                <DotStyle bg={tagDiscursoCor(x.qualificacao)}>
                                </DotStyle>
                                <Grid >
                                  {x.qualificacao}
                                </Grid>
                              </Grid>
                            </ChipStyle>
                          </Grid>
                        </Grid>
                        <Grid container item lg={6} xs={6}
                          onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                            formatDate(props.dtIni, "dd/MM/yyyy") +
                            '","value" : "' +
                            formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                            '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                            formatDate(props.dtFin, "dd/MM/yyyy") +
                            '","value" : "' +
                            formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                            '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"autorI","label":"AUTOR: ' + x.nome + '","value":"' + x.id_parlamentar + '"}]')}

                          style={{
                            paddingLeft: `${isMobile ? "5px" : "24px"}`,
                            paddingTop: `${isMobile ? "5px" : "15px"}`,
                            borderRight: '1px solid #EAEAEA',
                            borderBottom: '1px solid #EAEAEA',
                            borderRadius: i + 1 !== listaSubtemas.length ? null : '0px 0px 6px 0px',
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            placeContent: `${isMobile ? 'center' : 'unset'}`,

                          }}>
                          {x.count}
                        </Grid>
                      </>

                    );
                  })}
                </Grid>
              </Grid>

            </>
            : null
          }
        </>
      }

    </Grid>


  );
}

export default AccordionContentKey25;
