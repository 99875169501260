import React, { useState } from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import styled from "styled-components";
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import ParlamentarDemandaService from '../../../services/ParlamentarDemandaService';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AnexosComponent, { EAnexoModulos } from '../../../components/Anexos';
import ParlamentarDemandasPosicionamentos from './ParlamentarDemandasPosicionamentos';
import SelectTextFieldA from '../../../components/SelectTextFieldA';
import { useAlert } from '../../../contexts/AlertContext';

export interface IPosicionamento {
    idRef?: number,
    listaPocionamento: Array<{
        departamento?: string,
        id: number,
        id_demanda: number,
        id_responsavel: number,
        impacto?: string,
        posicionamento?: string,
        responsavel?: string,
        status?: string,
        tipo_impacto?: number,
        observacao?: string,
        confirmacao?: string,
        data_recebimento?: Date,
    }>,
    assunto: string,
    prioridade?: string,
    autor: string,
    departamento: string,
    objetivo: string,
    resultado: string,
    reloadList?: () => void,
    closeMenu?: () => void,
}

const resultadoItens = [
    { key: 1, label: "Pendente", value: "Pendente" },
    { key: 2, label: "Êxito", value: "Êxito" },
    { key: 3, label: "Sem êxito", value: "Sem êxito" },
    { key: 4, label: "Êxito parcial", value: "Êxito parcial" },
]

function AccordionTextDataDemandaParlamentarDetail(props: IPosicionamento,): JSX.Element {

    const { pallet } = usePallet();
    const classes = useStyles();
    const [idReferencia, setIdReferencia] = React.useState<number>();
    const { NewAlert } = useAlert();
    const handleReferencia = () => { idReferencia ? setIdReferencia(undefined) : setIdReferencia(57); }
    const [registro, setRegistro] = useState(props.resultado ? props.resultado : "Pendente");



    const BoxResponsavel = styled(Grid)`
    direction: ${isMobile ? "column" : "row"};
    background: ${pallet.backGroundPageColorPrimary};
    padding-left: ${isMobile ? "5px" : "20px"};
    min-height: 63px;
    box-sizing: border-box;
    border: 1px solid #EAEAEA;
    align-items: center;
    flex-direction:  ${isMobile ? "column" : "row"};; 
`;

    const ChipDepartamento = styled(Grid)`
    background-color: #E0CDF8;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    color: ${(pallet.general.color4)};
    height: ${isMobile ? "max-content" : "31px"};;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    text-align: ${isMobile ? "center" : "none"};
    width: max-content ;
    max-width: ${isMobile ? "220px" : "max-content"};
    font-weight: ${pallet.fontWeight.subtitle};
    font-size: ${pallet.general.size12};
    color: ${pallet.color.tertiary.font};
    margin-bottom: ${isMobile ? "5px" : "0px"};
    margin-top: ${isMobile ? "5px" : "0px"};
`;

    const NomeResponsavel = styled.div`
    font-size: ${pallet.general.size14};
    color: ${pallet.color.secundary.font};
    padding-left: 10px;
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    line-height: 17px;
    text-align: ${isMobile ? "center" : "none"};
    letter-spacing: 1px;
    text-align-last: ${isMobile ? "center" : ""} ;
    margin-inline-end: ${isMobile ? "" : "auto"};
    min-width: 200px;
    margin-bottom: ${isMobile ? "5px" : "0px"};
`;

    const CheckedIcon = styled(CheckIcon)`
    color: #4CD964;
    font-size: ${pallet.general.size18};
    font-weight: ${pallet.general.weight_600};
    margin-right: 10px;
    min-width:30px;
`;

    const DangerIcon = styled(ReportProblemIcon)`
    color: #F63854;
    font-size: ${pallet.general.size18};
    font-weight: ${pallet.general.weight_600};
    margin-right: 10px;
    min-width:30px;
`;

    const Status = styled.div`
    font-size: ${pallet.general.size12};
    color: ${pallet.color.tertiary.font};
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    position: relative;
    margin-right: 15px;
`;

    const handleResultado = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegistro(event.target.value);

        ParlamentarDemandaService.Update({
            resultado: event.target.value, posicionamentos: props.listaPocionamento.map(item => {
                return item.id
            })
        }, props.idRef).then(res => {
            if (res) {
                NewAlert('success', 'Resultado salvo com sucesso');

            } else {
                // NewAlert('error', 'Não foi possível salvar o resultado Verifique se todos os Posicionamentos estão preenchidos');
            }
        })
            .then(data => {
                props.reloadList()
            })
    }


    return (
        <>
            <Grid key={0} style={{ marginBottom: "10px" }}>
                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 15px 10px 15px"}`, width: "100%", minWidth: '100%' }}>
                    <Grid container style={{ paddingTop: "10px" }}>
                        <Grid container whiteSpace={"normal"} textOverflow={"ellipsis"} xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid item style={{
                                fontWeight: pallet.fontWeight.subtitle,
                                fontSize: `${pallet.general.size12}`,
                                color: `${pallet.color.tertiary.font}`,
                                paddingBottom: "8px"
                            }}>
                                ASSSUNTO DA DEMANDA
                            </Grid>

                            <Typography style={{
                                marginBottom: "32px",
                                width: `${isMobile ? "100%" : "100%"}`,
                                fontSize: `${pallet.general.size14}`,
                                color: `${pallet.color.tertiary.font}`,
                                overflowWrap: "break-word"
                            }}>
                                {props.assunto}
                            </Typography>
                        </Grid>
                        <Grid container whiteSpace={"normal"} textOverflow={"ellipsis"} xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid item style={{
                                fontWeight: pallet.fontWeight.subtitle,
                                fontSize: `${pallet.general.size12}`,
                                color: `${pallet.color.tertiary.font}`,
                                paddingBottom: "8px",
                            }}>
                                OBJETIVO
                            </Grid>

                            <Typography style={{
                                width: `${isMobile ? "100%" : "100%"}`,
                                marginBottom: "32px",
                                fontSize: `${pallet.general.size14}`,
                                color: `${pallet.color.tertiary.font}`,
                                overflowWrap: "break-word"
                            }}>
                                {props.objetivo}
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid style={{
                                fontWeight: pallet.fontWeight.subtitle,
                                fontSize: `${pallet.general.size12}`,
                                color: `${pallet.color.tertiary.font}`,
                                paddingBottom: "8px"
                            }}>
                                AUTOR DA DEMANDA
                            </Grid>

                            <Grid style={{
                                fontSize: `${pallet.general.size14}`,
                                color: `${pallet.color.secundary.font}`,
                            }}>
                                {props.departamento} &nbsp; {props.autor}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider variant="fullWidth" style={{ paddingTop: "30px", marginBottom: "30px" }} />
                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                        <Grid className="container">
                            <AnexosComponent
                                label={''}
                                id_referencia={props.idRef}
                                modulo={EAnexoModulos.DemandasParlamentar}
                                canEdit={false}
                                autosave={false}
                                isAnexosTitle={"ANEXOS"}

                            />
                        </Grid>
                    </Grid>

                    {/* <Divider variant="fullWidth" style={{ paddingTop: "30px" }} /> */}


                    {props.listaPocionamento.map(data => {
                        return (
                            <>
                                <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: "10px" }}>
                                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                        <Grid style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.general.size12}`,
                                            color: `${pallet.color.tertiary.font}`,
                                        }}>
                                        </Grid>
                                        <Grid style={{ marginTop: "10px" }}>
                                            <Grid className="container">
                                                <Stack spacing={2} alignItems={"center"}>
                                                    {/* <ModalPosicionamento /> */}
                                                    <ParlamentarDemandasPosicionamentos
                                                        reloadList={props.reloadList}
                                                        posicionamento={data}
                                                        responsaveis={props.listaPocionamento.map(item => {
                                                            return item.id_responsavel
                                                        })}
                                                    >
                                                        <BoxResponsavel xs={12} container item >
                                                            <ChipDepartamento item style={{
                                                            }}>
                                                                {data.departamento}
                                                            </ChipDepartamento>
                                                            <NomeResponsavel item > {data.responsavel} </NomeResponsavel>
                                                            {data.status === "Preenchido" ? <CheckedIcon /> : <DangerIcon />}
                                                            <Status item >{data.status}</Status>
                                                        </BoxResponsavel>
                                                    </ParlamentarDemandasPosicionamentos>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </>
                        )
                    })}
                    <Divider variant="fullWidth" style={{ paddingTop: "20px" }} />

                    <Grid md={5} xs={12} style={{ paddingTop: "20px" }}>

                        <Grid style={{
                            fontWeight: pallet.fontWeight.subtitle,
                            fontSize: `${pallet.general.size12}`,
                            color: `${pallet.color.tertiary.font}`,
                            paddingBottom: "20px"
                        }}>
                            RESULTADO DA DEMANDA
                        </Grid>

                        <Grid>
                            <Box component="form">
                                <SelectTextFieldA
                                    id={"demandas-resultado"}
                                    value={registro}
                                    defaultValue={registro}
                                    options={resultadoItens}
                                    onChange={handleResultado}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
}

export default AccordionTextDataDemandaParlamentarDetail;

