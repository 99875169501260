import { Button, Divider, Grid, ListItemText, MenuItem, Pagination, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styledI from "styled-components";
import ComboBox from "../../../components/ComboBox";
import { usePallet } from "../../../contexts/PalletContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconLabelButtons from '../../../components/ButtonIconSalvar';
import { IListInfo } from '../../../models/IListInfo';
import EmptyError from '../../../components/EmptyError';
import PaginationSaveState from '../../../components/PaginationSaveState';
import { useNavigate } from "react-router-dom";
// import TemaCorporativoCard from "./TemaCorporativoCard";
import { IFilterData } from "../../../components/filter/ListFilter";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import CitacoesService from "../../../services/CitacoesService";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import ProposicaoAcontecimentosService from "../../../services/ProposicaoAcontecimentosService";
import { useAlert } from "../../../contexts/AlertContext";
import { useAuthorization } from "../../../contexts/AuthorizationContext";
import { styled, alpha } from "@mui/material/styles";
import { MoreHoriz } from "@material-ui/icons";
import Menu, { MenuProps } from "@mui/material/Menu";
import CitacoesEditar from "./CitacoesEditar";

//## StyledCompenents

const StyledMenu = styled((props: MenuProps) => (
  <Menu
      elevation={0}
      anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
      }}
      transformOrigin={{
          vertical: "top",
          horizontal: "right",
      }}
      {...props}
  />
))(({ theme }) => ({
  ".MuiListItemText-primary": {
      fontSize: 0
  },
  "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(0),
      minWidth: 180,
      color:
          theme.palette.mode === "light"
              ? "rgb(55, 65, 81)"
              : theme.palette.grey[300],
      boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
          padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
          "& .MuiSvgIcon-root": {
              fontSize: 18,
              color: theme.palette.text.secondary,
              marginRight: theme.spacing(1.5),
          },
          "&:active": {
              backgroundColor: alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity
              ),
          },
      },
  },
}));



const FilterArea = styledI(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styledI(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;




const ContainerFilter = styledI(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;

const ContainerBody = styledI(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styledI(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styledI(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;

export interface ICitacaoResult {
  descricao: string;
  id: any;
}

export default function CitacoesList(props: {
  lista: [{ descricao: string, id: number, proposicoes: boolean, discursos: boolean, dou: boolean ,twitter: boolean }],
  reloadList?: () => void
}) {

  const navigate = useNavigate();

  const [open, setOpen] = React.useState();

  const { pallet } = usePallet();

  const { NewAlert } = useAlert();

  const Title = styled(Grid)`
  padding-left:  ${isMobile ? '5px' : '15px'};
  padding-top: ${isMobile ? '5px' : '10px'} ;
  padding-bottom: ${isMobile ? '5px' : '10px'} ;
  background-color: ${pallet.backGroundPageColorPrimary};
  font-size:${pallet.general.size12};
  color: ${pallet.textColorQuaternary};
  font-family: ${pallet.general.fontFamily};
  font-weight: ${pallet.general.weight_600};
  align-items: center;
  display: flex;
  height: 79px;
  `;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


  const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
    setOpen(id);
    setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
  setOpen(undefined);
};

  //## Paginação
  const [infoList, setInfoList] = useState<IListInfo>({
    pageIndex: 1,
    pageSize: 8,
    pageItens: 0,
    totalItens: 0,
    totalPages: 0,
  });
  const [currentPageSize, setCurrentPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);



  const filterName = 'TemasRelacionadosListCurrentPage';
  // const filterItens = [
  //   { key: "visualizacao", label: "Tudo", value: "T" },
  //   { key: "visualizacao", label: "Relacionados", value: "R" },
  //   { key: "visualizacao", label: "Não Relacionados", value: "NR" },
  // ]

  //## Dados
  const [lista, setLista] = useState([]);
  const [listaGeral, setListaGeral] = useState<any>([]);
  const [listaDescricao, setListaDescricao] = useState<any>([{ descricao: "", id: 0 }]);
  const [busca, setBusca] = useState<IFilterData[]>();
  const [descricaoParcial, setDescricaoParcial] = useState<string>("");

  const [novaLista, SetNovaLista] = useState<any>([{ descricao: "", id: 0 }]);



  //## Funções'
  const handleOnChangeItensPerPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageSize(Number(event.target.value));
  };



  const deletarCitacao = (id, descricao) => {

    CitacoesService.Update({ descricao, id }, id)

      .then(res => {
        if (res) {
          NewAlert('success', 'Palavra-chave apagada com sucesso')

        } else {
          NewAlert('error', 'Erro ao deletar, tente novamente.');
        }
      }).then((x) => props.reloadList());

  }


  const { user } = useAuthorization();

  const erroAlteracaoPerfil = () => {
    NewAlert(
        "error",
        "Não é possível realizar está ação com este perfil."
    );
}

  return (
    <>
      <ContainerHead container>
        <Grid container>
        </Grid>
        <Grid
          style={{
            width: isMobile ? "60%" : "100%",
          }}
        />



      </ContainerHead>
      <ContainerBody container style={{ marginTop: "20px" }}>
        {props.lista?.map((a) => {
          return (
            <Grid container style={{
              border: '1px solid #EAEAEA',
              borderRadius: '6px',
              margin: '10px',
              paddingLeft:  isMobile ? '5px' : '15px',
              paddingTop: isMobile ? '5px' : '10px',
              paddingBottom: isMobile ? '5px' : '10px' ,
              backgroundColor: pallet.backGroundPageColorPrimary,
              fontSize:pallet.general.size12,
              color: pallet.textColorQuaternary,
              fontFamily: pallet.general.fontFamily,
              fontWeight: pallet.general.weight_600,
              alignItems: 'center',
              display: 'flex',
              height: '79px'

            }}
              item>
              <Grid item lg={12} xs={12}>{a.descricao}</Grid>
              {a.proposicoes || a.discursos || a.dou || a.twitter ?
              "Filtro:"
              :
              null
              }
              <Grid item style={{display:"flex"}}>
              {a.proposicoes ? 
              <Grid style ={{backgroundColor: pallet.backgroundColorPrimary,color: pallet.textColorPrimary,
              borderRadius: "6px",padding: "5px",margin: "7px"
              }}>Proposição</Grid> :  null}
              {a.discursos ?              
              <Grid style ={{backgroundColor: pallet.backgroundColorPrimary,color: pallet.textColorPrimary,
              borderRadius: "6px",padding: "5px",margin: "7px"
              }}>Discursos</Grid>
              :
              null
              }
              {a.dou ? 
                  <Grid style ={{backgroundColor: pallet.backgroundColorPrimary,color: pallet.textColorPrimary,
                    borderRadius: "6px",padding: "5px",margin: "7px"
                    }}>Diário Oficial (DOU)</Grid> : null}
              {a.twitter ?
                <Grid style ={{backgroundColor: pallet.backgroundColorPrimary,color: pallet.textColorPrimary,
                  borderRadius: "6px",padding: "5px",margin: "7px"
                  }}>X (Antigo Twitter)</Grid> : null
            
             }
             </Grid>
              <Grid style={{ marginRight: "5px",marginLeft:'auto' }}>
                {/* <ButtonDialogConfirmation
                  styles={{
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                  }}
                  title={"CONFIRMAÇÃO"}
                  // lastPath={"/parlamentar"}
                  confirmationEvent={() => user.id_permissao !== 3 ?
                    deletarCitacao(a.id, a.descricao) :     NewAlert(
                      "error",
                      "Não é possível realizar está ação com este perfil."
                  )
                  }
                  title_button={""}
                  confirmation_button={"Apagar"}
                  content={"APAGAR PALAVRA-CHAVE?"}
                  message={"Esta ação não poderá ser desfeita."}
                >
                  <DeleteIcon
                    style={{
                      color: `${pallet.general.color4}`,
                    }} />

                    
                </ButtonDialogConfirmation> */}

                                                  <Grid
                                                        style={{
                                                            marginTop: "-25px",
                                                            paddingRight: "10px",
                                                            marginBottom: "-30px",
                                                        }}
                                                    >
                                                        <Button
                                                            id={`button-${a.id}`}
                                                            aria-controls={
                                                                anchorEl ? `mmenu-${a.id}` : undefined
                                                            }
                                                            disableElevation
                                                            variant="contained"
                                                            size="large"
                                                            style={{
                                                                backgroundColor: `${pallet.general.color3}`,
                                                                textTransform: "capitalize",
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.fontWeight.button,
                                                                fontSize: `${pallet.fontSize.smallButton}`,
                                                                color: `${pallet.color.secundary.font}`,
                                                                width: "25px",
                                                                height: "40px",
                                                            }}
                                                            onClick={(e) => handleClick(e, a.id)}
                                                            endIcon={
                                                                <Grid
                                                                    style={{
                                                                        fontSize: "30px",
                                                                        color: `${pallet.general.color4}`,
                                                                        marginTop: "9px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                >
                                                                    <MoreHoriz fontSize="inherit" />
                                                                </Grid>
                                                            }
                                                        ></Button>
                                                        <StyledMenu

                                                            id={`mmenu-${a.id}`}
                                                            MenuListProps={{
                                                                "aria-labelledby": `button-${a.id}`,
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open === a.id}
                                                            onClose={handleClose}
                                                        >
                                                            <MenuItem>
                                                            <CitacoesEditar
                                                                    idCitacao={a.id}
                                                                    reloadList={() =>
                                                                        props.reloadList()
                                                                    }
                                                                    closeMenu={() => handleClose()}
                                                                                                                                    />
                                                            </MenuItem>
                                                            <Divider
                                                                style={{
                                                                    marginTop: "0px",
                                                                    marginBottom: "0px",
                                                                }}
                                                            />
                                                            <MenuItem
                                                                style={{
                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                    fontWeight: pallet.fontWeight.button,
                                                                    fontSize: `${pallet.general.size14}`,
                                                                    color: `${pallet.color.tertiary.font}`,
                                                                    borderRadius: "6px",
                                                                    padding: "6px",
                                                                }}
                                                            >
                                                                <ButtonDialogConfirmation
                                                                    styles={{
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.fontWeight.button,
                                                                        fontSize: `${pallet.general.size14}`,
                                                                        color: `${pallet.color.tertiary.font}`,
                                                                        borderRadius: "6px",
                                                                        textTransform: "capitalize",
                                                                    }}
                                                                    title={"CONFIRMAÇÃO"}
                                                                    // lastPath={"/parlamentar"}
                                                                    confirmationEvent={() => user.id_permissao !== 3 ?
                                                                      deletarCitacao(a.id, a.descricao) :     NewAlert(
                                                                        "error",
                                                                        "Não é possível realizar está ação com este perfil."
                                                                    )
                                                                    }
                                                                    title_button={"Apagar"}
                                                                    confirmation_button={"Apagar"}
                                                                    content={"APAGAR CITAÇÃO?"}
                                                                    message={"Esta ação não poderá ser desfeita."}
                                                                >
                                                                    <DeleteIcon
                                                                        style={{
                                                                            color: `${pallet.color.tertiary.font}`,
                                                                            marginRight: "16px",
                                                                            fontFamily: `${pallet.fontFamily.general}`,
                                                                        }}

                                                                    />
                                                                    <ListItemText>!</ListItemText>
                                                                </ButtonDialogConfirmation>
                                                            </MenuItem>
                                                            {/* <MenuItem onClick={() => deletarEdicaoAcontecimentos(item.id)}
                                                            style={{
                                                                paddingTop: "8px",
                                                                paddingBottom: "8px",
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.fontWeight.lighter,
                                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                            }}>
                                                            <DeleteIcon />
                                                            Apagar
                                                        </MenuItem> */}
                                                        </StyledMenu>
                                                    </Grid>
                


              </Grid>

            </Grid>


          )
        })}






      </ContainerBody>
      {/* <ContainerFooter container>

      </ContainerFooter> */}
    </>
  )
}