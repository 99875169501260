import PrivateRoute from '../../components/PrivateRoute';
import Insights from './Insights';

export function InsightsRoutes(): JSX.Element {
    return (
        <>
            <PrivateRoute path="/insights" element={< Insights />} />
        </>
    );
}
