import PrivateRoute from '../../components/PrivateRoute';
import { NotificationsProvider } from '../../contexts/NotificationsContext';
import CentralNotificacaoList from './CentralNotificacaoList';

export function CentralNotificacaoRoutes(): JSX.Element {
    return (
        <>
            <NotificationsProvider>
            <PrivateRoute path="/central-notificacoes" element={< CentralNotificacaoList />} />
            </NotificationsProvider>
        </>
    );
}
