import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { isValid } from "date-fns";
import { Grid } from '@mui/material';
import { usePallet } from '../contexts/PalletContext';
import ptBRLocale from "date-fns/locale/pt-BR";
import useStyles from '../../src/components/layout/Styles';
import DatePicker from '@mui/lab/DatePicker';
import CalendarOutlineIcon from '@mui/icons-material/CalendarTodayOutlined';
import styled from "styled-components";

const DateYearSelector = (props: { yearDate: Date, color?: string, onChange?: (dtYear: Date) => void }) => {

    const { pallet } = usePallet();

    const [value, setValue] = React.useState<Date>(props.yearDate);

    const classes = useStyles();

    useEffect(() => {
        if (
            value !== null &&
            isValid(value)
        )
            props.onChange && props.onChange(value)
    }, [value]);

   

    const CalendarOutlineIconstyled = styled(CalendarOutlineIcon)`
        color: ${pallet.color.secundary.font};
        width:18px;
    `;
    
    return (
        <Grid container>
            
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale} >

                    {/* <YearPicker date={undefined} minDate={undefined} maxDate={undefined} 
                 /> */}
                    
                    <DatePicker
                        views={['year']}
                        label=""
                        value={value}                        
                        //MaxDate                      
                        //MinDate                        
                        components={{ OpenPickerIcon: CalendarOutlineIconstyled }}
                        InputAdornmentProps={{ position: "start",color:"red" }}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}

                        renderInput={(parmYear) => (

                            <React.Fragment>

                                <TextField size="small" {...parmYear}
                                    variant="outlined"
                                    label={""}
                                    InputProps={{
                                        ...parmYear.InputProps,
                                        style: {
                                            maxWidth: '100px',
                                            color: `${pallet.color.secundary.font}`,
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`,
                                            fontWeight: pallet.general.weight_600,
                                            letterSpacing: '2px'
                                        },

                                        classes: {
                                            notchedOutline: classes.textFieldOutlineOff
                                        }

                                    }}
                                    InputLabelProps={{
                                        style: { color: props.color ? props.color : "#4B1196" }

                                    }}


                                />

                            </React.Fragment>
                        )}
                    />
                  
                </LocalizationProvider>
            
        </Grid>
    )

}

export default DateYearSelector