export const extrairUrlCompleta = (texto) => {
    const urls = texto.match(/(https?:\/\/[^\s]+)/g);

    if (!urls) {
        return null;
    }

    for (let url of urls) {
        url = url.replace('http://', 'https://');
    }

    return urls[0];
}
