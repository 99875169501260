import {
  Accordion,
  AccordionDetails,
  Grid,
  AccordionSummary,
} from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { isMobile } from "react-device-detect";
import React from "react";
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";
import AccordionContentKey1 from "../_components/AccordionContentKey1";
import AccordionContentKey2 from "../_components/AccordionContentKey2";
import AccordionContentKey3 from "../_components/AccordionContentKey3";
import AccordionContentKey4 from "../_components/AccordionContentKey4";
import AccordionContentKey6 from "../_components/AccordionContentKey6";
import AccordionContentKey5 from "../_components/AccordionContentKey5";
import AccordionContentKey7 from "../_components/AccordionContentKey7";
import AccordionContentKey8 from "../_components/AccordionContentKey8";
import AccordionContentKey9 from "../_components/AccordionContentKey9";
import AccordionContentKey10 from "../_components/AccordionContentKey10";
import AccordionContentKey11 from "../_components/AccordionContentKey11";
import AccordionContentKey12 from "../_components/AccordionContentKey12";
import AccordionContentKey13 from "../_components/AccordionContentKey13";
import AccordionContentKey14 from "../_components/AccordionContentKey14";
import AccordionContentKey15 from "../_components/AccordionContentKey15";
import AccordionContentKey16 from "../_components/AccordionContentKey16";
import AccordionContentKey17 from "../_components/AccordionContentKey17";
import AccordionContentKey18 from "../_components/AccordionContentKey18";
import AccordionContentKey29 from "./AccordionContentKey29";
import AccordionContentKey20 from "./AccordionContentKey20";
import AccordionContentKey19 from "./AccordionContentKey19";
import AccordionContentKey23 from "./AccordionContentKey23";
import AccordionContentKey24 from "./AccordionContentKey24";
import AccordionContentKey25 from "./AccordionContentKey25";
import AccordionContentKey26 from "./AccordionContentKey26";
import AccordionContentKey27 from "./AccordionContentKey27";
import AccordionContentKey28 from "./AccordionContentKey28";
import AccordionContentKey31 from "./AccordionContentKey31";
import AccordionContentKey30 from "./AccordionContentKey30";
import AccordionContentKey21 from "./AccordionContentKey21";
import AccordionContentKey22 from "./AccordionContentKey22";
import AccordionContentKey32 from "./AccordionContentKey32";
import AccordionContentKey33 from "./AccordionContentKey33";
import AccordionContentKey34 from "./AccordionContentKey34";

export function AccordionInsight(props: {
  // filtros?: IFiltroTipoProps[],
  dtIni?: Date;
  dtFin?: Date;
  titleFederal?: string;
  originFederal?: string;
  conteudo?: React.ReactNode;
  onChange?: any;
  onChangeII?: any;
  indice?: string;
}): JSX.Element {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleChange =
    (panel: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? true : false);
    };

  const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    height: 68px;

    .MuiAccordionSummary-content {
      flex-direction: column;
      padding: 0px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
  `;

  const { pallet } = usePallet();
  const [esfera, setEsfera] = React.useState<string>("F");
  const [tipo, setTipo] = React.useState<string>("TODOS");

  const seletorConteudo = (indice: string) => {
    switch (indice) {
      case "1":
        return <AccordionContentKey1 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "2":
        return <AccordionContentKey2 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "3":
        return <AccordionContentKey3 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "4":
        return <AccordionContentKey4 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "5":
        return <AccordionContentKey5 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "6":
        return <AccordionContentKey6 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "7":
        return <AccordionContentKey7 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "8":
        return <AccordionContentKey8 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "9":
        return <AccordionContentKey9 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />;
      case "10":
        return <AccordionContentKey10 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "11":
        return <AccordionContentKey11 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "12":
        return <AccordionContentKey12 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "13":
        return <AccordionContentKey13 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "14":
        return <AccordionContentKey14 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "15":
        return <AccordionContentKey15 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "16":
        return <AccordionContentKey16 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "17":
        return <AccordionContentKey17 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "18":
        return <AccordionContentKey18 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "19":
        return <AccordionContentKey19 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "20":
        return <AccordionContentKey20 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "21":
        return <AccordionContentKey21 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "22":
        return <AccordionContentKey22 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "23":
        return <AccordionContentKey23 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "24":
        return <AccordionContentKey24 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "25":
        return <AccordionContentKey25 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "26":
        return <AccordionContentKey26 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "27":
        return <AccordionContentKey27 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "28":
        return <AccordionContentKey28 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "29":
        return <AccordionContentKey29 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "30":
        return <AccordionContentKey30 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "31":
        return <AccordionContentKey31 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "32":
        return <AccordionContentKey32 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "33":
        return <AccordionContentKey33 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
      case "34":
        return <AccordionContentKey34 dtIni={props.dtIni} dtFin={props.dtFin} esfera={props.onChange} />
    }
  };

  React.useEffect(() => {
    if (props.onChange === esfera) {
      return null;
    } else {
      setEsfera(props.onChange);
      setExpanded(false);
    }
  }, [props.onChange]);

  React.useEffect(() => {
    if (props.onChangeII === tipo) {
      return null;
    } else {
      setTipo(props.onChangeII);
      setExpanded(false);
    }
  }, [props.onChangeII]);

  return (
    <>
      <Grid>
        <Grid
          style={{
            paddingLeft: `${isMobile ? "10px" : "25px"}`,
            paddingRight: `${isMobile ? "10px" : "50px"}`,
            paddingTop: `${isMobile ? "25px" : "10px"}`,
            paddingBottom: `${isMobile ? "35px" : "10px"}`,
          }}
        >
          <Accordion
            expanded={expanded === true}
            onChange={handleChange(true)}
            elevation={0}
          >
            <AccordionSum>
              <Grid
                style={{
                  height: `${isMobile ? "110px" : "68px"}`,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {expanded === true ? (
                  <Grid
                    style={{
                      backgroundColor: "#DED3FF",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "5px",
                      width: "68px",
                    }}
                  >
                    {props.originFederal === "PROPOSIÇÃO" ? (
                      <AssignmentIcon
                        style={{ color: `${pallet.general.color1}` }}
                      />
                    ) : (
                      <AccountBoxIcon
                        style={{ color: `${pallet.general.color1}` }}
                      />
                    )}
                  </Grid>
                ) : (
                  <Grid
                    style={{
                      backgroundColor: "#DED3FF",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderBottomLeftRadius: "5px",
                      borderTopLeftRadius: "5px",
                      width: "68px",
                    }}
                  >
                    {props.originFederal === "PROPOSIÇÃO" ? (
                      <AssignmentIcon
                        style={{ color: `${pallet.general.color1}` }}
                      />
                    ) : (
                      <AccountBoxIcon
                        style={{ color: `${pallet.general.color1}` }}
                      />
                    )}
                  </Grid>
                )}

                <Grid
                  style={{
                    border: "1px solid #EAEAEA",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    background: `${pallet.backGroundPageColorPrimary}`,
                  }}
                >
                  {expanded === true ? (
                    <ArrowDropUpIcon
                      style={{
                        marginRight: "5px",
                        marginLeft: "10px",
                        color: `${pallet.general.color1}`,
                      }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      style={{
                        marginRight: "5px",
                        marginLeft: "10px",
                        color: `${pallet.general.color1}`,
                      }}
                    />
                  )}
                  <Grid
                    style={{
                      padding: "10px",
                      color: `${pallet.textColorQuaternary}`,
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size14}`,
                      fontWeight: pallet.general.weight_600,
                    }}
                  >
                    {props.titleFederal}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSum>

            {expanded === true ? (
              <AccordionDetails
                sx={{
                  fontFamily: `${pallet.general.fontFamily}`,
                  border: "1px solid #DFDFDF",
                  padding: "0px",
                  borderRadius: "0px 0px 5px 5px",
                }}
              >
                {seletorConteudo(props.indice)}
              </AccordionDetails>
            ) : null}
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}

export default AccordionInsight;
