import { Grid, Typography } from '@mui/material';
import React from 'react';
import { usePallet } from '../../../contexts/PalletContext';
import { useNavigate } from 'react-router-dom';


const SummaryPanelWidget = (props: { title: string, items: { value: number, label: string,link?: string }[] }) => {

    const navigate = useNavigate();
    const { pallet } = usePallet();
    return (
        <Grid
            style={{
                padding: "10px",
                backgroundColor: `${pallet.backgroundColorTertiary}`,
                border: "1px solid #EAEAEA",
                width: "100%",
                margin: "0 auto",
            }}>
            <Grid item>
                <Typography style={{
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontSize: `${pallet.general.size12}`,
                    fontWeight: pallet.fontWeight.subtitle,
                    color: `${pallet.general.color1}`
                }}>
                    {props.title}
                </Typography>

            </Grid>
            <Grid container >
                {props.items.map((item, index) => {
                    return (
                        <>
                            <Typography style={{
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontSize: `${pallet.general.size12}`,
                                fontWeight: pallet.fontWeight.subtitle,
                                margin: "0 10px 0 0",
                                borderLeft: index > 0 ? "1px solid #3B0675" : "0px",
                                paddingLeft: index > 0 ? "5px" : "0px",
                                cursor: item.link ? "pointer" : "default"
                            }}
                            onClick = {item.link ? () => navigate(item.link) : null}

                            >
                                {item.value}
                            </Typography>
                            <Typography style={{
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontSize: `${pallet.general.size12}`,
                                fontWeight: pallet.fontWeight.text,
                                margin: "0 5px 0 0",
                                cursor: item.link ? "pointer" : "default"
                            }}
                            onClick = {item.link ? () => navigate(item.link) : null}
                            >
                                {item.label}
                            </Typography>
                        </>
                    )
                })}
            </Grid>
        </Grid >
    )
}


export default SummaryPanelWidget;