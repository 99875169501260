import * as http from '../helpers/http';

class ParlamentarTwitterService {

  GetTweetsListInterlocutor = async (username: String, pageIndex: number = 1, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/parlamentar/${username}/twitterInterlocutor?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {return result.data;}
    return undefined;
  };

}

export default new ParlamentarTwitterService();
