import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import DoughnutParlamentarDiscurso from "./DoughnutParlamentarDiscurso";
import ParlamentarDiscursoService from "../../../services/ParlamentarDiscursoService";
import { format, subYears } from "date-fns";

export default function ParlamentarDiscursosDados(props: { id_parlamentar: number }) {
    const { pallet } = usePallet();

    const [summary, setSummary] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });
    const [loading, setLoading] = useState(true);
    const [filtro, setFiltro] = useState<Array<Date>>([subYears(new Date(), 1), new Date()]);

    useEffect(() => {
        getList();
        // eslint-disable-next-line 
    }, [filtro])

    const getList = useCallback(() => {
        if (filtro.length === 2 && props && props.id_parlamentar && filtro && filtro.length === 2) {
            const filter = [
                { key: 'dtIni', value: format(filtro[0], 'yyyy-MM-dd') },
                { key: 'dtFin', value: format(filtro[1], 'yyyy-MM-dd') },
            ]
            ParlamentarDiscursoService.GetSummary(props.id_parlamentar, filter)
                .then(async res => {
                    setSummary(() => res)
                    setLoading(false)
                })
        }
    }, [filtro])

    const setFilterDate = (ini, fin) => {
        setFiltro(() => [ini, fin])
    }


    return (

        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                    <DoughnutParlamentarDiscurso
                        loading={loading}
                        dtIni={filtro ? filtro[0] : subYears(new Date(), 1)}
                        dtFin={filtro ? filtro[1] : new Date()}
                        masterTitle={"ÍNDICE DE QUALIFICAÇÃO DE DISCURSOS"}
                        // titleChart={`Total: ${sumary?.convergente + sumary?.divergente + sumary?.neutro + sumary?.naoClassificado}`}
                        labels={[
                            { key: "Convergente", data: summary?.convergente, color: `${pallet.charts.green}` },
                            { key: "Divergente", data: summary?.divergente, color: `${pallet.charts.red}` },
                            { key: "Neutro", data: summary?.neutro, color: `${pallet.charts.lightGrey}` },
                            // { key: "Não Classificado", data: sumary?.naoClassificado, color: `${pallet.charts.blue}` },
                        ]}
                        onFilterChange={setFilterDate}
                    />
                </Grid>
            </Grid>

        </div>
    )
}