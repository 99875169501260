import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Rotas from './routes/Routes'
import { usePallet } from './contexts/PalletContext';
// import Alerta from './components/Alerta';

function App() {
    const { pallet } = usePallet();
    return (
        <>
            <CssBaseline />
            <div className="App" >
                <header className="App-header" />
                {/* <main className={classes.content}> */}
                {/* <Alerta /> */}
                <BrowserRouter>
                    {!pallet ? <></> : <Rotas />}
                </BrowserRouter>
                {/* </main> */}

            </div>
        </>
    );
}

export default App;
