// import React from 'react';
import useStyles from './layout/Styles';
import { Grid, Divider, Typography, Paper, Box } from '@mui/material';
import { usePallet } from '../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import EmptyError from './EmptyError';
import { Link } from '@mui/material';
import { inherits } from 'util';

// import { Translate } from '@material-ui/icons';


// const PieArcLabelFormat = (props) => {
//     return (
//         <div onClick={() => {alert("Ola,,,,,,,")}} style={{textAlign:"center", border:"1px solid red", textAnchor:"start", cursor:"pointer"}}>
//             <div>{props.key}</div>  
//             <div style={{fontWeight:"bold"}}>{props.data}</div>
//         </div>
//     )
// }


const PieChartInfoCard = (props: {
    title: string,
    labels: Array<{ key: string, data: number, color: string, url?: string }>,
    subtitle?: string
}) => {

    const classes = useStyles();
    const { pallet } = usePallet();

    const data = {
        labels: props.labels.map((i) => { return (i.key) }),
        datasets: [{
            label: props.title,
            data: props.labels.map((i) => { return (i.data) }),
            backgroundColor: props.labels.map((i) => { return (i.color) }),
            hoverBackgroundColor: props.labels.map((i) => { return (i.color) }),
            borderWidth: 0
        }],

    };
    const options = {
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: true,
                color: "#FFFFFF",
                // backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 2,
                font: {
                    size: 15,
                    weight: 600
                },
                formatter: (value, context) => {
                    if (value === 0) {
                        return ""
                    }
                    return " " + Math.round((value / (context.dataset.data.reduce((a, b) => a + b, 0) / 100))) + '% '; //Retornar porcentagem
                }
            }
        },
        maintainAspectRatio: false,
        segmentShowStroke: false
    };

    const height = 250;
    const width = 400;
    const padAngle = 0.02;
    const padRadius = 200;
    const cornerRadius = 6;


    return (
        <Paper>
            <div className={classes.paper}>
                <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item>
                        <Typography
                            style={{
                                color: `${pallet.color.quinternary.font}`,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: pallet.general.weight_bold,
                                //paddingRight: "20px"
                            }}>
                            {props.title} {props?.subtitle ? "|" : null}
                        </Typography>

                    </Grid>
                    <Grid item>
                        <Typography
                            style={{
                                color: `${pallet.color.secundary.font}`,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: pallet.general.weight_bold,
                                paddingLeft: "0px"
                            }}>
                            {props?.subtitle}
                        </Typography>

                    </Grid>



                </Grid>
            </div>
            <Divider />

            {props.labels.reduce((a, b) => a + b["data"], 0) > 0 ?
                <Grid>
                    <Box display="flex" justifyContent="center" style={{ paddingTop: "30px", paddingBottom: "30px" }} >
                        <Doughnut
                            width={width}
                            height={height}
                            data={data}
                            options={options}
                            plugins={[ChartDataLabels]}
                        />
                    </Box>
                    <Divider />
                    <Box justifyContent="flex-start" style={{ paddingTop: "5px", paddingRight: "20", paddingBottom: "18px", paddingLeft: `${isMobile ? "7px" : "25px"}` }} >
                        {props.labels.map(i => (
                            <Grid container key={i.key} style={{ marginTop: `${isMobile ? "7px" : "13px"}` }}>
                                <div style={{
                                    backgroundColor: `${i.color}`,
                                    width: "25px", height: "5px",
                                    borderRadius: "3px",
                                    marginTop: "8px",
                                    marginRight: `${isMobile ? "5px" : "12px"}`
                                }} />
                                {/* <div style={{
                                    marginRight: `${isMobile ? "4px" : "12px"}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.subtitle
                                }}> */}
                                {i.url?
                                <Link href={i.url} style={{
                                    color: `${pallet.color.secundary.font}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.subtitle,
                                    textDecoration: "none"                                    
                                }}>
                                    
                                    {i.key}
                                    
                                </Link>                                
                                : i.key}
                                {/* </div> */}
                                
                                <div style={{
                                    marginLeft: `${isMobile ? "4px" : "12px"}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontWeight: pallet.fontWeight.lighter,
                                    color: `${pallet.color.quaternary.font}`
                                }}>{i.data}</div>
                            </Grid>
                        ))}
        </Box>
                </Grid >
                :
<Grid
    style={{
        color: `${pallet.textColorQuaternary}`,
        fontSize: `${pallet.fontSize.titleWidgets}`,
        fontFamily: `${pallet.fontFamily.general}`,
        fontWeight: 600,
        textAlign: "center",
        paddingBottom: "98px",
        marginTop: "20px",
    }}
>
    <EmptyError title="Ops!" description="Ainda não temos dados suficientes para gerar resultados." />
</Grid>
            }

        </Paper >

    )

}

export default PieChartInfoCard;