import * as Yup from "yup";

const TCCadastroTopicosCorporativoSchema = Yup.object({
    descricao: Yup.string().required({ name: "nome", err: "Nome é obrigatório" }),
    ativo: Yup.bool().required({ name: "status", err: "Status é obrigatório" }),
    id_tema: Yup.number().typeError({ name: "tema", err: "Tema é obrigatório" }).required({ name: "tema", err: "Tema é obrigatório" }),
    id_subtema: Yup.number().typeError({ name: "subtema", err: "Subtema é obrigatório" }).required({ name: "Subtema", err: "Tema é obrigatório" }),
});

export default TCCadastroTopicosCorporativoSchema;
