import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import IATreinamentoList from "./IATreinamentoList";

const IACarouselTabsTreinamento = (props: {
    activeTab: any,
    index: number
}) => {

    return (
        <Paper style={{
            borderRadius: "6px",
            paddingTop: `${isMobile ? "0px" : "0px"}`,
        }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <IATreinamentoList />
            </TabPanel>
        </Paper>
    )
}

export default IACarouselTabsTreinamento;