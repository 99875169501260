import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import {
    Container,
    Divider,
    Grid,
    Paper,
} from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import styled from "styled-components";
import { useAlert } from "../../../../contexts/AlertContext";
import { useAuthorization } from '../../../../contexts/AuthorizationContext';
import ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaColaborativaService";
import CircularLoading from '../../../../components/CircularLoading';
import AssignmentIcon from "@mui/icons-material/Assignment";
import RealTimeQuillEditor, { IRange } from "./RealTimeQuillEditor";
import { formatDate } from "../../../../utils/dateUtils";
import { EComentarioModulos } from "../../../../models/Proposicao.Model";
import ComentarioService from "../../../../services/ComentarioService";
import { useNavigate } from "react-router-dom";
import { htmlToText } from 'html-to-text';
import StringsFile from 'strings-file';
import { saveAs } from "file-saver";

const Card = styled(Grid)`
    cursor: pointer;
    background-color: #4B11961A;
    color: ${(props => props.pallet.textColorQuaternary)};
    font-weight: ${(props => props.pallet.general.weight_bold)};
    font-size: 14px;
    font-family: ${(props => props.pallet.general.fontFamily)};
    padding: 18px;
    margin-top: 24px;
    text-transform: uppercase;
    border-radius: 4px;
    display: flex;
    white-space: nowrap;

    span, svg {
        color: ${(props => props.pallet.textColorSecondary)};
        margin: 0 5px;
    }
    svg {
        margin: 0px 10px 0px 0px;
    }
`;

export interface INTColaborativa {
    id_acao_preparatoria: number;
    proposicao: string;
    id_proposicao: number;
    prazo: Date;
    responsaveis: number[];
    id?: number;
    texto?: string;
    criado_em?: Date;
    atualizado_em?: Date;
}

interface IDoc {
    id: number;
    name: string;
    content: string;
}

export const EditorNotaTecnicaColaborativa = (props) => {
    const { pallet } = usePallet();
    const { NewAlert } = useAlert();
    const { user } = useAuthorization();


    const [loading, setLoading] = React.useState(false);

    const { idAcao } = useParams() as any;
    const [notaTecnica, setNotaTecnica] = useState<INTColaborativa>();
    const navigate = useNavigate();
    const [pageHeight, setPageHeight] = useState(window.innerHeight);

    useEffect(() => {
        const updatePageHeight = () => {
            setPageHeight(window.innerHeight);
        };

        window.addEventListener('resize', updatePageHeight);

        return () => {
            window.removeEventListener('resize', updatePageHeight);
        };
    }, []);





    const modules = {
        toolbar: [
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'super' }, { 'script': 'sub' }],
            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            // ['direction', { 'align': [] }],
            ['link'/*, 'image', 'video', 'formula'*/],
            // ['clean'],
            ['comments-add'],
            // ['print'],
        ],
        history: {
            userOnly: true
        }
    };

    const [docs, updateDocs] = useState<IDoc[]>([{
        "id": idAcao,
        "name": `NTColaborativa_${idAcao}`,
        "content": ""
    }])
    const [activeDoc, setActiveDoc] = useState<IDoc>()

    useEffect(() => {
        if (idAcao) {
            ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.Get(idAcao)
                .then(res => {
                    setNotaTecnica(res)
                    const textValue = {
                        "id": idAcao,
                        "name": `NTColaborativa_${idAcao}`,
                        "content": res.texto ? res.texto : ""
                    }
                    updateDocs([textValue])
                    setActiveDoc(textValue ? textValue : docs[0])
                })
        }
    }, [idAcao])


    const saveContent = async (docId: number, texto: string) => {
        setActiveDoc(null)
        if (!docId) return
        await ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.Update({
            "texto": texto
        }, idAcao)
            .then(res => {
                NewAlert('success', 'Nota atualizada com sucesso')
                const textValue = {
                    "id": docId,
                    "name": `NTColaborativa_${idAcao}`,
                    "content": texto
                }
                setActiveDoc(textValue)
            })
    }

    const handleOnChangeContent = (docId, content) => {
        const updatedDoc = docs.map(doc => {
            if (doc.id === docId) {
                return ({ ...doc, content })
            }
            return doc
        })
        updateDocs(updatedDoc)
    }

    const addComment = (comment: string, range: IRange) => {

        const data = {
            comentario: comment,
            modulo: EComentarioModulos.NotaTecnicaColaborativa,
        };
        ComentarioService.Create(String(idAcao), data)
            .then(
                (res) => {
                    if (res) return NewAlert('success', 'Comentário salvo com sucesso.')
                }
            );

    }


    return (
        <div className="relativeCSS">
            <style type="text/css" media="print">
                {"\
                        @page { size: 1250px 1650px; margin-top: 0.0in; margin-left: 0.03in; margin-bottom: 0.0in; margin-right: 0.03in }\
                        @body {transform: scale(100)}\
                    "}
            </style>
            <div className="flash" />
            <div style={{ width: '1150px', WebkitPrintColorAdjust: 'exact' }} >
                <Grid style={{
                    color: `${pallet.textColorQuaternary}`,
                    fontWeight: pallet.general.weight_bold,
                    fontSize: "24px",
                    fontFamily: `${pallet.general.fontFamily}`,
                    marginTop: "30px"
                }}>
                    Nota Técnica Colaborativa
                </Grid>

                <Grid xl={5} lg={5} xs={5} container
                    justifyContent="flex-start"
                    alignItems="center">
                    <Card pallet={pallet} >
                        <AssignmentIcon fontSize="small" /> <span onClick={() => navigate(`/proposicao/acao-preparatoria/${idAcao}`)}>Ação Preparatória</span><span>{notaTecnica?.prazo ? formatDate(new Date(notaTecnica?.prazo), 'dd/MM/yyyy') : ""}</span> | <span onClick={() => navigate(`/proposicao/${notaTecnica?.id_proposicao}`)}>{notaTecnica?.proposicao}</span>
                    </Card>
                </Grid>
                <Paper style={{
                    backgroundColor: `${pallet.backGroundPageColorSecondary}`,
                    position: "relative",
                    height: `${pageHeight - 100}px`,
                    marginTop: "30px",
                }}>{activeDoc ?
                    <RealTimeQuillEditor
                        modules={modules}
                        docId={activeDoc.id}
                        value={activeDoc.content}
                        onSave={saveContent}
                        onChange={handleOnChangeContent}
                        onComment={addComment}
                        idNotaTecnica={notaTecnica.id}
                    />
                    :
                    null}
                </Paper>
            </div>
        </div>
    );
};

export default EditorNotaTecnicaColaborativa;
