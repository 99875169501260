import PrivateRoute from '../../components/PrivateRoute';
import Citacoes from './Citacoes';

export function CitacoesRoutes(): JSX.Element {
    return (
        <>
            <PrivateRoute path="/citacoes" element={< Citacoes />} />
        </>
    );
}
