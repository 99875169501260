import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Button, Divider, Grid, ListItemText } from "@mui/material";
// import { isMobile } from "react-device-detect";
import { styled, alpha } from "@mui/material/styles";
import { usePallet } from "../../../contexts/PalletContext";
import { useNavigate } from "react-router-dom";
import MenuButtonIconEditActive from './MenuButtonIconEditActive';
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { isMobile } from "react-device-detect"
import Menu, { MenuProps } from "@mui/material/Menu";
import React from 'react';
import { MoreHoriz } from "@material-ui/icons"
import MenuItem from "@mui/material/MenuItem";
import GerDepartamentosEdicao from './GerDepartamentosEdicao';
import UsuarioService from '../../../services/UsuarioService';
import ButtonDialogConfirmation from '../../../components/ButtonDialogConfirmation';
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from '../../../contexts/AlertContext';
import { useAuthorization } from '../../../contexts/AuthorizationContext';

export interface IMenuButtonEditActive {
  idregister: number,
  active: boolean,
  path?: string
}

//## StyledCompenents
const TemaContent = styled(Grid)`
 align-items: center;
 border: 1px solid #EAEAEA;
 border-radius: 4px;
 margin: 5px 0;

 div.not-header {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     width: 100%;
     flex-direction: row;
     color: #1E0736;
     font-size: 15px;

     a { 
      cursor: pointer;
     }
     
     strong {
       font-size: 17px;
       margin: 0 20px;
     }
     p {
       font-size: 17px;
       font-weight: bold;
       color: #4B1196;
       margin: 0 10px 0 0;
     }
 }
 div.not-header-icon {
     background-color: #F2F2F2;
     width: 50px;
     height: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 4px 0px 0 4px;

     svg {
         color: #9B9B9B;
     }

     &.active {
       background-color: #DED3FF;

       svg { 
         color: #4B1196;
       }
     }
 }
`;








const StyledMenu = styled((props: MenuProps) => (
  <Menu
      elevation={0}
      anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
      }}
      transformOrigin={{
          vertical: "top",
          horizontal: "right",
      }}
      {...props}
  />
))(({ theme }) => ({
  ".MuiListItemText-primary": {
      fontSize: 0
  },
  "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(0),
      minWidth: 180,
      color:
          theme.palette.mode === "light"
              ? "rgb(55, 65, 81)"
              : theme.palette.grey[300],
      boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
          padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
          "& .MuiSvgIcon-root": {
              fontSize: 18,
              color: theme.palette.text.secondary,
              marginRight: theme.spacing(1.5),
          },
          "&:active": {
              backgroundColor: alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity
              ),
          },
      },
  },
}));

export default function GerDepartamentoCard(props: {
  departamento_id: number,
  departamento_descricao: string,
  onChange?: () => void

}) {
  const { pallet } = usePallet();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState();
  const { NewAlert } = useAlert();
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(undefined);
  };
  const { user } = useAuthorization();


  const deletarDepartamento = (id) => {
    UsuarioService.DeleteGerDpto(id)
        .then((res) => {
          //console.log("OLA",Number(res))
            if (Number(res) === -1) {
                handleClose();
                NewAlert("error", "Essa unidade de negócio possui membros, o mesmo não pode ser apagado");
                
            } 
            else if (Number(res) === 1) {
              NewAlert("success", "A unidade de negócio foi apagado com sucesso");
               props?.onChange()
            }
            
            else {
                NewAlert(
                    "error",
                    "Erro ao apagar, tente novamente"
                );
            }
        })
    // .then((x) => loadAcontecimentos(props?.id_proposicao));
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
    setOpen(id);
    setAnchorEl(event.currentTarget);
};


  
  return (
    <>
      <TemaContent>
        <div className="not-header">
          <Grid container justifyContent={"space-between"}>
            <Grid>
              <Grid container>
                <Grid>
                  <div className={`not-header-icon active`}>
                  <SupervisorAccountIcon fontSize="small" />
                  </div>
                </Grid>
                <Grid style={{ alignSelf: "center",maxWidth: isMobile ? "120px" : "100%",whiteSpace: isMobile ? "nowrap" : "unset" ,overflow: isMobile ? "hidden" : "unset"  ,textOverflow: isMobile ? "ellipsis" : "unset" }}>
                  <a onClick={() => navigate(`/gerenciamentodeusuarios/unidade_de_negocio/${props.departamento_id}`)}>
                    <strong>{props.departamento_descricao}</strong>
                  </a>
                </Grid>
              </Grid>
            </Grid>

            <Grid>

            <Grid style={{
                        marginTop: "10px",
                        marginRight: `${isMobile ? "10px" : "20px"}`,
                    }}>

                            <>
                            <Button
                            id={`button`}
                            aria-controls={
                                anchorEl ? `mmenu` : undefined
                            }
                            disableElevation
                            variant="contained"
                            size="large"
                            style={{
                                backgroundColor: `${pallet.general.color3}`,
                                textTransform: "capitalize",
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: pallet.fontWeight.button,
                                fontSize: `${pallet.fontSize.smallButton}`,
                                color: `${pallet.color.secundary.font}`,
                                width: "25px",
                                height: "40px",
                            }}
                            onClick={(e) => handleClick(e, 1)}
                            endIcon={
                                <Grid
                                    style={{
                                        fontSize: "30px",
                                        color: `${pallet.general.color4}`,
                                        marginTop: "9px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <MoreHoriz fontSize="inherit" />
                                </Grid>
                            }
                        ></Button>
                            <StyledMenu

                            id={`mmenu`}
                            MenuListProps={{
                                "aria-labelledby": `button`,
                            }}
                            anchorEl={anchorEl}
                            open={open === 1}
                            onClose={handleClose}
                        >
                            <MenuItem>
                                <GerDepartamentosEdicao
                                     id_departamento={props.departamento_id}
                                     closeMenu={() => handleClose()}
                                     idPermissao={user.id_permissao}
                                     reloadList={props.onChange}
                                />
                            </MenuItem>
                            <Divider
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                }}
                            />
                                    <MenuItem
                                        style={{
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.fontWeight.button,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            borderRadius: "6px",
                                            padding: "6px",
                                        }}
                                    >
                                        <ButtonDialogConfirmation
                                            styles={{
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.button,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`,
                                                borderRadius: "6px",
                                                textTransform: "capitalize",
                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            // lastPath={"/parlamentar"}
                                            confirmationEvent={() => user.id_permissao !== 3 ? deletarDepartamento(props?.departamento_id) : 
                                              NewAlert(
                                                "error",
                                                "Não é possível realizar está ação com este perfil.")}
                                            title_button={"Apagar"}
                                            confirmation_button={"Apagar"}
                                            content={"APAGAR UNIDADE DE NEGÓCIO?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        >
                                            <DeleteIcon
                                                style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    marginRight: "16px",
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                }}

                                            />
                                            <ListItemText>!</ListItemText>
                                        </ButtonDialogConfirmation>
                                    </MenuItem>
                        </StyledMenu>
                        </>
                        
                    </Grid>


            </Grid>

            </Grid>

        </div>
      </TemaContent>
    </>
  )
}