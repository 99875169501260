import PrivateRoute from '../../components/PrivateRoute';
import ProposicaoCadastro from './Proposicao.Cadastro';
import ProposicaoConsulta from './Proposicao.Consulta';
import ProposicaoLista from './Proposicao.Lista';
import ProposicaoAcaoPreparatoriaEspecifica from '../proposicao/_components/acaopreparatoria/ProposicaoAcaoPreparatoriaEspecifica';
import ProposicaoDashboard from './Proposicao.Dashboard';
import ProposicaoAcaoPreparatoriaNTColaborativa from './_components/acaopreparatoria/ProposicaoAcaoPreparatoriaNTColaborativa';
import NTColaborativaImprimir from './_components/acaopreparatoria/NTColaborativaImprimir';
import RelatorioProposicao from './_components/relatorio_proposicao/RelatorioProposicao';

export function ProposicaoRoutes() {
    return (
        <>
            <PrivateRoute path="/proposicao/" element={<ProposicaoLista />} />
            <PrivateRoute path="/proposicao/cadastro" element={<ProposicaoCadastro />} />
            <PrivateRoute path="/proposicao/:idProposicao/cadastro" element={<ProposicaoCadastro />} />
            <PrivateRoute path="/proposicao/:id/acaopreparatoria" element={<ProposicaoConsulta />} />
            <PrivateRoute path="/proposicao/:id/acaoinfluencia" element={<ProposicaoConsulta />} />
            <PrivateRoute path="/proposicao/:id/correlata" element={<ProposicaoConsulta />} />
            <PrivateRoute path="/proposicao/:id/emenda" element={<ProposicaoConsulta />} />
            <PrivateRoute path="/proposicao/:id" element={<ProposicaoConsulta />} />
            <PrivateRoute path="/proposicao/:id/acontecimentos" element={<ProposicaoConsulta />} />
            <PrivateRoute path="/proposicao/acao-preparatoria/:id" element={<ProposicaoAcaoPreparatoriaEspecifica />} />
            <PrivateRoute path="/proposicao/acao-preparatoria/:idAcao/nota-tecnica-colaborativa" element={<ProposicaoAcaoPreparatoriaNTColaborativa />} />
            <PrivateRoute path="/proposicao/acao-preparatoria/:idAcao/nota-tecnica-colaborativa/imprimir" element={<NTColaborativaImprimir />} />
            <PrivateRoute path="/proposicao/dashboard" element={<ProposicaoDashboard />} />
            <PrivateRoute path="/proposicao/relatorio/:id/:statusTramitacao/:statusAcontecimento/:statusTimeline/:statusAcaoPreparatoria/:statusAcaoInfluencia/:statusApensada/:statusEmenda" element={<RelatorioProposicao />} />
        </>
    );
}

