import { Divider, Grid } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import ComboBox from "../../../components/ComboBox";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import { IFilterData } from "../../../components/filter/ListFilter";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import React, { useEffect, useState } from "react";
import PieChartInfoCard from "../../../components/PieChartInfoCard";
import PieChartInfoInsight from "./PieChartInfoInsight";
import InsightService from "../../../services/InsightService";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/dateUtils";
import { IListFilterInputItem } from "../../../components/filter/ListFilterItems";

export function AccordionContentKey4(props: {
  // filtros?: IFiltroTipoProps[],
  titleFederal?: string;
  originFederal?: string;
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string
  onChange?: (event: any) => void;
}) {
  const ErrorMessage = (props: {
    name: string;
    errors: Array<{ name: string; err: string }>;
  }) => {
    const itemError = props.errors.filter((err) => err.name === props.name);
    return (
      <div style={{ color: "red", fontSize: "11px", marginTop: "5px" }}>
        {itemError.length === 1 && itemError[0].err}
      </div>
    );
  };

  const tagDiscursoCor = (value: string) => {
    if (value) {
      value = value.toLowerCase();
    }
    switch (value) {
      case "divergente":
        return `${pallet.charts.red}`;
      case "convergente":
        return `${pallet.charts.green}`;
      case "neutro":
        return `${pallet.charts.lightGrey}`;
      default:
        return `${pallet.charts.blue}`;
    }
  }

  const [loading, setLoading] = React.useState(false)

  const [casa, setCasa] = React.useState<number>();
  const [casaLabel, setCasaLabel] = React.useState<string>("");
  const [casaItens, setCasaFederalItens] = React.useState<Array<IFilterData>>([
    // { key: "0", label: "Todas ", value: "0" },
    { key: "1", label: "Senado Federal", value: "1" },
    { key: "2", label: "Câmara dos Deputados", value: "2" },
    // { key: "3", label: "Congresso Nacional", value: "3" },
  ]);


  const [estado, setEstado] = React.useState<string>("");
  const [estadoItens, setEstadoItens] = React.useState<Array<IListFilterInputItem>>([]);

  const [casaEstados, setCasaEstados] = React.useState<string>();
  const [casaEstadosItens, setCasaEstadosItens] = React.useState<Array<IListFilterInputItem>>([])
  const [data, setData] = useState({});

  const [casaMunicipios, setCasaMunicipios] = React.useState<string>("");
  const [casaMunicipiosItens, setCasaMunicipiosItens] = React.useState<Array<IListFilterInputItem>>([])



  const [posicionamento, setPosicionamento] = React.useState<string>("");

  const [error, setError] = useState<Array<{ name: string; err: string }>>([]);
  const { pallet } = usePallet();
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const handleCasa = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: number
  ) => {
    setCasa(Number(event.target.value));
    handleCasaLabel(casa);

  };

  const handleCasaLabel = (casa: number) => {
    switch (casa) {
      case 1:
        return "Senado Federal";
      case 2:
        return "Câmara dos Deputados";
      case 3:
        return "Congresso Nacional";
      default:
        return "Todas";
    }
  };


  const handleCasaLabelSigla = (casa: number) => {
    switch (casa) {
      case 1:
        return "SF";
      case 2:
        return "CD";
      case 3:
        return "CN";
      default:
        return "Todas";
    }
  };

  const [listaInterlocutores, setListaInterlocutores] = React.useState([]);
  const [interlocutor1, seinterlocutor1] = React.useState();
  const [interlocutor2, setinterlocutor2] = React.useState();
  const [interlocutor3, setinterlocutor3] = React.useState();
  const [interlocutor4, setinterlocutor4] = React.useState();
  const [interlocutor5, setinterlocutor5] = React.useState();
  const [outros, setOutros] = React.useState();
  const navigate = useNavigate();

  const corPosicionamento = (value: string) => {

    switch (value) {
      case "Contrário":
          return `${pallet.charts.red}`;
      case "Contrário com emenda saneadora":
          return `${pallet.charts.orange}`;
      case "Neutro":
          return `${pallet.charts.purple}`;
      case "Favorável com emenda saneadora":
          return `${pallet.charts.blue}`;
      case "Favorável":
          return `${pallet.charts.green}`;
      case "Pendente":
          return `${pallet.charts.yellow}`;
      case "Indefinido":
          return `${pallet.charts.lightGrey}`;
      case "Favorável ao parecer do relator":
          return '#C71585';
      case "Favorável ao parecer do relator com emenda":
          return '#B8860B';
      case "Contrário ao parecer do relator":
          return '#facb74';
      case "Contrário ao parecer do relator com emenda":
          return '#000000';
  
      default:
          return `${pallet.charts.lightGrey}`;
  }
  }

  function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
          </Grid>
          <Grid item >
            {props?.posicionamento}
          </Grid>
        </Grid>
      </>
    )
  }

  const posicionamentoItens = [
    { key: 0, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "Neutro" },
    { key: 1, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "Contrário com emenda saneadora" },
    { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "Contrário" },
    { key: 3, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "Favorável com emenda saneadora" },
    { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "Favorável" },
    { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "Favorável ao parecer do relator" },
    { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "Favorável ao parecer do relator com emenda" },
    { key: 7, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "Contrário ao parecer do relator" },
    { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "Contrário ao parecer do relator com emenda" },
    { key: 8, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "Pendente" },
    { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "Indefinido" },
  ]

  const handlePosicionamentoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPosicionamento(event.target.value);

  };

  const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
`;
  const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: ${isMobile ? "9px" : "10px"};
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 6px 4px 6px;
    line-height: 12px;
    letter-spacing: 1px;
    width: 'auto'; 
`;

  const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "3px" : "5px"};
    margin-top: 2px;
`;

  const Title = styled(Grid)`
    padding-left:  ${isMobile ? '5px' : '24px'};
    padding-top: ${isMobile ? '5px' : '30px'} ;
    background-color: ${pallet.backGroundPageColorPrimary};
    text-transform: uppercase;
    font-size:${pallet.general.size12};
    color: ${pallet.textColorQuaternary};
    font-family: ${pallet.general.fontFamily};
    font-weight: ${pallet.general.weight_600};
    place-content:  ${isMobile ? 'center' : 'unset'};
    height: 79px;
`;

  const esferaConv = (value: any) => {
    switch (value) {
      case 'F':
        return 1;
      case 'E':
        return 2;
      case 'M':
        return 3;


    }
  }

  const posicionamentoValor = (value: string) => {
    switch (value) {
      case "Pendente":
        return 99;
      case "Contrário":
        return 1;
      case "Contrário com emenda saneadora":
        return 2;
      case "Neutro":
        return 3;
      case "Favorável com emenda saneadora":
        return 4;
      case "Favorável":
        return 5;
      case "Favorável ao parecer do relator":
        return 6;
      case "Favorável ao parecer do relator com emenda":
        return 7;
      case "Contrário ao parecer do relator":
        return 8;
      case "Contrário ao parecer do relator com emenda":
        return 9;
      case "Indefinido":
        return 0;
      default:
        return 0;
    }
  };

  console.log("posicionamento:", posicionamento)

  React.useEffect(() => {
    if (casa) {
      if (posicionamento === "Indefinido") {
        if (
          String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
          String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
        ) {
          InsightService.GetKey4ListIndefinido(

            format(new Date(props.dtIni), "yyyy-MM-dd"),
            format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), esferaConv(props.esfera), casa
          ).then((res) => {
            if (res?.data) {
              setListaInterlocutores([])
              setListaInterlocutores(res.data)
            }
          })
        }
      }
      if (casa && posicionamento === "Indefinido") {
        if (
          String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
          String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
        ) {
          InsightService.GetKey4GraficoIndefinido(

            format(new Date(props.dtIni), "yyyy-MM-dd"),
            format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), esferaConv(props.esfera), casa
          ).then((res) => {
            if (res?.data) {
              seinterlocutor1(res?.data.interlocutor_1)
              setinterlocutor2(res?.data.interlocutor_2)
              setinterlocutor3(res?.data.interlocutor_3)
              setinterlocutor4(res.data.interlocutor_4)
              setinterlocutor5(res?.data.interlocutor_5)
              setOutros(res?.data.outros)
            }
          });
        }
      }
    }
    if (casa) {
      if (
        String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
        String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
      ) {
        InsightService.GetKey4(

          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(posicionamentoValor(posicionamento)), esferaConv(props.esfera), casa
        ).then((res) => {
          if (res?.data) {
            setListaInterlocutores([])
            setListaInterlocutores(res.data)
          }
        })
      }
    }

    if (casa && posicionamento) {
      if (
        String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
        String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
      ) {
        InsightService.GetKey4Grafico(

          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(posicionamentoValor(posicionamento)), esferaConv(props.esfera), casa
        ).then((res) => {
          if (res?.data) {
            seinterlocutor1(res?.data.interlocutor_1)
            setinterlocutor2(res?.data.interlocutor_2)
            setinterlocutor3(res?.data.interlocutor_3)
            setinterlocutor4(res.data.interlocutor_4)
            setinterlocutor5(res?.data.interlocutor_5)
            setOutros(res?.data.outros)

          }
        });

      }
    }

  }, [props.dtIni, props.dtFin, props.esfera, casa, posicionamento]);



  return (
    <>
      <Grid>
        <Grid
          container
          style={{
            justifyContent: casa ? "space-around" : 'start',
            marginLeft: `${isMobile ? "0px" : casa === 0 || casa === undefined ? '89px' : "0px"}`,
            paddingTop: `${isMobile ? "40px" : "30px"}`,
            paddingBottom: `${isMobile ? "20px" : "30px"}`,
            paddingLeft: `${isMobile ? "10px" : "0px"}`,
            paddingRight: `${isMobile ? "10px" : "0"}`,
          }}
        >
          {/* // loading ? null : */}
          <Grid
            item
            md={4}
            xs={12}
            lg={4}
            style={{ paddingBottom: `${isMobile ? "20px" : "0px"}` }}
          >
            <SelectTextFieldA
              name={"casa-federal"}
              value={casa}
              onChange={handleCasa}
              options={casaItens}
              label={"CASA LEGISLATIVA"}
              id={"casa-federal"}
              focused={true}
              placeholder={"Selecione"}
            />
            <ErrorMessage name="casaFederal" errors={error} />
          </Grid>

          {
            <>
              {
                casa ?
                  <Grid item md={4} xs={12} lg={4}>
                    <SelectTextFieldA
                      name={"posicionamento"}
                      value={posicionamento}
                      onChange={handlePosicionamentoOnChange}
                      options={posicionamentoItens}
                      label={"POSICIONAMENTO"}
                      id={"posicionamento"}
                      focused={true}
                      placeholder={"Selecione"}
                    />
                    <ErrorMessage name="posicionamento" errors={error} />
                  </Grid>
                  : null
              }
            </>
          }

        </Grid>
        <Grid style={{ marginBottom: "32px" }}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          xs={12}
          style={{
            margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          <PieChartInfoInsight
            title="QUANTIDADE DE PROJETOS POR INTERLOCUTOR"
            // subtitle={handleCasaLabel(casa)}
            labels={
              listaInterlocutores.length > 0 ?
                [
                  {
                    key: listaInterlocutores[0] ? listaInterlocutores[0].nome : null,
                    data: listaInterlocutores[0] ? interlocutor1 : null,
                    color: listaInterlocutores[0] ? `${pallet.color.charts.blue}` : null,
                    url: listaInterlocutores[0] ? '/interlocutor/' + listaInterlocutores[0].id_parlamentar : null

                  },
                  {
                    key: listaInterlocutores[1] ? listaInterlocutores[1]?.nome : null,
                    data: listaInterlocutores[1] ? interlocutor2 : null,
                    color: listaInterlocutores[1] ? `${pallet.color.charts.green}` : null,
                    url: listaInterlocutores[1] ? '/interlocutor/' + listaInterlocutores[1].id_parlamentar : null

                  },
                  {
                    key: listaInterlocutores[2] ? listaInterlocutores[2]?.nome : null,
                    data: listaInterlocutores[2] ? interlocutor3 : null,
                    color: listaInterlocutores[2] ? `${pallet.color.charts.purple}` : null,
                    url: listaInterlocutores[2] ? '/interlocutor/' + listaInterlocutores[2].id_parlamentar : null

                  },
                  {
                    key: listaInterlocutores[3] ? listaInterlocutores[3]?.nome : null,
                    data: listaInterlocutores[3] ? interlocutor4 : null,
                    color: listaInterlocutores[3] ? `${pallet.color.charts.yellow}` : null,
                    url: listaInterlocutores[3] ? '/interlocutor/' + listaInterlocutores[3].id_parlamentar : null

                  },
                  {
                    key: listaInterlocutores[4] ? listaInterlocutores[4]?.nome : null,
                    data: listaInterlocutores[4] ? interlocutor5 : null,
                    color: listaInterlocutores[4] ? `${pallet.color.charts.red}` : null,
                    url: listaInterlocutores[4] ? '/interlocutor/' + listaInterlocutores[4].id_parlamentar : null

                  },
                  {
                    key: listaInterlocutores[5] ? 'Outros' : null,
                    data: listaInterlocutores[5] ? outros : null,
                    color: listaInterlocutores[5] ? `${pallet.color.charts.lightGrey}` : null,
                    url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"dataInicio","label":"DATA APRESENTAÇÃO INICIAL: ' +
                      formatDate(props.dtIni, "dd/MM/yyyy") +
                      '","value" : "' +
                      formatDate(props.dtIni, "MM/dd/yyyy") +
                      '"},{"key":"dataFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
                      formatDate(props.dtFin, "dd/MM/yyyy") +
                      '","value" : "' +
                      formatDate(props.dtFin, "MM/dd/yyyy") +
                      '"},{"key":"removeids","label":"OUTROS INTERLOCUTORES","value":"' + listaInterlocutores.filter((a, b) => b < 5).map((item) => {
                        return (
                          item.id_parlamentar
                        )
                      }).join(',') + '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value":"' + casa + '"},{"key":"posicionamento","label":"POSICIONAMENTO: ' + posicionamento + '","value":"' + posicionamentoValor(posicionamento) + '"}]',

                  },
                ]
                :
                []
            }
          />
        </Grid>

        {listaInterlocutores.length > 0 ?
          <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
            <Title container style={{
              borderTop: '1px solid #EAEAEA',
              borderLeft: '1px solid #EAEAEA',
              borderBottom: '1px solid #EAEAEA',
              borderRight: `${isMobile ? '1px solid #EAEAEA' : 0}`,
              borderRadius: '6px 0px 0px 0px'
            }}
              item lg={6} xs={6}>
              INTERLOCUTORES
            </Title>
            <Title container item lg={6} xs={6}
              style={{
                borderTop: '1px solid #EAEAEA',
                borderRight: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRadius: '0px 6px 0px 0px'
              }}>
              PROJETOS
            </Title>
            <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
              {listaInterlocutores?.map((x, i) => {
                return (
                  <>
                    <Grid lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderLeft: '1px solid #EAEAEA',
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== listaInterlocutores.length ? null : '0px 0px 0px 6px',
                      maxWidth: 'none',
                      wordBreak: 'break-word',
                      placeContent: `${isMobile ? 'center' : 'unset'}`

                    }}>
                      <Grid container item
                        onClick={() => navigate('/interlocutor/' + x.id_parlamentar)}
                        style={{
                          // paddingBottom: '510pxpx',
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size12}`,
                          fontWeight: pallet.general.weight_600,
                          cursor: "pointer",
                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}
                      >

                        {x.nome + "(" + x.siglaPartido + ")"}

                      </Grid>
                      <Grid container style={{ paddingBottom: '10px', placeContent: `${isMobile ? 'center' : 'unset'}` }}
                      >
                        <ChipStyle pallet={pallet}>
                          <Grid container item >
                            <DotStyle bg={tagDiscursoCor(x.qualificacao)}>
                            </DotStyle>
                            <Grid >
                              {x.qualificacao ? x.qualificacao : "Não classificado"}
                            </Grid>
                          </Grid>
                        </ChipStyle>
                      </Grid>
                    </Grid>
                    <Grid container item lg={6} xs={6}

                      style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "5px" : "15px"}`,
                        borderRight: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRadius: i + 1 !== listaInterlocutores.length ? null : '0px 0px 6px 0px',
                        color: `${pallet.textColorTertiary}`,
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size12}`,
                        fontWeight: pallet.general.weight_600,
                        placeContent: `${isMobile ? 'center' : 'unset'}`
                      }}>
                      <Grid onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(props.dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(props.dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(props.dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(props.dtFin, "MM/dd/yyyy") +
                        '"},{"key":"posicionamento","label":"POSICIONAMENTO: ' + posicionamento + '","value":"' + posicionamentoValor(posicionamento) + '"},{"key":"autor","label":"AUTOR: ' + x.nome + '","value":"' + x.nome + '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value":"' + handleCasaLabelSigla(casa) + '"}]')}
                        style={{ cursor: "pointer", padding: "5px", inlineSize: "fit-content" }}>
                        {x.count}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>

          :
          null
        }
      </Grid>
    </>
  );
}

export default AccordionContentKey4;
