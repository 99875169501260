import * as http from '../helpers/http';

class ProposicaoResultadosService {
    GetList = async (id: number, pageIndex: number, pageSize: number = 8): Promise<any> => {
        const result = await http.get(`/proposicoes/${id}/resultados?pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                lista: result.data.data
            };
        };
        return undefined;
    };

    GetListNoPage = async (id: number): Promise<any> => {
        const result = await http.get(`/proposicoes/${id}/resultadosNoPage`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    totalItens: result.data.totalItens,
                },
                lista: result.data.data
            };
        };
        return undefined;
    };

    Get = async (idAcontecimento: number): Promise<any> => {
        const result = await http.get(`/proposicao/resultados/${idAcontecimento}`);
        if (result.success) { return result };
        return undefined;
    };

    Create = async (data: any, id: number, tag?: string): Promise<number> => {
        const result = await http.post(`/proposicoes/${id}/resultados/${tag}`, data);
        if (result.success) {
            return result.data 
        };
        return 0;
    };

    Delete = async (id: number): Promise<boolean> => {
        const result = await http.del(`/proposicao/resultados/${id}`);
        if (result.success) { 
            return true 
        };
        return false;
    };
};

export default new ProposicaoResultadosService();
