import { MoreHoriz } from "@material-ui/icons"
import EditIcon from '@mui/icons-material/Edit'
import { Button, Divider, Grid } from "@mui/material"
import { isMobile } from "react-device-detect"
import MenuButtonIconNoText from "../../../components/MenuButtonIconNoText"
import { IParlamentar } from "../../../models/Parlamentar.Model"
import ButtonParlamentarSeguir from "./ButtonParlamentarSeguir"
import { useNavigate } from 'react-router-dom';
import { useAlert } from "../../../contexts/AlertContext"
import { useAuthorization } from "../../../contexts/AuthorizationContext";
import { usePallet } from "../../../contexts/PalletContext";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import React from "react"
import RelatorioModal from "./relatorio_parlamentar/RelatorioModal"


const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    ".MuiListItemText-primary": {
        fontSize: 0
    },
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "0px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export const ParlamentarConsultaBotoes = (props: { parlamentar: IParlamentar, onChange: () => void, idPermissao: number }) => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const { pallet } = usePallet();

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };

    // const editar = (props?.parlamentar?.id) => {
    //     console.log("EDITANDO");
    // };

    const { NewAlert } = useAlert();

    return (
        <Grid item>
            <div>
                <Grid container item justifyContent="flex-end">
                <Grid style={{ display: "flex"}}>
                 <Grid style={{
                        paddingRight: `${isMobile ? "5px" : "10px"}`
                    }}>
                        {props.idPermissao !== 4 ?
                        <ButtonParlamentarSeguir
                            key={props.parlamentar?.id}
                            onClick={props.onChange}
                            id={props.parlamentar?.id}
                            seguindo={props.parlamentar?.cliente ? props.parlamentar.cliente.seguindo : false}
                            marginRight={isMobile ? "5px" : "10px"}
                            width={isMobile ? "85px" : "100px"}
                        />
                        :
                        <Grid style={{marginBottom: "50px"}}>
                            </Grid>
                        
                    }
                    </Grid>

            
                    
    <Grid container item justifyContent="flex-end">

        <Grid style={{
            marginTop: "10px",
            marginRight: `${isMobile ? "10px" : "50px"}`,
        }}>
    
        {/* // <MenuButtonIconNoText */}
        {/* //     iconButton={<MoreHoriz />} */}
        {/* //     itens={[ */}
        {/* //         { path: `/usuario/${props?.usuario?.id}/cadastro`, icon: <EditIcon />, label: 'Editar perfil', }, */}
        {/* //         { path: `/usuario/${props?.usuario?.id}/cadastro`, icon: <PasswordIcon />, label: 'Alterar senha', }, */}
        {/* //     ]} */}

        {/* // /> */}
        <>
            <Button
                id={`button`}
                aria-controls={
                    anchorEl ? `mmenu` : undefined
                }
                disableElevation
                variant="contained"
                size="large"
                style={{
                    backgroundColor: `${pallet.backgroundColorTertiary}`,
                    textTransform: "capitalize",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.fontSize.smallButton}`,
                    color: `${pallet.color.secundary.font}`,
                    width: "25px",
                    height: "40px",
                }}
                onClick={(e) => handleClick(e, 1)}
                endIcon={
                    <Grid
                        style={{
                            fontSize: "30px",
                            color: `${pallet.general.color4}`,
                            marginTop: "9px",
                            marginRight: "10px",
                        }}
                    >
                        <MoreHoriz fontSize="inherit" />
                    </Grid>
                }
            ></Button>

            <StyledMenu
                id={`mmenu`}
                MenuListProps={{
                    "aria-labelledby": `button`,
                }}
                anchorEl={anchorEl}
                open={open === 1}
                onClose={handleClose}
            >
                {props.idPermissao !== 4 ? 
                <>
                        <MenuItem onClick={() => navigate(`/interlocutor/${props?.parlamentar?.id}/cadastro`)}
                            style={{
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: pallet.fontWeight.lighter,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                            }}>
                            <EditIcon /> 
                            Editar
                        </MenuItem>

                <Divider
                    style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                    }}
                />
                </>
                    :
                    null}
                <MenuItem>
                <RelatorioModal
                        parlamentar={props.parlamentar}
                        // usuario={props.usuario}
                        // closeMenu={() => handleClose()}
                    />
                </MenuItem>

            </StyledMenu>
        </>
    
</Grid>
</Grid>
</Grid>
                </Grid>
            </div>
        </Grid>
    )
}