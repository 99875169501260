import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import ParlamentarAcontecimentos from "./ParlamentarAcontecimentos";

const ParlamentarCarouselTabsAcontecimentos = (props: { id_parlamentar: number, activeTab: any, index: number,idPermissao?: number }) => {

    return (
        <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "30px"}`, }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <ParlamentarAcontecimentos id_parlamentar={props.id_parlamentar} index={props.index} activeTab={props.activeTab} idPermissao= {props.idPermissao} />
            </TabPanel>
        </Paper>
    )
}

export default ParlamentarCarouselTabsAcontecimentos;