import { addDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect } from 'react';
import AgendaCarouselTabsDomingo from './AgendaCarouselTabsDomingo';
import AgendaCarouselTabsQuarta from './AgendaCarouselTabsQuarta';
import AgendaCarouselTabsQuinta from './AgendaCarouselTabsQuinta';
import AgendaCarouselTabsSabado from './AgendaCarouselTabsSabado';
import AgendaCarouselTabsSegunda from './AgendaCarouselTabsSegunda';
import AgendaCarouselTabsSexta from './AgendaCarouselTabsSexta';
import AgendaCarouselTabsTerca from './AgendaCarouselTabsTerca';
import CarouselCustomAgenda from './CarouselCustomAgenda';

export default function AgendaCarouselDias(props: { listaDeEventos: Array<any>, dateStartReference: Date, loadEventos: () => void, loading?: boolean}) {
    const [activeTab, setActiveTab] = React.useState(0);
    const [listaHoje, setListaHoje] = React.useState([]);
    const [listaHojeMaisUm, setListaHojeMaisUm] = React.useState([]);
    const [listaHojeMaiDois, setListaHojeMaisDois] = React.useState([]);
    const [listaHojeMaisTres, setListaHojeMaisTres] = React.useState([]);
    const [listaHojeMaisQuatro, setListaHojeMaisQuatro] = React.useState([]);
    const [listaHojeMaisCinco, setListaHojeMaisCinco] = React.useState([]);
    const [listaHojeMaisSeis, setListaHojeMaisSeis] = React.useState([]);
    
    const [nomeDiaHoje, setNomeDiaHoje] = React.useState('');
    const [nomeDiaHojeMaisUm, setNomeDiaHojeMaisUm] = React.useState('');
    const [nomeDiaHojeMaisDois, setNomeDiaHojeMaisDois] = React.useState('');
    const [nomeDiaHojeMaisTres, setNomeDiaHojeMaisTres] = React.useState('');
    const [nomeDiaHojeMaisQuatro, setNomeDiaHojeMaisQuatro] = React.useState('');
    const [nomeDiaHojeMaisQuinto, setNomeDiaHojeMaisQuinto] = React.useState('');
    const [nomeDiaHojeMaisSexto, setNomeDiaHojeMaisSexto] = React.useState('');

    const [dateDiaHoje, setDateDiaHoje] = React.useState('');
    const [dateDiaHojeMaisUm, setDateDiaHojeMaisUm] = React.useState('');
    const [dateDiaHojeMaisDois, setDateDiaHojeMaisDois] = React.useState('');
    const [dateDiaHojeMaisTres, setDateDiaHojeMaisTres] = React.useState('');
    const [dateDiaHojeMaisQuatro, setDateDiaHojeMaisQuatro] = React.useState('');
    const [dateDiaHojeMaisQuinto, setDateDiaHojeMaisQuinto] = React.useState('');
    const [dateDiaHojeMaisSexto, setDateDiaHojeMaisSexto] = React.useState('');

    useEffect(() => {
        setNomeDiaHoje(format(props.dateStartReference ? props.dateStartReference : new Date, 'EEEE',{ locale: ptBR }))
        setNomeDiaHojeMaisUm(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 1), 'EEEE',{ locale: ptBR }))
        setNomeDiaHojeMaisDois(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 2), 'EEEE',{ locale: ptBR }))
        setNomeDiaHojeMaisTres(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 3), 'EEEE',{ locale: ptBR }))
        setNomeDiaHojeMaisQuatro(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 4), 'EEEE',{ locale: ptBR }))
        setNomeDiaHojeMaisQuinto(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 5), 'EEEE',{ locale: ptBR }))
        setNomeDiaHojeMaisSexto(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 6), 'EEEE',{ locale: ptBR }))

        setDateDiaHoje(format(props.dateStartReference ? props.dateStartReference : new Date, 'dd/MM',{ locale: ptBR }))
        setDateDiaHojeMaisUm(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 1), 'dd/MM',{ locale: ptBR }))
        setDateDiaHojeMaisDois(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 2), 'dd/MM',{ locale: ptBR }))
        setDateDiaHojeMaisTres(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 3), 'dd/MM',{ locale: ptBR }))
        setDateDiaHojeMaisQuatro(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 4), 'dd/MM',{ locale: ptBR }))
        setDateDiaHojeMaisQuinto(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 5), 'dd/MM',{ locale: ptBR }))
        setDateDiaHojeMaisSexto(format(addDays(props.dateStartReference ? props.dateStartReference : new Date, 6), 'dd/MM',{ locale: ptBR }))

        // Gerando Array de Eventos do dia de Hoje:
        let filtroHoje = props?.listaDeEventos?.filter((x) => {
            return x.dia === format(props.dateStartReference, "dd/MM/yyyy");
        });
        setListaHoje(filtroHoje)

        // Gerando Array de Eventos do dia de Hoje +1:
        let filtroHojeMaisUm = props?.listaDeEventos?.filter((x) => {
            return x.dia === format(addDays(props.dateStartReference, 1), "dd/MM/yyyy");
        });
        setListaHojeMaisUm(filtroHojeMaisUm)

        // Gerando Array de Eventos do dia de Hoje +2:
        let filtroHojeMaisDois = props?.listaDeEventos?.filter((x) => {
            return x.dia === format(addDays(props.dateStartReference, 2), "dd/MM/yyyy");
        });
        setListaHojeMaisDois(filtroHojeMaisDois)

        // Gerando Array de Eventos do dia de Hoje +3:
        let filtroHojeMaisTres = props?.listaDeEventos?.filter((x) => {
            return x.dia === format(addDays(props.dateStartReference, 3), "dd/MM/yyyy");
        });
        setListaHojeMaisTres(filtroHojeMaisTres)

        // Gerando Array de Eventos do dia de Hoje +4:
        let filtroHojeMaisQuatro = props?.listaDeEventos?.filter((x) => {
            return x.dia === format(addDays(props.dateStartReference, 4), "dd/MM/yyyy");
        });
        setListaHojeMaisQuatro(filtroHojeMaisQuatro)

        // Gerando Array de Eventos do dia de Hoje +5:
        let filtroHojeMaisCinco = props?.listaDeEventos?.filter((x) => {
            return x.dia === format(addDays(props.dateStartReference, 5), "dd/MM/yyyy");
        });
        setListaHojeMaisCinco(filtroHojeMaisCinco)

        // Gerando Array de Eventos do dia de Hoje +6:
        let filtroHojeMaisSeis = props?.listaDeEventos?.filter((x) => {
            return x.dia === format(addDays(props.dateStartReference, 6), "dd/MM/yyyy");
        });
        setListaHojeMaisSeis(filtroHojeMaisSeis)

    }, [props?.listaDeEventos, props?.dateStartReference])

    const itens = [
        { index: 0, hide: false, label: `${nomeDiaHoje}`, component: <AgendaCarouselTabsSegunda key={0} index={0} activeTab={activeTab} dateStartReference={props?.dateStartReference} eventListToday={listaHoje} loadEventos={props?.loadEventos} loading={props?.loading}/>, date: `${dateDiaHoje}`},
        { index: 1, hide: false, label: `${nomeDiaHojeMaisUm}`, component: <AgendaCarouselTabsTerca key={1} index={1} activeTab={activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisUm={listaHojeMaisUm} loadEventos={props?.loadEventos} loading={props?.loading}/>,  date: `${dateDiaHojeMaisUm}`},
        { index: 2, hide: false, label: `${nomeDiaHojeMaisDois}`, component: <AgendaCarouselTabsQuarta key={2} index={2} activeTab={activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisDois={listaHojeMaiDois} loadEventos={props?.loadEventos} loading={props?.loading}/>,  date: `${dateDiaHojeMaisDois}`},
        { index: 3, hide: false, label: `${nomeDiaHojeMaisTres}`, component: <AgendaCarouselTabsQuinta key={3} index={3} activeTab={activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisTres={listaHojeMaisTres} loadEventos={props?.loadEventos} loading={props?.loading}/>,  date: `${dateDiaHojeMaisTres}`},
        { index: 4, hide: false, label: `${nomeDiaHojeMaisQuatro}`, component: <AgendaCarouselTabsSexta key={4} index={4} activeTab={activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisQuatro={listaHojeMaisQuatro} loadEventos={props?.loadEventos} loading={props?.loading}/>,  date: `${dateDiaHojeMaisQuatro}`},
        { index: 5, hide: false, label: `${nomeDiaHojeMaisQuinto}`, component: <AgendaCarouselTabsSabado key={5} index={5} activeTab={activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisCinco={listaHojeMaisCinco} loadEventos={props?.loadEventos} loading={props?.loading}/>,  date: `${dateDiaHojeMaisQuinto}`},
        { index: 6, hide: false, label: `${nomeDiaHojeMaisSexto}`, component: <AgendaCarouselTabsDomingo key={6} index={6} activeTab={activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisSeis={listaHojeMaisSeis} loadEventos={props?.loadEventos} loading={props?.loading}/>,  date: `${dateDiaHojeMaisSexto}`},
    ];

    return (
        <CarouselCustomAgenda itens={itens} activeTab={activeTab} onChange={setActiveTab} loading={props?.loading}/>
    );
}