import { addDays, subYears } from 'date-fns';
import React from 'react';
import DashboardContextService from '../services/DashboardContextService';
//import { } from 'date'


interface IDashboardProviderProps { children: React.ReactNode; }
interface IDashboardContextResult {
  impacto_financeiro_positivo: number,
  impacto_financeiro_neutro: number,
  impacto_financeiro_negativo: number,

  prioridade_minima_qtd: number,
  prioridade_baixa_qtd: number,
  prioridade_media_qtd: number,
  prioridade_alta_qtd: number,
  prioridade_maxima_qtd: number,

  prioridade_minima_valor: number,
  prioridade_baixa_valor: number,
  prioridade_media_valor: number,
  prioridade_alta_valor: number,
  prioridade_maxima_valor: number,

  pendencias_minhas: number,
  pendencias_departamento: number,
  parlamentares: number,
  em_tramitacao: number,

  // universo_title_chart: string,
  // universo_inativosSubtitle_chart: string,
  universo_inativosValue_chart: number,
  // universo_ativosSubtitle_chart: string,
  universo_ativosValue_chart: number,

  casasLegislativas_title_chart: string,
  casasLegislativas_congressoNacionalSubtitle_chart: string,
  casasLegislativas_congressoNacionalValue_chart: number,
  casasLegislativas_camaraDosDeputadosSubtitle_chart: string,
  casasLegislativas_camaraDosDeputadosValue_chart: number,
  casasLegislativas_senadoFederalSubtitle_chart: string,
  casasLegislativas_senadoFederalValue_chart: number,

  acoesInfluenciaPendente: number,
  acoesInfluenciaExito: number,
  acoesInfluenciaSemExito: number,
  acoesInfluenciaExitoParcial: number,

  momentosCriticosdesignacaoRelator: number,
  momentosCriticosaguardanoParecerDoRelator: number,
  momentosCriticosprazoParaEmenda: number,
  momentosCriticosaguardaDeliberacao: number,

  posicionamentocontrarioParecerRelator: number,
  posicionamentocontrarioParecerRelatorEmenda: number,
  posicionamentofavoravelParecerRelatorEmenda: number,
  posicionamentofavoravelParecerRelator: number,
  posicionamentofavoravel: number,
  posicionamentofavoravelComEmenda: number,
  posicionamentocontrario: number,
  posicionamentocontrarioComEmenda: number,
  posicionamentopendente: number,
  posicionamentoneutro: number

  qualificacaoParlamentarconvergente: number
  qualificacaoParlamentardivergente: number
  qualificacaoParlamentarnaoClassificado: number
  qualificacaoParlamentarneutro: number

  dtIni: Date,
  dtFin: Date,
  filterKindList: string[],
  chartRenderFederal: string,

  setPeriod: (dtIni: Date, dtFin: Date) => void,

  sphereFilterFunction: (sphereList: string[]) => void,
}

const Context = React.createContext<IDashboardContextResult | undefined>(undefined);

const DashboardProvider = ({ children }: IDashboardProviderProps) => {

  React.useEffect(() => {
    setInterval(() => loadDashboardData(), 60000 * 5)
    // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [dtIni, setDtIni] = React.useState<Date>(subYears(new Date(), 5));
  const [dtFin, setDtFin] = React.useState<Date>(new Date());

  // const [sphereFilter, setSphereFilter] = React.useState(() => ['Federal']);
  const [filterKindList, setFilterKindList] = React.useState(() => ["Federal"]);
  const [chartRenderFederal, setChartRenderFederal] = React.useState(() => "Federal");

  const [impacto_financeiro_positivo, setImpacto_financeiro_positivo] = React.useState<number>(0);
  const [impacto_financeiro_neutro, setImpacto_financeiro_neutro] = React.useState<number>(0);
  const [impacto_financeiro_negativo, setImpacto_financeiro_negativo] = React.useState<number>(0);

  const [prioridade_minima_valor, setPrioridade_minima_valor] = React.useState<number>(0);
  const [prioridade_baixa_valor, setPrioridade_baixa_valor] = React.useState<number>(0);
  const [prioridade_media_valor, setPrioridade_media_valor] = React.useState<number>(0);
  const [prioridade_alta_valor, setPrioridade_alta_valor] = React.useState<number>(0);
  const [prioridade_maxima_valor, setPrioridade_maxima_valor] = React.useState<number>(0);

  const [prioridade_minima_qtd, setPrioridade_minima_qtd] = React.useState<number>(0);
  const [prioridade_baixa_qtd, setPrioridade_baixa_qtd] = React.useState<number>(0);
  const [prioridade_media_qtd, setPrioridade_media_qtd] = React.useState<number>(0);
  const [prioridade_alta_qtd, setPrioridade_alta_qtd] = React.useState<number>(0);
  const [prioridade_maxima_qtd, setPrioridade_maxima_qtd] = React.useState<number>(0);

  const [pendencias_minhas, setPendencias_minhas] = React.useState<number>(0);
  const [pendencias_departamento, setPendencias_departamento] = React.useState<number>(0);

  const [parlamentares, setParlamentares] = React.useState<number>(0);

  const [em_tramitacao, setEm_tramitacao] = React.useState<number>(0);

  // const [universo_title_chart, setUniverso_title_chart] = React.useState<string>("UNIVERSO");
  // const [universo_inativosSubtitle_chart, setUniverso_inativosSubtitle_chart] = React.useState<string>("Inativos");
  const [universo_inativosValue_chart, setUniverso_inativosValue_chart] = React.useState<number>(0);
  // const [universo_ativosSubtitle_chart, setUniverso_ativosSubtitle_chart] = React.useState<string>("Ativos");
  const [universo_ativosValue_chart, setUniverso_ativosValue_chart] = React.useState<number>(0);

  const [casasLegislativas_title_chart, setCasasLegislativas_title_chart] = React.useState<string>("CASAS LEGISLATIVAS");
  const [casasLegislativas_congressoNacionalSubtitle_chart, setCasasLegislativas_congressoNacionalSubtitle_chart] = React.useState<string>("Congresso Nacional");
  const [casasLegislativas_congressoNacionalValue_chart, setCasasLegislativas_congressoNacionalValue_chart] = React.useState<number>(0);
  const [casasLegislativas_camaraDosDeputadosSubtitle_chart, setCasasLegislativas_camaraDosDeputadosSubtitle_chart] = React.useState<string>("Câmara dos Deputados");
  const [casasLegislativas_camaraDosDeputadosValue_chart, setCasasLegislativas_camaraDosDeputadosValue_chart] = React.useState<number>(0);
  const [casasLegislativas_senadoFederalSubtitle_chart, setCasasLegislativas_senadoFederalSubtitle_chart] = React.useState<string>("Senado Federal");
  const [casasLegislativas_senadoFederalValue_chart, setCasasLegislativas_senadoFederalValue_chart] = React.useState<number>(0);

  const [acoesInfluenciaPendente, setAcoesInfluenciaPendente] = React.useState<number>(0);
  const [acoesInfluenciaExito, setAcoesInfluenciaExito] = React.useState<number>(0);
  const [acoesInfluenciaSemExito, setAcoesInfluenciaSemExito] = React.useState<number>(0);
  const [acoesInfluenciaExitoParcial, setAcoesInfluenciaExitoParcial] = React.useState<number>(0);

  const [momentosCriticosdesignacaoRelator, setmomentosCriticosdesignacaoRelator] = React.useState<number>(0);
  const [momentosCriticosaguardanoParecerDoRelator, setmomentosCriticosaguardanoParecerDoRelator] = React.useState<number>(0);
  const [momentosCriticosprazoParaEmenda, setmomentosCriticosprazoParaEmenda] = React.useState<number>(0);
  const [momentosCriticosaguardaDeliberacao, setmomentosCriticosaguardaDeliberacao] = React.useState<number>(0);

  const [posicionamentocontrarioParecerRelator, setposicionamentocontrarioParecerRelator] = React.useState<number>(0);
  const [posicionamentocontrarioParecerRelatorEmenda, setposicionamentocontrarioParecerRelatorEmenda] = React.useState<number>(0);
  const [posicionamentofavoravelParecerRelatorEmenda , setposicionamentofavoravelParecerRelatorEmenda] = React.useState<number>(0);
  const [posicionamentofavoravelParecerRelator, setposicionamentofavoravelParecerRelator] = React.useState<number>(0);
  const [posicionamentofavoravel, setposicionamentofavoravel] = React.useState<number>(0);
  const [posicionamentofavoravelComEmenda, setposicionamentofavoravelComEmenda] = React.useState<number>(0);
  const [posicionamentocontrario, setposicionamentocontrario] = React.useState<number>(0);
  const [posicionamentocontrarioComEmenda, setposicionamentocontrarioComEmenda] = React.useState<number>(0);
  const [posicionamentopendente, setposicionamentopendente] = React.useState<number>(0);
  const [posicionamentoneutro, setposicionamentoneutro] = React.useState<number>(0);

  const [qualificacaoParlamentarconvergente, setqualificacaoParlamentarconvergente] = React.useState<number>(0);
  const [qualificacaoParlamentardivergente, setqualificacaoParlamentardivergente] = React.useState<number>(0);
  const [qualificacaoParlamentarnaoClassificado, setqualificacaoParlamentarnaoClassificado] = React.useState<number>(0);
  const [qualificacaoParlamentarneutro, setqualificacaoParlamentarneutro] = React.useState<number>(0);


  const setPeriod = (dtIni: Date, dtFin: Date) => {
    setDtIni(dtIni);
    setDtFin(dtFin);
    loadDashboardData();
  }

  const sphereFilterFunction = (sphereList: string[]) => {
    setFilterKindList(sphereList);
    const Federal = sphereList.find(item => item === 'Federal');
    if (Federal) setChartRenderFederal(Federal)
    loadDashboardData();
  }

  const loadDashboardData = () => {
    if (loading || !dtIni || !dtFin) return;
    setLoading(true);

    DashboardContextService.execute(dtIni, addDays(dtFin, 1)).then(result => {
      if (result) {

        if (result.impacto_financeiro.positivo !== impacto_financeiro_positivo) setImpacto_financeiro_positivo(result.impacto_financeiro.positivo);
        if (result.impacto_financeiro.neutro !== impacto_financeiro_neutro) setImpacto_financeiro_neutro(result.impacto_financeiro.neutro);
        if (result.impacto_financeiro.negativo !== impacto_financeiro_negativo) setImpacto_financeiro_negativo(result.impacto_financeiro.negativo);

        if (result.prioridade.minima.valor !== prioridade_minima_valor) setPrioridade_minima_valor(result.prioridade.minima.valor);
        if (result.prioridade.baixa.valor !== prioridade_baixa_valor) setPrioridade_baixa_valor(result.prioridade.baixa.valor);
        if (result.prioridade.media.valor !== prioridade_media_valor) setPrioridade_media_valor(result.prioridade.media.valor);
        if (result.prioridade.alta.valor !== prioridade_alta_valor) setPrioridade_alta_valor(result.prioridade.alta.valor);
        if (result.prioridade.maxima.valor !== prioridade_maxima_valor) setPrioridade_maxima_valor(result.prioridade.maxima.valor);

        if (result.prioridade.minima.qtd !== prioridade_minima_qtd) setPrioridade_minima_qtd(result.prioridade.minima.qtd);
        if (result.prioridade.baixa.qtd !== prioridade_baixa_qtd) setPrioridade_baixa_qtd(result.prioridade.baixa.qtd);
        if (result.prioridade.media.qtd !== prioridade_media_qtd) setPrioridade_media_qtd(result.prioridade.media.qtd);
        if (result.prioridade.alta.qtd !== prioridade_alta_qtd) setPrioridade_alta_qtd(result.prioridade.alta.qtd);
        if (result.prioridade.maxima.qtd !== prioridade_maxima_qtd) setPrioridade_maxima_qtd(result.prioridade.maxima.qtd);

        if (result.pendencias.minhas !== pendencias_minhas) setPendencias_minhas(result.pendencias.minhas);
        if (result.pendencias.departamento !== pendencias_departamento) setPendencias_departamento(result.pendencias.departamento);

        if (result.parlamentaresSeguidas !== parlamentares) setParlamentares(result.parlamentaresSeguidas);

        if (result.proposicoesTramitacao !== em_tramitacao) setEm_tramitacao(result.proposicoesTramitacao);

        // if (result.universoChart.universo_title_chart !== universo_title_chart) setUniverso_title_chart(result.universoChart.universo_title_chart);
        // if (result.universoChart.universo_inativosSubtitle_chart !== universo_inativosSubtitle_chart) setUniverso_inativosSubtitle_chart(result.universoChart.universo_inativosSubtitle_chart);
        if (result.universo.inativas !== universo_inativosValue_chart) setUniverso_inativosValue_chart(result.universo.inativas);
        // if (result.universoChart.universo_ativosSubtitle_chart !== universo_ativosSubtitle_chart) setUniverso_ativosSubtitle_chart(result.universoChart.universo_ativosSubtitle_chart);
        if (result.universo.ativas !== universo_ativosValue_chart) setUniverso_ativosValue_chart(result.universo.ativas);

        // if (result.casasLegislativas.casasLegislativas_title_chart !== casasLegislativas_title_chart) setCasasLegislativas_title_chart(result.casasLegislativas.casasLegislativas_title_chart);
        // if (result.casasLegislativas.casasLegislativas_congressoNacionalSubtitle_chart !== casasLegislativas_congressoNacionalSubtitle_chart) setCasasLegislativas_congressoNacionalSubtitle_chart(result.casasLegislativas.casasLegislativas_congressoNacionalSubtitle_chart);
        if (result.casas.proposicoesCongresso !== casasLegislativas_congressoNacionalValue_chart) setCasasLegislativas_congressoNacionalValue_chart(result.casas.proposicoesCongresso);
        // if (result.casasLegislativas.casasLegislativas_camaraDosDeputadosSubtitle_chart !== casasLegislativas_camaraDosDeputadosSubtitle_chart) setCasasLegislativas_camaraDosDeputadosSubtitle_chart(result.casasLegislativas.casasLegislativas_camaraDosDeputadosSubtitle_chart);
        if (result.casas.proposicoesCamara !== casasLegislativas_camaraDosDeputadosValue_chart) setCasasLegislativas_camaraDosDeputadosValue_chart(result.casas.proposicoesCamara);
        // if (result.casasLegislativas.casasLegislativas_senadoFederalSubtitle_chart !== casasLegislativas_senadoFederalSubtitle_chart) setCasasLegislativas_senadoFederalSubtitle_chart(result.casasLegislativas.casasLegislativas_senadoFederalSubtitle_chart);
        if (result.casas.proposicoesSenado !== casasLegislativas_senadoFederalValue_chart) setCasasLegislativas_senadoFederalValue_chart(result.casas.proposicoesSenado);


        if (result.acoesInfluencia.pendente !== acoesInfluenciaPendente) setAcoesInfluenciaPendente(result.acoesInfluencia.pendente);
        if (result.acoesInfluencia.exito !== acoesInfluenciaExito) setAcoesInfluenciaExito(result.acoesInfluencia.exito);
        if (result.acoesInfluencia.semExito !== acoesInfluenciaSemExito) setAcoesInfluenciaSemExito(result.acoesInfluencia.semExito);
        if (result.acoesInfluencia.exitoParcial !== acoesInfluenciaExitoParcial) setAcoesInfluenciaExitoParcial(result.acoesInfluencia.exitoParcial);

        if (result.momentosCriticos.designacaoRelator !== momentosCriticosdesignacaoRelator) setmomentosCriticosdesignacaoRelator(result.momentosCriticos.designacaoRelator)
        if (result.momentosCriticos.aguardanoParecerDoRelator !== momentosCriticosaguardanoParecerDoRelator) setmomentosCriticosaguardanoParecerDoRelator(result.momentosCriticos.aguardanoParecerDoRelator)
        if (result.momentosCriticos.prazoParaEmenda !== momentosCriticosprazoParaEmenda) setmomentosCriticosprazoParaEmenda(result.momentosCriticos.prazoParaEmenda)
        if (result.momentosCriticos.aguardaDeliberacao !== momentosCriticosaguardaDeliberacao) setmomentosCriticosaguardaDeliberacao(result.momentosCriticos.aguardaDeliberacao)

        if (result.posicionamento.contrarioParecerRelator !== posicionamentocontrarioParecerRelator ) setposicionamentocontrarioParecerRelator (result.posicionamento.contrarioParecerRelator)
        if (result.posicionamento.favoravelParecerRelatorEmenda !== posicionamentofavoravelParecerRelatorEmenda ) setposicionamentofavoravelParecerRelatorEmenda(result.posicionamento.favoravelParecerRelatorEmenda)
        if (result.posicionamento.favoravelParecerRelator !== posicionamentofavoravelParecerRelator ) setposicionamentofavoravelParecerRelator(result.posicionamento.favoravelParecerRelator)
        if (result.posicionamento.favoravel !== posicionamentofavoravel) setposicionamentofavoravel(result.posicionamento.favoravel)
        if (result.posicionamento.favoravelComEmenda !== posicionamentofavoravelComEmenda) setposicionamentofavoravelComEmenda(result.posicionamento.favoravelComEmenda)
        if (result.posicionamento.contrario !== posicionamentocontrario) setposicionamentocontrario(result.posicionamento.contrario)
        if (result.posicionamento.contrarioComEmenda !== posicionamentocontrarioComEmenda) setposicionamentocontrarioComEmenda(result.posicionamento.contrarioComEmenda)
        if (result.posicionamento.pendente !== posicionamentopendente) setposicionamentopendente(result.posicionamento.pendente)
        if (result.posicionamento.neutro !== posicionamentoneutro) setposicionamentoneutro(result.posicionamento.neutro)

        if (result.qualificacaoParlamentar.convergente !== qualificacaoParlamentarconvergente) setqualificacaoParlamentarconvergente(result.qualificacaoParlamentar.convergente)
        if (result.qualificacaoParlamentar.divergente !== qualificacaoParlamentardivergente) setqualificacaoParlamentardivergente(result.qualificacaoParlamentar.divergente)
        if (result.qualificacaoParlamentar.naoClassificado !== qualificacaoParlamentarnaoClassificado) setqualificacaoParlamentarnaoClassificado(result.qualificacaoParlamentar.naoClassificado)
        if (result.qualificacaoParlamentar.neutro !== qualificacaoParlamentarneutro) setqualificacaoParlamentarneutro(result.qualificacaoParlamentar.neutro)



      }
      setLoading(false);
    })
      .catch(() => setLoading(false))
  }

  return (
    <Context.Provider value={{
      impacto_financeiro_positivo,
      impacto_financeiro_neutro,
      impacto_financeiro_negativo,

      prioridade_minima_valor,
      prioridade_baixa_valor,
      prioridade_media_valor,
      prioridade_alta_valor,
      prioridade_maxima_valor,

      prioridade_minima_qtd,
      prioridade_baixa_qtd,
      prioridade_media_qtd,
      prioridade_alta_qtd,
      prioridade_maxima_qtd,

      pendencias_minhas,
      pendencias_departamento,
      parlamentares,
      em_tramitacao,

      // universo_title_chart,
      // universo_inativosSubtitle_chart,
      universo_inativosValue_chart,
      // universo_ativosSubtitle_chart,
      universo_ativosValue_chart,

      casasLegislativas_title_chart,
      casasLegislativas_congressoNacionalSubtitle_chart,
      casasLegislativas_congressoNacionalValue_chart,
      casasLegislativas_camaraDosDeputadosSubtitle_chart,
      casasLegislativas_camaraDosDeputadosValue_chart,
      casasLegislativas_senadoFederalSubtitle_chart,
      casasLegislativas_senadoFederalValue_chart,

      acoesInfluenciaPendente,
      acoesInfluenciaExito,
      acoesInfluenciaSemExito,
      acoesInfluenciaExitoParcial,

      momentosCriticosdesignacaoRelator,
      momentosCriticosaguardanoParecerDoRelator,
      momentosCriticosprazoParaEmenda,
      momentosCriticosaguardaDeliberacao,

      posicionamentocontrarioParecerRelator,
      posicionamentocontrarioParecerRelatorEmenda,
      posicionamentofavoravelParecerRelatorEmenda,
      posicionamentofavoravelParecerRelator,
      posicionamentofavoravel,
      posicionamentofavoravelComEmenda,
      posicionamentocontrario,
      posicionamentocontrarioComEmenda,
      posicionamentopendente,
      posicionamentoneutro,

      qualificacaoParlamentarconvergente,
      qualificacaoParlamentardivergente,
      qualificacaoParlamentarnaoClassificado,
      qualificacaoParlamentarneutro,

      dtIni,
      dtFin,

      filterKindList,
      chartRenderFederal,

      setPeriod,

      sphereFilterFunction
    }} >
      {children}
    </Context.Provider >
  );

}

export { DashboardProvider };
export const useDashboard = () => React.useContext(Context);