import React, { useState, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { formatDate } from "../../../../utils/dateUtils";
import { Divider } from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import { MoreHoriz } from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ComentarioModal from "./ComentarioModal";
import { ILoggedUser } from "../../../../models/User.Model";
import { IComentario } from "../../../../models/Proposicao.Model";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import ChatIcon from "@mui/icons-material/Chat";
import useStyles from "../../../../components/layout/Styles";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import ButtonDialogConfirmation from "../../../../components/ButtonDialogConfirmation";
import ComentarioService from "../../../../services/ComentarioService";
import { useAlert } from "../../../../contexts/AlertContext";
import ContentEditable from "react-contenteditable";

export default function RespostaComentario(props: {
  acao: IAcaoPreparatoria;
  comentario: IComentario;
  listaUsuario: ILoggedUser[];
  reloadLista: () => void;
  responderComentario: (usuario: { id: number; nome: string }) => void;
}) {
  const { pallet } = usePallet();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState();

  const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
    setOpen(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(undefined);
  };

  const { NewAlert } = useAlert();

  const regexMencoes = /@@@([0-9]+)@@@/g;

  const trocarMencionados = (comentario) =>
    comentario.replace(regexMencoes, (...match) => {
      const id = parseInt(match[1]);

      const usuario = props.listaUsuario?.filter((item) => item.id === id)[0];

      const nome = usuario?.nome ? usuario?.nome : "Usuário não identificado";

      return `<b data-id="${id}">${nome}</b>`;
    });

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      minWidth: 135,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "5px 0px",
        minWidth: 140,
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          // fontSize: 18,
        },
      },
    },
  }));

  const deletarComentario = (idComentario: number) => {
    ComentarioService.Delete(String(idComentario))
      .then((res) => {
        if (res) {
          NewAlert("success", "Resposta apagada com sucesso");
          handleClose();
        } else {
          NewAlert("error", "Tente novamente");
        }
      })
      .then((x) => props.reloadLista());
  };

  return (
    <>
      <Grid
        style={{
          width: "100%",
          display: "flex",
          padding: `${isMobile ? "5px 5px 0px 5px" : "20px 20px 0px 20px"}`,
        }}
        item
      >
        <Grid style={{ width: "43px", flexShrink: 0, marginRight: "3px" }} item>
          <Avatar src={props.comentario.foto_autor} />
        </Grid>
        <Grid
          container
          style={{
            padding: "10px",
            backgroundColor: "white",
            border: "1px solid lightgrey",
            borderRadius: "3px",
          }}
          item
        >
          <Grid container>
            <Grid item style={{ fontWeight: pallet.general.weight_600 }}>
              {props.comentario.autor}
            </Grid>
            <Grid style={{ marginLeft: "auto" }} item>
              <Button
                id={`button-1`}
                aria-controls={anchorEl ? `mmenu-1` : undefined}
                disableElevation
                variant="contained"
                size="medium"
                style={{
                  backgroundColor: pallet.backgroundColorTertiary,
                  fontFamily: `${pallet.fontFamily.general}`,
                  fontWeight: pallet.fontWeight.lighter,
                  fontSize: `${pallet.fontSize.titleWidgets}`,
                  color: `${pallet.general.color4}`,
                  borderRadius: "6px",
                  width: "25px",
                  height: "20px",
                  zIndex: 10,
                }}
                onClick={(e) => handleClick(e, 1)}
                endIcon={
                  <Grid
                    style={{
                      fontSize: "30px",
                      marginTop: "9px",
                      marginRight: "10px",
                    }}
                  >
                    <MoreHoriz fontSize="inherit" />
                  </Grid>
                }
              ></Button>
            </Grid>
            <StyledMenu
              style={{
                border: "1px solid lightgrey",
                borderRadius: "6px",
              }}
              id={`mmenu-${1}`}
              MenuListProps={{
                "aria-labelledby": `button-${1}`,
              }}
              anchorEl={anchorEl}
              open={open === 1}
              onClose={handleClose}
            >
              <ComentarioModal
                reloadLista={() => props.reloadLista()}
                acao={props.acao}
                listaUsuario={props.listaUsuario}
                comentario={props.comentario}
                resposta={true}
              >
                <MenuItem
                  style={{
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    padding: "7px",
                    textTransform: "none",
                  }}
                >
                  <EditIcon
                    style={{
                      color: `${pallet.color.tertiary.font}`,
                      fontFamily: `${pallet.fontFamily.general}`,
                      marginRight: "16px",
                      // width: "50px"
                    }}
                  />
                  Editar
                </MenuItem>
              </ComentarioModal>

              <Grid>
                <Divider
                  variant="fullWidth"
                  style={{
                    paddingTop: `${isMobile ? "2px" : "5px"}`,
                    marginBottom: `${isMobile ? "2px" : "5px"}`,
                  }}
                />
              </Grid>

              <ButtonDialogConfirmation
                styles={{
                  fontFamily: `${pallet.fontFamily.general}`,
                  fontWeight: pallet.fontWeight.button,
                  fontSize: `${pallet.general.size14}`,
                  color: `${pallet.color.tertiary.font}`,
                  borderRadius: "6px 6px 0px 0px",
                  textTransform: "capitalize",
                }}
                title={"CONFIRMAÇÃO"}
                title_button={""}
                confirmation_button={"Apagar"}
                content={"APAGAR RESPOSTA?"}
                message={"Esta ação não poderá ser desfeita."}
                confirmationEvent={() =>
                  deletarComentario(Number(props.comentario.id))
                }
              >
                <MenuItem
                  style={{
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    padding: "1px",
                  }}
                >
                  <DeleteIcon
                    style={{
                      color: `${pallet.color.tertiary.font}`,
                      marginRight: "16px",
                      fontFamily: `${pallet.fontFamily.general}`,
                    }}
                  />
                  Deletar
                </MenuItem>
              </ButtonDialogConfirmation>
            </StyledMenu>
          </Grid>
          <Grid item lg={12} xs={12} style={{ color: pallet.general.color4 }}>
            {" "}
            {formatDate(new Date(props.comentario.feito_em), "dd/MM/yyyy")}
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
            style={{ marginTop: "15px", wordBreak: "break-word" }}
          >
            <div 
              dangerouslySetInnerHTML={{
                __html: trocarMencionados(props.comentario.comentario),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ marginLeft: `${isMobile ? "51px" : "66px"}` }}>
        <Button
          onClick={() =>
            props.responderComentario({
              id: Number(props.comentario.id_usuario),
              nome: props.comentario.autor,
            })
          }
          style={{
            textTransform: "none",
            padding: "0px",
            fontFamily: pallet.fontFamily.general,
            fontWeight: pallet.general.weight_600,
            color: pallet.general.color4,
          }}
        >
          Responder
        </Button>
      </Grid>
    </>
  );
}
