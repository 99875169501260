import React from 'react';
import CarouselCustom from '../../../components/carousel/CarouselCustom';
import IACarouselTabsTemasRelacionados from './IACarouselTabsTemasRelacionados';
import IACarouselTabsTreinamento from './IACarouselTabsTreinamento';

export default function IACarouselTabs() {
    const [activeTab, setActiveTab] = React.useState(0);
    const itens = [
        // { index: 0, hide: false, label: "Treinamento", component: <div /> },
        { index: 0, hide: false, label: "Treinamento", component: <IACarouselTabsTreinamento key={0} index={0} activeTab={activeTab} /> },
        // { index: 1, hide: false, label: "Temas Relacionados", component: <IACarouselTabsTemasRelacionados key={1} index={1} activeTab={activeTab} /> },
    ]
    return (
        <CarouselCustom itens={itens} activeTab={activeTab} onChange={setActiveTab} />
    );
}