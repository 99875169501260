import PrivateRoute from '../../components/PrivateRoute';
import AgendaRenderPDFJornal from '../agenda/_components/AgendaRenderPDFJornal';
import Relatorios from './Relatorios';
import RelatorioGeral from './Relatorio_Geral';
import RelatorioProposicoesPeloPosicionamento from './_components/Proposicao/RelatorioProposicoesPeloPosicionamento';
import RelatorioProposicoesPorAtivoInativo from './_components/Proposicao/RelatorioProposicoesPorAtivoInativo';
import RelatorioProposicoesPorCasaLegislativa from './_components/Proposicao/RelatorioProposicoesPorCasaLegislativa';
import RelatorioProposicoesPorNivelPrioridade from './_components/Proposicao/RelatorioProposicoesPorNivelPrioridade';
import RelatorioAcoesDeInfluenciaPorResultado from './_components/Proposicao/RelatorioAcoesDeInfluenciaPorResultado';
import RelatorioAcoesRealizadasPorPeriodo from './_components/Proposicao/RelatorioAcoesRealizadasPorPeriodo';
import RelatorioTeste from './_components/RelatorioTeste';
import RelatorioProposicoesDivididasPorTemasLegislativos from './_components/Proposicao/RelatorioProposicoesDivididasPorTemasLegislativos';
import RelatorioAcoesDeInfluenciaCriadasPorFulano from './_components/Proposicao/RelatorioAcoesDeInfluenciaPorFulano';
import RelatorioManifestacaoAcoesPorDepartamento from './_components/Proposicao/RelatorioManifestacaoAcoesPorDepartamento';
import RelatorioAcoesDeInfluenciaRealizadasPorDepartamento from './_components/Proposicao/RelatorioAcoesDeInfluenciaRealizadasPorDepartamento';
import RelatorioProposicoesApresentadasPeriodo from './_components/Proposicao/RelatorioProposicoesApresentasPeriodo';
import RelatorioInterlocutorQualificacao from './_components/Interlocutor/RelatorioInterlocutorQualificacao';
import RelatorioProposicoesMovimentacaoPosicionamento from './_components/Proposicao/RelatorioProposicoesMovimentacaoPosicionamento';
import RelatorioProposicoesDivididasPorTemasCorporativos from './_components/Proposicao/RelatorioProposicoesDivididasPorTemasCorporativos';
import RelatorioInterlocutorQualificacaoPartido from './_components/Interlocutor/RelatorioInterlocutorQualificacaoPartido';
import RelatorioInterlocutorProfissao from './_components/Interlocutor/RelatorioInterlocutorProfissao';
import RelatorioInterlocutorPorCasaLegislativa from './_components/Interlocutor/RelatorioInterlocutorPorCasaLegislativa';
import RelatorioInterlocutorPorOrgaoTecnico from './_components/Interlocutor/RelatorioInterlocutorPorOrgaoTecnico';
import RelatorioInterlocutorAcoesDeInfluencia from './_components/Interlocutor/RelatorioInterlocutorAcoesDeInfluencia';
import RelatorioProposicoesPorCasaLegislativaEstadual from './_components/Proposicao/RelatorioProposicoesPorCasaLegislativaEstadual';
import RelatorioInterlocutorPorCasaLegislativaEstadual from './_components/Interlocutor/RelatorioInterlocutorPorCasaLegislativaEstadual';
import RelatorioProposicoesPorCasaLegislativaMunicipal from './_components/Proposicao/RelatorioProposicoesPorCasaLegislativaMunicipal';
import RelatorioInterlocutorPorCasaLegislativaMunicipal from './_components/Interlocutor/RelatorioInterlocutorPorCasaLegislativaMunicipal';

export function RelatoriosRoutes(): JSX.Element {
    return (
        <>
            <PrivateRoute path="/relatorios" element={<Relatorios />} />
            <PrivateRoute path="/relatorios/geral" element={<RelatorioGeral />} />
            {//////////////////////////////
                //FEDERAL e ESTADUAL
            }
            {////////////////////////////////
                //PROPOSICOES
            }
            <PrivateRoute path="/relatorio/proposicao/casas/:dtIni/:dtFin" element={<RelatorioProposicoesPorCasaLegislativa />} />
            <PrivateRoute path="/relatorio/proposicao/prioridades/:dtIni/:dtFin" element={<RelatorioProposicoesPorNivelPrioridade />} />
            <PrivateRoute path="/relatorio/proposicao/posicionamento/:dtIni/:dtFin" element={<RelatorioProposicoesPeloPosicionamento />} />
            <PrivateRoute path="/relatorio/proposicao/atividade/:dtIni/:dtFin" element={<RelatorioProposicoesPorAtivoInativo />} />
            <PrivateRoute path="/relatorio/proposicao/acaoinfluencia/resultado/:dtIni/:dtFin" element={<RelatorioAcoesDeInfluenciaPorResultado />} />
            <PrivateRoute path="/relatorio/proposicao/acoesporperiodo/:dtIni/:dtFin" element={<RelatorioAcoesRealizadasPorPeriodo />} />
            {/* <PrivateRoute path="/relatorio/proposicao/propsportemaslegislativos/:dtIni/:dtFin" element={<RelatorioProposicoesDivididasPorTemasLegislativos />} /> */}
            <PrivateRoute path="/relatorio/proposicao/acaoinfluencia/resultadoporfulano/:dtIni/:dtFin" element={<RelatorioAcoesDeInfluenciaCriadasPorFulano />} />
            <PrivateRoute path="/relatorio/proposicao/manifestacoesporunidade_de_negocio/:dtIni/:dtFin" element={<RelatorioManifestacaoAcoesPorDepartamento />} />
            <PrivateRoute path="/relatorio/proposicao/acaoinfluencia/unidade_de_negocio/:dtIni/:dtFin" element={<RelatorioAcoesDeInfluenciaRealizadasPorDepartamento />} />
            <PrivateRoute path="/relatorio/proposicao/dividida/tema/legislativo/:dtIni/:dtFin" element={<RelatorioProposicoesDivididasPorTemasLegislativos />} />
            <PrivateRoute path="/relatorio/proposicao/apresentadas/periodo/:dtIni/:dtFin" element={<RelatorioProposicoesApresentadasPeriodo />} />
            <PrivateRoute path="/relatorio/proposicao/movimentacao/posicionamento/:dtIni/:dtFin" element={<RelatorioProposicoesMovimentacaoPosicionamento />} />
            <PrivateRoute path="/relatorio/proposicao/dividida/tema/corporativo/:dtIni/:dtFin" element={<RelatorioProposicoesDivididasPorTemasCorporativos />} />
            {//////////////////////////////
                //INTERLOCUTOR - Federal e Estadual (Mesmo Front)
            }
            <PrivateRoute path="/relatorio/interlocutor/qualificacao/:dtIni/:dtFin" element={<RelatorioInterlocutorQualificacao />} />
            <PrivateRoute path="/relatorio/interlocutor/partido/:dtIni/:dtFin" element={<RelatorioInterlocutorQualificacaoPartido />} />
            <PrivateRoute path="/relatorio/interlocutor/profissao/:dtIni/:dtFin" element={<RelatorioInterlocutorProfissao />} />
            <PrivateRoute path="/relatorio/interlocutor/casas/:dtIni/:dtFin" element={<RelatorioInterlocutorPorCasaLegislativa />} />
            <PrivateRoute path="/relatorio/interlocutor/orgaotecnico/:dtIni/:dtFin" element={<RelatorioInterlocutorPorOrgaoTecnico />} />
            <PrivateRoute path="/relatorio/interlocutor/acaoinfluencia/:dtIni/:dtFin" element={<RelatorioInterlocutorAcoesDeInfluencia />} />
            {//////////////////////////////
                //SOMENTE ESTADUAL
            }
            <PrivateRoute path="/relatorio/interlocutor/casas/estadual/:dtIni/:dtFin" element={<RelatorioInterlocutorPorCasaLegislativaEstadual/>} />
            {////////////////////////////////
                //PROPOSICOES
            }
            <PrivateRoute path="/relatorio/proposicao/casas/estadual/:dtIni/:dtFin" element={<RelatorioProposicoesPorCasaLegislativaEstadual />} />
            {//////////////////////////////
                //SOMENTE MUNICIPAL
            }
            <PrivateRoute path="/relatorio/interlocutor/casas/estadual/:dtIni/:dtFin" element={<RelatorioInterlocutorPorCasaLegislativaEstadual/>} />
            {////////////////////////////////
                //PROPOSICOES
            }
            <PrivateRoute path="/relatorio/proposicao/casas/municipal/:dtIni/:dtFin" element={<RelatorioProposicoesPorCasaLegislativaMunicipal/>} />
            {//////////////////////////////
                //SOMENTE MUNICIPAL
            }
            <PrivateRoute path="/relatorio/interlocutor/casas/municipal/:dtIni/:dtFin" element={<RelatorioInterlocutorPorCasaLegislativaMunicipal/>} />
            
        </>
    );
}