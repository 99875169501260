import * as React from "react";

import { Grid, Link, TextField, Typography, Button, InputAdornment, Dialog, DialogTitle, IconButton } from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useAlert } from "../../../../contexts/AlertContext";
import useStyles from "../../../../components/layout/Styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import { CalendarToday } from "@material-ui/icons";
import EditIcon from '@mui/icons-material/Edit';
import InputMultilineTextField from "../../../../components/InputMultilineTextField";
import InputTextField from "../../../../components/InputTextField";
import { ICheckBoxNestedItem, InputDropDownCheckBoxNested, } from "../../../../components/CheckBoxNested";
import SolidButton from "../../../../components/SolidButton";
import styled from "styled-components";
import UsuarioService from "../../../../services/UsuarioService";
import { arraysEquals } from "../../../../utils/array.utils";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import { formatDate } from "../../../../utils/dateUtils";
import ButtonIconLeft from "../../../../components/ButtonIconLeft";
import { BootstrapDialog, BootstrapDialogTitle, } from "../../../../components/BootstrapDialogTitle";
import { INotaTecnica } from "../../../../models/Proposicao.Model";
import FeedIcon from "@mui/icons-material/Feed";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ButtonDialogConfirmation from "../../../../components/ButtonDialogConfirmation";
import DeadLineDate from "../../../../components/DeadLineDate";
import ProposicaoAcaoPreparatoriaService from "../../../../services/ProposicaoAcaoPreparatoriaService";
import ProposicaoAcaoPreparatoriaEdicaoSchema from '../../models/ProposicaoAcaoPreparatoriaEdicaoSchema';
import { useAuthorization } from "../../../../contexts/AuthorizationContext";


export default function ProposicaoAcaoPreparatoriaModalEdicao(props: {
    onChange?: () => (
        id: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    closeMenu?: () => void,
    reloadList?: () => void,
    children?: React.ReactNode,
    acao_preparatoria?: IAcaoPreparatoria,
    responsaveis?: Array<number>,
    editLayout?: boolean,
    id?: number,
}) {

    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    const [data, setData] = useState({});
    const [idReferencia, setIdReferencia] = React.useState<number>();
    const [titleDialog, setTitleDialog] = useState<string>("QUALIFICAR NOTA TÉCNICA");
    const [objetivo, setObjetivo] = useState<string>();
    const [prazo, setPrazo] = useState(Date);
    const [autor, setAutor] = useState<string>();
    const [responsavel, setResponsavel] = useState<string>();
    const [responsaveisList, setResponsaveisList] = useState([{ id: "", label: "", value: "" }]);
    const [responsaveisEsforco, setResponsaveisEsforco] = useState([]);
    const [departamento, setDepartamento] = useState<Array<ICheckBoxNestedItem>>();
    const [userList, setUserList] = useState<Array<number>>([]);
    const [lockSaveButton, setLockSaveButton] = useState<boolean>(true);
    const [status, setStatus] = useState<string>();
    const [lockedUserList, setLockedUserList] = useState<Array<number>>([]);
    const [open, setOpen] = React.useState(false);
    const [newUserList, setNewUserList] = useState<Array<number>>([]);
    const [responsaveisNotaTec, setresponsaveisNotaTec] = useState<Array<any>>([]);
    const [error, setError] = useState<Array<{ name: string, err: string }>>([])

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    const { user } = useAuthorization();
    useEffect(() => {
        SaveValidation();
    }, [prazo, responsavel, userList, objetivo]);


    function SaveValidation() {
        if (prazo && responsavel && (userList.length > 0) && objetivo) {
            setLockSaveButton(false)

        } else setLockSaveButton(true)
    }

    React.useEffect(() => {
        if (open) {
            UsuarioService.GetByDpto().then(async (res) => {
                const comboItens = res.map((departamento) => {
                    return {
                        id: departamento.id,
                        label: departamento.nome,
                        items: departamento.usuarios.map((usuario) => {
                            return {
                                id: usuario.id,
                                label: usuario.nome,
                                value: false,
                            };
                        }),
                    };
                });
                setDepartamento(comboItens);

            });

            UsuarioService.GetList().then(async res => {
                const responsaveis = res.map(usuario => {
                    return {
                        id: usuario.id,
                        label: usuario.nome,
                        value: usuario.id,
                    }
                })
                setResponsaveisList(responsaveis);
                setTimeout(() => {

                    setUserList(props?.acao_preparatoria?.responsaveisNotaTec.map(item => {
                        return item.nota_tecnica_id_responsavel;
                    }))
                    setLockedUserList(props?.acao_preparatoria?.responsaveisNotaTec.map(item => {
                        if (item.nota_tecnica_status === "Preenchido")
                        return item.nota_tecnica_id_responsavel;
                    }))
                }, 1000);
                // let resultadoUserList = props?.acao_preparatoria?.responsaveis_esf orco.split(",");

                // setUserList(resultadoUserList){
                //   return nota_tecnica_id_responsavel;
                // // }
                // setLockedUserList(props?.acao_preparatoria?.responsaveisNotaTec.map(item => {
                //   if (item.nota_tecnica_status === "Preenchido")
                //     return item.nota_tecnica_id_responsavel;
                // }))
            },
            )

            setPrazo(formatDate(new Date(props?.acao_preparatoria.prazo), "dd/MM/yyyy") < formatDate(new Date(), "dd/MM/yyyy") ? String(new Date()) : String(props?.acao_preparatoria.prazo)  ) 
            setResponsavel(String(props?.acao_preparatoria?.id_autor))
            setObjetivo(props?.acao_preparatoria.objetivo)
            setResponsaveisEsforco(props?.acao_preparatoria?.responsaveis_esforco)
                
                setresponsaveisNotaTec(props?.acao_preparatoria?.responsaveisNotaTec)

        }
    }, [open]);

    const editarAcaoPreparatoria = () => {
        const data = {
            prazo: prazo ? new Date(prazo) : null,
            id_responsavel: responsavel,
            // id_autor: autor,
            objetivo: objetivo,
            responsaveis_esforco: userList,
            // responsaveisNotaTec: responsaveisNotaTec,
        }

        setError(() => [])
        data && ProposicaoAcaoPreparatoriaEdicaoSchema

            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));


        setData(data)
        ProposicaoAcaoPreparatoriaEdicaoSchema.validate(data).catch(function (err) {
        });

        if (ProposicaoAcaoPreparatoriaEdicaoSchema.isValidSync(data)) {

            ProposicaoAcaoPreparatoriaService.Update(data, props?.id).then((res) => {
                if (String(props?.acao_preparatoria.status) === "Concluída") {
                    NewAlert("error", "Não é possível editar ação preparatória concluída");
                }
                else if (res) {
                    NewAlert("success", "Ação Preparatória atualizada com sucesso");
                    if (props.reloadList) {
                        props.reloadList();
                    }
                    if (props?.closeMenu) {
                        props?.closeMenu();
                    }
                    setOpen(false);
                }
                else {
                    NewAlert("error", "Verifique os campos e tente outra vez");
                }
            })
                .then((data) => {
                    // props?.onChange()
                    // handleAfterUpload()
                });
        }
    };

    const handleSetUserList = React.useCallback((data) => {
        if (!arraysEquals(userList, data.selected)) {
            setDepartamento(() => [...data.list]);
            setUserList(data.selected);

        }
    },
        [departamento]
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handlePrazoChange = (prazoData) => {
        setPrazo(prazoData)
    };

    const handleObjetivoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setObjetivo(event.target.value)
    };

    const handleResponsavelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResponsavel(event.target.value)
    };

    function handleAfterUpload() {
        NewAlert('success', 'Ação Preparatória criada com sucesso')
    }

    return (
        <div>
            {
                user.id_permissao ?
            
            <Button onClick={() => handleClickOpen()}
                style={{
                    marginLeft: "-7px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    paddingRight: "85px",
                    marginRight: "-10px"
                }}>
                <EditIcon style={{ marginRight: "5px" }} />
                &nbsp;&nbsp;&nbsp;Editar
            </Button>
            :
            null
            }

            <BootstrapDialog style={{
                paddingLeft: `${isMobile ? "5px" : "10px"}`,
                paddingRight: `${isMobile ? "5px" : "10px"}`,
                borderRadius: "6px 6px 0px 0px",
            }}
                maxWidth={"md"}
                onClose={handleClose}
                aria-labelledby="customized-dialog"
                open={open}
                sx={{ ".MuiPaper-root": { width: "100%" } }}
            >
                <Grid container xl={12} lg={12} xs={12} sx={{
                    height: `${isMobile ? "50px" : "80px"}`,
                    borderRadius: "6px",
                    boxShadow: "0px 10px 20px rgba(31, 32, 65, 0.05)",
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`
                }}>
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}>
                        <Grid style={{
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: pallet.general.weight_bold,
                            fontSize: `${pallet.general.size18}`,
                            // padding: "4px 0px 0px 10px",
                        }}>
                            Editar Ação Preparatória
                        </Grid>
                    </BootstrapDialogTitle>

                </Grid>
                <Grid container xl={12} lg={12} xs={12} style={{
                    backgroundColor: `${pallet.backGroundPageColorSecondary}`
                }}>
                    <Grid container spacing={3}
                        style={{
                            paddingLeft: `${isMobile ? "5px" : "24px"}`,
                            paddingRight: `${isMobile ? "5px" : "24px"}`,
                        }}
                    >
                        <Grid item lg={6} xs={12} style={{
                            paddingTop: `${isMobile ? "15px" : "48px"}`,
                        }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}
                                style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.general.weight_600,
                                    color: `${pallet.general.color1}`,
                                }}>
                                <DeadLineDate
                                    // focused={true}
                                    minDate={new Date}
                                    onChange={handlePrazoChange}
                                    label={"PRAZO"}
                                    inputFormat={"dd/MM/yyyy"}
                                    value={prazo}
                                    defaultValue={prazo}
                                    id={"prazo-demandas"}
                                />
                            </LocalizationProvider>
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='prazo' errors={error} />
                            </Grid>
                        </Grid>
                        <Grid item lg={6} xs={12}
                            style={{
                                paddingTop: `${isMobile ? "15px" : "48px"}`,
                            }}>
                            <SelectTextFieldA
                                focused={true}
                                value={responsavel}
                                onChange={handleResponsavelChange}
                                options={responsaveisList}
                                // defaultValue={responsavel}
                                label={"RESPONSÁVEL PELA AÇÃO PREPARATÓRIA"}
                                id={"responsavel-acao_preparatoria"}
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='id_responsavel' errors={error} />
                            </Grid>
                        </Grid>

                        <Grid item lg={12} xs={12} style={{ marginBottom: `${isMobile ? "15px" : "0px"}` }}>
                            <InputMultilineTextField
                                type={"text"}
                                focused={true}
                                // placeholder={"Digite"}
                                maxRows={4}
                                value={objetivo}
                                defaultValue={objetivo}
                                onChange={handleObjetivoChange}
                                id={"objetivo-demandas"}
                                label="OBJETIVO"
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='objetivo' errors={error} />
                            </Grid>
                        </Grid>
                        <Grid xl={2} lg={2} xs={12} container style={{
                            paddingLeft: `${isMobile ? "25px" : "30px"}`
                        }}
                            justifyContent="flex-start"
                            alignItems="center">
                        </Grid>
                    </Grid>
                </Grid>

                <Grid>
                    < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "30px"}`, marginBottom: `${isMobile ? "15px" : "30px"}` }} />
                </Grid>

                <Typography className={classes.heading} style={{
                    color: `${pallet.color.secundary}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontSize: `${pallet.general.size12}`,
                    fontWeight: pallet.general.weight_600,
                    alignItems: "center",
                    letterSpacing: "1px",
                    borderRadius: "2px",
                    textTransform: "uppercase",
                    marginLeft: `${isMobile ? "25px" : "25px"}`,
                    marginBottom: `${isMobile ? "10px" : "10px"}`,

                }}>
                    Unidade de negócio
                </Typography>
                <Grid item lg={12} xs={12}
                    style={{
                        paddingLeft: `${isMobile ? "15px" : "24px"}`,
                        paddingRight: `${isMobile ? "15px" : "24px"}`
                    }}
                >
                    {departamento &&
                        <>
                            <InputDropDownCheckBoxNested
                                items={departamento}
                                onChange={handleSetUserList}
                                label={"Solicitacao de"}
                                selectedItems={userList}
                                lockedItems={lockedUserList}

                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='responsaveis_esforco' errors={error} />
                            </Grid>
                        </>
                    }
                </Grid>
                <Grid style={{
                    paddingLeft: `${isMobile ? "15px" : "24px"}`,
                    paddingRight: `${isMobile ? "15px" : "24px"}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.general.weight_500,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                }}>
                    {lockedUserList.length >= 1 ?
                        <div>
                            Não é possível remover usuários que já preencheram nota técnica.
                        </div>
                        :
                        <div></div>
                    }
                </Grid>
                <Grid>
                    < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "20px" : "40px"}` }} />
                </Grid>
                <Grid style={{
                    paddingTop: `${isMobile ? "15px" : "30px"}`,
                    paddingLeft: `${isMobile ? "15px" : "30px"}`,
                    paddingRight: `${isMobile ? "15px" : "30px"}`,
                }}>
                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                        <Grid style={{
                            fontWeight: pallet.fontWeight.subtitle,
                            fontSize: `${pallet.general.size12}`,
                            color: `${pallet.color.tertiary.font}`,

                        }}>
                            ANEXOS
                        </Grid>
                        <Grid>
                            <Grid className="container">
                                <AnexosComponent
                                    label={''}
                                    modulo={EAnexoModulos.ProposicaoAcaoPreparatoria}
                                    id_referencia={props.id}
                                    onAfterUpload={handleAfterUpload}
                                    canEdit={true}
                                    autosave={true}
                                />
                                {/* {String(userList)
                } */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    < Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                </Grid>
                <Grid container alignItems="center" sx={{
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    height: `${isMobile ? "50px" : "80px"}`,
                    borderRadius: "6px",
                    boxShadow: "0px 10px 20px rgba(31, 32, 65, 0.05)"


                }}>
                    <Grid container alignItems="center" justifyContent="end"
                        style={{
                            textAlignLast: "end",
                            paddingRight: `${isMobile ? "10px" : "24px"}`,

                        }}>
                        <Grid item>
                            <ButtonDialogConfirmation
                                styles={{
                                    borderRadius: "6px 6px 0px 0px",
                                    marginRight: "20px",
                                    textTransform: "capitalize",
                                    color: `${pallet.general.color1}`,
                                }}
                                title={"CONFIRMAÇÃO"}
                                title_button={"Cancelar"}
                                content={"CANCELAR CADASTRO?"}
                                message={"Esta ação não poderá ser desfeita."}
                                confirmation_button={"Cancelar"}
                                confirmationEvent={() => {
                                    setOpen(false)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <SolidButton
                                onClick={editarAcaoPreparatoria}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={"SALVAR"}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </BootstrapDialog>
        </div >
    )
}