import React from 'react';
import { useParams } from 'react-router-dom';
import ParlamentarService from '../../services/ParlamentarService';
import Main from '../main/Main';
import { Grid, Paper } from '@mui/material';
import useStyles from '../../components/layout/Styles';
import { IParlamentar } from '../../models/Parlamentar.Model';
import ParlamentarCarouselTabs from '../parlamentar/_components/ParlamentarCarouselTabs'
// import _ from 'lodash';
import ParlamentarConsultaHeader from '../parlamentar/_components/ParlamentarConsultaHeader';
import { ParlamentarConsultaBotoes } from '../parlamentar/_components/ParlamentarConsultaBotoes';
import { ParlamentarConsultaInicio } from '../parlamentar/_components/ParlamentarConsultaInicio';
import { ParlamentarConsultaInformacoes } from '../parlamentar/_components/ParlamentarConsultaInformacoes';
import { ParlamentarConsultaMenuParlamentar } from '../parlamentar/_components/ParlamentarConsultaMenuParlamentar';
import { useQuery } from '../../utils/utils';
import { GerUsuariosConsultaBotoes } from './_components/GerUsuariosConsultaBotoes';
import GerUsuariosConsultaHeader from './_components/GerUsuariosConsultaHeader';
import GerUsuariosCarouselTabs from './_components/GerUsuariosCarouselTabs';
import { GerUsuariosConsultaInicio } from './_components/GerUsuariosConsultaInicio';
import { GerUsuariosConsultaInformacoes } from './_components/GerUsuariosConsultaInformacoes';
import UsuarioService from '../../services/UsuarioService';
import { IDepartamento, IUser } from '../../models/User.Model';
import { useAuthorization } from '../../contexts/AuthorizationContext';
import { AnyDate } from 'react-date-range';
import ClientesService from '../../services/ClientesService';
import GerDepartamentoConsultaHeader from './_components/GerDepartamentoConsultaHeader';
import { GerDepartamentoConsultaBotoes } from './_components/GerDepartamentoConsultaBotoes';
import { GerDepartamentoConsultaInicio } from './_components/GerDepartamentoConsultaInicio';
import GerUsuariosCarouselTabsUsuarios from './_components/GerUsuariosCarouselTabsUsuarios';
import GerUsuariosTabsUsuarios from './_components/GerUsuariosTabsUsuarios';
import GerDepartamentoTabsUsuarios from './_components/GerDepartamentoTabsUsuarios';

export default function GerenciamentoDepartamento(): JSX.Element {


    const classes = useStyles();
    const [departamento, setDepartamento] = React.useState<any>()
    const qry = useQuery().get('filtro');
    const { user } = useAuthorization();
    //const id = Number(`${user?.id}`);
    //const [id, setId] = React.useState<Number>()
    const { id } = useParams();


    // React.useEffect(() => {
    //     console.log("Entrou no efect1 *********",Number(`${user?.id}`))
    //     setId(Number(`${user?.id}`));
    //     //loadUsuario()
    //     // eslint-disable-next-line
    // })

    React.useEffect(() => {
        //setId(Number(`${user?.id}`));        
        loadUsuario()
        // eslint-disable-next-line
    }, [id])

    const loadUsuario = () => {


        if (id)
            ClientesService
                .getDepartamento(Number(id))
                .then(departamento => departamento && setDepartamento(departamento));

    }

    
    return (
        <div className={classes.root}>
            <Main>
                <Grid container justifyContent="space-around">
                    {/* ref={divRef} */}
                    <Paper style={{ borderRadius: "7px", width: "100%" }} >
                        {/* Parlamentar Header */}
                        <GerDepartamentoConsultaHeader departamento={departamento} />
                        {/* ---------------- BOTOES ---------------------  */}
                        <GerDepartamentoConsultaBotoes departamento={departamento} onChange={loadUsuario} />
                        {/* Parlamentar Inicio */}
                        <GerDepartamentoConsultaInicio departamento={departamento} />
                        {/* Informações */}
                        {/* <GerDepartamentoConsultaInformacoes departamento={departamento} /> */}
                    </Paper>

                    <Grid container style={{ paddingTop: "5px" }}>
                        <Paper style={{ borderRadius: "7px", width: "100%" }}>
                            <Grid item style={{ width: "100%", marginTop: "15px" }}>

                                <GerDepartamentoTabsUsuarios id_dep={Number(id)} />

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Main>
        </div>
    );
}