import React from 'react';
import styled from "styled-components";
import Tab from '@mui/material/Tab';
import { isMobile } from 'react-device-detect';
import { Grid, Tabs } from '@mui/material';
import { usePallet } from '../../contexts/PalletContext';

const a11yProps = (index: number) => { return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` } };

export interface ICarouselItens {
    index: number,
    hide: boolean,
    label: string,
    component: JSX.Element
}

const CustomTabs = styled(Tabs)`
    font-weight: 600;
    font-size: 14px;
    
    .MuiButtonBase-root {
        padding: 12px 16px;
    }
     button.Mui-selected {
        color: ${(props => props.color)};
    }
`;

export default function CarouselCustom(props: { itens: Array<ICarouselItens>, spacing?: number, activeTab: number, onChange: (value: number) => void }) {

    const { pallet } = usePallet();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => props.itens[newValue].hide || props.onChange(newValue);

    return (
        <Grid style={{ width: "100%" }} >
            <Grid style={{ borderBottom: 1 }}>
                <Grid container spacing={props.spacing ? props.spacing : 0} style={{ width: "100%", flexDirection: `${isMobile ? "row" : "column"}`, marginBottom: "0px" }}>
                    <CustomTabs
                        variant="scrollable"
                        scrollButtons={true}
                        value={props.activeTab}
                        onChange={handleChange}
                        aria-label="scrollTabs"
                        TabIndicatorProps={{
                            style: {
                                background: `${pallet.color.primary.background}`
                            }
                        }}
                        color={pallet.textColorQuaternary}
                    // style={{ paddingLeft: `${isMobile ? "30px" : "0px"}`, paddingTop: `${isMobile ? "10px" : "0px"}` }}
                    >
                        {/* Itens do carrocel */}
                        {props.itens.map(item => { return (<Tab key={item.index} style={{ display:`${item.hide ? 'none' : 'block'}`, fontFamily: `${pallet.fontFamily.general}`, fontWeight: 700, fontSize: '16px' }} label={item.label} {...a11yProps(item.index)} />) })}
                    </CustomTabs>
                </Grid>
            </Grid>
            {/* Componentes */}
            {props.itens.map(item => { return (item.component) })}
        </Grid>
    );
}