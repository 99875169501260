import { Grid, Divider, FormControl, Radio, FormControlLabel, RadioGroup } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import ComboBox from "../../../components/ComboBox";
import { IFilterData } from "../../../components/filter/ListFilter";
import ProposicaoService from "../../../services/ProposicaoService";
import React from "react";
import TabelasService from "../../../services/TabelasService";
import { IListFilterInputItem } from "../../../components/filter/ListFilterItems";
import ParlamentarService from "../../../services/ParlamentarService";
import { Visibility } from "@material-ui/icons";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import InsightService from "../../../services/InsightService";
import FiltroRelatoriaAutoria from "../../../components/FitroRelatoriaAutoria";
import { formatDate } from "../../../utils/dateUtils";
export function AccordionContentKey29(props: {
  // filtros?: IFiltroTipoProps[],
  titleFederal?: string;
  originFederal?: string;
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string,
  onChange?: (event: any) => void;
}) {
  const navigate = useNavigate();
  const [filtro, setFiltro] = React.useState<any>('A');
  const [nomeFiltro, setNomeFiltro] = React.useState("Autoria");
  const { pallet } = usePallet();
  const [listaDescricao, setListaDescricao] = useState<any[]>([]);
  const [descricaoParcial, setDescricaoParcial] = useState<any>();
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);
  const [interlocutorItens, setInterlocutorItens] = React.useState<Array<IListFilterInputItem>>([]);
  const [idInterlocutor, setIdInterlocutor] = useState<number>();
  const [nome, setNome] = useState<string>("");
  const [nomeParcial, setNomeParcial] = useState<string>("");
  const [listaNomes, setListaNomes] = useState<string[]>([]);
  const [arrayResultGrupo, setArrayResultGrupo] = React.useState([]);
  const [arrayResultTema, setArrayResultTema] = React.useState([]);
  const [arrayResultSubTema, setArrayResultSubTema] = React.useState([]);
  const [relatoria, setRelatoria] = useState<string>("")
  const [autoria, setAutoria] = useState<string>("")
  const Title = styled(Grid)`
  padding-left:  ${isMobile ? '5px' : '24px'};
  padding-top: ${isMobile ? '5px' : '30px'} ;
  background-color: ${pallet.backGroundPageColorPrimary};
  text-transform: uppercase;
  font-size:${pallet.general.size14};
  color: ${pallet.textColorQuaternary};
  font-family: ${pallet.general.fontFamily};
  place-content:  ${isMobile ? 'center' : 'unset'};
  font-weight: ${pallet.general.weight_600};
  height: 79px;
`;
  // React.useEffect(() => {
  //   TabelasService.getTabelaInterlocutores()
  //     .then(async res => {
  //       const data = []
  //       data.push({ key: "", label: '-- Selecione -- ', value: "" });
  //       res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
  //       setInterlocutorItens(() => [...data]);
  //     });
  // }, []);
  useEffect(() => {
    if (nomeParcial && nomeParcial !== null && nomeParcial.trim().length > 2) {
      ParlamentarService.GetPartialNameWithId(nomeParcial, [{ key: 'esfera', value: 'Municipal' }])
        .then(async res => setListaNomes(res));
    }
    else if (nomeParcial === "") {
      loadParlamentarList();
    }
  }, [nomeParcial]);
  const loadParlamentarList = () => {
    let idParlamentar
    const filter = currentFilter ? currentFilter.map(item => { return { key: item.key, label: item.label, value: item.value } }) : [];
    if (nome && nome.trim() !== "") {
      filter?.push({ key: 'nome', label: nome, value: idParlamentar });
      setIdInterlocutor(idParlamentar)
      setNome(() => undefined)
    }
    ParlamentarService.GetList()
      .then(async res => {
        if (res && res.success) {
          console.log(res)
        }
      });
  };
  const handleOnSelect = (data: any) => {
    if (data) {
      setIdInterlocutor(data.value);
    }
  };
  const setFilter = (f: any) => {
    setFiltro(f.target.value);
    if (f.target.value === "R") {
      setNomeFiltro("Relatoria");
    }
    if (f.target.value === "A") {
      setNomeFiltro("Autoria");
    };
  }
  React.useEffect(() => {
    if (filtro === 'A' && idInterlocutor) {
      if (
        String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
        String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
      ) {
        InsightService.GetKey29AutoriasGrupo(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), idInterlocutor,
        ).then((res) => {
          if (res) {
            setArrayResultGrupo(res.data)
          }
        });
        InsightService.GetKey29AutoriasTema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), idInterlocutor,
        ).then((res) => {
          if (res) {
            setArrayResultTema(res.data)
          }
        });
        InsightService.GetKey29AutoriasSubTema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), idInterlocutor,
        ).then((res) => {
          if (res) {
            setArrayResultSubTema(res.data)
          }
        });
      }
    }
    if (filtro === 'R' && idInterlocutor) {
      if (
        String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
        String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
      ) {
        InsightService.GetKey29RelatoriasGrupo(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), idInterlocutor
        ).then((res) => {
          if (res) {
            setArrayResultGrupo(res.data)
          }
        });
        InsightService.GetKey29RelatoriasTema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), idInterlocutor
        ).then((res) => {
          if (res) {
            setArrayResultTema(res.data)
          }
        });
        InsightService.GetKey29RelatoriasSubTema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), idInterlocutor
        ).then((res) => {
          if (res) {
            setArrayResultSubTema(res.data)
          }
        });
      }
    }
  }, [props.dtIni, props.dtFin, idInterlocutor, filtro]);
  console.log("idInterlocutor", idInterlocutor)
  return (
    <Grid>
      <Grid>
        <Grid
          style={{
            color: `${pallet.textColorQuaternary}`,
            fontFamily: `${pallet.general.fontFamily}`,
            fontSize: `${pallet.general.size14}`,
            fontWeight: pallet.general.weight_600,
            marginTop: "30px",
            marginBottom: "10px",
            marginLeft: "24px",
            marginRight: "24px",
          }}
        >
          INTERLOCUTOR
        </Grid>
        <Grid container md={12}
          xs={12}
          lg={12}>
          <Grid
            style={{
              marginBottom: "30px",
              marginLeft: "24px",
              marginRight: "24px",
            }}
            item
            md={6}
            xs={12}
            lg={6}
          >
            <ComboBox
              name='filtroNome'
              itens={listaNomes}
              onChange={setNomeParcial}
              onSelect={handleOnSelect}
              placeholder="Buscar Interlocutor"
            />
          </Grid>
          {/* <Grid
            style={{
              marginBottom: "30px",
              marginLeft: "24px",
              marginRight: "24px",
              textAlignLast: `${isMobile ? "center" : "unset"}`
            }}
            item
            md={4}
            xs={12}
            lg={4}
          >
            <FiltroRelatoriaAutoria
              // title={"Visualizar"}
              onChange={setFilter}
              icon={<Visibility />}
            />
          </Grid> */}
        </Grid>
        <Grid>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid
          style={{ marginTop: "20px", marginLeft: "24px", marginRight: "24px", marginBottom: "24px" }}
        >
          <Grid>
            <Grid
              container
              style={{
              }}
            >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                GRUPO
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES
              </Title>
            </Grid>
            <Grid
              item
              container
              style={{
                color: `${pallet.textColorQuaternary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size12}`,
                fontWeight: pallet.general.weight_500,
                maxHeight: "400px", overflowY: "auto"
              }}
            >
              {arrayResultGrupo.map((x, i) => {
                return (
                  <>
                    <Grid container lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderLeft: '1px solid #EAEAEA',
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== arrayResultGrupo.length ? null : '0px 0px 0px 6px',
                      maxWidth: `${isMobile ? '128px' : 'none'}`,
                      wordBreak: `${isMobile ? 'break-all' : 'normal'}`,
                      placeContent: `${isMobile ? 'center' : 'unset'}`,
                      textAlign: `${isMobile ? 'center' : 'unset'}`
                    }}>
                      <Grid container item lg={6} xs={6}
                        style={{
                          paddingBottom: '5px',
                          height: 'auto',
                          color: `${pallet.textColorQuaternary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size14}`,
                          fontWeight: pallet.general.weight_600,
                          maxWidth: 'none',
                          minWidth: `${isMobile ? '115px' : 'none'}`,
                          wordBreak: `${isMobile ? 'break-all' : 'normal'}`,
                          placeContent: `${isMobile ? 'center' : 'unset'}`,
                          textAlign: `${isMobile ? 'center' : 'unset'}`
                        }}
                      >
                        {x.descricao}
                      </Grid>
                    </Grid>
                    <Grid container item lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== arrayResultGrupo.length ? null : '0px 0px 6px 0px',
                      color: `${pallet.textColorTertiary}`,
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size14}`,
                      fontWeight: pallet.general.weight_600,
                      maxWidth: 'none',
                      wordBreak: 'break-word',
                      minWidth: '100px',
                      cursor: "pointer",
                      placeContent: `${isMobile ? 'center' : 'unset'}`
                    }}
                      onClick={filtro === 'R' ?
                        () => navigate('/proposicao?filtro=[{"key":"dtIniRel","label":"DATA RELATORIA INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "yyyy/dd/dd 00:00:00") +
                          '"},{"key":"dtFinRel","label":"DATA RELATORIA FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "yyyy/MM/dd 23:59:59") +
                          '"},{"key":"relator","label":"RELATOR: ' + x.nome + '","value":"' + idInterlocutor + '"},{"key":"grupos","label":"GRUPO: ' + x.descricao + '","value":"' + x.id + '"}]')
                        :
                        () => navigate('/proposicao?filtro=[{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "yyyy/MM/dd 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "yyyy/MM/dd 23:59:59") +
                          '"},{"key":"autorI","label":"AUTOR: ' + x.nome + '","value":"' + idInterlocutor + '"},{"key":"grupos","label":"GRUPO: ' + x.descricao + '","value":"' + x.id + '"}]')
                      }
                    >
                      {x.count}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "20px", marginLeft: "24px", marginRight: "24px", marginBottom: "24px" }}
        >
          <Grid>
            <Grid
              container
              style={{
              }}
            >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                TEMA
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES
              </Title>
            </Grid>
            <Grid
              item
              container
              style={{
                color: `${pallet.textColorQuaternary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size12}`,
                fontWeight: pallet.general.weight_500,
                maxHeight: "400px", overflowY: "auto"
              }}
            >
              {arrayResultTema.map((x, i) => {
                return (
                  <>
                    <Grid container lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderLeft: '1px solid #EAEAEA',
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== arrayResultTema.length ? null : '0px 0px 0px 6px',
                      maxWidth: `${isMobile ? '128px' : 'none'}`,
                      wordBreak: `${isMobile ? 'break-all' : 'normal'}`,
                      placeContent: `${isMobile ? 'center' : 'unset'}`,
                      textAlign: `${isMobile ? 'center' : 'unset'}`
                    }}>
                      <Grid container item lg={6} xs={6}
                        style={{
                          paddingBottom: '5px',
                          height: 'auto',
                          color: `${pallet.textColorQuaternary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size14}`,
                          fontWeight: pallet.general.weight_600,
                          maxWidth: 'none',
                          minWidth: `${isMobile ? '115px' : 'none'}`,
                          wordBreak: `${isMobile ? 'break-all' : 'normal'}`,
                          placeContent: `${isMobile ? 'center' : 'unset'}`,
                          textAlign: `${isMobile ? 'center' : 'unset'}`
                        }}
                      >
                        {x.descricao}
                      </Grid>
                    </Grid>
                    <Grid container item lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== arrayResultTema.length ? null : '0px 0px 6px 0px',
                      color: `${pallet.textColorTertiary}`,
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size14}`,
                      fontWeight: pallet.general.weight_600,
                      maxWidth: 'none',
                      wordBreak: 'break-word',
                      minWidth: '100px',
                      cursor: "pointer",
                      placeContent: `${isMobile ? 'center' : 'unset'}`
                    }}
                      onClick={filtro === 'R' ?
                        () => navigate('/proposicao?filtro=[{"key":"dtIniRel","label":"DATA RELATORIA INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "yyyy/MM/dd 00:00:00") +
                          '"},{"key":"dtFinRel","label":"DATA RELATORIA FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "yyyy/MM/dd 23:59:00") +
                          '"},{"key":"relator","label":"RELATOR: ' + x.nome + '","value":"' + idInterlocutor + '"},{"key":"temas","label":"TEMA: ' + x.descricao + '","value":"' + x.id + '"}]')
                        :
                        () => navigate('/proposicao?filtro=[{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "yyyy/MM/dd 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "yyyy/MM/dd 23:59:00") +
                          '"},{"key":"autorI","label":"AUTOR: ' + x.nome + '","value":"' + idInterlocutor + '"},{"key":"temas","label":"TEMA: ' + x.descricao + '","value":"' + x.id + '"}]')
                      }
                    >
                      {x.count}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "20px", marginLeft: "24px", marginRight: "24px", marginBottom: "24px" }}
        >
          <Grid>
            <Grid
              container
              style={{
              }}
            >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                SUBTEMA
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES
              </Title>
            </Grid>
            <Grid
              item
              container
              style={{
                color: `${pallet.textColorQuaternary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size12}`,
                fontWeight: pallet.general.weight_500,
                maxHeight: "400px", overflowY: "auto"
              }}
            >
              {arrayResultSubTema.map((x, i) => {
                return (
                  <>
                    <Grid container lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderLeft: '1px solid #EAEAEA',
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== arrayResultSubTema.length ? null : '0px 0px 0px 6px',
                      maxWidth: `${isMobile ? '128px' : 'none'}`,
                      wordBreak: `${isMobile ? 'break-all' : 'normal'}`,
                      placeContent: `${isMobile ? 'center' : 'unset'}`,
                      textAlign: `${isMobile ? 'center' : 'unset'}`
                    }}>
                      <Grid container item lg={6} xs={6}
                        style={{
                          paddingBottom: '5px',
                          height: 'auto',
                          color: `${pallet.textColorQuaternary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size14}`,
                          fontWeight: pallet.general.weight_600,
                          maxWidth: 'none',
                          minWidth: `${isMobile ? '115px' : 'none'}`,
                          wordBreak: `${isMobile ? 'break-all' : 'normal'}`,
                          placeContent: `${isMobile ? 'center' : 'unset'}`,
                          textAlign: `${isMobile ? 'center' : 'unset'}`
                        }}
                      >
                        {x.descricao}
                      </Grid>
                    </Grid>
                    <Grid container item lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== arrayResultSubTema.length ? null : '0px 0px 6px 0px',
                      color: `${pallet.textColorTertiary}`,
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size14}`,
                      fontWeight: pallet.general.weight_600,
                      maxWidth: 'none',
                      wordBreak: 'break-word',
                      minWidth: '100px',
                      cursor: "pointer",
                      placeContent: `${isMobile ? 'center' : 'unset'}`
                    }}
                      onClick={filtro === 'R' ?
                        () => navigate('/proposicao?filtro=[{"key":"dtIniRel","label":"DATA RELATORIA INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy ") +
                          '","value" : "' +
                          formatDate(props.dtIni, "yyyy/MM/dd 00:00:00") +
                          '"},{"key":"dtFinRel","label":"DATA RELATORIA FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "yyyy/MM/dd 23:59:59") +
                          '"},{"key":"relator","label":"RELATOR: ' + x.nome + '","value":"' + idInterlocutor + '"},{"key":"subtemas","label":"SUBTEMA: ' + x.descricao + '","value":"' + x.id_subtema + '"}]')
                        :
                        () => navigate('/proposicao?filtro=[{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "yyyy/MM/dd 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "yyyy/MM/dd 23:59:59") +
                          '"},{"key":"autorI","label":"AUTOR: ' + x.nome + '","value":"' + idInterlocutor + '"},{"key":"subtemas","label":"SUBTEMA: ' + x.descricao + '","value":"' + x.id_subtema + '"}]')
                      }
                    >
                      {console.log("X.nome", x.nome)}
                      {x.count}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
}
export default AccordionContentKey29;