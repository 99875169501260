import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as EmptyState } from '../img/emptyCorrelata.svg';
import { Button, fabClasses } from '@mui/material';
import { ArrowForwardIos } from '@material-ui/icons';
import { usePallet } from '../contexts/PalletContext';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import ProposicaoService from '../services/ProposicaoService';
import CircularLoading from './CircularLoading';
import { useAlert } from '../contexts/AlertContext';

function EmptyErrorCorrelata(props: { title: string, description?: string, id_proposicao: number, onChange?: () => void }) {
    const { pallet } = usePallet();

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { NewAlert } = useAlert();

    const gerarCorrelatas = () => {

        setLoading(true);
        ProposicaoService.GerarCorrelata(props.id_proposicao).then(res => {
            setLoading(false);
            if (res > 0) {
                //setLoading(false);
                props?.onChange()
            } else {
                NewAlert('error', 'Para essa proposição não existem informações suficientes para gerar correlatas !');
            }
            //setLoading(false);
        }
        )
        //setLoading(false);
    }

    return (
        <>
            {
                loading === true

                    ?

                    <CircularLoading
                        size={80}
                        top={"90%"}
                        left={"50%"}
                        zIndex={2}

                    />

                    :
                    <Grid>

                        <EmptyState />
                        <h2>{props.title ? props.title : "Você ainda não relacionou nenhum tema"}</h2>
                        {/* <Grid>{props.description ? props.description : "Para gerar correlata, é necessário relacionar os temas"}</Grid> */}



                        <Grid container xs={12} justifyContent="center">
                            {/* <Button
                                onClick={() => { navigate(`/ia`) }}
                                variant="contained"
                                size="large"
                                style={{
                                    textTransform: 'uppercase',
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.general.size12}`,
                                    fontWeight: pallet.general.weight_300,
                                    border: "none",
                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                    color: `${pallet.textColorPrimary}`
                                }}
                                endIcon={<ArrowForwardIos style={{
                                    color: `${pallet.textColorPrimary}`,
                                    width: "10px"
                                }}

                                />}
                            >
                                TEMAS RELACIONADOS
                            </Button> */}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                onClick={gerarCorrelatas}
                                variant="contained"
                                size="large"
                                style={{
                                    textTransform: 'uppercase',
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.general.size12}`,
                                    fontWeight: pallet.general.weight_300,
                                    border: "none",
                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                    color: `${pallet.textColorPrimary}`
                                }}
                                startIcon={<RefreshIcon style={{
                                    color: `${pallet.textColorPrimary}`
                                }}
                                />}
                            >
                                GERAR CORRELATAS
                            </Button>
                        </Grid>

                    </Grid>
            }

        </>
    );

}

export default EmptyErrorCorrelata;
