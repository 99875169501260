import React from 'react';
import { Grid, Paper, Typography } from "@mui/material"
import { isMobile } from "react-device-detect"
import { usePallet } from "../../../contexts/PalletContext"
import { IParlamentar } from "../../../models/Parlamentar.Model"
import styled from "styled-components"
import { IDepartamento, IUser } from '../../../models/User.Model';
import { UsuarioRoutes } from '../../usuarios/_Usuarios.Routes';
import SummaryPanelWidget from '../../workspace/_components/SummaryPanelWidget';
import SummaryPanelWidgetGerDep from './SummaryPanelWidgetGerDep';



const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 11px 4px 11px;
    /* margin-bottom: 7px; */
    line-height: 15px;
    letter-spacing: 1px;
`;


const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 9px;
    margin-top: 3px;
`;

const HeaderContentPaper = styled(Paper)`
  justify-content: center;
  padding: 15px;
  margin-bottom: 15px;
`;

// const prioridadeColor = (value: string) => {
//     const { pallet } = usePallet()

//     switch (value) {
//         case 'Máxima':
//             return `${pallet.charts.red}`;
//         case 'Baixa':
//             return `${pallet.charts.blue}`;
//         case 'Média':
//             return `${pallet.charts.yellow}`;
//         case 'Alta':
//             return `${pallet.charts.purple}`;
//         case 'Mínima':
//             return `${pallet.charts.green}`;
//         default:
//             return `${pallet.charts.lightGrey}`;
//     }
// }

const ParlamentarConsultaSpanCustom = (props) => {
    const { pallet } = usePallet()
    return (
        <span style={{
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.smallButton}`,
            ...props?.style
        }}>
            {props?.children}
        </span>
    )
}

const ParlamentarConsultaTypography = (props) => {
    const { pallet } = usePallet()
    return (
        <Typography align="justify" style={{
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.titleCardParlamentar}`,
            color: `${pallet.color.colorBase.black}`,
            ...props?.style
        }}>
            {props?.children}
        </Typography>
    )
}

const ParlamentarApelidoConsultaTypography = (props) => {
    const { pallet } = usePallet()
    return (
        <Typography align="justify" style={{
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: 500,
            fontSize: '15px',
            paddingTop: '2px',
            color: `${pallet.color.colorBase.black}`,
            ...props?.style
        }}>
            {props?.children}
        </Typography>
    )
}

const ParlamentarConsultaSituacaoTypography = (props: { children: any, style?: any }) => {
    const { pallet } = usePallet()
    return (
        <Typography align="justify" style={{
            padding: "3px 9px",
            borderRadius: "13px",
            backgroundColor: `${pallet.color.colorBase.greyBackground}`,
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.text_10}`,
            color: `${pallet.color.colorBase.greyText}`,
            marginBottom: "15px",
            textTransform: "uppercase",
            ...props?.style
        }}>
            {props.children}
        </Typography>
    )
}
export const GerDepartamentoConsultaInicio = (props: { departamento: any }) => {
    const { pallet } = usePallet()
    return (
        <Grid item>
            <div style={{ paddingLeft: `${isMobile ? "20px" : "50px"}`, marginTop: "35px" }}>
                <Grid >

                    <Grid item >
                        {/* <Grid container>

                            <ChipStyle pallet={pallet}>
                                <Grid style={{ display: "flex", justifyContent: "space-between " }} >

                                    <DotStyle bg={props.departamento?.situacao === 1 ? pallet.color.colorBase.green : pallet.color.charts.red}>
                                    </DotStyle>

                                    <Grid>
                                        {(props.usuario?.situacao == 1 ? "Ativo" : "Desativado")}
                                    </Grid>
                                </Grid>
                            </ChipStyle>
                        </Grid> */}

                        <Grid container style={{
                            marginTop: "10px"
                        }}>
                            <Grid item>
                                <ParlamentarConsultaTypography style={{ textTransform: "uppercase" }}>
                                    UNIDADE DE NEGÓCIO
                                </ParlamentarConsultaTypography>
                            </Grid>
                        
                        </Grid>
                        <Grid container>
                            <Typography style={{
                                color: `${pallet.color.colorBase.black}`,
                            }}>
                                <ParlamentarConsultaSpanCustom >
                                    {props.departamento?.descricao} {/*props.usuario?.respdep && '• Responsável pelo Departamento' */}
                                </ParlamentarConsultaSpanCustom>
                                {/* <span>
                                    {props.parlamentar?.sigla_partido ? " • " : ""}
                                </span>
                                <ParlamentarConsultaSpanCustom style={{ textTransform: "uppercase" }}>
                                    {props.parlamentar?.sigla_partido}
                                </ParlamentarConsultaSpanCustom> */}


                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                        <Grid item xl={6} lg={6} xs={12} style={{
                            marginTop: "10px",
                            // flexWrap: "nowrap",
                        }}>
                            <SummaryPanelWidgetGerDep
                                title="RESPONSÁVEIS"
                                items={[
                                    {
                                        value: props.departamento?.totais.tot_respativo | 0,
                                        label: "Ativo(s)",
                                    },
                                    {
                                        value: props.departamento?.totais.tot_respinativo | 0,
                                        label: "Inativo(s)",
                                    },
                                ]}
                            />
                        </Grid>

                        <Grid item xl={6} lg={6} xs={12} style={{
                            marginTop: "10px",
                            // flexWrap: "nowrap",
                        }}>
                            <SummaryPanelWidgetGerDep
                                title="TOTAL DE MEMBROS"
                                items={[
                                    {
                                        value: props.departamento?.totais.tot_membativo | 0,
                                        label: "Ativo(s)",
                                    },
                                    {
                                        value: props.departamento?.totais.tot_membinativo | 0,
                                        label: "Inativo(s)",
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </div>
        </Grid>
    )
}