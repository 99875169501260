import { format } from "date-fns";
import * as Yup from "yup";

const ParlamentarDemandasSchema = Yup.object({
    prazo: Yup.date().min(format(new Date(), 'yyyy-MM-dd')).required({ name: "prazo", err: "Escolha uma data válida" }),
    objetivo: Yup.string().required({ name: "objetivo", err: "Preencha o campo 'Objetivo' " }).min(3),
    assunto: Yup.string().required({ name: "assunto", err: "Preencha corretamente o campo 'Assunto, no mínimo 3 caracteres'" }).min(3, { name: "assunto", err: "Preencha corretamente o campo 'Assunto, no mínimo 3 caracteres'" }),
    prioridade: Yup.string().required({ name: "prioridade", err: "Selecione uma prioridade " }),
    posicionamentos: Yup.array().of(Yup.number()).required().min(1, { name: "posicionamentos", err: "Selecione pelo menos um responsável" }),
});
export default ParlamentarDemandasSchema;
