import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, TextField, Typography } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobile } from 'react-device-detect';
import styled from "styled-components";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { formatDate } from '../../../utils/dateUtils';
import CircularLoading from '../../../components/CircularLoading';

// const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

function AccordionTextDataComissaoParlamentar(props: { lista: Array<{ sigla: string, descricao: string, entrada: Date, saida: Date, nome: string }> }): JSX.Element {

    const { pallet } = usePallet();
    const classes = useStyles();
    const [loading,setLoading] = useState(false)

    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;   
    padding-top: 10px;
    flex-flow: row-reverse;
    
    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
`;

        useEffect(() => {
           setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 100);
            
            // eslint-disable-next-line
        }, [props?.lista])
    return (
        <>
        
            {loading 
            ?            
             <CircularLoading
                size={80}
                top={"50%"}
                left={"50%"}
                zIndex={2}
              /> 
        
        :
                
            props.lista?.map(item => {
                return (


                    <Grid key={item.sigla} style={{ marginBottom: "20px" }}>

                        <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }} >
                            <AccordionSum
                                expandIcon={<ArrowDropDownIcon
                                    style={{
                                        color: `${pallet.general.color2}`,
                                        fontSize: `${pallet.general.size16}`
                                    }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">

                                <Grid container>

                                    <Typography className={classes.heading} style={{
                                        color: `${pallet.textColorTertiary}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size14}`,
                                        marginTop: "4px",
                                        marginLeft: "8px",
                                        fontWeight: pallet.general.weight_600,
                                        lineHeight: "17px",
                                        letterSpacing: "1px",
                                        textTransform: "uppercase",
                                        padding: "3px 0px 0px 2px",
                                    }}>
                                        {item?.sigla}
                                    </Typography>

                                    <Grid style={{
                                        backgroundColor: `${pallet.backgroundColorSecondary}`,
                                        color: "#9B9B9B",
                                        textTransform: "uppercase",
                                        borderRadius: "100px",
                                        fontSize: "10px",
                                        padding: "5px 8px 5px 8px",
                                        marginTop: "2px",
                                        marginLeft: "12px",
                                        border: "1px solid #D8D8D8",
                                        lineHeight: "15px",
                                        letterSpacing: "1px",
                                        fontWeight: pallet.general.weight_600,
                                        boxSizing: "border-box",
                                    }}>
                                        {item?.descricao}
                                    </Grid>
                                </Grid>

                            </AccordionSum>

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`
                            }}>
                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "20px 20px 0px 20px"}`, width: "100%" }}>
                                    <Grid xl={12}>
                                        <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: "10px" }}>
                                            <Grid xs={12} lg={4} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`,

                                                }}>
                                                    NOME DA COMISSÃO
                                                </Grid>

                                                <Grid style={{ marginBottom: "10px", paddingRight: "15px", textAlign: "justify" }}>
                                                    {item?.nome}
                                                </Grid>


                                            </Grid>
                                            <Grid xs={12} lg={2} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, paddingLeft: "15px" }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    STATUS
                                                </Grid>

                                                <Grid style={{ marginBottom: "10px", paddingRight: "15px" }}>
                                                    {item?.descricao}
                                                </Grid>

                                            </Grid>
                                            <Grid xs={12} lg={2} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    ENTRADA
                                                </Grid>

                                                <Grid container style={{ marginBottom: "10px" }}>
                                                    {item.entrada && formatDate(new Date(item.entrada), 'dd/MM/yyyy')}
                                                </Grid>

                                            </Grid>
                                            <Grid xs={12} lg={2} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}>
                                                    SAÍDA
                                                </Grid>
                                                <Grid container style={{ marginBottom: "10px" }}>
                                                    <Grid>
                                                        {item.saida && formatDate(new Date(item.saida), 'dd/MM/yyyy')}
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataComissaoParlamentar;

function NewAlert(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
