import { Typography, Grid, Paper } from '@mui/material';
import styled from 'styled-components';
import { usePallet } from '../../../contexts/PalletContext';
import useStyles from '../../../components/layout/Styles';
import NotificationsContextService, { INotificationsContextResultData } from '../../../services/NotificationsContextService';
import NotificacoesList, { IFiltroNotificacaoProps } from './NotificacoesList';
import NotificacaoCard from './NotificacaoCard';
import React, { useEffect, useState } from 'react';
import CarouselCustom from '../../../components/carousel/CarouselCustom';
import NotificacoesListPendencia from './NotificacoesListPendencia';
import ButtonDialogConfirmation from '../../../components/ButtonDialogConfirmation';
import { useAuthorization } from '../../../contexts/AuthorizationContext';
import { useAlert } from '../../../contexts/AlertContext';

const NotificacaoContent = styled(Paper)`
    margin-bottom: 20px;

    .customScroll { 
        /* width */
        &::-webkit-scrollbar {
            width: 20px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #F2F2F2;
            border-radius: 10px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #4B1196; 
            border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #4B1196; 
        }
    }
`;

const TitleText = styled(Typography)`
    color: ${(props => props.pallet.textColorQuaternary)};
    font-weight: ${(props => props.pallet.general.weight_bold)};
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const ButtonLimpar = styled(Typography)`
    align-self: center; 
    font-size: 14px;
    color: #4B1196;
    cursor: pointer;
    margin-left: 20px; 
`;
const NotificacaoPanelPendencia = (props: {
    title: string,
    filters: IFiltroNotificacaoProps[],
    onChange: (event: any) => void,
    id?: string,
    list?: INotificationsContextResultData[],
    totalMinhaInt?: number,
    totalDeptInt?: number,
    totalMinhaProp?: number,
    totalDeptProp?: number,
    reloadList?: (event?: number) => void;
}) => {
    const { pallet } = usePallet();
    const classes = useStyles();
    const [arrayIdsI, setArrayIdsI] = React.useState<Array<any>>([{
        id: 0,
        status: false,
    }]);
    const [arrayIdsII, setArrayIdsII] = React.useState<Array<number>>([]);
    const [activeTab, setActiveTab] = React.useState(0);
    const [filtroAba, setFiltroAba] = React.useState("PROPOSIÇÃO")
    const itensCarousel = [
        { index: 0, hide: false, label: "PROPOSIÇÃO", component: null, activeTab: { activeTab } },
        { index: 1, hide: false, label: "INTERLOCUTOR", component: null, activeTab: { activeTab } },
    ]
    const { user } = useAuthorization();
    const [limpar, setLimpar] = useState<boolean>(false);
    const [arquivado, setArquivado] = useState<boolean>();
    const [checked, setChecked] = useState<boolean>(false);
    const { NewAlert } = useAlert();

    const [arquivou, setArquivou] = useState<string>('')
    useEffect(() => {


        if (props.list?.length > 0) {
            for (var i = 0; i < props.list?.length; i++) {
                arrayIdsI.push({ id: props.list[i].id, status: false })
            }
        }
    }, [props.list]);

    const passarIds = (event: any, id: number) => {

        setLimpar(false)
        arrayIdsI.map((a) => {

            if (a.id === id && a.status === false) {

                a.status = true

            } else
                if (a.id === id && a.status === true) {

                    a.status = false

                }
        })

        // if (event === true) {
        //  arrayIdsI.push(id)
        // }
        // else {
        //     arrayIdsI.splice(arrayIdsI.indexOf(id), 1)
        // }

        // }

    }

    const handleArquivado = async () => {
        var arrayTamanho = arrayIdsI.length;
        arrayIdsI.map((a) => {

            if (a.status === true) {
                arrayIdsII.push(a.id)
            }
        })

        for (var i = 0; i < arrayIdsII.length; i++) {
            await NotificationsContextService
                .Arquivar(arrayIdsII[i])
                .then((res) => {
                })
        }
        setArquivado(true)
        setArquivou("DELETE")
        props.reloadList()
        handleLimpar()
        NewAlert('success', 'Notificações selecionadas arquivadas com sucesso!')

    };


    React.useEffect(() => {
        if (activeTab === 0) {
            setFiltroAba("PROPOSIÇÃO")
        } else {
            setFiltroAba("INTERLOCUTOR")
        }

    }, [activeTab]);


    const handleFalse = () => {
        setChecked(false)
        // setLimpar(false)
    };

    const handleLimpar = () => {
        
        setLimpar(true)
        handleFalse()

        arrayIdsI.map((a) => {
            a.status = false
        })
    };

    return (

        <NotificacaoContent className={classes.paper}
            id={props.id ? props.id : "notificacao-panel"}
            style={{
                padding: "unset",
            }}
        >
            <Grid item xs={12}
                style={{
                    padding: "10px 17px",
                    margin: "5px 10px",
                }}
            >
                <TitleText pallet={pallet}>
                    {props.title}
                </TitleText>
            </Grid>
            <Grid>
                <CarouselCustom itens={itensCarousel} activeTab={activeTab} onChange={setActiveTab} />
            </Grid>
            <Grid container xs={12}
                style={{
                    backgroundColor: "#F9F9F9",
                    padding: "10px 17px",
                }}
            >
                <NotificacoesListPendencia filtros={props.filters} totalMinhaInt={props.totalMinhaInt} totalDeptInt={props.totalDeptInt} totalMinhaProp={props.totalMinhaProp} totalDeptProp={props.totalDeptProp} activeTab={activeTab} onChange={props.onChange} />
            </Grid>
            {props.list?.length > 1 ?
                                    <Grid style={{
                                        alignItems: 'right',
                                        display: 'flex',
                                        margin: '10px 15px 10px 0px',
                                        justifyContent: 'right'
                                    }}>
            <ButtonLimpar onClick={handleLimpar} style={{ marginRight: '10px' }}>Limpar</ButtonLimpar>
                                        <ButtonDialogConfirmation
                                            size={'large'}
                                            styles={{
                                                "&:hover": {
                                                    backgroundColor: '#4B1196',
                                                },
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                color: `${pallet.textColorPrimary}`,
                                                fontSize: `${pallet.general.size12}`,
                                                background: '#4B1196',
                                                letterSpacing: "1px",
                                                border: `${"1px solid lightgrey"}`,
                                                borderRadius: "5px",
                                                fontWeight: pallet.general.weight_600,
                                                padding: "10px",
                                                marginLeft: '10px'

                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            // lastPath={"/central-notificacoes"}
                                            confirmationEvent={() => user.id_permissao !== 3 ? handleArquivado() : NewAlert(
                                                "error",
                                                "Não é possível realizar está ação com este perfil.")} 
                                            title_button={"ARQUIVAR SELECIONADAS"}
                                            confirmation_button={"Arquivar"}
                                            content={"DESEJA REALMENTE ARQUIVAR AS NOTIFICAÇÕES SELECIONADAS?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        />
                                     </Grid>
                                        :
                                        null
                                        
                             }


            <Grid className="customScroll" item xs={12}
                style={{
                    // padding: "10px 17px 17px",
                    maxHeight: "350px",
                    overflow: "auto",
                    margin: "10px 17px 17px",
                }}
            >
                {props.list?.filter(itemFiltrado => itemFiltrado.modulo === filtroAba).map((item) => {
                    return <NotificacaoCard 
                            limpar={limpar}
                            onChange={handleLimpar}
                            checked={checked}
                            notification={item} 
                            status={"A"} 
                            central={false} 
                            arquivado={arquivado}
                            idsFlow={passarIds}
                            reloadList={props.reloadList}
                            removeNotificacao={() => {}}
                            />
                })}

                {props.list?.filter(itemFiltrado => itemFiltrado.modulo === filtroAba).length === 0 && (
                    <>
                        Não há nenhuma notificação
                    </>
                )}
            </Grid>
        </NotificacaoContent>
    )
}


export default NotificacaoPanelPendencia;