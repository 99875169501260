import React from 'react';
import { usePallet } from '../contexts/PalletContext';
import { TextField, MenuItem, Autocomplete } from '@mui/material';
import useStyles from './layout/Styles';



export default function InputTextFieldTags(props: {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value: any,
    options: Array<any>,
    label?: string,
    id: string,
    name?: string,
    placeholder?: string,
    focused?: boolean,
    disabled?: boolean,
    defaultValue?: any,
    altura?: string
}) {

    const classes = useStyles();
    const { pallet } = usePallet();

    return (
        <TextField
            defaultValue={props.defaultValue ? props.defaultValue : props.value}
            select
            value={props.value}
            name={props.name ? props.name : ""}
            placeholder={props.placeholder ? "Selecione" : ""}
            onChange={props.onChange}
            style={{ width: "100%" }}
            focused={props.focused ? props.focused : false}
            id={props.id}
            label={props.label}
            variant="outlined"
            InputProps={{
                classes: {
                    notchedOutline: classes.notchedOutline,
                },
                style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size14}`,
                    fontWeight: pallet.general.weight_100,
                    height: props.altura,
                    wordWrap: "break-word",

                }
            }}
            disabled={props.disabled ? true : false}
            InputLabelProps={{
                style: { color: "grey" }
            }}
        >
            {props.options?.map((option) => (
                <MenuItem placeholder={props.placeholder ? props.placeholder : ""}
                    style={{ fontFamily: `${pallet.general.fontFamily}`, maxWidth: '600px', whiteSpace: 'pre-line' }}
                    key={option.value ? option.value : option.label}
                    value={option.value}>
                    {option.label} {option.name}

                </MenuItem>
            ))}
        </TextField>
    )
}