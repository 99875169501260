import { AppBar, Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import styled from "styled-components";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import ProposicaoService from '../../../../services/ProposicaoService';
import { usePallet } from '../../../../contexts/PalletContext';
import ContentStepTimeLine from './contentStepTimeLine';
import EmptyError from '../../../../components/EmptyError';

const CustomTabs = styled(Tabs)`
    font-weight: 600;
    font-size: 14px;

    .MuiButtonBase-root {
        padding: 12px 16px;
        color: ${(props => props.colorBase)};
    }
     button.Mui-selected {
        color: ${(props => props.color)};
    }
`;

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function TimeLineCarouselSteps(props: {
    id_proposicao: number,
    index: number,
    activeTab: number,
    proposicao_integrada?: boolean,
    onChange?: any
}) {
    const { pallet } = usePallet();
    const [totalLista, setTotalLista] = React.useState([]);
    const TamanhoTela = window.screen.width

    useEffect(() => {
        if (props.activeTab === props.index) {
            loadTimeline(props.id_proposicao)
        }
    }, [props.activeTab]);

    useEffect(() => {
        setValue(totalLista.length - 1)
    }, [totalLista]);

    async function loadTimeline(id_proposicao: number) {
        await ProposicaoService.GetTimeLine(id_proposicao)
            .then(res => {
                console.log("resposta timeline", res)
                setTotalLista(res?.lista)
            })
    }

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const customTab = (tabName, lengthNumber) => {
        return (
            <>
                <Grid container>
                    <Grid style={{ fontSize: '12px', backgroundColor: 'white', borderRadius: '50%', width: '18px', paddingTop: '1.5px', marginRight: '5px' }}>
                        {lengthNumber}
                    </Grid>
                    <Grid>
                        {tabName}
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <div
        >
            {totalLista?.length > 0
                ?
                <Box sx={{ bgcolor: 'background.paper', paddingTop: '0px', marginTop: '0px' }}>
                    <AppBar elevation={0} position="static" style={{ paddingTop: '0px', marginTop: '0px' }}>
                        <CustomTabs
                            centered={true}
                            scrollButtons={true}
                            value={value}
                            onChange={handleChange}
                            variant={TamanhoTela < 600 ? `scrollable` : null}
                            aria-label="scrollTabs"
                            style={{ backgroundColor: `${pallet.backgroundColorQuaternary}` }}
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                            color={`${pallet.general.color1}`}
                            colorBase={'#b0b1b3'}
                        >
                            {totalLista?.map((x, index) => <Tab label={customTab(x.sigla, x.eventos.length)} {...a11yProps(index)} />)}
                        </CustomTabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        {totalLista?.map((e, index) => {
                            return (
                                <>
                                    <TabPanel value={value} index={index} dir={theme.direction}>
                                        <Grid>
                                            <ContentStepTimeLine eventos={e.eventos} />
                                        </Grid>
                                    </TabPanel>
                                </>
                            )
                        })}
                    </SwipeableViews>
                </Box>
                :
                <Grid
                    style={{
                        color: `${pallet.textColorQuaternary}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontWeight: 600,
                        textAlign: "center",
                        paddingBottom: "50px",
                        paddingTop: "50px"
                    }}
                >
                    <EmptyError title="Ops!" description="Não existe informação para timeline desta proposição" />
                </Grid>
            }
        </div>

    );
}