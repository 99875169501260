import { DataUsageRounded } from '@material-ui/icons';
import * as http from '../helpers/http';
import {IComentario} from "../models/Proposicao.Model"

class ComentarioService {

    Create = async (id:string, data: any): Promise<number> => {
        const result = await http.post(`/proposicao/acao-preparatoria/${id}/comentario/`, data);
        if (result.success) { return result.data }
        return 0;
    };

    Update = async (id:string, data: any): Promise<number> => {
        const result = await http.put(`/proposicao/acao-preparatoria/comentario/${id}`, data);
        if (result.success) { return result.data }
        return 0;
    };

    Delete = async (id:string): Promise<number> => {
        const result = await http.del(`/proposicao/acao-preparatoria/comentario/${id}`);
        if (result.success) { return result.data }
        return 0;
    };


    getComentario = async  (id: string, filter: { key: string, value: any }[],pageIndex: number, pageSize: number = 8): Promise<{ data: [IComentario] }> => {
        const result = await http.get(`/proposicao/acao-preparatoria/${id}/comentario/?filter=${JSON.stringify(filter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) { return result.data }
        return undefined;
    };

    getResposta = async  (id: string, filter: { key: string, value: any }[],pageIndex: number, pageSize: number = 8): Promise<{ data: [IComentario] }> => {
        const result = await http.get(`/proposicao/acao-preparatoria/comentario/${id}/respostas/?filter=${JSON.stringify(filter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) { return result.data }
        return undefined;
    };

}

export default new ComentarioService();