import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Divider, ListItemText, Switch, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import InputDatePicker from "../../../components/InputDatePicker";
import InputMultilineTextField from "../../../components/InputMultilineTextField";
import AnexosComponent, { EAnexoModulos } from "../../../components/Anexos";
import SolidButton from "../../../components/SolidButton";
import { isMobile } from "react-device-detect";
import EditIcon from "@mui/icons-material/Edit";
import ProposicaoAcontecimentosService from "../../../services/ProposicaoAcontecimentosService";
import { useAlert } from "../../../contexts/AlertContext";
import RoomIcon from "@mui/icons-material/Room";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InputTextField from "../../../components/InputTextField";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { formatDate } from "../../../utils/dateUtils";
import { format } from "date-fns";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import * as Yup from "yup";
import CitacoesService from "../../../services/CitacoesService";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ImageLoader from "../../../components/ImageLoader";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import UsuarioService from "../../../services/UsuarioService";
import TabelasService from "../../../services/TabelasService";
import { IUser } from "../../../models/User.Model";
import UsuarioCreateSchema from "../../proposicao/models/UsuarioCreateSchema";
import UsuarioCreateSchemaII from "../../proposicao/models/UsuarioCreateSchemaII";
import { useAuthorization } from "../../../contexts/AuthorizationContext";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function CustomizedDialogs(props: {
  reloadList?: () => void;
  closeMenu?: () => void;
  id_usuario: number;
}) {
  const { NewAlert } = useAlert();
  const [open, setOpen] = React.useState(false);
  const [statusUsuario, setStatusUsuario] = React.useState(true);
  const { pallet } = usePallet();
  const [dateAcontecimentos, setDateAcontecimentos] = React.useState();
  const [dataAcontecimentosAgenda, setDataAcontecimentosAgenda] =
    React.useState();
  const [nome, setNome] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [usuario, setUsuario] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [permissao, setPermissao] = React.useState(0);
  const [situacao, setSituacao] = React.useState(0);
  const [departamento, setDepartamento] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const [listaDepartamento, setListaDepartamento] = React.useState([]);
  const [confirmacaoEmail, setConfirmacaoEmail] = React.useState<boolean>(false);
  const [confirmacaoAlerta, setConfirmacaoAlerta] = React.useState<boolean>(false);
  const [confirmacaoWhats, setConfirmacaoWhats] = React.useState<boolean>(false);
  const [listaPermissao] = React.useState([
    {
      key: 1,
      label: "Master",
      value: 1
    },
    {
      key: 2,
      label: "Normal",
      value: 2
    },
    {
      key: 3,
      label: "Read Only",
      value: 3
    },
    {
      key: 4,
      label: "Esforço Colaborativo",
      value: 4
    }

  ]);
  const [onSave, setOnSave] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [idRef, setIdRef] = React.useState();
  const [idAgRef, setIdAgRef] = React.useState<string>();
  const [data, setData] = React.useState({});
  const [horas, setHoras] = React.useState<string>();
  const [local, setLocal] = React.useState<string>();
  const [error, setError] = React.useState<
    Array<{ name: string; err: string }>
  >([]);
  const [imageGet, setImageGet] = React.useState("");
  const [imageSelected, setImageSelected] = React.useState({});
  const { user, getConfig } = useAuthorization();
  const [dataExpiracao, setDataExpiracao] = React.useState<any>();


  const handleDataAcontecimentosAgendaChange = (dataAcEve) => {
    setDataAcontecimentosAgenda(dataAcEve);
  };

  const handleConfirmacaoEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = !!event.target.checked;
    setConfirmacaoEmail(value);
  };

  const handleConfirmacaoAlertaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = !!event.target.checked;
    setConfirmacaoAlerta(value);
  };

  const handleConfirmacaoWhatsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = !!event.target.checked;
    setConfirmacaoWhats(value);
  };

  const handleTelefoneOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTelefone(event.target.value);
  };


  React.useEffect(() => {
    TabelasService.getTabelaDepartamentos().then(async (res) => {
      const data = [];
      data.push({ key: "", label: "-- Selecione -- ", value: "" });
      res?.map((item) =>
        data.push({
          key: item.descricao,
          label: item.descricao,
          value: item.id,
        })
      );
      setListaDepartamento(() => [...data]);
    });
  }, []);

  const handleDepartamentoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepartamento(event.target.value);
  };
  const handlePermissaoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPermissao(Number(event.target.value));
  };

  const handleOnChangeStatus = () => {
    if (status === true) {
      setStatus(false);
      setSituacao(0);
    }
    else {
      setStatus(true);
      setSituacao(1);
    }
  };

  const handleClickOpen = async () => {

    UsuarioService
      .Get(Number(props.id_usuario))
      .then((res) => {
        if (res) {
          setNome(res[0]?.nome);
          setEmail(res[0]?.email);
          setUsuario(res[0]?.login);
          setStatus(res[0]?.status);
          setImageGet(res[0]?.arquivo)
          setTelefone(res[0]?.telefone)
          setPermissao(res[0]?.id_permissao);
          setSituacao(res[0]?.situacao);
          setDepartamento(String(res[0]?.dep_id));
          setDataExpiracao(res[0]?.ts_expiracao);
          setConfirmacaoEmail(res[0]?.notificacao_email);
          setConfirmacaoAlerta(res[0]?.notificacao_portal);
          setConfirmacaoWhats(res[0]?.notificacao_whatsapp);

        }

      });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onImageSelect = (file) => {
    setImageSelected(file);
  };
  const handleChangeNome = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNome(event.target.value);
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleChangeUsuario = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsuario(event.target.value);
  };
  const handleChangeSenha = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSenha(event.target.value);
  };

  
  const handleDataExpiracaoChange = (prazo) => {
    setDataExpiracao(prazo)
};

  const postCadastro = async (e) => {
    e.preventDefault();

    const data = {
      nome: nome,
      email: email,
      usuario: usuario,
      password: senha,
      telefone: telefone,
      id_permissao: permissao,
      situacao: situacao,
      id_departamento: departamento,
      imagem: imageSelected,
      dataExpiracao: dataExpiracao,
      confirmacaoEmail : confirmacaoEmail,
      confirmacaoAlerta : confirmacaoAlerta,
      confirmacaoWhats : confirmacaoWhats

      
    };

    const dataII = {
      nome: nome,
      email: email,
      usuario: usuario,
      id_permissao: permissao,
      situacao: situacao,
      telefone: telefone,
      id_departamento: departamento,
      imagem: imageSelected,
      dataExpiracao: dataExpiracao,
      confirmacaoEmail : confirmacaoEmail,
      confirmacaoAlerta : confirmacaoAlerta,
      confirmacaoWhats : confirmacaoWhats
    };

    setError(() => []);
    if (senha === '') {

      dataII &&
        UsuarioCreateSchemaII.validate(dataII, { abortEarly: false }).catch((err) =>
          setError(() => err.errors)
        );
      setData(dataII);
      UsuarioCreateSchemaII.validate(dataII).catch(function (err) { });

    } else {
      data &&
        UsuarioCreateSchema.validate(data, { abortEarly: false }).catch((err) =>
          setError(() => err.errors)
        );
      setData(data);
      UsuarioCreateSchema.validate(data).catch(function (err) { });

    }
    

    if (senha === '') {

      if (UsuarioCreateSchemaII.isValidSync(dataII)) {
        await UsuarioService.UpdateGer(Number(props.id_usuario), dataII)

          .then(res => {
            if (res) {
              props?.reloadList()
              handleClose()
              NewAlert('success', 'Usuário alterado com sucesso')
              props?.closeMenu()
            } else {
              NewAlert('error', 'Erro ao alterar, verifique os campos e salve novamente');
            }
          })


      } else {


        if (UsuarioCreateSchema.isValidSync(data)) {
          await UsuarioService.UpdateGer(Number(props.id_usuario), data)

            .then(res => {
              if (res) {
                props?.reloadList()
                handleClose()
                NewAlert('success', 'Usuário alterado com sucesso')
                props?.closeMenu()
              } else {
                NewAlert('error', 'Erro ao alterar, verifique os campos e salve novamente');
              }
            })
        }

      }

    }

  }

  async function handleOnAfterUpload() {
    if (idRef) {
      setIdRef(undefined);
      setOnSave(false);
    }
  }
  const ErrorMessage = (props: {
    name: string;
    errors: Array<{ name: string; err: string }>;
  }) => {
    const itemError = props.errors.filter((err) => err.name === props.name);
    return (
      <div style={{ color: "red", fontSize: "11px", marginTop: "5px" }}>
        {itemError.length === 1 && itemError[0].err}
      </div>
    );
  };
  
  return (
    <div>


      <Grid onClick={() => {user.id_permissao !== 3 ?

        handleClickOpen()
        :
        NewAlert(
          "error",
          "Não é possível realizar está ação com este perfil.")

      }}>
        <Grid  style={{fontFamily: `${pallet.fontFamily.general}`,
          fontWeight: pallet.fontWeight.button,
          fontSize: `${pallet.general.size14}`,
          color: `${pallet.color.tertiary.font}`,
          borderRadius: "6px",
          textTransform: "capitalize"}}>

        <EditIcon style={{ fontSize: "15", color: "grey" }} /> Editar informações</Grid>
      </Grid>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Grid
            style={{

              padding: "12px",
              marginTop: "7px",
            }}
          >
            EDITAR USUÁRIO
          </Grid>
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ padding: "0px" }}>
          <Grid
            container
            style={{
              fontFamily: pallet.fontFamily.general,
              padding: "25px 20px 20px 40px",
            }}
          >
            FOTO DE PERFIL
            <Grid container alignItems="center" direction="row">
              <Grid item>
                <ImageLoader
                  src={imageGet}
                  pallet={pallet}
                  canEdit={true}
                  onLoad={onImageSelect}
                />
              </Grid>
              <Grid
                item
                style={{
                  marginLeft: `${isMobile ? "0px" : "40px"}`,
                  marginTop: `${isMobile ? "10px" : "0px"}`,
                }}
              >
                <Grid container>
                  <Grid
                    item
                    style={{
                      fontSize: `${pallet.general.size12}`,
                      fontWeight: pallet.general.weight_600,
                      color: `${pallet.textColorSecondary}`,
                    }}
                  >
                    Tamanho máximo:
                  </Grid>
                  <Grid
                    item
                    style={{
                      fontSize: `${pallet.general.size12}`,
                      fontWeight: pallet.general.weight_100,
                      color: `${pallet.textColorSecondary}`,
                      marginLeft: "3px",
                    }}
                  >
                    2MB
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    style={{
                      fontSize: `${pallet.general.size12}`,
                      fontWeight: pallet.general.weight_600,
                      color: `${pallet.textColorSecondary}`,
                    }}
                  >
                    Formatos Suportados:
                  </Grid>
                  <Grid
                    item
                    style={{
                      fontSize: `${pallet.general.size12}`,
                      fontWeight: pallet.general.weight_100,
                      color: `${pallet.textColorSecondary}`,
                      marginLeft: "3px",
                    }}
                  >
                    JPG, PNG
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}>
              <Grid>
                <InputTextField
                  type={"text"}
                  value={nome}
                  name="nome"
                  onChange={handleChangeNome}
                  id={"nome-completo"}
                  label={"NOME COMPLETO"}
                />
              </Grid>
              <Grid style={{ color: 'red', fontSize: '10px' }}>
                <ErrorMessage name='nome' errors={error} />
              </Grid>
            </Grid>
            <Grid style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}>
              <Grid>
                <SelectTextFieldA
                  name={"departamento"}
                  value={departamento}
                  onChange={handleDepartamentoOnChange}
                  options={listaDepartamento}
                  label={"UNIDADE DE NEGÓCIO"}
                  id={"departamento"}
                  focused={true}
                />
              </Grid>
              <Grid style={{ color: 'red', fontSize: '10px' }}>
                <ErrorMessage name='id_departamento' errors={error} />
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}
            >
            {user.id_permissao === 1 ?
            <InputDatePicker
            onChange={handleDataExpiracaoChange}
            label={"DATA DE EXPIRAÇÃO"}
            defaultValue={null}
            inputFormat={"dd/MM/yyyy"}
            value={dataExpiracao}
            id={"data-expiracao"}

            
        />
            :
          <InputTextField
                  type={"text"}
                  value={ moment(dataExpiracao).format("DD/MM/YYYY")}
                  disabled={true}
                  onChange={handleDataExpiracaoChange}
                  id={"data-expiracao"}
                  label={"DATA DE EXPIRAÇÃO"}
                />
            }
        </Grid>
            
            <Grid style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={12}
              xs={12}>
              <Grid>
                <InputTextField
                  type={"email"}
                  value={email}
                  name="email"
                  onChange={handleChangeEmail}
                  id={"email"}
                  label={"EMAIL"}
                />
              </Grid>
              <Grid style={{ color: 'red', fontSize: '10px' }}>
                <ErrorMessage name='email' errors={error} />
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}
            >
              <InputTextField
                type={"text"}
                value={usuario}
                name="usuario"
                onChange={handleChangeUsuario}
                id={"usuario"}
                label={"USUÁRIO"}
              />
            </Grid>
            {user.id_permissao !== 4  ?
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}
            >
              <InputTextField
                type={"text"}
                value={telefone}
                name="telefone"
                onChange={handleTelefoneOnChange}
                id={"telefone"}
                label={"TELEFONE"}
                maxLenght={12}
              />
            </Grid>
            :
            null
            }
            {/* <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}>
              <Grid>
                <InputTextField
                  type={"password"}
                  value={senha}
                  name="senha"
                  onChange={handleChangeSenha}
                  id={"senha"}
                  label={"SENHA"}
                />
              </Grid>
              <Grid style={{ color: 'red', fontSize: '10px' }}>
                <ErrorMessage name='senha' errors={error} />
              </Grid>
            </Grid> */}
            <Grid style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}
            >
              <Grid>
                <SelectTextFieldA
                  name={"permissao"}
                  value={permissao}
                  onChange={handlePermissaoOnChange}
                  options={listaPermissao}
                  label={"PERMISSÃO"}
                  id={"permissao"}
                  focused={true}
                />
              </Grid>
              <Grid style={{ color: 'red', fontSize: '10px' }}>
                <ErrorMessage name='id_permissao' errors={error} />
              </Grid>
            </Grid>

            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}
            >
              <Switch
                checked={status}
                onChange={() => handleOnChangeStatus()}
              />
              Usuário Ativo
            </Grid>
            <Grid
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "2px",
                marginLeft: "2px",
              }}
            >
              <ErrorMessage name="keyword" errors={error} />
            </Grid>
            
            <Grid
              style={{ marginTop: "5px", padding: "10px" }}
              item
              lg={12}
              xs={12}
            >
              {getConfig()?.habNotEmail ?  
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={confirmacaoEmail}
                              onChange={handleConfirmacaoEmailChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Receber por email."
                        />
                      </FormGroup>
                      :
                      null
                        }
                      {
                        getConfig()?.habNotPush ?  
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                            checked={confirmacaoAlerta}
                              onChange={handleConfirmacaoAlertaChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Receber por alerta do portal(navegador de internet)."
                        />
                      </FormGroup>
                      :
                      null
                      }
                      {
                        getConfig()?.habNotWhat ? 
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                            checked={confirmacaoWhats}
                              onChange={handleConfirmacaoWhatsChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Receber por WhatsApp."
                        />
                      </FormGroup>
                      :
                      null  
                      }

            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            padding: "30px",
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
          }}
        >
          <ButtonDialogConfirmation
            styles={{
              borderRadius: "6px 6px 0px 0px",
              marginRight: "20px",
              textTransform: "capitalize",
              color: `${pallet.general.color1}`,
            }}
            title={"CONFIRMAÇÃO"}
            title_button={"Cancelar"}
            content={"CANCELAR CADASTRO?"}
            message={"Esta ação não poderá ser desfeita."}
            confirmation_button={"Cancelar"}
            confirmationEvent={() => {
              handleClose();
            }}
          />
          <SolidButton
            onClick={postCadastro}
            onChange={props?.reloadList}
            color={`${pallet.textColorPrimary}`}
            backgroundColor={`${pallet.backgroundColorPrimary}`}
            fontSize={`${pallet.general.size14}`}
            title={"SALVAR"}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
