import React, { useCallback, useEffect, useState } from 'react';
import styled from "styled-components";
import Main from '../main/Main';
import useStyles from '../../components/layout/Styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { isMobile } from 'react-device-detect';
import { IFilterData } from '../../components/filter/ListFilter';

import ComboBox from '../../components/ComboBox';
import Button from '@mui/material/Button';
import ButtonOutLine from '../../components/ButtonOutline';
import { usePallet } from '../../contexts/PalletContext';
import { Box, Link, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import PieChartInfoCard from '../../components/PieChartInfoCard';
import filtersBackgroundImage from '../../../src/img/background_filters_dashboard.png';
import { useDashboard } from '../../contexts/DashboardContext';
import ParlamentarService from '../../services/ParlamentarService';
import { useAuthorization } from "../../contexts/AuthorizationContext";


const defaultFilter: IFilterData[] = [
    { key: 'esfera', label: 'ESFERA: Federal', value: 'Federal' },
    { key: 'legislatura', label: 'LEGISLATURA: 57', value: '57' },
    { key: 'situacao', label: 'Exercício', value: 'Exercício' },
]

const FilterArea = styled(Paper)`
    width: 100%;
    /* height: 55px; */
    margin-top: 16px;
    margin-bottom: 10px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffff;
`;

const FilterQuantity = styled(Typography)`
    text-transform: uppercase;
    font-weight: ${(props => props.pallet.general.weight_500)};
    color: ${(props => props.pallet.color.colorBase.labelTextFild)};
    font-size: ${(props => props.pallet.general.size2)};
    display: flex;
    align-items: center;

    >strong {
        color: ${(props => props.pallet.textColorQuaternary)};
        font-weight: ${(props => props.pallet.general.weight_500)};

        &:first-child {
            font-weight: bold;
        }
    }
`;

const FilterQuantityInput = styled(TextField)`
     margin: 0 10px;
 
     .MuiOutlinedInput-input {
         width: 30px;
         padding-top: 10px;
         padding-bottom: 10px;
 
         svg {
             color: ${(props => props.pallet.textColorQuaternary)};
         }
    }
`;


const FilterButton = styled(Button)`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    color:  #160342;
    border: 0;
    font-size: 14px;
    line-height: 17px;
    background: rgba(75, 17, 150, 0.1);
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    height: 100%;
`;

const ContainerHead = styled(Grid)`
    margin-bottom: 10px;
    margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
    margin-bottom: 30px;
`;

const ContainerCombo = styled(Grid)`
  width: 100%;
  margin-bottom: 5px;
`;

const ContainerFilter = styled(Grid)`
  /* width: 10%; */
  width: 100%;
  margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
  justify-content: ${isMobile ? 'center' : 'space-between'};
`;

const ContainerCard = styled(Grid)`
  margin-top: 20px;
`;

const ContainerFooter = styled(Grid)`
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
`;

export default function ParlamentarDashboard() {
    const { pallet } = usePallet();


    const classes = useStyles();

    const [nome, setNome] = useState<string>();
    const [nomeParcial, setNomeParcial] = useState<string>('');
    const [listaNomes, setListaNomes] = useState<string[]>([]);

    const [openFilter, setOpenFilter] = React.useState<boolean>(false);
    const handleToggleFilter = () => setOpenFilter(!openFilter);
    const { user } = useAuthorization();

    const [sumaryFedCD, setsumaryFedCD] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });
    const [sumaryFedSG, setsumaryFedSG] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });
    const [sumaryEstLG, setsumaryEstLG] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });
    const [sumaryEstEX, setsumaryEstEX] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });
    const [sumaryMunLG, setsumaryMunLG] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });
    const [sumaryMunEX, setsumaryMunEX] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });

    const [EsfSel, setEsfSel] = useState<string>('F');

    useEffect(() => {
        getData();
        // eslint-disable-next-line 
    }, [EsfSel])

    const getData = useCallback(() => {
        switch (EsfSel) {
            case 'F':

                ParlamentarService.GetSummary([
                    { "key": "esfera", "value": "F" },
                    { "key": "casa", "value": "CD" },
                    { "key": "legislatura", "value": "57" },
                    { "key": "situacao", "value": "Exercício" }
                ]).then(async res => {
                    if (res) {
                        setsumaryFedCD(res);
                    }
                });

                ParlamentarService.GetSummary([
                    { "key": "esfera", "value": "F" },
                    { "key": "casa", "value": "SF" },
                    { "key": "legislatura", "value": "57" },
                    { "key": "situacao", "value": "Exercício" }
                ]).then(async res => {
                    if (res) {
                        setsumaryFedSG(res);
                    }
                });

                break;
            case 'E':
                //testando
                ParlamentarService.GetSummary([
                    { "key": "esfera", "value": "E" },
                    { "key": "poder", "value": "L" }
                ]).then(async res => {
                    if (res) {
                        setsumaryEstLG(res);
                    }
                });

                ParlamentarService.GetSummary([
                    { "key": "esfera", "value": "E" },
                    { "key": "poder", "value": "E" }
                ]).then(async res => {
                    if (res) {
                        setsumaryEstEX(res);
                    }
                });
                break;
            case 'M':

                ParlamentarService.GetSummary([
                    { "key": "esfera", "value": "M" },
                    { "key": "poder", "value": "L" }
                ]).then(async res => {
                    if (res) {
                        setsumaryMunLG(res);
                    }
                });

                ParlamentarService.GetSummary([
                    { "key": "esfera", "value": "M" },
                    { "key": "poder", "value": "E" }
                ]).then(async res => {
                    if (res) {
                        setsumaryMunEX(res);
                    }
                });
                break;
            //default:

        }

    }, [EsfSel])

    const {
        universo_ativosValue_chart,
        universo_inativosValue_chart,
        casasLegislativas_congressoNacionalValue_chart,
        casasLegislativas_camaraDosDeputadosValue_chart,
        casasLegislativas_senadoFederalValue_chart,
    } = useDashboard()


    const handleEsfera = (event, newSelEsf) => {
        setEsfSel(newSelEsf);
    }

    let filtro = [{ "key": "esfera", "label": "ESFERA: Federal", "value": "Federal" }, { "key": "casa", "label": "CASA: Câmara", "value": "2" }, { "key": "legislatura", "label": "LEGISLATURA: 57", "value": "57" }, { "key": "situacao", "label": "SITUACAO: Exercício", "value": "Exercício" }, { "key": "qualificacao", "label": "QUALIFICACAO: Convergente", "value": "Convergente" }];

    return (
        <div className={classes.root} >
            <Main>
                <>
                    <ContainerHead container >
                        <ContainerSubHead container alignItems="center" justifyContent="space-between">
                            <Grid style={{
                                color: `${pallet.textColorSecondary}`,
                                fontWeight: pallet.general.weight_600,
                                fontSize: "24px",
                                fontFamily: `${pallet.general.fontFamily}`
                            }}>
                                Interlocutores
                            </Grid>
                            <Grid>
                                <ButtonOutLine
                                    path="/interlocutor/cadastro"
                                />
                            </Grid>
                        </ContainerSubHead>

                        {/* <Grid container>
                            <Grid style={{ width: '100%' }}>
                                <ComboBox
                                    name='filtroNome'
                                    itens={listaNomes}
                                    onChange={setNomeParcial}
                                    onSelect={setNome}
                                    placeholder="Buscar"
                                />
                            </Grid>
                        </Grid> */}
                        <Grid style={{
                            width: isMobile ? '60%' : '100%',
                        }} />


                        {/* </Grid> */}
                    </ContainerHead>
                    <ContainerBody container>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper} style={{
                                    backgroundImage: `url(${filtersBackgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>

                                    <Grid style={{ marginBottom: '5px' }}>
                                        <Typography
                                            style={{
                                                color: `${pallet.color.secundary.font}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.subtitle,
                                            }}>
                                            ESFERAS
                                        </Typography>
                                    </Grid>

                                    <Grid container alignItems="center" direction="row">
                                        <Grid item>
                                            <Box display="block">

                                                <Grid style={{ backgroundColor: "#fcfcfc", borderRadius: "4px" }}>
                                                    <ToggleButtonGroup
                                                        value={EsfSel}
                                                        exclusive={true}
                                                        onChange={handleEsfera}
                                                        aria-label="sphere"
                                                        size="small"

                                                    >

                                                        <ToggleButton
                                                            value="F"
                                                            aria-label="device"
                                                            sx={{
                                                                '&.Mui-selected': {
                                                                    color: `${pallet.textColorPrimary}`,
                                                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                                }
                                                            }}

                                                        >
                                                            <Typography style={{
                                                                fontSize: "14px",
                                                                textTransform: 'capitalize',
                                                                paddingLeft: "4px",
                                                                paddingRight: "4px",
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.general.weight_600
                                                            }}>
                                                                Federal
                                                            </Typography>
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value="E"
                                                            aria-label="device"
                                                            sx={{
                                                                '&.Mui-selected': {
                                                                    color: `${pallet.textColorPrimary}`,
                                                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                                }
                                                            }}

                                                        >
                                                            <Typography style={{
                                                                fontSize: "14px",
                                                                textTransform: 'capitalize',
                                                                paddingLeft: "4px",
                                                                paddingRight: "4px",
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.general.weight_600
                                                            }}>
                                                                Estadual
                                                            </Typography>
                                                        </ToggleButton>

                                                        <ToggleButton
                                                            value="M"
                                                            aria-label="device"
                                                            sx={{
                                                                '&.Mui-selected': {
                                                                    color: `${pallet.textColorPrimary}`,
                                                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                                }
                                                            }}

                                                        >
                                                            <Typography style={{
                                                                fontSize: "14px",
                                                                textTransform: 'capitalize',
                                                                paddingLeft: "4px",
                                                                paddingRight: "4px",
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.general.weight_600
                                                            }}>
                                                                Municipal
                                                            </Typography>
                                                        </ToggleButton>

                                                    </ToggleButtonGroup>
                                                </Grid>
                                                {/* <SpheresFilter
                                                    isFilter={
                                                        [
                                                            { text: "Federal" },
                                                            { text: "Estadual" },
                                                            { text: "Municipal" },
                                                        ]
                                                    }
                                                    
                                                //onChange={}
                                                /> */}
                                            </Box>
                                        </Grid>


                                    </Grid>
                                </Paper>
                            </Grid>



                            {EsfSel === 'F' && user.id_permissao !== 4 ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                                    <PieChartInfoCard
                                        title="QUALIFICAÇÃO"
                                        subtitle="CÂMARA GERAL"
                                        labels={[
                                            {
                                                key: "Convergente", data: sumaryFedCD?.convergente, color: `${pallet.color.charts.green}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"casa","label":"CASA: Câmara","value":"2"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"situacao","label":"SITUACAO: Exercício","value":"Exercício"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"}]'
                                            },
                                            {
                                                key: "Divergente", data: sumaryFedCD?.divergente, color: `${pallet.color.charts.red}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"casa","label":"CASA: Câmara","value":"2"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"situacao","label":"SITUACAO: Exercício","value":"Exercício"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"}]'
                                            },
                                            {
                                                key: "Neutro", data: sumaryFedCD?.neutro, color: `${pallet.color.charts.lightGrey}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"casa","label":"CASA: Câmara","value":"2"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"situacao","label":"SITUACAO: Exercício","value":"Exercício"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"}]'
                                            },
                                            {
                                                key: "Não Classificado", data: sumaryFedCD?.naoClassificado, color: `${pallet.color.charts.blue}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"casa","label":"CASA: Câmara","value":"2"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"situacao","label":"SITUACAO: Exercício","value":"Exercício"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"}]'
                                            },
                                        ]}
                                    />
                                </Grid>
                                :
                                null
                            }

                            {EsfSel === 'F' && user.id_permissao !== 4 ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: "40px" }}>
                                    <PieChartInfoCard
                                        title="QUALIFICAÇÃO"
                                        subtitle="SENADO GERAL"
                                        labels={[
                                            {
                                                key: "Convergente", data: sumaryFedSG?.convergente, color: `${pallet.color.charts.green}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"casa","label":"CASA: Senado","value":"1"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"situacao","label":"SITUACAO: Exercício","value":"Exercício"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"}]'
                                            },
                                            {
                                                key: "Divergente", data: sumaryFedSG?.divergente, color: `${pallet.color.charts.red}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"casa","label":"CASA: Senado","value":"1"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"situacao","label":"SITUACAO: Exercício","value":"Exercício"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"}]'
                                            },
                                            {
                                                key: "Neutro", data: sumaryFedSG?.neutro, color: `${pallet.color.charts.lightGrey}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"casa","label":"CASA: Senado","value":"1"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"situacao","label":"SITUACAO: Exercício","value":"Exercício"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"}]'
                                            },
                                            {
                                                key: "Não Classificado", data: sumaryFedSG?.naoClassificado, color: `${pallet.color.charts.blue}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"casa","label":"CASA: Senado","value":"1"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"situacao","label":"SITUACAO: Exercício","value":"Exercício"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"}]'
                                            },
                                        ]}
                                    />
                                </Grid>
                                :
                                null
                            }

                            {EsfSel === 'E' && user.id_permissao !== 4 ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                                    <PieChartInfoCard
                                        title="QUALIFICAÇÃO"
                                        subtitle="EXECUTIVO"
                                        labels={[
                                            {
                                                key: "Convergente", data: sumaryEstEX?.convergente, color: `${pallet.color.charts.green}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"},{"key":"poder","label":"PODER: Executivo","value":"E"}]'
                                            },
                                            {
                                                key: "Divergente", data: sumaryEstEX?.divergente, color: `${pallet.color.charts.red}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"}, {"key":"poder","label":"PODER: Executivo","value":"E"}]'
                                            },
                                            {
                                                key: "Neutro", data: sumaryEstEX?.neutro, color: `${pallet.color.charts.lightGrey}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"},{"key":"poder","label":"PODER: Executivo","value":"E"}]'
                                            },
                                            {
                                                key: "Não Classificado", data: sumaryEstEX?.naoClassificado, color: `${pallet.color.charts.blue}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"}, {"key":"poder","label":"PODER: Executivo","value":"E"}]'
                                            },
                                        ]}
                                    />
                                </Grid>
                                :
                                null
                            }

                            {EsfSel === 'E' && user.id_permissao !== 4 ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: "40px" }}>
                                    <PieChartInfoCard
                                        title="QUALIFICAÇÃO"
                                        subtitle="LEGISLATIVO"
                                        labels={[
                                            {
                                                key: "Convergente", data: sumaryEstLG?.convergente, color: `${pallet.color.charts.green}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"},{"key":"poder","label":"PODER: Legislativo","value":"L"}]'
                                            },
                                            {
                                                key: "Divergente", data: sumaryEstLG?.divergente, color: `${pallet.color.charts.red}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"},{"key":"poder","label":"PODER: Legislativo","value":"L"}]'
                                            },
                                            {
                                                key: "Neutro", data: sumaryEstLG?.neutro, color: `${pallet.color.charts.lightGrey}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"},{"key":"poder","label":"PODER: Legislativo","value":"L"}]'
                                            },
                                            {
                                                key: "Não Classificado", data: sumaryEstLG?.naoClassificado, color: `${pallet.color.charts.blue}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"},{"key":"poder","label":"PODER: Legislativo","value":"L"}]'
                                            },
                                        ]}
                                    />
                                </Grid>
                                :
                                null
                            }


                            {EsfSel === 'M' && user.id_permissao !== 4 ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                                    <PieChartInfoCard
                                        title="QUALIFICAÇÃO"
                                        subtitle="EXECUTIVO"
                                        labels={[
                                            {
                                                key: "Convergente", data: sumaryMunEX?.convergente, color: `${pallet.color.charts.green}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"},{"key":"poder","label":"PODER: Executivo","value":"E"}]'
                                            },
                                            {
                                                key: "Divergente", data: sumaryMunEX?.divergente, color: `${pallet.color.charts.red}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"},{"key":"poder","label":"PODER: Executivo","value":"E"}]'
                                            },
                                            {
                                                key: "Neutro", data: sumaryMunEX?.neutro, color: `${pallet.color.charts.lightGrey}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"},{"key":"poder","label":"PODER: Executivo","value":"E"}]'
                                            },
                                            {
                                                key: "Não Classificado", data: sumaryMunEX?.naoClassificado, color: `${pallet.color.charts.blue}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"},{"key":"poder","label":"PODER: Executivo","value":"E"}]'
                                            },
                                        ]}
                                    />
                                </Grid>
                                :
                                null
                            }

                            {EsfSel === 'M' && user.id_permissao !== 4 ?
                                // (sumaryMunLG.convergente > 0 && sumaryMunLG.divergente > 0 && sumaryMunLG.neutro > 0 && sumaryMunLG.naoClassificado > 0) ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: "40px" }}>
                                    <PieChartInfoCard
                                        title="QUALIFICAÇÃO"
                                        subtitle="LEGISLATIVO"
                                        labels={[
                                            {
                                                key: "Convergente", data: sumaryMunLG?.convergente, color: `${pallet.color.charts.green}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value":"Convergente"},{"key":"poder","label":"PODER: Legislativo","value":"L"}]'
                                            },
                                            {
                                                key: "Divergente", data: sumaryMunLG?.divergente, color: `${pallet.color.charts.red}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value":"Divergente"},{"key":"poder","label":"PODER: Legislativo","value":"L"}]'
                                            },
                                            {
                                                key: "Neutro", data: sumaryMunLG?.neutro, color: `${pallet.color.charts.lightGrey}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value":"Neutro"},{"key":"poder","label":"PODER: Legislativo","value":"L"}]'
                                            },
                                            {
                                                key: "Não Classificado", data: sumaryMunLG?.naoClassificado, color: `${pallet.color.charts.blue}`,
                                                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value":"Não Classificado"},{"key":"poder","label":"PODER: Legislativo","value":"L"}]'
                                            },
                                        ]}
                                    />
                                </Grid>
                                /*     :
                                    <Grid
                                        style={{
                                            color: `${pallet.textColorQuaternary}`,
                                            fontSize: `${pallet.fontSize.titleWidgets}`,
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: 600,
                                            textAlign: "center",
                                            paddingBottom: "150px",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <EmptyError title="Ops!" description="Ainda não temos dados suficientes para gerar resultados." />

                                    </Grid>
                                */
                                :
                                null

                            }

                        </Grid>
                    </ContainerBody>
                    {/* <ContainerFooter container>
                        <PaginationSaveState
                            name={'ParlamentarListCurrentPage'}
                            pages={infoList?.totalPages}
                            page={currentPage}
                            onChange={setCurrentPage}
                        />
                    </ContainerFooter> */}
                </>
            </Main>
        </div >
    );
}

