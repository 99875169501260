import * as React from 'react';
import Button from '@mui/material/Button';
import styledC from 'styled-components';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, ListItemText } from '@mui/material';
import { usePallet } from "../../../contexts/PalletContext";
import InputMultilineTextField from '../../../components/InputMultilineTextField';
import SolidButton from '../../../components/SolidButton';
import { isMobile } from 'react-device-detect';
import EditIcon from '@mui/icons-material/Edit';
import { useAlert } from '../../../contexts/AlertContext';
import InputTextFieldSimple from '../../../components/InputTextFieldSimple';
import SelectTextFieldA from '../../../components/SelectTextFieldA';
import TreinamentoIAEditSchema from './TreinamentoIAEditSchema';
import IAService from '../../../services/IAService';

const DotPrioridade = styledC.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 10px;
`;

const DotPosicionamento = styledC.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "10px" : "10px"}; 
`;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function TreinamentoIAEdicao(props: {
    apelido: string,
    prioridade: number,
    posicionamento: number,
    texto: string,
    id: number,
    idPermissao: number,
    reloadList: () => void
    closeMenu: () => void
}) {
    const { NewAlert } = useAlert();
    const [open, setOpen] = React.useState(false);
    const { pallet } = usePallet();
    const [onSave, setOnSave] = React.useState(false)
    const [data, setData] = React.useState({});
    const [error, setError] = React.useState<Array<{ name: string, err: string }>>([]);
    const [prioridade, setPrioridade] = React.useState('');
    const [posicionamento, setPosicionamento] = React.useState<string>();
    const [apelido, setApelido] = React.useState('');
    const [texto, setTexto] = React.useState("");


    React.useEffect(() => {

        const prioridadeLabel = (value: number) => {
            switch (value) {
                case 1:
                    return 'Mínima';
                case 2:
                    return 'Baixa';
                case 3:
                    return 'Média';
                case 4:
                    return 'Alta';
                case 5:
                    return 'Máxima';
                default:
                    return 'Indefinido';
            }
        };

        const posicionamentoLabel = (value: number) => {
            switch (value) {
                case 1:
                    return "Contrário";
                case 2:
                    return "Contrário com emenda saneadora";
                case 3:
                    return "Neutro";
                case 4:
                    return "Favorável com emenda saneadora";
                case 5:
                    return "Favorável";
                case 6:
                    return "Favorável ao parecer do relator";
                case 7:
                    return "Favorável ao parecer do relator com emenda";
                case 8:
                    return "Contrário ao parecer do relator";
                case 9:
                    return "Contrário ao parecer do relator com emenda";
                case 99:
                    return "Pendente";
                default:
                    return `${pallet.charts.yellow}`;
            }
        };

        setApelido(props?.apelido)
        setPosicionamento(posicionamentoLabel(props?.posicionamento))
        setPrioridade(prioridadeLabel(props?.prioridade))
        setTexto(props?.texto)
    }, []);


    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }



    const corPrioridade = (value: string) => {

        switch (value) {
            case 'Máxima':
                return `${pallet.charts.red}`;
            case 'Baixa':
                return `${pallet.charts.blue}`;
            case 'Média':
                return `${pallet.charts.yellow}`;
            case 'Alta':
                return `${pallet.charts.purple}`;
            case 'Mínima':
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }

    const corPosicionamento = (value: string) => {

        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }

    function LabelHandle(props: { prioridade: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPrioridade bg={corPrioridade(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.prioridade}
                    </Grid>
                </Grid>
            </>
        )
    }

    function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.posicionamento}
                    </Grid>
                </Grid>
            </>
        )
    }

    const posicionamentosItens = [
        { key: 0, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "Neutro" },
        { key: 1, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "Contrário com emenda saneadora" },
        { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "Contrário" },
        { key: 3, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "Favorável com emenda saneadora" },
        { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "Favorável" },
        { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "Favorável ao parecer do relator" },
        { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "Favorável ao parecer do relator com emenda" },
        { key: 7, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "Contrário ao parecer do relator" },
        { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "Contrário ao parecer do relator com emenda" },
        { key: 8, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "Pendente" },
        { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "Indefinido" },
    ]

    const prioridades = [
        { key: 0, label: "-- Selecione --", value: "Selecione" },
        { key: 1, label: <LabelHandle prioridade={"Mínima"} bg={"Mínima"} />, value: "Mínima" },
        { key: 2, label: <LabelHandle prioridade={"Baixa"} bg={"Baixa"} />, value: "Baixa" },
        { key: 3, label: <LabelHandle prioridade={"Média"} bg={"Média"} />, value: "Média" },
        { key: 4, label: <LabelHandle prioridade={"Alta"} bg={"Alta"} />, value: "Alta" },
        { key: 5, label: <LabelHandle prioridade={"Máxima"} bg={"Máxima"} />, value: "Máxima" },
    ]


    const handleClickOpen = async (id) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props?.reloadList()
        props?.closeMenu()
    };

    const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTexto(event.target.value)
    };

    const handleTreinamentoIAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApelido(event.target.value)
    }

    const salvarEdicaoTreinamentoIA = async (e) => {
        setOnSave(true)
        e.preventDefault();

        const prioridadeChangeToAPI = (value: string) => {
            switch (value) {
                case 'Selecione':
                    return undefined;
                case 'Mínima':
                    return 1;
                case 'Baixa':
                    return 2;
                case 'Média':
                    return 3;
                case 'Alta':
                    return 4;
                case 'Máxima':
                    return 5;
                default:
                    return 'Indefinido';
            }
        };

        const posicionamentoChangeToAPI = (value: string) => {
            switch (value) {
                case 'Selecione':
                    return undefined;
                case "Contrário":
                    return 1;
                case "Contrário com emenda saneadora":
                    return 2;
                case "Neutro":
                    return 3;
                case "Favorável com emenda saneadora":
                    return 4;
                case "Favorável":
                    return 5;
                case "Favorável ao parecer do relator":
                    return 6;
                case "Favorável ao parecer do relator com emenda":
                    return 7;
                case "Contrário ao parecer do relator":
                    return 8;
                case "Contrário ao parecer do relator com emenda":
                    return 9;
                case "Pendente":
                    return 99;
                default:
                    return `${pallet.charts.yellow}`;
            }
        };

        const data = {
            apelido: apelido,
            prioridade: prioridadeChangeToAPI(prioridade),
            posicionamento: posicionamentoChangeToAPI(posicionamento),
            texto: texto,
            id: props?.id
        }

        setError(() => [])
        data && TreinamentoIAEditSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        TreinamentoIAEditSchema.validate(data).catch(function (err) {
        });

        if (TreinamentoIAEditSchema.isValidSync(data)) {
            await IAService.UpdateTreinamento(data, props?.id)
                .then(res => {
                    if (res) {
                        handleClose()
                        NewAlert('success', 'Texto de Treinamento IA editado com sucesso')
                        props?.reloadList()
                        props?.closeMenu()
                    } else {
                        NewAlert('error', 'Erro ao editar, verifique os campos e salve novamente');
                    }
                })
            // .then(x => loadAcontecimentos(props.id_Proposicao))
        } else {
            NewAlert('error', 'Algum campo está incorreto ou vazio, verifique!');
        }
    }

    const handlePrioridadeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrioridade(event.target.value)
    };

    const handlePosicionamentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosicionamento(event.target.value)
    };

    

    return (
        <div>
            <Button onClick={() => {props.idPermissao !== 3 ? handleClickOpen(props?.id) :  NewAlert("error","Não é possível realizar está ação com este perfil.") }}
                style={{
                    marginLeft: "-18px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    marginRight: "-10px",
                    padding: '0px'
                }}>
                <EditIcon style={{
                    color: `${pallet.color.tertiary.font}`,
                    marginRight: "15px",
                    fontFamily: `${pallet.fontFamily.general}`,
                }} />
                <ListItemText>@</ListItemText>
                Editar
            </Button>
            <BootstrapDialog
                id={`dialog-${props?.id}`}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Grid style={{
                        fontFamily: `${pallet.fontFamily}`,
                        fontSize: `${pallet.general.size18}`,
                        color: `${pallet.textColorSecondary}`,
                        padding: "12px",
                        marginTop: "7px",
                    }}>
                        EDITAR CONTEXTO
                    </Grid>
                </BootstrapDialogTitle>

                <DialogContent dividers style={{ padding: "0px", fontFamily: `${pallet.general.fontFamily}` }} >
                    <Grid style={{ padding: "30px 25px 30px 25px" }}>
                        <Grid style={{ fontSize: `${pallet.general.size14}`, fontWeight: pallet.general.weight_bold, marginBottom: '20px', color: `${pallet.general.color1}` }}>
                            INFORMAÇÕES
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={6} xl={6} xs={12}>
                                <SelectTextFieldA
                                    value={prioridade}
                                    onChange={handlePrioridadeChange}
                                    options={prioridades}
                                    label={"PRIORIDADE"}
                                    id={"prioridade-treinamentoIA"}
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='prioridade' errors={error} />
                                </Grid>
                            </Grid>
                            <Grid item lg={6} xl={6} xs={12}>
                                <SelectTextFieldA
                                    options={posicionamentosItens}
                                    // placeholder={"Selecione departamento ou usuário"}
                                    value={posicionamento}
                                    onChange={handlePosicionamentoChange}
                                    label={"POSICIONAMENTO"}
                                    id={"posicionamento-treinamentoIA"}
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='posicionamento' errors={error} />
                                </Grid>
                            </Grid>
                            <Grid item lg={12} xl={12} xs={12}>
                                <InputTextFieldSimple
                                    type={"text"}
                                    value={apelido}
                                    name="apelido"
                                    onChange={handleTreinamentoIAChange}
                                    id={"apelido-treinamentoIA"}
                                    label={"APELIDO"}
                                    focused
                                    placeholder='Digite'
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='apelido' errors={error} />
                                </Grid>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <InputMultilineTextField
                                    // defaultValue={dataAcontecimentos?.acontecimentos_acontecimento}
                                    type={"text"}
                                    maxRows={4}
                                    value={texto}
                                    onChange={handleDescricaoChange}
                                    id={"texto-treinamentoIA"}
                                    label="CONTEXTO"
                                    focused
                                    placeholder='Digite'
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='texto' errors={error} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions style={{
                    padding: "30px",
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`
                }}>
                    <Grid onClick={() => handleClose()} style={{ cursor: 'pointer', color: `${pallet.backgroundColorPrimary}`, marginRight: '30px' }}>
                        Cancelar
                    </Grid>
                    <SolidButton
                        onClick={salvarEdicaoTreinamentoIA}
                        onChange={props?.reloadList}
                        color={`${pallet.textColorPrimary}`}
                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                        fontSize={`${pallet.general.size14}`}
                        title={"SALVAR"}
                    />
                </DialogActions>
            </BootstrapDialog>
        </div >
    );
}