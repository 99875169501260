import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import StepHourCalendar from '../../agenda/_components/stepHourCalendar'
import { usePallet } from '../../../contexts/PalletContext';

const Root = styled('div')(() => ({
    width: '100%',
    '& > :not(style) + :not(style)': {
        marginTop: "10px",
    },
}));

export default function ConteudoSexta(props: { index: number, activeTab: number, dateStartReference: Date, eventListTodayMaisQuatro: Array<any>, loadEventos: () => void, loading: boolean }) {
    const { pallet } = usePallet();
    return (
        <>
            <div style={{ fontFamily: `${pallet.fontFamily.general}` }}>
                <Root>
                    <Divider>
                        <p style={{ backgroundColor: "white", color: `${pallet.general.color1}`, fontSize: "14px", fontWeight: pallet.general.weight_600 }}>{props?.eventListTodayMaisQuatro?.length}  {props?.eventListTodayMaisQuatro?.length > 1 ? "Eventos" : "Evento"}</p>
                    </Divider>
                </Root>
                <Box style={{ marginTop: "15px", marginLeft: "10px" }}>
                    <StepHourCalendar blocoEvento={props?.eventListTodayMaisQuatro} dateStartReference={props?.dateStartReference} loadEventos={props?.loadEventos} loading={props?.loading} />
                </Box>
            </div>
        </>
    )
}
