import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';
import useStyles from '../../components/layout/Styles';
import { AppBarCustom } from './AppBarCustom';
import { DrawerCustom } from './DrawerCustom';
import { DrawerNotificacao } from './DrawerNotificacao';
import Workspace from '../workspace/Workspace';

export default function Main2() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleToggleOpen = () => setOpen(!open);

    const [openNotificacao, setOpenNotificacao] = React.useState(false);
    const handleToggleNotificacao = () => setOpenNotificacao(!openNotificacao);

    return (
        <>  

             <AppBarCustom classes={classes} open={open} handleToggleOpen={handleToggleOpen} openNotificacao={openNotificacao} toggleNotificacao={handleToggleNotificacao} />
            <Grid style={{display:'flex'}}>
                <Grid style={{width: "4%"}}>
                 <DrawerCustom classes={classes} open={open} handleToggleOpen={handleToggleOpen} />
                </Grid>
                    <Grid style={{width: "96%"}}>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                            <Container maxWidth="lg" className={classes.container}>
                                 <Workspace/>
                            </Container>
                    </main>
                </Grid>
            </Grid>
         
            {/* <DrawerNotificacao classes={classes} open={openNotificacao} handleToggleOpen={handleToggleNotificacao} /> */}

        </>
    )
}

Main2.propTypes = {
    children: PropTypes.node,
};