import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HomeIcon from '@mui/icons-material/Home';
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import PieChartIcon from "@mui/icons-material/PieChart";
import InsightsIcon from "@mui/icons-material/Insights";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ReviewsIcon from "@mui/icons-material/Reviews";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';

export const mainMenu = [
  {
    key: 0,
    title: "ÁREA DE TRABALHO",
    url: "/workspace",
    icon: <HomeIcon fontSize="small" />,
  },
  {
    key: 1,
    title: "PROPOSIÇÕES",
    subTitles: [
      { subkey: 0, namesubTitle: "Lista", url: "/proposicao" },
      { subkey: 1, namesubTitle: "Dashboard", url: "/proposicao/dashboard" },
      { subkey: 2, namesubTitle: "Cadastrar Proposição", url: "/proposicao/cadastro" },
    ],
    icon: <AssignmentIcon fontSize="small" />,
  },
  {
    key: 2,
    title: "INTERLOCUTORES",
    subTitles: [
      { subkey: 0, namesubTitle: "Lista", url: "/interlocutor" },
      { subkey: 1, namesubTitle: "Dashboard", url: "/interlocutor/dashboard" },
      { subkey: 2, namesubTitle: "Cadastrar Interlocutor", url: "/interlocutor/cadastro" },
    ],
    icon: <AccountBoxIcon fontSize="small" />,
  },
  {
    key: 3,
    title: "AGENDA",
    subTitles: [
      { subkey: 0, namesubTitle: "Agenda", url: "/agenda" },
      { subkey: 1, namesubTitle: "Cadastrar Evento", url: "/agenda/cadastro" },
    ],
    icon: <InsertInvitationIcon fontSize="small" />,
  },

  // {
  //   key: 3,
  //   title: "MÍDIA",
  //   subTitles: [
  //     {subkey:0,namesubTitle: "Notícias", url: "/" },
  //     {subkey:1,namesubTitle: "Twitter", url: "/" },
  //   ],
  //   icon: <CampaignIcon fontSize="small" />,
  // },

  {
    key: 4,
    title: "RELATÓRIOS",
    url: '/relatorios',
    subTitles: [
      {subkey:0,namesubTitle: "Específicos", url: "/relatorios" },
      {subkey:1,namesubTitle: "Geral", url: "/relatorios/geral" },
    ],
    icon: <PieChartIcon fontSize="small" />,
  },

  {
    key: 5,
    title: "INSIGHTS",
    url: "/insights",
    icon: <InsightsIcon fontSize="small" />,
  },
  {
    key: 6,
    title: "BUSCA GERAL",
    subTitles: [
      { subkey: 0, namesubTitle: "Proposição", url: "/busca-geral/---?var=proposicao" },
      { subkey: 1, namesubTitle: "Interlocutores", url: "/busca-geral/---?var=interlocutor" },
      { subkey: 2, namesubTitle: "Discursos", url: "/busca-geral/---?var=discurso" },
      { subkey: 2, namesubTitle: "Twitter", url: "/busca-geral/---?var=twitter" },
      { subkey: 3, namesubTitle: "Notícias", url: "/busca-geral/---?var=noticia" },
      { subkey: 4, namesubTitle: "DOU", url: "/busca-geral/---?var=dou", hide: 'hideDou' },
    ],
    icon: <SearchIcon fontSize="small" />,
  },
];

export const mainMenu2 = [
  /*
  {key: 6,title: "CLASSIFICAÇÃO DE TEMAS", icon: <FolderOpenIcon fontSize="small" />, url: "/" },
  {key: 7,title: "CITAÇÕES", icon: <ReviewsIcon fontSize="small" />, url: "/citacoes" },*/
  {
    key: 7,
    title: "CENTRAL DE NOTIFICAÇÕES",
    icon: <NotificationsNoneOutlinedIcon />,
    url: "/central-notificacoes"
  },
  {
    key: 8,
    title: "CLASSIFICAÇÃO DE TEMAS",
    icon: <FolderOpenIcon />,
    url: "/classificacaodetemas",
  },
  {
    key: 9,
    title: "CITAÇÕES", 
    icon: <ReviewsIcon fontSize="small" />, 
    url: "/citacoes" 
  },
  // {
  //   key: 9,
  //   title: "INTELIGÊNCIA ARTIFICIAL",
  //   icon: <img src={Vector} />,
  //   url: "/ia",
  // },
  {
    key: 10,
    title: "FAVORITOS",
    icon: <StarIcon fontSize="small" />,
    url: "/favoritos",
    
  },  
  {
    key: 11,
    title: "GERENCIAMENTO DE USUÁRIOS",
    icon: <SupervisorAccountIcon fontSize="small" />,
    url: "/gerenciamentodeusuarios", //+ `${user?.id}`,
    
  }, 
];

export const mainMenuEsforco = [
  {
    key: 0,
    title: "ÁREA DE TRABALHO",
    url: "/workspace",
    icon: <HomeIcon fontSize="small" />,
  },
  {
    key: 1,
    title: "PROPOSIÇÕES",
    subTitles: [
      { subkey: 0, namesubTitle: "Lista", url: "/proposicao" },
      { subkey: 1, namesubTitle: "Dashboard", url: "/proposicao/dashboard" },
    ],
    icon: <AssignmentIcon fontSize="small" />,
  },
  {
    key: 2,
    title: "INTERLOCUTORES",
    subTitles: [
      { subkey: 0, namesubTitle: "Lista", url: "/interlocutor" },
    ],
    icon: <AccountBoxIcon fontSize="small" />,
  },
  {
    key: 3,
    title: "AGENDA",
    subTitles: [
      { subkey: 0, namesubTitle: "Agenda", url: "/agenda" },
    ],
    icon: <InsertInvitationIcon fontSize="small" />,
  },

  // {
  //   key: 3,
  //   title: "MÍDIA",
  //   subTitles: [
  //     {subkey:0,namesubTitle: "Notícias", url: "/" },
  //     {subkey:1,namesubTitle: "Twitter", url: "/" },
  //   ],
  //   icon: <CampaignIcon fontSize="small" />,
  // },

  {
    key: 4,
    title: "RELATÓRIOS",
    url: '/relatorios',
    // subTitles: [
    //   {subkey:0,namesubTitle: "Proposições", url: "/relatorios/proposicao" },
    //   {subkey:1,namesubTitle: "Interlocutores", url: "/relatorios/interlocutor" },
    // ],
    icon: <PieChartIcon fontSize="small" />,
  },
  {
    key: 5,
    title: "BUSCA GERAL",
    subTitles: [
      { subkey: 0, namesubTitle: "Proposição", url: "/busca-geral/---?var=proposicao" },
      { subkey: 1, namesubTitle: "Interlocutores", url: "/busca-geral/---?var=interlocutor" },
      { subkey: 2, namesubTitle: "Discursos", url: "/busca-geral/---?var=discurso" },
      { subkey: 3, namesubTitle: "Notícias", url: "/busca-geral/---?var=noticia" },
      { subkey: 4, namesubTitle: "DOU", url: "/busca-geral/---?var=dou", hide: 'hideDou' },
    ],
    icon: <SearchIcon fontSize="small" />,
  }
];

export const mainMenu2Esforco = [
  /*
  {key: 6,title: "CLASSIFICAÇÃO DE TEMAS", icon: <FolderOpenIcon fontSize="small" />, url: "/" },
  {key: 7,title: "CITAÇÕES", icon: <ReviewsIcon fontSize="small" />, url: "/citacoes" },*/
  {
    key: 6,
    title: "CENTRAL DE NOTIFICAÇÕES",
    icon: <NotificationsNoneOutlinedIcon />,
    url: "/central-notificacoes"
  },
  {
    key: 7,
    title: "FAVORITOS",
    icon: <StarIcon fontSize="small" />,
    url: "/favoritos",
    
  },
  {
    key: 8,
    title: "GERENCIAMENTO DE USUÁRIOS",
    icon: <SupervisorAccountIcon fontSize="small" />,
    url: "/gerenciamentodeusuarios", //+ `${user?.id}`,
    
  }, 

];

export const mainMenuReadOnly = [
  {
    key: 0,
    title: "ÁREA DE TRABALHO",
    url: "/workspace",
    icon: <HomeIcon fontSize="small" />,
  },
  {
    key: 1,
    title: "PROPOSIÇÕES",
    subTitles: [
      { subkey: 0, namesubTitle: "Lista", url: "/proposicao" },
      { subkey: 1, namesubTitle: "Dashboard", url: "/proposicao/dashboard" },
    ],
    icon: <AssignmentIcon fontSize="small" />,
  },
  {
    key: 2,
    title: "INTERLOCUTORES",
    subTitles: [
      { subkey: 0, namesubTitle: "Lista", url: "/interlocutor" },
      { subkey: 1, namesubTitle: "Dashboard", url: "/interlocutor/dashboard" },
    ],
    icon: <AccountBoxIcon fontSize="small" />,
  },
  {
    key: 3,
    title: "AGENDA",
    subTitles: [
      { subkey: 0, namesubTitle: "Agenda", url: "/agenda" },

    ],
    icon: <InsertInvitationIcon fontSize="small" />,
  },

  // {
  //   key: 3,
  //   title: "MÍDIA",
  //   subTitles: [
  //     {subkey:0,namesubTitle: "Notícias", url: "/" },
  //     {subkey:1,namesubTitle: "Twitter", url: "/" },
  //   ],
  //   icon: <CampaignIcon fontSize="small" />,
  // },

  {
    key: 4,
    title: "RELATÓRIOS",
    url: '/relatorios',
    // subTitles: [
    //   {subkey:0,namesubTitle: "Proposições", url: "/relatorios/proposicao" },
    //   {subkey:1,namesubTitle: "Interlocutores", url: "/relatorios/interlocutor" },
    // ],
    icon: <PieChartIcon fontSize="small" />,
  },

  {
    key: 5,
    title: "INSIGHTS",
    url: "/insights",
    icon: <InsightsIcon fontSize="small" />,
  },
  {
    key: 6,
    title: "BUSCA GERAL",
    subTitles: [
      { subkey: 0, namesubTitle: "Proposição", url: "/busca-geral?var=proposicao" },
      { subkey: 1, namesubTitle: "Interlocutores", url: "/busca-geral?var=interlocutor" },
      { subkey: 2, namesubTitle: "Discursos", url: "/busca-geral?var=discurso" },
      { subkey: 3, namesubTitle: "Notícias", url: "/busca-geral?var=noticia" },
      { subkey: 4, namesubTitle: "DOU", url: "/busca-geral?var=dou", hide: 'hideDou' },
    ],
    icon: <SearchIcon fontSize="small" />,
  }
];
