import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { usePallet } from '../contexts/PalletContext';


const NumbersPanelWidget = (props: { value: number, title: string, link?: string }) => {
    const { pallet } = usePallet();
    return (
        <Grid style={{ padding: "20px" }}>
            <Grid item>
                <Typography style={{
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontSize: "26px",
                    fontWeight: pallet.fontWeight.subtitle,
                }}>
                    {props.value}
                </Typography>
            </Grid>
            <Grid item>
                <Typography style={{
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontSize: `${pallet.fontSize.titleWidgets}`,
                    fontWeight: pallet.fontWeight.subtitle,
                    color: `${pallet.general.color1}`
                }}>
                    {props?.link ?
                        <Link href={`${props.link}`} onClick={() => { }} style={{
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontWeight: pallet.fontWeight.button,
                            textDecoration: "none",
                            color: `${pallet.color.tertiary.font}`
                        }}>
                           {props.title}
                        </Link>
                        :
                        `${props.title}`
                    }
                </Typography>

            </Grid>
        </Grid>
    )
}


export default NumbersPanelWidget;