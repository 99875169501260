import { format } from "date-fns";
import * as Yup from "yup";

const ParlamentarPosicionamentoSchema = Yup.object({
    impacto: Yup.string().required({ name: "impacto", err: "Preencha o campo ' Valor do impacto'"}),
    observacao: Yup.string().required({ name: "observacao", err: "Preencha o campo 'Argumentos para justificar posicionamento'" }).min(3),

});

export default ParlamentarPosicionamentoSchema;
