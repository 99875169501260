import React from 'react';
import { usePallet } from '../contexts/PalletContext';
import styled from "styled-components";
import { TextField, MenuItem, Autocomplete, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';


const FilterTag = styled(Chip)`
        font-size: 12px;
        font-weight: 600;
        background-color:rgba(224, 205, 248, 1);
        border: none;
        color:"#4B1196";
        padding: 10px;
        font-family: Montserrat;
        font-style: normal;        
        border-radius: 39px;
        justify-content: center;
        align-items: center;
        margin-left: 2px;
        margin-right: 5px;
        margin-top: 2px;
        margin-bottom: 5px;
`;

export default function InputTextFieldTags(props: {
    arrayItems: Array<any>,
    label: string,
    options?: Array<any>
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onSelect?: (e: React.ChangeEvent<HTMLInputElement>, value?: any) => void,
    onDelete?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    type?: string,
    placeholder?: string,
    name?: string,
    focused?: boolean,
}) {

    const { pallet } = usePallet();

    const handleChange = (e, value) => {
        if (e && e.type == "change")
            if (props.onChange)
                props.onChange(e)
    }

    return (
        <Stack sx={{ width: "100%" }}>
            <Autocomplete
                defaultValue={props?.arrayItems}
                value={props?.arrayItems}
                multiple
                id="tags-filled"
                options={props.options ? props.options : []}
                clearIcon={false}
                freeSolo
                renderTags={() => props?.arrayItems?.map((item, index) => {
                    if(item.origin === 0){
                        return (
                            <FilterTag
                                key={index}
                                style={{
                                    margin: "3px",
                                    whiteSpace: "pre-wrap",
                                    backgroundColor: '#343434',
                                    color: 'white',
                                    fontWeight: 100
                                }}
                                label={item.tagLabel}
                                variant="outlined"
                            />
                        );
                    }else{
                        return (
                            <FilterTag
                                key={index}
                                style={{
                                    margin: "3px",
                                    whiteSpace: "pre-wrap",
                                    // fontFamily: `${pallet?.general.fontFamily}`,
                                    // fontSize: "12.5px",
                                    // fontWeight: pallet?.general.weight_100,
                                }}
                                label={item.tagLabel}
                                variant="outlined"
                                onDelete={() => props.onDelete(item.id)}
                            />
                        );
                    };                
                })}
                style={{
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size14}`,
                    fontWeight: pallet.general.weight_100,
                }}
                onInputChange={handleChange}
                onChange={props.onSelect}
                renderInput={(params) => (
                    <TextField
                        name={props.name}
                        type={props.type}
                        // onChange={handleChange}
                        onKeyUp={props.onKeyUp}
                        // onSelect={props.onSelect}
                        onBlur={props.onBlur}
                        focused={props.focused ? props.focused : false}
                        {...params}
                        variant="outlined"
                        label={props.label}
                        placeholder={props.placeholder}
                        InputLabelProps={{
                            style: { color: "grey" }
                        }}
                    />
                )}
            />
        </Stack>
    )
}