
import React, { useState } from "react";
import { usePallet } from "../../../contexts/PalletContext";
import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import ParlamentarOutrosMandatos from "./ParlamentarOutrosMandatos";
import ParlamentarOutrosLiderancas from "./ParlamentarOutrosLiderancas";
import ParlamentarOutrosFrentes from "./ParlamentarOutrosFrentes";
import ParlamentarOutrosEventos from "./ParlamentarOutrosEventos";
import Grid from "@mui/material/Grid";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import ParlamentarOutrosService from "../../../services/ParlamentarOutrosService";
import EmptyError from "../../../components/EmptyError";
import CircularLoading from "../../../components/CircularLoading";

const ParlamentarCarouselTabsOutros = (props: { id_parlamentar: number, activeTab: any, index: number, loading?: boolean, permissaoUsuario?: number }) => {
    const { pallet } = usePallet();
    const [changeOutros, setChangeOutros] = React.useState("")
    const [outros, setOutros] = useState<{ eventos: Number, frentes: Number, mandatos: Number, historicos: Number }>({ eventos: 0, frentes: 0, mandatos: 0, historicos: 0 });
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        if (props.activeTab === props.index)
            loadData(props.id_parlamentar)
        // eslint-disable-next-line 
    }, [props.activeTab]);

    async function loadData(id_parlamentar: number) {
        await ParlamentarOutrosService.GetSummary(id_parlamentar)
            .then(res => {
                setOutros(() => res);
                if (res.mandatos > 0) setChangeOutros("outrosmandato")
                else if (res.historicos > 0) setChangeOutros("outroslideranca")
                else if (res.frentes > 0) setChangeOutros("outrosfrente")
                else if (res.eventos > 0) setChangeOutros("outrosevento")
                setLoading(false);

            })
    }

    const trocaEntreOutros = (key: string) => {
        setChangeOutros(key);

    }

    return (
        <div>
            <Paper style={{ borderRadius: "6px",marginTop: `${isMobile  ? "0px" : props.permissaoUsuario !== 4 ? "-30px" : "-3px"}` }}>
                <TabPanel value={props.activeTab} index={props.index}>

                    {
                        loading === true
                            ?

                            <CircularLoading
                                size={80}
                                top={"90%"}
                                left={"50%"}
                                zIndex={2}
                            />

                            :

                            outros.eventos === 0 && outros.frentes === 0 && outros.mandatos === 0 && outros.historicos === 0

                                ?

                                <Grid
                                    style={{
                                        color: `${pallet.textColorQuaternary}`,
                                        fontSize: `${pallet.fontSize.titleWidgets}`,
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: 600,
                                        textAlign: "center",
                                        paddingBottom: "150px",
                                        marginTop: "30px",
                                    }}
                                >
                                    <EmptyError title="Ops!" description="Não foram encontrados dados suficientes para este resultado." />
                                </Grid>

                                :
                                <Grid container style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                                    {
                                        Number(outros.mandatos) > 0 &&
                                        <Grid alignItems="flex-start" style={{ marginRight: "20px" }}>
                                            <ButtonIconSalvar
                                                onClick={trocaEntreOutros}
                                                tipo={"outrosmandato"}
                                                backgroundColor={changeOutros === "outrosmandato" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                                                border={`1px solid ${changeOutros !== "outrosmandato" ? "lightgrey" : ""}`}
                                                fontColor={changeOutros !== "outrosmandato" ? "lightgrey" : `${pallet.textColorPrimary}`}
                                                fontSize={`${pallet.general.size12}`}
                                                icon={""}
                                                title={"MANDATOS"}
                                            />
                                        </Grid>
                                    }
                                    {Number(outros.historicos) > 0 &&
                                        <Grid alignItems="flex-start">
                                            <ButtonIconSalvar
                                                onClick={trocaEntreOutros}
                                                tipo={"outroslideranca"}
                                                backgroundColor={changeOutros === "outroslideranca" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                                                border={`1px solid ${changeOutros !== "outroslideranca" ? "lightgrey" : ""}`}
                                                fontColor={changeOutros !== "outroslideranca" ? "lightgrey" : `${pallet.textColorPrimary}`}
                                                fontSize={`${pallet.general.size12}`}
                                                icon={""}
                                                title={"LIDERANÇAS"}
                                            />
                                        </Grid>
                                    }
                                    {Number(outros.frentes) > 0 &&
                                        <Grid alignItems="flex-start" style={{ paddingLeft: "20px" }}>
                                            <ButtonIconSalvar
                                                onClick={trocaEntreOutros}
                                                tipo={"outrosfrente"}
                                                backgroundColor={changeOutros === "outrosfrente" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                                                border={`1px solid ${changeOutros !== "outrosfrente" ? "lightgrey" : ""}`}
                                                fontColor={changeOutros !== "outrosfrente" ? "gray" : `${pallet.textColorPrimary}`}
                                                fontSize={`${pallet.general.size12}`}
                                                icon={""}
                                                title={"FRENTES"}
                                            />
                                        </Grid>
                                    }

                                    {Number(outros.eventos) > 0 &&
                                        <Grid alignItems="flex-start" style={{ paddingLeft: "20px" }}>
                                            <ButtonIconSalvar
                                                onClick={trocaEntreOutros}
                                                tipo={"outrosevento"}
                                                backgroundColor={changeOutros === "outrosevento" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                                                border={`1px solid ${changeOutros !== "outrosevento" ? "lightgrey" : ""}`}
                                                fontColor={changeOutros !== "outrosevento" ? "gray" : `${pallet.textColorPrimary}`}
                                                fontSize={`${pallet.general.size12}`}
                                                icon={""}
                                                title={"EVENTOS"}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                    }{
                        changeOutros === "outrosmandato" ?
                            <ParlamentarOutrosMandatos id_parlamentar={props.id_parlamentar} /> :
                            changeOutros === "outroslideranca" ?
                                <ParlamentarOutrosLiderancas id_parlamentar={props.id_parlamentar} /> :
                                changeOutros === "outrosfrente" ?
                                    <ParlamentarOutrosFrentes id_parlamentar={props.id_parlamentar} /> :
                                    changeOutros === "outrosevento" ?
                                        <ParlamentarOutrosEventos id_parlamentar={props.id_parlamentar} /> :
                                        <></>
                    }
                </TabPanel>
            </Paper >
        </div>
    )
}


export default ParlamentarCarouselTabsOutros;