import React from 'react';
import { useParams } from 'react-router-dom';
import ParlamentarService from '../../services/ParlamentarService';
import Main from '../main/Main';
import { Grid, Paper } from '@mui/material';
import useStyles from '../../components/layout/Styles';
import { IParlamentar } from '../../models/Parlamentar.Model';
import ParlamentarCarouselTabs from './_components/ParlamentarCarouselTabs'
// import _ from 'lodash';
import ParlamentarConsultaHeader from './_components/ParlamentarConsultaHeader';
import { ParlamentarConsultaBotoes } from './_components/ParlamentarConsultaBotoes';
import { ParlamentarConsultaInicio } from './_components/ParlamentarConsultaInicio';
import { ParlamentarConsultaInformacoes } from './_components/ParlamentarConsultaInformacoes';
import { ParlamentarConsultaMenuParlamentar } from './_components/ParlamentarConsultaMenuParlamentar';
import { useQuery } from '../../utils/utils';
import { useAuthorization } from "../../contexts/AuthorizationContext";

export default function ParlamentarConsulta(): JSX.Element {

    const { id } = useParams();
    const classes = useStyles();
    const [parlamentar, setParlamentar] = React.useState<IParlamentar>()
    const qry = useQuery().get('filtro');
    const { user } = useAuthorization();

    // console.log("IDDDDDDDDDDDDDDDDDDDDDD",id)
    React.useEffect(() => {
        loadParlamentar()
        // eslint-disable-next-line
    }, [id])

    const loadParlamentar = () => {
        if (id)
            ParlamentarService
                .Get(Number(id))
                .then(parlamentar => {
                    setParlamentar(parlamentar)
                });
    }
    return (
        <div className={classes.root}>
            <Main>
                <Grid container justifyContent="space-around">

                    {/* ref={divRef} */}
                    <Paper style={{ borderRadius: "7px", width: "100%" }} >
                        {/* Parlamentar Header */}
                        <ParlamentarConsultaHeader parlamentar={parlamentar} idPermissao={user.id_permissao} />
                        {/* ---------------- BOTOES ---------------------  */}
                        <ParlamentarConsultaBotoes parlamentar={parlamentar} onChange={loadParlamentar} idPermissao={user.id_permissao} />
                        {/* Parlamentar Inicio */}
                        <ParlamentarConsultaInicio parlamentar={parlamentar} idPermissao={user.id_permissao} />
                        {/* Informações */}
                        <ParlamentarConsultaInformacoes parlamentar={parlamentar} />
                        {/* Menu Parlamentar */}
                        {user.id_permissao !== 4 ?
                        <ParlamentarConsultaMenuParlamentar parlamentar={parlamentar} onBlur={loadParlamentar} onChange={loadParlamentar} idPermissao={user.id_permissao}/>
                        :
                        null
                        }
                   </Paper>
                    <Grid container style={{ paddingTop: "30px" }}>
                        <Grid item style={{ width: "100%" }}>
                            <ParlamentarCarouselTabs id_parlamentar={Number(id)} query={qry} parlamentar={parlamentar} permissaoUsuario={user.id_permissao} twitterUsername={parlamentar?.social?.rede3}  />
                        </Grid>
                    </Grid>
                </Grid>
            </Main>
        </div>
    );
}