import React from 'react';
import { Grid, Typography } from "@mui/material"
import { isMobile } from "react-device-detect"
import { usePallet } from "../../../contexts/PalletContext"
import { IParlamentar } from "../../../models/Parlamentar.Model"

const ParlamentarConsultaSpanCustom = (props) => {
    const { pallet } = usePallet()
    return (
        <span style={{
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.smallButton}`,
            ...props?.style
        }}>
            {props?.children}
        </span>
    )
}

const ParlamentarConsultaTypography = (props) => {
    const { pallet } = usePallet()
    return (
        <Typography align="justify" style={{
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.titleCardParlamentar}`,
            color: `${pallet.color.colorBase.black}`,
            ...props?.style
        }}>
            {props?.children}
        </Typography>
    )
}

const ParlamentarApelidoConsultaTypography = (props) => {
    const { pallet } = usePallet()
    return (
        <Typography align="justify" style={{
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: 500,
            fontSize: '15px',
            paddingTop: '2px',
            color: `${pallet.color.colorBase.black}`,
            ...props?.style
        }}>
            {props?.children}
        </Typography>
    )
}

const ParlamentarConsultaSituacaoTypography = (props: { children: any, style?: any }) => {
    const { pallet } = usePallet()
    return (
        <Typography align="justify" style={{
            padding: "3px 9px",
            borderRadius: "13px",
            backgroundColor: `${pallet.color.colorBase.greyBackground}`,
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.text_10}`,
            color: `${pallet.color.colorBase.greyText}`,
            marginBottom: "15px",
            textTransform: "uppercase",
            ...props?.style
        }}>
            {props.children}
        </Typography>
    )
}
export const ParlamentarConsultaInicio = (props: { parlamentar: IParlamentar, idPermissao?: number }) => {
    const { pallet } = usePallet()
    return (
        <Grid item>
            <div style={{ paddingLeft: `${isMobile ? "20px" : "50px"}`, marginTop: "35px" }}>
                <Grid >
                    <Grid item >
                        <Grid container>
                            {props.parlamentar?.situacao && props.parlamentar?.situacao !== '' ?
                                <ParlamentarConsultaSituacaoTypography>
                                    {props.parlamentar?.situacao}
                                </ParlamentarConsultaSituacaoTypography>
                                :
                                null
                            }
                            {props.idPermissao !== 4 ?
                            <ParlamentarConsultaSituacaoTypography style={{ marginLeft: "10px" }}>
                                {props.parlamentar?.cliente?.qualificacao || 'Não Classificado'}
                            </ParlamentarConsultaSituacaoTypography>
                            :
                            null}
                        </Grid>
                        <Grid container>
                            <ParlamentarConsultaTypography style={{ textTransform: "uppercase" }}>
                                {props.parlamentar?.nome_civil}
                            </ParlamentarConsultaTypography>
                            <ParlamentarApelidoConsultaTypography style={{ textTransform: "uppercase" }}>
                                &nbsp;({props.parlamentar?.nome})
                            </ParlamentarApelidoConsultaTypography>
                        </Grid>
                        <Grid container>
                            <Typography style={{
                                color: `${pallet.color.colorBase.black}`,
                            }}>
                                <ParlamentarConsultaSpanCustom >
                                    {props.parlamentar?.tratamento}
                                </ParlamentarConsultaSpanCustom>
                                <span>
                                    {props.parlamentar?.sigla_partido ? " • " : ""}
                                </span>
                                <ParlamentarConsultaSpanCustom style={{ textTransform: "uppercase" }}>
                                    {props.parlamentar?.sigla_partido}
                                </ParlamentarConsultaSpanCustom>
                                <span>
                                    {props.parlamentar?.uf_exercicio ? " • " : ""}
                                </span>
                                <ParlamentarConsultaSpanCustom style={{ textTransform: "uppercase", }}>
                                    {props.parlamentar?.uf_exercicio}
                                </ParlamentarConsultaSpanCustom>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}