import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, Divider } from '@mui/material';
import { usePallet } from "../../../../contexts/PalletContext";
import InputDatePicker from '../../../../components/InputDatePicker';
import InputMultilineTextField from '../../../../components/InputMultilineTextField';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import SolidButton from '../../../../components/SolidButton';
import { isMobile } from 'react-device-detect';
import EditIcon from '@mui/icons-material/Edit';
import ProposicaoAcontecimentosService from '../../../../services/ProposicaoAcontecimentosService';
import { useAlert } from '../../../../contexts/AlertContext';
import ProposicaoAcontecimentosCreateSchema from '../../models/ProposicaoAcontecimentosCreateSchema';
import ProposicaoService from '../../../../services/ProposicaoService';
import ProposicaoTramitacaoCreateSchema from '../../models/ProposicaoTramitacaoCreateSchema';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import TabelasService from '../../../../services/TabelasService';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogs(props: {
    idTramitacao: number
    reloadList: () => void
    closeMenu: () => void
    onChange?: () => void
    idProposicao: number
}) {
    const { NewAlert } = useAlert();
    const [open, setOpen] = React.useState(false);
    const { pallet } = usePallet();
    const [dateTramitacao, setDateTramitacao] = React.useState();
    const [descricao, setDescricao] = React.useState("");
    const [onSave, setOnSave] = React.useState(false)
    const [idRef, setIdRef] = React.useState()
    const [situacaoTramitacao, setSituacaoTramitacao] = React.useState<any>();
    const [tramitacoesSituacoes, setTramitacoesSituacoes] = React.useState([{ key: 0, label: '', value: 0 }]);
    const [data, setData] = React.useState({});
    const [error, setError] = React.useState<Array<{ name: string, err: string }>>([]);

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    const handleClickOpen = async (id) => {

        await ProposicaoService.GetTramitacaoUni(props?.idProposicao, id).then(res => {
            setIdRef(res?.data[0].tramitacao_id)
            setDateTramitacao(res?.data[0].tramitacao_data_atualizacao)
            setDescricao(res?.data[0].tramitacao_descricao)
            setSituacaoTramitacao(res?.data[0].codigo_situacao)
        });
        loadTramitacoesSituacoes();
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props?.reloadList()
        props?.closeMenu()
    };


    const handleDateTramitacaoChange = (dateAc) => {
        setDateTramitacao(dateAc)
    };

    const handleSituacaoTramitacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSituacaoTramitacao(event.target.value);
    };

    const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value)
    };

    const loadTramitacoesSituacoes = async () => {

        await TabelasService.getTabelaTramitacaoSituacoes()
            .then(res => {
                const dataresp = res.map(item => {
                    return {
                        key: item.codsituacao,
                        label: item.descsituacao,
                        value: item.codsituacao
                    }
                });
                setTramitacoesSituacoes(dataresp);
            })

    }

    async function handleOnAfterUpload() {
        if (idRef) {
            setIdRef(undefined);
            setOnSave(false)
        }
    }

    const salvarEdicaoTramitacao = async (e) => {
        setOnSave(true)
        e.preventDefault();
        const data = {
            date: dateTramitacao,
            descricao: descricao,
            situacao: situacaoTramitacao,
        }

        setError(() => [])
        data && ProposicaoAcontecimentosCreateSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        ProposicaoTramitacaoCreateSchema.validate(data).catch(function (err) {
        });

        if (ProposicaoTramitacaoCreateSchema.isValidSync(data)) {
            await ProposicaoService.UpdateTramitacao(data, props?.idProposicao, props?.idTramitacao)
                .then(res => {
                    if (res) {
                        handleClose()
                        NewAlert('success', 'Tramitação editada com sucesso')
                        setTimeout(() => {
                        props?.reloadList()
                        props?.closeMenu()
                        props?.onChange()
                      }, 2000);
                       
                    } else {
                        NewAlert('error', 'Erro ao editar, verifique os campos e salve novamente');
                    }
                })
            // .then(x => loadAcontecimentos(props.id_Proposicao))
        } else {
            NewAlert('error', 'Algum campo está incorreto ou vazio, verifique!');
        }
    }

    const { user } = useAuthorization();

    return (
        <div>
            <Button onClick={() => {user.id_permissao !== 3 ?

                                        handleClickOpen(props?.idTramitacao)

                                        :

                                        NewAlert(
                                            "error",
                                            "Não é possível realizar está ação com este perfil."
                                        )
                                        
                                    }}
                style={{
                    marginLeft: "-7px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    paddingRight: "85px",
                    marginRight: "-10px"
                }}>
                <EditIcon />
                <div style={{fontSize: 0}}>@</div> Editar
            </Button>
            <BootstrapDialog
                id={`dialog-${props?.idTramitacao}`}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Grid style={{
                        fontFamily: `${pallet.fontFamily}`,
                        fontSize: `${pallet.general.size18}`,
                        color: `${pallet.textColorSecondary}`,
                        padding: "12px",
                        marginTop: "7px",
                    }}>
                        EDITAR REGISTRO
                    </Grid>
                </BootstrapDialogTitle>

                <DialogContent dividers style={{ padding: "0px" }} >
                    <Grid style={{ padding: "30px 25px 30px 25px" }}>
                        <Grid container spacing={2}>

                            <Grid item lg={6} xs={12}>
                                <InputDatePicker
                                    // defaultValue={dataAcontecimentos?.acontecimentos_data_acontecimento}
                                    onChange={handleDateTramitacaoChange}
                                    label={"DATA DE REGISTRO"}
                                    inputFormat={"dd/MM/yyyy"}
                                    value={dateTramitacao}
                                    id={"dataTramitacao-proposicao"}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <SelectTextFieldA
                                    value={situacaoTramitacao}
                                    //onChange={handleSituacaoTramitacaoChange}
                                    onChange={(e) => handleSituacaoTramitacaoChange(e)}
                                    options={tramitacoesSituacoes}
                                    label={"SITUAÇÃO"}
                                    id={"situacao-tramitacao"}
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='situacao' errors={error} />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item style={{ marginTop: "20px" }} lg={12} xs={12}>
                            <InputMultilineTextField
                                // defaultValue={dataAcontecimentos?.acontecimentos_acontecimento}
                                type={"text"}
                                maxRows={4}
                                value={descricao}
                                onChange={handleDescricaoChange}
                                id={"descricao-proposicao"}
                                label="DESCRIÇÃO"
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='descricao' errors={error} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ paddingBottom: "10px" }}>
                        <Divider />
                    </Grid>
                    <Grid style={{ padding: "20px 18px 20px 18px" }}>
                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid style={{
                                fontWeight: pallet.fontWeight.subtitle,
                                fontSize: `${pallet.general.size12}`,
                                color: `${pallet.color.tertiary.font}`,
                                marginLeft: "9px"
                            }}>
                                ANEXOS
                            </Grid>

                            <Grid className="container">
                                <AnexosComponent
                                    label=''
                                    modulo={EAnexoModulos.Tramitacao}
                                    id_referencia={idRef}
                                    canEdit={true}
                                    autosave={false}
                                    save={onSave}
                                    onAfterUpload={handleOnAfterUpload}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    padding: "30px",
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`
                }}>
                    <SolidButton
                        onClick={salvarEdicaoTramitacao}
                        onChange={props?.reloadList}
                        color={`${pallet.textColorPrimary}`}
                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                        fontSize={`${pallet.general.size14}`}
                        title={"SALVAR"}
                    />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}