import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import useStyles from "../../../components/layout/Styles";
import { useAlert } from "../../../contexts/AlertContext";
import { usePallet } from "../../../contexts/PalletContext";
import { IParlamentar } from "../../../models/Parlamentar.Model";
import { parlamentarQualificacoes } from "../../../models/ParlamentarQualificacoes";
import InputMultilineTextField from '../../../components/InputMultilineTextField'
import ParlamentarService from "../../../services/ParlamentarService";
import { text } from "@fortawesome/fontawesome-svg-core";


export

    const ParlamentarConsultaMenuParlamentar = (props: { parlamentar: IParlamentar, onChange: () => void,onBlur: () => void, idPermissao: number }) => {
        const { pallet } = usePallet();
        const classes = useStyles();
        const [qualificacao, setQualificacao] = useState('não classificado');
        const [observacao, setObservacao] = useState('');
        const { NewAlert } = useAlert();
        //const [observacoes, setObservacoes] = useState('');

 

        React.useEffect(() => {
            if (props.parlamentar?.cliente?.qualificacao) setQualificacao(props.parlamentar.cliente.qualificacao.toLowerCase());
            if (props.parlamentar?.cliente?.observacao) setObservacao(props.parlamentar.cliente.observacao);
            // eslint-disable-next-line 
        }, [props.parlamentar])

        React.useEffect(() => {
            if (props.onChange) props.onChange()
            // eslint-disable-next-line
        }, [ qualificacao])


        // React.useEffect(() => {
        //     if (props.onBlur) props.onBlur()
        //     // eslint-disable-next-line
        // }, [ observacao])

        const CustomLabelQualificacao: React.FunctionComponent = () => {
            return (
                <div style={{
                    color: `${pallet.color.colorBase.labelTextFild}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    marginRight:"8px"
                }}>QUALIFICAÇÃO</div>
            )
        }

        const CustomLabelObs: React.FunctionComponent = () => {
            return (
                <div style={{
                    color: `${pallet.color.colorBase.labelTextFild}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    marginRight:"8px"
                }}>OBSERVAÇÕES</div>
            )
        }

        const handleQualificacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            ParlamentarService.SetQualificacao(Number(props?.parlamentar?.id), event.target.value).then(x => {
                setQualificacao(event.target.value);
                NewAlert('success', 'Registro Salvo com sucesso');
            });
        };

         const handleObservacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
             setObservacao(event.target.value)
         };

        // const handleObservacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     ParlamentarService.SetObservacao(Number(props.parlamentar.id), event.target.value).then(x => {
        //         setObservacao(event.target.value)
        //         NewAlert('success', 'Observação salva com sucesso');
        //     });
        // };

        const handleObservacaoBlur = (e) => {
            ParlamentarService.SetObservacao(Number(props.parlamentar.id), e.target.value).then(x => {
                        NewAlert('success', 'Observação salva com sucesso');
           });


        };
        const permissaoErro = () => {
            NewAlert(
                "error",
                "Não é possível realizar está ação com este perfil.")
    
        }
        

        return (
            <Grid style={{ paddingLeft: `${isMobile ? "20px" : "50px"}`, paddingRight: `${isMobile ? "20px" : "50px"}`, paddingBottom: `${isMobile ? "20px" : "45px"}` }}>
                <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: `${pallet.color.primary.background}` }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                            backgroundColor: `${pallet.color.colorBase.lightGreyBackground}`,
                            height: "10px",
                            borderRadius: "5px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            flexFlow: "row-reverse",
                            // border:`1px solid ${pallet.color.colorBase.greyText}`
                        }}
                    >
                        <Typography className={classes.heading} style={{
                            color: `${pallet.color.secundary.font}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontWeight: pallet.fontWeight.subtitle,
                            marginLeft: "10px",
                        }}>
                            MENU DO INTERLOCUTOR
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{
                        fontFamily: `${pallet.fontFamily.general}`
                    }}>
                        {/* <MyComponent /> */}
                        <Grid style={{
                            padding: `${isMobile ? "10px 0px 0px 0px" : "20px 20px 10px 20px"}`,
                            width: "100%"
                        }}>
                            <Grid xl={12}>
                                <Box component="form">
                                    <TextField
                                        select
                                        // focused={true}
                                        id="outlined-select-currency"
                                        label={<CustomLabelQualificacao />} variant="outlined"
                                        value={qualificacao}
                                        defaultValue={qualificacao}
                                        onChange={props.idPermissao !== 3 ? handleQualificacaoChange : permissaoErro}
                                        style={{
                                            fontFamily: pallet.fontFamily.general,
                                            inlineSize: `${isMobile ? "100%" : "50%"}`
                                        }}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline
                                            },
                                            style: {
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontWeight: pallet.fontWeight.lighter,

                                            }
                                        }}
                                    >
                                        {parlamentarQualificacoes.map((option) => (
                                            <MenuItem
                                                style={{
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    backgroundColor: `${pallet.backGroundPaperColorSecondary}`,

                                                }}
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>

                                <Box>
                                </Box>

                                <Box component="form">
                                    <Grid item lg={12} xs={12} style={{
                                        fontFamily: pallet.fontFamily.general,
                                        inlineSize: "100%",
                                        marginTop: "20px",
                                    }}>
                                        {/* <InputMultilineTextField
                                            type={"text"}
                                            maxRows={4}
                                            value={observacao}
                                            id={"observacao-parlamentar"}
                                            label={<CustomLabelObs />}
                                            onChange={handleObservacaoChange}
                                        /> */}

                                        <TextField 
                                                maxRows="4" 
                                               multiline variant="outlined" 
                                                value={observacao} 
                                                id={"observacao-parlamentar"}
                                                label={<CustomLabelObs />}
                                                style={{ width: "100%" }}
                                               onBlur={props.idPermissao !== 3 ? handleObservacaoBlur : permissaoErro}
                                               onChange={props.idPermissao !== 3 ? handleObservacaoChange : permissaoErro}
                                               InputProps={{
                                                classes: {
                                
                                                    notchedOutline: classes.notchedOutline,
                                
                                                }}}
                                                
                                        />
                                       
                                    </Grid>
                                    {/* <TextField
                                        multiline
                                        maxRows={4}
                                        focused={true}
                                        label={<CustomLabelObs />} variant="outlined"
                                        value={observacao}
                                        // onChange={_.debounce(handleObservacaoChange, 1000)}
                                        onChange={handleObservacaoChange}
                                        style={{
                                            fontFamily: pallet.fontFamily.general,
                                            inlineSize: "100%",
                                            marginTop: "20px",
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.root,
                                                notchedOutline: classes.notchedOutline,

                                            },
                                            style: {
                                                // borderBlockStartColor: "ActiveBorder",
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontWeight: pallet.fontWeight.lighter,
                                                backgroundColor: `${pallet.backGroundPaperColorSecondary}`,
                                            }
                                        }} >
                                    </TextField> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

