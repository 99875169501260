import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import useStyles from "../../../../components/layout/Styles";
import SelectTextField from "../../../../components/SelectTextField";
import { useAlert } from '../../../../contexts/AlertContext';
import { usePallet } from "../../../../contexts/PalletContext";
import { IProposicao } from "../../../../models/Proposicao.Model";
import ProposicaoService from "../../../../services/ProposicaoService";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import TabelasService from "../../../../services/TabelasService";
import ButtonAdd from "../../../../components/ButtonAdd";
import ButtonIconSalvar from "../../../../components/ButtonIconSalvar";
import AddCircleIcon from '@mui/icons-material/AddCircle';


const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
`;

const AnexoGrid = styled(Grid)`
  .upload-message {
    display: none;
  }
`;

export const ProposicaoPosicionamentoUnidadeNegocioSelect = (props: {
  proposicao: IProposicao;
  idPermissao?: number;
  posicionamento:number;
  id_unidade_negocio:number;
  idPosicionamento: number;
}) => {
  const { pallet } = usePallet();
  const classes = useStyles();

  const [posicionamento, setPosicionamento] = React.useState(props.posicionamento ? props.posicionamento : '0');
  const [listaDepartamento, setListaDepartamento] = React.useState([]);
  const [data, setData] = React.useState({});
  const [idTemp, setIdTemp] = React.useState(0);
  const [departamento, setDepartamento] = React.useState(props.id_unidade_negocio ? props.id_unidade_negocio : '');
  const { NewAlert } = useAlert();

  const handleChangePosicionamento = (value: string) => {
    setPosicionamento(value);

    
   
      // const data = {
      //   id_departamento: departamento,
      //   posicionamento: value
  
  
      // };
      setData(data);
         ProposicaoService.SetPosicionamentoUnidadeNegocio(props.proposicao.id,{data: {id_departamento: departamento,posicionamento: value}}).then((res) => {
          if (res) {
            NewAlert("success", "Posicionamento salvo com sucesso.");
            setIdTemp(res.data)
          } else {
            NewAlert(
              "error",
              "Ocorreu um erro, tente novamente."
            );
          }
        });
      // }
      

  };





  const handleDepartamentoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepartamento(event.target.value);
  };



  React.useEffect(() => {

    setTimeout(() => {



      const data = [];
      TabelasService.getTabelaDepartamentos().then(async (res) => {
      data.push({ key: "", label: "-- Selecione -- ", value: "" });
      res?.map((item) =>
        data.push({
          key: item.descricao,
          label: item.descricao,
          value: item.id,
        })
      );
      setListaDepartamento(() => [...data]);
    });
  }, 2000);
  }, [props.id_unidade_negocio]);

  const corPosicionamento = (value: string) => {
    switch (value) {
      case "Contrário":
          return `${pallet.charts.red}`;
      case "Contrário com emenda saneadora":
          return `${pallet.charts.orange}`;
      case "Neutro":
          return `${pallet.charts.purple}`;
      case "Favorável com emenda saneadora":
          return `${pallet.charts.blue}`;
      case "Favorável":
          return `${pallet.charts.green}`;
      case "Pendente":
          return `${pallet.charts.yellow}`;
      case "Indefinido":
          return `${pallet.charts.lightGrey}`;
      case "Favorável ao parecer do relator":
          return '#C71585';
      case "Favorável ao parecer do relator com emenda":
          return '#B8860B';
      case "Contrário ao parecer do relator":
          return '#facb74';
      case "Contrário ao parecer do relator com emenda":
          return '#000000';
      default:
          return `${pallet.charts.lightGrey}`;
  }
  };

  function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
          </Grid>
          <Grid item >
            {props?.posicionamento}
          </Grid>
        </Grid>
      </>
    )
  }



  const posicionamentosItens = [
    // { key: 0, label: <LabelHandlePosicionamento posicionamento={"Selecione"} bg={"0"} />, value: "0" },
    { key: 1, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "3" },
    { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "2" },
    { key: 3, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "1" },
    { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "4" },
    { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "5" },
    { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "6" },
    { key: 7, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "7" },
    { key: 8, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "8" },
    { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "9" },
    { key: 10, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "99" },
    { key: 11, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "0" },
  ]




  const permissaoErro = () => {
    NewAlert(
        "error",
        "Não é possível realizar está ação com este perfil.")

}



  return (

          <Grid container spacing={3} >
  
          <Grid
              item
              lg={6}
              xs={12}
            >

          <SelectTextFieldA
                name={"departamento"}
                value={departamento}
                onChange={handleDepartamentoOnChange}
                options={listaDepartamento}
                label={"UNIDADE DE NEGÓCIO"}
                id={"departamento"}
                focused={true}
              />
            </Grid>

           
            {departamento || props.id_unidade_negocio ? 
            <>
            <Grid
              item
              lg={6}
              xs={12}
            >

              <SelectTextField
                name="posicionamento-select"
                label="POSICIONAMENTO"
                style={{ width: "100%" }}
                value={String(posicionamento)}
                defaultValue={'0'}
                options={posicionamentosItens}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    color: "gray"
                  }
                }}
                onChange={props.idPermissao !== 3 ? handleChangePosicionamento : permissaoErro}
              />

            </Grid>
                        <Grid item lg={12} xs={12} style={{ fontSize: pallet.general.size12, fontWeight: pallet.general.weight_600, marginRight: "-25px" }}>ANEXOS</Grid>
                        <AnexoGrid style={{ marginRight: "50px", width: "100%", marginLeft: "25px" }}>
                          <AnexosComponent
                            label=''
                            id_referencia={idTemp > 0 ? idTemp : props.idPosicionamento}
                            modulo={EAnexoModulos.PosicionamentoUnidadeNegocio}
                            canEdit={true}
                            autosave={true}
                          />
                        </AnexoGrid>
            </>
            :
            null
          }
          



    </Grid>
  );
};
