// import React from 'react';
import useStyles from '../../../components/layout/Styles';
import { Grid, Divider, Typography, Paper, Box, Link } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import DadosPendencias from '../../dashboard/_components/DadosPendencias';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import CircularLoading from '../../../components/CircularLoading';
import EmptyError from '../../../components/EmptyError';


const DoughnutChartParlamentarProposicao = (props: {
    masterTitle: string,
    titleChart: string,
    total: number,
    seguindo: number,
    labels: Array<{ key: string, data: number, color: string, url?: string }>,
    links?: Array<{ key: number, url: string }>,
    permissaoUsuario?:number,
    loading?: boolean,
}) => {

    const classes = useStyles();
    const { pallet } = usePallet();

    const height = 250;
    const width = 400;


    /////////////////////////////////////
    /// Variaveis do Chart.js
    /////////////////////////////////////
    const data = {
        labels: props.labels.map((i) => { return (i.key) }),
        datasets: [{
            label: props.titleChart,
            data: props.labels.map((i) => { return (i.data) }),
            backgroundColor: props.labels.map((i) => { return (i.color) }),
            hoverBackgroundColor: props.labels.map((i) => { return (i.color) }),
            borderWidth: 0
        }],

    };
    const options = {
        plugins: {
            datalabels: {
                display: true,
                color: "#FFFFFF",
                // backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 2,

                font: {
                    size: 15,
                    weight: 600
                },
                formatter: (value, context) => {
                    if (value === 0) {
                        return ""
                    }
                    return " " + Math.round((value / (context.dataset.data.reduce((a, b) => a + b, 0) / 100))) + '% '; //Retornar porcentagem
                }
            },
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        segmentShowStroke: false
    };

    return (
        <Paper>
            <div className={classes.paper} style={{ backgroundColor: `${pallet.backgroundColorTertiary}` }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Typography
                        style={{
                            color: `${pallet.backgroundColorPrimary}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            paddingRight: "20px"
                        }}>
                        {props.masterTitle}
                    </Typography>
                </Grid>
            </div>
            <Divider />
            <DadosPendencias
                valueMinhas={props.total}
                valueDepartamento={props.seguindo}
                titleMinhas={"Total"}
                //linkMinhas={"/proposicao?filtro=[{'key':'autor','label':'Autor','value':''}]"}
                linkMinhas={props?.links[0]["url"]}
                titleDepartamento={"Seguidas"}
                //linkDepartamento={"/agenda"}
                linkDepartamento={props?.links[1]["url"]}

            />
            {props.permissaoUsuario !== 4 ? 
            <>
            <Divider />
            <div className={classes.paper}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Typography
                        style={{
                            color: `${pallet.general.color4}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            paddingRight: "20px"
                        }}>
                        {props.titleChart}
                    </Typography>
                </Grid>
            </div>
            {props.seguindo <= 0 ?

                props.loading
                    ?

                    <CircularLoading
                        size={80}
                        top={"90%"}
                        left={"50%"}
                        zIndex={2}
                    />

                    :

                    <Grid
                        style={{
                            color: `${pallet.textColorQuaternary}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "150px",
                            marginTop: "20px",
                        }}
                    >
                        <EmptyError title="Ops!" description="Ainda não temos dados suficientes para gerar resultados." />
                    </Grid>

                :

                <Grid>
                    <Box display="flex" justifyContent="center" style={{ paddingTop: "30px", paddingBottom: "30px" }} >
                        <Doughnut
                            width={width}
                            height={height}
                            data={data}
                            options={options}
                            plugins={[ChartDataLabels]}
                        />
                    </Box>
                    <Divider />
                    <Box justifyContent="flex-start" style={{ paddingTop: "5px", paddingRight: "20", paddingBottom: "18px", paddingLeft: "25px" }} >
                        {props.labels.map(i => (
                            <Grid container key={i.key} style={{ marginTop: `${isMobile ? "7px" : "13px"}` }}>
                                <div style={{
                                    backgroundColor: `${i.color}`,
                                    width: "25px", height: "5px",
                                    borderRadius: "3px",
                                    marginTop: "8px",
                                    marginRight: "12px"
                                }} />
                                <div style={{
                                    marginRight: "12px",
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.subtitle
                                }}>
                                    {i.url ?
                                        <Link href={i.url} style={{
                                            color: `${pallet.color.secundary.font}`,
                                            fontSize: `${pallet.fontSize.titleWidgets}`,
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.fontWeight.subtitle,
                                            textDecoration: "none"
                                        }}>
                                            {i.key}
                                        </Link>
                                        :
                                        i.key
                                    }
                                </div>
                                <div style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontWeight: pallet.fontWeight.lighter,
                                    color: `${pallet.color.quaternary.font}`
                                }}>{i.data}</div>
                            </Grid>
                        ))}
                    </Box>
                </Grid>}

                    </>
                    :
                    null
                            }
        </Paper>

    )
}

export default DoughnutChartParlamentarProposicao;