import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../../components/carousel/TabPanel";
import { usePallet } from "../../../../contexts/PalletContext";
import ProposicaoApensadas from "../apensadas/ProposicaoApensadas";


const ProposicaoCarouselTabsApensadas = (props: { id_proposicao: number, activeTab: any, index: number, proposicao_integrada?: boolean }) => {
    const { pallet } = usePallet();



    return (

        <TabPanel value={props.activeTab} index={props.index}>
            <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "30px"}` }}>
                <ProposicaoApensadas id_proposicao={props.id_proposicao} index={props.index} activeTab={props.activeTab} proposicao_integrada={props.proposicao_integrada} />
            </Paper>
        </TabPanel>

    )
}


export default ProposicaoCarouselTabsApensadas;