import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import { CircularProgressWithLabel } from '../../../components/CircularProgressWidgets';

const IndicatoresPrioridade = (props: {
    value: number,
    title: string,
    tag: string,
    link: string,
}) => {
    return (
        <Tooltip title={props.tag} TransitionProps={{ timeout: 600 }} placement={'right-end'}>
            <Grid item>
                {/* Componente abaixo precisa receber: Cor do Progress + numero percentual + title link + href do link*/}
                <CircularProgressWithLabel
                    value={props.value}
                    title={props.title}
                    link={props.link}
                />
            </Grid>
        </Tooltip>
    )
}


// const IndicatoresPrioridade = (props: {
//     valor_prioridade_minima: number,
//     valor_prioridade_baixa: number,
//     valor_prioridade_media: number,
//     valor_prioridade_alta: number,
//     valor_prioridade_maxima: number,
//     titleMinima: string,
//     titleBaixa: string,
//     titleMedia: string,
//     titleAlta: string,
//     titleMaxima: string,
//     linkMinima: string,
//     linkBaixa: string,
//     linkMedia: string,
//     linkAlta: string,
//     linkMaxima: string,
// }) => {
//     const { pallet } = usePallet();
//     return (
//         <div>
//             <Grid container justifyContent="space-around">
//                 <Grid item>
//                     {/* Componente abaixo precisa receber: Cor do Progress + numero percentual + title link + href do link*/}
//                     <CircularProgressWithLabel
//                         value={props.valor_prioridade_minima}
//                         title={props.titleMinima}
//                         link={props.linkMinima}
//                     />
//                 </Grid>
//                 <Grid item>
//                     <CircularProgressWithLabel
//                         value={props.valor_prioridade_baixa}
//                         title={props.titleBaixa}
//                         link={props.linkBaixa}
//                     />
//                 </Grid>
//                 <Grid item>
//                     <CircularProgressWithLabel
//                         value={props.valor_prioridade_media}
//                         title={props.titleBaixa}
//                         link={props.linkBaixa}
//                     />
//                 </Grid>
//                 <Grid item>
//                     <CircularProgressWithLabel
//                         value={props.valor_prioridade_alta}
//                         title={props.titleAlta}
//                         link={props.linkAlta}
//                     />
//                 </Grid>
//                 <Grid item>
//                     <CircularProgressWithLabel
//                         value={props.valor_prioridade_maxima}
//                         title={props.titleMaxima}
//                         link={props.linkMaxima}
//                     />
//                 </Grid>
//             </Grid>
//         </div>
//     )
// }

export default IndicatoresPrioridade;