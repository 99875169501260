import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { usePallet } from '../contexts/PalletContext';

interface IProps {
  icon: any,
  title: string,
  backgroundColor: string,
  fontColor: string,
  fontSize: string,
  tipo: string,
  onClick(key: string): void,
  border: string,
  padding?: string,
  onBlock?: number
}

export default function IconLabelButtons(props: IProps) {

  const { pallet } = usePallet();


  return (
    <Stack direction="row" spacing={2}>
      <Button
        disabled={props.onBlock > 10000 ? true : false}
        onClick={() => { props.onClick(props.tipo) }}
        variant="outlined"
        startIcon={props.icon}
        style={{
          fontSize: `${props.fontSize}`,
          backgroundColor: `${props.onBlock > 4000 ? 'red' : props.backgroundColor}`,
          color: `${props.fontColor}`,
          border: `${props.border}`,
          borderRadius: "5px",
          fontWeight: pallet.general.weight_600,
          padding: `${props.padding || "10px"}`,
          fontFamily: `${pallet.general.fontFamily}`,
          alignItems: "center",
          letterSpacing: "1px",
        }}>
        {props.onBlock > 10000 ? 'Caracteres Excedidos' : props.title}
      </Button>
    </Stack>
  );
}