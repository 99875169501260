import * as Yup from "yup";

const TCCadastroTemasCorporativoSchema = Yup.object({

    descricao: Yup.string().required({ name: "nome", err: "Nome é obrigatório"}),
    ativo: Yup.bool().required({ name: "status", err: "Status é obrigatório" }),
    id_tema_grupo: Yup.number().typeError({name: "grupo", err: "Grupo é obrigatório"}).required({ name: "grupo", err: "Grupo é obrigatório" }),
});


export default TCCadastroTemasCorporativoSchema;
