import * as http from '../helpers/http';

class AgendaService {

    GetList = async (dtIini: any, dtFin: any, tipo: string,
        // origin: string
    )
        : Promise<any> => {
        // const result = await http.get(`/agenda/dtIni=${dtIini}/dtFin=${dtFin}/tipo=${tipo}`);
        const result = await http.get(`/agenda/${dtIini}/${dtFin}/${tipo}`);

        if (result.success
            // && origin === 'c'
        ) {
            return {

                success: true,
                // listInfo: {
                //     pageSize: result.data.pageSize,
                //     pageIndex: result.data.pageIndex,
                //     pageItens: result.data.pageItens,
                //     totalItens: result.data.totalItens,
                //     totalPages: result.data.totalPages
                // },
                lista: result
            }
        }
        return undefined;
    }



    GetListRelatorio = async (dtIni: any, dtFin: any, tipo: string): Promise<any> => {

        const result = await http.get(`/agenda/relatorio/${dtIni}/${dtFin}/${tipo}`);
        if (result.success) {
            return {
                success: true,
                informacoes: result
            }

        }
        return undefined;
    }

    Create = async (data: any): Promise<number> => {
        const result = await http.post(`/agenda/`, data);
        if (result.success) { return result.data }
        return 0;
    };

    Get = async (idAgenda: number): Promise<any> => {
        const result = await http.get(`/agenda/${idAgenda}`);
        if (result.success) { return result }
        return undefined;
    };

    Update = async (data: any, idAgenda: number, tag?: string): Promise<boolean> => {
        const result = await http.put(`/agenda/${idAgenda}/${tag}`, data);
        if (result.success) { return result.success }
        return false;
    };

    Delete = async (idAgenda: number): Promise<boolean> => {

        const result = await http.del(`/agenda/${idAgenda}`);
        if (result.success) { return true }
        return false;
    };


}

export default new AgendaService();
