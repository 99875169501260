import PrivateRoute from '../../components/PrivateRoute';
import Dashboard from './Dashboard';

export function DashboardRoutes(): JSX.Element {
    return (
        <>
            {/* <PrivateRoute path="/" element={< Dashboard />} /> */}
            <PrivateRoute path="/dashboard" element={< Dashboard />} />
            <PrivateRoute path="/dashboard" element={< Dashboard />} />
        </>
    );
}
