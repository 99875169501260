import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import useStyles from './layout/Styles';
import { usePallet } from '../contexts/PalletContext';




export default function InputTextField(
    props: {
        value: string,
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
        id: any,
        label?: any,
        type?: string,
        maxRows: number,
        minRows?: number,
        placeholder?: string,
        focused?: boolean,
        defaultValue?: string,
        size?: any,
        maxLength?:number
    }) {

    const classes = useStyles();
    const { pallet } = usePallet();

    return (
        <TextField
            size={props.size ? props.size : 'medium'}
            type={props.type ? props.type : ""}
            placeholder={props.placeholder ? props.placeholder : ""}
            multiline={props.type === "number" ? false : true}
            minRows={props.minRows ? props.minRows : 0}
            maxRows={props.maxRows}
            value={props?.value ? props?.value : props.defaultValue}
            focused={props.focused ? props.focused : false}
            onChange={props.onChange}
            style={{ width: "100%" }}
            id={props.id}
            label={props.label}
            variant="outlined"
            inputProps={{maxLength: props.maxLength ? props.maxLength : false,}}
            InputProps={{
             
                classes: {
                    
                    notchedOutline: classes.notchedOutline,
                },
                style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size14}`,
                    fontWeight: pallet.general.weight_100,
                }
            }}
            InputLabelProps={{
                style: { color: "grey" }
            }}
        />
    );
}