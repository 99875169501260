import React, { useCallback, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, MenuItem, styled, TextField, Typography, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import AccordionTextDataAcaoInfluenciaParlamentar from "./AccordionTextDataAcaoInfluenciaParlamentar";
import PaginationSaveState from "../../../components/PaginationSaveState";
import { IListInfo } from "../../../models/IListInfo";
import ParlamentarMenuFiltroTipoComissao from "../../../components/ParlamentarMenuFiltroTipoComissao";
import { Visibility } from "@material-ui/icons";
import ParlamentarDemandaService from "../../../services/ParlamentarDemandaService";
import AccordionTextDataDemandaParlamentar from "./AccordionTextDataDemandaParlamentar";
import ParlamentarMenuFiltroTipoDemanda from "../../../components/ParlamentarMenuFiltroTipoDemanda";
import useStyles from '../../../components/layout/Styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AnexosComponent, { EAnexoModulos } from "../../../components/Anexos";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from "util";
import InputDatePicker from "../../../components/InputDatePicker";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import AccordionTextDataDemandaNova from "./AccordionTextDataDemandaNova";
import CircularLoading from '../../../components/CircularLoading';
import EmptyErrorDoc from "../../../components/EmptyErrorDoc";
import { couldStartTrivia } from "typescript";

export default function ParlamentarDemandasDemanda(props: { id_parlamentar: number, loading?: boolean, idPermissao?: number }) {

    const classes = useStyles();
    const { pallet } = usePallet();
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filtro, setFiltro] = useState<string>('T');

    const [idReferencia, setIdReferencia] = React.useState<number>();
    const [loading, setLoading] = useState(false);
    const [loadingAfterSalvar, setLoadingAfterSalvar] = useState(false);

    const [dataPrazo, setDataPrazo] = useState('');
    const handleReferencia = () => { idReferencia ? setIdReferencia(undefined) : setIdReferencia(97); }

    const [resultado, setResultado] = useState('');


    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [currentPage, filtro])

    const loadingListAfterSalvar = () => {
        setLoadingAfterSalvar(true)
        // setCurrentPage(1)
        getList()
        setTimeout(() => {
            setLoadingAfterSalvar(false)
        }, 1000);
    }

    const getList = useCallback(async () => {
        const f = [{ key: 'status', value: filtro }]

        if (currentPage && currentPage > 0)
            await ParlamentarDemandaService.GetList(props.id_parlamentar, f, currentPage, 8).then(async res => {
                if (res && res.success) {
                    setLista(res.lista);
                    setInfoList(res.listInfo);
                    setTimeout(() => {
                        setLoading(false);
                        //setLoadingAfterSalvar(false)
                    }, 1000);
                    //setLoading(false);
                }
            })
    }, [props.id_parlamentar, currentPage, filtro, loading])

    const setPage = (p: number) => setCurrentPage(p);
    const setFilter = (f: any) => setFiltro(f.target.value);

    const handlerResultadoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResultado(event.target.value)
    };

    const handleChangeNovaDemanda = () => {
        setLoading(true);
        getList();
    };

    return (
        <div>
            {
                loading === true
                    ?

                    <CircularLoading
                        size={80}
                        top={"90%"}
                        left={"50%"}
                        zIndex={2}
                    />

                    :

                    infoList?.totalItens === undefined || (infoList?.totalItens <= 0 && infoList?.totalNoFilter === 0) ?

                        <Grid>

                            <Grid direction="row" alignItems="baseline" style={{
                                paddingLeft: `${isMobile ? "30px" : "45px"}`,
                                paddingRight: `${isMobile ? "30px" : "50px"}`,
                                paddingTop: `${isMobile ? "10px" : "10px"}`,
                                paddingBottom: `${isMobile ? "0px" : "50px"}`,

                            }} >
                                {/* //Nova Demanda */}
                                <AccordionTextDataDemandaNova
                                    // listaNovaDemanda={lista}
                                    onClick={loadingListAfterSalvar}
                                    id_parlamentar={props?.id_parlamentar}
                                    onChange={handleChangeNovaDemanda}
                                    lista={infoList}
                                    idPermissao={props.idPermissao}
                                />
                            </Grid>

  

                        </Grid>



                        :

                        <Grid style={{ paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                            <Grid item xl={12} lg={12} xs={12} >
                                <Grid direction="row" alignItems="baseline" style={{
                                    paddingLeft: `${isMobile ? "30px" : "45px"}`,
                                    paddingRight: `${isMobile ? "30px" : "50px"}`,
                                    paddingTop: `${isMobile ? "10px" : "10px"}`,
                                    marginBottom: `${isMobile ? "0px" : "0px"}`,

                                }} >
                                    {/* //Nova Demanda */}
                                    <AccordionTextDataDemandaNova
                                        // listaNovaDemanda={lista}
                                        onClick={loadingListAfterSalvar}
                                        id_parlamentar={props?.id_parlamentar}
                                        onChange={handleChangeNovaDemanda}
                                        lista={infoList}
                                        idPermissao={props.idPermissao}
                                    />

                                </Grid>
                                <Divider variant="fullWidth" style={{ margin: "30px 0px 0px 0px" }} />
                                <Grid container item alignItems="flex-start" style={{
                                    paddingLeft: `${isMobile ? "10px" : "30px"}`,
                                    paddingRight: `${isMobile ? "10px" : "30px"}`,
                                    paddingTop: `${isMobile ? "10px" : "30px"}`,
                                    marginBottom: `${isMobile ? "-10px" : "10px"}`
                                }} >
                                    <Grid container item alignItems="baseline" xs={12} >
                                        <Grid xs={6} container item alignItems="baseline">
                                            <Grid item style={{
                                                marginRight: `${isMobile ? "8px" : "8px"}`,
                                                paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                            }}>
                                                <Grid item style={{
                                                    fontSize: "18px",
                                                    color: `${pallet.textColorTertiary}`,
                                                    fontWeight: pallet.general.weight_600
                                                }}>
                                                    {infoList?.totalItens}
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <Grid item style={{
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: "14px",
                                                    fontWeight: pallet.general.weight_600,
                                                    paddingTop: `${isMobile ? "20px" : "15px"}`,
                                                }}>
                                                    {infoList?.totalItens > 1 ? "DEMANDAS" : "DEMANDA"}
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid xs={6} container item justifyContent="flex-end" >
                                            <Grid item>
                                                <Grid style={{ paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: `${isMobile ? "10px" : "0px"}`, paddingLeft: `${isMobile ? "0" : "0px"}` }}>
                                                    <ParlamentarMenuFiltroTipoDemanda title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{
                                        width: "100%",
                                        paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                        paddingRight: `${isMobile ? "20px" : "20px"}`,
                                        paddingTop: "20px",
                                        paddingBottom: `${isMobile ? "20px" : "40px"}`
                                    }}
                                    >
                                        {loading || loadingAfterSalvar ?
                                            <CircularLoading
                                                size={80}
                                                top={"50%"}
                                                left={"50%"}
                                                zIndex={2}
                                            />

                                            :
                                            <AccordionTextDataDemandaParlamentar
                                                lista={lista}
                                                onChange={handleChangeNovaDemanda}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                                    <PaginationSaveState
                                        name={'ParlamentarComissaoList'}
                                        pages={infoList?.totalPages}
                                        page={currentPage}
                                        onChange={setPage}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
            }

        </div >
    )
}