import React, { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import PlaceIcon from '@material-ui/icons/Place';
import CallIcon from '@material-ui/icons/Call';
import { IParlamentar } from "../../../models/Parlamentar.Model"
import { usePallet } from "../../../contexts/PalletContext";
import useStyles from "../../../components/layout/Styles";
import { calculaIdade } from "../../../utils/dateUtils";
import format from "date-fns/format";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { IUser } from '../../../models/User.Model';
import { UsuarioRoutes } from '../../usuarios/_Usuarios.Routes';
// import ParlamentarService from '../../../services/ParlamentarService';
const CustomGrid = (props: { children: React.ReactNode, style?: any }): JSX.Element => {
    const { pallet } = usePallet();
    return (
        <Grid style={{
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.text_10}`,
            ...props.style
        }}>
            {props.children}
        </Grid>
    )
}
export const GerUsuariosConsultaInformacoes = (props: { usuario: IUser }) => {
    const { pallet } = usePallet();
    const classes = useStyles();
    // useEffect(() => {
    //     TabelasService(event.target.value).then(
    //         res => {
    //             const data = []
    //             data.push({ key: '', label: '-- Selecione -- ', value: '' });
    //             res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.nome }))
    //             setArrayMunicipioNascimento(() => [...data]);
    //         })
    // }, []);

    return (
        <Grid style={{ paddingLeft: `${isMobile ? "20px" : "50px"}`, paddingRight: `${isMobile ? "20px" : "50px"}`, paddingTop: "20px", paddingBottom: "20px" }}>
            <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                        height: "10px",
                        borderRadius: "5px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        flexFlow: "row-reverse",
                    }}
                >
                    <Typography className={classes.heading} style={{
                        color: `${pallet.textColorTertiary}`,
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size14}`,
                        fontWeight: pallet.general.weight_600,
                        marginLeft: "10px",
                    }}>
                        INFORMAÇÕES
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{
                    fontFamily: `${pallet.general.fontFamily}`
                }}>
                    {/* <MyComponent /> */}
                    <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "20px 10px 10px 20px"}`, width: "100%" }}>
                        <Grid xl={12}>

                            <Grid xl={12} container justifyContent="flex-start">
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", marginTop: "10px", fontWeight: pallet.general.weight_600 }}>
                                        LOGIN
                                    </CustomGrid>
                                    <Grid style={{ color: "black", fontWeight: 100 }}>{props.usuario?.login || "Não Informada"}</Grid>
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        UNIDADE DE NEGÓCIO
                                    </CustomGrid>
                                    {<Grid>{`${props.usuario?.dep_nome || "Não Informada"}` || "Não Informada"}</Grid>}
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        PERMISSÃO
                                    </CustomGrid>
                                    {<Grid>{`${props.usuario?.permissao || "Não Informada"}` || "Não Informada"}</Grid>}
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        STATUS
                                    </CustomGrid>
                                    <Grid>{props.usuario?.situacao === 1 ? "Ativo" : "Desativado"}</Grid>
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`,marginRight: "auto" ,marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        E-MAIL
                                    </CustomGrid>
                                    <Grid>{`${props.usuario?.email || "Não Informado"}` || "Não Informado"}</Grid>
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        TELEFONE
                                    </CustomGrid>
                                    <Grid>{`${props.usuario?.telefone || "Não Informado"}` || "Não Informado"}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid >
    );
}