import React from "react";
import { usePallet } from "../contexts/PalletContext";

import {
  BarChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar
} from "recharts";
import { Grid, Paper, Typography } from "@mui/material";
import { ViewArraySharp } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import { useNavigate } from 'react-router-dom';
import { formatDate } from "../utils/dateUtils";

export default function App(props: {
  dataresult?: Array<{ ano?: string, mes?: string, qtd?: string }>, dtIni?: Date, dtFin?: Date, listaDados?: Array<{ nome: string, id_parlamentar: number, count: string }>
}) {

  const navigate = useNavigate();

  const dataMap = [
    {
      id: 0,
      name: "",
      pv: 0,
      onClick: () => navigate('/interlocutor/' + props.listaDados[0].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')
      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[0].id_parlamentar + '"}]')

    },
    {
      id: 1,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[1].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[1].id_parlamentar + '"}]')

    },
    {
      id: 2,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[2].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[2].id_parlamentar + '"}]')
    },
    {
      id: 3,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[3].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[3].id_parlamentar + '"}]')
    },
    {
      id: 4,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[4].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[4].id_parlamentar + '"}]')
    },
    {
      id: 5,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[5].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[5].id_parlamentar + '"}]')
    },
    {
      id: 6,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[6].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[6].id_parlamentar + '"}]')
    },
    {
      id: 7,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[7].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[7].id_parlamentar + '"}]')
    },
    {
      id: 8,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[8].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[8].id_parlamentar + '"}]')
    },
    {
      id: 9,
      name: "",
      pv: 0,
      onClick: () =>  navigate('/interlocutor/' + props.listaDados[9].id_parlamentar + '?filtro=[{"dtInicio:"'+formatDate(props.dtIni, "yyyy-MM-dd")+'},{"dtFin:"' + formatDate(props.dtFin, "yyyy-MM-dd") +  '}]')

      // onClick: () => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtComissaoIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
      // formatDate(props.dtIni, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtIni, "MM/dd/yyyy") +
      // '"},{"key":"dtComissaoFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
      // formatDate(props.dtFin, "dd/MM/yyyy") +
      // '","value" : "' +
      // formatDate(props.dtFin, "MM/dd/yyyy") +
      // '"},{"key":"cpi","label":"CPIs","value":"' +  props.listaDados[9].id_parlamentar + '"}]')
    }
  ];

  const { pallet } = usePallet();

  var tooltip;

  dataMap.map((item) => {
    item.pv = 0;
    item.name = "";
  });

  props?.listaDados?.map((item, x) => {
    dataMap[x].pv = Number(item.count);
    dataMap[x].name = item.nome;
  });




  const CustomTooltip = ({ active, payload, label }: { active: any, payload: any, label: any }) => {

    if (active) {

      return (

        <Paper
          style={{
            fontSize: `${pallet.general.size14}`, background: `${pallet.backgroundColorTertiary}`
          }}

        >

          {`${payload[0].value}`}

        </Paper>


      );

    }

    return null;

  };

  return (

    <Grid>
      <ResponsiveContainer width={'100%'} height={500}>

        <BarChart style={{ cursor: 'pointer' }}
          barSize={100}
          data={dataMap}
          height={10}
          margin={{
            top: 40,
            right: 15,
            left: isMobile ? -30 : -15,
            bottom: 120
          }}
        >
          <CartesianGrid horizontal={true} vertical={false} />
          <XAxis dataKey="name" angle={300} interval={0} tickMargin={65} tick={{ fontFamily: `${pallet.fontFamily}`, fontSize: `${pallet.general.size12}` }} />
          <YAxis interval={0} scale={'linear'} tick={{ fontFamily: `${pallet.fontFamily}`, fontSize: `${pallet.general.size12}` }} />
          <Tooltip cursor={false} content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />

          <Bar
            dataKey="pv" barSize={isMobile ? 10 : 23} fill="#DED3FF" />
        </BarChart>
      </ResponsiveContainer>
    </Grid>

  );
}
