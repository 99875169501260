import * as React from 'react';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styled from "styled-components";
import Switch from '@mui/material/Switch';
import { Autocomplete, Chip, Box, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, MenuItem, Radio, RadioGroup, TextField, Checkbox, IconButton } from '@mui/material';
import { IFilterData } from './ListFilter';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBRLocale from "date-fns/locale/pt-BR";
import DateRangePicker from '@mui/lab/DateRangePicker';
import { DateRange } from '@mui/lab/DateRangePicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { addMonths } from 'date-fns';
import eventBus from '../../services/EventBus';
import { formatDate } from '../../utils/dateUtils';
import moment from 'moment';


const MainGrid = styled(Grid)`
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    max-height: 300px;
`;

const RadioGroupItem = styled(RadioGroup)`
    span { 
        font-weight: 300;
        font-size: 14px;
        color: #1E0736;
        
    }
`;

const InputDate = styled(Grid)`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
`;

const InputDateLabel = styled(Grid)`
    width: 48%; 
    position: absolute; 
    right: 90px;
    display: flex; 
    flex-wrap: nowrap; 
    align-items: center; 
    z-index: 1;

    fieldset {
        border: 0px;
    }
    .MuiBox-root {
        color: rgba(0, 0, 0, 0.37);
    }
`;
export interface IListFilterRadioItem {
    label: string,
    value: string,
    // checkChecked?: (key: string) => any
}

interface IListFilterCheckboxItem {
    label: string;
    value: string;
}


export const ListFilterCheckBoxItem = (props: {
    currentFilter: IFilterData[];
    filterKey: string;
    label: string;
    itens: IListFilterRadioItem[];
    onChange: (data: any, value: any) => void;
    switch?: boolean;
}): JSX.Element => {

    const check = (key: string, value: any): boolean => {
        const item = props.currentFilter.filter(item => item.key === key && item.value === value);
        if (item.length === 1) return !!(item[0].value === value);
        else return false;
    };

    const switchCheck = (key: string): boolean => {

        const item = props.currentFilter.filter(item => item.key === key);
        if (item.length >= 1) return false;
        else return true;
    };
    return (
        <MainGrid container >
            <FormControl component="fieldset">
                <FormGroup>
                    {props.itens.map((item, index) =>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={index}
                                    name={props.filterKey}
                                    id={props.label}
                                    value={item.value}
                                    inputProps={{
                                        'aria-label': item.label
                                    }}
                                    checked={props.switch ? switchCheck(props.filterKey) : check(props.filterKey, item.value)}
                                    onChange={props.onChange}></Checkbox>}
                            label={item.label}
                            style={{ color: "#1E0736" }}
                        />)}
                </FormGroup>
            </FormControl>
        </MainGrid>
    );
}


export const ListFilterCheckBoxItemInvertido = (props: {
    currentFilter: IFilterData[];
    filterKey: string;
    label: string;
    itens: IListFilterRadioItem[];
    onChange: (data: any, value: any) => void;
    switch?: boolean;
}): JSX.Element => {

    const check = (key: string, value: any): boolean => {
        const item = props.currentFilter.filter(item => item.key === key && item.value === value);
        if (item.length === 1) return !!(item[0].value === value);
        else return false;
    };

    const switchCheck = (key: string): boolean => {

        const item = props.currentFilter.filter(item => item.key === key);
        if (item.length >= 1) return true;
        else return false;
    };
    return (
        <MainGrid container >
            <FormControl component="fieldset">
                <FormGroup>
                    {props.itens.map((item, index) =>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={index}
                                    name={props.filterKey}
                                    id={props.label}
                                    value={item.value}
                                    inputProps={{
                                        'aria-label': item.label
                                    }}
                                    checked={props.switch ? switchCheck(props.filterKey) : check(props.filterKey, item.value)}
                                    onChange={props.onChange}></Checkbox>}
                            label={item.label}
                            style={{ color: "#1E0736" }}
                        />)}
                </FormGroup>
            </FormControl>
        </MainGrid>
    );
}


export const ListFilterRadioItem = (props: {
    currentFilter: IFilterData[];
    filterKey: string;
    label: string;
    itens: IListFilterRadioItem[];
    onChange: (data: any, value: any) => void;
}): JSX.Element => {

    const check = (key: string, value: any): boolean => {
        const item = props.currentFilter.filter(item => item.key === key);
        if (item.length === 1) return !!(item[0].value === value);
        else if (value === '') return true;
        else return false;
    };

    return (
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <RadioGroupItem
                row
                name={props.filterKey}
                aria-label={props.label}
                onChange={props.onChange}
                style={{ justifyContent: 'space-around' }}
            >
                {props.itens?.map((item, index) => <FormControlLabel
                    key={index}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                    checked={check(props.filterKey, item.value)}
                />
                )}
            </RadioGroupItem>
        </FormControl>
    );
}

export const ListFilterCheckboxItemEsfera2 = (props: {
    currentFilter: IFilterData[];
    filterKey: string;
    label: string;
    items: IListFilterCheckboxItem[];
    onChange: (values: IFilterData[]) => void;
}) => {

    useEffect(() => {
        const filterItem = props.currentFilter.find(item => item.key === props.filterKey);
        if (!filterItem || filterItem.value.length === 0) {
            handleChange(props.items[0].value);
        }
    }, []);

    const check = (value: any): boolean => {
        const filterItem = props.currentFilter.find(item => item.key === props.filterKey);
        return filterItem ? filterItem.value.includes(value) : false;
    };

    const checkSelectedTypeEsfera = (valueSelected: string, filterItem: any) => {
        const removeItens = valueSelected === "Federal" ? ["Estadual", "Municipal"] : ["Federal"];
        filterItem.value = filterItem.value.filter(item => !removeItens.includes(item));

        // Atualizar a string da chave label
        const labelPrefix = filterItem.label.split(':')[0]; // "ESFERA"
        const newLabel = filterItem.value.length > 0 ? filterItem.value.join(', ') : 'Nenhum';
        filterItem.label = `${labelPrefix}: ${newLabel}`;
    };

    const handleChange = (value: any) => {
        const filterItem = props.currentFilter.find(item => item.key === props.filterKey);
        let updatedFilter: IFilterData[];

        if (props.filterKey === "esfera" && filterItem) {
            checkSelectedTypeEsfera(value, filterItem);
        }

        if (filterItem) {
            const newValue = check(value)
                ? filterItem.value.filter((v: any) => v !== value)
                : [...filterItem.value, value];

            const newLabel = `${props.label.toUpperCase()}: ${newValue.join(', ')}`;

            updatedFilter = props.currentFilter.map(item =>
                item.key === props.filterKey
                    ? { ...item, value: newValue, label: newLabel }
                    : item
            );
        } else {
            const newValue = [value];
            const newLabel = `${props.label.toUpperCase()}: ${newValue.join(', ')}`;

            updatedFilter = [
                ...props.currentFilter,
                {
                    key: props.filterKey,
                    label: newLabel,
                    value: newValue,
                }
            ];
        }

        props.onChange(updatedFilter);
    };

    return (
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormGroup>
                <Grid container style={{ justifyContent: 'space-around', width: '100%' }}>
                    {props.items.map((item, index) => (
                        <Grid item key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={check(item.value)}
                                        onChange={() => handleChange(item.value)}
                                    />
                                }
                                label={item.label}
                            />
                        </Grid>
                    ))}
                </Grid>
            </FormGroup>
        </FormControl>
    );
};

export const ListFilterCheckboxItemEsfera = (props: {
    currentFilter: IFilterData[];
    filterKey: string;
    label: string;
    items: IListFilterCheckboxItem[];
    onChange: (values: IFilterData[]) => void;
}) => {

    const check = (value: any): boolean => {
        const filterItem = props.currentFilter.find(item => item.key === props.filterKey);
        return filterItem ? filterItem.value.includes(value) : false;
    };

    const handleChange = (value: any) => {
        const filterItem = props.currentFilter.find(item => item.key === props.filterKey);
        let updatedFilter: IFilterData[];

        // if (props.filterKey === "esfera" && filterItem) { checkSelectedTypeEsfera(value, filterItem); };

        if (filterItem) {
            const newValue = check(value)
                ? filterItem.value.filter((v: any) => v !== value)
                : [...filterItem.value, value];

            const newLabel = `${props.label.toUpperCase()}: ${newValue.join(', ')}`;

            updatedFilter = props.currentFilter.map(item =>
                item.key === props.filterKey
                    ? { ...item, value: newValue, label: newLabel }
                    : item
            );
        } else {
            const newValue = [value];
            const newLabel = `${props.label.toUpperCase()}: ${newValue.join(', ')}`;

            updatedFilter = [
                ...props.currentFilter,
                {
                    key: props.filterKey,
                    label: newLabel,
                    value: newValue,
                }
            ];
        }

        props.onChange(updatedFilter);
    };

    return (
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormGroup>
                <Grid container style={{ justifyContent: 'space-around', width: '100%' }}>
                    {props.items.map((item, index) => (
                        <Grid item>
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={check(item.value)}
                                        onChange={() => handleChange(item.value)}
                                    />
                                }
                                label={item.label}
                            />
                        </Grid>
                    ))}
                </Grid>
            </FormGroup>
        </FormControl>
    );
};

export interface IListFilterSwitchItem {
    key: string,
    label: string
}

export const ListFilterSwitchItem = (props: {
    currentFilter: IFilterData[];
    filterKey: string,
    label: string,
    itens: IListFilterSwitchItem[],
    onChange: (data: any, value: any) => void
}) => {

    const check = (key: string): boolean => {
        const item = props.currentFilter.filter(item => item.key === key);
        return (item.length === 1);
    };


    return (
        <FormControl component="fieldset">
            {props.itens?.map((item, index) => {
                return (
                    <Grid container key={index}>
                        <Switch key={index} name={item.key} onChange={props.onChange} checked={check(item.key)} />{item.label}
                    </Grid>
                )
            })}
        </FormControl>
    )
}


export interface IListFilterInputItem {
    key: string,
    label: string,
    value: string,
}

export interface IListFilterInputItemNew {
    key: string,
    label: string,
    value: string,
    id: any
}

interface ListFilterInputItemProps {
    currentFilter: IFilterData[];
    filterKey: string;
    label: string;
    itens: IListFilterInputItem[];
    onChange: (data: any, value: any) => void;
}

export const ListFilterInputItem = forwardRef((props: ListFilterInputItemProps, ref) => {
    const check = (key: string): string => {
        const item = props?.currentFilter?.filter(item => item.key === key);
        return item.length === 1 ? item[0].value : '';
    };

    const [selectedItem, setSelectedItem] = useState(check(props.filterKey));

    const handleChange = (event) => {

        const selectedValue = event.target.value;
        event.target.name = props.filterKey;
        setSelectedItem(selectedValue);
        props.onChange(event, selectedValue);
    };

    const handleRemove = (key?: any) => {
        setSelectedItem('');
        props.onChange({ target: { name: key ? key : props.filterKey } }, '');
    };

    useImperativeHandle(ref, () => ({
        clear: (key: string) => handleRemove(key),
    }));

    return (
        <MainGrid container>
            <TextField
                id={`outlined-select-${props.filterKey}`}
                select
                label={props.label}
                value={props.currentFilter.length === 0 ? '' : selectedItem}
                onChange={handleChange}
                style={{ width: '95%', backgroundColor: 'white' }}
                InputProps={{
                    endAdornment: selectedItem ? (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={props.filterKey ? () => handleRemove(props.filterKey) : handleRemove}
                                style={{
                                    marginRight: '20px',
                                }}>
                                <CloseIcon style={{ fontSize: '15px' }} />
                            </IconButton>
                        </InputAdornment>
                    ) : null,
                }}
            >
                {props.itens?.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.value}
                        style={{
                            color: option.key === '' ? '#9B9B9B' : '#1E0736',
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            whiteSpace: "unset",
                            wordBreak: "break-all",
                            maxWidth: "500px",
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </MainGrid>
    );
});


export const MultipleListFilterInputItem = (props: {
    currentFilter: IFilterData[];
    filterKey: string,
    label: string,
    itens: IListFilterInputItem[],
    onChange: (data: any, value: any, id: any, cod: any) => void
}) => {
    const filterEstadosArray = props.currentFilter.filter(filter => filter.key === props.filterKey);

    let resultArray = [];
    if (filterEstadosArray.length > 0) {
        const filterValueArray = filterEstadosArray[0].value;
        if (props.itens) {
            resultArray = filterValueArray?.map(
                val => props?.itens?.find(item => item.value === val) || { key: val, label: val, value: val }
            );
        };
    };

    const handleChange = (event, selectedOptions) => {
        event.target.name = props.filterKey;
        let optionsId = selectedOptions.map(option => option.id);
        let optionsValue = selectedOptions.map(option => option.value);
        let optionsCod = selectedOptions.map(option => option.cod);
        props.onChange(event, optionsValue, optionsId, optionsCod);
    };

    return (
        <MainGrid container>
            <Autocomplete
                multiple
                id={`searchable-select-${props.filterKey}`}
                style={{ width: '100%', backgroundColor: 'white' }}
                options={props.itens}
                getOptionLabel={(option) => option.label}
                value={resultArray}
                onChange={handleChange}
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={props.label}
                        style={{ width: '95%', backgroundColor: 'white' }}
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={index}
                            label={option.label}
                            {...getTagProps({ index })}
                            style={{
                                color: option.key === '' ? '#9B9B9B' : '#1E0736',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '16px',
                                whiteSpace: "unset",
                                wordBreak: "break-all",
                                maxWidth: "380px"
                            }}
                        />
                    ))
                }
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                        <input
                            type="checkbox"
                            checked={selected}
                            style={{ marginRight: 8 }}
                        />
                        {option.label}
                    </li>
                )}
            />
        </MainGrid>
    );
};

export const MultipleListFilterInputItemMunicipio = (props: {
    currentFilter: IFilterData[];
    filterKey: string,
    label: string,
    itens: IListFilterInputItem[],
    onChange: (data: any, value: any, id: any, cod: any) => void
}) => {
    const filterEstadosArray = props.currentFilter.filter(filter => filter.key === props.filterKey);

    let resultArray = [];
    if (filterEstadosArray.length > 0) {
        const filterValueArray = filterEstadosArray[0].value;

        if (props.itens) {
            resultArray = filterValueArray?.map(
                val => props?.itens?.find(item => item.value === val) || { key: val, label: val, value: val }
            );
        };
    };

    const handleChange = (event, selectedOptions) => {
        event.target.name = props.filterKey;
        let optionsId = selectedOptions.map(option => option.id);
        let optionsValue = selectedOptions.map(option => option.value);
        let optionsCod = selectedOptions.map(option => option.cod);
        props.onChange(event, optionsValue, optionsId, optionsCod);
    };

    return (
        <MainGrid container>
            <Autocomplete
                multiple
                id={`searchable-select-${props.filterKey}`}
                style={{ width: '100%', backgroundColor: 'white' }}
                options={props.itens}
                getOptionLabel={(option) => option.label}
                value={resultArray}
                onChange={handleChange}
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={props.label}
                        style={{ width: '95%', backgroundColor: 'white' }}
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={index}
                            label={option.label}
                            {...getTagProps({ index })}
                            style={{
                                color: option.key === '' ? '#9B9B9B' : '#1E0736',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '16px',
                                whiteSpace: "unset",
                                wordBreak: "break-all",
                                maxWidth: "500px"
                            }}
                        />
                    ))
                }
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                        <input
                            type="checkbox"
                            checked={selected}
                            style={{ marginRight: 8 }}
                        />
                        {option.label}
                    </li>
                )}
            />
        </MainGrid>
    );
};


export const ListFilterTextItem = (props: {
    currentFilter: IFilterData[];
    filterKey: string,
    label: string,
    onChange: (data: any, value: any) => void,
    placeholder?: string,
}) => {

    const check = (key: string): string => {
        const item = props.currentFilter.filter(item => item.key === key);

        if (item.length === 1) {
            return item[0].value;
        }
        else return '';
    };

    const [value, setValue] = React.useState<string>(check(props.filterKey));
    const handleChange = (event) => {
        setValue(event.target.value);
        event.target.name = props.filterKey;
        props.onChange(event, event.target.value)
    };

    return (
        <MainGrid container>
            <TextField
                fullWidth
                placeholder={props.placeholder}
                variant='outlined'
                id={`outlined-text-${props.filterKey}`}
                label={props.label}
                name={props.filterKey}
                value={check(props.filterKey)}
                onChange={handleChange}
                style={{ width: '95%', backgroundColor: 'white' }}
            />
        </MainGrid>
    )
}

export const ListFilterAutocompleteItem = (props: {
    currentFilter: IFilterData[];
    filterKey: string,
    label: string,
    placeholder?: string,
    itens: IListFilterInputItem[],
    onChange: (data: any, value: any) => void,
    onSelect: (data: any, value: any) => void,
    onSubmit?: () => void
}) => {
    const check = (key: string): string => {
        const item = props.currentFilter.filter(item => item.key === key);

        if (item.length === 1) {
            return item[0].value;
        }
        else return '';
    };


    const [value, setValue] = React.useState<string>(check(props.filterKey));
    const [inputValue, setInputValue] = React.useState(check(props.filterKey));

    useEffect(() => {
        if (props.filterKey) {
            const filterKeyValue = check(props.filterKey);
            setValue(filterKeyValue);
        }
    }, [props.filterKey])

    const handleChange = (event, value: string | null) => {
        if (event && event.type === "change") {
            event.target.name = props.filterKey;
            props.onChange && props.onChange(event, value.trim())
        }
        setInputValue(value);
        setValue(value);
    };

    const handleSelect = (event, value: any) => {
        if (value) {
            setInputValue(value);
            setValue(value);
            event.target.name = props.filterKey;
            props.onSelect && props.onSelect(event, value);
        }
    };
    const handleKeyPress = (event) => {
        if (event.charCode === 13) {
            if (props.onSubmit) props.onSubmit()
        }
        return true
    };

    return (
        <MainGrid container>
            <Autocomplete
                // selectOnFocus
                disablePortal
                // id={`combo-box-${props.label}`}
                fullWidth
                noOptionsText
                // clearOnBlur
                blurOnSelect
                value={value}
                inputValue={inputValue}
                options={props.itens}
                sx={{ width: '100%' }}
                renderInput={(params) =>
                    <TextField
                        fullWidth
                        placeholder={props.placeholder}
                        variant='outlined'
                        {...params}
                        id={`outlined-select-${props.filterKey}`}
                        label={props.label}
                        name={props.filterKey}
                        style={{ width: '95%', backgroundColor: 'white' }}

                    />
                }
                onKeyPress={handleKeyPress}
                onInputChange={handleChange}
                onChange={handleSelect}
            />
        </MainGrid>
    )
}

export const ListFilterRangeDatePickerItem = (props: {
    currentFilter: IFilterData[],
    filterKey: string,
    label: string,
    keyIni?: string,
    keyFin?: string,
    clear?: string,
    backgroundColor?: boolean,
    onChange: (data: any, value: DateRange<Date>) => void,
    origin?: string
}) => {

    const check = (key: string): Date | null => {
        const item = props.currentFilter.find(item => item.key === key);
        return item ? item.value : null;
    };

    // const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // const [blockFilterFuncTrue, setBlockFilterFuncTrue] = useState<(() => void) | null>(null);
    // const [blockFilterFuncFalse, setBlockFilterFuncFalse] = useState<(() => void) | null>(null);
    // const isInitialMount = React.useRef(true);

    const initializeValue = (): DateRange<Date> => {
        if (props.origin === 'proposicao') {
            const today = moment();
            const startDate = moment(today).subtract(6, 'months');
            return [startDate.toDate(), today.toDate()];
        }
        if (props.filterKey === "dataApresentacao") {
            return [check("dataIni"), check("dataFin")];
        } else if (props.filterKey === "dataAtualizacao") {
            return [check("dataAtualizacaoIni"), check("dataAtualizacaoFin")];
        } else if (props.filterKey === "dataCargaTramitacao") {
            return [check("dataCargaTramitacaoIni"), check("dataCargaTramitacaoFin")];
        } else if (props.filterKey === "dataTramitacao") {
            return [check("dataIniTramitacao"), check("dataFinTramitacao")];
        } else if (props.filterKey === "periodoAtividade") {
            return [check("periodoAtividadeIni"), check("periodoAtividadeFin")];
        }
    };

    const [value, setValue] = useState<DateRange<Date>>(initializeValue());

    useEffect(() => {
        // console.log('-----------------------------------------------', value)
        handleChange(value);
    }, []);

    useEffect(() => {
        if (props.currentFilter.length === 0) {
            setValue(initializeValue());
        }
    }, [props.currentFilter]);

    let inicioUrlAtual = window.location.href.split('/')[2];

    const handleChange = (newValue: DateRange<Date | null>) => {
        // console.log('handleChange', newValue);

        let startDate = newValue[0];
        let endDate = newValue[1];

        // setErrorMessage(null);

        if (startDate instanceof Date && !isNaN(startDate.getTime()) && endDate instanceof Date && !isNaN(endDate.getTime())) {

            const adjustedValue: DateRange<Date> = [startDate, endDate];
            setValue(adjustedValue);
            let newFilterValue = { target: { name: props.filterKey } };
            // console.log('handleChange1', newFilterValue, adjustedValue,);

            props.onChange(newFilterValue, adjustedValue);
        } else {
            setValue(newValue as DateRange<Date>);
        }
    };

    return (
        <MainGrid container sx={{ '>div': { width: '95%' } }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                <DateRangePicker
                    disableFuture
                    startText={props.label}
                    endText=""
                    label={props.label}
                    value={value}
                    inputFormat={"dd/MM/yyyy"}
                    onChange={handleChange}
                    renderInput={(startParams, endParams) => (
                        <InputDate container>
                            <TextField
                                fullWidth
                                variant='outlined'
                                id={`outlined-start-${props.filterKey}`}
                                label={props.label}
                                style={{ width: '100%', backgroundColor: props.backgroundColor === false ? 'transparent' : 'white' }}
                                {...startParams}
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarTodayOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <InputDateLabel>
                                <Box sx={{ mx: 6 }}> a </Box>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id={`outlined-end-${props.filterKey}`}
                                    label={props.label}
                                    {...endParams}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </InputDateLabel>
                        </InputDate>
                    )}
                />
            </LocalizationProvider>
        </MainGrid>
    );
};