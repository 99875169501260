import * as React from 'react';
import styled from 'styled-components';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { usePallet } from '../contexts/PalletContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Chip, Grid, Paper, Typography, Divider } from '@mui/material';
import { isTemplateTail } from 'typescript';
import { isMobile } from 'react-device-detect';
import { arraysEquals } from '../utils/array.utils';


// const { pallet } = usePallet();

const GridContainer = styled(Grid)`
overflow-y:scroll; 
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 5px;
width: auto;
min-height: auto;
max-height: 250px;
`;

const StyledBox = styled.div`
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;    
`;

const Container = styled(Grid)`
    width: 100%;
`;

const Area = styled(Paper)`
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 4px;
    text-transform: capitalize;
    height: auto;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffff;
    box-shadow: none;
    align-content: center;
    
`;
const LabelNested = styled(Typography)`
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #2b29299d;
    background-color: #ffff;
    box-shadow: none;
    align-content: center;
    `;

const FilterTag = styled(Chip)`
                            
        max-width: ${isMobile ? "220px" : "100%"};
        font-size: 12px;
        font-weight: 600;
        background-color:${(props => props.status > 0 ? "#E0E0E0" : "rgba(224, 205, 248, 1)")};
        border: none;
        color:${(props => props.status > 0 ? "#9B9B9B" : "#4B1196")};
        padding: 10px;
        font-family: Montserrat;
        font-style: normal;        
        border-radius: 39px;
        justify-content: center;
        align-items: center;
        margin-left: 2px;
        margin-right: 5px;
        margin-top: 2px;
        margin-bottom: 5px;
`;

const TagContainer = styled(Grid)`
        padding-right: 10px;
`;
export interface ICheckBoxNestedChild {
    id: number,
    label: string,
    value: boolean
}

export interface ICheckBoxNestedItem {
    id: number,
    label: string,
    items: Array<ICheckBoxNestedChild>
}

const InputDropDownCheckBoxNestedChild = (props: { item: ICheckBoxNestedItem, onChange: (data: any) => void, lockedItems?: Array<number> }) => {
    const [openDropDown, setOpenDropDown] = React.useState<boolean>(false);
    const { pallet } = usePallet();

    const checkIfAll = (item: Array<ICheckBoxNestedChild>): boolean | undefined => {
        return item.filter(i => !!i.value).length === item.length;
    }

    const checkIfAny = (item: Array<ICheckBoxNestedChild>): boolean | undefined => {
        return item.filter(i => !!i.value).length > 0 && !checkIfAll(item)
    }

    const checkIfAnyLocked = (item: Array<ICheckBoxNestedChild>): boolean | undefined => {
        if (!props.lockedItems) return false
        return item.filter(i => !!i.value && props.lockedItems.filter(lock => lock === i.id)).length > 0
    }

    const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const res = props.item.items.map(item => { return { id: item.id, label: item.label, value: event.target.checked } })
        props.onChange({ id: props.item.id, items: res })
    };

    const handleChangeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
        const res = props.item.items.map(item => item.id === Number(event.target.id) ? { id: item.id, label: item.label, value: event.target.checked } : item)
        props.onChange({ id: props.item.id, items: res })
    };

    const checkLocked = (id: number) => {
        if (!props.lockedItems) return false
        const items = props.lockedItems.filter(lock => lock === id)

        if (items.length > 0) return true
        return false
    }

    return (
        <>
            <Grid container alignItems={"baseline"} style={{ width: "100%" }}  >
                <Grid item style={{
                    width: '4px',
                    height: '45px',
                    paddingLeft: `${isMobile ? "10px " : "0px"}`
                }}>
                    <Button style={{ width: '5px', height: '10px', }} onClick={() => setOpenDropDown(!openDropDown)}>
                        {openDropDown ? <ArrowDropUpIcon style={{ color: `${pallet.textColorTertiary}`, marginLeft: `${isMobile ? "0px" : "15px"}`, }} /> : <ArrowDropDown style={{ color: `${pallet.textColorTertiary}`, marginLeft: `${isMobile ? "0px" : "15px"}`, }} />}
                    </Button>
                </Grid>
                <Grid item style={{ width: "94%" }} >
                    <FormControlLabel sx={{ color: `${pallet.textColorTertiary}`, }}
                        label={`${props.item.label}  (${props.item.items.length})`} //props.item.items.filter(i => !!i.value).length
                        control={
                            <Checkbox
                                sx={{
                                    color: `${pallet.textColorTertiary}`,
                                    marginRight: `${isMobile ? "15px " : "25px"}`,

                                }}
                                color={"default"}
                                // key={index}
                                id={`${props.item.id}`}
                                checked={checkIfAll(props.item.items)}
                                indeterminate={checkIfAny(props.item.items)}
                                disabled={checkIfAnyLocked(props.item.items)}
                                onChange={handleChangeAll}
                            />}

                    />
                    <Grid>
                        < Divider variant="fullWidth" />
                    </Grid>

                    <Box sx={{
                        marginLeft: '50px',
                        display: 'flex',
                        textTransform: "capitalize",
                        flexFlow: "column",

                    }}
                    >
                        {/* Label 2o. Nivel */}

                        {openDropDown && props.item.items.map((item, index) =>
                            <FormControlLabel style={{ color: `${pallet.textColorQuaternary}` }}
                                key={index} label={item.label} control={
                                    <Checkbox
                                        sx={{ color: `${pallet.textColorTertiary}` }}
                                        color={"default"}
                                        key={index}
                                        id={String(item.id)}
                                        checked={item.value}
                                        onChange={handleChangeOne}
                                        disabled={checkLocked(item.id)}
                                    />}
                            />)}
                    </Box>
                </Grid>
            </Grid>

        </>
    )
}

export function InputDropDownCheckBoxNested(props: {
    items: Array<ICheckBoxNestedItem>,
    selectedItems?: Array<number>,
    lockedItems?: Array<number>,
    onChange: (data: any) => void,
    label?: string,
}):
    JSX.Element {
    const [openArea, setOpenArea] = React.useState<boolean>(false);
    const [parent, setParent] = React.useState<Array<ICheckBoxNestedItem>>([]);
    const [selected, setSelected] = React.useState<Array<number>>([]);


    const handleChange = React.useCallback(() => {
        if (props.onChange && parent.length > 0) {
            props.onChange({ list: parent, selected: selected })
        }

    }, [selected])

    React.useEffect(() => {
        handleChange();

    }, [selected])

    React.useEffect(() => {

        const lista = props.items.map(item => {

            return {
                id: item.id,
                label: item.label,
                items: item.items.map(i => {
                    return {
                        id: i.id,
                        label: i.label,
                        value: props.selectedItems.indexOf(i.id) !== -1
                    }
                })
            }
        });

        setParent(() => [...lista])


    }, [props.selectedItems])



    React.useEffect(() => {
        const array: number[] = []
        parent.map(child => {
            child.items.map(item => {
                if (item.value)
                    array.push(item.id)
            })
        })
        if (!arraysEquals(selected, array))
            setSelected(() => [...array])

    }, [parent])

    React.useEffect(() => {
        setParent(() => [...props.items])


    }, [props.items])

    const handleOnDelete = (data: any) => {
        const res: Array<ICheckBoxNestedItem> = parent.map(child => {
            return {
                ...child,
                items: child.items.map(item => {
                    if (item.id !== Number(data.id)) {
                        return item
                    } else {
                        return { ...item, value: false }
                    }
                })
            }
        });
        setParent(() => [...res]);
    }
    const handleOnChange = (data) => {
        const res = parent.map((item, index) => item.id === data.id ? { id: item.id, label: item.label, items: data.items } : item)
        setParent(() => [...res]);
    }

    const checkLocked = (id: number) => {
        if (!props.lockedItems) return false
        const items = props.lockedItems.filter(lock => lock === id)

        if (items.length > 0) return true
        return false
    }


    return (
        <Container>
            <Area onClick={() => setOpenArea(!openArea)}>
                {parent.filter(child => child.items.filter(item => item.value).length > 0).length === 0 &&
                    <LabelNested> Responsáveis </LabelNested>}
                {parent.map(child => child.items.filter(item => item.value).map((item, index) => {
                    return (
                        <TagContainer>
                            {/* CHIPS */}
                            <FilterTag
                                key={index}
                                label={item.label}
                                onDelete={() => handleOnDelete(item)}
                                disabled={checkLocked(item.id)}
                            />
                        </TagContainer>
                    )
                }))}
            </Area>
            <div >
                <GridContainer >
                    {openArea && parent.map((item, index) =>
                        <InputDropDownCheckBoxNestedChild
                            key={index}
                            item={item}
                            onChange={handleOnChange}
                            lockedItems={props.lockedItems}
                        />
                    )}
                </GridContainer>
            </div>
        </Container>

    )
}