import ReactDOM from 'react-dom';
import PrivateRoute from '../../components/PrivateRoute';
import Agenda from './Agenda';
import AgendaCadastro from './Agenda.Cadastro';
import AgendaRenderPDFOperacional from './_components/AgendaRenderPDFOperacional'
import AgendaRenderPDFJornal from './_components/AgendaRenderPDFJornal'
import AgendaRenderPDFAcoesInternas from './_components/AgendaRenderPDFAcoesInternas';
import AgendaNew from './AgendaNew';
import AgendaRenderPDFJornalNovo from './_components/AgendaRenderPDFJornalNovo';
import AgendaRenderPDFOperacionalNovo from './_components/AgendaRenderPDFOperacionalNovo';
import AgendaRenderPDFAcoesInternasNovo from './_components/AgendaRenderPDFAcoesInternasNovo';



export function AgendaRoutes(): JSX.Element {
    return (
        <>
            {/* <PrivateRoute path="/agenda" element={<Agenda />} /> */}
            <PrivateRoute path="/agenda" element={<Agenda />} />
            <PrivateRoute path="/agenda/cadastro" element={<AgendaCadastro />} />
            <PrivateRoute path="/agenda/agendaGeraPDFOperacional/:dtIni/:dtFin/:tipo" element={<AgendaRenderPDFOperacionalNovo/>} />
            <PrivateRoute path="/agenda/agendaGeraPDFJornal/:dtIni/:dtFin/:tipo" element={<AgendaRenderPDFJornalNovo />} />
            <PrivateRoute path="/agenda/AgendaRenderPDFAcoesInternas/:dtIni/:dtFin/:tipo" element={<AgendaRenderPDFAcoesInternasNovo />} />
            <PrivateRoute path="/agenda/:idAgenda/cadastro" element={<AgendaCadastro />} />

        </>
    );
}
