import * as http from '../helpers/http';

class ParlamentarAcaoInfluenciaService {

    GetList = async (id: number, filtro: any, pageIndex: number, pageSize: number = 8): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}/acoesinfluencia?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${JSON.stringify(filtro)}`);
        if (result.success) {

            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                lista: result.data
            }


        }
        return undefined;
    }

    GetResultadoSummary = async (id: number, filtro: any): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}/acoesinfluencia/resultado/summary?filter=${JSON.stringify(filtro)}`);
        if (result.success) {
            return result.data;
        }
        return undefined;
    }


    GetInteracoesSummary = async (id: number, filtro: any): Promise<any> => {
        const result = await http.get(`/parlamentar/${id}/acoesinfluencia/interacoes/summary?filter=${JSON.stringify(filtro)}`);
        if (result.success) {
            return result.data;
        }
        return undefined;
    }

}

export default new ParlamentarAcaoInfluenciaService();
