import React, { useEffect, useState, useMemo, useRef } from "react";
import { Button, Grid, Paper } from "@mui/material";
import styled from "styled-components";
import ButtonOutLine from "../../components/ButtonOutline";
import FilterQuantity from "../../components/filter/FilterQuantity";
import ListFilter, { IFilterData } from "../../components/filter/ListFilter";
import ProposicaoListFilterItens from "./_components/filtro/ProposicaoListFilterItens";
import AccordionProposicaoLista from "./_components/lista/AccordionProposicaoLista";
import Main from "../main/Main";
import { isMobile } from "react-device-detect";
import { usePallet } from "../../contexts/PalletContext";
import { IListInfo } from "../../models/IListInfo";
import useStyles from "../../components/layout/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import ProposicaoService from "../../services/ProposicaoService";
import PaginationSaveState from "../../components/PaginationSaveState";
import EmptyError from "../../components/EmptyError";
import { objectsEquals, useQuery } from "../../utils/utils";
import { emptyStore, readStore } from "../../helpers/persistence";
import CircularLoading from "../../components/CircularLoading";
import ComboBoxSemSelect from "../../components/ComboBoxSemSelect";
import { useAlert } from "../../contexts/AlertContext";
import ButtonOutlineReadOnly from "../../components/ButtonOutlineReadOnly";
import { useAuthorization } from "../../contexts/AuthorizationContext";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { v4 as uuid } from "uuid";
import { format } from "date-fns";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Clear';
import moment from 'moment-timezone';
import Alert from '@mui/material/Alert';
import { FilterList } from "@material-ui/icons";









//## StyledCompenents
const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
`;

const FilterButton = styled(Button)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  color: #160342;
  border: 0;
  font-size: 14px;
  line-height: 17px;
  background: rgba(75, 17, 150, 0.1);
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  height: 100%;

  :first-child {
    margin-right: 10px;
  }
`;

const ContainerHead = styled(Grid)`
  margin-bottom: 10px;
  margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
  margin-bottom: 30px;
`;

const PageTitle = styled(Grid)`
  color: ${(props) => props.pallet.textColorQuaternary};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  font-size: 24px;
  font-family: ${(props) => props.pallet.general.fontFamily};
`;

const ContainerFilter = styled(Grid)`
  /* width: 10%; */
  width: 100%;
  margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
  justify-content: ${isMobile ? "center" : "space-between"};
  flex-direction: column;
`;

const ContainerCard = styled(Grid)`
  margin-top: 20px;
`;

const PaperContent = styled(Paper)`
  color: ${(props) => props.pallet.textColorQuaternary};
  font-size: ${(props) => props.pallet.fontSize.titleWidgets};
  font-family: ${(props) => props.pallet.fontFamily.general};
  font-weight: 600;
  padding-top: 10%;
  padding-bottom: 10%;
  width: 100%;
  text-align: center;
  background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
`;

export default function ProposicaoLista() {
    const { getConfig } = useAuthorization();
    const { pallet } = usePallet();
    const classes = useStyles();
    const isOrigin = useQuery().get("relatorioNome");
    const { NewAlert } = useAlert();

    //## Paginação
    const [infoList, setInfoList] = useState<IListInfo>({
        pageIndex: 1,
        pageSize: 8,
        pageItens: 0,
        totalItens: 0,
        totalPages: 0,
    });
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>();
    const [noList, setNoList] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [filtrar, setFiltrar] = useState(false);
    const [info, setInfo] = useState(useQuery().get("filtro"));
    const [relName, setRelName] = useState(null);

    const { user } = useAuthorization();

    //## Filtro
    const [openFilter, setOpenFilter] = React.useState<boolean>(false);
    const [currentFilter, setCurrentFilter] = useState([]);
    const [checkFilter, setCheckFilter] = useState<IFilterData[]>([]);
    const handleToggleFilter = () => {
        setPrimeiraConsulta(false)
        if (info) {
            setInfo(null);
        }
        setOpenFilter(!openFilter);
    };

    const filterName = "ProposicaoListCurrentPage";

    //## Dados
    const [lista, setLista] = useState([]);
    const [listaDescricaos, setListaDescricaos] = useState<any[]>([]);
    const [descricaoParcial, setDescricaoParcial] = useState<String>();
    const [primeiraConsulta, setPrimeiraConsulta] = useState<Boolean>(true);
    const [filtroLista, setFiltroLista] = useState([]);
    const [triggerAutoGetFiltro, setTriggerAutoGetFiltro] = useState(0);
    const [exp, setExp] = useState(false);
    const [selectedRadioValue, setSelectedRadioValue] = useState('');
    const [tituloPreFilitro, setTituloPreFilitro] = useState('');
    const [esferaChanged, setEsferaChanged] = useState('');
    const [esferaStatus, setEsferaStatus] = useState([]);

    useEffect(() => {
        setFiltroLista([]);
        ProposicaoService.GetFiltrosSalvos(user.id).then((res) => {
            if (res && res.length > 0) {
                setFiltroLista(res)
                console.log(res)

                const lastSelected = res && res.find(item => item.ultimo_selecionado === true);

                console.log(lastSelected)
                const customEvent = {
                    target: {
                        value: lastSelected?.titulo,
                        name: lastSelected?.id
                    }
                };
                // Pra liberar o get automatico com filtro salvo, descomentar abaixo:
                // handleRadioChange(customEvent, res);
            }
        });
    }, [triggerAutoGetFiltro]);

    useEffect(() => {
        if (primeiraConsulta === false) {
            loadProposicaoList();
            setPrimeiraConsulta(false)
        }
    }, [currentPage, currentPageSize]);


    const changeFiltrarStatus = async () => {
        setTituloPreFilitro('');
        setRelName(null);
        loadProposicaoList();
        setExp(false);
    };

    /////////////////////////////////////////////////////////
    //Ao setar vairavel lista, preparar Array para Excel
    const handleOnClickGerarExcel = async () => {
        setLoading(true);
        let filter = currentFilter.filter(x => x.value.length > 0).map((item) => {
            return { key: item.key, value: item.value };
        });
        const data = {
            filter: filter.length === 0 ? [] : filter,
            currentPageSize: currentPageSize,
            currentPage: currentPage,
        };

        let healedWord = descricaoParcial
            ? descricaoParcial.replace("/", "-")
            : descricaoParcial;
        currentPage &&
            (await ProposicaoService.GetElk(healedWord, data).then((res) => {
                const datatratado = [];
                res.data.map((item) => {
                    datatratado.push({
                        Proposição: item.descricao,
                        Casa: item.casa_nome,
                        Apelido: item.cliente.apelido,
                        "Data Apresentação": format(
                            new Date(item.data_apresentacao),
                            "dd/MM/yyyy"
                        ),
                        Seguir:
                            item.cliente.seguindo === true ? "Seguindo" : "Não Seguindo",
                        Prioridade: item.cliente.prioridade_descricao,
                        Posicionamento: item.cliente.posicionamento_descricao,
                        Ementa: item.ementa,
                        Comissão: item.comissao,
                        Tipo: item.tipo_descricao,
                        Número: item.numero,
                        Ano: item.ano,
                        Tramitação: item.tramitacao,
                        Situação: item.situacao_descricao,
                        Despacho: item.despacho_atual,
                        "Orgão Sigla": item.orgao_sigla,
                        Regime: item.proposicao_regime,
                        "Tema(s) Legislativo(s)": item?.autores
                            ? item?.temas_legislativo
                                .map((itemtema) => {
                                    return itemtema.descricao;
                                })
                                .join(", ")
                            : "Não definido",
                        "Autor(es)": item?.autores
                            ? item?.autores
                                .map((itemautor) => {
                                    return itemautor.nome;
                                })
                                .join(", ")
                            : "Não definido",
                        "Grupo Coporativo": item.cliente.tema_corporativo?.grupo_descricao
                            ? item?.cliente.tema_corporativo.grupo_descricao
                            : "Não definido",
                        "Tema Corporativo": item.cliente.tema_corporativo?.tema_descricao
                            ? item?.cliente.tema_corporativo.tema_descricao
                            : "Não definido",
                        "Subtema Corporativo": item?.cliente.tema_corporativo
                            ?.subtema_descricao
                            ? item?.cliente.tema_corporativo.subtema_descricao
                            : "Não definido",
                        "Links Externo": item.link_externo,
                    });
                });

                const resp = [];
                resp.push({
                    category: "Proposição",
                    data: datatratado,
                });

                resp.map((item, index) => {
                    item["json"] = XLSX.utils.json_to_sheet(item.data);
                });

                const obj = { Sheets: {}, SheetNames: [] };
                resp.map((item, key) => {
                    return (
                        (obj.Sheets[item.category] = item.json),
                        obj.SheetNames.push(item.category)
                    );
                });

                const idunico = uuid();

                const test = { ...obj };
                const excelBuffer = XLSX.write(test, {
                    bookType: "xlsx",
                    type: "array",
                });
                const dataresp = new Blob([excelBuffer], { type: "xlsx" });
                FileSaver.saveAs(dataresp, "ProposicaoLista_" + idunico + ".xlsx");

                setLoading(false);
            }));
    };

    const handleRadioChange = async (event, filtroListFromUseEffect?) => {

        const value = event.target.value;
        const id_filter_selected = Number(event.target.name);
        console.log('handleRadioChange', value, id_filter_selected)

        setTituloPreFilitro(value);

        const selectedFiltro = Array.isArray(filtroListFromUseEffect) && filtroListFromUseEffect.length > 0
            ? filtroListFromUseEffect.find(item => item.titulo === value)?.filtro
            : filtroLista.find(item => item.titulo === value)?.filtro;

        console.log('handleRadioChange 00000000', selectedFiltro)

        //Registrando o filtro selecionado no banco e descelecionando os demais:
        await ProposicaoService.registrarUltimoFiltroEscolhido(id_filter_selected);

        if (selectedFiltro) {
            console.log('handleRadioChange 11111', selectedFiltro)

            setSelectedRadioValue(value);
            setLoading(true);

            await setInfoList({
                pageIndex: 1,
                pageSize: 8,
                pageItens: 0,
                totalItens: 0,
                totalPages: 0,
            });

            let filter = JSON.parse(selectedFiltro).map((item) => {
                return { key: item.key, value: item.value };
            });

            const validaPeriodo = await checkPeriodoDatas(filter)

            const data = {
                filter: filter.length === 0 ? [] : filter,
                currentPageSize: currentPageSize,
                currentPage: filtroListFromUseEffect.length > 0 ? 1 : currentPage,
            };

            let healedWord = descricaoParcial
                ? descricaoParcial.replace("/", "-")
                : descricaoParcial;

            setCurrentFilter(JSON.parse(selectedFiltro))

            if (validaPeriodo) {
                if (healedWord && healedWord !== undefined) {

                    currentPage &&
                        (await ProposicaoService.GetElk(healedWord, data).then((res) => {
                            if (res && res.data)
                                setListaDescricaos(
                                    res.data.map((item) => {
                                        if (res.data.length > 0) {
                                            setNoList(false);
                                            setLista(res.data);
                                            setInfoList(res.listInfo);
                                            setLoading(false);
                                            return {
                                                value: item.id,
                                                label: item.descricao,
                                            };
                                        } else {
                                            setNoList(true);
                                            setLoading(false);
                                        };
                                    })
                                );
                        }));
                } else {
                    console.log('333333333333333', data)

                    currentPage &&
                        ProposicaoService.GetElk(undefined, data).then((res) => {
                            if (res?.data?.length > 0) {
                                setListaDescricaos(
                                    res.data.map((item) => {
                                        setNoList(false);
                                        setLista(res.data);
                                        setInfoList(res.listInfo);
                                        setLoading(false);
                                        return {
                                            value: item.id,
                                            label: item.descricao,
                                        };
                                    })
                                );
                            } else {
                                setNoList(true);
                                setLoading(false);
                                setLista([]);
                                setInfoList({
                                    pageIndex: 1,
                                    pageSize: 8,
                                    pageItens: 0,
                                    totalItens: 0,
                                    totalPages: 0,
                                });
                            };
                        });
                };
            } else {
                setLoading(false);
                NewAlert('error', 'Período maior que 6 meses');
                setLista([]);
            }

            setExp(false);
        }
    };


    const handleOnChangeFilter = async (data: IFilterData[]) => {
        setInfoList({
            pageIndex: 1,
            pageSize: 8,
            pageItens: 0,
            totalItens: 0,
            totalPages: 0,
        });

        const casaCheck = data.some(
            (objeto) => objeto.hasOwnProperty("key") && objeto.key === "casa"
        );

        if (!casaCheck) {
            data = data.filter(
                (objeto) => objeto.key !== "ambito" && objeto.key !== "casa"
            );
        }

        data = data.map(x => {
            if (x.key === 'tema') {
                return { ...x, value: Number(x.value) };
            }
            return x;
        });

        await currentFilter.pop();
        setLista([]);
        setCurrentFilter(data.length > 0 ? data : []);

    };


    useEffect(() => {
        const url = new URL(window.location.href);
        const filtro = url.searchParams.get('filtro');

        const filtroDataUrl = JSON.parse(decodeURIComponent(filtro));

        if (filtroDataUrl !== null) {
            loadProposicaoList(filtroDataUrl);
        };
    }, []);

    const checkPeriodoDatas = (filters) => {
        const checkKeysAndDates = (keyIni, keyFin) => {
            const itemIni = filters.find(item => item.key === keyIni);
            const itemFin = filters.find(item => item.key === keyFin);

            if (itemIni && itemFin) {
                const dataIni = moment(itemIni.value, 'MM/DD/YYYY');
                const dataFin = moment(itemFin.value, 'MM/DD/YYYY');

                if (!dataIni.isValid() || !dataFin.isValid()) {
                    console.error(`Datas inválidas para ${keyIni} ou ${keyFin}`);
                    return false;
                }

                const diffMonths = dataFin.diff(dataIni, 'months', true);

                if (diffMonths > 6) {
                    return false;
                }
            }

            return true;
        };

        const keyPairs = [
            ['dataIni', 'dataFin'],
            ['dataAtualizacaoIni', 'dataAtualizacaoFin'],
            ['dataIniTramitacao', 'dataFinTramitacao'],
            ['dataCargaTramitacaoIni', 'dataCargaTramitacaoFin']
        ];

        for (const [keyIni, keyFin] of keyPairs) {
            if (!checkKeysAndDates(keyIni, keyFin)) {
                return false;
            }
        }

        return true; // Retorna true se todos os pares estiverem dentro do limite de 6 meses
    };

    const loadProposicaoList = async (filtroDataUrl = null) => {

        // setInfoList abaixo, precisa zerar total e botao de baixar xls em toda requisicao nova de lista.
        await setInfoList({
            pageIndex: 1,
            pageSize: 8,
            pageItens: 0,
            totalItens: 0,
            totalPages: 0,
        });

        const setFilter = filtroDataUrl === null ? currentFilter : filtroDataUrl;

        setLoading(true);
        let filter = setFilter.map((item) => {
            return { key: item.key, value: item.value };
        });

        const validaPeriodo = await checkPeriodoDatas(filter)
        console.log('validaPeriodo', validaPeriodo)

        const data = {
            filter: filter.length === 0 ? [] : filter,
            currentPageSize: currentPageSize,
            currentPage: currentPage === undefined ? 1 : currentPage,
        };

        let healedWord = descricaoParcial
            ? descricaoParcial.replace("/", "-")
            : descricaoParcial;

        if (info) {

            if (healedWord && healedWord !== undefined) {
                currentPage &&
                    (await ProposicaoService.GetElk(healedWord, data).then((res) => {
                        if (res && res.data)
                            setListaDescricaos(
                                res.data.map((item) => {
                                    if (res.data.length > 0) {
                                        setNoList(false);
                                        setLista(res.data);
                                        setInfoList(res.listInfo);
                                        setLoading(false);
                                        return {
                                            value: item.id,
                                            label: item.descricao,
                                        };
                                    } else {
                                        setNoList(true);
                                        setLoading(false);
                                    };
                                })
                            );
                    }));
            } else {
                filter &&
                    ProposicaoService.GetElk(undefined, data).then((res) => {
                        if (res?.data?.length > 0) {
                            setListaDescricaos(
                                res.data.map((item) => {
                                    setNoList(false);
                                    setLista(res.data);
                                    setInfoList(res.listInfo);
                                    setLoading(false);
                                    return {
                                        value: item.id,
                                        label: item.descricao,
                                    };
                                })
                            );
                        } else {
                            setNoList(true);
                            setLoading(false);
                            setLista([]);
                            setInfoList({
                                pageIndex: 1,
                                pageSize: 8,
                                pageItens: 0,
                                totalItens: 0,
                                totalPages: 0,
                            });
                        };
                    });
            };




        } else if (validaPeriodo) {
            console.log("INFO:><>><")
            if (healedWord && healedWord !== undefined) {
                currentPage &&
                    (await ProposicaoService.GetElk(healedWord, data).then((res) => {
                        if (res && res.data)
                            setListaDescricaos(
                                res.data.map((item) => {
                                    if (res.data.length > 0) {
                                        setNoList(false);
                                        setLista(res.data);
                                        setInfoList(res.listInfo);
                                        setLoading(false);
                                        return {
                                            value: item.id,
                                            label: item.descricao,
                                        };
                                    } else {
                                        setNoList(true);
                                        setLoading(false);
                                    };
                                })
                            );
                    }));
            } else {
                filter &&
                    ProposicaoService.GetElk(undefined, data).then((res) => {
                        if (res?.data?.length > 0) {
                            setListaDescricaos(
                                res.data.map((item) => {
                                    setNoList(false);
                                    setLista(res.data);
                                    setInfoList(res.listInfo);
                                    setLoading(false);
                                    return {
                                        value: item.id,
                                        label: item.descricao,
                                    };
                                })
                            );
                        } else {
                            setNoList(true);
                            setLoading(false);
                            setLista([]);
                            setInfoList({
                                pageIndex: 1,
                                pageSize: 8,
                                pageItens: 0,
                                totalItens: 0,
                                totalPages: 0,
                            });
                        };
                    });
            };
        } else {
            setLoading(false);
            NewAlert('error', 'Período maior que 6 meses');
            setLista([]);
        }
    };

    let filter = currentFilter.map((item) => {
        return { key: item.key, value: item.value };
    });

    //## Funções
    const handleOnChangeItensPerPageSize = (data: any) => {
        setPrimeiraConsulta(false)
        setCurrentPage(1);
        setCurrentPageSize(Number(data.target.value));
    };

    const handleSelect = (data: any) => {
        if (data !== descricaoParcial) setDescricaoParcial(data);
    };

    const pressBuscar = async (value: any) => {
        if (filter.length === 0 && descricaoParcial === undefined) {
            readStore("ProposicaoListCurrentFilter").then((filter) => {
                setCheckFilter(filter);
                if (filter.length <= 0) {
                    NewAlert(
                        "error",
                        "Necessário escrever uma palavra ou escolher filtros para fazer busca"
                    );
                };
            });
        } else {
            const data = {
                filter: filter,
                currentPageSize: currentPageSize,
                currentPage: currentPage,
            };
            if (value.which === 13) {
                setLoading(true);
                setLista([]);
                value.preventDefault();
                let healedWord = descricaoParcial
                    ? descricaoParcial.replace("/", "-")
                    : descricaoParcial;
                currentPage &&
                    (await ProposicaoService.GetElk(healedWord, data).then((res) => {
                        if (res && res.data)
                            setListaDescricaos(
                                res.data.map((item) => {
                                    value.preventDefault();
                                    if (res.data.length > 0) {
                                        setNoList(false);
                                        setLista(res.data);
                                        setInfoList(res.listInfo);
                                        return {
                                            value: item.id,
                                            label: item.descricao,
                                        };
                                    } else {
                                        setNoList(true);
                                    };
                                })
                            );
                    }));
                setLoading(false);
            };
        };
    };

    const pressBuscarReWord = async (writed) => {
        if (filter.length === 0 && descricaoParcial === undefined) {
            NewAlert(
                "error",
                "Necessário escrever uma palavra ou escolher filtros para fazer busca"
            );
        } else {
            const data = {
                filter: filter,
                currentPageSize: currentPageSize,
                currentPage: currentPage,
            };
            let healedWord = descricaoParcial
                ? descricaoParcial.replace("/", "-")
                : descricaoParcial;
            currentPage &&
                (await ProposicaoService.GetElk(healedWord, data).then((res) => {
                    if (res && res.data)
                        setListaDescricaos(
                            res.data.map((item) => {
                                if (res.data.length > 0) {
                                    setNoList(false);
                                    setLista(res.data);
                                    setInfoList(res.listInfo);
                                    return {
                                        value: item.id,
                                        label: item.descricao,
                                    };
                                } else {
                                    setNoList(true);
                                };
                            })
                        );
                }));
        };
    };

    const apagaAmbitoPorCasa = () => {
        const casaCheck = currentFilter.some(
            (objeto) => objeto.hasOwnProperty("key") && objeto.key === "casa"
        );
        if (!casaCheck) {
            currentFilter.filter((objeto) => objeto.key !== "casa");
        };
    };

    const handleDelete = async (id) => {
        await ProposicaoService.deleteFiltroPropLista(id).then((res) => {
            if (res) {
                <Alert severity="success">'Filtro excluído com sucesso.'</Alert>
                changeTriggerAutoFiltro();
                setExp(false);
            } else {
                <Alert severity="error">' Erro, tente novamente'</Alert>
            };
        });
    };

    const openDetected = (isExpanded) => {
        setExp(isExpanded);
    };


    const changeSalvarFiltro = async (titulo) => {
        const data = {
            id_usuario: user.id,
            titulo: titulo,
            id_cliente: user.cliente.id,
            filtro: JSON.stringify(currentFilter), // STRING
            criado_em: moment().format(),
            atualizado_em: moment().format(),
            removido: false

        };

        await ProposicaoService.salvarFiltroPropLista(data).then((res) => {
            if (res) {
                <Alert severity="success">'Filtro salvo com sucesso.'</Alert>
                handleToggleFilter()
            } else {
                <Alert severity="error">' Erro, tente novamente'</Alert>
            };
        });

        changeTriggerAutoFiltro()
    };

    const changeTriggerAutoFiltro = () => {
        setTriggerAutoGetFiltro(triggerAutoGetFiltro + 1)
    };

    const limparFiltroSalvo = () => {
        setTituloPreFilitro('')
    };

    return (
        <div className={classes.root}>
            <Main>
                <>
                    <ContainerHead container>
                        <ContainerSubHead
                            container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <PageTitle pallet={pallet}>
                                {relName ? (
                                    <Grid>
                                        <Grid style={{ fontWeight: 100, fontSize: "20px" }}>
                                            Lista Relacionada ao Relatório
                                        </Grid>
                                        <Grid>{relName}</Grid>
                                    </Grid>
                                ) : (
                                    "Proposições"
                                )}
                            </PageTitle>
                            {relName ? null : (
                                <Grid>
                                    {user.id_permissao === 3 ? (
                                        <ButtonOutlineReadOnly path="Não é possível realizar está ação com este perfil." />
                                    ) : user.id_permissao === 4 ? null : (
                                        <ButtonOutLine path="/proposicao/cadastro" />
                                    )}
                                </Grid>
                            )}
                        </ContainerSubHead>

                        {relName ? null : (
                            <Grid container>
                                <Grid style={{ width: "100%" }}>
                                    <ComboBoxSemSelect
                                        name="inputPropsList"
                                        onChange={handleSelect}
                                        onKeyPress={pressBuscar}
                                        placeholder="Buscar"
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            style={{
                                width: isMobile ? "60%" : "100%",
                            }}
                        />
                        <ContainerFilter>
                            <FilterArea>
                                <Grid container style={{ width: '100%' }}>
                                    <Grid item xl={3} lg={3} style={{ width: `${isMobile ? "70%" : "78%"}` }}>
                                        <FilterQuantity
                                            list={infoList}
                                            currentPageSize={currentPageSize}
                                            onChange={handleOnChangeItensPerPageSize}
                                        />
                                    </Grid>
                                    <Grid item xl={5} lg={5} style={{ width: '100%', paddingRight: '8px' }}>
                                        {getConfig()?.habNotFiltroMult ?
                                            <Grid style={{
                                                width: "100%",
                                                fontFamily: pallet.fontFamily.general,
                                                position: "relative" // adiciona isso para o pai ter posição relativa
                                            }}>
                                                <div style={{
                                                    position: "absolute",
                                                    zIndex: 1000,
                                                    width: "100%",
                                                    marginTop: '-4px',
                                                    fontSize: '14px'
                                                }}> {/* Adicione este wrapper */}
                                                    <Accordion
                                                        disabled={filtroLista.length === 0 ? true : false}
                                                        onChange={(event, isExpanded) => openDetected(isExpanded)}
                                                        expanded={exp}
                                                        sx={{ boxShadow: 'none' }}
                                                        style={{
                                                            borderRadius: '8px',
                                                            border: `${filtroLista.length > 0 ? '1px solid #d3d3d3' : '1px solid #e9e9e9'}`,
                                                            width: "100%",
                                                            boxShadow: exp ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "0px 0px 0px rgba(0, 0, 0, 0.0)", // Adiciona uma sombra
                                                            backgroundColor: pallet.general.color3, // Adiciona uma cor de fundo
                                                        }}>
                                                        <AccordionSummary
                                                            expandIcon={filtroLista.length > 0 ? <ExpandMoreIcon /> : null}
                                                            aria-controls="panel3-content"
                                                            id="panel3-header"
                                                        >
                                                            {filtroLista.length > 0
                                                                ?
                                                                tituloPreFilitro && tituloPreFilitro !== ''
                                                                    ?
                                                                    <>
                                                                        <span style={{ backgroundColor: '#39ff14', borderRadius: '50%', width: '10px', height: '10px', marginTop: '5px' }} />
                                                                        <span style={{ fontWeight: pallet.general.weight_600, marginLeft: '5px' }}>Ativo:</span>
                                                                        <span style={{ fontWeight: pallet.general.weight_100, marginLeft: '5px' }}>{tituloPreFilitro}</span>
                                                                    </>
                                                                    :
                                                                    <span style={{ fontWeight: pallet.general.weight_100 }}>Filtros Salvos</span>
                                                                :
                                                                <span style={{ fontWeight: pallet.general.weight_300, fontStyle: 'italic', color: pallet.general.color1 }}>-- Nenhum filtro salvo --</span>
                                                            }
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{
                                                            width: "100%"
                                                        }}>
                                                            <FormControl style={{
                                                                width: "100%"
                                                            }}>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="radio-buttons-group"
                                                                    value={tituloPreFilitro && tituloPreFilitro !== '' ? selectedRadioValue : ''}
                                                                    onChange={handleRadioChange}
                                                                >
                                                                    {filtroLista.map((item, index) => {
                                                                        return (
                                                                            <Grid container key={index} style={{
                                                                                backgroundColor: "#f9f9f9",
                                                                                padding: "8px",
                                                                                marginBottom: "5px",
                                                                                width: "100%",
                                                                                borderRadius: "5px",
                                                                            }}>
                                                                                <Grid xl={11} lg={11}>
                                                                                    <FormControlLabel
                                                                                        value={item.titulo}
                                                                                        control={<Radio value={item.titulo} size="small" style={{ color: pallet.general.color2 }} />}
                                                                                        label={<Grid style={{ wordWrap: "break-word", fontFamily: pallet.general.fontFamily, fontSize: pallet.general.size14 }}>{item.titulo}</Grid>}
                                                                                        id={item.id}
                                                                                        name={item.id}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid xl={1} lg={1}>
                                                                                    <IconButton aria-label="delete" onClick={() => handleDelete(item.id)}>
                                                                                        <DeleteIcon style={{ fontSize: '18px' }} />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </Grid>
                                            :
                                            null
                                        }
                                    </Grid>
                                    <Grid item xl={2} lg={2} style={{ width: '100%', paddingRight: '8px', paddingLeft: '8px' }}>
                                        <FilterButton
                                            startIcon={<BackupTableIcon />}
                                            onClick={handleOnClickGerarExcel}
                                            disabled={lista.length === 0 ? true : false}
                                        >
                                            Gerar .xls {lista.length === 0 ? null : 'pág. ' + currentPage}
                                        </FilterButton>
                                    </Grid>
                                    <Grid item xl={2} lg={2} style={{ width: '100%', paddingLeft: '8px' }}>
                                        <Grid style={{ marginTop: `${isMobile ? "5px" : "0px"}` }}>
                                            <FilterButton
                                                disabled={loading ? true : false}
                                                startIcon={
                                                    tituloPreFilitro && tituloPreFilitro !== ''
                                                        ?
                                                        <FontAwesomeIcon icon={faSlidersH} />
                                                        :
                                                        currentFilter.length === 0 ?
                                                            <FontAwesomeIcon icon={faSlidersH} />
                                                            :
                                                            <FontAwesomeIcon style={{ color: '#0eff00' }} icon={faSlidersH} />
                                                }
                                                onClick={handleToggleFilter}
                                            >
                                                Filtro
                                            </FilterButton>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <ListFilter
                                    limparFiltroSalvo={limparFiltroSalvo}
                                    apagaAmbitoPorCasa={apagaAmbitoPorCasa}
                                    filtrarAction={changeFiltrarStatus}
                                    saveFilter={changeSalvarFiltro}
                                    name={"ProposicaoListCurrentFilter"}
                                    filter={currentFilter}
                                    open={openFilter}
                                    onToogle={handleToggleFilter}
                                    onChangeFilter={handleOnChangeFilter}
                                >
                                    <ProposicaoListFilterItens
                                        filter={currentFilter}
                                        onChangeFilter={handleOnChangeFilter}
                                        idPermissao={user.id_permissao}
                                    />
                                </ListFilter>
                            </FilterArea>
                        </ContainerFilter>
                    </ContainerHead>
                    {descricaoParcial ? (
                        <Grid
                            style={{
                                fontWeight: 100,
                            }}
                        >
                            Resultado de busca por:{" "}
                            <span style={{ fontWeight: 600 }}>"{descricaoParcial}"</span>
                        </Grid>
                    ) : null}
                    <ContainerBody container>
                        {loading === true ? (
                            <Grid style={{ margin: "0 auto" }}>
                                <CircularLoading size={80} zIndex={2} positionCustom={true} />
                            </Grid>
                        ) : loading === false && lista !== undefined && lista.length > 0 ? (
                            lista.map((item) => (
                                <ContainerCard key={item.id}>
                                    <AccordionProposicaoLista
                                        key={item.id}
                                        wordWrited={descricaoParcial}
                                        onClick={pressBuscarReWord}
                                        item={item}
                                        idPermissao={user.id_permissao}
                                    />
                                </ContainerCard>
                            ))
                        ) : loading === false && noList === true ? (
                            <Paper
                                style={{
                                    color: `${pallet.textColorQuaternary}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: 600,
                                    paddingTop: "10%",
                                    paddingBottom: "10%",
                                    width: "100%",
                                    textAlign: "center",
                                    backgroundColor: "#ffff",
                                }}
                            >
                                <EmptyError
                                    title="Ops! Nenhum resultado."
                                    description="Use o filtro ou escreva no campo de busca usando 'ENTER' para buscar!"
                                />
                            </Paper>
                        ) : (
                            <Paper
                                style={{
                                    color: `${pallet.textColorQuaternary}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: 600,
                                    paddingTop: "10%",
                                    paddingBottom: "10%",
                                    width: "100%",
                                    textAlign: "center",
                                    backgroundColor: "#ffff",
                                }}
                            >
                                <EmptyError
                                    title="Ops! Nenhum resultado."
                                    description="Refaça a busca selecionando ítens no filtro ou escreva no campo de busca, usando 'ENTER' para buscar!"
                                />
                            </Paper>
                        )}
                    </ContainerBody>
                    <ContainerFooter container>
                        <PaginationSaveState
                            name={filterName}
                            pages={infoList.totalPages}
                            page={currentPage}
                            onChange={setCurrentPage}
                        />
                    </ContainerFooter>
                </>
            </Main>
        </div >
    );
};