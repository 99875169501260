import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import PieChartParlamentarAcoesInfluencia from "./PieChartParlamentarAcoesInfluencia";
import PieChartParlamentarAcoesInfluencia2 from "./PieChartParlamentarAcoesInfluencia2";
import SmallIconButton from "../../../components/SmallIconButton";
import ParlamentarAcaoInfluenciaService from "../../../services/ParlamentarAcaoInfluenciaService";
import { format, subYears } from "date-fns";
import EmptyError from "../../../components/EmptyError";
import { formatDate } from "../../../utils/dateUtils";
import { IParlamentar } from "../../../models/Parlamentar.Model";
import moment from "moment";

export interface IFilterAcaoInfluencia {
    key: string,
    value: any
}

export default function ParlamentarAcoesInfluenciaDados(props: { id_parlamentar: number,parlamentar?: IParlamentar }) {
    const { pallet } = usePallet();
    const [sumaryres, setSumaryres] = useState({ Total: 0, Pendente: 0, Exito: 0, SemExito: 0, ExitoParcial: 0 });
    const [sumaryint, setSumaryint] = useState([{ ano: '', mes: '', qtd: '' }]);

    const [filtrores, setFiltrores] = useState<Array<Date>>([subYears(new Date(), 1), new Date()]);
    const [filtroint, setFiltroint] = useState<Date>(new Date());

    useEffect(() => {
        getDataRes();        

        // eslint-disable-next-line 
    }, [filtrores])
    
    useEffect(() => {
        getDataInt();

        // eslint-disable-next-line 
    }, [filtroint])

    const getDataRes = useCallback(() => {

        if (filtrores.length == 2 && props && props.id_parlamentar && filtrores && filtrores.length == 2) {

            ParlamentarAcaoInfluenciaService.GetResultadoSummary(props.id_parlamentar, [
                { key: 'dtIni', value: format(filtrores[0], 'yyyy-MM-dd 00:00:00') },
                { key: 'dtFin', value: format(filtrores[1], 'yyyy-MM-dd 23:59:59') },
            ])
                .then(async res => {
                    setSumaryres(() => res)
                    //setLoading(false)
                })
        }

    }, [filtrores])

    const getDataInt = useCallback(() => {

       if (props && props.id_parlamentar && filtroint) {


            ParlamentarAcaoInfluenciaService.GetInteracoesSummary(props.id_parlamentar, [
                { key: 'ano', value: format(filtroint, 'yyyy') },
            ])
                .then(async res => {
                    console.log("RES VINDO",res)
                    setSumaryint(() => res)
                    //console.log("resultado interações",res)
                    //setLoading(false)
                })
        }

    }, [filtroint])

    const setFilterDateRange = (ini, fin) => {
        setFiltrores(() => [ini, fin])
    }

    const setFilterDateYear = (dtyear) => {
        setFiltroint(() => dtyear)
    }

    return (

        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                    <PieChartParlamentarAcoesInfluencia
                        masterTitle={"INTERAÇÕES"}
                        //titleChart={`Total: ${sumary?.titular + sumary?.suplente}`}                       
                        yearSel={filtroint}
                        onDateChange={setFilterDateYear}
                        titleChart="2021"
                        total={0}
                        seguindo={0}
                        labels={sumaryint}
                    /*
                    labels={[
                        { key: "Êxito", data: sumaryint?.titular, color: `${pallet.charts.green}` },
                        { key: "Êxito Parcial", data: sumaryint?.suplente, color: `${pallet.charts.blue}` },
                        { key: "Sem Êxito", data: sumaryint?.suplente, color: `${pallet.charts.red}` },
                        { key: "Pendente", data: sumaryint?.suplente, color: `${pallet.charts.red}` },
                    ]}
                    */
                    />
                </Grid>


                <Grid item xl={12} lg={12} xs={12} style={{ paddingTop: "40px" }}>
                    <PieChartParlamentarAcoesInfluencia2
                        masterTitle={"ÍNDICE DE RESULTADO DE AÇÕES"}
                        //titleChart={`Total: ${sumary?.titular + sumary?.suplente}`}
                        titleChart=""
                        total={sumaryres?.Total}
                        seguindo={0}
                        labels={[
                            { key: "Êxito", data: sumaryres?.Exito, color: `${pallet.charts.green}`,
                            url:'/proposicao?filtro=[{"key":"acaoInfluenciaP","label":"Ação de Influência: Exito","value":"1"},{"key":"acaoInfluenciaParlamentar","label":"Influenciado : ' + props.parlamentar.nome + '","value":"' + props.id_parlamentar +'"},{"key":"dataInfluencia","label":"PRAZO DA AÇÃO DE INFLUENCIA: De ' +
                            moment(filtrores[0]).format( 'dd-MM-yyyy') + ' até ' +  moment(filtrores[1]).format( 'dd-MM-yyyy') +
                            '","value" : "' +
                            filtrores +
                            '"}]'},
                            { key: "Êxito Parcial", data: sumaryres?.ExitoParcial, color: `${pallet.charts.blue}`,
                            url:'/proposicao?filtro=[{"key":"acaoInfluenciaP","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"acaoInfluenciaParlamentar","label":"Influenciado: ' + props.parlamentar.nome +'","value":"' + props.id_parlamentar +'"},{"key":"dataInfluencia","label":"PRAZO DA AÇÃO DE INFLUENCIA: De ' +
                            moment(filtrores[0]).format( 'dd-MM-yyyy') + ' até ' +  moment(filtrores[1]).format( 'dd-MM-yyyy') +
                            '","value" : "' +
                            filtrores +
                            '"}]'  },
                           
                           
                            { key: "Sem Êxito", data: sumaryres?.SemExito, color: `${pallet.charts.red}`,
                            url:'/proposicao?filtro=[{"key":"acaoInfluenciaP","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"acaoInfluenciaParlamentar","label":"Influenciado: ' + props.parlamentar.nome +'","value":"' + props.id_parlamentar +'"},{"key":"dataInfluencia","label":"PRAZO DA AÇÃO DE INFLUENCIA: De ' +
                            moment(filtrores[0]).format( 'dd-MM-yyyy') + ' até ' +  moment(filtrores[1]).format( 'dd-MM-yyyy')  +
                            '","value" : "' +
                            filtrores +
                            '"}]' },
                            { key: "Pendente", data: sumaryres?.Pendente, color: `${pallet.charts.yellow}`,
                            url:'/proposicao?filtro=[{"key":"acaoInfluenciaP","label":"Ação de Influência: Pendente","value":"4"},{"key":"acaoInfluenciaParlamentar","label":"Influenciado: ' + props.parlamentar.nome +'","value":"' + props.id_parlamentar +'"},{"key":"dataInfluencia","label":"PRAZO DA AÇÃO DE INFLUENCIA: De ' +
                            moment(filtrores[0]).format( 'dd-MM-yyyy') + ' até ' +  moment(filtrores[1]).format( 'dd-MM-yyyy') +
                            '","value" : "' +
                            filtrores +
                            '"}]'},
                        ]}
                        dtIni={filtrores ? filtrores[0] : subYears(new Date(), 1)}
                        dtFin={filtrores ? filtrores[1] : new Date()}
                        onFilterChange={setFilterDateRange}
                    />
                </Grid>

            </Grid>

        </div>
    )
}

function Years(arg0: Date): Date | (() => Date) {
    throw new Error("Function not implemented.");
}
