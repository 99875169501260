import PrivateRoute from '../../components/PrivateRoute';
import BuscaGeral from './BuscaGeral';


export function BuscaGeralRoutes(): JSX.Element {
    return (
        <>
            <PrivateRoute path="/busca-geral/:wordKey" element={<BuscaGeral />} />
            {/* <PrivateRoute path="/busca-geral/:wordKey" element={<BuscaGeral />} /> */}
        </>
    );
}