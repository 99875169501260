import React from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import { Grid, Button, Icon, Menu, FormControl, Divider } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import useStyles from './layout/Styles';
import { usePallet } from '../contexts/PalletContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { data } from 'jquery';


export default function AgendaMenuFiltroTipo(props: { title: string, icon: any, onChange: (value) => void }) {
    const [selectedValue, setSelectedValue] = React.useState('T');
    const { pallet } = usePallet();
    const [value, setValue] = React.useState('T');

    
    const TodosEventos = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Todos Eventos
            </div>
        )
    }
    const SenadoFederal = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Senado Federal
            </div>
        )
    }
    const CamaraDeputados = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Câmara dos Deputados
            </div>
        )
    }

    const CongressoNacional = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Congresso Nacional
            </div>
        )
    }
    const Outros = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Acontecimentos e Cadastrados
            </div>
        )
    }
    // const Geral = () => {
    //     return (
    //         <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
    //             Geral
    //         </div>
    //     )
    // }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
      };

    return (
        <Grid>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button
                            {...bindTrigger(popupState)}
                            variant="contained"
                            size="large"
                            style={{
                                textTransform: 'uppercase',
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontSize: `${pallet.general.size12}`,
                                fontWeight: pallet.general.weight_600,
                                border: "none",
                                backgroundColor: `${pallet.backgroundColorQuintenary}`,
                                color: `${pallet.textColorTertiary}`
                            }}
                            startIcon={
                                <Icon style={{
                                    color: `${pallet.color.secundary.font}`
                                }}
                                >{props.icon}</Icon>}
                        >
                            {props.title}
                        </Button>
                        <Menu {...bindMenu(popupState)} >
                            <FormControl component="fieldset" onChange={props.onChange} style={{ minWidth: "200px" }}>
                                <RadioGroup
                                    aria-label="gender"
                                    defaultValue="tudo"
                                    name="qualificacao"
                                    id="qualificacao"
                                    value={value}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value='T' control={<Radio onClick={popupState.close}  sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<TodosEventos />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value='SF' control={<Radio onClick={popupState.close}  sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<SenadoFederal />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value='CD' control={<Radio onClick={popupState.close}  sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<CamaraDeputados />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value='CN' control={<Radio onClick={popupState.close}  sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<CongressoNacional />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value='O' control={<Radio onClick={popupState.close}  sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Outros />} />
                                    {/* <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value='G' control={<Radio onClick={popupState.close}  sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Geral />} /> */}
                                </RadioGroup>
                            </FormControl>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>

        </Grid >


    );
}