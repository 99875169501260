import React from 'react';
import { Typography } from '@mui/material';


////////////////////////////////////////////////////////////////////
//  Area de Status do card de Lista do parlamentar
//  ( Exercicio / Fim de Mandato / etc)
////////////////////////////////////////////////////////////////////
const ParlamentarCardStatus = (props: { item: any, pallet: any }) => {
    
    return (
        <>
            {props.item.statusMandato && (
                <Typography align="justify" style={{
                    padding: "3px 9px",
                    borderRadius: "13px",
                    backgroundColor: `${props.pallet.color.colorBase.greyBackground}`,
                    fontFamily: `${props.pallet.fontFamily.general}`,
                    fontWeight: props.pallet.fontWeight.subtitle,
                    fontSize: `${props.pallet.fontSize.text_10}`,
                    color: `${props.pallet.color.colorBase.greyText}`,
                    marginBottom: "15px"
                }}>
                    {props.item.statusMandato}
                </Typography>
            )}
        </>
    )
}

export default ParlamentarCardStatus;