import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import ConteudoTerca from "./ConteudoTerca";

const AgendaCarouselTabsTerca = (props: { activeTab: any, index: number, dateStartReference: Date, eventListTodayMaisUm: Array<any>, loadEventos: () => void, loading: boolean}) => {

    return (
        <Grid style={{ borderRadius: "6px"}}>
            <TabPanel value={props.activeTab} index={props.index}>
                <ConteudoTerca index={props.index} activeTab={props.activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisUm={props?.eventListTodayMaisUm} loadEventos={props?.loadEventos} loading={props?.loading} />
            </TabPanel>
        </Grid>
    )
}

export default AgendaCarouselTabsTerca;