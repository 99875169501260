import Paper from "@mui/material/Paper";

import { isMobile } from "react-device-detect";

import ProposicaoResultados from "./../../../proposicao/_components/resultados/ProposicaoResultado";
import TabPanel from "../../../../components/carousel/TabPanel";


const ProposicaoCarouselTabsResultados = (props: { id_proposicao: number, activeTab: any, index: number }) => {
    return (
        <TabPanel value={props.activeTab} index={props.index}>
        <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "30px"}`, }}>
            <ProposicaoResultados id_proposicao={props.id_proposicao} index={props.index} activeTab={props.activeTab} />
        </Paper>
        </TabPanel>
    );
};

export default ProposicaoCarouselTabsResultados;
