import React from 'react';
import { Typography } from '@mui/material';


const ParlamentarCardInfo = (props: { item: any, pallet: any }) => {
    return (
        <>
            <Typography style={{
                fontFamily: `${props.pallet.fontFamily.general}`,
                fontWeight: props.pallet.fontWeight.subtitle,
                fontSize: `${props.pallet.fontSize.titleCardParlamentar}`,
                color: `${props.pallet.color.colorBase.black}`,
                textTransform: "uppercase",
                width: "230px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginBottom: "8px",
            }}>
                {props.item.nome}
            </Typography>
            <Typography align="justify" style={{
                fontFamily: `${props.pallet.fontFamily.general}`,
                fontWeight: props.pallet.fontWeight.subtitle,
                fontSize: `${props.pallet.fontSize.smallButton}`,
                color: `${props.pallet.color.colorBase.black}`,
            }}>
                {props.item.mandatoTipo}
            </Typography>
            <Typography style={{
                marginBottom: "8px",
                color: `${props.pallet.color.quaternary.font}`,
            }}>
                
                <span style={{
                    fontFamily: `${props.pallet.fontFamily.general}`,
                    fontWeight: props.pallet.fontWeight.subtitle,
                    fontSize: `${props.pallet.fontSize.smallButton}`,
                    color: `${props.pallet.color.quaternary.font}`,
                    textTransform: "uppercase",
                }}>
                    {props.item.partido}
                </span>
                <span>
                {props.item.partido && props.item.partido?" • ":""}
                </span>
                <span style={{
                    fontFamily: `${props.pallet.fontFamily.general}`,
                    fontWeight: props.pallet.fontWeight.subtitle,
                    fontSize: `${props.pallet.fontSize.smallButton}`,
                    color: `${props.pallet.color.quaternary.font}`,
                    textTransform: "uppercase",
                }}>
                    {props.item.estadoFederacao}
                </span>
                
            </Typography>
        </>
    )
}

export default ParlamentarCardInfo;