import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { usePallet } from "../../../../contexts/PalletContext";
import useStyles from "../../../../components/layout/Styles";
import { IProposicao } from "../../../../models/Proposicao.Model";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LinkIcon from "@mui/icons-material/Link";
import { formatDate } from "../../../../utils/dateUtils";
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const ProposicaoAcaoPreparatoriaEspecificaAnexos = (props: { acao_preparatoria: IAcaoPreparatoria}) => {
  const { pallet } = usePallet();
  const classes = useStyles();
  const AnexoGrid = styled(Grid)`
  .upload-message {
    display: none;
  }
`;

  return (
    <Grid
      style={{
        paddingLeft: `${isMobile ? "20px" : "50px"}`,
        paddingRight: `${isMobile ? "20px" : "50px"}`,
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Accordion elevation={0} style={{ border: "1px solid #EAEAEA",marginBottom:"25px" }}>
        <AccordionSummary
          expandIcon={
            <ArrowDropDownIcon style={{ color: `${pallet.general.color1}` }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
            height: "10px",
            borderRadius: "5px",
            paddingLeft: "20px",
            paddingRight: "20px",
            flexFlow: "row-reverse",
          }}
        >
          <Typography
            className={classes.heading}
            style={{
              color: `${pallet.textColorTertiary}`,
              fontFamily: `${pallet.general.fontFamily}`,
              fontSize: `${pallet.general.size14}`,
              fontWeight: pallet.general.weight_600,
              marginLeft: "10px",
            }}
          >
            <Grid container style={{ display: "flex" }}>
              ANEXOS
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            fontFamily: `${pallet.general.fontFamily}`}}>
            <AnexoGrid style={{ marginRight: "50px", width: "100%", marginLeft: "25px" }}>
              <AnexosComponent
                label=''
                id_referencia={props.acao_preparatoria?.id}
                modulo={EAnexoModulos.ProposicaoAcaoPreparatoria}
                canEdit={false}
                autosave={false}
              />
            </AnexoGrid>

        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};


export default ProposicaoAcaoPreparatoriaEspecificaAnexos;
