import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import ConteudoDomingo from "./ConteudoDomingo";

const AgendaCarouselTabsDomingo = (props: { activeTab: any, index: number,  dateStartReference: Date, eventListTodayMaisSeis: Array<any>, loadEventos: () => void, loading: boolean}) => {

    return (
        <Grid style={{ borderRadius: "6px"}}>
            <TabPanel value={props.activeTab} index={props.index}>
                <ConteudoDomingo index={props.index} activeTab={props.activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisSeis={props?.eventListTodayMaisSeis} loadEventos={props?.loadEventos} loading={props?.loading} />
            </TabPanel>
        </Grid>
    )
}

export default AgendaCarouselTabsDomingo;