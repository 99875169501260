import React, { useEffect, useState } from 'react';
import Main from '../../main/Main';
import { useParams } from 'react-router-dom';
import { Box, Checkbox, Grid } from '@mui/material';
import InputTextField from '../../../components/InputTextField';
import SelectTextFieldA from '../../../components/SelectTextFieldA';
import ButtonDialogConfirmation from '../../../components/ButtonDialogConfirmation';
import { useAlert } from '../../../contexts/AlertContext';
import { usePallet } from '../../../contexts/PalletContext';
import { useNavigate } from 'react-router-dom';
import useStyles from "../../../components/layout/Styles";
import styled from "styled-components";
import { isMobile } from 'react-device-detect';
import TabelasService from '../../../services/TabelasService';
import SolidButton from '../../../components/SolidButton';
import CircularLoading from '../../../components/CircularLoading';
import TCCadastroGruposCorporativoSchema from './Grupo/TCCadastroGruposCorporativoSchema';
import TCCadastroTemasCorporativoSchema from './Tema/TCCadastroTemasCorporativoSchema';
import TCCadastroSubtemasCorporativoSchema from './SubTema/TCCadastroSubtemasCorporativoSchema';
import TCService from '../../../services/TCService';
import { useAuthorization } from '../../../contexts/AuthorizationContext';
import TCCadastroTopicosCorporativoSchema from './Topico/TCCadastroTopicosCorporativoSchema';

const Title = styled(Grid)`
        color: ${(props => props.pallet.textColorSecondary)};
        font-weight: ${(props => props.pallet.general.weight_bold)};
        font-size: 14px;
        font-family: ${(props => props.pallet.general.fontFamily)};
        margin-bottom: 20px;
`;

const ArrayStatus = [
    { key: 0, label: 'Ativo', value: true },
    { key: 1, label: 'Inativo', value: false },
];

export default function TCCadastroTemaCorporativo() {
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    const navigate = useNavigate();

    const [error, setError] = useState<Array<{ name: string, err: string }>>([])
    const [onSave, setOnSave] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [actionButton, setActionButton] = React.useState('G')


    const [nome, setNome] = React.useState('')
    const [status, setStatus] = React.useState<boolean>(false)
    const [grupo, setGrupo] = React.useState<number>(0)
    const [tema, setTema] = React.useState<number>(0)
    const [subtema, setSubtema] = React.useState<number>(0)
    const [arrayGrupo, setArrayGrupo] = useState([{ key: 0, label: '-- Selecione -- ', value: 0 }]);
    const [arrayTema, setArrayTema] = useState([{ key: 0, label: '-- Selecione -- ', value: 0 }]);
    const [arraySubtema, setArraySubtema] = useState([{ key: 0, label: '-- Selecione -- ', value: 0 }]);
    const [prioridade, setPrioridade] = React.useState('Selecione')

    const [limparCampo, setLimparCampo] = React.useState(false)
    const [loadingTema, setLoadingTema] = useState(false);
    const [loadingSubtema, setLoadingSubtema] = useState(false);
    const { user } = useAuthorization();


    const { idAbaActive } = useParams()


    React.useEffect(() => {
        setArrayGrupo([]);
        setArrayTema([]);
        setArraySubtema([]);

        if (actionButton === 'T' || actionButton === 'S' || actionButton === 'TP') {
            TabelasService.getProposicaoGrupoTemas().then(
                res => {
                    const data = []
                    data.push({ key: 0, label: '-- Selecione -- ', value: 0 });
                    res.map(item => data.push({ key: item.id, label: item.descricao, value: item.id }))
                    setArrayGrupo(() => [...data]);
                })
        }
    }, [actionButton]);


    React.useEffect(() => {
        setActionButton(idAbaActive)

    }, [idAbaActive]);

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red', fontSize: '11px', marginTop: "5px", }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }


    const saveNovaClassificacao = async (e) => {
        setOnSave(true)
        e.preventDefault();
        let data = {}

        if (actionButton === 'G') {
            data = {
                descricao: nome,
                ativo: status
            }

            setError(() => [])
            data && TCCadastroGruposCorporativoSchema
                .validate(data, { abortEarly: false })
                .catch(err => setError(() => err.errors));

            if (TCCadastroGruposCorporativoSchema.isValidSync(data)) {
                await TCService.CreateGrupo(data)
                    .then(res => {
                        setLimparCampo(true)
                        setNome(undefined)
                        if (res && res > 0) {
                            NewAlert('success', 'Grupo criado com sucesso')
                        } else {
                            NewAlert('error', 'Erro ao criar grupo, verifique os campos obrigatórios e salve novamente');
                        }
                        setTimeout(() => {
                            setLimparCampo(false)
                        }, 100);
                    });
            }
            else {
                NewAlert('error', 'Favor preencher os campos obrigatórios');
            }

        } else if (actionButton === 'T') {
            data = {
                descricao: nome,
                ativo: status,
                id_tema_grupo: grupo > 0 ? grupo : null
            }

            setError(() => [])
            data && TCCadastroTemasCorporativoSchema
                .validate(data, { abortEarly: false })
                .catch(err => setError(() => err.errors));


            if (TCCadastroTemasCorporativoSchema.isValidSync(data)) {
                await TCService.CreateTema(grupo, data)
                    .then(res => {
                        setLimparCampo(true)
                        setNome(undefined)
                        setGrupo(0)
                        if (res && res > 0) {
                            NewAlert('success', 'Tema criado com sucesso')
                        } else {
                            NewAlert('error', 'Erro ao criar grupo, verifique os campos obrigatórios e salve novamente');
                        }
                        setTimeout(() => {
                            setLimparCampo(false)
                        }, 100);
                    });
            }
            else {
                NewAlert('error', 'Favor preencher os campos obrigatórios');
            }
        } else if (actionButton === 'S') {

            const prioridadeChange = (value) => {
                let prioridade: number = 0
                switch (value) {
                    case 'Mínima':
                        prioridade = 1;
                        break;
                    case 'Baixa':
                        prioridade = 2;
                        break;
                    case 'Média':
                        prioridade = 3;
                        break;
                    case 'Alta':
                        prioridade = 4;
                        break;
                    case 'Máxima':
                        prioridade = 5;
                        break;
                    default:
                        prioridade = 0;
                        break;
                }
                return prioridade;
            };

            data = {
                descricao: nome,
                ativo: status,
                id_tema: tema > 0 ? tema : null,
                id_prioridade: prioridade !== 'Selecione' ? prioridadeChange(prioridade) : null,
            };

            setError(() => [])
            data && TCCadastroSubtemasCorporativoSchema
                .validate(data, { abortEarly: false })
                .catch(err => setError(() => err.errors));

            if (TCCadastroSubtemasCorporativoSchema.isValidSync(data)) {
                await TCService.CreateSubtema(tema, data)
                    .then(res => {
                        setLimparCampo(true);
                        setNome(undefined);
                        setGrupo(0);
                        setPrioridade('Selecione');
                        setArrayTema([]);
                        setTema(0);
                        if (res && res > 0) {
                            NewAlert('success', 'Subtema criado com sucesso');
                        } else {
                            NewAlert('error', 'Erro ao criar grupo, verifique os campos obrigatórios e salve novamente');
                        }
                        setTimeout(() => {
                            setLimparCampo(false);
                        }, 100);
                    });
            }
            else {
                NewAlert('error', 'Favor preencher os campos obrigatórios');
            }

        } else {
            data = {
                descricao: nome,
                ativo: status,
                id_tema: tema > 0 ? tema : null,
                id_subtema: subtema
            };

            setError(() => [])
            data && TCCadastroTopicosCorporativoSchema
                .validate(data, { abortEarly: false })
                .catch(err => setError(() => err.errors));
            if (TCCadastroTopicosCorporativoSchema.isValidSync(data)) {
                await TCService.CreateTopico(subtema, data)
                    .then(res => {
                        setLimparCampo(true);
                        setNome(undefined);
                        setGrupo(0);
                        setArrayTema([]);
                        setTema(0);
                        setSubtema(0);
                        setArraySubtema([]);
                        if (res && res > 0) {
                            NewAlert('success', 'Subtema criado com sucesso')
                        } else {
                            NewAlert('error', 'Erro ao criar grupo, verifique os campos obrigatórios e salve novamente');
                        }
                        setTimeout(() => {
                            setLimparCampo(false);
                        }, 100);
                    });
            }
            else {
                NewAlert('error', 'Favor preencher os campos obrigatórios');
            }
        }
    };

    const ChangeActionButton = (item) => {
        setActionButton(item);
        setArrayGrupo([]);
        setArrayTema([]);
        setArraySubtema([]);
        setGrupo(0)
        setTema(0)
        setSubtema(0)
    }

    const handleChangeNome = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNome(event.target.value);
    }

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    }

    const handleChangeGrupo = (event) => {
        setArrayTema([])
        setGrupo(event.target.value)
        TabelasService.getProposicaoTemasByGrupo(event.target.value).then(
            res => {
                setLoadingTema(true)
                const data = []
                data.push({ key: 0, label: '-- Selecione -- ', value: 0 });
                res.map(item => data.push({ key: item.id, label: item.descricao, value: item.id }))
                setArrayTema(() => [...data])
                setTimeout(() => {
                    setLoadingTema(false)
                }, 500);
            })
    };

    const handleChangeTema = (event) => {
        setArraySubtema([])
        setTema(event.target.value)
        TabelasService.getProposicaoSubTemasByTema(event.target.value).then(
            res => {
                setLoadingSubtema(true)
                const data = []
                data.push({ key: 0, label: '-- Selecione -- ', value: 0 });
                res.map(item => data.push({ key: item.id, label: item.descricao, value: item.id }))
                setArraySubtema(() => [...data])
                setTimeout(() => {
                    setLoadingSubtema(false)
                }, 500);
            })
    };

    // const handleChangeTema = (event) => {
    //     setTema(event.target.value)
    // };

    const handleChangeSubtema = (event) => {
        setSubtema(event.target.value)
    };

    const DotPrioridade = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 25px;
`


    const corPrioridade = (value: string) => {

        switch (value) {
            case 'Máxima':
                return `${pallet.charts.red}`;
            case 'Baixa':
                return `${pallet.charts.blue}`;
            case 'Média':
                return `${pallet.charts.yellow}`;
            case 'Alta':
                return `${pallet.charts.purple}`;
            case 'Mínima':
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }
    function LabelHandle(props: { prioridade: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPrioridade bg={corPrioridade(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.prioridade}
                    </Grid>
                </Grid>
            </>
        )
    }

    const prioridades = [
        { key: 0, label: '-- Selecione --', value: "Selecione" },
        { key: 1, label: <LabelHandle prioridade={"Mínima"} bg={"Mínima"} />, value: "Mínima" },
        { key: 2, label: <LabelHandle prioridade={"Baixa"} bg={"Baixa"} />, value: "Baixa" },
        { key: 3, label: <LabelHandle prioridade={"Média"} bg={"Média"} />, value: "Média" },
        { key: 4, label: <LabelHandle prioridade={"Alta"} bg={"Alta"} />, value: "Alta" },
        { key: 5, label: <LabelHandle prioridade={"Máxima"} bg={"Máxima"} />, value: "Máxima" },
    ]

    const handlePrioridadeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrioridade(event.target.value)
    };

    const erroAlteracaoPerfil = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil."
        );
    }



    return (
        <>
            {loading ?

                < CircularLoading
                    size={80}
                    top={"50%"}
                    left={"50%"}
                    zIndex={2}
                />

                :

                <div className={classes.root}>
                    <Main>
                        <Grid>
                            <Grid style={{
                                color: `${pallet.textColorSecondary}`,
                                fontWeight: pallet.general.weight_bold,
                                fontSize: "24px",
                                fontFamily: `${pallet.general.fontFamily}`,
                                marginTop: "30px",
                            }}>
                                Cadastrar Nova Classificação
                            </Grid>
                            <Grid style={{
                                backgroundColor: `${pallet.backGroundPageColorSecondary}`,
                                padding: `${isMobile ? "15px" : "50px"}`,
                                marginTop: "30px",
                            }}>

                                <Grid xl={12} lg={12} xs={12} container
                                    justifyContent="flex-start"
                                    alignItems="center">
                                    <Title pallet={pallet}>
                                        TIPO
                                    </Title>

                                </Grid>

                                <Grid container direction={"row"} justifyContent={'space-between'} style={{ width: '100%' }}>
                                    <Grid xl={3} lg={3} xs={12} item style={{ padding: "10px" }}>
                                        <Grid onClick={() => ChangeActionButton('G')} style={{
                                            cursor: 'pointer',
                                            backgroundColor: `${actionButton === 'G' ? pallet.backgroundColorQuintenary : 'white'}`,
                                            borderRadius: '7px',
                                            border: `1px solid ${actionButton === 'G' ? 'white' : pallet.backgroundColorQuaternary}`
                                        }}>
                                            <Grid style={{ padding: "30px", textAlign: 'center', color: `${actionButton === 'G' ? pallet.textColorQuintenary : pallet.backgroundColorQuaternary}` }}>
                                                GRUPO
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xl={3} lg={3} xs={12} item style={{ padding: "10px" }}>
                                        <Grid onClick={() => ChangeActionButton('T')} style={{
                                            cursor: 'pointer',
                                            backgroundColor: `${actionButton === 'T' ? pallet.backgroundColorQuintenary : 'white'}`,
                                            borderRadius: '7px',
                                            border: `1px solid ${actionButton === 'T' ? 'white' : pallet.backgroundColorQuaternary}`
                                        }}>
                                            <Grid style={{ padding: "30px", textAlign: 'center', color: `${actionButton === 'T' ? pallet.textColorQuintenary : pallet.backgroundColorQuaternary}` }}>
                                                TEMA
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xl={3} lg={3} xs={12} item style={{ padding: "10px" }}>
                                        <Grid onClick={() => ChangeActionButton('S')} style={{
                                            cursor: 'pointer',
                                            backgroundColor: `${actionButton === 'S' ? pallet.backgroundColorQuintenary : 'white'}`,
                                            borderRadius: '7px',
                                            border: `1px solid ${actionButton === 'S' ? 'white' : pallet.backgroundColorQuaternary}`
                                        }}>
                                            <Grid style={{ padding: "30px", textAlign: 'center', color: `${actionButton === 'S' ? pallet.textColorQuintenary : pallet.backgroundColorQuaternary}` }}>
                                                SUBTEMA
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xl={3} lg={3} xs={12} item style={{ padding: "10px" }}>
                                        <Grid onClick={() => ChangeActionButton('TP')} style={{
                                            cursor: 'pointer',
                                            backgroundColor: `${actionButton === 'TP' ? pallet.backgroundColorQuintenary : 'white'}`,
                                            borderRadius: '7px',
                                            border: `1px solid ${actionButton === 'TP' ? 'white' : pallet.backgroundColorQuaternary}`,
                                        }}>
                                            <Grid style={{ padding: "30px", textAlign: 'center', color: `${actionButton === 'TP' ? pallet.textColorQuintenary : pallet.backgroundColorQuaternary}` }}>
                                                TÓPICO
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Box component="form" style={{ marginTop: "35px" }}>

                                    <Grid xl={12} lg={12} xs={12} container style={{
                                        // paddingLeft: `${isMobile ? "25px" : "30px"}`
                                    }}
                                        justifyContent="flex-start"
                                        alignItems="center">
                                        <Title pallet={pallet}>
                                            INFORMAÇÕES GERAIS
                                        </Title>

                                    </Grid>

                                    {/* contaudo referente a grupo */}

                                    <Grid container spacing={3}>
                                        <Grid item lg={8} xs={12}>
                                            {limparCampo ?
                                                null :
                                                <InputTextField
                                                    type={"text"}
                                                    value={nome}
                                                    onChange={handleChangeNome}
                                                    id={"nome-grupo"}
                                                    label={"NOME"}
                                                    focused={true}
                                                    placeholder={"Digite"}
                                                />
                                            }
                                            <ErrorMessage name='nome' errors={error} />
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <SelectTextFieldA
                                                name={"status"}
                                                value={status}
                                                onChange={handleChangeStatus}
                                                options={ArrayStatus}
                                                label={"STATUS"}
                                                id={"status-grupo"}
                                                focused={true}
                                                placeholder={"Selecione"}
                                            />
                                            <ErrorMessage name='status' errors={error} />
                                        </Grid>
                                        {actionButton === 'S'
                                            ?
                                            <Grid item lg={4} xs={12}>
                                                <SelectTextFieldA
                                                    name={"prioridade"}
                                                    value={prioridade}
                                                    onChange={handlePrioridadeChange}
                                                    options={prioridades}
                                                    label={"PRIORIDADE"}
                                                    id={"prioridade-grupo"}
                                                    focused={true}
                                                    placeholder={"Selecione"}
                                                />
                                                <ErrorMessage name='prioridade' errors={error} />
                                            </Grid>
                                            :
                                            null
                                        }

                                    </Grid>

                                    {actionButton === 'T' || actionButton === 'S' || actionButton === 'TP'
                                        ?
                                        <>
                                            <Grid xl={12} lg={12} xs={12} container style={{ marginTop: '40px' }}
                                                justifyContent="flex-start"
                                                alignItems="center">
                                                <Title pallet={pallet}>
                                                    ASSOCIAR À
                                                </Title>

                                            </Grid>

                                            {/* contaudo referente a TEMA */}

                                            <Grid container spacing={4}>
                                                <Grid item lg={12} xs={12}>
                                                    {limparCampo
                                                        ?
                                                        null
                                                        :
                                                        <SelectTextFieldA
                                                            name={"grupo"}
                                                            value={grupo}
                                                            onChange={handleChangeGrupo}
                                                            options={arrayGrupo}
                                                            label={"GRUPO"}
                                                            id={"associar-grupo"}
                                                            focused={true}
                                                            placeholder={"Selecione"}
                                                        />
                                                    }
                                                    <ErrorMessage name='grupo' errors={error} />
                                                </Grid>
                                                {actionButton === 'S' || actionButton === 'TP'
                                                    ?
                                                    loadingTema
                                                        ?
                                                        <CircularLoading
                                                            size={40}
                                                            top={"75%"}
                                                            left={"50%"}
                                                            zIndex={2}

                                                        />
                                                        :
                                                        arrayTema.length > 1
                                                            ?
                                                            <Grid item lg={12} xs={12}>
                                                                <SelectTextFieldA
                                                                    name={"tema"}
                                                                    value={tema}
                                                                    onChange={handleChangeTema}
                                                                    options={arrayTema}
                                                                    label={"TEMA"}
                                                                    id={"associar-tema"}
                                                                    focused={true}
                                                                    placeholder={"Selecione"}
                                                                />
                                                                <ErrorMessage name='tema' errors={error} />
                                                            </Grid>
                                                            : grupo ?
                                                                <Grid style={{ marginBottom: `${isMobile ? '15px' : '0px'}`, fontFamily: `${pallet.general.fontFamily}`, color: 'red', fontSize: `${pallet.general.size12}`, marginLeft: '40px', marginTop: '25px' }}>
                                                                    Não existe Tema cadastrado neste Grupo
                                                                </Grid>
                                                                :
                                                                null


                                                    :
                                                    null
                                                }
                                                {actionButton === 'TP'
                                                    ?
                                                    loadingSubtema
                                                        ?
                                                        <CircularLoading
                                                            size={40}
                                                            top={"75%"}
                                                            left={"50%"}
                                                            zIndex={2}

                                                        />
                                                        :
                                                        arraySubtema.length > 1
                                                            ?
                                                            <Grid item lg={12} xs={12}>
                                                                <SelectTextFieldA
                                                                    name={"subtema"}
                                                                    value={subtema}
                                                                    onChange={handleChangeSubtema}
                                                                    options={arraySubtema}
                                                                    label={"SUBTEMA"}
                                                                    id={"associar-subtema"}
                                                                    focused={true}
                                                                    placeholder={"Selecione"}
                                                                />
                                                                <ErrorMessage name='subtema' errors={error} />
                                                            </Grid>
                                                            : tema ?
                                                                <Grid style={{ marginBottom: `${isMobile ? '15px' : '0px'}`, fontFamily: `${pallet.general.fontFamily}`, color: 'red', fontSize: `${pallet.general.size12}`, marginLeft: '40px', marginTop: '25px' }}>
                                                                    Não existe Subtema cadastrado neste Tema
                                                                </Grid>
                                                                :
                                                                null


                                                    :
                                                    null
                                                }

                                            </Grid>
                                        </>
                                        :
                                        null
                                    }
                                </Box>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="end" style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, borderTop: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                                <Grid item style={{ marginRight: "20px" }}>
                                    <ButtonDialogConfirmation
                                        styles={{
                                            borderRadius: "6px 6px 0px 0px",
                                            // marginRight: "20px",
                                            textTransform: "capitalize",
                                            color: `${pallet.general.color1}`,
                                            lineHeight: "18px",
                                            fontSize: `${pallet.general.size14}`,

                                        }}
                                        lastPath={"/classificacaodetemas"}
                                        title={"CONFIRMAÇÃO"}
                                        title_button={"Cancelar"}
                                        confirmation_button={"SAIR"}
                                        content={"SAIR SEM SALVAR?"}
                                        message={"As informações preenchidas serão perdidas."}
                                    />
                                </Grid>
                                <Grid item>
                                    <SolidButton
                                        onClick={user.id_permissao !== 3 ? saveNovaClassificacao : erroAlteracaoPerfil}
                                        color={`${pallet.textColorPrimary}`}
                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                        fontSize={`${pallet.general.size14}`}
                                        title={"SALVAR"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Main >
                </div >
            }
        </>

    );
}