import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import AccordionTextDataComissaoParlamentar from "./AccordionTextDataComissaoParlamentar";
import PaginationSaveState from "../../../components/PaginationSaveState";
import { IListInfo } from "../../../models/IListInfo";
import ParlamentarMenuFiltroTipoComissao from "../../../components/ParlamentarMenuFiltroTipoComissao";
import { Visibility } from "@material-ui/icons";
import ParlamentarAmbitoService from "../../../services/ParlamentarAmbitoService";
import FilterQuantity from '../../../components/filter/FilterQuantity';

export default function ParlamentarComissoesComissao(props: { id_parlamentar: number,query: string }) {
    const { pallet } = usePallet();
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1)
    };
    const [filtro, setFiltro] = useState<string>('A');

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [currentPage, filtro, currentPageSize])
    
    let dtIni = props.query ? props.query.slice(props.query.search("dtInicio") + 10,props.query.search("dtFin") - 4) : ""
    let dtFin = props.query ? props.query.slice(props.query.search("dtFin") + 7,props.query.length - 2).replace("/","-") : ""



    const getList = useCallback(() => {
        
        if (currentPage && currentPage > 0) {
            const f = [{ key: 'tipo', value: filtro }]

            if (props.query) {
            setLista([])
            ParlamentarAmbitoService.GetListInsight(props.id_parlamentar,dtIni,dtFin, f, currentPage, currentPageSize).then(async res => {
                // console.log("Resposta", res)
                if (res && res.success) {
                    setLista(res.lista);
                    setInfoList(res.listInfo);
    
                }
            })

            }else {
                setLista([])
                ParlamentarAmbitoService.GetList(props.id_parlamentar, f, currentPage, currentPageSize).then(async res => {
                    // console.log("Resposta", res)
                    if (res && res.success) {
                        setLista(res.lista);
                        setInfoList(res.listInfo);
                        console.log("Paginação", infoList)
                    }
                })

            }
        }
    }, [props.id_parlamentar, currentPage, currentPageSize, filtro])

    const setPage = (p: number) => setCurrentPage(p);
    const setFilter = (f: any) => setFiltro(f.target.value);

    return (

        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Grid container direction="row" alignItems="baseline" style={{
                        paddingLeft: "20px",
                        paddingTop: `${isMobile ? "10px" : "0px"}`,
                        marginBottom: `${isMobile ? "-10px" : "0px"}`
                    }} >
                        <Grid container item xs={6}>
                            <Grid style={{ marginRight: "8px" }}>
                                <Grid style={{
                                    color: `${pallet.textColorTertiary}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    {infoList?.totalItens}
                                </Grid>
                            </Grid>

                            <Grid>
                                <Grid item style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: "14px",
                                    fontWeight: pallet.general.weight_600,
                                    paddingTop: "2px"
                                }}>
                                    {infoList?.totalItens > 1 ? "REGISTROS" : "REGISTRO"}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Grid style={{ paddingRight: `${isMobile ? "21px" : "21px"}` }}>
                                        <ParlamentarMenuFiltroTipoComissao title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        {
                        infoList?.totalItens > 4 ?
                            
                            <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "20px" }}>
                                <FilterQuantity
                                    list={infoList}
                                    currentPageSize={currentPageSize}
                                    onChange={handleOnChangeItensPerPageSize}
                                />
                            </Grid>
                            : null}
                    </Grid>
                    <Grid style={{ paddingLeft: `${isMobile ? "20px" : "20px"}`, paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: "20px", paddingBottom: "20px" }}>
                        {/* {console.log("Renderizando", lista)} */}
                        <AccordionTextDataComissaoParlamentar lista={lista} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                    <PaginationSaveState
                        name={'ParlamentarComissaoList'}
                        pages={infoList?.totalPages}
                        page={currentPage}
                        onChange={setPage}
                    />
                </Grid>
            </Grid>
        </div>
    )
}