import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, Icon, Link, Menu, MenuItem, MenuProps, TextField, Typography } from '@mui/material';
import useStyles from '../../../../components/layout/Styles';
import { usePallet } from '../../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { CalendarToday, MoreHoriz } from '@material-ui/icons';
import { formatDate } from '../../../../utils/dateUtils';
import styled from "styled-components";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAlert } from "../../../../contexts/AlertContext";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useState, useEffect } from "react";
import { parlamentarQualificacoes } from '../../../../models/ParlamentarQualificacoes';
import ParlamentarDiscursoService from '../../../../services/ParlamentarDiscursoService';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import ParlamentarDemandasEdicao from '../../../parlamentar/_components/ParlamentarDemandasEdicao';
import EditIcon from '@mui/icons-material/Edit'
import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import DeleteIcon from '@mui/icons-material/Delete';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import ProposicaoService from '../../../../services/ProposicaoService';
import { proposicaoPosicionamento } from '../../../../models/Proposicao.Model';
import ProposicaoEmendasModal from '../emendas/ProposicaoEmendasModal';

const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
`;

function AccordionTextDataEmendaProposicao(props: {
    lista?: Array<any>,
    onChange?: () => void,
    id_proposicao?: number,
    proposicao_integrada?: boolean
}) {

    const { NewAlert } = useAlert();
    const { pallet } = usePallet();
    const classes = useStyles();
    const [lista, setLista] = useState<Array<any>>(props.lista);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const [excluidos, setExcluidos] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1);

    const corPosicionamento = (value: string) => {

        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }

    function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.posicionamento}
                    </Grid>
                </Grid>
            </>
        )
    }

    const posicionamentosItens = [
        // { key: 0, label: "", value: "" },
        { key: 0, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "Neutro" },
        { key: 1, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "Contrário com emenda saneadora" },
        { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "Contrário" },
        { key: 3, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "Favorável com emenda saneadora" },
        { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "Favorável" },
        { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "Favorável ao parecer do relator" },
        { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "Favorável ao parecer do relator com emenda" },
        { key: 7, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "Contrário ao parecer do relator" },
        { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "Contrário ao parecer do relator com emenda" },
        { key: 8, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "Pendente" },
        { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "Indefinido" },

    ]


    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        setLista(props.lista);
    },
        [props.lista]
    )

    const setCorrelataLista = (id: number, proposicao: string) => {
        const objIndex = lista.findIndex((object => object.id === id))
        if (objIndex >= 0) {
            lista[objIndex].posicionamento = proposicao
            setLista(lista)
        }
    }


    async function loadCorrelatas() {
        props.onChange();
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined)
    };

    const deletarCorrelata = (id) => {
        ProposicaoService.DeleteCorrelata(id).then(res => {
            if (res) {
                NewAlert('success', 'Correlata apagada com sucesso!')
                handleClose()
            } else {
                NewAlert('error', 'Erro ao apagar, verifique os campos e apague novamente');
            }
        })
            .then(x => loadCorrelatas())
    }

    // const handlePosicionamentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRegistro(event.target.value);

    //     ParlamentarDemandaService.Update({
    //         resultado: event.target.value, posicionamentos: props.listaPocionamento.map(item => {
    //             return item.id
    //         })
    //     }, props.idRef).then(res => {
    //         if (res) {
    //             NewAlert('success', 'Resultado salvo com sucesso');

    //         } else {
    //             // NewAlert('error', 'Não foi possível salvar o resultado Verifique se todos os Posicionamentos estão preenchidos');
    //         }
    //     })
    //         .then(data => {
    //             props.reloadList()
    //         })
    // }

    const handlePosicionamentoChange = (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        ProposicaoService.SetPosicionamentoCorrelata(id, event.target.value).then(x => {
            setCorrelataLista(id, event.target.value);
            loadCorrelatas();
            NewAlert('success', 'Registro Salvo com sucesso');

        });

        return event.target.value
    }


    const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 6px 4px 6px;
    /* margin-bottom: 7px; */
    line-height: 15px;
    letter-spacing: 1px;
`;

    const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 5px;
    margin-top: 3px;
`;
    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: ${isMobile ? "100px" : "70px"};
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
`;


    const StyledMenu = styled((props: MenuProps) => (
        <Menu

            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 135,
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '5px 0px',
                minWidth: 140,

            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    // fontSize: 18,
                },
            },
        },
    }));

    return (
        <>

            {lista?.map(item => {
                return (
                    <Grid key={item.id} style={{ marginBottom: "20px" }}>
                        <Accordion expanded={true} elevation={0} style={{ border: "1px solid #EAEAEA", background: `${pallet.backGroundPageColorPrimary}` }} >
                            <AccordionSummary style={{
                                paddingLeft: `${isMobile ? "5px" : "20px"}`,
                                borderRadius: "5px",
                                // paddingLeft: "20px",
                                paddingRight: "20px",
                                flexFlow: "row-reverse",
                                height: "64px",
                                cursor: "default"
                            }}

                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container justifyContent="space-between" direction="row" >
                                    <Grid container item lg={6} xs={6} >
                                        <Typography className={classes.heading} style={{
                                            color: `${pallet.textColorTertiary}`,
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`,
                                            fontWeight: pallet.general.weight_600,
                                            marginTop: "6px",
                                            // marginRight: "10px"
                                        }}>
                                            {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "10px"}`, textOverflow: "elipsis", whiteSpace: "nowrap" }}> </span>}
                                            <Link
                                                href={`/proposicao/${item.id_proposicao}`}
                                                style={{
                                                    color: `${pallet.color.secundary.font}`,
                                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    // fontWeight: pallet.general.weight_600,
                                                    textDecoration: "none",
                                                }}
                                                target="_blank"
                                            >
                                                {item.proposicao} &nbsp; | &nbsp;
                                            </Link>
                                        </Typography>


                                        {/* <------------DATA E HORA-------> */}
                                        <Grid container xl={3} lg={3} item style={{
                                            maxWidth: `${isMobile ? "210px" : "250px"}`
                                        }}
                                        >
                                            <Grid>
                                                <CalendarToday style={{
                                                    color: `${pallet.backgroundColorPrimary}`,
                                                    height: "22px",
                                                    width: "20px",
                                                    marginRight: "10px",
                                                    marginTop: "3px",
                                                    marginLeft: `${isMobile ? "0px" : "5px"}`
                                                }} />
                                            </Grid>
                                            <Grid >
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.textColorQuaternary}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    fontWeight: pallet.general.weight_600,
                                                }}>
                                                    <Grid container
                                                        style=
                                                        {{
                                                            marginTop: "5px",
                                                        }}>
                                                        {formatDate(new Date(item.data), 'dd/MM/yyyy')}

                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid item lg={6} xs={6}
                                    // textAlign={"end"}
                                    // style={{ position: "relative", paddingBottom: "24px" }}
                                    >
                                        <ButtonDialogConfirmation
                                            // styles={{
                                            //     fontFamily: `${pallet.fontFamily.general}`,
                                            //     fontWeight: pallet.fontWeight.button,
                                            //     fontSize: `${pallet.general.size14}`,
                                            //     color: `${pallet.color.tertiary.font}`,
                                            //     borderRadius: "6px 6px 0px 0px",
                                            //     textTransform: "capitalize"
                                            // }}
                                            styles={{
                                                backgroundColor: `${pallet.backgroundColorTertiary}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.lighter,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                color: `${pallet.color.quaternary.font}`,
                                                borderRadius: "6px",
                                                width: "25px",
                                                position: "absolute",
                                                top: "23px",
                                                height: "20px",
                                                right: "5px",
                                                zIndex: 10,
                                            }}

                                            title={"CONFIRMAÇÃO"}
                                            title_button={""}
                                            confirmation_button={"Apagar"}
                                            content={"APAGAR Correlata?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                            confirmationEvent={() => deletarCorrelata(item.id)}

                                        >
                                            <DeleteIcon style={{
                                                color: `${pallet.color.quaternary.font}`,
                                                marginRight: "16px",
                                                fontFamily: `${pallet.fontFamily.general}`,
                                            }} />

                                        </ButtonDialogConfirmation>

                                    </Grid >

                                    {/* ----DESCRICAO E LABEL---- */}


                                </Grid>
                            </AccordionSummary>

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                background: `${pallet.backGroundPageColorSecondary}`

                            }}>
                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "20px 20px 10px 20px"}`, width: "100%" }}>
                                    <Grid xl={12} container justifyContent="space-between">
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <Grid style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.general.size12}`,
                                                color: `${pallet.color.tertiary.font}`
                                            }}>
                                                DESCRIÇÃO
                                            </Grid>

                                            <Grid style={{
                                                marginTop: "10px",
                                                fontWeight: pallet.general.weight_300,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`
                                            }}
                                            >
                                                {item.descricao}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Divider variant="fullWidth" style={{ paddingTop: "10px" }} />

                                    <Grid style={{ padding: `${isMobile ? "20px 5px 10px 0px" : "20px 20px 10px 0px"}`, width: "100%" }}>
                                        <Grid style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.general.size12}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            paddingBottom: "20px"
                                        }}>

                                            REGISTRO
                                        </Grid>
                                        <Grid >
                                            <Box component="form">
                                                <SelectTextFieldA
                                                    value={item?.posicao}
                                                    onChange={(e) => handlePosicionamentoChange(item.id, e)}
                                                    //options={proposicaoPosicionamento}
                                                    options={posicionamentosItens}
                                                    id={"posicionamento" + item.id}
                                                    label={"POSICIONAMENTO"}

                                                />
                                            </Box>
                                            <Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataEmendaProposicao;


