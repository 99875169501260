import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Main from "../main/Main";
import useStyles from "../../components/layout/Styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import { IFilterData } from "../../components/filter/ListFilter";

import ComboBox from "../../components/ComboBox";
import Button from "@mui/material/Button";
import ButtonOutLine from "../../components/ButtonOutline";
import { usePallet } from "../../contexts/PalletContext";
import {
  Box,
  Link,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import PieChartInfoCard from "../../components/PieChartInfoCard";
import { useDashboard } from "../../contexts/DashboardContext";
import CalendarOutlineIcon from "@mui/icons-material/CalendarTodayOutlined";
import DateRangeSelector from "../../components/DateRangeSelector";
import filtersBackgroundImage from "../../../src/img/background_filters_dashboard.png";
import SelectTextFieldA from "../../components/SelectTextFieldA";
import TabelasService from '../../services/TabelasService';
import { IDashboardProposicao } from "../../models/Proposicao.Model";
import ProposicaoService from '../../services/ProposicaoService';
import { format, subYears } from "date-fns";
import { formatDate } from "../../utils/dateUtils";
import { useAuthorization } from "../../contexts/AuthorizationContext";
import moment from 'moment-timezone';

const defaultFilter: IFilterData[] = [
  { key: "esfera", label: "ESFERA: Federal", value: "Federal" },
  { key: "legislatura", label: "LEGISLATURA: 57", value: "57" },
  { key: "situacao", label: "Exercício", value: "Exercício" },
];

const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
`;

const FilterQuantity = styled(Typography)`
  text-transform: uppercase;
  font-weight: ${(props) => props.pallet.general.weight_500};
  color: ${(props) => props.pallet.color.colorBase.labelTextFild};
  font-size: ${(props) => props.pallet.general.size2};
  display: flex;
  align-items: center;

  > strong {
    color: ${(props) => props.pallet.textColorQuaternary};
    font-weight: ${(props) => props.pallet.general.weight_500};

    &:first-child {
      font-weight: bold;
    }
  }
`;

const FilterQuantityInput = styled(TextField)`
  margin: 0 10px;

  .MuiOutlinedInput-input {
    width: 30px;
    padding-top: 10px;
    padding-bottom: 10px;

    svg {
      color: ${(props) => props.pallet.textColorQuaternary};
    }
  }
`;

const FilterButton = styled(Button)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  color: #160342;
  border: 0;
  font-size: 14px;
  line-height: 17px;
  background: rgba(75, 17, 150, 0.1);
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  height: 100%;
`;

const ContainerHead = styled(Grid)`
  margin-bottom: 10px;
  margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
  margin-bottom: 30px;
`;

const ContainerCombo = styled(Grid)`
  width: 100%;
  margin-bottom: 5px;
`;

const ContainerFilter = styled(Grid)`
  /* width: 10%; */
  width: 100%;
  margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
  justify-content: ${isMobile ? "center" : "space-between"};
`;

const ContainerCard = styled(Grid)`
  margin-top: 20px;
`;

const ContainerFooter = styled(Grid)`
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
`;

export default function ProposicaoDashboard() {
  const { pallet } = usePallet();
  const classes = useStyles();


  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const handleToggleFilter = () => setOpenFilter(!openFilter);

  const [etiqueta, setEtiqueta] = useState("Todas")

  const [sumaryFedUN, setSumaryFedUN] = useState({
    ativas: 0,
    inativas: 0,

  });

  const [sumaryEstUN, setSumaryEstUN] = useState({
    ativas: 0,
    inativas: 0,

  });

  const [sumaryMunUN, setSumaryMunUN] = useState({
    ativas: 0,
    inativas: 0,

  });

  const [sumaryFedMC, setSumaryFedMC] = useState({
    designacaoRelator: 0,
    aguardanoParecerDoRelator: 0,
    prazoParaEmenda: 0,
    aguardaDeliberacao: 0,
  });

  const [sumaryFedAP, setSumaryFedAP] = useState({
    concluido: 0,
    pendentes: 0,
    total: 0,
  });

  const [sumaryEstAP, setSumaryEstAP] = useState({
    concluido: 0,
    pendentes: 0,
    total: 0,
  });

  const [sumaryMunAP, setSumaryMunAP] = useState({
    concluido: 0,
    pendentes: 0,
    total: 0,
  });

  const [sumaryFedNT, setSumaryFedNT] = useState({
    concluidas: 0,
    pendentes: 0,
  });

  const [sumaryEstNT, setSumaryEstNT] = useState({
    concluidas: 0,
    pendentes: 0,
  });

  const [sumaryMunNT, setSumaryMunNT] = useState({
    concluidas: 0,
    pendentes: 0,
  });

  const [sumaryFedAI, setSumaryFedAI] = useState({
    exito: 0,
    exitoParcial: 0,
    semExito: 0,
    pendente: 0,
  });

  const [sumaryEstAI, setSumaryEstAI] = useState({
    exito: 0,
    exitoParcial: 0,
    semExito: 0,
    pendente: 0,
  });

  const [sumaryMunAI, setSumaryMunAI] = useState({
    exito: 0,
    exitoParcial: 0,
    semExito: 0,
    pendente: 0,
  });


  const [esferaTotal, setEsferaTotal] = useState();
  const [EsfSel, setEsfSel] = useState<string>("F");
  const [Filtro, setFiltro] = useState();
  //const [dtIni, setDtIni] = React.useState<Date>(subYears(new Date(), 1));
  const [dtIni, setDtIni] = React.useState<Date>(moment().subtract(1, 'months').toDate());
  const [dtFin, setDtFin] = React.useState<Date>(moment().toDate());

  const [casa, setCasa] = useState("Todas")
  const [uf, setUf] = useState("0");
  const [municipio, setMunicipio] = useState("0");
  const [municipioNome, setMunicipioNome] = useState("");
  const [arrayUf, setArrayUf] = useState([]);
  const [arrayMunicipio, setArrayMunicipio] = useState([]);
  const [arrayMunicipioNome, setArrayMunicipioNome] = useState([]);
  const [dataFilter, setDataFilter] = useState<Array<Date>>([subYears(new Date(), 1), new Date()]);
  const { user } = useAuthorization();
  
  const handleEsfera = (event, newSelEsf) => {
    setEsfSel(newSelEsf);
    setCasa("Todas")
    setUf("0")
    setMunicipio("0")
  };
  

  React.useEffect(() => {
    TabelasService.getTabelaEstados().then(
      res => {
        if (res) {
          const data = []
          data.push({ key: '', label: 'Selecione', value: '0' });
          res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.sigla }))
          setArrayUf(() => [...data]);
          
        }
      })
      
      ProposicaoService.GetSummary(dtIni, dtFin, [
        { "key": "esfera", "value": "Federal" },
      ]).then(
        
        res => {
          if (res) {
            setSumaryFedUN(res.universo);
          setSumaryFedMC(res.momentosCriticos);
          setSumaryFedAP(res.acaoPreparatoria);
          setSumaryFedNT(res.notasTecnicas);
          setSumaryFedAI(res.acoesInfluencia);


        }
      })

  }, []);

  React.useEffect(() => {

    if (EsfSel === "F"){
    if (casa === "Todas") {
      ProposicaoService.GetSummary(dtIni, dtFin, [

        { "key": "esfera", "value": "Federal" },

      ]).then(

        res => {
          if (res) {
            setSumaryFedUN(res.universo);
            setSumaryFedMC(res.momentosCriticos);
            setSumaryFedAP(res.acaoPreparatoria);
            setSumaryFedNT(res.notasTecnicas);
            setSumaryFedAI(res.acoesInfluencia);


          }
        })
    } else {

      ProposicaoService.GetSummary(dtIni, dtFin, [

        { "key": "casa", "value": casa },

      ]).then(

        res => {
          if (res) {
     
            setSumaryFedUN(res.universo);
            setSumaryFedMC(res.momentosCriticos);
            setSumaryFedAP(res.acaoPreparatoria);
            setSumaryFedNT(res.notasTecnicas);
            setSumaryFedAI(res.acoesInfluencia);


          }
        })

    }
  }else if(EsfSel === "E"){


    TabelasService.getTabelaMunicipios(uf).then(
      res => {
        if (res) {
          const data = []
          data.push({ key: '', label: 'Selecione', value: '0' });
          res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.sigla }))
          setArrayMunicipio(() => [...data]);
        }
      },


      resII => {
        if (resII) {
          const data = []
          data.push({ key: '', label: 'Selecione', value: '0' });
          resII.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.nome }))
          setArrayMunicipioNome(() => [...data]);
        }
      })

    if (uf === "0" && EsfSel === "E") {
      ProposicaoService.GetSummary(dtIni, dtFin, [

        { "key": "esfera", "value": "Estadual" },

      ]).then(
        res => {
          if (res) {
            setSumaryEstUN(res.universo);
            setSumaryEstAP(res.acaoPreparatoria);
            setSumaryEstNT(res.notasTecnicas);
            setSumaryEstAI(res.acoesInfluencia);
          }
        })
    }

    if(EsfSel === 'E' && uf !== "0") {
      ProposicaoService.GetSummary(dtIni, dtFin, [

        { "key": "estado", "value": uf },

      ]).then(
        
        res => {
          console.log("RRAAS",uf)
          if (res) {
            setSumaryEstUN(res.universo);
            setSumaryEstAP(res.acaoPreparatoria);
            setSumaryEstNT(res.notasTecnicas);
            setSumaryEstAI(res.acoesInfluencia);
          }
        })
    }
  }else{

    setMunicipio(municipio)
    
        arrayMunicipio.filter(i => i.value === municipio).map((i) => { setMunicipioNome(i.label) })
    console.log("Municipio filtrado", arrayMunicipio.filter(i => i.value === municipio))

    if (municipio === "0" && uf === "0") {
      ProposicaoService.GetSummary(dtIni, dtFin, [

        { "key": "esfera", "value": "Municipal" },

      ]).then(
        res => {
          if (res) {
            setSumaryMunUN(res.universo);
            setSumaryMunAP(res.acaoPreparatoria);
            setSumaryMunNT(res.notasTecnicas);
            setSumaryMunAI(res.acoesInfluencia);
          }
        })
    } else if (municipio === "0" && uf !== "0") {
      console.log("EU ESTOU AQUI",uf)
      ProposicaoService.GetSummary(dtIni, dtFin, [

        { "key": "estado", "value": uf },

      ]).then(
        res => {
          if (res) {
            setSumaryEstUN(res.universo);
            setSumaryEstAP(res.acaoPreparatoria);
            setSumaryEstNT(res.notasTecnicas);
            setSumaryEstAI(res.acoesInfluencia);
            setSumaryMunUN(res.universo);
            setSumaryMunAP(res.acaoPreparatoria);
            setSumaryMunNT(res.notasTecnicas);
            setSumaryMunAI(res.acoesInfluencia);

          }
        })
    } else {


      ProposicaoService.GetSummary(dtIni, dtFin, [

        { "key": "municipio", "value": municipio },

      ]).then(

        res => {
          if (res) {
            setSumaryMunUN(res.universo);
            setSumaryMunAP(res.acaoPreparatoria);
            setSumaryMunNT(res.notasTecnicas);
            setSumaryMunAI(res.acoesInfluencia);


          }
        })
    }

  }


  }, [EsfSel,casa,uf,municipio,dtIni,dtFin]);


  const casasLegislativas = [
    { key: 'Selecione', label: 'Selecione', value: 'Todas' },
    { key: 'Camara', label: 'Câmara dos Deputados', value: 'CD' },
    { key: 'Senado', label: 'Senado Federal', value: 'SF' },
    { key: 'Congresso', label: 'Congresso Nacional', value: 'CN' },
  ]

  const handleCasaLegislativaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCasa(event.target.value)
    

    if (event.target.value === "Todas") {
      setEtiqueta("Todas")
    } else if (event.target.value === "CD") {
      setEtiqueta("Câmara dos Deputados")
    } else if (event.target.value === "SF") {
      setEtiqueta("Senado Federal")
    } else if (event.target.value === "CN") {
      setEtiqueta("Congresso Nacional")
    }

  };

  const handleEstadoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMunicipio("0")
    setUf(event.target.value)
    

    TabelasService.getTabelaMunicipios(event.target.value).then(
      res => {
        if (res) {
          const data = []
          data.push({ key: '', label: 'Selecione', value: '0' });
          res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.sigla }))
          setArrayMunicipio(() => [...data]);
        }
      })

  };
  const handleMunicipioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMunicipio(event.target.value)
  };

  const onChangeDate = (dtIni: Date, dtFin: Date) => {

    console.log("DATA CHANGE",dtIni,dtFin)

    setDtIni(dtIni);
    setDtFin(dtFin);

  };

  return (
    <div className={classes.root}>
      <Main>
        <>
          <ContainerHead container>
            <ContainerSubHead
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                style={{
                  color: `${pallet.textColorSecondary}`,
                  fontWeight: pallet.general.weight_600,
                  fontSize: "24px",
                  fontFamily: `${pallet.general.fontFamily}`,
                }}
              >
                Proposições
              </Grid>

            </ContainerSubHead>

            <Grid
              style={{
                width: isMobile ? "60%" : "100%",
              }}
            />

          </ContainerHead>
          <ContainerBody container>
            <Grid container spacing={2}>
              <Grid container style={{ paddingLeft: "12px" }}>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    backgroundImage: `url(${filtersBackgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    padding: "25px",
                    borderRadius: "5px",
                    boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                  }}
                >
                  <Grid item>
                    <Grid style={{ marginBottom: "5px" }}>
                      <Typography
                        style={{
                          color: `${pallet.color.secundary.font}`,
                          fontSize: `${pallet.fontSize.titleWidgets}`,
                          fontFamily: `${pallet.fontFamily.general}`,
                          fontWeight: pallet.fontWeight.subtitle,
                        }}
                      >
                        PERÍODO
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      style={{
                        maxWidth: `${isMobile ? "100%" : "300px"}`,
                        width: "250px",
                        background: "#4B1196",
                        borderRadius: "5px",
                        justifyContent: `${isMobile ? "flex-start" : "center"}`,
                      }}
                    >
                      <Grid item>
                        <CalendarOutlineIcon
                          style={{
                            color: `${pallet.color.primary.font}`,
                            marginTop: "6px",
                            fontSize: "17px",
                            marginRight: "0px",
                            marginLeft: `${isMobile ? "6px" : "5px"}`
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingRight: "10px",
                          color: `${pallet.color.primary.font}`,
                        }}
                      >
                        <DateRangeSelector
                          startDate={dtIni}
                          endDate={dtFin}
                          onChange={onChangeDate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginLeft: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "10px" : "0px"}` }}>
                    <Grid style={{ marginBottom: "5px" }}>
                      <Typography
                        style={{
                          color: `${pallet.color.secundary.font}`,
                          fontSize: `${pallet.fontSize.titleWidgets}`,
                          fontFamily: `${pallet.fontFamily.general}`,
                          fontWeight: pallet.fontWeight.subtitle,
                        }}
                      >
                        ESFERAS
                      </Typography>
                    </Grid>

                    <Grid container alignItems="center" direction="row">
                      <Grid item>
                        <Box display="block">
                          <Grid
                            style={{
                              backgroundColor: "#fcfcfc",
                              borderRadius: "4px",
                            }}
                          >
                            <ToggleButtonGroup
                              value={EsfSel}
                              exclusive={true}
                              onChange={handleEsfera}
                              aria-label="sphere"
                              size="small"
                            >
                              <ToggleButton
                                value="F"
                                aria-label="device"
                                sx={{
                                  "&.Mui-selected": {
                                    color: `${pallet.textColorPrimary}`,
                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: isMobile ? `${pallet.backgroundColorPrimary}` : "#4B1196",
                                },
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.general.weight_600,
                                  }}
                                >
                                  Federal
                                </Typography>
                              </ToggleButton>
                              <ToggleButton
                                value="E"
                                aria-label="device"
                                sx={{
                                  "&.Mui-selected": {
                                    color: `${pallet.textColorPrimary}`,
                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: isMobile ? `${pallet.backgroundColorPrimary}` : "#4B1196",
                                },
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.general.weight_600,
                                  }}
                                >
                                  Estadual
                                </Typography>
                              </ToggleButton>

                              <ToggleButton
                                value="M"
                                aria-label="device"
                                sx={{
                                  "&.Mui-selected": {
                                    color: `${pallet.textColorPrimary}`,
                                    backgroundColor: `${pallet.backgroundColorPrimary}`,
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: isMobile ? `${pallet.backgroundColorPrimary}` : "#4B1196",
                                },
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.general.weight_600,
                                  }}
                                >
                                  Municipal
                                </Typography>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>

                  </Grid>
                  {EsfSel === "F" ?
                    <Grid item xs={10} lg={3} xl={3} style={{ marginLeft: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "10px" : "0px"}` }}>
                      <Grid style={{ marginBottom: "5px" }}>
                        <Typography
                          style={{
                            color: `${pallet.color.secundary.font}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: pallet.fontWeight.subtitle,
                          }}
                        >
                          CASA
                        </Typography>
                      </Grid>
                      <Grid style={{ backgroundColor: "white", borderRadius: "4px", width: "100%" }}>
                        <SelectTextFieldA
                          value={casa}
                          altura="37px"
                          options={casasLegislativas}
                          id={"casa-dashboard"}
                          onChange={handleCasaLegislativaChange}
                        />
                      </Grid>
                    </Grid>
                    : null}
                  {EsfSel === "E" || EsfSel === "M" ?
                    <Grid item xs={10} lg={2} xl={2} style={{ marginLeft: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "10px" : "0px"}` }}>
                      <Grid style={{ marginBottom: "5px" }}>
                        <Typography
                          style={{
                            color: `${pallet.color.secundary.font}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: pallet.fontWeight.subtitle,
                          }}
                        >
                          ESTADO
                        </Typography>
                      </Grid>
                      <Grid style={{ backgroundColor: "white", borderRadius: "4px", width: "100%" }}>
                        <SelectTextFieldA
                          value={uf}
                          altura="37px"
                          options={arrayUf}
                          id={"estado-dashboard"}
                          onChange={handleEstadoChange}
                        />
                      </Grid>
                    </Grid>
                    : null}
                  {EsfSel === "M" && uf !== "0" ?
                    <Grid item xs={10} lg={3} xl={3} style={{ marginLeft: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "10px" : "0px"}` }}>
                      <Grid style={{ marginBottom: "5px" }}>
                        <Typography
                          style={{
                            color: `${pallet.color.secundary.font}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: pallet.fontWeight.subtitle,
                          }}
                        >
                          MUNICÍPIO
                        </Typography>
                      </Grid>
                      <Grid style={{ backgroundColor: "white", borderRadius: "4px", width: "100%" }}>
                        <SelectTextFieldA
                          value={municipio}
                          altura="37px"
                          options={arrayMunicipio}
                          id={"municipio-dashboard"}
                          onChange={handleMunicipioChange}
                        />
                      </Grid>
                    </Grid>
                    : null}
                </Grid>

              </Grid>

              {EsfSel === "F" ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                  <PieChartInfoCard
                    title="UNIVERSO"
                    labels={[
                      {
                        key: "Ativos",
                        data: sumaryFedUN?.ativas,
                        color: `${pallet.color.charts.green}`,
                        url: etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" }]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true}]'

                      },
                      {
                        key: "Inativos",
                        data: sumaryFedUN?.inativas,
                        color: `${pallet.color.charts.red}`,
                        url: etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" }]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false}]'
                    }
                    ]}
                  />
                </Grid>
              ) : null}

              {EsfSel === "E" ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                  <PieChartInfoCard
                    title="UNIVERSO"
                    labels={[
                      {
                        key: "Ativos",
                        data: sumaryEstUN?.ativas,
                        color: `${pallet.color.charts.green}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true}]'
                        
                      },
                      {
                        key: "Inativos",
                        data: sumaryEstUN?.inativas,
                        color: `${pallet.color.charts.red}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                      }
                    ]}
                  />
                </Grid>
              ) : null}

              {EsfSel === "M" ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                  <PieChartInfoCard
                    title="UNIVERSO"
                    labels={[
                      {
                        key: "Ativos",
                        data: sumaryMunUN?.ativas,
                        color: `${pallet.color.charts.green}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                      },
                      {
                        key: "Inativos",
                        data: sumaryMunUN?.inativas,
                        color: `${pallet.color.charts.red}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Não","value":false}]'
                      }


                      
                    ]}
                  />
                </Grid>
              ) : null}

              {EsfSel === "F" ? (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  xs={12}
                  style={{ paddingTop: "40px", paddingLeft: `${isMobile ? "17px" : "40px"}` }}
                >
                  <PieChartInfoCard
                    title="MOMENTOS CRÍTICOS"
                    labels={[
                      {
                        key: "Aguardando Designação do Relator",
                        data: sumaryFedMC?.designacaoRelator,
                        color: `${pallet.color.charts.green}`,
                        url:  etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key": "momento","label": "MOMENTO: Aguardando Designação do Relator","value": "ADR"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +  formatDate(dtIni, 'dd/MM/yyyy') +'","value" : "' + formatDate(dtIni, 'MM/dd/yyyy') + '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +  formatDate(dtFin, 'dd/MM/yyyy')  +'","value" : "' + formatDate(dtFin, 'MM/dd/yyyy') + '"}]'

                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key": "momento","label": "MOMENTO: Aguardando Designação do Relator","value": "ADR"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +  formatDate(dtIni, 'dd/MM/yyyy') +'","value" : "' + formatDate(dtIni, 'MM/dd/yyyy') + '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +  formatDate(dtFin, 'dd/MM/yyyy')  +'","value" : "' + formatDate(dtFin, 'MM/dd/yyyy') + '"}]'

                      },
                      {
                        key: "Aguardando Parecer do Relator",
                        data: sumaryFedMC?.aguardanoParecerDoRelator,
                        color: `${pallet.color.charts.red}`,
                        url:  etiqueta !== "Todas" ? 
                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key": "momento","label": "MOMENTO: Aguardando Parecer do Relator","value": "APR"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtIni, "MM/dd/yyyy") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtFin, "MM/dd/yyyy") +
                          '"}]'
                          :
                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Parecer do Relator","value": "APR"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtIni, "MM/dd/yyyy") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtFin, "MM/dd/yyyy") +
                          '"}]'
                      },
                      {
                        key: "Prazo para emendas",
                        data: sumaryFedMC?.prazoParaEmenda,
                        color: `${pallet.color.charts.blue}`,
                        url:  etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Prazo para Emendas","value": "PE"},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                          :
                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Prazo para Emendas","value": "PE"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtIni, "MM/dd/yyyy") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtFin, "MM/dd/yyyy") +
                          '"}]'
                      },
                      {
                        key: "Aguardando Deliberação",
                        data: sumaryFedMC?.aguardaDeliberacao,
                        color: `${pallet.color.charts.yellow}`,
                        url:  etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Deliberação","value": "AD"},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                          :
                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "momento","label": "MOMENTO: Aguardando Deliberação","value": "AD"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtIni, "MM/dd/yyyy") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtFin, "MM/dd/yyyy") +
                          '"}]'
                      },
                    ]}
                  />
                </Grid>
              ) : null}

              {EsfSel === "F" ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                  <PieChartInfoCard
                    title="AÇÃO PREPARATÓRIA"
                    labels={[
                      {
                        key: "Concluído",
                        data: sumaryFedAP?.total - sumaryFedAP?.pendentes,
                        color: `${pallet.color.charts.green}`,
                        url:  etiqueta !== "Todas" ?'/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Concluída","value": "1"},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                          : 
                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Concluída","value": "1"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                          '"}]'

                        },
                      {
                        key: "Pendente",
                        data: sumaryFedAP?.pendentes,
                        color: `${pallet.color.charts.yellow}`,
                        url:  etiqueta !== "Todas" ?'/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Pendente","value": "0"},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                          : 
                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Pendente","value": "0"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                          '"}]'
                      }
                    ]}
                  />
                </Grid>
              ) : null}

              {EsfSel === "E" ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: `${isMobile ? "17px" : "40px"}` }}>
                  <PieChartInfoCard
                    title="AÇÃO PREPARATÓRIA"
                    labels={[
                      {
                        key: "Concluído",
                        data: sumaryEstAP?.total - sumaryEstAP?.pendentes,
                        color: `${pallet.color.charts.green}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Concluída","value": "1"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Concluída","value": "1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                      },
                      {
                        key: "Pendente",
                        data: sumaryEstAP?.pendentes,
                        color: `${pallet.color.charts.yellow}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Pendente","value": "0"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Pendente","value": "0"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                                            }
                    ]}
                  />
                </Grid>
              ) : null}

              {EsfSel === "M" ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: `${isMobile ? "17px" : "40px"}` }}>
                  <PieChartInfoCard
                    title="AÇÃO PREPARATÓRIA"
                    labels={[
                      {
                        key: "Concluído",
                        data: sumaryMunAP?.total - sumaryMunAP?.pendentes,
                        color: `${pallet.color.charts.green}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Concluída","value": "1"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Concluída","value": "1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Concluída","value": "1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                      },
                      {
                        key: "Pendente",
                        data: sumaryMunAP?.pendentes,
                        color: `${pallet.color.charts.yellow}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Pendente","value": "0"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Pendente","value": "0"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key": "acaoPreparatoria","label": "AÇÃO PREPARATÓRIA: Pendente","value": "0"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'

        
                      }
                    ]}
                  />
                </Grid>
              ) : null}

              {EsfSel === "F" ? (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  xs={12}
                  style={{ paddingTop: "40px", paddingLeft: `${isMobile ? "17px" : "40px"}` }}
                >
                  <PieChartInfoCard
                    title="NOTA TÉCNICA"
                    labels={[
                      {
                        key: "Concluído",
                        data: sumaryFedNT?.concluidas,
                        color: `${pallet.color.charts.green}`,
                        url:  etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key": "notaTecnica","label": "NOTA TÉCNICA: Concluída","value": "1"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                          : 
                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key": "notaTecnica","label": "NOTA TÉCNICA: Concluída","value": "1"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                          '"}]'
                      },
                      {
                        key: "Pendente",
                        data: sumaryFedNT?.pendentes,
                        color: `${pallet.color.charts.yellow}`,
                        url:  etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "notaTecnica","label": "NOTA TÉCNICA: Pendente","value": "0"},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                          : 
                          '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "notaTecnica","label": "NOTA TÉCNICA: Pendente","value": "0"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                          '"}]'
                    }
                    ]}
                  />
                </Grid>
              ) : null}


              {EsfSel === "E" ? (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  xs={12}
                  style={{ paddingTop: "40px" }}
                >
                  <PieChartInfoCard
                    title="NOTA TÉCNICA"
                    labels={[
                      {
                        key: "Concluído",
                        data: sumaryEstNT?.concluidas,
                        color: `${pallet.color.charts.green}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key": "notaTecnica","label": "NOTA TÉCNICA: Concluída","value": "1"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key": "notaTecnica","label": "NOTA TÉCNICA: Concluída","value": "1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        
                      },
                      {
                        key: "Pendente",
                        data: sumaryEstNT?.pendentes,
                        color: `${pallet.color.charts.yellow}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "notaTecnica","label": "NOTA TÉCNICA: Pendente","value": "0"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "notaTecnica","label": "NOTA TÉCNICA: Pendente","value": "0"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'

                      }
                    ]}
                  />
                </Grid>
              ) : null}

              {EsfSel === "M" ? (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  xs={12}
                  style={{ paddingTop: "40px" }}
                >
                  <PieChartInfoCard
                    title="NOTA TÉCNICA"
                    labels={[
                      {
                        key: "Concluído",
                        data: sumaryMunNT?.concluidas,
                        color: `${pallet.color.charts.green}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "notaTecnica","label": "NOTA TÉCNICA: Concluída","value": "1"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key": "notaTecnica","label": "NOTA TÉCNICA: Concluída","value": "1"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key": "notaTecnica","label": "NOTA TÉCNICA: Concluída","value": "1"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                      },
                      {
                        key: "Pendente",
                        data: sumaryMunNT?.pendentes,
                        color: `${pallet.color.charts.yellow}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "notaTecnica","label": "NOTA TÉCNICA: Pendente","value": "0"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "notaTecnica","label": "NOTA TÉCNICA: Pendente","value": "0"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key": "notaTecnica","label": "NOTA TÉCNICA: Pendente","value": "0"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                      }
                    ]}
                  />
                </Grid>
              ) : null}


              {EsfSel === "F" &&  user.id_permissao !== 4 ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                  <PieChartInfoCard
                    title="AÇÕES DE INFLUÊNCIA"
                    labels={[
                      {
                        key: "Êxito",
                        data: sumaryFedAI?.exito,
                        color: `${pallet.color.charts.green}`,
                        url:  etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                              formatDate(dtIni, "dd/MM/yyyy") +
                              '","value" : "' +
                              formatDate(dtIni, "MM/dd/yyyy") +
                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                              formatDate(dtFin, "dd/MM/yyyy") +
                              '","value" : "' +
                              formatDate(dtFin, "MM/dd/yyyy") +
                              '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                              formatDate(dtIni, "dd/MM/yyyy") +
                              '","value" : "' +
                              formatDate(dtIni, "MM/dd/yyyy") +
                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                              formatDate(dtFin, "dd/MM/yyyy") +
                              '","value" : "' +
                              formatDate(dtFin, "MM/dd/yyyy") +
                              '"}]'
                      },
                      {
                        key: "Êxito Parcial",
                        data: sumaryFedAI?.exitoParcial,
                        color: `${pallet.color.charts.blue}`,
                        url: etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                              formatDate(dtIni, "dd/MM/yyyy") +
                              '","value" : "' +
                              formatDate(dtIni, "MM/dd/yyyy") +
                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                              formatDate(dtFin, "dd/MM/yyyy") +
                              '","value" : "' +
                              formatDate(dtFin, "MM/dd/yyyy") +
                              '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                              formatDate(dtIni, "dd/MM/yyyy") +
                              '","value" : "' +
                              formatDate(dtIni, "MM/dd/yyyy") +
                              '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                              formatDate(dtFin, "dd/MM/yyyy") +
                              '","value" : "' +
                              formatDate(dtFin, "MM/dd/yyyy") +
                              '"}]'
                      },
                      {
                        key: "Sem Êxito",
                        data: sumaryFedAI?.semExito,
                        color: `${pallet.color.charts.red}`,
                        url: etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                  :
                  '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                      },
                      {
                        key: "Pendente",
                        data: sumaryFedAI?.pendente,
                        color: `${pallet.color.charts.yellow}`,
                        url: etiqueta !== "Todas" ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"casa","label":"CASA: ' + etiqueta + '","value":"' + casa + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                  :
                  '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                       },
                    ]}
                  />
                </Grid>
              ) : null}


              {EsfSel === "E" &&  user.id_permissao !== 4  ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: `${isMobile ? "17px" : "40px"}` }}>
                  <PieChartInfoCard
                    title="AÇÕES DE INFLUÊNCIA"
                    labels={[
                      {
                        key: "Êxito",
                        data: sumaryEstAI?.exito,
                        color: `${pallet.color.charts.green}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                      },
                      {
                        key: "Êxito Parcial",
                        data: sumaryEstAI?.exitoParcial,
                        color: `${pallet.color.charts.blue}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                      },
                      {
                        key: "Sem Êxito",
                        data: sumaryEstAI?.semExito,
                        color: `${pallet.color.charts.red}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                      },
                      {
                        key: "Pendente",
                        data: sumaryEstAI?.pendente,
                        color: `${pallet.color.charts.yellow}`,
                        url: uf !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                        : '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy 23:59:59") +
                        '"}]'
                      },
                    ]}
                  />
                </Grid>
              ) : null}


              {EsfSel === "M" &&  user.id_permissao !== 4  ? (
                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: `${isMobile ? "17px" : "40px"}` }}>
                  <PieChartInfoCard
                    title="AÇÕES DE INFLUÊNCIA"
                    labels={[
                      {
                        key: "Êxito",
                        data: sumaryMunAI?.exito,
                        color: `${pallet.color.charts.green}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"acaoInfluencia","label":"Ação de Influência: Exito","value":"1"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                      },
                      {
                        key: "Êxito Parcial",
                        data: sumaryMunAI?.exitoParcial,
                        color: `${pallet.color.charts.blue}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Êxito Parcial","value":"3"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                      },
                      {
                        key: "Sem Êxito",
                        data: sumaryMunAI?.semExito,
                        color: `${pallet.color.charts.red}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Sem Êxito","value":"2"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        
                      },
                      {
                        key: "Pendente",
                        data: sumaryMunAI?.pendente,
                        color: `${pallet.color.charts.yellow}`,
                        url: uf !== '0' && municipio !== '0' ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"municipio","label":"MUNICIPIO: ' + municipioNome + '","value":"' + municipio + '" },{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        uf !== '0' && municipio === '0'  ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"estado","label":"ESTADO: ' + uf + '","value":"' + uf + '" },{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        :
                        '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"acaoInfluencia","label":"Ação de Influência: Pendente","value":"99"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtIni, "MM/dd/yyyy") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(dtFin, "MM/dd/yyyy") +
                        '"}]'
                        
                      },
                    ]}
                  />
                </Grid>
              ) : null}
            </Grid>
          </ContainerBody>
        </>
      </Main>
    </div>
  );
}