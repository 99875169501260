import { format } from "date-fns";
import * as Yup from "yup";

const ParlamentarDiscursosSchema = Yup.object({
    data: Yup.date().min(format(new Date(), 'yyyy-MM-dd')).required({ name: "data", err: "Escolha uma data válida" }),
    descricao: Yup.string().required({ name: "descricao", err: "Preencha o campo 'Descricao' " }),
    sumario: Yup.string().required({ name: "sumario", err: "Preencha corretamente o campo Sumário." }),
    transcricao: Yup.string().required({ name: "transcricao", err: "Preencha a transcricao." }),
    qualificacao: Yup.string().required({ name: "qualificacao", err: "Selecione uma qualificacao " }),
});
export default ParlamentarDiscursosSchema;
