import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import SelectTextField from "../../../components/SelectTextField";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import { IProposicao } from "../../../models/Proposicao.Model";
import { usePallet } from "../../../contexts/PalletContext";
import useStyles from "../../../components/layout/Styles";
import InsightService from "../../../services/InsightService";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/dateUtils";
import ErrorMessage from "../../../components/ErrorMessage";

export function AccordionContentKey23(props: {
  proposicao?: IProposicao;
  dtIni?: Date;
  dtFin?: Date;
  esfera?: string
  onChange?: () => void;
}) {
  const { pallet } = usePallet();
  const classes = useStyles();
  const [grupoItens, setGrupoItens] = React.useState([]);
  const [temaItens, setTemaItens] = React.useState([]);
  const [subTemaItens, setSubTemaItens] = React.useState([]);
  const [grupo, setGrupo] = React.useState<string>();
  const [tema, setTema] = React.useState<string>();
  const [subtema, setSubtema] = React.useState<string>(
    props?.proposicao?.cliente?.tema_corporativo
      ? String(props?.proposicao?.cliente?.tema_corporativo?.subtema_id)
      : undefined
  );
  const [listaGrupos, setListaGrupos] = React.useState([]);
  const [listaTemas, setListaTemas] = React.useState([]);
  const [listaSubtemas, setListaSubtemas] = React.useState([]);

  const [loading, setLoading] = useState(false);

  // Monta itens do select com o retorno da query
  const getItens = (
    data: { id: number; descricao: string }[]
  ): { value: string; label: string }[] => {
    const ret = data?.map((item) => {
      return {
        value: String(item.id),
        label: item.descricao,
      };
    });
    return ret;
  };

  React.useEffect(() => {
    TabelasService.getProposicaoGrupoTemas().then((res) => {
      const data = getItens(res);
      setGrupoItens(data);
    });


  }, []);

  const esferaConv = (value: any) => {
    switch (value) {
      case 'F':
        return 1;
      case 'E':
        return 2;
      case 'M':
        return 3;


    }
  }

  const Title = styled(Grid)`
    padding-left:  ${isMobile ? '5px' : '24px'};
    padding-top: ${isMobile ? '5px' : '30px'} ;
    background-color: ${pallet.backGroundPageColorPrimary};
    text-transform: uppercase;
    font-size:${pallet.general.size12};
    color: ${pallet.textColorQuaternary};
    font-family: ${pallet.general.fontFamily};
    font-weight: ${pallet.general.weight_600};
    place-content:  ${isMobile ? 'center' : 'unset'};
    text-align:  ${isMobile ? 'center' : 'unset'};
    height: 79px;
`;

  React.useEffect(() => {
    if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
      InsightService.GetKey23_24Grupo(
        format(new Date(props.dtIni), "yyyy-MM-dd"),
        format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), esferaConv(props.esfera)
      ).then((res) => {
        if (res.data) {
          setLoading(true)
          setListaGrupos(res.data.data)
        }
      });
    }
    if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
      InsightService.GetKey23_24Tema(
        format(new Date(props.dtIni), "yyyy-MM-dd"),
        format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), esferaConv(props.esfera)
      ).then((res) => {
        setListaTemas(res?.data?.data)
      });
    }
    if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
      InsightService.GetKey23_24Subtema(
        format(new Date(props.dtIni), "yyyy-MM-dd"),
        format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), esferaConv(props.esfera)
      ).then((res) => {
        setListaSubtemas(res?.data?.data)
      });
    }
  }, [props.dtIni, props.dtFin, props.esfera]);

  React.useEffect(() => {
    if (grupo) {
      TabelasService.getProposicaoTemasByGrupo(Number(grupo)).then((res) => {
        const data = getItens(res);
        setLoading(false)
        setTemaItens(data);
      });
    }
  }, [grupo]);

  React.useEffect(() => {
    if (tema) {
      TabelasService.getProposicaoSubTemasByTema(Number(tema)).then((res) => {
        const data = getItens(res);
        setSubTemaItens(data);
      });
    }
  }, [tema]);

  React.useEffect(() => {
    if (props?.proposicao?.cliente?.tema_corporativo?.tema_id) {
      TabelasService.getProposicaoTemas(
        props?.proposicao?.cliente?.tema_corporativo?.tema_id
      ).then((res) => {
        // if (res) {
        setGrupo(String(res[0].id_grupo));
        setTema(String(props?.proposicao?.cliente?.tema_corporativo?.tema_id));

        // }
      });
    }
  }, [props?.proposicao?.cliente?.tema_corporativo?.tema_id]);

  const onChangeGrupo = (value: string) => {
    if (value) {
      setGrupo(value);
    }
  };

  const onChangeTema = (value: string) => {
    if (value) setTema(value);
  };

  const onChangeSubtema = (value: string) => {
    if (value) {
      setSubtema(value);
    }
  };
  const navigate = useNavigate();

  return (
    <Grid>
      {listaGrupos.length > 0 ?

        <Grid
          style={{
            margin: `${isMobile ? "30px 10px 10px 10px" : "30px 30px 32px 30px"}`,
          }}
        >

          <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
            <Title container style={{
              borderTop: '1px solid #EAEAEA',
              borderLeft: '1px solid #EAEAEA',
              borderBottom: '1px solid #EAEAEA',
              borderRight: `${isMobile ? '1px solid #EAEAEA' : 0}`,
              borderRadius: '6px 0px 0px 0px'
            }}
              item lg={6} xs={6}>
              GRUPO
            </Title>
            <Title container item lg={6} xs={6}
              style={{
                borderTop: '1px solid #EAEAEA',
                borderRight: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRadius: '0px 6px 0px 0px'
              }}>
              PROPOSIÇÕES
            </Title>
            <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
              {listaGrupos?.map((x, i) => {
                return (
                  <>
                    <Grid lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "10px" : "15px"}`,
                      paddingBottom: `${isMobile ? "5px" : "5px"}`,
                      borderLeft: '1px solid #EAEAEA',
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== listaGrupos.length ? null : '0px 0px 0px 6px',
                      maxWidth: 'none',
                      wordBreak: 'break-word',
                      placeContent: `${isMobile ? 'center' : 'unset'}`,
                      textAlign: `${isMobile ? 'center' : 'unset'}`,

                    }}>
                      <Grid container item
                        onClick={() => navigate('/interlocutor/' + x.id_parlamentar)}
                        style={{
                          paddingBottom: `${isMobile ? "5px" : "5px"}`,
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size12}`,
                          fontWeight: pallet.general.weight_600,
                          cursor: "pointer",
                          textAlign: `${isMobile ? 'center' : 'unset'}`,
                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}
                      >

                        {x.descricao}

                      </Grid>
                    </Grid>
                    <Grid container item lg={6} xs={6}

                      style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "10px" : "15px"}`,

                        paddingBottom: `${isMobile ? "5px" : "5px"}`,
                        borderRight: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRadius: i + 1 !== listaGrupos.length ? null : '0px 0px 6px 0px',
                        color: `${pallet.textColorTertiary}`,
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size12}`,
                        fontWeight: pallet.general.weight_600,
                        textAlign: `${isMobile ? 'center' : 'unset'}`,
                        placeContent: `${isMobile ? 'center' : 'unset'}`,

                      }}>
                      <Grid onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(props.dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(props.dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(props.dtFin, "MM/dd/yyyy 23:59:00") +
                        '"},{"key":"grupos","label":"GRUPO: ' + x.descricao + '","value":"' + x.id + '"}]')}
                        style={{ cursor: "pointer", padding: "5px", inlineSize: "fit-content" }}>
                        {x.count}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>


          {listaTemas.length > 0 ?
            <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: `${isMobile ? '1px solid #EAEAEA' : 0}`,
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                TEMA
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES
              </Title>
              <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                {listaTemas?.map((x, i) => {
                  return (
                    <>
                      <Grid lg={6} xs={6} style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "10px" : "15px"}`,
                        paddingBottom: `${isMobile ? "5px" : "5px"}`,
                        borderLeft: '1px solid #EAEAEA',
                        borderRight: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRadius: i + 1 !== listaTemas.length ? null : '0px 0px 0px 6px',
                        maxWidth: 'none',
                        wordBreak: 'break-word',
                        textAlign: `${isMobile ? 'center' : 'unset'}`,
                        placeContent: `${isMobile ? 'center' : 'unset'}`


                      }}>
                        <Grid container item
                          onClick={() => navigate('/interlocutor/' + x.id_parlamentar)}
                          style={{
                            paddingBottom: `${isMobile ? "5px" : "5px"}`,
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size12}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            textAlign: `${isMobile ? 'center' : 'unset'}`,
                            placeContent: `${isMobile ? 'center' : 'unset'}`
                          }}
                        >
                          {x.descricao}

                        </Grid>
                      </Grid>
                      <Grid container item lg={6} xs={6}

                        style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "10px" : "15px"}`,
                          borderRight: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRadius: i + 1 !== listaTemas.length ? null : '0px 0px 6px 0px',
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size12}`,
                          fontWeight: pallet.general.weight_600,
                          textAlign: `${isMobile ? 'center' : 'unset'}`,
                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}>
                        <Grid onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "MM/dd/yyyy 23:59:00") +
                          '"},{"key":"temas","label":"TEMA: ' + x.descricao + '","value":"' + x.id + '"}]')}
                          style={{ cursor: "pointer", padding: "5px", inlineSize: "fit-content" }}
                        >
                          {x.count}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>

            :
            null
          }
          {listaSubtemas.length > 0 ?
            <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: `${isMobile ? '1px solid #EAEAEA' : 0}`,
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                SUBTEMA
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES
              </Title>
              <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                {listaSubtemas?.map((x, i) => {
                  return (
                    <>
                      <Grid lg={6} xs={6} style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "10px" : "15px"}`,

                        borderLeft: '1px solid #EAEAEA',
                        paddingBottom: `${isMobile ? "5px" : "5px"}`,
                        borderRight: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRadius: i + 1 !== listaSubtemas.length ? null : '0px 0px 0px 6px',
                        maxWidth: 'none',
                        wordBreak: 'break-word',
                        textAlign: `${isMobile ? 'center' : 'unset'}`,

                        placeContent: `${isMobile ? 'center' : 'unset'}`

                      }}>
                        <Grid container item
                          onClick={() => navigate('/interlocutor/' + x.id_parlamentar)}
                          style={{
                            paddingBottom: `${isMobile ? "5px" : "5px"}`,
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size12}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            textAlign: `${isMobile ? 'center' : 'unset'}`,

                            placeContent: `${isMobile ? 'center' : 'unset'}`
                          }}
                        >

                          {x.descricao}

                        </Grid>
                      </Grid>
                      <Grid container item lg={6} xs={6}

                        style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "10px" : "15px"}`,

                          paddingBottom: `${isMobile ? "5px" : "5px"}`,
                          borderRight: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRadius: i + 1 !== listaSubtemas.length ? null : '0px 0px 6px 0px',
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size12}`,
                          fontWeight: pallet.general.weight_600,
                          textAlign: `${isMobile ? 'center' : 'unset'}`,

                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}>
                        <Grid onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "MM/dd/yyyy 23:59:00") +
                          '"},{"key":"subtemas","label":"SUBTEMA: ' + x.descricao + '","value":"' + x.id_subtema + '"}]')}
                          style={{ cursor: "pointer", padding: "5px", inlineSize: "fit-content" }}>
                          {x.count}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>

            :
            null
          }
        </Grid>

        : loading ?
          <Grid>
            <ErrorMessage description="Não foram encontrados dados para este Insight." />
          </Grid>
          : null
      }
    </Grid>
  );
}

export default AccordionContentKey23;
