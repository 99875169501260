import React, { useState } from 'react';
import { Button, Divider, Grid, styled, Typography } from "@mui/material"
import { isMobile } from "react-device-detect"
import { usePallet } from "../../../../contexts/PalletContext"
import MenuButtonIconNoText from '../../../../components/MenuButtonIconNoText';
import { MoreHoriz } from '@material-ui/icons';
import useStyles from '../../../../components/layout/Styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu, { MenuProps } from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import ProposicaoAcaoPreparatoriaModalEdicao from "./ProposicaoAcaoPreparatoriaModalEdicao"
import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import ProposicaoAcaoPreparatoriaService from '../../../../services/ProposicaoAcaoPreparatoriaService';
import { useAlert } from '../../../../contexts/AlertContext';
import { Navigate } from 'react-router-dom';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 135,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '5px 0px',
            minWidth: 140,

        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
            },
        },
    },
}));


const MuiButton = styled(Button)

const AcaoConsultaSpanCustom = (props) => {
    const { pallet } = usePallet()

    return (
        <span style={{
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.smallButton}`,
            ...props?.style
        }}>
            {props?.children}
        </span>
    )
}

const AcaoConsultaTypography = (props) => {
    const { pallet } = usePallet()
    return (
        <Typography align="justify" style={{
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.titleCardParlamentar}`,
            color: `${pallet.color.colorBase.black}`,
            ...props?.style
        }}>
            {props?.children}
        </Typography>
    )
}

const AcaoSituacaoTypography = (props: { children: any, style?: any }) => {
    const { pallet } = usePallet()


    return (
        <Typography align="justify" style={{
            padding: "3px 9px",
            borderRadius: "13px",
            backgroundColor: `${pallet.color.colorBase.greyBackground}`,
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.text_10}`,
            color: `${pallet.color.colorBase.greyText}`,
            marginBottom: "15px",
            textTransform: "uppercase",
            ...props?.style
        }}>
            {props.children}
        </Typography>
    )
}
export const ProposicaoAcaoPreparatoriaEspecificaBotoes = (props: { id_proposicao?: number, acao_preparatoria?: IAcaoPreparatoria, onChange?: () => void, reload?: () => void }) => {
    const { pallet } = usePallet()
    const { NewAlert } = useAlert();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState<any>(false);
    const classes = useStyles();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined)
    };

    async function loadAP(id: number) {
        props.reload();
    }

    const deletarAP = async (id) => {
        await ProposicaoAcaoPreparatoriaService.Delete(id).then(res => {
            if (res) {
                NewAlert('success', 'Ação preparatória apagada com sucesso')
                navigate(`/proposicao/${props?.acao_preparatoria.id_proposicao}`)


                handleClose()
            } else {
                NewAlert('error', 'Erro ao apagar, verifique os campos e apague novamente');
            }
        })
    }
    const { user } = useAuthorization();
    return (

        <div style={{ paddingLeft: `${isMobile ? "20px" : "40px"}`, marginTop: "16px", paddingRight: `${isMobile ? "20px" : "40px"}` }}>

            <Grid style={{ display: "flex", width: "100%", alignItems: "center", verticalAlign: "center", justifyContent: "space-between" }} >

                <Grid>
                    <AcaoSituacaoTypography style={{ marginTop: "10px", marginLeft: "8px" }}>
                        {props?.acao_preparatoria?.status}
                    </AcaoSituacaoTypography>
                </Grid>

                {user.id_permissao !== 4 ?
                <Grid container
                    textAlign={"end"}
                    style={{ width: "15px", marginRight: "45px" }}
                >
                    <Grid
                        onClick={
                            (e) => handleClick(e, props?.acao_preparatoria?.id)
                        }
                        style={

                            {
                                cursor: "pointer",
                                fontSize: "24px",
                                height: "50px",
                                padding: "10px 13px 13px 13px",
                                borderRadius: "7px",
                                backgroundColor: `${pallet.color.secundary.background}`,
                            }}
                    >
                        <MoreHoriz fontSize='inherit' style={{ color: `${pallet.color.secundary.font}` }} />
                    </Grid>
                    {}
                    <StyledMenu
                        id={`mmenu-${props.acao_preparatoria?.id}`}
                        MenuListProps={{
                            'aria-labelledby': `button-${props.acao_preparatoria?.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open !== false && open === props.acao_preparatoria?.id}
                        onClose={handleClose}
                    >
                        <MenuItem disabled={props.acao_preparatoria?.status === "Concluída" ? true : false}>
                            <ProposicaoAcaoPreparatoriaModalEdicao
                                id={props.acao_preparatoria?.id}
                                acao_preparatoria={props?.acao_preparatoria}
                                reloadList={() => loadAP(props?.acao_preparatoria?.id)}
                                closeMenu={() => handleClose()}
                            // id_proposicao={props?.id_proposicao}
                            />
                        </MenuItem>
                        <Grid>
                            < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "2px" : "5px"}`, marginBottom: `${isMobile ? "2px" : "5px"}` }} />
                        </Grid>
                        <MenuItem
                            style={{
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: pallet.fontWeight.button,
                                fontSize: `${pallet.general.size14}`,
                                color: `${pallet.color.tertiary.font}`,
                                borderRadius: "6px",
                                padding: "7px",

                            }}>

                            <ButtonDialogConfirmation
                                styles={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.button,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    borderRadius: "6px 6px 0px 0px",
                                    textTransform: "capitalize"
                                }}
                                title={"CONFIRMAÇÃO"}
                                title_button={"Apagar"}
                                confirmation_button={"Apagar"}
                                content={"APAGAR AÇÃO PREPARATÓRIA?"}
                                message={"Esta ação não poderá ser desfeita."}
                                confirmationEvent={() => deletarAP(props.acao_preparatoria?.id)}

                            >
                                <DeleteIcon style={{
                                    color: `${pallet.color.tertiary.font}`,
                                    marginRight: "16px",
                                    fontFamily: `${pallet.fontFamily.general}`,
                                }} />

                            </ButtonDialogConfirmation>
                        </MenuItem>
                    </StyledMenu>
                </Grid >
                :
                null
            }
            </Grid>


        </div>

    )
}

export default ProposicaoAcaoPreparatoriaEspecificaBotoes;
