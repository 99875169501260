import PrivateRoute from '../../components/PrivateRoute';
import TemasCorporativosLista from '../temas_corporativos/_components/Grupo/TemasCorporativos.Lista';
import TemasCorporativosTemaLista from '../temas_corporativos/_components/Tema/TemasCorporativos.Tema.Lista';
import TCTemasCorporativosSubTemaLista from '../temas_corporativos/_components/SubTema/TCTemasCorporativos.SubTema.Lista';
import TCCadastroTemaCorporativo from '../temas_corporativos/_components/TCCadastroTemaCorporativo';
import Insights from '@mui/icons-material/Insights';
import CentralNotificacaoList from '../central_notificacao/CentralNotificacaoList';
import Relatorios from '../relatorios/Relatorios';
import ParlamentarConsulta from '../parlamentar/Parlamentar.Consulta';
import GerenciamentoUsuario from './Gerenciamento.Usuario';
import GerenciamentoDepartamento from './Gerenciamento.Departamento';




export function GerenciamentoUsuariosRoutes(): JSX.Element {   
    return (
        <>             
            <PrivateRoute path="/gerenciamentodeusuarios" element={<GerenciamentoUsuario />} />         
            <PrivateRoute path="/gerenciamentodeusuarios/unidade_de_negocio/:id" element={<GerenciamentoDepartamento />} />                  
        </>
    );
}

