import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Divider, Grid, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import { isMobile } from "react-device-detect";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import useStyles from "../../../../components/layout/Styles";
import SolidButton from "../../../../components/SolidButton";
import { useAlert } from "../../../../contexts/AlertContext";
import { usePallet } from "../../../../contexts/PalletContext";
import { EComentarioModulos, IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import { ILoggedUser } from "../../../../models/User.Model";
import ComentarioService from "../../../../services/ComentarioService";

export const AccordionComentario = (props: {
  acao: IAcaoPreparatoria;
  reloadLista?: () => void;
  listaUsuario: ILoggedUser[];
}) => {
  const { pallet } = usePallet();
  const classes = useStyles();
  const { NewAlert } = useAlert();

  const [comentarioNovo, setComentarioNovo] = useState("");
  const [idComentarioNovo, setIdComenatarioNovo] = useState(0);
  const [listaUsuarioFiltrado, setListaUsuarioFiltrado] =
    useState<ILoggedUser[]>();

  const [inputValue, setInputValue] = React.useState("");

  const salvarComentario = async () => {
    if (comentarioNovo) {
      const data = {
        //Descomentado porque não estava populando a coluna mencionado na tabela comentarios
        comentario: comentarioNovo,
        // comentario: comentarioNovo.replace(
        //   regex.mencoes,
        //   (...match) => `@@@${match[1]}@@@`
        // ),
        modulo: EComentarioModulos.AcaoPreparatoria,
        //id_referencia: String(props.acao.id)
      };
      await ComentarioService.Create(String(props.acao.id), data).then(
        (res) => {
          if (res) setIdComenatarioNovo(Number(res));
          NewAlert("success", "Comentário salvo com sucesso.");
          setComentarioNovo("");
          setTimeout(() => props?.reloadLista(), 1000);
        }
      );
    }
  };

  const handleChangeComentarioNovo = (e) => {
    if (e.target.value) setComentarioNovo(e.target.value);
  };

  function handleAfterUpload() {
    setComentarioNovo("");
    setIdComenatarioNovo(0);
  }

  const regex = {
    ultimaMencao: /(^| |&nbsp;)@(.[^ ]*)?$/g,
    mencaoAdicionada: /<b data-id="([0-9]+)">(.[^ ]*)<\/b>&nbsp;$/g,
    // mencoes: /<b data-id="([0-9]+)">(.[^ ]*)<\/b>/g,
    mencoes: /<b data-id="([0-9]+)">(.[^&nbsp;]*)<\/b>/g,
    limpar: /<b>(.[^ ]*)?<\/b>$/g,
    limparTags: /(<+(?!b|\/b)([^>]+)>)/ig
  };
  const keys = { enter: 13, space: 32, up: 38, down: 40, backspace: 8 };

  // escolherUsuario = escolherUsuario.bind(this)
  // listarUsuarios = listarUsuarios.bind(this)
  // onKeyDown = onKeyDown.bind(this)
  // onKeyUp = onKeyUp.bind(this)
  // onChange = onChange.bind(this)
  // onBlur = onBlur.bind(this)
  // salvar = salvar.bind(this)

  // state = {
  //   autocomplete: false,
  //   selecionado: 0,
  //   salvando: false,
  //   comentarioNovo: props.valor || ''
  // }

  const [autocomplete, setAutocomplete] = React.useState(false);
  const [selecionado, setSelecionado] = React.useState(0);
  const [salvando, setSalvando] = React.useState(false);
  const indexSelecionado = useRef(selecionado);

  useEffect(() => {
    indexSelecionado.current = selecionado;
  }, [selecionado]);

  useEffect(() => {
    setListaUsuarioFiltrado(props.listaUsuario);
  }, []);

  // componentDidUpdate(prevProps) {
  //   if (prevProps.valor !== props.valor) {
  //     setState({ comentarioNovo: props.valor })
  //     document.getElementById(props.id).focus()
  //   }
  // }

  const escolherUsuario = (usuario) => {

    //console.log("E taggggggggggggg ??", usuario, "----------", props.listaUsuario, "------", comentarioNovo)
    if (!usuario) return;

    setAutocomplete(false);
    setListaUsuarioFiltrado(props.listaUsuario);
    setSelecionado(0);
    setComentarioNovo(
      comentarioNovo.replace(
        regex.ultimaMencao,
        `$1<b data-id="${usuario.id}">${usuario.nome}</b>&nbsp;`
      )
    );
  };

  const listarUsuarios = (usuarios) => {
    if (usuarios) {
      let mencao = String(comentarioNovo.match(regex.ultimaMencao));
      mencao =
        mencao && mencao[0].replace(regex.ultimaMencao, "$2").toLowerCase();
      return mencao
        ? usuarios.filter(({ nome }) => nome.toLowerCase().match(mencao))
        : usuarios;
    }
    return [];
  };

  const handleOnKeyDown = (event) => {
    const listaDeUsuarios = listarUsuarios(listaUsuarioFiltrado);

    // if (event.keyCode === keys.enter) {
    //   event.preventDefault()
    //   return event.shiftKey ? salvar() : escolherUsuario(listaDeUsuarios[selecionado])
    // }

    if (event.keyCode === keys.space && listaDeUsuarios.length === 1) {
      event.preventDefault();
      return escolherUsuario(listaDeUsuarios[indexSelecionado.current]);
    }

    if (
      event.keyCode === keys.backspace &&
      comentarioNovo.match(regex.mencaoAdicionada)
    ) {
      event.preventDefault();
      return setComentarioNovo(
        comentarioNovo.replace(regex.mencaoAdicionada, "")
      );
    }
  };

  const handleOnKeyUp = (e) => {
    const keyCode = e.keyCode;
    const listaDeUsuarios = listarUsuarios(listaUsuarioFiltrado);
    const ehUltimo = indexSelecionado.current === listaDeUsuarios.length - 1;

    if (keyCode === keys.up) {
      return setSelecionado(
        indexSelecionado.current && indexSelecionado.current - 1
      );
    }
    if (keyCode === keys.down) {
      return setSelecionado(
        ehUltimo ? indexSelecionado.current : indexSelecionado.current + 1
      );
    }

    setSelecionado(0);
    setAutocomplete(!!e.target.textContent.match(regex.ultimaMencao));
    // if (!!e.target.textContent.match(regex.ultimaMencao)) {
    //   setListaUsuarioFiltrado(props.listaUsuario);
    // }
  };

  const handleOnChange = (event) => {
    let newValue = event.target.value.replace(/@@/g, "@").replace(regex.limparTags, "");

    if (newValue.match(regex.limpar))
      newValue = newValue.replace(regex.limpar, "$1");
    setComentarioNovo(newValue);

    if (autocomplete) {
      let ultimaMencao = String(newValue.match(regex.ultimaMencao))
        .replace("@", "")
        .trim();
      if (
        ultimaMencao !== "" &&
        ultimaMencao !== null &&
        ultimaMencao !== "null"
      ) {
        const novaLista = props.listaUsuario.filter(
          (i) => i.nome.indexOf(ultimaMencao.toUpperCase()) === 0
        );
        setListaUsuarioFiltrado(novaLista ? novaLista : props.listaUsuario);
      } else {
        setListaUsuarioFiltrado(props.listaUsuario);
      }
    }
  };

  const handleOnBlur = () => {
    setTimeout(() => setAutocomplete(false), 500);
  };

  // const  salvar = () => {

  //   //console.log('LINK >>>>>>>>>>>>>>>',window.location.pathname)

  //   // if (!comentarioNovo) return setState({ error: 'Campo obrigatório.' })

  //   setState({ salvando: true })
  //   try {
  //     await props.acao({
  //       ...props,
  //       comentario: comentarioNovo.replace(regex.mencoes, (...match) => `@@@${match[1]}@@@`),
  //       link: window.location.pathname
  //     })

  //     props.reload && props.reload()
  //     props.alertaStore.criar('Comentário enviado.')
  //     return setState({ error: null, comentarioNovo: '', salvando: false })
  //   } catch (error) {
  //     console.error(error)
  //     return setState({ error: (error && error.message) || 'Não foi possível comentar.', salvando: false })
  //   }
  // }

  return (
    <Grid
      style={{
        paddingLeft: `${isMobile ? "0px" : "25px"}`,
        paddingRight: `${isMobile ? "20px" : "25px"}`,
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Accordion
        elevation={0}
        style={{ border: "1px solid #EAEAEA", marginBottom: "20px" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon style={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: `${pallet.backgroundColorPrimary}`,
            height: "10px",
            borderRadius: "5px",
            paddingLeft: "20px",
            paddingRight: "20px",
            flexFlow: "row-reverse",
          }}
        >
          <Typography
            className={classes.heading}
            style={{
              color: "white",
              fontFamily: `${pallet.general.fontFamily}`,
              fontSize: `${pallet.general.size14}`,
              fontWeight: pallet.general.weight_600,
              marginLeft: "10px",
            }}
          >
            NOVO COMENTÁRIO
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ padding: "0px" }}
          style={{
            fontFamily: `${pallet.general.fontFamily}`,
          }}
        >
          <Grid
            container
            style={{
              paddingTop: "30px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Grid item lg={12} xs={12} style={{ position: "relative" }}>
              {props.listaUsuario && (
                <>
                  <span
                    style={{
                      position: "absolute",
                      fontSize: "12px",
                      left: "10px",
                      top: "-9.5px",
                      backgroundColor: "white",
                      color: "grey",
                      padding: "0px 5px",
                    }}
                  >
                    COMENTÁRIO
                  </span>
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "3px",
                      minHeight: "100px",
                    }}
                    className="MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-multiline"
                  >
                    <ContentEditable
                      style={{
                        width: "100%",
                        minHeight: "100px",
                        backgroundColor: " white !important",
                        margiLeft: "5px",
                        padding: "10px",
                        outline: "0px solid transparent",
                        zIndex: 1,
                      }}
                      id={"comentario"}
                      className="MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-multiline"
                      html={comentarioNovo}
                      onChange={handleOnChange}
                      onKeyDown={handleOnKeyDown}
                      onKeyUp={handleOnKeyUp}
                      onBlur={handleOnBlur}
                    />
                  </div>
                  <div
                    style={{
                      display: `${autocomplete ? "block" : "none"}`,
                      height: "100px",
                      overflow: "auto",
                      boxShadow: "3px 3px 3px 0px rgba(0, 0, 0, 0.2)",
                      backgroundColor: "white !important",
                      borderRadius: "0px 0px 3px 3px",
                    }}
                  >
                    <ul
                      className={`dropdown-menu`}
                      style={{
                        backgroundColor: "white !important",
                        border: "none",
                        marginLeft: "5px",
                        padding: "0px",
                      }}
                    >
                      {listarUsuarios(
                        listaUsuarioFiltrado
                          ? listaUsuarioFiltrado
                          : props.listaUsuario
                      ).map(({ id, nome }, i) => (
                        <li
                          className={`dropdown-item `} //
                          style={{
                            cursor: "pointer",
                            fontWeight: i === selecionado ? 700 : 300,
                          }}
                          onClick={() => escolherUsuario({ id, nome })}
                        >
                          {nome}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "50px" }}>
            <Divider variant="fullWidth" />
          </Grid>

          <Grid
            xs={12}
            lg={12}
            style={{
              marginBottom: `${isMobile ? "8px" : "0px"}`,
              marginTop: `${isMobile ? "8px" : "20px"}`,
              paddingRight: "20px",
              paddingLeft: "20px",
            }}
          >
            <Grid
              style={{
                fontWeight: pallet.fontWeight.subtitle,
                fontSize: `${pallet.general.size12}`,
                color: `${pallet.color.tertiary.font}`,
              }}
            ></Grid>
            <Grid className="container">
              <AnexosComponent
                label=""
                modulo={EAnexoModulos.Comentario}
                id_referencia={idComentarioNovo}
                onAfterUpload={handleAfterUpload}
                canEdit={true}
                autosave={true}
              />
            </Grid>
          </Grid>
          <Grid>
            <Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
          </Grid>
          <Grid container>
            <Grid
              item
              lg={12}
              xs={12}
              style={{
                backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                paddingRight: `${isMobile ? "20px" : "40px"}`,
                textAlignLast: "end",
                padding: `${isMobile ? "10px 20px 10px 20px" : "20px 20px 20px 20px"
                  }`,
              }}
            >
              <SolidButton
                onClick={salvarComentario}
                color={`${pallet.textColorPrimary}`}
                onChange={salvarComentario}
                backgroundColor={`${pallet.backgroundColorPrimary}`}
                fontSize={`${pallet.general.size14}`}
                title={"SALVAR"}
                disabled={false}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AccordionComentario;
