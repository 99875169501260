import React, { useEffect, useState } from "react";
import { Alert, Grid, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { usePallet } from "../../../../contexts/PalletContext";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import WarningIcon from "@mui/icons-material/Warning";
import GridAlerta from "../../../../components/GridAlerta";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import { formatDate } from "../../../../utils/dateUtils";

const ParlamentarConsultaSpanCustom = (props) => {
  const { pallet } = usePallet();
  return (
    <span
      style={{
        fontFamily: `${pallet.fontFamily.general}`,
        fontWeight: pallet.fontWeight.subtitle,
        fontSize: `${pallet.fontSize.smallButton}`,
        ...props?.style,
      }}
    >
      {props?.children}
    </span>
  );
};

const AcaoConsultaTypography = (props) => {
  const { pallet } = usePallet();
  return (
    <Typography
      align="justify"
      style={{
        fontFamily: `${pallet.fontFamily.general}`,
        fontWeight: pallet.fontWeight.subtitle,
        fontSize: `${pallet.fontSize.titleCardParlamentar}`,
        color: `${pallet.color.colorBase.black}`,
        ...props?.style,
      }}
    >
      {props?.children}
    </Typography>
  );
};

const ParlamentarApelidoConsultaTypography = (props) => {
  const { pallet } = usePallet();
  return (
    <Typography
      align="justify"
      style={{
        fontFamily: `${pallet.fontFamily.general}`,
        fontWeight: 500,
        fontSize: "15px",
        paddingTop: "2px",
        color: `${pallet.color.colorBase.black}`,
        ...props?.style,
      }}
    >
      {props?.children}
    </Typography>
  );
};

export const ProposicaoAcaoPreparatoriaEspecificaInicio = (props: {
  acao_preparatoria: IAcaoPreparatoria;
  reload?: () => void;
}) => {
  const { pallet } = usePallet();
  let count: number;

  const [statusAcao, setStatusAcao] = useState(undefined);

  useEffect(() => {
    if (props?.acao_preparatoria?.status) {
      setStatusAcao(props?.acao_preparatoria?.status);
    }
  }, [props?.acao_preparatoria?.status]);

  async function loadAP(id: number) {
    props.reload();
  }

  return (
    <Grid item>
      <div
        style={{
          paddingLeft: `${isMobile ? "20px" : "50px"}`,
          marginTop: "15px",
          paddingRight: `${isMobile ? "20px" : "50px"}`,
        }}
      >
        <Grid>
          <Grid item>
            <Grid container>
              <AcaoConsultaTypography>Ação Preparatória</AcaoConsultaTypography>
            </Grid>
            <Grid container>
              <Typography
                style={{
                  color: `${pallet.general.color1}`,
                }}
              >
                <Grid container>
                  <Grid item style={{ cursor: "pointer" }} onClick={() => window.open(`/proposicao/${props?.acao_preparatoria.id_proposicao}`, "_blank")}>
                    <ParlamentarConsultaSpanCustom >
                      {props?.acao_preparatoria?.proposicao}
                    </ParlamentarConsultaSpanCustom>
                  </Grid>
                  {props?.acao_preparatoria?.inteiro_teor
                    ?
                    <Grid item style={{ cursor: "pointer", marginLeft: '5px', fontSize: '13px', marginTop: '3px' }} onClick={() => window.open(`${props?.acao_preparatoria?.inteiro_teor.replace(
                      "http:",
                      "https:"
                    )}`, "_blank")}>
                      (Inteiro Teor)
                    </Grid>
                    :
                    null}
                </Grid>
                <span></span>
                <ParlamentarConsultaSpanCustom
                  style={{ textTransform: "uppercase" }}
                ></ParlamentarConsultaSpanCustom>
                <span></span>
                <ParlamentarConsultaSpanCustom
                  style={{ textTransform: "uppercase" }}
                ></ParlamentarConsultaSpanCustom>
              </Typography>
            </Grid>
            <Grid
              container
              style={{
                marginTop: "20px",
                fontWeight: pallet.general.weight_600,
              }}
            >
              <Typography style={{}}>
                <ParlamentarConsultaSpanCustom>
                  PRAZO
                </ParlamentarConsultaSpanCustom>
              </Typography>
            </Grid>
            <Grid container style={{ fontWeight: pallet.general.weight_600 }}>
              <Typography
                style={{
                  color: pallet.general.color1,
                }}
              >
                <ParlamentarConsultaSpanCustom>
                  <CalendarTodayIcon />
                </ParlamentarConsultaSpanCustom>
              </Typography>
              <Typography style={{ marginLeft: "5px" }}>
                <ParlamentarConsultaSpanCustom>
                  {props.acao_preparatoria?.prazo
                    ? formatDate(
                      new Date(props.acao_preparatoria.prazo),
                      "dd/MM/yyyy"
                    )
                    : ""}
                </ParlamentarConsultaSpanCustom>
              </Typography>
            </Grid>

            {statusAcao === "Em andamento" ? (
              <Grid style={{ marginTop: "20px" }} container>
                <GridAlerta
                  tipo="error"
                  descricao="ATENÇÃO! FIQUE ATENTO AO PRAZO. AINDA HÁ PENDÊNCIAS PARA ESTA AÇÃO PREPARATÓRIA."
                />
              </Grid>
            ) : (
              <Grid style={{ marginTop: "20px" }} container>
                <GridAlerta
                  tipo=""
                  descricao="TUDO CERTO! VOCÊ NÃO POSSUI PENDÊNCIAS PARA ESTA AÇÃO PREPARATÓRIA."
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default ProposicaoAcaoPreparatoriaEspecificaInicio;
