import PrivateRoute from '../../components/PrivateRoute';
import RelatorioInterlocutor from './_components/relatorio_parlamentar/RelatorioInterlocutor';
import ParlamentarCadastro from './Parlamentar.Cadastro';
import ParlamentarConsulta from './Parlamentar.Consulta';
import ParlamentarDashboard from './Parlamentar.Dashboard';
import ParlamentarLista from './Parlamentar.Lista';
import ParlamentarCarouselTabs from './_components/ParlamentarCarouselTabs';

export function ParlamentarRoutes(): JSX.Element {
    return (
        <>
            <PrivateRoute path="/interlocutor" element={<ParlamentarLista />} />
            <PrivateRoute path="/interlocutor/dashboard" element={<ParlamentarDashboard />} />
            <PrivateRoute path="/interlocutor/cadastro" element={<ParlamentarCadastro />} />
            <PrivateRoute path="/interlocutor/:idInter/cadastro" element={<ParlamentarCadastro />} />
            <PrivateRoute path="/interlocutor/:id" element={<ParlamentarConsulta />} />
            <PrivateRoute path="/interlocutor/:id/acontecimentos" element={<ParlamentarConsulta />} />
            {/* <PrivateRoute path="/interlocutor/relatorio/:id/:statusDemandaDados/:statusDemandaLista/:statusAcaoInfluenciaDados/:statusAcaoInfluenciaLista/:statusProposicaoDados/:statusProposicaoLista/:statusComissaoLista/:statusDiscursoDados/:statusDiscursoLista" element={<RelatorioInterlocutor />} /> */}
            <PrivateRoute path="/interlocutor/relatorio/:id/:statusDemandaDados/:statusDemandaLista/:statusAcaoInfluenciaDados/:statusAcaoInfluenciaLista/:statusProposicaoDados/:statusProposicaoLista/:statusComissaoLista/:statusDiscursoDados/:statusDiscursoLista/:statusOutrosFrentes/:statusOutrosEventos" element={<RelatorioInterlocutor />} />

        </>
    );
}