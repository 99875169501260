import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import useStyles from "../../../../components/layout/Styles";
import SelectTextField from "../../../../components/SelectTextField";
import { useAlert } from '../../../../contexts/AlertContext';
import { usePallet } from "../../../../contexts/PalletContext";
import { IProposicao } from "../../../../models/Proposicao.Model";
import ProposicaoService from "../../../../services/ProposicaoService";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import TabelasService from "../../../../services/TabelasService";
import ButtonAdd from "../../../../components/ButtonAdd";
import ButtonIconSalvar from "../../../../components/ButtonIconSalvar";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ProposicaoPosicionamentoUnidadeNegocioSelect } from "./ProposicaoPosicionamentoUnidadeNegocioSelect";
import CircularLoading from '../../../../components/CircularLoading';

const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
`;

export interface IListaProps {
  id_unidade_negocio: number,
  posicionamento: number,
  id: number

}



export const ProposicaoPosicionamentoUnidadeNegocio = (props: {
  proposicao: IProposicao;
  onChange: () => void;
  idPermissao?: number;
}) => {
  const { pallet } = usePallet();
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
 
  const [lista, setLista] = React.useState([])
  
  

  const adicionarUnidade = () => {



    lista.push({id_unidade_negocio:0,posicionamento: 0})
    
    setLista(() => [...lista])
    

}

useEffect(() => {
ProposicaoService.GetPosicionamentoUnidadeNegocio(props.proposicao.id)
.then(async res => {
    if (res) {
      if(res.data.data.length <= 0){

        setLista([{id_unidade_negocio:0,posicionamento: 0}])
      }
      else{

        setLista(res.data.data);
      }
    } 
});

}, [props.proposicao.id]);

  return (
    <Grid
      style={{
        paddingLeft: `${isMobile ? "10px" : "50px"}`,
        paddingRight: `${isMobile ? "10px" : "50px"}`,
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
            height: "10px",
            borderRadius: "5px",
            paddingLeft: `${isMobile ? "5px" : "20px"}`,
            paddingRight: `${isMobile ? "5px" : "20px"}`,
            flexFlow: "row-reverse",
          }}
        >
          <Typography
            className={classes.heading}
            style={{
              color: `${pallet.textColorTertiary}`,
              fontFamily: `${pallet.general.fontFamily}`,
              fontSize: `${pallet.general.size14}`,
              fontWeight: pallet.general.weight_600,
              marginLeft: "10px",
            }}
          >
            <Grid style={{ display: "flex" }}>POSICIONAMENTO POR UNIDADE DE NEGÓCIO</Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            fontFamily: `${pallet.general.fontFamily}`,
            paddingRight: "30px",
            paddingLeft: "30px"
          }}
        >
          <Grid container spacing={3} >
            <Grid style={{ marginTop: "30px" ,marginBottom: "25px",width: "100%", display:"flex",justifyContent:"flex-end" }}>
              <ButtonIconSalvar
                onClick={() => adicionarUnidade()}
                tipo={"cadastrar_usuario"}
                backgroundColor={pallet.backgroundColorPrimary}
                border={"1px solid lightgrey"}
                fontColor={pallet.textColorPrimary}
                fontSize={pallet.general.size12}
                icon={<AddCircleIcon />}
                title={"ADICIONAR POSICIONAMENTO"}
              />
            </Grid>
            
            {
                // loading ?
                // <CircularLoading
                //     positionCustom={true}
                //     size={100}
                //     top={isMobile ? "37%" : "50%"}
                //     left={isMobile ? "6%" : "45%"}
                //     zIndex={2}
                // />
                // :
                lista?.map((i) => {
                return (

                  <Grid container style={{marginBottom: "15px"}}>
                    <ProposicaoPosicionamentoUnidadeNegocioSelect proposicao={props.proposicao} id_unidade_negocio={i.id_unidade_negocio} posicionamento={i.posicionamento}  idPermissao={props.idPermissao} idPosicionamento={i.id}/>
                  </Grid>
                )

              })
            }
         </Grid>

        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
