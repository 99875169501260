import React from 'react';
import { useEffect } from 'react';
import { readStore } from '../helpers/persistence';
import { ILoggedUser } from '../models/User.Model';
import AuthorizationService from '../services/AuthorizationContextService'
import * as http from '../helpers/http';

interface IProps { children: React.ReactNode; }
interface IContextResult {
  user?: ILoggedUser,
  isAutenticated: () => boolean,
  loading: boolean,
  hasAccess: (rule: string) => boolean,
  login: (username: String, password: String) => Promise<ILoggedUser | undefined>,
  logout: () => void,
  getConfig: () => any
}

const Context = React.createContext<IContextResult>({
  user: undefined,
  isAutenticated: () => false,
  loading: false,
  hasAccess: (id: string) => false,
  login: (username: string, password: string) => Promise.resolve(undefined),
  logout: () => console.warn('no provider'),
  getConfig: undefined,
});

const AuthorizationProvider = ({ children }: IProps) => {
  const [user, setUser] = React.useState<ILoggedUser | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [supportKey, setSupportKey] = React.useState<boolean>(true);
  const [config, setConfig] = React.useState<any>(undefined);

  useEffect(() => {
    !config && AuthorizationService.config().then(r => {
      setConfig(r.data);
      }).catch(() => setLoading(false));

    if (!user && !loading) {
      const qryToken = window.location.search.match(/token=([^&]*)/);
      if (qryToken) {
        const token = qryToken[1];
        let stringTonken = `"${token}"`;
        localStorage.setItem("token", stringTonken);

        const qryRefreshToken = window.location.search.match(/refresh-token=([^&]*)/);
        if (qryRefreshToken) {
          const refreshToken = qryRefreshToken[1];
          let stringRefreshTonken = `"${refreshToken}"`;
          localStorage.setItem("refresh-token", stringRefreshTonken);
        }

        setLoading(true);
        AuthorizationService.me()
          .then(u => {
            setUser(u);
            setLoading(false)
          }).catch(() => setLoading(false));
      } else {
        readStore('token')
          .then(token => {
            if (token) {
              setLoading(true);
              AuthorizationService.me()
                .then(u => {
                  setUser(u);
                  setLoading(false)
                }).catch(() => setLoading(false))
            }
          });
      }
    }
  }, []);

  const logout = () => setUser(undefined);

  const login = (username: String, password: String): Promise<ILoggedUser | undefined> => {
    setLoading(true);
    return AuthorizationService
      .login(username, password)
      .then(u => {
        setLoading(false);
        setUser(u);
        return u;
      })
      .catch(error => {
        setLoading(false);
        throw error;
      });
  }

  const hasAccess = (rule: string) => user && user.roles && Array.isArray(user.roles) && user.roles.length > 0 ? user.roles.indexOf(rule) > 0 : true;
  const isAutenticated = () => !!(!!user && user.id > 0 && localStorage.getItem('token') && localStorage.getItem('token') !== '');
  const getConfig = () => config;

  return (
    <Context.Provider value={{ user, isAutenticated, loading, login, logout, hasAccess, getConfig }}>
      {children}
    </Context.Provider>
  );
}

export { AuthorizationProvider };
export const useAuthorization = () => React.useContext(Context);
