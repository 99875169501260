import { format } from "date-fns";
import * as Yup from "yup";

const UsuarioUpdatePassWordSchema = Yup.object({
    senhaatual: Yup.string().min(6,{ name: "senhaatual", err: "Campo deve ter ao menos 6 caracteres" }),
    senhanova: Yup.string().min(6,{ name: "senhanova", err: "Campo deve ter ao menos 6 caracteres" }),    
    senhanovaconf: Yup.string().min(6,{ name: "senhanovaconf", err: "Campo deve ter ao menos 6 caracteres" })    
});

export default UsuarioUpdatePassWordSchema;
