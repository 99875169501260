import React from 'react';
import styled from "styled-components";
import Tab from '@mui/material/Tab';
import { isMobile } from 'react-device-detect';
import { Grid, Tabs } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';

const a11yProps = (index: number) => { return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` } };

export interface ICarouselItens {
    index: number,
    hide: boolean,
    label: string,
    component: JSX.Element,
    date: String
}

const CustomTabs = styled(Tabs)`
    font-weight: 600;
    font-size: 14px;
    
    .MuiButtonBase-root {
        padding: 12px 16px;
    }
     button.Mui-selected {
        color: ${(props => props.color)};
    }
`;

export default function CarouselCustomAgenda(props: { itens: Array<ICarouselItens>, activeTab: number, onChange: (value: number) => void, loading: boolean }) {
    const { pallet } = usePallet();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => props.itens[newValue].hide || props.onChange(newValue);
    const TamanhoTela = window.screen.width;

    return (
        <Grid style={{ width: "100%" }} >
            <Grid style={{ borderBottom: 1 }}>
                <Grid container spacing={0} style={{ width: "100%", flexDirection: `${isMobile ? "row" : "column"}` }}>
                    <CustomTabs
                        variant={TamanhoTela < 600 ? `scrollable` : null}
                        scrollButtons={true}
                        value={props.activeTab}
                        onChange={handleChange}
                        aria-label="scrollTabs"
                        TabIndicatorProps={{
                            style: {
                                background: `${pallet.color.primary.background}`
                            }
                        }}
                        color={pallet.textColorQuaternary}
                        centered={TamanhoTela > 600 ? true : false}
                    >
                        {/* Itens do carrocel */}
                        {props.itens.map(item => {
                            return (
                                    <Tab
                                        key={item.index}
                                        style={{
                                            display:`${item.hide ? 'none' : 'block'}`, 
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: 700,
                                            fontSize: '16px',
                                        }}
                                        label={
                                            <React.Fragment>
                                              {item.date}<br />
                                              <span style={{ fontSize: "smaller" }}>{item.label}</span>
                                            </React.Fragment>
                                          }
                                        {...a11yProps(item.index)}
                                    />
                            )
                        })}
                    </CustomTabs>
                </Grid>
            </Grid>
            {/* Componentes */}
            {props.itens.map(item => { return (item.component) })}
        </Grid>
    );
}