import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import TabPanel from "../../../components/carousel/TabPanel";
import { usePallet } from "../../../contexts/PalletContext";
import { IParlamentar } from "../../../models/Parlamentar.Model";
import ParlamentarProposicoesDados from "./ParlamentarProposicoesDados";
import ParlamentarProposicoesProposicao from "./ParlamentarProposicoesProposicao";

const ParlamentarCarouselTabsProposicoes = (props: { id_parlamentar: number, activeTab: any, index: number, parlamentar?: IParlamentar, permissaoUsuario?: number }) => {
    const { pallet } = usePallet();
    const [changeDadosOuProp, setChangeDadosOuProp] = React.useState("dados")

    const trocaParaDadosOuProposicao = (key: string) => {
        setChangeDadosOuProp(key)
    }

    return (
        <Paper style={{ borderRadius: "6px", marginTop: `${isMobile  ? "0px" : props.permissaoUsuario !== 4 ? "-30px" : "-3px"}` }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <Grid container style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                    <Grid alignItems="flex-start" style={{ marginRight: "20px" }}>
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuProposicao}
                            tipo={"dados"}
                            backgroundColor={changeDadosOuProp === "dados" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuProp === "proposicao" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuProp === "proposicao" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"DADOS"}
                        />
                    </Grid>
                    <Grid alignItems="flex-start">
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuProposicao}
                            tipo={"proposicao"}
                            backgroundColor={changeDadosOuProp === "proposicao" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuProp === "dados" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuProp === "dados" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"PROPOSIÇÕES"}
                        />
                    </Grid>
                </Grid>
                {
                    changeDadosOuProp === "dados" ?
                        <ParlamentarProposicoesDados id_parlamentar={props.id_parlamentar} parlamentar={props?.parlamentar} permissaoUsuario={props.permissaoUsuario} /> :
                        <ParlamentarProposicoesProposicao id_parlamentar={props.id_parlamentar} permissaoUsuario={props.permissaoUsuario} />
                }
            </TabPanel>
        </Paper>
    )
}

export default ParlamentarCarouselTabsProposicoes;