import { isMobile } from 'react-device-detect';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import CircularLoading from '../components/CircularLoading';
import { useAuthorization } from '../contexts/AuthorizationContext';
import { AgendaRoutes } from '../pages/agenda/_Agenda.Routes';
import { CentralNotificacaoRoutes } from '../pages/central_notificacao/_CentralNotificacao.Routes';
import { CitacoesRoutes } from '../pages/citacoes/_Citacoes.Routes';
import { DashboardRoutes } from '../pages/dashboard/_Dashboard.Routes';
import { InsightsRoutes } from '../pages/insights/_Insights.Routes';
import { InteligenciaArtificialRoutes } from '../pages/inteligencia_artificial/_InteligenciaArtificial.Routes';
import SignIn from '../pages/login/SignIn';
import { ParlamentarRoutes } from '../pages/parlamentar/_Parlamentar.Routes';
import { ProposicaoRoutes } from '../pages/proposicao/_Proposicao.Routes';
import { RelatoriosRoutes } from '../pages/relatorios/_Relatorios.Routes';
import { TemaCorporativoRoutes } from '../pages/temas_corporativos/_TemaCorporativo.Routes';
import { WorkSpaceRoutes } from '../pages/workspace/_Workspace.Routes';
import { BuscaGeralRoutes } from '../pages/busca_geral/_buscaGeral.Routes';
import { GerenciamentoUsuariosRoutes } from '../pages/gerenciamento_usuarios/_GerenciamentoUsuarios.Routes';
import { FavoritosRoutes } from '../pages/favoritos/_Favoritos.Routes';
import PrivateRoute from '../components/PrivateRoute';
import { useEffect } from 'react';
import Main from '../pages/main/Main';

const Rotas: React.FC = (): JSX.Element => {
    const { isAutenticated, loading } = useAuthorization();
    if (loading) return (
        <>
            {
                isMobile ?
                    <CircularLoading
                        size={80}
                        top={"50%"}
                        left={"25%"}
                        zIndex={2}
                    />
                    :
                    <CircularLoading
                        size={80}
                        top={"50%"}
                        left={"50%"}
                        zIndex={2}
                    />
            }
        </>
    )
    else if (!isAutenticated()) return <SignIn />
    else return (
        <>
           
            <Routes>
                {GeneralRoutes()}
                {DashboardRoutes()}
                {AgendaRoutes()}
                {WorkSpaceRoutes()}
                {ProposicaoRoutes()}
                {ParlamentarRoutes()}
                {CentralNotificacaoRoutes()}
                {InteligenciaArtificialRoutes()}
                {TemaCorporativoRoutes()}
                {BuscaGeralRoutes()}
                {/* {MidiaRoutes()} */}
                {InsightsRoutes()}
                {CitacoesRoutes()}
                {RelatoriosRoutes()}
                {GerenciamentoUsuariosRoutes()}
                {FavoritosRoutes()}
                {/* <Route path="/cadastro" element={<SignUp />} /> */}
                {/* <Route path="/login" element={<SignIn />} /> */}
            </Routes >
       
        </>
    );
}

function GeneralRoutes(): JSX.Element {
    return (
        <>
            <PrivateRoute path="/redirect" element={<Redirect />} />
            <PrivateRoute path="/dou/:id" element={<Dou />} />
        </>
    );
}

export function Redirect(url = null) {
    const navigate = useNavigate();
    const { state } = useLocation();
    useEffect(() => {
        navigate(state?.['rota'], {state: state?.['state']});
     }, []);
    return null;
}

export function Dou() {
    const ul = useLocation();
    window.location.href = process.env.REACT_APP_API_URL + ul.pathname;
    return null;
}

export default Rotas;
