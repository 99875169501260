import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, TextField, Typography, Button } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import styled from "styled-components";
import { formatDate } from '../../../utils/dateUtils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DeadLineDate from '../../../components/DeadLineDate';
import AnexosComponent, { EAnexoModulos } from '../../../components/Anexos';
import SelectTextFieldA from '../../../components/SelectTextFieldA';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ParlamentarDemandaService from '../../../services/ParlamentarDemandaService';
import UsuarioService from '../../../services/UsuarioService'
import { useAlert } from '../../../contexts/AlertContext';
import InputMultilineTextField from '../../../components/InputMultilineTextField';
import SolidButton from '../../../components/SolidButton';
import { ICheckBoxNestedItem, InputDropDownCheckBoxNested } from '../../../components/CheckBoxNested';
import { arraysEquals } from '../../../utils/array.utils';
import { useNavigate } from 'react-router';
import ParlamentarDemandasSchema from '../models/ParlamentarDemandasSchema';
import EmptyErrorDoc from '../../../components/EmptyErrorDoc';
import { IListInfo } from "../../../models/IListInfo";
import ParlamentarDiscursoService from '../../../services/ParlamentarDiscursoService';
import ParlamentarDiscursosSchema from '../models/ParlamentarDiscursosSchema';

export interface IFileInfo {
    name: string,
    filename: string,
    size: number,
    new: boolean,
    file: any
}

function AccordionDiscursoNovo(props: {
    index?: number,
    activeTab?: number,
    id_parlamentar?: number,
    onChange?: () => void,
    lista: IListInfo,
    onClick?: () => void,
    idPermissao?: number,
}): JSX.Element {

    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    const navigate = useNavigate();

    const [idDiscurso, setIdDiscurso] = useState(0);
    const [expandedAcordion, setExpandedAcordion] = useState(false);  
    const [dataDiscurso, setDataDiscuro] = useState(Date);
    const [prioridade, setPrioridade] = useState('');
    const [assunto, setAssunto] = useState('');
    const [sumario, setSumario] = useState('');
    const [qualificacao, setQualificacao] = useState('');
    const [descricao, setDescricao] = useState('');
    const [transcricao, setTranscricao] = useState('');
    const [objetivo, setObjetivo] = useState('');

    // const [lockSaveButton, setLockSaveButton] = useState<boolean>(true)
    const [departamento, setDepartamento] = useState<Array<ICheckBoxNestedItem>>();
    const [userList, setUserList] = useState<Array<number>>([]);
    const [error, setError] = useState<Array<{ name: string, err: string }>>([])
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)



    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);

        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    // React.useEffect(() => {
    //     UsuarioService.GetByDpto().then(async res => {

    //         //console.log("resposta getByDptooooooooooooooo........",res)
    //         const comboItens = res.map(departamento => {
    //             return {
    //                 id: departamento.id,
    //                 label: departamento.nome,
    //                 items: departamento.usuarios.map(usuario => {
    //                     return {
    //                         id: usuario.id,
    //                         label: usuario.nome,
    //                         value: false
    //                     }
    //                 })
    //             }
    //         })
    //         setDepartamento(comboItens)
    //     },
    //     )
    // }, []);



    // const handleAssuntoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setDescricao(event.target.value)
    // };



    const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value)
    };
    const handleTranscricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTranscricao(event.target.value)
    };

    const handleDataDiscuroChange = (prazoData) => {
        setDataDiscuro(prazoData)
    };

    const handleSumarioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSumario(event.target.value)
    };

    const handleQualificacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQualificacao(event.target.value)
    };

    const handleChangeAcordion =
    (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {      
      setExpandedAcordion(!ativo);      
    };

    function handleAfterUpload() {
        setIdDiscurso(undefined);
        setDataDiscuro(Date)
        setDescricao("")
        setSumario("")
        setTranscricao("")
        setQualificacao("")
        NewAlert('success', 'Discurso criado com sucesso')
        props.onClick()
    }

    // useEffect(() => {
    //     SaveValidation();

    // }, [prazo, assunto, prioridade, userList, objetivo])

    // function SaveValidation() {
    //     if (prazo && assunto && prioridade && (userList.length > 0) && objetivo) {
    //         setLockSaveButton(false)

    //     } else setLockSaveButton(true)
    // }

    const salvarNovoDiscurso = async () => {
        
        const data = {
            data: dataDiscurso ? new Date(dataDiscurso) : null,
            descricao: descricao,
            sumario: sumario,
            transcricao: transcricao,
            qualificacao: qualificacao,
        }
        setError(() => [])
        data && ParlamentarDiscursosSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        ParlamentarDiscursosSchema.validate(data).catch(function (err) {
        });

        if (ParlamentarDiscursosSchema.isValidSync(data)) {
            await ParlamentarDiscursoService.Create(data, props?.id_parlamentar).then(res => {
                if (res && res > 0) {
                    setIdDiscurso(res)
                    NewAlert('success', 'Discurso criada com sucesso')
                    setLoading(true)
                    setLoadingForm(true)
                    setTimeout(() => {
                        setLoadingForm(false)
                        setLoading(false)
                    }, 200);
                } else {
                    NewAlert('error', 'Verifique os campos e tente outra vez');
                    setTimeout(() => {
                        setLoadingForm(false)
                        setLoading(false)
                    }, 100);

                }
            })
                .then(data => {
                    props?.onChange()
                    handleAfterUpload()
                })
        } else {
            setTimeout(() => {
                setLoadingForm(false)
                setLoading(false)
            }, 100);
        }
    }



    const DotPrioridade = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 25px;
`;

    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: 64px;
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
`;
    const corPrioridade = (value: string) => {

        switch (value) {
            case 'Máxima':
                return `${pallet.charts.red}`;
            case 'Baixa':
                return `${pallet.charts.blue}`;
            case 'Média':
                return `${pallet.charts.yellow}`;
            case 'Alta':
                return `${pallet.charts.purple}`;
            case 'Mínima':
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }
    function LabelHandle(props: { prioridade: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPrioridade bg={corPrioridade(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.prioridade}
                    </Grid>
                </Grid>
            </>
        )
    }

    const parlamentarQualificacoes = [
        {
            value: 'convergente',
            label: 'Convergente',
        },
        {
            value: 'divergente',
            label: 'Divergente',
        },
        {
            value: 'neutro',
            label: 'Neutro',
        },
        {
            value: 'não classificado',
            label: 'Não Classificado',
        },
    ];


    const permissaoErro = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil.")

    }

    //console.log("TESTE",expandedAcordion)
    //console.log("LISTA",props.lista?.totalItens)

    return (
        <>
            <Accordion disabled={false} elevation={0} style=
                {{
                    border: "1px solid #EAEAEA",

                }}
                onChange={handleChangeAcordion(expandedAcordion)}>
                <AccordionSum
                    expandIcon={<ArrowDropDownIcon
                        style={{
                            color: `${pallet.general.color3}`,
                            fontSize: `${pallet.general.size16}`,

                        }}
                    />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: `${pallet.color.primary.background}`,
                        borderRadius: "5px",

                    }}
                >
                    <Typography className={classes.heading} style={{
                        color: `${pallet.color.primary.font}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontWeight: pallet.fontWeight.subtitle,
                        alignItems: "center",
                        letterSpacing: "1px",
                        marginLeft: "10px",
                    }}>
                        NOVO DISCURSO
                    </Typography>
                </AccordionSum>
                <AccordionDetails style={{
                    fontFamily: `${pallet.fontFamily.general}`
                }}>

                    <Grid >
                        <Grid container xl={12} lg={12} xs={12} style={{ paddingTop: "20px" }}>
                            <Grid container spacing={3}
                                style={{ paddingLeft: `${isMobile ? "0px" : "24px"}`, paddingRight: `${isMobile ? "0px" : "24px"}` }}
                            >
                                <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }} >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}
                                        style={{
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.general.weight_600,
                                            color: `${pallet.general.color1}`,
                                        }}>
                                        <DeadLineDate
                                            onChange={handleDataDiscuroChange}
                                            minDate={new Date}
                                            label={"DATA"}
                                            inputFormat={"dd/MM/yyyy"}
                                            value={dataDiscurso}
                                            id={"data-discuro"}
                                        />
                                    </LocalizationProvider>
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='data' errors={error} />
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }}>
                                    <InputMultilineTextField
                                        type={"text"}
                                        maxRows={10}
                                        value={sumario}
                                        defaultValue={sumario}
                                        onChange={handleSumarioChange}
                                        id={"sumario-discurso"}
                                        label="SUMÁRIO"
                                    />
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='sumario' errors={error} />
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }}>
                                    <InputMultilineTextField
                                        type={"text"}
                                        maxRows={10}
                                        value={descricao}
                                        defaultValue={descricao}
                                        onChange={handleDescricaoChange}
                                        id={"descricao-discurso"}
                                        label="DESCRIÇÃO"
                                    />
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='descricao' errors={error} />
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }}>
                                    <InputMultilineTextField
                                        type={"text"}
                                        maxRows={10}
                                        value={transcricao}
                                        defaultValue={transcricao}
                                        onChange={handleTranscricaoChange}
                                        id={"transcricao-discurso"}
                                        label="TRANSCRIÇÃO"
                                    />
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='transcricao' errors={error} />
                                    </Grid>
                                </Grid>

                                <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }}>

                                    <SelectTextFieldA
                                        value={qualificacao}
                                        onChange={handleQualificacaoChange}
                                        options={parlamentarQualificacoes}
                                        label={"QUALIFICAÇÃO"}
                                        id={"prioridade-demandas"}
                                    />
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='qualificacao' errors={error} />
                                    </Grid>

                                </Grid>


               

                                <Grid xl={2} lg={2} xs={12} container style={{
                                    paddingLeft: `${isMobile ? "25px" : "30px"}`
                                }}
                                    justifyContent="flex-start"
                                    alignItems="center">
                                </Grid>
                            </Grid>
                        </Grid>
        

                        <Grid style={{
                            paddingTop: `${isMobile ? "15px" : "40px"}`,
                            paddingLeft: `${isMobile ? "0px" : "24px"}`,
                            paddingRight: `${isMobile ? "0px" : "24px"}`,
                        }}>
                            <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                <Grid style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`,

                                }}>
                                    ANEXOS
                                </Grid>
                                <Grid style={{ marginTop: "10px" }}>
                                    <Grid className="container">

                                        <AnexosComponent
                                            label={''}
                                            modulo={EAnexoModulos.Discurso}
                                            id_referencia={idDiscurso}
                                            onAfterUpload={handleAfterUpload}
                                            canEdit={true}
                                            autosave={true}     
                                            msgResp={false}                                     
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                
                <Grid>
                    < Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                </Grid>
                <Grid container alignItems="center" sx={{
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    height: `${isMobile ? "50px" : "98px"}`,
                    borderRadius: "5px"
                }}>
                    <Grid container item>

                        <Grid item lg={12} xs={12}
                            style=
                            {{
                                paddingRight: `${isMobile ? "0px" : "40px"}`,
                                textAlignLast: "end",
                                padding: `${isMobile ? "0px" : "10px 20px 10px 20px"}`,
                            }}>
                            <SolidButton
                                onClick={props.idPermissao !== 3 ? salvarNovoDiscurso :  permissaoErro}
                                // disabled={lockSaveButton}
                                // onChange={props?.reloadList}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={"SALVAR"}
                            // ativo={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Accordion >
                {
                    !expandedAcordion &&  props.lista?.totalItens <= 0 ?

                    <Grid
                    style={{
                        color: `${pallet.textColorQuaternary}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontWeight: 600,
                        textAlign: "center",
                        paddingBottom: "100px",
                        marginTop: "20px",
                    }}
                >
                    <EmptyErrorDoc title="Comece criando um novo discurso."
                        description="Você ainda não criou nenhum discurso para este parlamentar."
                        subdescription=" Clique no botão abaixo para registrar." />
                </Grid>


                    :


                    ""


                }

< Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "40px"}`,marginBottom: `${isMobile ? "15px" : "40px"}` }} />

        </>
    );
}
export default AccordionDiscursoNovo;
