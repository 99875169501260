import React from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import { Grid, Button, Icon, Menu, FormControl, Divider } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import useStyles from './layout/Styles';
import { usePallet } from '../contexts/PalletContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isMobile } from 'react-device-detect';


export default function FiltroRelatoriaAutoria(props: { title?: string, icon?: any, onChange?: (value) => void }) {
    const [selectedValue, setSelectedValue] = React.useState('T');
    const { pallet } = usePallet();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const Relatoria = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}` }}>
                Relatoria
            </div>
        )
    }
    const Autoria = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}` }}>
                Autoria
            </div>
        )
    }


    return (
        <Grid>

            <FormControl component="fieldset" onChange={props.onChange} >
                <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue='R'
                    name="radio-buttons-group"
                >
                    <FormControlLabel value='R' control={<Radio style={{ color: `${pallet.backgroundColorPrimary}` }} />} label={<Relatoria />} />
                    <FormControlLabel value='A' control={<Radio style={{ color: `${pallet.backgroundColorPrimary}` }} />} label={<Autoria />} />

                </RadioGroup>
            </FormControl>

        </Grid >


    );
}