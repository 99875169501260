import Paper from "@mui/material/Paper";

import { isMobile } from "react-device-detect";

import TabPanel from "../../../../components/carousel/TabPanel";

import { usePallet } from "../../../../contexts/PalletContext";

import ProposicaoTramitacoes from "../tramitacao/ProposicaoTramitacoes";


const ProposicaoCarouselTabsTramitacao = (props: { id_proposicao: number, activeTab: any, index: number, proposicao_integrada?: boolean, onChange?: any, propCasa?: number }) => {
  const { pallet } = usePallet();

  return (
    <TabPanel value={props.activeTab} index={props.index}>
      <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "30px"}` }}>
        <ProposicaoTramitacoes id_proposicao={props.id_proposicao} index={props.index} activeTab={props.activeTab} proposicao_integrada={props.proposicao_integrada} onChange={props?.onChange} propCasa={props.propCasa} />
      </Paper>
    </TabPanel>
  )
};

export default ProposicaoCarouselTabsTramitacao;
