import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import AccordionTextDataOutrosParlamentarFrente from "./AccordionTextDataOutrosParlamentarFrente";
import PaginationSaveState from "../../../components/PaginationSaveState";
import { IListInfo } from "../../../models/IListInfo";
import { Visibility } from "@material-ui/icons";
import ParlamentarOutrosService from "../../../services/ParlamentarOutrosService";

export default function ParlamentarOutrosFrentes(props: { id_parlamentar: number }) {
    const { pallet } = usePallet();
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [currentPage])

    const getList = useCallback(() => {
        if (currentPage && currentPage > 0)
            
            ParlamentarOutrosService.GetFrentes(props.id_parlamentar, currentPage, 8).then(async res => {
                if (res && res.data) {
                    setLista(res.data);
                    setInfoList({
                        pageSize: res.pageSize,
                        pageIndex: res.pageIndex,
                        pageItens: res.pageItens,
                        totalItens: res.totalItens,
                        totalPages: res.totalPages
                    });
                }
            })
    }, [props.id_parlamentar, currentPage])

    const setPage = (p: number) => setCurrentPage(p);

    return (

        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                   
                    <Grid style={{ paddingLeft: `${isMobile ? "20px" : "20px"}`, paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: "20px", paddingBottom: "20px" }}>
                        <AccordionTextDataOutrosParlamentarFrente lista={lista} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                    <PaginationSaveState
                        name={'ParlamentarDiscursoList'}
                        pages={infoList?.totalPages}
                        page={currentPage}
                        onChange={setPage}
                    />
                </Grid>
            </Grid>
        </div>
    )
}