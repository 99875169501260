import * as http from '../helpers/http';

class RelatoriosSevice {

    
    //////////////////////////
    // Proposicao

    GetProposicoesPorCasaLegislativa = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/casas/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesPorNivelPrioridade = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {        
        const result = await http.get(`/relatorio/proposicao/prioridades/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) {            
            return result
        }
        return undefined;
    };

    GetProposicoesPeloPosicionamento = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/posicionamento/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesPorAtivoInativo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/atividade/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesAcaoInfluenciaResultado = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/acaoinfluencia/resultado/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesAcaoInfluenciaResultadoFulano = async (dtIni: string, dtFin: string, idresp: number, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/acaoinfluencia/resultado/fulano/${dtIni}/${dtFin}/${idresp}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesAcaoInfluenciaDepartamento = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/acaoinfluencia/departamento/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesAcaoPeriodo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/acao/periodo/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesTemaLegislativo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/temalegislativo/periodo/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesManifestAcaoPrepDep = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/acaopreparatoria/manifestacao/departamento/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesDivididaCasaTemaLegislativo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/dividida/tema/legislativo/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesApresentadasPeriodo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/apresentadas/periodo/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesMovimentacaoPosicionamento = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/movimentacao/posicionamento/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetProposicoesDivididaTemaCorporativo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/dividida/tema/corporativo/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    // Estadual

    GetProposicoesPorAssembleiaEstadual = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/casas/estadual/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

     // Municipal

     GetProposicoesPorAssembleiaMunicipal = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/proposicao/casas/municipal/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };


    //////////////////////////
    // Interlocutor

    GetInterlocutorQualificacaoPeriodo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/interlocutor/qualificacao/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetInterlocutorQualificacaoPartidoPeriodo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/interlocutor/partido/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetInterlocutorProfissaoPeriodo = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/interlocutor/profissao/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetInterlocutorPorCasaLegislativa = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/interlocutor/casas/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };
    
    GetInterlocutorPorOrgaoTecnico  = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/interlocutor/orgaotecnico/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    GetInterlocutorAcaoInfluencia = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/interlocutor/acaoinfluencia/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

     // Estadual

     GetInterlocutorPorAssembleiaEstadual = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/interlocutor/casas/estadual/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

    // Estadual

    GetInterlocutorPorAssembleiaMunicipal = async (dtIni: string, dtFin: string, filter: string): Promise<any> => {
        
        const result = await http.get(`/relatorio/interlocutor/casas/municipal/${dtIni}/${dtFin}?filter=${filter}`);
        if (result.success) { return result }
        return undefined;
    };

}

export default new RelatoriosSevice;