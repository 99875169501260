import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, TextField, Typography } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobile, isWindows } from 'react-device-detect';
import styled from "styled-components";
import { formatDate } from '../../../utils/dateUtils';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccordionTextDataAcaoInfluenciaParlamentarDetail from './AccordionTextDataAcaoInfluenciaParlamentarDetail';

const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 6px 4px 6px;
    /* margin-bottom: 7px; */
    line-height: 15px;
    letter-spacing: 1px;
`;

const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 5px;
    margin-top: 3px;
`;

// const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export interface IAcaoInfluenciaParlamentar {
    lista: [
        {
            acoes: [
                {
                    ambito: {
                        descricao: string,
                        id: number,
                        sigla: string
                    },
                    anexos: [],
                    casa: {
                        id: number,
                        nome: string
                    },
                    descricao: string,
                    id: number,
                    prazo: Date,
                    proposicao: {
                        descricao: string,
                        id: number
                    },
                    responsavel: {
                        id: number,
                        nome: string
                    },
                    resultado: {
                        descricao: string,
                        id: number
                    },
                    cliente: {
                        apelido: string,
                        posicionamento: string,
                        prioridade: string
                    }
                }
            ],
            id_proposicao: number,
            proposicao: string

        }
    ]

}

function AccordionTextDataAcaoInfluenciaParlamentar(props: { lista?: Array<any> }): JSX.Element {

    const { pallet } = usePallet();
    const classes = useStyles();

    const prioridadeColor = (value: string) => {
        switch (value) {
            case 'Máxima':
                return `${pallet.charts.red}`;
            case 'Baixa':
                return `${pallet.charts.blue}`;
            case 'Média':
                return `${pallet.charts.yellow}`;
            case 'Alta':
                return `${pallet.charts.purple}`;
            case 'Mínima':
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }

    const posicionamentoColor = (value: string) => {
        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    }


    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: 68px;
    flex-flow: row-reverse;
    background-color: ${pallet.backgroundColorTertiary};

    .MuiAccordionSummary-content {
        flex-direction: column;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
`;

    //const totativas = props.lista.acoes?.filter(valor => valor.resultado.id == 3).length;
    return (
        <>
            {props.lista?.map(item => {
                return (


                    <Grid key={0} style={{ marginBottom: "20px" }}>

                        <Accordion defaultExpanded={true} elevation={0} style={{ border: "1px solid #EAEAEA" }} >
                            {/* <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    // backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                    height: "10px",
                                    borderRadius: "5px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}
                            > */}
                            <AccordionSum style={{
                                paddingLeft: `${isMobile ? "5px" : "20px"}`,
                                paddingRight: "10px"
                            }}
                                expandIcon={<ArrowDropDownIcon
                                    style={{
                                        color: `${pallet.general.color2}`,
                                        fontSize: `${pallet.general.size16}`                                    
                                    }}
                                />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container style={{ marginLeft: "10px" }} >
                                    <Grid>
                                        <DescriptionIcon style={{ color: `${pallet.backgroundColorPrimary}`, width: "16px", marginBottom: "-7px", marginRight: "5px" }} />
                                    </Grid>

                                    <Typography className={classes.heading} style={{
                                        color: `${pallet.textColorTertiary}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size12}`,
                                        fontWeight: pallet.general.weight_600,
                                        marginTop: "6px"
                                    }}>
                                        {/* PL4938/2000 */}
                                       <a style={{textDecoration:"none"}} href={`/proposicao/${item.id_proposicao}`} target="_blank"> {item?.proposicao} </a>
                                    </Typography>


                                </Grid>
                                <Grid container style={{ marginTop: "5px", marginLeft: "6px" }}>
                                    <Grid style={{ paddingRight: "7px" }}>
                                        <Typography className={classes.heading} style={{
                                            color: `${pallet.textColorTertiary}`,
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`,
                                            fontWeight: pallet.general.weight_600,
                                            marginTop: "3px",
                                            paddingLeft: `${item.proposicao_apelido ? "9px" : ""}`
                                        }}>
                                            {item?.proposicao_apelido}
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ paddingRight: "7px" }}>
                                        <ChipStyle pallet={pallet}>
                                            <Grid container>
                                                <DotStyle bg={prioridadeColor(item?.prioridade)}>
                                                </DotStyle>
                                                <Grid>
                                                    {item?.prioridade}
                                                </Grid>
                                            </Grid>
                                        </ChipStyle>
                                    </Grid>
                                    <Grid>
                                        <ChipStyle pallet={pallet}>
                                            <Grid container>
                                                <DotStyle bg={posicionamentoColor(item?.posicionamento)}>
                                                </DotStyle>
                                                <Grid>
                                                    {item?.posicionamento}
                                                </Grid>
                                            </Grid>
                                        </ChipStyle>

                                    </Grid>


                                </Grid>

                                {/* </AccordionSummary> */}
                            </AccordionSum>

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`
                            }}>


                                <Grid container style={{ fontSize: "12px", fontFamily: `${pallet.general.fontFamily}`, paddingTop: "10px", paddingBottom: "10px" }}>
                                    <Grid item style={{ marginRight: "5px" }}>
                                        <Grid container>
                                            <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                                {item?.acoes.filter(valor => valor.resultado.id > 3).length}
                                            </Grid>
                                            <Grid style={{ paddingLeft: "5px" }}>
                                                {item?.acoes.filter(valor => valor.resultado.id > 3).length > 1 ? "ATIVAS" : "ATIVA"}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ marginTop: "-12px", marginRight: "5px", fontSize: "25px" }}>
                                        •
                                    </Grid>
                                    <Grid item>
                                        <Grid container>
                                            <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                                {item?.acoes.filter(valor => valor.resultado.id < 4).length}
                                            </Grid>
                                            <Grid style={{ paddingLeft: "5px" }}>
                                                {item?.acoes.filter(valor => valor.resultado.id < 4).length > 1 ? "CONCLUÍDAS" : "CONCLUÍDA"}
                                            </Grid>
                                        </Grid>
                                        {/* 
                                        {item?.acoes.filter(valor => valor.resultado.id < 4).length}
                                        {item?.acoes.filter(valor => valor.resultado.id < 4).length > 1 ? " CONCLUÍDAS" : " CONCLUÍDA"} */}
                                    </Grid>
                                </Grid>


                                <Grid>
                                    <AccordionTextDataAcaoInfluenciaParlamentarDetail listadetail={item?.acoes} />
                                </Grid>

                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}

const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: 68px;
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
`;

export default AccordionTextDataAcaoInfluenciaParlamentar;

function NewAlert(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
