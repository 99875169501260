import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import TabPanel from "../../../components/carousel/TabPanel";
import { usePallet } from "../../../contexts/PalletContext";
import { IParlamentar } from "../../../models/Parlamentar.Model";
import ParlamentarDiscursosDados from "./ParlamentarDiscursosDados";
import ParlamentarDiscursosDiscurso from "./ParlamentarDiscursosDiscurso";

const ParlamentarCarouseDiscursos = (props: { id_parlamentar: number, activeTab: any, index: number,idPermissao?: number,parlamentar?: IParlamentar }) => {
    const { pallet } = usePallet();

    const [changeDadosOuDisc, setChangeDadosOuDisc] = React.useState("dadosdiscurso")

    const trocaParaDadosOuDiscurso = (key: string) => {
        setChangeDadosOuDisc(key)
    }

    
    return (
        // <Grid style={{ minHeight: '800px'}}>
        <Paper style={{ borderRadius: "6px", marginTop: `${isMobile  ? "0px" : props.idPermissao !== 4 ? "-30px" : "-3px"}` }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <Grid container style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                    <Grid alignItems="flex-start" style={{ marginRight: "20px" }}>
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuDiscurso}
                            tipo={"dadosdiscurso"}
                            backgroundColor={changeDadosOuDisc === "dadosdiscurso" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuDisc === "dircursos" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuDisc === "dircursos" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"DADOS"}
                        />
                    </Grid>
                    <Grid alignItems="flex-start">
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuDiscurso}
                            tipo={"dircursos"}
                            backgroundColor={changeDadosOuDisc === "dircursos" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuDisc === "dadosdiscurso" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuDisc === "dadosdiscurso" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"DISCURSOS"}
                        />
                    </Grid>
                </Grid>
                {
                    changeDadosOuDisc === "dadosdiscurso" ?
                        <ParlamentarDiscursosDados id_parlamentar={props.id_parlamentar} /> :
                        <ParlamentarDiscursosDiscurso id_parlamentar={props.id_parlamentar} idPermissao={props.idPermissao} parlamentar={props.parlamentar} />
                }
            </TabPanel>
        </Paper>
        // </Grid>
    )
}

export default ParlamentarCarouseDiscursos;