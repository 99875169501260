import React, { useState, useEffect } from "react";

import styled from "styled-components";

import FilterItemPanel from "../../../../components/filter/FilterItemPanel";
import {
    IListFilterInputItem,
    IListFilterRadioItem,
    ListFilterInputItem,
    // ListFilterCheckboxItemEsfera,
    ListFilterCheckboxItemEsfera2,
} from "../../../../components/filter/ListFilterItems";
import { IFilterData } from "../../../../components/filter/ListFilter";
import InputMultilineTextField from "../../../../components/InputMultilineTextField";

import TabelasService from "../../../../services/TabelasService";

import { Autocomplete, Chip, Grid, TextField } from '@mui/material';

// import { useAuthorization } from "../../../../contexts/AuthorizationContext";


const esferaItens: IListFilterRadioItem[] = [
    { label: "Federal", value: "Federal" },
    { label: "Estadual", value: "Estadual" },
    { label: "Municipal", value: "Municipal" },
];


const casaItens: IListFilterInputItem[] = [
    { key: "casa", label: "Senado Federal", value: "SF" },
    { key: "casa", label: "Congresso Nacional", value: "CN" },
    { key: "casa", label: "Câmara dos Deputados", value: "CD" }
];

interface Filter {
    key?: string;
    label?: string;
    value?: string;
};


export default function ProposicaoNotificationFilterItens(props: {
    filter: IFilterData[];
    onChangeFilter: (data: IFilterData[]) => void;
    idPermissao?: number;
}): JSX.Element {
    // const { user, getConfig } = useAuthorization();

    useEffect(() => {
        TabelasService.getTabelaEstados().then(res => {
            const data = []
            res?.map(item => data.push({ id: item.id, key: item.sigla, label: item.nome, value: item.sigla }))
            setEstados(() => [...data]);
        });

        if (checkArrayFilter(props.filter)) {
            setShowFilters(true);
        };

        let itemTitulo = props.filter.find(item => item.key === "titulo");
        setTitulo(itemTitulo !== undefined ? itemTitulo.label : "");
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const estadoFilter = props.filter.find(item => item.key === 'estado');

            if (estadoFilter && estadoFilter.value.length > 0 && check("esfera", "Municipal")) {
                let listMunicipios = [];
                await Promise.all(estadoFilter.value.map(async estado => {
                    try {
                        let array: any = [estado];
                        const res = await TabelasService.getTabelaMunicipios(array);
                        const data = res.map(item => ({
                            id: item.id,
                            key: item.nome,
                            label: `${estado} | ${item.nome}`,
                            value: `${estado} | ${item.nome}`,
                            cod: item.sigla
                        }));

                        listMunicipios.push(...data);
                    } catch (error) {
                        console.error(`Erro ao buscar municípios para o estado ${estado}:`, error);
                    }
                }));
                setMunicipio(listMunicipios);
            };
        };

        fetchData();
    }, [props.filter]);

    const checkObjFilter = (obj: Filter): boolean => {
        const validKey = obj.key === "esfera";
        const values = ["Federal", "Estadual", "Municipal"];
        const keyValeusInclude = values.some(value => obj.value.includes(value))

        return validKey && keyValeusInclude;
    };

    const checkArrayFilter = (array: Filter[]): boolean => {
        return array.some(checkObjFilter);
    };

    const [showFilters, setShowFilters] = useState<Boolean>(false);

    const [expanded, setExpanded] = useState<string | false>("panel1");
    const handleChangeOpen = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => setExpanded(newExpanded ? panel : false);

    const check = (key: string, value: any): boolean => {
        const item = props.filter.filter((item) => item.key === key);
        return item.length === 1 && item[0].value.includes(value);
    };

    const [estados, setEstados] = useState<Array<IListFilterInputItem>>([]);
    const [municipio, setMunicipio] = React.useState<Array<IListFilterInputItem>>([]);

    const handleEstadoOnClick = async (data: any, value: any, id: any) => {
        const values = props.filter.filter((item) => item.key !== "estado" && item.key !== "municipal");

        values.push({
            key: data.target.name,
            label: `${data.target.name.toUpperCase()}: ${value}`,
            value: value,
            id: id
        });

        let listMunicipios = [];
        await value.forEach(estado => {
            TabelasService.getTabelaMunicipios(estado).then(
                res => {
                    const data = []
                    res?.map(item => data.push({ id: item.id, key: item.nome, label: item.nome, value: item.nome, cod: item.sigla }));
                    listMunicipios.push(() => [...data])
                })
        });

        setMunicipio(listMunicipios);

        props.onChangeFilter(values);
    };

    const handleGenericOnClick = (data: any, value: any, id_: any, cod: any) => {
        if (data && data.target) {
            const eventTarget = data.target;
            const name = eventTarget?.name;
            const idTarget = eventTarget?.id;
            const fieldValue = (value !== undefined) ? value : eventTarget.value;

            const values = props.filter.filter((item) => item.key !== name && item.key !== idTarget);

            if (fieldValue && name) {
                values.push({
                    key: name,
                    label: `${name.toUpperCase()}: ${fieldValue}`,
                    value: fieldValue,
                    id: id_,
                    cod: cod
                });
            } else if (fieldValue && idTarget) {
                values.push({
                    key: idTarget,
                    label: `${idTarget.toUpperCase()}: ${fieldValue}`,
                    value: fieldValue,
                    id: id_,
                    cod: cod
                });
            };
            props.onChangeFilter(values);
        } else {
            console.error('target não definido');
        };
    };

    const handleCasaOnClick = (data: any, value: any) => {
        const values = props.filter.filter(item => item.key !== 'casa' && item.key !== 'ambito');

        switch (value) {
            case "SF":
                values.push({ key: data.target.name, label: data.target.name.toUpperCase() + ': Senado', value: value });
                break;
            case "CD":
                values.push({ key: data.target.name, label: data.target.name.toUpperCase() + ': Câmara', value: value });
                break;
            case "CN":
                values.push({ key: data.target.name, label: data.target.name.toUpperCase() + ': Congresso', value: value });
                break;
        };

        props.onChangeFilter(values);
    };

    const handleWrapper = (updatedFilter: IFilterData[]) => {
        props.onChangeFilter(updatedFilter);
        setShowFilters(true);
    };

    const [titulo, setTitulo] = React.useState<string>("");
    const handleTituloOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const values = props.filter.filter(item => item.key !== 'titulo')
        values.push({ key: "titulo", label: event.target.value, value: event.target.value });

        setTitulo(event.target.value);
        props.onChangeFilter(values);
    };

    return (
        <>
            <Grid style={{marginBottom: "10px", marginTop: "10px"}}>
                <InputMultilineTextField
                    type={"text"}
                    maxRows={2}
                    value={titulo}
                    defaultValue={""}
                    onChange={handleTituloOnChange}
                    id={"titulo"}
                    label="Digite um titulo para o filtro criado."
                />
            </Grid>

            {/* Esfera */}

            <FilterItemPanel panel="panel1" name="esfera" label="Esfera" expanded="esfera">
                {/* <ListFilterRadioItem
                    label="Esfera"
                    currentFilter={props.filter}
                    filterKey="esfera"
                    itens={esferaItens}
                    onChange={handleGenericOnClick}
                /> */}

                <ListFilterCheckboxItemEsfera2
                    label="Esfera"
                    currentFilter={props.filter}
                    filterKey="esfera"
                    items={esferaItens}
                    onChange={handleWrapper}
                />
            </FilterItemPanel>
            {/* END Esfera */}

            {showFilters ?
                <FilterItemPanel panel="panel2" name="poder" label="Poder Legislativo" onOpen={handleChangeOpen}>
                    {(check("esfera", "Estadual") || check("esfera", "Municipal")) && (
                        <MultipleListFilterInputItem
                            label="Estado"
                            currentFilter={props.filter}
                            filterKey="estado"
                            itens={estados}
                            onChange={handleEstadoOnClick}
                        />
                    )}

                    {check("esfera", "Federal") && (
                        <>
                            <ListFilterInputItem
                                label="Casa Legislativa"
                                currentFilter={props.filter}
                                filterKey="casa"
                                itens={casaItens}
                                onChange={handleCasaOnClick}
                            />
                        </>
                    )}

                    {check("esfera", "Municipal") && (
                        <MultipleListFilterInputItem
                            label="Municipio"
                            currentFilter={props.filter}
                            filterKey="municipio"
                            itens={municipio}
                            onChange={handleGenericOnClick}
                        />
                    )}
                </FilterItemPanel> : null
            }
        </>
    );
};


const MainGrid = styled(Grid)`
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    max-height: 300px;
`;

const MultipleListFilterInputItem = (props: {
    currentFilter: IFilterData[];
    filterKey: string,
    label: string,
    itens: IListFilterInputItem[],
    onChange: (data: any, value: any, id: any, cod: any) => void
}) => {
    const filterEstadosArray = props.currentFilter.filter(filter => filter.key === props.filterKey);

    let resultArray = [];
    if (filterEstadosArray.length > 0) {
        const filterValueArray = filterEstadosArray[0].value;

        if (props.itens) {
            resultArray = filterValueArray.map(
                val => props.itens.find(item => item.value === val) || { key: val, label: val, value: val }
            );
        };
    };

    const handleChange = (event, selectedOptions) => {
        event.target.name = props.filterKey;
        let optionsId = selectedOptions.map(option => option.id);
        let optionsValue = selectedOptions.map(option => option.value);
        let optionsCod = selectedOptions.map(option => option.cod);
        props.onChange(event, optionsValue, optionsId, optionsCod);
    };

    return (
        <MainGrid container>
            <Autocomplete
                multiple
                id={`searchable-select-${props.filterKey}`}
                style={{ width: '100%', backgroundColor: 'white' }}
                options={props.itens}
                getOptionLabel={(option) => option.label}
                value={resultArray}
                onChange={handleChange}
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={props.label}
                        style={{ width: '95%', backgroundColor: 'white' }}
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={index}
                            label={option.label}
                            {...getTagProps({ index })}
                            style={{
                                color: option.key === '' ? '#9B9B9B' : '#1E0736',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '16px',
                                whiteSpace: "unset",
                                wordBreak: "break-all",
                                maxWidth: "500px"
                            }}
                        />
                    ))
                }
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                        <input
                            type="checkbox"
                            checked={selected}
                            style={{ marginRight: 8 }}
                        />
                        {option.label}
                    </li>
                )}
            />
        </MainGrid>
    );
};