import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { usePallet } from '../contexts/PalletContext';



const ButtonCount = (props: {
    item: {
        key: string,
        label: string,
    },
    active: boolean,
    style?: React.CSSProperties,
    onClick: (event: any) => void,
}) => {
    const { pallet } = usePallet();

    return (
        <Button
            onClick={(e) => { props.onClick(e) }}
            key={props.item.key}
            title={props.item.label !== "DOU" ? props.item.label : "DIÁRIO"}
            variant="outlined"
            style={{
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.fontSize.titleWidgets}`,
                fontWeight: pallet.general.weight_bold,
                //backgroundColor: `${props.active ? pallet.textColorQuintenary : "#FFFFFF"}`,
                color: `${props.active ? "white" : "#9B9B9B"}`,
                border: `1px solid ${props.active ? pallet.general.color2 : "#D8D8D8"}`,
                borderRadius: "6px",
                alignItems: "center",
                letterSpacing: "1px",
                padding: "5 7px",
                marginRight: "20px",
                ...props.style
            }}
        >

            {props.item.label}
           

        </Button>
    )
}



export default ButtonCount;