import React from 'react';
import { useState, useEffect } from "react";

import { isMobile } from 'react-device-detect';
import styled from "styled-components";

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, Menu, MenuItem, MenuProps, Typography } from '@mui/material';
import { CalendarToday, MoreHoriz } from '@material-ui/icons';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteIcon from '@mui/icons-material/Delete';

import ProposicaoEmendasModal from './ProposicaoEmendasModal';
import EmendasProposicaoEdicao from './EmendasProposicaoEdicao';

import { useAuthorization } from '../../../../contexts/AuthorizationContext';
import { usePallet } from '../../../../contexts/PalletContext';
import { useAlert } from "../../../../contexts/AlertContext";

import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import useStyles from '../../../../components/layout/Styles';

// import { proposicaoPosicionamento } from '../../../../models/Proposicao.Model';
import ProposicaoService from '../../../../services/ProposicaoService';
import { formatDate } from '../../../../utils/dateUtils';


const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
`;

function AccordionTextDataEmendaProposicao(props: {
    lista?: Array<any>,
    onChange?: () => void,
    id_proposicao?: number,
    proposicao_integrada: boolean
}) {

    const { NewAlert } = useAlert();
    const { pallet } = usePallet();
    const classes = useStyles();
    const [lista, setLista] = useState<Array<any>>(props.lista);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const { user } = useAuthorization();

    const corPosicionamento = (value: string) => {
        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        };
    };

    function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.posicionamento}
                    </Grid>
                </Grid>
            </>
        );
    };

    const posicionamentosItens = [
        { key: 0, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "Neutro" },
        { key: 1, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "Contrário com emenda saneadora" },
        { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "Contrário" },
        { key: 3, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "Favorável com emenda saneadora" },
        { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "Favorável" },
        { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "Favorável ao parecer do relator" },
        { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "Favorável ao parecer do relator com emenda" },
        { key: 7, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "Contrário ao parecer do relator" },
        { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "Contrário ao parecer do relator com emenda" },
        { key: 8, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "Pendente" },
        { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "Indefinido" },
    ];

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        setLista(props.lista);
    }, [props.lista]);

    const setEmendaLista = (id: number, proposicao: string) => {
        const objIndex = lista.findIndex((object => object.id === id));

        if (objIndex >= 0) {
            lista[objIndex].posicionamento = proposicao;
            setLista(lista);
        };
    };

    async function loadEmendas(idEmenda: number) {
        props.onChange();
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };

    const deletarEmenda = (id) => {
        ProposicaoService.DeleteEmenda(id).then(res => {
            if (res) {
                NewAlert('success', 'Emenda apagada com sucesso!');
                handleClose();
            } else {
                NewAlert('error', 'Erro ao apagar, verifique os campos e apague novamente');
            };
        }).then(x => loadEmendas(id))
    };

    const handleEmendaPosicionamentoChange = (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        ProposicaoService.SetPosicionamentoEmenda(id, event.target.value).then(x => {
            setEmendaLista(id, event.target.value);
            loadEmendas(id);
            NewAlert('success', 'Registro Salvo com sucesso');
        });

        return event.target.value;
    };

    const StyledMenu = styled((props: MenuProps) => (
        <Menu

            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 135,
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '5px 0px',
                minWidth: 140,

            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    // fontSize: 18,
                },
            },
        },
    }));

    return (
        <>
            {lista?.map(item => {
                return (
                    <Grid key={item.id} style={{ marginBottom: "20px" }}>
                        <Accordion expanded={true} elevation={0} style={{ border: "1px solid #EAEAEA", background: `${pallet.backGroundPageColorPrimary}` }} >
                            <AccordionSummary style={{
                                paddingLeft: `${isMobile ? "5px" : "20px"}`,
                                borderRadius: "5px",
                                // paddingLeft: "20px",
                                paddingRight: "20px",
                                flexFlow: "row-reverse",
                                height: "64px",
                                cursor: "default"
                            }}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container justifyContent="space-between" direction="row" >
                                    <Grid container item lg={6} xs={6} >
                                        <Typography className={classes.heading} style={{
                                            color: `${pallet.textColorTertiary}`,
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`,
                                            fontWeight: pallet.general.weight_600,
                                            marginTop: "6px",
                                        }}>
                                            {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "10px"}`, textOverflow: "elipsis", whiteSpace: "nowrap" }}> </span>}
                                            {item.proposicao} &nbsp; | &nbsp;
                                        </Typography>

                                        {/* <------------DATA E HORA-------> */}
                                        <Grid container xl={3} lg={3} item style={{
                                            maxWidth: `${isMobile ? "210px" : "250px"}`
                                        }}
                                        >
                                            <Grid>
                                                <CalendarToday style={{
                                                    color: `${pallet.backgroundColorPrimary}`,
                                                    height: "22px",
                                                    width: "20px",
                                                    marginRight: "10px",
                                                    marginTop: "3px",
                                                    marginLeft: `${isMobile ? "0px" : "5px"}`
                                                }} />
                                            </Grid>
                                            <Grid >
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.textColorQuaternary}`,
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    fontWeight: pallet.general.weight_600,
                                                }}>
                                                    <Grid container
                                                        style=
                                                        {{
                                                            marginTop: "5px",
                                                        }}>
                                                        {formatDate(new Date(item.data), 'dd/MM/yyyy')}
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {
                                        (!props?.proposicao_integrada && props?.proposicao_integrada !== undefined) &&

                                        <Grid item lg={6} xs={6}
                                        // textAlign={"end"}
                                        // style={{ position: "relative", paddingBottom: "24px" }}
                                        >
                                            <Button
                                                disableElevation
                                                variant="contained"
                                                size="medium"
                                                style={{
                                                    backgroundColor: `${pallet.backgroundColorTertiary}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontWeight: pallet.fontWeight.lighter,
                                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                                    color: `${pallet.color.quaternary.font}`,
                                                    borderRadius: "6px",
                                                    width: "25px",
                                                    position: "absolute",
                                                    top: "23px",
                                                    height: "20px",
                                                    right: "5px",
                                                    zIndex: 10,
                                                }}
                                                onClick={(e) => handleClick(e, item.id)}
                                                endIcon={<Grid style={{ fontSize: "30px", marginTop: "9px", marginRight: "10px" }}>
                                                    <MoreHoriz fontSize='inherit' />
                                                </Grid>
                                                }
                                            >
                                            </Button>
                                            <StyledMenu style={{
                                                border: "1px solid lightgrey",
                                                borderRadius: "6px",
                                            }}
                                                id={`mmenu-${item.id}`}
                                                MenuListProps={{
                                                    'aria-labelledby': `button-${item.id}`,
                                                }}
                                                anchorEl={anchorEl}
                                                open={open === item.id}
                                                onClose={handleClose}
                                            >
                                                <MenuItem style={{ borderRadius: "6px", minHeight: "45px" }}>
                                                    <EmendasProposicaoEdicao
                                                        idEmenda={item.id}
                                                        reloadList={() => loadEmendas(item.id)}
                                                        closeMenu={() => handleClose()}
                                                        idProposicao={props?.id_proposicao}
                                                        idPermissao={user.id_permissao}
                                                    />
                                                </MenuItem>
                                                <Grid>
                                                    < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "2px" : "5px"}`, marginBottom: `${isMobile ? "2px" : "5px"}` }} />
                                                </Grid>
                                                <MenuItem
                                                    // onClick={() => deletarEdicaoDemandas(item.id)}
                                                    style={{
                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                        fontWeight: pallet.fontWeight.button,
                                                        fontSize: `${pallet.general.size14}`,
                                                        color: `${pallet.color.tertiary.font}`,
                                                        borderRadius: "6px",
                                                        padding: "7px",

                                                    }}>

                                                    <ButtonDialogConfirmation
                                                        styles={{
                                                            fontFamily: `${pallet.fontFamily.general}`,
                                                            fontWeight: pallet.fontWeight.button,
                                                            fontSize: `${pallet.general.size14}`,
                                                            color: `${pallet.color.tertiary.font}`,
                                                            borderRadius: "6px 6px 0px 0px",
                                                            textTransform: "capitalize"
                                                        }}
                                                        title={"CONFIRMAÇÃO"}
                                                        title_button={"Apagar"}
                                                        confirmation_button={"Apagar"}
                                                        content={"APAGAR Emenda?"}
                                                        message={"Esta ação não poderá ser desfeita."}
                                                        confirmationEvent={() => user.id_permissao !== 3 ? deletarEmenda(item.id) : NewAlert(
                                                            "error",
                                                            "Não é possível realizar está ação com este perfil.")}
                                                    >
                                                        <DeleteIcon style={{
                                                            color: `${pallet.color.tertiary.font}`,
                                                            marginRight: "16px",
                                                            fontFamily: `${pallet.fontFamily.general}`,
                                                        }} />

                                                    </ButtonDialogConfirmation>
                                                </MenuItem>
                                            </StyledMenu>
                                        </Grid >
                                    }
                                    {/* ----DESCRICAO E LABEL---- */}
                                </Grid>
                            </AccordionSummary>

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                background: `${pallet.backGroundPageColorSecondary}`

                            }}>
                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "20px 20px 10px 20px"}`, width: "100%" }}>
                                    <Grid xl={12} container justifyContent="space-between">
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <Grid style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.general.size12}`,
                                                color: `${pallet.color.tertiary.font}`
                                            }}>
                                                EMENDA
                                            </Grid>

                                            <Grid style={{
                                                marginTop: "8px",
                                                marginBottom: `${item.autor ? "8px" : "0px"}`,
                                                fontWeight: pallet.general.weight_300,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`
                                            }}
                                            >
                                                {item.ementa}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {item.autor && 
                                        <Grid xl={12} container justifyContent="space-between">
                                            <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.general.size12}`,
                                                    color: `${pallet.color.tertiary.font}`
                                                }}>
                                                    AUTOR
                                                </Grid>

                                                <Grid style={{
                                                    marginTop: "8px",
                                                    marginBottom: `${item.qualificacao ? "8px" : "0px"}`,
                                                    fontWeight: pallet.general.weight_300,
                                                    fontSize: `${pallet.general.size14}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}
                                                >
                                                    {item.autor}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    {item.qualificacao && 
                                        <Grid xl={12} container justifyContent="space-between">
                                            <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.general.size12}`,
                                                    color: `${pallet.color.tertiary.font}`
                                                }}>
                                                    QUALIFICAÇÃO
                                                </Grid>

                                                <Grid style={{
                                                    marginTop: "8px",
                                                    fontWeight: pallet.general.weight_300,
                                                    fontSize: `${pallet.general.size14}`,
                                                    color: `${pallet.color.secundary.font}`
                                                }}
                                                >
                                                    {item.qualificacao}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: "30px", paddingBottom: "20px" }}>
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <Grid container alignItems="center" style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.general.size12}`,
                                                color: `${pallet.color.secundary.font}`
                                            }}>
                                                <ArrowRightAltIcon style={{ marginRight: "5px" }} />
                                                {item.ementa && <ProposicaoEmendasModal id={item.id}
                                                    posicionamento={item.posicionamento}
                                                    //onChange={(id, e) => handleEmendaPosicionamentoChange(id, e)}
                                                />}
                                            </Grid>
                                            {/* <Grid style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.fontSize.text_10}`,
                                                color: `${pallet.color.secundary.font}`
                                            }}>
                                                {item.url && <Link href={item.url.replace('http://', 'https://')} >Pronunciamento</Link>}
                                            </Grid> */}
                                        </Grid>
                                    </Grid>

                                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                        <Grid className="container">
                                            <AnexosComponent
                                                label={''}
                                                id_referencia={item.id}
                                                modulo={EAnexoModulos.ProposicaoEmenda}
                                                canEdit={false}
                                                autosave={false}
                                                isAnexosTitle={"ANEXOS"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider variant="fullWidth" style={{ paddingTop: "10px" }} />
                                    <Grid style={{ padding: `${isMobile ? "20px 5px 10px 0px" : "20px 20px 10px 0px"}`, width: "100%" }}>
                                        <Grid style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.general.size12}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            paddingBottom: "20px"
                                        }}>

                                            REGISTRO
                                        </Grid>
                                        <Grid >
                                            <Box component="form">
                                                <SelectTextFieldA
                                                    value={item?.posicionamento}
                                                    onChange={(e) => user.id_permissao !== 3 ? handleEmendaPosicionamentoChange(item.id, e) : NewAlert(
                                                        "error",
                                                        "Não é possível realizar está ação com este perfil.")}
                                                    //options={proposicaoPosicionamento}
                                                    options={posicionamentosItens}
                                                    id={"posicionamento" + item.id}
                                                    label={"POSICIONAMENTO"}
                                                />
                                            </Box>
                                            <Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataEmendaProposicao;
