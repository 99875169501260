import * as Yup from "yup";

const ProposicaoAcontecimentosCreateSchema = Yup.object({
    nome: Yup.string().required({ name: "nome", err: "Campo com preenchimento obrigatório" }),
    email: Yup.string().required({ name: "email", err: "Campo com preenchimento obrigatório" }),
    senha:  Yup.string().min(6).required({ name: "senha", err: "Campo deve ter ao menos 6 caracteres" }),
    id_permissao: Yup.number().required({ name: "id_permissao", err: "Campo com preenchimento obrigatório" }),
    id_departamento: Yup.number().required({ name: "id_departamento", err: "Campo com preenchimento obrigatório" }),
});


export default ProposicaoAcontecimentosCreateSchema;