import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../../components/carousel/TabPanel";
import { usePallet } from "../../../../contexts/PalletContext";
import ProposicaoAcaoPreparatoriaEspecificaComentario from "./ProposicaoAcaoPreparatoriaEspecificaComentario"
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";

const ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsComentários = (props: {activeTab: any, index: number,acao:IAcaoPreparatoria }) => {
    const { pallet } = usePallet();



    return (
        <TabPanel value={props.activeTab} index={props.index}>
            <Paper style={{ borderRadius: "6px", marginTop: `${isMobile ? "0px" : "0px"}` }}>
                <ProposicaoAcaoPreparatoriaEspecificaComentario acao={props.acao} />           
            </Paper>
        </TabPanel>
    )
}


export default ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsComentários;