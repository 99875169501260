import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, TextField, Typography } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobile } from 'react-device-detect';
import { CalendarToday } from '@material-ui/icons';
import { formatDate } from '../../../utils/dateUtils';
import ParlamentarDiscursosModal from './ParlamentarDiscursosModal';
import { useState } from "react";

// const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

function AccordionTextDataOutrosParlamentarFrente(props: { lista: Array<any> }) {

    const { pallet } = usePallet();
    const classes = useStyles();

    return (
        <>
            {props.lista?.map(item => {
                return (


                    <Grid key={item.id} style={{ marginBottom: "10px" }}>

                        <Accordion expanded elevation={0} style={{ border: "1px solid #EAEAEA" }} >
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    // backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                    height: "10px",
                                    borderRadius: "5px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}
                            >

                                <Grid container>

                                    <Typography className={classes.heading} style={{
                                        color: `${pallet.color.tertiary.font}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size14}`,
                                        fontWeight: pallet.general.weight_600,
                                        marginTop: "4px",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        LEGISLATURA ATUAL <Typography style={{ color: `${pallet.color.secundary.font}`, marginLeft: "8px", fontWeight: pallet.general.weight_600 }}>[{item.legislatura}]</Typography>
                                    </Typography>


                                </Grid>

                            </AccordionSummary>

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`
                            }}>
                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 15px 0px 5px"}`, width: "100%" }}>

                                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                        <Grid style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.fontSize.text_10}`,
                                            color: `${pallet.color.tertiary.font}`
                                        }}>
                                            DESCRIÇÃO
                                        </Grid>

                                        <Grid style={{ marginTop: "10px" }}>
                                            {item.descricao}
                                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique ipsa deserunt ex, laborum molestias ducimus blanditiis dolorum facilis facere consectetur doloribus quis autem aliquam incidunt consequuntur nihil at? Dignissimos, debitis? */}
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataOutrosParlamentarFrente;

function NewAlert(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
