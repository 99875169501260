import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { usePallet } from '../contexts/PalletContext';

export default function ButtonIconLeft(props: { icon: any, title: string, onClick?: () => void, className }) {

  const { pallet } = usePallet();

  const onClickEvent = () => {
    if (props.onClick) props.onClick();
    return true
  }

  return (
    <Stack direction="row" spacing={2}>
      <Button
        className={props.className}
        onClick={onClickEvent}
        variant="outlined"
        startIcon={props.icon}
        style={{
          border: "none",
        }}
      >
        {props.title}

      </Button>

    </Stack>


  );
}