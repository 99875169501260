import * as http from '../helpers/http';

export interface INotificationsContextResult {
  newNotifications: {
    pageSize: number,
    pageIndex: number,
    pageItens: number,
    totalItens: number,
    totalPages: number,
    data?: INotificationsContextResultData[]
  },
  totalNotificacoesTodas: number,
  totalNotificacoesProposicao: number,
  totalNotificacoesInterlocutor: number,
  totalNotificacoesAgenda: number,
  totalNotificacoesCitacoes: number,
  totalNotificacoesTwitter: number,
  notificacaoSummary: INotificationsSummaryData[]
  notificacaoPendencies: INotificationsContextResultData[],
  notificacaoPendenciesDepartament: INotificationsContextResultData[],
  getData: any,
  mudarPagina: any,
  getDataPendencias: any,
  getDataPendenciasDepartamento: any
}

export interface INotificationsContextResultData {
  id: number,
  tipo: number,
  modulo: string,
  descricao: string,
  referencia: number,
  mensagem: string,
  path: string,
  links: string,
  data_evento: Date,
  criado_em?: Date,
  arquivada: string,
  dislike: string,
  lida: string,
  like: string,
  noFollow: string,
  checked?: boolean,
  seguindo?: boolean
}


export interface INotificationsSummaryData {
  dia: string,
  temas: [
    {
      modulo: string,
      count: number
    }
  ]
}

class NotificationsContextService {
  GetAll = async (filter?: any, pageSize?: Number, pageIndex?: Number, central = '', newFilter?: any): Promise<any> => {
    if (newFilter) newFilter = JSON.stringify(newFilter);
    const result = await http.get(
      `/notificacoes?filter=${JSON.stringify(filter)}&pageSize=${pageSize}&pageIndex=${pageIndex}&central=${central}`
    );
    if (result.success) { return result.data }
    return undefined;
  };

  GetNews = async (): Promise<number> => {
    const result = await http.get('/notificacoes/news');
    if (result.success) { return result.data }
    return undefined;
  };

  GetSummary = async (): Promise<any> => {
    const result = await http.get('/notificacoes/summary');
    if (result.success) { return result.data }
    return undefined;
  };

  GetPendencies = async (pageSize?: Number, pageIndex?: Number): Promise<any> => {
    const result = await http.get(`/notificacoes/departamento?filter=[{"key":"pendencias","label":"Pendencias","value":true}]`);
    if (result.success) { return result.data }

    return undefined;
  };

  GetPendenciesDepartament = async (pageSize?: Number, pageIndex?: Number): Promise<any> => {
    const result = await http.get(`/notificacoes/departamento?filter=[{"key":"pendenciasDepartamento","label":"Pendencias","value":true}]`);
    if (result.success) { return result.data }

    return undefined;
  };

  Arquivar = async (id_notificacao: number): Promise<any> => {
    const result = await http.post(`/notificacoes/arquivar/${id_notificacao}`);
    if (result.success) { return result.data }

    return undefined;
  };

  Desarquivar = async (id_notificacao: number): Promise<any> => {
    const result = await http.post(`/notificacoes/desarquivar/${id_notificacao}`);
    if (result.success) { return result.data };

    return undefined;
  };

  ArquivarTodas = async (filter: string, dtIni: Date, dtFin?: Date): Promise<any> => {
    const result = await http.post(`/notificacoes/arquivar/todas/${filter}/${dtIni}/${dtFin}`);
    if (result.success) { return result.data }

    return undefined;
  };

  ArquivarTodasAreaTrabalho = async (filter?: string): Promise<any> => {
    const result = await http.post(`/notificacoes/arquivar/area-trabalho/${filter}`);
    if (result.success) { return result.data }

    return undefined;
  };

  Seguir = async (id_notificacao: number): Promise<boolean> => {
    const result = await http.post(`/notificacoes/seguir/${id_notificacao}`);
    if (result.success) { return result.data }

    return false;
  };

  NaoSeguir = async (tipo: number): Promise<boolean> => {
    const result = await http.del(`/notificacoes/pararSeguir/${tipo}`);
    if (result.success) { return result.data }

    return false;
  };

  UpdateFiltro = async (id: string, data: any): Promise<boolean> => {
    const result = await http.put(`/notificacoes/filtro/${id}`, data);
    if (result.success) { return result.data }

    return false;
  };

  SalvarFiltro = async (data: any): Promise<boolean> => {
    const result = await http.post(`/notificacoes/filtro`, data);
    if (result.success) { return result.data };

    return false;
  };

  DeleteFiltro = async (id: number): Promise<boolean> => {
    const result = await http.del(`/notificacoes/filtro/${id}`);
    if (result.success) { return result.data };

    return false;
  };

  Filtros = async (): Promise<any> => {
    const result = await http.get(`/notificacoes/filtros/`);
    if (result.success) { return result.data };

    return undefined;
  };
};

export default new NotificationsContextService();
