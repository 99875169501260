import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { usePallet } from '../../contexts/PalletContext';
import { Box, Grid, Paper } from '@mui/material';
import { useAuthorization } from '../../contexts/AuthorizationContext';
import { useDashboard } from '../../contexts/DashboardContext';
import useStyles from '../../components/layout/Styles';
import Main from '../main/Main';
import Copyright from '../../components/Copyright';
import IndicadoresImpactoFinanceiro from './_components/IndicadoresImpactoFinanceiro';
import InfoCard from '../../components/InfoCard';
import IndicatoresPrioridade from './_components/IndicadoresPrioridade';
import NumbersPanelWidget from '../../components/NumbersPanelWidget';
import DadosPendencias from './_components/DadosPendencias';
import PieChartInfoCard from '../../components/PieChartInfoCard';
import { format, subYears } from 'date-fns'
import { isMobile } from 'react-device-detect';
import filtersBackgroundImage from '../../../src/img/background_filters_dashboard.png';
import DateRangeSelector from '../../components/DateRangeSelector'
import Typography from '@material-ui/core/Typography';
import CalendarOutlineIcon from '@mui/icons-material/CalendarTodayOutlined';import SpheresFilter from '../../components/spheresFilter';
;


export default function Dashboard() {


    // const [chartRenderManagement, setChartRenderManagement] = React.useState("Federal");
    const {
        impacto_financeiro_positivo,
        impacto_financeiro_neutro,
        impacto_financeiro_negativo,

        prioridade_minima_valor,
        prioridade_baixa_valor,
        prioridade_media_valor,
        prioridade_alta_valor,
        prioridade_maxima_valor,

        prioridade_minima_qtd,
        prioridade_baixa_qtd,
        prioridade_media_qtd,
        prioridade_alta_qtd,
        prioridade_maxima_qtd,

        pendencias_minhas,
        pendencias_departamento,
        parlamentares,
        em_tramitacao,

        // universo_title_chart,
        // universo_inativosSubtitle_chart,
        universo_inativosValue_chart,
        // universo_ativosSubtitle_chart,
        universo_ativosValue_chart,

        casasLegislativas_congressoNacionalValue_chart,
        casasLegislativas_camaraDosDeputadosValue_chart,
        casasLegislativas_senadoFederalValue_chart,

        acoesInfluenciaPendente,
        acoesInfluenciaExito,
        acoesInfluenciaSemExito,
        acoesInfluenciaExitoParcial,

        momentosCriticosdesignacaoRelator,
        momentosCriticosaguardanoParecerDoRelator,
        momentosCriticosprazoParaEmenda,
        momentosCriticosaguardaDeliberacao,

        posicionamentocontrarioParecerRelator,
        posicionamentocontrarioParecerRelatorEmenda,
        posicionamentofavoravelParecerRelatorEmenda,
        posicionamentofavoravelParecerRelator,
        posicionamentofavoravel,
        posicionamentofavoravelComEmenda,
        posicionamentocontrario,
        posicionamentocontrarioComEmenda,
        posicionamentopendente,
        posicionamentoneutro,

        qualificacaoParlamentarconvergente,
        qualificacaoParlamentardivergente,
        qualificacaoParlamentarnaoClassificado,
        qualificacaoParlamentarneutro,

        dtIni,
        dtFin,
        // filterKindList,
        chartRenderFederal,
        setPeriod,
        // sphereFilterFunction,
    } = useDashboard()

    React.useEffect(() => {
        dateRangeSet(subYears(new Date(), 5), new Date())
        // eslint-disable-next-line
    }, []);

    const { hasAccess } = useAuthorization();
    const { pallet } = usePallet();
    const classes = useStyles();

    const dateRangeSet = (startDate: Date, endDate: Date) => {
        if (endDate !== null) {
            setPeriod(startDate, endDate)
        }
    }

    const onChangeDate = (dtIni: Date, dtFim: Date) => {

    }
    return (
        !hasAccess('dashboard') ? <></>
            :
            <div className={classes.root}>
                <Main>
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper} style={{
                                    backgroundImage: `url(${filtersBackgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>

                                    <Grid style={{ marginBottom: '20px' }}>
                                        <Typography
                                            style={{
                                                color: `${pallet.color.secundary.font}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.subtitle,
                                            }}>
                                            Período
                                        </Typography>
                                    </Grid>

                                    <Grid container alignItems="center" direction="row"

                                        style={{
                                            maxWidth: `${isMobile ? "100%" : "280px"}`,
                                            background: "#4B1196",
                                            borderRadius: '5px',
                                            justifyContent: `${isMobile ? "flex-start" : "center"}`

                                        }}
                                    >
                                        <Grid item>
                                            <CalendarOutlineIcon style={{
                                                color: `${pallet.color.primary.font}`,
                                                marginTop: '6px',
                                                fontSize: '17px',
                                                marginRight: '0px',
                                                marginLeft: `${isMobile ? "10px" : "-20px"}`
                                            }} />
                                        </Grid>
                                        <Grid item
                                            style={{
                                                paddingRight: `${isMobile ? "0px" : "0px"}`,
                                                color: `${pallet.color.primary.font}`
                                            }} >

                                            <DateRangeSelector

                                                startDate={dtIni}
                                                endDate={dtFin}
                                                onChange={onChangeDate}

                                            />
                                        </Grid>
                                        {/* <Box display="block">
                                            <SpheresFilter
                                                title={"ESFERAS"}
                                                isFilter={
                                                    [
                                                        { text: "Municipal" },
                                                        { text: "Estadual" },
                                                        { text: "Federal" },
                                                    ]
                                                }
                                            //onClick={isFederalSphereFilter}
                                            />
                                        </Box> */}
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: "40px" }}>
                                <Paper className={classes.paper}>
                                    <InfoCard title={'IMPACTO FINANCEIRO'} />
                                    <Grid item xs={12} key='IndicadoresImpactoFinanceiro' >
                                        <IndicadoresImpactoFinanceiro
                                            valor_positivo={impacto_financeiro_positivo}
                                            valor_neutro={impacto_financeiro_neutro}
                                            valor_negativo={impacto_financeiro_negativo} />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: "40px" }}>
                                <Paper className={classes.paper}>
                                    <InfoCard title={'PRIORIDADE'} />
                                    <Grid item xs={12}>
                                        <div>
                                            <Grid container justifyContent="space-around" style={{ color: `${pallet.general.color1}` }} >
                                                <IndicatoresPrioridade
                                                    value={prioridade_minima_valor}
                                                    title={"Mínima"}
                                                    link={"/agenda"}
                                                    tag={prioridade_minima_qtd.toString()}
                                                />
                                                <IndicatoresPrioridade
                                                    value={prioridade_baixa_valor}
                                                    title={"Baixa"}
                                                    link={"/agenda"}
                                                    tag={prioridade_baixa_qtd.toString()}
                                                />
                                                <IndicatoresPrioridade
                                                    value={prioridade_media_valor}
                                                    title={"Média"}
                                                    link={"/agenda"}
                                                    tag={prioridade_media_qtd.toString()}
                                                />
                                                <IndicatoresPrioridade
                                                    value={prioridade_alta_valor}
                                                    title={"Alta"}
                                                    link={"/agenda"}
                                                    tag={prioridade_alta_qtd.toString()}
                                                />
                                                <IndicatoresPrioridade
                                                    value={prioridade_maxima_valor}
                                                    title={"Máxima"}
                                                    link={"/agenda"}
                                                    tag={prioridade_maxima_qtd.toString()}
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: "40px" }}>
                                <Paper className={classes.paper}>
                                    <InfoCard title={'PENDÊNCIAS'} />
                                    <DadosPendencias
                                        valueMinhas={pendencias_minhas}
                                        valueDepartamento={pendencias_departamento}
                                        titleMinhas={"Minhas"}
                                        linkMinhas={"/agenda"}
                                        titleDepartamento={"Unidade de negócio"}
                                        linkDepartamento={"/agenda"}

                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: "40px" }}>
                                <Paper className={classes.paper}>
                                    <InfoCard title={'PARLAMENTARES'} />
                                    <Grid xs={6}>
                                        <NumbersPanelWidget
                                            value={parlamentares}
                                            title={"Seguidos"}
                                            link={"/agenda"}
                                        />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: "40px", paddingLeft: "40px" }}>
                                <Paper className={classes.paper}>
                                    <InfoCard title={'EM TRAMITAÇÂO'} />
                                    <Grid item xs={6}>
                                        <NumbersPanelWidget
                                            value={em_tramitacao}
                                            title={"Proposições"}
                                            link={"/agenda"}
                                        />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                                <PieChartInfoCard
                                    title="UNIVERSO"
                                    labels={[
                                        { key: "Ativos", data: universo_ativosValue_chart, color: `${pallet.color.charts.green}` },
                                        { key: "Inativos", data: universo_inativosValue_chart, color: `${pallet.color.charts.red}` },
                                    ]}
                                />
                            </Grid>
                            {chartRenderFederal === 'Federal' ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: "40px" }}>
                                    <PieChartInfoCard
                                        title="CASAS LEGISLATIVAS"
                                        labels={[
                                            { key: "Câmara dos Deputados", data: casasLegislativas_camaraDosDeputadosValue_chart, color: `${pallet.color.charts.green}` },
                                            { key: "Senado Federal", data: casasLegislativas_senadoFederalValue_chart, color: `${pallet.color.charts.purple}` },
                                            { key: "Congresso Nacional", data: casasLegislativas_congressoNacionalValue_chart, color: `${pallet.color.charts.red}` },
                                        ]}
                                    />
                                </Grid>
                                :
                                null
                            }
                            <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }}>
                                <PieChartInfoCard
                                    title={"AÇÔES DE INFLUÊNCIA"}
                                    labels={[
                                        { key: "Êxito", data: acoesInfluenciaExito, color: `${pallet.color.charts.green}` },
                                        { key: "Êxito Parcial", data: acoesInfluenciaExitoParcial, color: `${pallet.color.charts.purple}` },
                                        { key: "Sem Êxito", data: acoesInfluenciaSemExito, color: `${pallet.color.charts.red}` },
                                        { key: "Pendente", data: acoesInfluenciaPendente, color: `${pallet.color.charts.yellow}` },
                                    ]}
                                />
                            </Grid>
                            {chartRenderFederal === 'Federal' ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: "40px" }}>
                                    <PieChartInfoCard
                                        title={"MOMENTOS CRÍTICOS"}
                                        labels={[
                                            { key: "Aguardando Designação do Relator", data: momentosCriticosdesignacaoRelator, color: `${pallet.color.charts.green}` },
                                            { key: "Prazo para Emendas", data: momentosCriticosprazoParaEmenda, color: `${pallet.color.charts.purple}` },
                                            { key: "Aguardando Parecer do Relator", data: momentosCriticosaguardanoParecerDoRelator, color: `${pallet.color.charts.red}` },
                                            { key: "Aguardando Deliberação", data: momentosCriticosaguardaDeliberacao, color: `${pallet.color.charts.yellow}` },
                                        ]}
                                    />
                                </Grid>
                                :
                                null
                            }
                            {chartRenderFederal === 'Federal' ?
                                <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px" }} >
                                    <PieChartInfoCard
                                        title={"QUALIFICAÇÂO PARLAMENTAR"}
                                        labels={[
                                            { key: "Convergente", data: qualificacaoParlamentarconvergente, color: `${pallet.color.charts.green}` },
                                            { key: "Não Classificado", data: qualificacaoParlamentarnaoClassificado, color: `${pallet.color.charts.purple}` },
                                            { key: "Divergente", data: qualificacaoParlamentardivergente, color: `${pallet.color.charts.red}` },
                                            { key: "Neutro", data: qualificacaoParlamentarneutro, color: `${pallet.color.charts.yellow}` },
                                        ]}
                                    />
                                </Grid>
                                :
                                null
                            }
                            <Grid item xl={6} lg={6} xs={12} style={{ paddingTop: "40px", paddingLeft: "40px" }}>
                                <PieChartInfoCard
                                    title={"POSICIONAMENTO"}
                                    labels={[
                                        { key: "Favorável ao parecer do relator", data: posicionamentofavoravelParecerRelator, color: '#C71585' },
                                        { key: "Favorável ao parecer do relator com emenda", data: posicionamentofavoravelParecerRelatorEmenda, color: '#B8860B' },
                                        { key: "Contrário ao parecer do relator", data: posicionamentocontrarioParecerRelator, color: '#facb74' },
                                        { key: "Contrário ao parecer do relator com emenda", data: posicionamentocontrarioParecerRelatorEmenda, color: '#000000' },
                                        { key: "Favorável", data: posicionamentofavoravel, color: `${pallet.color.charts.green}` },
                                        { key: "Favorável com Emenda", data: posicionamentofavoravelComEmenda, color: `${pallet.charts.blue}` },
                                        { key: "Contrário", data: posicionamentocontrario, color: `${pallet.color.charts.red}` },
                                        { key: "Contrário com Emenda Saneadora", data: posicionamentocontrarioComEmenda, color: `${pallet.charts.orange}` },
                                        { key: "Pendente", data: posicionamentopendente, color: `${pallet.color.charts.yellow}` },
                                        { key: "Neutro", data: posicionamentoneutro, color: `${pallet.charts.purple}` },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Box pt={4}>
                            <Copyright />
                        </Box>
                    </>
                </Main>
            </div>
    );
}