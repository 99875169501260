
import React from 'react';
// import _ from 'lodash';
import { Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import blankAvatar from '../../../img/avatar_foto.jpg';
import { IParlamentar } from '../../../models/Parlamentar.Model';
import { usePallet } from '../../../contexts/PalletContext';
import CircularLoading from '../../../components/CircularLoading';
import ImageLoader from '../../../components/ImageLoader';
import { IUser } from '../../../models/User.Model';

const GerUsuariosConsultaHeader = (props: { usuario: IUser }): JSX.Element => {
    const { pallet } = usePallet();
    return (
        <div
            style={{
                // backgroundImage:
                //     `${props.usuario?.cliente?.qualificacao?.toLowerCase() === "convergente" ? pallet.color.ParlamentarQualificacao.convergente :
                //         props.usuario?.cliente?.qualificacao?.toLowerCase() === "divergente" ? pallet.color.ParlamentarQualificacao.divergente :
                //             props.usuario?.cliente?.qualificacao?.toLowerCase() === "neutro" ? pallet.color.ParlamentarQualificacao.neutro :
                //                 pallet.color.ParlamentarQualificacao.naoClassificado
                //     }`,
                backgroundImage:
                `${pallet.color.ParlamentarQualificacao.convergente}`,
                height: "95px",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px"
            }}>
            <Grid container justifyContent="flex-start">
                <div style={{
                    marginLeft: `${isMobile ? "15px" : "50px"}`,
                    marginTop: "30px",
                    }}>
                    <ImageLoader
                        src={props.usuario?.arquivo}
                        pallet={pallet}
                        canEdit={false}
                    />
                </div>
            </Grid>
        </div>
    )

}

export default GerUsuariosConsultaHeader;