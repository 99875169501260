import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import TabPanel from "../../../components/carousel/TabPanel";
import { usePallet } from "../../../contexts/PalletContext";
import ParlamentarComissoesDados from "./ParlamentarComissoesDados";
import ParlamentarComissoesComissao from "./ParlamentarComissoesComissao";
import { IParlamentar } from "../../../models/Parlamentar.Model";

const ParlamentarCarouselTabsComissoes = (props?: { id_parlamentar?: number, activeTab?: any, index?: number,query: string, parlamentar?: IParlamentar, permissaoUsuario?: number }) => {
    const { pallet } = usePallet();

    const [changeDadosOuComis, setChangeDadosOuComis] = React.useState(props.query ? "comissoes" : "dadoscomissao")

    const trocaParaDadosOuComissao = (key: string) => {
        setChangeDadosOuComis(key)
    }
  
    return (
        <Paper style={{ borderRadius: "6px", marginTop: `${isMobile  ? "0px" : props.permissaoUsuario !== 4 ? "-30px" : "-3px"}` }}>
            <TabPanel value={props.activeTab} index={props.index}>
                <Grid container style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                    <Grid alignItems="flex-start" style={{ marginRight: "20px" }}>
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuComissao}
                            tipo={"dadoscomissao"}
                            backgroundColor={changeDadosOuComis === "dadoscomissao" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuComis === "comissoes" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuComis === "comissoes" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"DADOS"}
                        />
                    </Grid>
                    <Grid alignItems="flex-start">
                        <ButtonIconSalvar
                            onClick={trocaParaDadosOuComissao}
                            tipo={"comissoes"}
                            backgroundColor={changeDadosOuComis === "comissoes" ? `${pallet.backgroundColorPrimary}` : `${pallet.backgroundColorTertiary}`}
                            border={`1px solid ${changeDadosOuComis === "dadoscomissao" ? "lightgrey" : ""}`}
                            fontColor={changeDadosOuComis === "dadoscomissao" ? "gray" : `${pallet.textColorPrimary}`}
                            fontSize={`${pallet.general.size12}`}
                            icon={""}
                            title={"COMISSÕES"}
                        />
                    </Grid>
                </Grid>
                {
                    changeDadosOuComis === "dadoscomissao" ?
                        <ParlamentarComissoesDados id_parlamentar={props.id_parlamentar} parlamentar={props.parlamentar} /> :
                        <ParlamentarComissoesComissao id_parlamentar={props.id_parlamentar} query={props.query} />
                }
            </TabPanel>
        </Paper>
    )
}

export default ParlamentarCarouselTabsComissoes;