import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { usePallet } from "../../../contexts/PalletContext";
import useStyles from "../../../components/layout/Styles";
import UploadFilesService from "../../../services/UploadFiles.service";
import filtersBackgroundImage from "../../../../src/img/background_filters_dashboard.png";
import { isMobile } from "react-device-detect";
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { download } from "../../../helpers/http";
import { useAlert } from "../../../contexts/AlertContext";
import CircularLoading from "../../../components/CircularLoading";
import Main from "../../main/Main";






//## StyledCompenents
const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styled(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;

const ContainerFilter = styled(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;


const FilterButton = styled(Button)`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	text-transform: capitalize;
	color: #FFFFFF;
	border: 0;
	font-size: 14px;
	line-height: 17px;	
	border-radius: 6px;
	padding: 10px;

	height: 100%;
  background: rgba(75, 17, 150, 0.9);
	:first-child {
		margin-right: 10px;
	}
  &:hover {
    background-color:  rgba(75, 17, 150, 0.9);
    color: #FFFFFF;
   
   }
`;

const ContainerBody = styled(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;


const TitleText = styled(Typography)`
    color: ${(props) => props.pallet.textColorQuaternary};
    font-weight: ${(props) => props.pallet.general.weight_bold};
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1px;
    `;

export default function GerUsuariosTabCentralAjuda(props: { id_user: number, index: number, activeTab: number, onChange: () => void }) {
  const { pallet } = usePallet();
  const [loadingProposicaoLista, setLoadingProposicaoLista] = useState(false);
  const [loadingDashboardProposicao, setLoadingDashboardProposicao] = useState(false);
  const [loadingProposicaoEspecifica, setLoadingProposicaoEspecifica] = useState(false);
  const [loadingCadastrarInterlocutor, setLoadingCadastrarInterlocutor] = useState(false);
  const [loadingCentralNotificacao, setLoadingCentralNotificacao] = useState(false);
  const [loadingDashboardInicial, setLoadingDashboardInicial] = useState(false);
  const [loadingClassificacaoTemas, setLoadingClassificacaoTemas] = useState(false);
  const [loadingGeranciamentoUsuario, setLoadingGeranciamentoUsuario] = useState(false);
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [loadingCitacoes, setLoadingCitacoes] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [loadingInterlocutorEspecifico, setLoadingInterlocutorEspecifico] = useState(false);
  const [loadingRelatorios, setLoadingRelatorios] = useState(false);
  const [loadingFavoritosNew, setLoadingFavoritosNew] = useState(false);
  const { NewAlert } = useAlert();
  const classes = useStyles();



  const downloadRoot = async (nome: string, setLoading = (e) => e) => {
    NewAlert('info', `Download do arquivo ${nome} em andamento...`);
    setLoading(true);
    UploadFilesService.download(nome, true)
      .then((resp) => {
        if (resp.status === 200) {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link?.setAttribute("download", nome);
          document.body.appendChild(link);
          link?.click();
          link?.parentNode?.removeChild(link);
          NewAlert('success', `Download do arquivo ${nome} finalizado com sucesso!`);
          setLoading(false);
        } else {
          NewAlert('error', `Erro ao realizar o download do arquivo ${nome}.`);
          setLoading(false);
        }
      }).catch((err) => {
        NewAlert('error', `Erro ao realizar o download do arquivo ${nome}.`);
        console.error(err);
        setLoading(false);
      });
  }

  return (
    <div className={classes.root}>
      <Grid maxWidth={"100%"} style={{ padding: '50px', backgroundColor: 'white' }} container>
        <Grid item xs={12} key="TitleText">
          <TitleText pallet={pallet}>Manual Legisnote - V011124</TitleText>
        </Grid>

        <Grid container style={{ paddingTop: "30px" }}>
          <Grid
            item
            container
            xs={12}
            style={{
              padding: "25px",
            }}
          >
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingProposicaoLista ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('01_Módulo proposição - Lista.pptx', setLoadingProposicaoLista); }} href="#" underline="hover">
                  {'Módulo proposição - Lista'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingDashboardProposicao ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('02_ Módulo proposição - Dashboard de proposição.pptx', setLoadingDashboardProposicao); }} href="#" underline="hover">
                  {'Módulo proposição - Daschboard Proposição'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingProposicaoEspecifica ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('03_ Módulo proposição - Específica.pptx', setLoadingProposicaoEspecifica); }} href="#" underline="hover">
                  {'Módulo proposição - Proposição Específica'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingAgenda ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Agenda.pptx', setLoadingAgenda); }} href="#" underline="hover">
                  {'Módulo Agenda - Agenda'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingCadastrarInterlocutor ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Cadastrar Interlocutor.pptx', setLoadingCadastrarInterlocutor); }} href="#" underline="hover">
                  {'Módulo Cadastrar Interlocutor - Cadastro'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingCentralNotificacao ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Central de Notificações.pptx', setLoadingCentralNotificacao); }} href="#" underline="hover">
                  {'Módulo Notificação - Central de Notificação'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingClassificacaoTemas ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Classificação de temas.pptx', setLoadingClassificacaoTemas); }} href="#" underline="hover">
                  {'Módulo Temas - Classificação'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingDashboardInicial ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Dashboard Incial.pptx', setLoadingDashboardInicial); }} href="#" underline="hover">
                  {'Módulo Dashboard - Dashboard Inicial'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingCitacoes ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo de Citações.pptx', setLoadingCitacoes); }} href="#" underline="hover">
                  {'Módulo Citações'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingGeranciamentoUsuario ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Gerenciamento de Usuário.pptx', setLoadingGeranciamentoUsuario); }} href="#" underline="hover">
                  {'Módulo Usuário - Gerenciamento de Usuário'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingInsights ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Insights.pptx', setLoadingInsights); }} href="#" underline="hover">
                  {'Módulo Insights'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingInterlocutorEspecifico ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Interlocutor Especifico.pptx', setLoadingInterlocutorEspecifico); }} href="#" underline="hover">
                  {'Módulo Interlocutor - Interlocutor Específico'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingRelatorios ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulo Relatórios.pptx', setLoadingRelatorios); }} href="#" underline="hover">
                  {'Módulo Relatórios'}
                </Link>
              }
            </Grid>
            <Grid item style={{ height: '50px', width: '100%', marginBottom: '20px', display: `${isMobile ? "block" : "flex"}` }}>
              {loadingFavoritosNew ? <Grid style={{ margin: '0 auto' }}><CircularLoading size={50} zIndex={2} positionCustom={true} /> </Grid> :
                <Link onClick={() => { downloadRoot('Módulos _Favoritos New.pptx', setLoadingFavoritosNew); }} href="#" underline="hover">
                  {'Módulo Favoritos - New'}
                </Link>
              }
            </Grid>


          </Grid>
        </Grid>
      </Grid>
    </div >
  )
}