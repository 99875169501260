import * as Yup from "yup";

const TCCadastroGruposCorporativoSchema = Yup.object({

    descricao: Yup.string().required({ name: "nome", err: "Nome é obrigatório"}),
    ativo: Yup.bool().required({ name: "status", err: "Status é obrigatório" }),
});


export default TCCadastroGruposCorporativoSchema;
