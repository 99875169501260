import * as Yup from "yup";

const TCTemaCorporativoTopicoCreateSchema = Yup.object({
    descricao: Yup.string().required({ name: "nome", err: "Campo com preenchimento obrigatório" }),
    id_tema_grupo: Yup.number().required({ name: "grupo", err: "Campo com preenchimento obrigatório" }),
    id_tema: Yup.number().required({ name: "tema", err: "Campo com preenchimento obrigatório" }),
    id_subtema: Yup.number().required({ name: "subtema", err: "Campo com preenchimento obrigatório" }),
    id_topico: Yup.number(),
    ativo: Yup.boolean().required({ name: "prioridade", err: "Campo com preenchimento obrigatório" }),
});


export default TCTemaCorporativoTopicoCreateSchema;