import * as http from '../helpers/http';
import { format } from 'date-fns'
import { IDashboard } from '../models/DashBoard.Model';



class DashboardContextService {
    execute = async (dtIni: Date, dtFin: Date): Promise<IDashboard> => {
        const result = await http.get('/dashboard/' + format(dtIni, 'yyyy-MM-dd') + '/' + format(dtFin, 'yyyy-MM-dd'));
        if (result.success) { return result.data }
        return undefined;
    };
}

export default new DashboardContextService();
