import React, { useEffect, useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { readStore, writeStore } from '../helpers/persistence';

export default function PaginationSaveState(props: { name: string, page: number, pages: number, onChange: (page: number) => void }): JSX.Element {

    const [currentPage, setCurrentPage] = useState<number>(0);

    useEffect(() => {
        readStore(props.name).then(page => {
            setPage(undefined, page)
        })
    }, [])

    useEffect(() => {
        props.onChange(currentPage);
    }, [currentPage]);


    useEffect(() => {
        setPage(undefined, props.page)
    }, [props.page]);

    const setPage = (event: object, page: number) => {
        let p = page;
        if (!p || p > props.pages) p = 1;
        if (p === currentPage) return;
        writeStore(props.name, p).then(() => setCurrentPage(p))
    }

    return (
        <Stack spacing={2} >
            {props.pages > 1 ?
                <Pagination
                    size={isMobile ? "small" : "large"}
                    onChange={setPage}
                    count={props.pages}
                    page={currentPage}
                    shape="rounded"
                /> : <></>}
        </Stack>
    )
}

