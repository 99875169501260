import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import ComboBox from "../../../../components/ComboBox";
import { usePallet } from "../../../../contexts/PalletContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconLabelButtons from '../../../../components/ButtonIconSalvar';
import { IListInfo } from '../../../../models/IListInfo';
import EmptyError from '../../../../components/EmptyError';
import PaginationSaveState from '../../../../components/PaginationSaveState';
import { useNavigate } from "react-router-dom";
import TemaCorporativoCard from "./TemaCorporativoCard";
import { IFilterData } from "../../../../components/filter/ListFilter";
import TCService from "../../../../services/TCService";
import ButtonIconSalvar from "../../../../components/ButtonIconSalvar";

//## StyledCompenents
const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styled(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;

const ContainerFilter = styled(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;

export default function TCTemasCorporativosGrupoList() {
  const { pallet } = usePallet();
  const navigate = useNavigate();

  //## Paginação
  const [infoList, setInfoList] = useState<IListInfo>({
    pageIndex: 1,
    pageSize: 8,
    pageItens: 0,
    totalItens: 0,
    totalPages: 0,
  });
  const [currentPageSize, setCurrentPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState({ key: "visualizacao", label: "Todos", value: "T" });
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const filterName = 'TemasRelacionadosListCurrentPage';
  // const filterItens = [
  //   { key: "visualizacao", label: "Tudo", value: "T" },
  //   { key: "visualizacao", label: "Relacionados", value: "R" },
  //   { key: "visualizacao", label: "Não Relacionados", value: "NR" },
  // ]

  //## Dados
  const [lista, setLista] = useState([]);
  const [listaDescricao, setListaDescricao] = useState<string[]>([]);
  const [busca, setBusca] = useState<IFilterData[]>();
  const [descricaoParcial, setDescricaoParcial] = useState<string>("");

  useEffect(() => {
    if (descricaoParcial && descricaoParcial.trim().length > 2) {
      TCService.getGrupos(currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "descricaoP", value: descricaoParcial }]))
        .then(async res => {
          if (res) {
            const data = [];

            res.data.map(item => data.push({
              key: item.grupo_id,
              //label: item.descricao + (item.casa === 1 ? ' [Senado Federal]' : (item.casa === 2 ? ' [Câmara dos Deputados]' : '')),
              label: item.grupo_descricao,
              value: item.grupo_id
            }))

            setListaDescricao(data.map(item => { return item.label }));
            // setListaDescricao(() => [...data]);
            setBusca(() => [...data]);
          }
        });
    } else if (descricaoParcial === "") {
      loadGrupoList();
    }
  }, [descricaoParcial]);

  useEffect(() => {
    if (currentPage > 0) loadGrupoList();
  }, [currentPage, currentPageSize, currentFilter]);

  const loadGrupoList = () => {
    if (filter) {
      //currentFilter = { "key": "visualizacao", "value": filter.value }
    }    

    TCService.getGrupos(currentPageSize, currentPage, JSON.stringify(currentFilter)) // force break
      .then(async (res) => {        

        if (res) {
          setLista(res.data);
          setInfoList(res.listInfo);
        }
      });
  };

  //## Funções'
  const handleOnChangeItensPerPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageSize(Number(event.target.value));
  };

  const handleOnChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter: IFilterData = JSON.parse(event.target.value);
    setFilter(selectedFilter);
    setCurrentFilter([...currentFilter.filter(x => x.key !== selectedFilter.key), selectedFilter]);
  };

  const handleOnSelectDescricaoParcial = async (tema: string) => {
    const selecao = busca.filter(x => x.label === tema);
    if (selecao.length) {
      //navigate(`/tc/grupo/${selecao[0].value}`);

      //Filtra por id

      await TCService.GetGrupo(selecao[0].value).then(res => {
        
        //setChecked(res?.data[0].grupo_ativo)
        //setNome(res?.data[0].grupo_descricao)
        //setIdRegistro(res?.data[0].grupo_id)

        if (res) {
          setLista(res.data);
          setInfoList(res.listInfo);
        }
      });


    }
  };

  return (
    <>
      <ContainerHead container>
        <Grid container>          
        </Grid>
        <Grid
          style={{
            width: isMobile ? "60%" : "100%",
          }}
        />

        {/* <ContainerFilter style={{  }}>
          <FilterArea>
            <Grid style={{ width: `${isMobile ? "70%" : "65%"}`, display: "flex", alignItems: "center" }}>              
              <strong>
                <span style={{ color: pallet.general.color1 }}>
                  {infoList?.totalItens ? infoList?.totalItens : "1"}
                </span> Grupo(s)
              </strong>
            </Grid>
            <Grid style={{ width: `${isMobile ? "30%" : "35%"}`, display: "flex", justifyContent: "flex-end" }}>
              
              <ButtonIconSalvar
                onClick={() => navigate('/tc/cadastrar/G')}
                tipo={"cadastrar_grupo"}
                backgroundColor={pallet.backgroundColorPrimary}                                
                border={"1px solid lightgrey"}
                fontColor={pallet.textColorPrimary}
                fontSize={pallet.general.size12}
                icon={""}
                title={"CADASTRAR"}
              />

            </Grid>
          </FilterArea>
        </ContainerFilter> */}
        <Grid style={{ width: "100%", margin: "20px 40px" }}>
          <ComboBox
            name="filtroTCorporativo"
            itens={listaDescricao}
            onChange={setDescricaoParcial}
            onSelect={handleOnSelectDescricaoParcial}
            placeholder="Buscar"
          />
        </Grid>
      </ContainerHead>
      <ContainerBody container style={{ marginTop: "20px" }}>
        {lista &&
          lista.map((item) => (
            <TemaCorporativoCard
              tema_id={item.grupo_id}
              tema_descricao={item.grupo_descricao}
              tema_seativo={item.grupo_ativo}
              onChange={loadGrupoList}

            />
          ))}
        {!lista.length && (
          <PaperContent pallet={pallet}>
            <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" />
          </PaperContent>
        )}
      </ContainerBody>
      <ContainerFooter container>
        <PaginationSaveState
          name={filterName}
          pages={infoList?.totalPages}
          page={currentPage}
          onChange={setCurrentPage}
        />
      </ContainerFooter>
    </>
  )
}