import React from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import { Grid, Button, Icon, Menu, FormControl, Divider } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { usePallet } from '../../../contexts/PalletContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useStyles from '../../../components/layout/Styles';


export default function GerUsuariosMenuFiltroAtivoInativo(props: { title: string, icon: any, onChange: (value) => void }) {
    const classes = useStyles();
    const { pallet } = usePallet();

    const Tudo = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Todos
            </div>
        )
    }
    const Ativo = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Usuários ativos
            </div>
        )
    }
    const Inativo = () => {
        return (
            <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}`, color: `${pallet.color.tertiary.font}` }}>
                Usuários inativos
            </div>
        )
    }

    return (
        <Grid>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button
                            {...bindTrigger(popupState)}
                            // onClick={openDatePicker}
                            variant="contained"
                            size="large"
                            style={{
                                textTransform: 'uppercase',
                                fontFamily:`${pallet.fontFamily.general}`,
                                fontSize:`${pallet.general.size12}`,
                                fontWeight:pallet.general.weight_bold,
                                backgroundColor:`${pallet.backgroundColorQuintenary}`,

                                color:`${pallet.textColorTertiary}`
                            }}
                            startIcon={
                                <Icon style={{
                                    color: `${pallet.color.secundary.font}`
                                }}
                                >{props.icon}</Icon>}
                        >
                            {props.title}
                        </Button>
                        {/* {props.itens.map(item, index => {
                            <Menu {...bindMenu(popupState)} style={{ marginTop: "50px", }}>
                                <MenuItem key={index} onClick={popupState.close} style={{
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.lighter,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                }}>{item[index]}</MenuItem>
                            </Menu>
                        })} */}
                        <Menu {...bindMenu(popupState)} >
                            <FormControl component="fieldset" onChange={props.onChange} style={{ minWidth: "195px" }}>
                                {/* <FormLabel component="legend">Gender</FormLabel> */}

                                <RadioGroup
                                    aria-label="gender"
                                    defaultValue="tudo"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value="T" control={<Radio onClick={popupState.close} sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Tudo />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value="A" control={<Radio onClick={popupState.close} sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Ativo />} />
                                    <Divider style={{ margin: "3px 0px 3px 0px" }} />
                                    <FormControlLabel style={{ paddingLeft: "20px" }} value="I" control={<Radio onClick={popupState.close} sx={{ color: `${pallet.color.quaternary.font}`, '&.Mui-checked': { color: `${pallet.backgroundColorPrimary}` }, }} />} label={<Inativo />} />
                                </RadioGroup>
                            </FormControl>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>

        </Grid >


    );
}