import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import DoughnutChartParlamentarComissoes from "./DoughnutChartParlamentarComissoes";
import ParlamentarAmbitoService from "../../../services/ParlamentarAmbitoService";
import styled from "styled-components";
import { IParlamentar } from "../../../models/Parlamentar.Model";

export default function ParlamentarComissoesDados(props: { id_parlamentar: number, parlamentar?: IParlamentar }) {
    const { pallet } = usePallet();
    const [sumary, setSumary] = useState({ titular: 0, suplente: 0, outros: 0 });
    const [sumaryList, setSumaryList] = useState({ listTit: [], listSup: [], listOut: [] });
    const [loading, setLoading] = useState(true);
    const [comissaoList,setComissaoList] =useState([])
    useEffect(() => {
        getParlamentarAmbitoSummary();
        // eslint-disable-next-line 
    }, [props.id_parlamentar])

    const getParlamentarAmbitoSummary = useCallback(() => {
        ParlamentarAmbitoService.GetSummary(props.id_parlamentar).then(async res => {
            if (res) {
                setSumary(res.res);
                setSumaryList(res.listComissoes)
                setLoading(false)

            }

        })

        ParlamentarAmbitoService.GetSummaryList(props.id_parlamentar).then(async res => {
            if (res) {
                setComissaoList(res)

            }

        })
    }, [props.id_parlamentar])

    const Title = styled(Grid)`
    padding-left:  ${isMobile ? '5px' : '24px'};
    padding-top: ${isMobile ? '5px' : '30px'} ;
    background-color: ${pallet.backGroundPageColorPrimary};
    text-transform: uppercase;
    font-size:${pallet.general.size12};
    color: ${pallet.textColorQuaternary};
    font-family: ${pallet.general.fontFamily};
    font-weight: ${pallet.general.weight_600};
    height: 79px;
    place-content:  ${isMobile ? 'center' : 'unset'};
`;


    const getYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    };
    return (

        <div>
            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                    <DoughnutChartParlamentarComissoes
                        loading={loading}
                        masterTitle={"PARTICIPAÇÃO EM COMISSÕES"}
                        titleChart={JSON.stringify(getYear())}
                        total={0}
                        seguindo={0}
                        labels={[
                            { key: "Titular", data: sumary?.titular, color: `${pallet.charts.green}` },
                            { key: "Suplente", data: sumary?.suplente, color: `${pallet.charts.red}` },
                        ]}
                        labelToolTip={[sumaryList?.listSup, sumaryList?.listTit, sumaryList?.listOut]}
                    />
                </Grid>
            </Grid>

            <Grid>
            {comissaoList.length > 0 ?
            <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
            <Title container style={{
              borderTop: '1px solid #EAEAEA',
              borderLeft: '1px solid #EAEAEA',
              borderBottom: '1px solid #EAEAEA',
              borderRight: `${isMobile ? '1px solid #EAEAEA' : 0}`,
              borderRadius: '6px 0px 0px 0px'
            }}
              item lg={6} xs={6}>
              NOME DA COMISSÃO
            </Title>
            <Title container item lg={6} xs={6}
              style={{
                borderTop: '1px solid #EAEAEA',
                borderRight: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRadius: '0px 6px 0px 0px'
              }}>
              PARTICIPAÇÃO
            </Title>
            <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
              {comissaoList.map((x, i) => {
                return (
                  <>
                    <Grid container lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderLeft: '1px solid #EAEAEA',
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== comissaoList.length ? null : '0px 0px 0px 6px',
                      maxWidth: 'none',
                      wordBreak: 'break-word',
                    }}>
                      <Grid item
                     style={{
                          paddingBottom: '10px',
                          color: `${pallet.textColorQuaternary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size12}`,
                          fontWeight: pallet.general.weight_600,
                          }}
                      >
                        {x.nome}
                      </Grid>

                    </Grid>
                    <Grid container item lg={6} xs={6}
                      style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "5px" : "15px"}`,
                        borderRight: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRadius: i + 1 !== comissaoList.length ? null : '0px 0px 6px 0px',
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size12}`,
                        fontWeight: pallet.general.weight_600,
                        placeContent: `${isMobile ? 'center' : 'unset'}`

                      }}>
                      {x.participacao}
                    </Grid>
                  </>
                );
              })}

            </Grid>
          </Grid>
          : null }
            </Grid>

        </div>
    )
}