import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, TextField, Typography } from '@mui/material';
import useStyles from '../../../components/layout/Styles';
import { usePallet } from '../../../contexts/PalletContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobile } from 'react-device-detect';
import { CalendarToday } from '@material-ui/icons';
import { formatDate } from '../../../utils/dateUtils';
import ParlamentarDiscursosModal from './ParlamentarDiscursosModal';
import { useState } from "react";

// const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

function AccordionTextDataOutrosParlamentarLideranca(props: { lista: Array<any> }) {

    const { pallet } = usePallet();
    const classes = useStyles();
    const [qualificacao, setQualificacao] = useState('não classificado');

    // React.useEffect(() => {
    //     if (props.lista?.cliente?.qualificacao) setQualificacao(props.lista. .cliente.qualificacao.toLowerCase());
    //     // eslint-disable-next-line 
    // }, [props.lista])

    // React.useEffect(() => {
    //     if (props.onChange) props.onChange()
    //     // eslint-disable-next-line
    // }, [qualificacao])


    const handleQualificacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // ParlamentarService.SetQualificacao(Number(props.parlamentar.id), event.target.value).then(x => {
        // ParlamentarService.SetQualificacao(Number(1892), event.target.value).then(x => {
        //     setQualificacao(event.target.value);
        //     NewAlert('success', 'Registro Salvo com sucesso');
        // });
    };

    return (
        <>
            {props.lista?.map(item => {
                return (


                    <Grid style={{ marginBottom: "10px" }}>

                        <Accordion expanded elevation={0} style={{ border: "1px solid #EAEAEA" }} >
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    // backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                    height: "10px",
                                    borderRadius: "5px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}
                            >

                                <Grid container>

                                    <Typography className={classes.heading} style={{
                                        color: `${pallet.textColorTertiary}`,
                                        fontFamily: `${pallet.general.fontFamily}`,
                                        fontSize: `${pallet.general.size14}`,
                                        fontWeight: pallet.general.weight_600,
                                        marginTop: "4px"
                                    }}>
                                        {item.partido}
                                    </Typography>


                                </Grid>

                            </AccordionSummary>

                            <Divider />

                            <AccordionDetails style={{
                                fontFamily: `${pallet.general.fontFamily}`
                            }}>
                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 15px 0px 5px"}`, width: "100%" }}>
                                    <Grid xl={12}>
                                        <Grid xl={12} container style={{ paddingTop: "10px" }}>
                                            <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.tertiary.font}`
                                                }}>
                                                    POSIÇÃO
                                                </Grid>

                                                <Grid style={{ marginTop: "10px" }}>
                                                    {item.posicao}
                                                </Grid>


                                            </Grid>
                                            <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginLeft: "25px" }}>
                                                <Grid style={{
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    fontSize: `${pallet.fontSize.text_10}`,
                                                    color: `${pallet.color.tertiary.font}`
                                                }}>
                                                    PERÍODO
                                                </Grid>

                                                <Grid style={{ marginTop: "10px" }}>
                                                    {formatDate(new Date(item.inicio), 'dd/MM/yyyy')} a {formatDate(new Date(item.final), 'dd/MM/yyyy')}
                                                </Grid>

                                            </Grid>


                                        </Grid>
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid >
                )
            })}
        </>
    );
}

export default AccordionTextDataOutrosParlamentarLideranca;

function NewAlert(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
