import {
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useStyles from "../../components/layout/Styles";
import { usePallet } from "../../contexts/PalletContext";
import styled from "styled-components";
import Main from "../main/Main";
import filtersBackgroundImage from "../../../src/img/background_filters_dashboard.png";
import { isMobile } from "react-device-detect";
import CalendarOutlineIcon from "@mui/icons-material/CalendarTodayOutlined";
import DateRangeSelector from "../../components/DateRangeSelector";
import { subMonths, subYears } from "date-fns";
import TiposList, { IFiltroTipoProps } from "./_components/TiposLista";
import AccordionInsight from "./_components/AccordionInsight";
// import AccordionContentKey1 from "./_components/AccordionContentKey1";
// import AccordionContentKey2 from "./_components/AccordionContentKey2";
// import AccordionContentKey3 from "./_components/AccordionContentKey3";
// import AccordionContentKey4 from "./_components/AccordionContentKey4";
// import AccordionContentKey5 from "./_components/AccordionContentKey5";
// import AccordionContentKey6 from "./_components/AccordionContentKey6";
// import AccordionContentKey7 from "./_components/AccordionContentKey7";
// import AccordionContentKey8 from "./_components/AccordionContentKey8";
// import AccordionContentKey9 from "./_components/AccordionContentKey9";
// import AccordionContentKey10 from "./_components/AccordionContentKey10";
// import AccordionContentKey11 from "./_components/AccordionContentKey11";
// import AccordionContentKey12 from "./_components/AccordionContentKey12";
// import AccordionContentKey13 from "./_components/AccordionContentKey13";
// import AccordionContentKey14 from "./_components/AccordionContentKey14";
// import AccordionContentKey15 from "./_components/AccordionContentKey15";
// import AccordionContentKey16 from "./_components/AccordionContentKey16";
// import AccordionContentKey17 from "./_components/AccordionContentKey17";
// import AccordionContentKey18 from "./_components/AccordionContentKey18";


export default function Insights() {
  const classes = useStyles();
  const { pallet } = usePallet();
  const [dtIni, setDtIni] = React.useState<Date>(subMonths(new Date(), 6));
  const [dtFin, setDtFin] = React.useState<Date>(new Date());
  const [EsfSel, setEsfSel] = useState<string>("F");

  //TODO: VERIFICAR SE EXISTE OURTA FORMA DE APLICAR O FILTRO DE DATA

  // const [titles,setTitles] = React.useState<Array<IFiltroTipoProps>>([

  //   { key: "17", label: "CORRELAÇÂO DE FORÇAS NAS COMISSÕES", type: ["F"], origin: "INTERLOCUTOR", component: <AccordionContentKey17 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "1", label: "INTERLOCUTORES QUE MAIS RELATAM PROPOSIÇÕES", type: ["F"], origin: "INTERLOCUTOR", component: <AccordionContentKey1 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "2", label: "INTERLOCUTORES QUE MAIS PARTICIPAM DE CPI", type: ["F"], origin: "INTERLOCUTOR", component: <AccordionContentKey2 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "3", label: "TEMAS LEGISLATIVOS MAIS TRATADOS POR INTERLOCUTORES", type: ["F"], origin: "INTERLOCUTOR", component: < AccordionContentKey3 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "4", label: "INTERLOCUTORES QUE MAIS APRESENTAM PROJETOS POR POSICIONAMENTO", type: ["F", "E", "M"], origin: "INTERLOCUTOR", component: < AccordionContentKey4 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "5", label: "PARTIDOS QUE MAIS APRESENTAM PROJETOS POR POSICIONAMENTO", type: ["F", "E", "M"], origin: "INTERLOCUTOR", component: < AccordionContentKey5 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "6", label: "PRIORIDADE DE PROPOSIÇÕES POR GRUPO TEMA E SUBTEMA", type: ["F", "E", "M"], origin: "PROPOSIÇÃO", component: < AccordionContentKey6 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "7", label: "GRUPOS, TEMAS E SUBTEMAS QUE MAIS SOFRERAM MOVIMENTAÇÃO POR PERÍODO", type: ["F", "E", "M"], origin: "PROPOSIÇÃO", component: <AccordionContentKey7 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "8", label: "GRUPOS, TEMAS E SUBTEMAS | PRINCIPAIS INTERLOCUTORES AUTORES DAS PROPOSIÇÕES", type: ["F", "E", "M"], origin: "PROPOSIÇÃO", component: <AccordionContentKey8 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "9", label: "GRUPOS, TEMAS E SUBTEMAS | PRINCIPAIS PARTIDOS DOS AUTORES DAS PROPOSIÇÕES", type: ["F", "E", "M"], origin: "PROPOSIÇÃO", component: <AccordionContentKey9 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "10", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES CORRELATAS", type: ["F"], origin: "PROPOSIÇÃO", component: <AccordionContentKey10 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "11", label: "TEMAS LEGISLATIVOS QUE MAIS TEM RECEBIDO ATENÇÃO", type: ["F"], origin: "PROPOSIÇÃO", component: <AccordionContentKey11 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "12", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES POR TEMA LEGISLATIVO", type: ["F"], origin: "PROPOSIÇÃO", component: <AccordionContentKey12 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "13", label: "TEMAS CORPORATIVOS MAIS TRATADOS POR INTERLOCUTORES", type: ["E", "M"], origin: "INTERLOCUTOR", component: <AccordionContentKey13 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "14", label: "TEMAS CORPORATIVOS QUE MAIS TEM RECEBIDO ATENÇÃO", type: ["E"], origin: "PROPOSIÇÃO", component: <AccordionContentKey14 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "15", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES POR TEMA CORPORATIVO", type: ["E"], origin: "PROPOSIÇÃO", component: <AccordionContentKey15 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "16", label: "TEMAS CORPORATIVOS QUE MAIS TEM RECEBIDO ATENÇÃO", type: ["M"], origin: "PROPOSIÇÃO", component: <AccordionContentKey16 dtIni={dtIni} dtFin={dtFin} /> },
  //   { key: "18", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES POR TEMA CORPORATIVO", type: ["M"], origin: "PROPOSIÇÃO", component: <AccordionContentKey18 dtIni={dtIni} dtFin={dtFin} /> },

  // ]);


  const [titles, setTitles] = React.useState<Array<IFiltroTipoProps>>([

    { key: "17", label: "CORRELAÇÃO DE FORÇAS NAS COMISSÕES E NO PLENÁRIO", type: ["F"], origin: "INTERLOCUTOR" },
    { key: "1", label: "INTERLOCUTORES QUE MAIS RELATAM PROPOSIÇÕES", type: ["F"], origin: "INTERLOCUTOR" },
    { key: "2", label: "INTERLOCUTORES QUE MAIS PARTICIPAM DE CPI", type: ["F"], origin: "INTERLOCUTOR" },
    { key: "3", label: "TEMAS LEGISLATIVOS MAIS TRATADOS POR INTERLOCUTORES", type: ["F"], origin: "INTERLOCUTOR" },
    { key: "32", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS MAIS TRATADOS POR INTERLOCUTORES", type: ["F"], origin: "INTERLOCUTOR" },
    { key: "4", label: "INTERLOCUTORES QUE MAIS APRESENTAM PROJETOS POR POSICIONAMENTO", type: ["F"], origin: "INTERLOCUTOR" },
    { key: "5", label: "PARTIDOS QUE MAIS APRESENTAM PROJETOS POR POSICIONAMENTO", type: ["F"], origin: "INTERLOCUTOR" },
    { key: "6", label: "PRIORIDADE DE PROPOSIÇÕES POR GRUPO, TEMA, SUBTEMA E TÓPICO", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "7", label: "GRUPOS, TEMAS,  SUBTEMAS E TÓPICOS QUE MAIS SOFRERAM MOVIMENTAÇÃO POR PERÍODO", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "8", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS | PRINCIPAIS INTERLOCUTORES AUTORES DAS PROPOSIÇÕES", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "9", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS | PRINCIPAIS PARTIDOS DOS AUTORES DAS PROPOSIÇÕES", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "10", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES CORRELATAS", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "11", label: "TEMAS LEGISLATIVOS QUE MAIS TEM RECEBIDO ATENÇÃO", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "33", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICO QUE MAIS TEM RECEBIDO ATENÇÃO", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "12", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES POR TEMA LEGISLATIVO", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "34", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES POR GRUPOS, TEMAS, SUBTEMAS E TÓPICO", type: ["F"], origin: "PROPOSIÇÃO" },
    { key: "19", label: "INTERLOCUTORES QUE MAIS APRESENTAM PROJETOS POR POSICIONAMENTO", type: ["E"], origin: "INTERLOCUTOR" },
    { key: "20", label: "INTERLOCUTORES QUE MAIS APRESENTAM PROJETOS POR POSICIONAMENTO", type: ["M"], origin: "INTERLOCUTOR" },
    { key: "21", label: "PARTIDOS QUE MAIS APRESENTAM PROJETOS POR POSICIONAMENTO", type: ["E"], origin: "INTERLOCUTOR" },
    { key: "22", label: "PARTIDOS QUE MAIS APRESENTAM PROJETOS POR POSICIONAMENTO", type: ["M"], origin: "INTERLOCUTOR" },
    { key: "13", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS MAIS TRATADOS POR INTERLOCUTORES", type: ["E"], origin: "INTERLOCUTOR" },
    { key: "29", label: "GRUPOS, TEMAS,  SUBTEMAS E TÓPICOS MAIS TRATADOS POR INTERLOCUTORES", type: ["M"], origin: "INTERLOCUTOR" },
    { key: "14", label: "GRUPOS, TEMAS,  SUBTEMAS E TÓPICOS QUE MAIS TEM RECEBIDO ATENÇÃO", type: ["E"], origin: "PROPOSIÇÃO" },
    { key: "16", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS QUE MAIS TEM RECEBIDO ATENÇÃO", type: ["M"], origin: "PROPOSIÇÃO" },
    { key: "15", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES POR GRUPO, TEMA E SUBTEMA", type: ["E"], origin: "PROPOSIÇÃO" },
    { key: "18", label: "TEMPO MÉDIO DE TRAMITAÇÃO DE PROPOSIÇÕES POR GRUPO, TEMA E SUBTEMA", type: ["M"], origin: "PROPOSIÇÃO" },
    { key: "23", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS QUE MAIS SOFRERAM MOVIMENTAÇÃO POR PERÍODO", type: ["E"], origin: "PROPOSIÇÃO" },
    { key: "24", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS QUE MAIS SOFRERAM MOVIMENTAÇÃO POR PERÍODO", type: ["M"], origin: "PROPOSIÇÃO" },
    { key: "25", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS | PRINCIPAIS INTERLOCUTORES AUTORES DAS PROPOSIÇÕES", type: ["E"], origin: "PROPOSIÇÃO" },
    { key: "26", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS | PRINCIPAIS INTERLOCUTORES AUTORES DAS PROPOSIÇÕES", type: ["M"], origin: "PROPOSIÇÃO" },
    { key: "27", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS | PRINCIPAIS PARTIDOS DOS AUTORES DAS PROPOSIÇÕES", type: ["E"], origin: "PROPOSIÇÃO" },
    { key: "28", label: "GRUPOS, TEMAS, SUBTEMAS E TÓPICOS | PRINCIPAIS PARTIDOS DOS AUTORES DAS PROPOSIÇÕES", type: ["M"], origin: "PROPOSIÇÃO" },
    { key: "30", label: "PRIORIDADE DE PROPOSIÇÕES POR GRUPO, TEMA, SUBTEMA E TÓPICOS", type: ["E"], origin: "PROPOSIÇÃO" },
    { key: "31", label: "PRIORIDADE DE PROPOSIÇÕES POR GRUPO, TEMA, SUBTEMA E TÓPICOS", type: ["M"], origin: "PROPOSIÇÃO" },

  ]);

  const [tipo, setTipo] = React.useState<Array<IFiltroTipoProps>>([
    { key: "todos", label: "TODOS", active: true },
    { key: "proposicao", label: "PROPOSIÇÃO", active: false },
    { key: "interlocutor", label: "INTERLOCUTOR", active: false },
  ]);

  const [tipoAtual, SetTipoAtual] = React.useState("TODOS")
  const handleEsfera = (event, newSelEsf) => {
    setEsfSel(newSelEsf);

  };

  const onChangeDate = (dataInicio: Date, dataFinal: Date) => {
    setDtIni(dataInicio);
    setDtFin(dataFinal);

  };

  React.useEffect(() => { }, [tipo]);

  const onChangeTipo = (event: any) => {
    setTipo(
      tipo.map((x) => {
        if (x.label.toLowerCase() === event.target.title.toLowerCase()) {
          x.active = true;
          SetTipoAtual(event.target.title);
        }
        else if (x.active) x.active = false;
        return x;
      })
    );
  };

  const TitleText = styled(Typography)`
    color: ${(props) => props.pallet.textColorQuaternary};
    font-weight: ${(props) => props.pallet.general.weight_bold};
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1px;
  `;

  return (
    <div className={classes.root}>
      <Main>
        <>
          <Grid maxWidth={"100%"} container>
            <Grid item xs={12} key="TitleText">
              <TitleText pallet={pallet}>Insights</TitleText>
            </Grid>

            <Grid container style={{ paddingTop: "30px" }}>
              <Grid
                item
                container
                xs={12}
                style={{
                  backgroundImage: `url(${filtersBackgroundImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  padding: "25px",
                  borderRadius: "5px",
                  boxShadow:
                    "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                }}
              >
                <Grid item>
                  <Grid style={{ marginBottom: "5px" }}>
                    <Typography
                      style={{
                        color: `${pallet.color.secundary.font}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontWeight: pallet.fontWeight.subtitle,
                      }}
                    >
                      PERÍODO
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    direction="row"
                    style={{
                      maxWidth: `${isMobile ? "100%" : "300px"}`,
                      width: "250px",
                      background: "#4B1196",
                      borderRadius: "5px",
                      justifyContent: `${isMobile ? "flex-start" : "center"}`,
                    }}
                  >
                    <Grid item>
                      <CalendarOutlineIcon
                        style={{
                          color: `${pallet.color.primary.font}`,
                          marginTop: "6px",
                          fontSize: "17px",
                          marginRight: "0px",
                          marginLeft: `${isMobile ? "6px" : "5px"}`,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        paddingRight: "10px",
                        color: `${pallet.color.primary.font}`,
                      }}
                    >
                      <DateRangeSelector
                        startDate={dtIni}
                        endDate={dtFin}
                        onChange={onChangeDate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    marginLeft: `${isMobile ? "0px" : "30px"}`,
                    marginTop: `${isMobile ? "10px" : "0px"}`,
                  }}
                >
                  <Grid style={{ marginBottom: "5px" }}>
                    <Typography
                      style={{
                        color: `${pallet.color.secundary.font}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontWeight: pallet.fontWeight.subtitle,
                      }}
                    >
                      ESFERAS
                    </Typography>
                  </Grid>

                  <Grid container alignItems="center" direction="row">
                    <Grid item>
                      <Box display="block">
                        <Grid
                          style={{
                            backgroundColor: "#fcfcfc",
                            borderRadius: "4px",
                          }}
                        >
                          <ToggleButtonGroup
                            value={EsfSel}
                            exclusive={true}
                            onChange={handleEsfera}
                            aria-label="sphere"
                            size="small"
                          >
                            <ToggleButton
                              value="F"
                              aria-label="device"
                              sx={{
                                "&.Mui-selected": {
                                  color: `${pallet.textColorPrimary}`,
                                  backgroundColor: "#4B1196",
                                },
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  paddingLeft: "4px",
                                  paddingRight: "4px",
                                  fontFamily: `${pallet.fontFamily.general}`,
                                  fontWeight: pallet.general.weight_600,
                                }}
                              >
                                Federal
                              </Typography>
                            </ToggleButton>
                            <ToggleButton
                              value="E"
                              aria-label="device"
                              sx={{
                                "&.Mui-selected": {
                                  color: `${pallet.textColorPrimary}`,
                                  backgroundColor: `${pallet.backgroundColorPrimary}`,
                                },
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  paddingLeft: "4px",
                                  paddingRight: "4px",
                                  fontFamily: `${pallet.fontFamily.general}`,
                                  fontWeight: pallet.general.weight_600,
                                }}
                              >
                                Estadual
                              </Typography>
                            </ToggleButton>

                            <ToggleButton
                              value="M"
                              aria-label="device"
                              sx={{
                                "&.Mui-selected": {
                                  color: `${pallet.textColorPrimary}`,
                                  backgroundColor: `${pallet.backgroundColorPrimary}`,
                                },
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  paddingLeft: "4px",
                                  paddingRight: "4px",
                                  fontFamily: `${pallet.fontFamily.general}`,
                                  fontWeight: pallet.general.weight_600,
                                }}
                              >
                                Municipal
                              </Typography>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Paper
              style={{ borderRadius: "7px", width: "100%", marginTop: "35px" }}
            >
              {
                !isMobile ?

                  <Grid
                    style={{
                      borderRadius: "7px",
                      padding: "25px",
                      backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    }}
                  >
                    <TiposList filtros={tipo} onChange={onChangeTipo} />
                  </Grid>
                  :
                  <Grid container flex-direction={'column'} textAlign={'center'} display={'inline-grid'}
                    style={{
                      borderRadius: "7px",
                      padding: "25px",
                      backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    }}
                  >
                    <TiposList filtros={tipo} onChange={onChangeTipo} />
                  </Grid>

              }
              <Grid>
                <Divider
                  variant="fullWidth"
                  style={{
                    marginBottom: `${isMobile ? "15px" : "30px"}`,
                  }}
                />
              </Grid>
              <Grid style={{ marginBottom: "25px" }}>
                {
                  tipoAtual === "TODOS" ?
                    titles.filter(a => a?.type?.find(b => b === EsfSel)).map((x) => {
                      return (
                        <>
                          <Grid spacing={10}>
                            <AccordionInsight titleFederal={x.label} originFederal={x.origin} conteudo={x.component} onChange={EsfSel} onChangeII={tipoAtual} indice={x.key} dtIni={dtIni} dtFin={dtFin} />
                          </Grid>
                        </>
                      )
                    })
                    :
                    titles.filter(a => a?.type?.find(b => b === EsfSel)).filter(a => a.origin === tipoAtual).map((x) => {
                      return (
                        <>
                          <Grid spacing={10}>
                            <AccordionInsight titleFederal={x.label} originFederal={x.origin} conteudo={x.component} onChange={EsfSel} onChangeII={tipoAtual} indice={x.key} dtIni={dtIni} dtFin={dtFin} />
                          </Grid>
                        </>
                      )
                    })
                }
              </Grid>

            </Paper>
          </Grid>
        </>
      </Main>
    </div >
  );
}
