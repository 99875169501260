import * as http from '../helpers/http';


class NewsService {
  GetNewsList = async (data): Promise<any> => {
    const result = await http.post(`/midianews/list`, data);
    if (result.success) { return result.data }
    return undefined;
  };

  favorite = async (data): Promise<any> => {
    const result = await http.put(`/midianews/favoritar`, data);
    if (result.success) { return result }
    return undefined;
  };

  desfavorite = async (url: string): Promise<any> => {
    let urlobj = {'url' : url}
    const result = await http.put(`/midianews/desfavoritar`, urlobj);
    if (result.success) { return result }
    return undefined;
  };

  favoriteList = async (data): Promise<any> => {
    const result = await http.get(`/midianews/favoritos/list?filter=${data.filter}&pageSize=${data.pageSize}&pageIndex=${data.pageIndex}`);
    if (result.success) { return result.data }
    return undefined;
  };
};

export default new NewsService();
