import React from 'react';
import { useParams } from 'react-router-dom';
import ParlamentarService from '../../services/ParlamentarService';
import Main from '../main/Main';
import { Grid, Paper } from '@mui/material';
import useStyles from '../../components/layout/Styles';
import { IParlamentar } from '../../models/Parlamentar.Model';
import ParlamentarCarouselTabs from '../parlamentar/_components/ParlamentarCarouselTabs'
// import _ from 'lodash';
import ParlamentarConsultaHeader from '../parlamentar/_components/ParlamentarConsultaHeader';
import { ParlamentarConsultaBotoes } from '../parlamentar/_components/ParlamentarConsultaBotoes';
import { ParlamentarConsultaInicio } from '../parlamentar/_components/ParlamentarConsultaInicio';
import { ParlamentarConsultaInformacoes } from '../parlamentar/_components/ParlamentarConsultaInformacoes';
import { ParlamentarConsultaMenuParlamentar } from '../parlamentar/_components/ParlamentarConsultaMenuParlamentar';
import { useQuery } from '../../utils/utils';
import { GerUsuariosConsultaBotoes } from './_components/GerUsuariosConsultaBotoes';
import GerUsuariosConsultaHeader from './_components/GerUsuariosConsultaHeader';
import GerUsuariosCarouselTabs from './_components/GerUsuariosCarouselTabs';
import { GerUsuariosConsultaInicio } from './_components/GerUsuariosConsultaInicio';
import { GerUsuariosConsultaInformacoes } from './_components/GerUsuariosConsultaInformacoes';
import UsuarioService from '../../services/UsuarioService';
import { IUser } from '../../models/User.Model';
import { useAuthorization } from '../../contexts/AuthorizationContext';
import { AnyDate } from 'react-date-range';

export default function GerenciamentoUsuario(): JSX.Element {

    
    const classes = useStyles();
    const [usuario, setUsuario] = React.useState<IUser>()
    const qry = useQuery().get('filtro');
    const { user } = useAuthorization();
    const id = Number(`${user?.id}`);
    //const [id, setId] = React.useState<Number>()


    // React.useEffect(() => {
    //     console.log("Entrou no efect1 *********",Number(`${user?.id}`))
    //     setId(Number(`${user?.id}`));
    //     //loadUsuario()
    //     // eslint-disable-next-line
    // })
    
    React.useEffect(() => {
        //setId(Number(`${user?.id}`));        
        loadUsuario()
        // eslint-disable-next-line
    },[id])

    const loadUsuario = () => {       
        if (id)
            UsuarioService
                .Get(Number(id))
                .then(usuario => usuario && setUsuario(usuario[0]));
    }
    
    
    return (
        <div className={classes.root}>
            <Main>
                <Grid container justifyContent="space-around">
                    {/* ref={divRef} */}
                    <Paper style={{ borderRadius: "7px", width: "100%" }} >
                        {/* Parlamentar Header */}
                        <GerUsuariosConsultaHeader usuario={usuario} />
                        {/* ---------------- BOTOES ---------------------  */}
                        <GerUsuariosConsultaBotoes usuario={usuario} onChange={loadUsuario} />
                        {/* Parlamentar Inicio */}
                        <GerUsuariosConsultaInicio usuario={usuario} />
                        {/* Informações */}
                        <GerUsuariosConsultaInformacoes usuario={usuario} />
                    </Paper>
                    {
                    user.id_permissao !== 2  && user.id_permissao !== 4  ?
                    <Grid container style={{ paddingTop: "30px" }}>
                        <Grid item style={{ width: "100%" }}>
                            <GerUsuariosCarouselTabs id_user={Number(id)} query={qry} onChange={loadUsuario} usuario={usuario} />
                        </Grid>
                    </Grid>

                    :
                    null
                        }
                </Grid>
            </Main>
        </div>
    );
}