import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Icon, SvgIconProps, Tooltip } from "@mui/material";
import { usePallet } from "../../contexts/PalletContext";
import { useNavigate } from "react-router-dom";
import VectorBlue from '../../../src/img/VectorBlue.svg'
import { withStyles } from "@material-ui/core";
import { useState } from "react";


export default function MenuInferior(props: {
  icon: SvgIconProps,
  title: string,
  url?: string,
  no: number,
  i?: number,
  idButtonSelected?: number,
  onChange(key: number)
}) {



  const { pallet } = usePallet();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

    props.onChange(props?.no)
  };



  const handleClose = () => {
    setAnchorEl(null);
    props.onChange(undefined)
  };
  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    props.onChange(props?.i);
    navigate(props.url)
  };

  //console.log("Selecionado:",props.idButtonSelected)
  //console.log("no",props?.no)
  //console.log("index:",props.i)
  return (

    <div>

      <Button
        sx={{ color: `${props.idButtonSelected === props.i ? pallet.general.color2 : pallet.general.color4}`, marginTop: "9px", paddingLeft: "0px" }}
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // onClick={handleClick}
        onClick={handleMenuClick}
      >
        <div style={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: `${props.idButtonSelected === props.i ? pallet.backgroundColorQuaternary : "white"}` }}>
          {props.idButtonSelected === props.i ?
            props.title === "INTELIGÊNCIA ARTIFICIAL" ? <div style={{ marginTop: "4px" }}><img src={VectorBlue}></img></div> : <Icon style={{ color: pallet.general.color2 }}>{props.icon}</Icon>
            : <Tooltip title={props.title} arrow>
              <Icon>{props.icon}</Icon>
            </Tooltip>}
        </div>
      </Button>

      {/* <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: -60,
        }}
      >
        <Grid style={{ marginTop: "-8px", marginBottom: "-8px", minWidth: "190px" }}>
        <MenuItem 
                      sx={{
                        '&:hover': {
                          backgroundColor: pallet.color.secundary,
                        },
                          fontSize: pallet.general.size14,
                          fontFamily: pallet.fontFamily.general,
                          fontWeight: "bold",
                          //marginLeft: "10px",
                          verticalAlign: "middle",
                          backgroundColor: pallet.color.secundary,
                          color: pallet.textColorSecondary,
                        }} onClick={() => navigate(props.url)}>
          
            {props.title}
         </MenuItem>
          </Grid>
      </Menu> */}
    </div>
  );
}
