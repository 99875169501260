import { MoreHoriz } from "@material-ui/icons"
import EditIcon from '@mui/icons-material/Edit'
import { Divider, Grid, ListItemText } from "@mui/material"
import { isMobile } from "react-device-detect"
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuButtonIconNoText from "../../../components/MenuButtonIconNoText"
import { useNavigate } from 'react-router-dom';
import { IDepartamento, IUser } from "../../../models/User.Model"
import Button from '@mui/material/Button';
import { styled, alpha } from "@mui/material/styles";
import { PalletProvider, usePallet } from "../../../contexts/PalletContext";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import GerUsuariosEdicao from "./GerUsuariosEdicao";
import DeleteIcon from "@mui/icons-material/Delete";
import ClientesService from "../../../services/ClientesService";
import GerDepartamentosEdicao from "./GerDepartamentosEdicao";
import { number } from "yup";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import { useAlert } from '../../../contexts/AlertContext';
import UsuarioService from "../../../services/UsuarioService";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    ".MuiListItemText-primary": {
        fontSize: 0
    },
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "0px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));


export const GerDepartamentoConsultaBotoes = (props: { departamento: any, onChange: () => void }) => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { pallet } = usePallet();
    const [open, setOpen] = React.useState();
    // const editar = (props?.parlamentar?.id) => {
    //     console.log("EDITANDO");
    // };
    const { NewAlert } = useAlert();

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };

    const deletarDepartamento = (id) => {
        UsuarioService.DeleteGerDpto(id)
            .then((res) => {
              //console.log("OLA",Number(res))
                if (Number(res) === -1) {
                    handleClose();
                    NewAlert("error", "Essa unidade de negócio possui membros, o mesmo não pode ser apagado");
                    
                } 
                else if (Number(res) === 1) {
                  NewAlert("success", "A unidade de negócio foi apagada com sucesso");
                   props?.onChange()
                }
                
                else {
                    NewAlert(
                        "error",
                        "Erro ao apagar, tente novamente"
                    );
                }
            })
        // .then((x) => loadAcontecimentos(props?.id_proposicao));
      };
      
    return (
        <Grid item>
            <div>


                <Grid container item justifyContent="flex-end">


                    <Grid style={{
                        marginTop: "10px",
                        marginRight: `${isMobile ? "10px" : "50px"}`,
                    }}>
                        {(!(props?.departamento?.id) || (props?.departamento?.id > 3)) &&
                            // <MenuButtonIconNoText
                            //     iconButton={<MoreHoriz />}
                            //     itens={[
                            //         { path: `/usuario/${props?.usuario?.id}/cadastro`, icon: <EditIcon />, label: 'Editar perfil', },
                            //         { path: `/usuario/${props?.usuario?.id}/cadastro`, icon: <PasswordIcon />, label: 'Alterar senha', },
                            //     ]}

                            // />
                            <>
                                <Button
                                    id={`button`}
                                    aria-controls={
                                        anchorEl ? `mmenu` : undefined
                                    }
                                    disableElevation
                                    variant="contained"
                                    size="large"
                                    style={{
                                        backgroundColor: `${pallet.backgroundColorQuintenary}`,
                                        textTransform: "capitalize",
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.fontWeight.button,
                                        fontSize: `${pallet.fontSize.smallButton}`,
                                        color: `${pallet.color.secundary.font}`,
                                        width: "25px",
                                        height: "40px",
                                    }}
                                    onClick={(e) => handleClick(e, 1)}
                                    endIcon={
                                        <Grid
                                            style={{
                                                fontSize: "30px",
                                                color: `${pallet.general.color4}`,
                                                marginTop: "9px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <MoreHoriz fontSize="inherit" />
                                        </Grid>
                                    }
                                ></Button>
                                <StyledMenu

                                    id={`mmenu`}
                                    MenuListProps={{
                                        "aria-labelledby": `button`,
                                    }}
                                    anchorEl={anchorEl}
                                    open={open === 1}
                                    onClose={handleClose}
                                >
                                    <MenuItem>
                                        <GerDepartamentosEdicao
                                            id_departamento={props?.departamento?.id}
                                            closeMenu={() => handleClose()}
                                        />
                                    </MenuItem>
                                    <Divider
                                        style={{
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                        }}
                                    />
                                    {/* <MenuItem onClick={() => ClientesService.DelDepartamento(`${props?.departamento?.id}`)}
                                        style={{
                                            paddingTop: "8px",
                                            paddingBottom: "8px",
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.fontWeight.lighter,
                                            fontSize: `${pallet.fontSize.titleWidgets}`,
                                        }}>
                                        <DeleteIcon />
                                        Apagar
                                    </MenuItem> */}
                                    <MenuItem
                                        style={{
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.fontWeight.button,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            borderRadius: "6px",
                                            padding: "6px",
                                        }}
                                    >
                                        <ButtonDialogConfirmation
                                            styles={{
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.button,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`,
                                                borderRadius: "6px",
                                                textTransform: "capitalize",
                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            // lastPath={"/parlamentar"}
                                            confirmationEvent={() => deletarDepartamento(props?.departamento?.id)}
                                            title_button={"Apagar"}
                                            confirmation_button={"Apagar"}
                                            content={"APAGAR UNIDADE DE NEGÓCIO?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        >
                                            <DeleteIcon
                                                style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    marginRight: "16px",
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                }}

                                            />
                                            <ListItemText>!</ListItemText>
                                        </ButtonDialogConfirmation>
                                    </MenuItem>
                                </StyledMenu>
                            </>
                        }
                    </Grid>
                </Grid>

            </div>
        </Grid>
    )
}

