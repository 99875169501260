import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import ConteudoSegunda from "./ConteudoSegunda";

const AgendaCarouselTabsSegunda = (props: { activeTab: any, index: number, dateStartReference: Date, eventListToday: Array<any>, loadEventos: () => void, loading: boolean }) => {

    return (
        <Grid style={{ borderRadius: "6px"}}>
            <TabPanel value={props.activeTab} index={props.index}>
                <ConteudoSegunda index={props.index} activeTab={props.activeTab} dateStartReference={props?.dateStartReference} eventListToday={props?.eventListToday} loadEventos={props?.loadEventos} loading={props?.loading}/>
            </TabPanel>
        </Grid>
    )
}

export default AgendaCarouselTabsSegunda;