import React from 'react'
import { AuthorizationProvider } from './AuthorizationContext';
import { NotificationsProvider } from './NotificationsContext';
import { PalletProvider } from './PalletContext';
import { DashboardProvider } from './DashboardContext';
import { AlertProvider } from './AlertContext';
import { TimeZoneProvider } from './TimeZoneContext';

// children?:
// | React.ReactChild
// | React.ReactChild[]

function Providers(props: { children?: React.ReactNode; }): JSX.Element {
  return (
    <AuthorizationProvider>
      <TimeZoneProvider>
        <AlertProvider>
          <PalletProvider>
            {/* <NotificationsProvider> */}
              <DashboardProvider>
                {props.children}
              </DashboardProvider>
            {/* </NotificationsProvider> */}
          </PalletProvider>
        </AlertProvider>
      </TimeZoneProvider>
    </AuthorizationProvider >
  );
}

export { Providers };