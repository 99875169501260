import FilterQuantity from "../../../../components/filter/FilterQuantity";
import { Button } from '@material-ui/core';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Grid, Menu, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import ComboBox from "../../../../components/ComboBox";
import useStyles from "../../../../components/layout/Styles";
import { usePallet } from "../../../../contexts/PalletContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconLabelButtons from '../../../../components/ButtonIconSalvar';
import { IListInfo } from '../../../../models/IListInfo';
import IAService from "../../../../services/IAService";
import EmptyError from '../../../../components/EmptyError';
import PaginationSaveState from '../../../../components/PaginationSaveState';
import { useNavigate } from "react-router-dom";
import TemaCorporativoCard from "../Grupo/TemaCorporativoCard";
import { IFilterData } from "../../../../components/filter/ListFilter";
import { arraysEquals } from "../../../../utils/array.utils";
import TCService from "../../../../services/TCService";
import TCCorporativoTemaCard from "./TCCorporativoTemaCard";
import ButtonIconSalvar from "../../../../components/ButtonIconSalvar";
import TCMenuButtonIconEditActiveTema from "./TCMenuButtonIconEditActive.Tema";
import { MoreHoriz } from "@material-ui/icons";
import { MenuItem } from "@mui/material"
import SummaryPanelWidget from "../../../workspace/_components/SummaryPanelWidget";
import filtersBackgroundImage from '../../../../../src/img/background_filters_dashboard.png';
import MenuButtonIconNoText from "../../../../components/MenuButtonIconNoText";
import EditIcon from '@mui/icons-material/Edit';
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
import EmptyErrorCorrelata from "../../../../components/EmptyErrorCorrelata";
import EmptyErrorDocCarousel from "../../../../components/EmptyErrorDocCarousel";
import EmptyErrorTemaCorporativoList from "../EmptyErrorTemaCorporativoList";




//## StyledCompenents

const HeaderPaper = styled(Paper)`
  background-size: cover;
  /* background-position: center; */
  justify-content: center;
  height: 80px;
  padding: 0 15px;
`;

const HeaderContentPaper = styled(Paper)`
  justify-content: center;
  padding: 15px;
  margin-bottom: 15px;
`;

const HeaderText = styled(Typography)`
  font-weight: ${(props) => props.pallet.fontWeight.subtitle};
  font-size: ${(props) => props.pallet.fontSize.titleWidgets};
  font-family: ${(props) => props.pallet.fontFamily.general};
  color: ${(props) => props.pallet.color.colorBase.greyText};
`;

const TitleText = styled(Typography)`
  color: ${(props) => props.pallet.textColorQuaternary};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styled(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;

const ContainerFilter = styled(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;

const ButtonMenu = styled(Button)`
    background-color: ${(props => props.pallet.backgroundColorTertiary)};
    font-family: ${(props => props.pallet.fontFamily.general)};
    font-weight: ${(props => props.pallet.fontWeight.lighter)};
    font-size: ${(props => props.pallet.fontSize.titleWidgets)};
    color: ${(props => props.pallet.general.color4)};
    border-radius: 6px;
    margin-left: 10px;
    width: 25px;
    height: 45px;
    z-index: 10;
    
    &:hover {
        background: white;
        filter: contrast(0.5);
    }
`;


const MenuItemStyle = styled(MenuItem)`
    font-family: ${(props => props.pallet.fontFamily.general)};
    font-weight: ${(props => props.pallet.fontWeight.button)};
    font-size: ${(props => props.pallet.general.size14)};
    color: ${(props => props.pallet.color.tertiary.font)};
    border-radius: 6px;
    padding: 7px;
    text-transform: none;
`;

export interface IListInfoTema {
    descricaoGrupo: string,
    descricaoAtivo: boolean,
    totalTemas: number,
    totalTemasAtivos: number,
    totalSubTemas: number,
    totalSubTemasAtivos: number,
    totalTopicos: number,
    totalTopicosAtivos: number
}


export default function TCTemasCorporativosTemaList(props: { idGrupo: number }) {
    const classes = useStyles();
    const { pallet } = usePallet();
    const navigate = useNavigate();

    //## Paginação
    const [infoList, setInfoList] = useState<IListInfo>({
        pageIndex: 1,
        pageSize: 8,
        pageItens: 0,
        totalItens: 0,
        totalPages: 0,
    });

    //## InfoTema
    const [infoGrupo, setInfoGrupo] = useState<IListInfoTema>({
        descricaoGrupo: "",
        descricaoAtivo: true,
        totalTemas: 0,
        totalTemasAtivos: 0,
        totalSubTemas: 0,
        totalSubTemasAtivos: 0,
        totalTopicos: 0,
        totalTopicosAtivos: 0
    });

    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filter, setFilter] = useState({ key: "visualizacao", label: "Todos", value: "T" });
    const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

    const filterName = 'TemasCorporativosTemaListCurrentPage';
    // const filterItens = [
    //   { key: "visualizacao", label: "Tudo", value: "T" },
    //   { key: "visualizacao", label: "Relacionados", value: "R" },
    //   { key: "visualizacao", label: "Não Relacionados", value: "NR" },
    // ]

    //## Dados
    const [lista, setLista] = useState([]);
    const [listaDescricao, setListaDescricao] = useState<string[]>([]);
    const [busca, setBusca] = useState<IFilterData[]>();
    const [descricaoParcial, setDescricaoParcial] = useState<string>("");
    const [listaInfoTema, setListaInfoTema] = useState([]);

    useEffect(() => {
        if (descricaoParcial && descricaoParcial.trim().length > 2) {
            TCService.getTemas(props?.idGrupo, currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "descricaoP", value: descricaoParcial }]))
                .then(async res => {
                    if (res) {
                        const data = [];

                        res.data.map(item => data.push({
                            key: item.tema_id,
                            //label: item.descricao + (item.casa === 1 ? ' [Senado Federal]' : (item.casa === 2 ? ' [Câmara dos Deputados]' : '')),
                            label: item.tema_descricao,
                            value: item.tema_id
                        }))

                        setListaDescricao(data.map(item => { return item.label }));
                        // setListaDescricao(() => [...data]);
                        setBusca(() => [...data]);
                    }
                });
        } else if (descricaoParcial === "") {
            loadTemaList();
        }
    }, [descricaoParcial]);

    useEffect(() => {
        if (currentPage > 0) loadTemaList();
    }, [currentPage, currentPageSize, currentFilter]);

    const loadTemaList = () => {
        if (filter) {
            //currentFilter = { "key": "visualizacao", "value": filter.value }
        }

        TCService.getTemas(props?.idGrupo, currentPageSize, currentPage, JSON.stringify(currentFilter)) // force break
            .then(async (res) => {
                if (res) {
                    setLista(res.data);
                    setInfoList(res.listInfo);
                    setInfoGrupo(res.listInfoGrupo);
                }
            });
    };

    //## Funções'
    const handleOnChangeItensPerPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPageSize(Number(event.target.value));
    };

    const handleOnChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFilter: IFilterData = JSON.parse(event.target.value);
        setFilter(selectedFilter);
        setCurrentFilter([...currentFilter.filter(x => x.key !== selectedFilter.key), selectedFilter]);
    };

    const handleOnSelectDescricaoParcial = async (tema: string) => {
        const selecao = busca.filter(x => x.label === tema);
        if (selecao.length) {
            //navigate(`/tc/grupo/${selecao[0].value}`);

            //Filtra por id

            await TCService.GetTema(selecao[0].value).then(res => {

                //setChecked(res?.data[0].grupo_ativo)
                //setNome(res?.data[0].grupo_descricao)
                //setIdRegistro(res?.data[0].grupo_id)

                if (res) {
                    setLista(res.data)
                    setInfoList(res.listInfo);
                    setInfoGrupo(res.listInfoGrupo)
                }
            });


        }
    };

    return (
        <>
            <Grid container>
                <Grid style={{ width: "100%", margin: "20px 40px" }}>
                    <ComboBox
                        name="filtroTemaCorporativo"
                        itens={listaDescricao}
                        onChange={setDescricaoParcial}
                        onSelect={handleOnSelectDescricaoParcial}
                        placeholder="Buscar"
                    />
                </Grid>
                <Grid item xs={12}>

                    <ContainerBody container style={{ marginTop: "20px" }}>

                        {lista &&
                            lista.map((item) => (
                                <TCCorporativoTemaCard
                                    tema_id={item.tema_id}
                                    tema_descricao={item.tema_descricao}
                                    tema_seativo={item.tema_ativo}
                                    onChange={loadTemaList}

                                />
                            ))}
                        {!lista.length && (
                            <PaperContent pallet={pallet}>
                                <EmptyErrorTemaCorporativoList title="Os itens cadastrados aparecerão aqui" tipotc="T" description="Cadastre Temas" />
                            </PaperContent>
                        )}
                    </ContainerBody>
                    <ContainerFooter container>
                        <PaginationSaveState
                            name={filterName}
                            pages={infoList?.totalPages}
                            page={currentPage}
                            onChange={setCurrentPage}
                        />
                    </ContainerFooter>
                </Grid>
            </Grid>


        </>
    )
}