import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { Typography, Box, Link } from '@mui/material';
import { usePallet } from '../contexts/PalletContext';


function CircularProgressWithLabel(props: CircularProgressProps & { value: number, title: string, link: string }) {
    const { pallet } = usePallet();

    return (
        <div>
            <Box position="relative" display="inline-flex" style={{ padding: "20px" }}>
                <CircularProgress size={80} variant="determinate" {...props}
                    style={{
                        color: `${pallet.color.secundary.font}`,
                        backgroundColor: `${pallet.color.colorBase.greyBackground}`,
                        borderRadius: "40px"
                    }}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <div style={{ borderRadius: "40px", backgroundColor: "white", width: "67px", height: "67px" }}></div>
                </Box>
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{ zIndex: 2 }}

                >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
            <Typography align={'center'}>
                <Link href={`${props.link}`} onClick={() => { }} style={{
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontSize: `${pallet.fontSize.titleWidgets}`,
                    fontWeight: pallet.fontWeight.button,
                    textDecoration: "none",
                    color: `${pallet.color.secundary.font}`
                }}> 
                {props.title}
                </Link> 
            </Typography>
        </div>
    );
}


export { CircularProgressWithLabel }

