import * as React from 'react';

import { Box, Grid, Link, MenuItem, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import ParlamentarService from '../../../services/ParlamentarService';
import { Divider } from '@mui/material';
import { useAlert } from "../../../contexts/AlertContext";
import useStyles from '../../../components/layout/Styles';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../components/BootstrapDialogTitle';
import ParlamentarDiscursoService from '../../../services/ParlamentarDiscursoService';
import { formatDate } from '../../../utils/dateUtils';
import blankAvatar from '../../../img/avatar_foto.jpg'
import { parlamentarQualificacoes } from '../../../models/ParlamentarQualificacoes';
import SelectTextFieldA from '../../../components/SelectTextFieldA';
import { useAuthorization } from '../../../contexts/AuthorizationContext';

export default function ParlamentarDiscursosModal(props: { id: number, qualificacao: string, onChange?: (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void }) {
    const { pallet } = usePallet();
    const [open, setOpen] = React.useState(false);
    const [discurso, setDiscurso] = React.useState<{ data: Date, transcricao: string, nome: string, url_foto: string, qualificacao: string }>();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getItem();
    }, [open])

    const { user } = useAuthorization();

    const getItem = () => {
        open &&
            props.id &&
            ParlamentarDiscursoService
                .GetTranscricao(props.id)
                .then(x => setDiscurso(x))
    }

    const CustomLabelQualificacao: React.FunctionComponent = () => {
        return (
            <div style={{
                color: `${pallet.color.colorBase.labelTextFild}`,
                marginRight: "8px",
                fontFamily: `${pallet.fontFamily.general}`,
            }}>
                QUALIFICAÇÃO
            </div>
        )
    }

    const { NewAlert } = useAlert();
    const permissaoErro = () => {
  
        NewAlert("error","Não é possível realizar está ação com este perfil.")
        }

    return (
        <div>
            <Link href="#" onClick={handleClickOpen} underline="none"
                style={{
                    fontWeight: pallet.fontWeight.subtitle,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.secundary.font}`
                }}>
                {'Ver transcrição do discurso'}
            </Link>

            <BootstrapDialog style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
                maxWidth={"md"}
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}                
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    DISCURSO
                </BootstrapDialogTitle>

                <Grid style={{ paddingLeft: `${isMobile ? "15px" : "30px"}`, paddingTop: "15px" }}>
                    <Stepper orientation="vertical">

                        <Step key={0} expanded active={false}>
                            <StepLabel
                                icon={
                                    <Grid container >
                                        <div
                                            style={{
                                                borderRadius: "50%",
                                                width: "40px",
                                                height: "40px",
                                                overflow: "hidden",
                                                position: "relative",
                                                boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                backgroundColor: `${pallet.backgroundGeneralPage}`
                                            }}
                                        >
                  
                                            {
                                                <img src={discurso?.url_foto || blankAvatar} alt="Logo" style={{
                                                    width: `${discurso?.url_foto ? "26px" : "26px"}`,
                                                    marginLeft: `${discurso?.url_foto ? "6px" : "6px"}`,
                                                    marginTop: `${discurso?.url_foto ? "0px" : "-45px"}`,
                                                    opacity: `${discurso?.url_foto ? "0px" : "70%"}`
                                                }} />}

                                        </div>
                                    </Grid>
                                }
                            >
                                <span style={{
                                    fontWeight: pallet.general.weight_600,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    letterSpacing: "1px",
                                    textTransform: 'uppercase',
                                    marginLeft: "10px"
                                }}>
                                    {discurso?.nome}
                                </span>


                            </StepLabel>

                        </Step>

                    </Stepper>

                    <Grid xl={12} container direction="row" justifyContent="space-between" style={{ paddingTop: "10px" }}>
                        <Grid xs={12} lg={3} container style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid item direction="column" style={{
                                fontWeight: pallet.general.weight_500,
                                fontSize: `${pallet.general.size12}`,
                                color: `${pallet.color.tertiary.font}`,
                                marginRight: "80px"
                            }}>
                                DATA
                                <Grid item style={{
                                    marginTop: "5px",
                                    fontWeight: pallet.general.weight_300,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`
                                }}>
                                    {discurso && formatDate(new Date(discurso.data), 'dd/MM/yyyy')}

                                </Grid>
                            </Grid >

                            <Grid container item xs={12} lg={3} style={{ marginBottom: `${isMobile ? "10px" : "0px"}`, marginTop: `${isMobile ? "10px" : "0px"}` }}>
                                <Grid item direction="column" style={{
                                    fontWeight: pallet.general.weight_500,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`
                                }}>
                                    HORA
                                    <Grid item style={{
                                        marginTop: "5px",
                                        fontWeight: pallet.general.weight_300,
                                        fontSize: `${pallet.general.size14}`,
                                        color: `${pallet.color.tertiary.font}`
                                    }}>
                                        {discurso && formatDate(new Date(discurso.data), 'HH:MM')}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xl={12} container justifyContent="space-between" style={{ paddingTop: `${isMobile ? "0px" : "15px"}`, paddingRight: `${isMobile ? "15px" : "0px"}` }}>
                            <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "10px"}` }}>
                                <Grid style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    paddingBottom: "20px"
                                }}>
                                    REGISTRO
                                </Grid>
                                {user.id_permissao !== 4 ?
                                <Grid>
                                    <Box component="form"
                                        style={{
                                            fontFamily: pallet.fontFamily.general,
                                            inlineSize: `${isMobile ? "100%" : "50%"}`
                                        }}
                                    >
                                        <SelectTextFieldA
                                            value={props.qualificacao ? props.qualificacao.toLowerCase() : ""}
                                            onChange={(e) => user.id_permissao !== 3 ? props.onChange(props.id, e) : permissaoErro }
                                            options={parlamentarQualificacoes}
                                            id={"qualificacao" + props.id}
                                            label={"QUALIFICAÇÃO"}
                                        />
                                    </Box>
                                    <Box>
                                    </Box>
                                </Grid>
                                :
                                null
                                    }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider style={{ marginBottom: "15px" }} />

                <Grid style={{
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size16}`,
                    fontWeight: pallet.general.weight_300,
                    alignItems: "center",
                    lineHeight: "1.6",
                    color: "#1E0736",
                    height: "250px",
                    textAlign: "justify",
                    width: "100%",
                    overflow: "scroll",
                    overflowX: "hidden",
                    padding: `${isMobile ? "10px 15px 10px 15px" : "20px 20px 20px 30px"}`
                }}>
                    {discurso?.transcricao}
                </Grid>

            </BootstrapDialog>
        </div >
    );
}
