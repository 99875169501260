import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { usePallet } from '../contexts/PalletContext';
import { Icon } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function CustomizedMenus(props: { icon: any, dtIni: Date, dtFin: Date, tipo: string, disabled?: boolean, idPermissao?: number }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { pallet } = usePallet();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // console.log("LISTA DE EVENTOS EM PROS NO COMPONENTE BOTAO MENU", props.listaEventos)
    // console.log(props?.dtIni)
    // console.log(props?.dtFin)

    return (
        <div>
            <Button
                style={{
                    textTransform: 'uppercase',
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontSize: `${pallet.general.size12}`,
                    fontWeight: pallet.general.weight_600,
                    border: "none",
                    backgroundColor: `${props.disabled === true ? '#e9e9e9' : pallet.color.secundary.font}`,
                    color: `${pallet.backgroundColorTertiary}`,
                    paddingTop: '9px',
                    paddingBottom: '9px'
                }}
                disabled={props.disabled ? props.disabled : false}
                size="large"
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
                startIcon={
                    <Icon style={{
                        color: `${props.disabled === true ? 'white' : pallet.backgroundColorTertiary}`
                    }}
                    >{props.icon}</Icon>}
            >
                Gerar .PDF
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem style={{
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontSize: `${pallet.general.size12}`,
                    fontWeight: pallet.general.weight_300,
                    border: "none",
                }}
                    onClick={() => window.open(`/agenda/agendaGeraPDFJornal/${props?.dtIni}/${props?.dtFin}/${props?.tipo}`, "_blank")} disableRipple>
                    <Icon style={{
                        color: `${pallet.backgroundColorTertiary}`
                    }}
                    >{props.icon}</Icon>
                    Agenda Jornal
                </MenuItem>
                <Divider />
                <MenuItem
                    style={{
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontSize: `${pallet.general.size12}`,
                        fontWeight: pallet.general.weight_300,
                        border: "none",
                    }}
                    onClick={() => window.open(`/agenda/agendaGeraPDFOperacional/${props?.dtIni}/${props?.dtFin}/${props?.tipo}`, "_blank")} disableRipple>
                    <Icon style={{
                        color: `${pallet.backgroundColorTertiary}`
                    }}
                    >{props.icon}</Icon>
                    Agenda Operacional
                </MenuItem>
                {props.idPermissao !== 4 ?
                <>
                <Divider />
                <MenuItem
                    style={{
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontSize: `${pallet.general.size12}`,
                        fontWeight: pallet.general.weight_300,
                        border: "none",
                    }}
                    // onClick={handleClose} disableRipple>
                    onClick={() => window.open(`/agenda/AgendaRenderPDFAcoesInternas/${props?.dtIni}/${props?.dtFin}/${props?.tipo}`, "_blank")} disableRipple>

                    <Icon style={{
                        color: `${pallet.backgroundColorTertiary}`
                    }}
                    >{props.icon}</Icon>
                    Agenda de Ações Internas
                </MenuItem>
                </>
                :
                null
                }
            </StyledMenu>
        </div>
    );
}
