import React from "react";
import CarouselCustom from "../../../../components/carousel/CarouselCustom";
import ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsComentários from "./ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsComentários";
import ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsNotaTecnica from "./ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsNotaTecnica";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";

import styled from "styled-components";
import { Grid } from "@mui/material";

const CarouselTab = styled(CarouselCustom)`
  .customTab {
    width: 500px;
    background-color: red !important;
  }
`;

export default function ProposicaoAcaoPreparatoriaEspecificaCarrouselTabs(props: {
  acao_preparatoria: IAcaoPreparatoria;
  onChange: (data: any) => void;
  reload?: () => void;
}) {
  const [activeTab, setActiveTab] = React.useState(0);
  const itens = [
    {
      index: 0,
      label: "Notas Técnicas",
      component: (
        <ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsNotaTecnica
          key={0}
          index={0}
          activeTab={activeTab}
          acao={props.acao_preparatoria}
          onChange={props.onChange}
          reload={() => {
            props?.reload();
          }}
        />
      ),
    },
    {
      index: 1,
      label: "Comentários",
      component: (
        <ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsComentários
          key={1}
          index={1}
          acao={props.acao_preparatoria}
          activeTab={activeTab}
        />
      ),
    },
  ];
  return (
    <CarouselTab itens={itens} activeTab={activeTab} onChange={setActiveTab} />
  );
}
