import { Divider, Grid } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import ComboBox from "../../../components/ComboBox";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import { IFilterData } from "../../../components/filter/ListFilter";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import React, { useEffect, useState } from "react";
import PieChartInfoCard from "../../../components/PieChartInfoCard";
import PieChartInfoInsight from "./PieChartInfoInsight";
import InsightService from "../../../services/InsightService";
import { format } from "date-fns";
import { formatDate } from "../../../utils/dateUtils";

export function AccordionContentKey17(props: {
  titleFederal?: string;
  originFederal?: string;
  onChange?: (event: any) => void;
  dtIni?: Date;
  dtFin?: Date;
  esfera?: string,
}) {
  const ErrorMessage = (props: {
    name: string;
    errors: Array<{ name: string; err: string }>;
  }) => {
    const itemError = props.errors.filter((err) => err.name === props.name);
    return (
      <div style={{ color: "red", fontSize: "11px", marginTop: "5px" }}>
        {itemError.length === 1 && itemError[0].err}
      </div>
    );
  };

  const [ambitoLabel, setAmbitoLabel] = React.useState<string>("");
  const [ambito, setAmbito] = React.useState<string>("");
  const [ambitoItens, setAmbitoItens] = React.useState<Array<IFilterData>>([]);

  const [casa, setCasa] = React.useState<number>(undefined);
  const [casaLabel, setCasaLabel] = React.useState<string>("");
  const [casaItens, setCasaFederalItens] = React.useState<Array<IFilterData>>([
    { key: "0", label: "--Selecione -- ", value: "0" },
    { key: "1", label: "Senado Federal", value: "1" },
    { key: "2", label: "Câmara dos Deputados", value: "2" },
    //TODO: VERIFICAR NECESSIDADE DO CN, SENDO QUE NAO EXISTEM PARLAMENTARES NO BANCO COM CASA = 3
    // { key: "3", label: "Congresso Nacional", value: "3" },
  ]);

  const [error, setError] = useState<Array<{ name: string; err: string }>>([]);
  const { pallet } = usePallet();
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);
  const [convergente, setConvergente] = useState<number>();
  const [divergente, setDivergente] = useState<number>();
  const [neutro, setNeutro] = useState<number>();
  const [naoClassificado, setNaoClassificado] = useState<number>();
  const handleCasa = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: number
  ) => {
    setCasa(Number(event.target.value));
    handleCasaLabel(casa);
    setAmbito(undefined);
  };

  React.useEffect(() => {
    if (casa) {
      TabelasService.getTabelaAmbitosTipo(casa).then(async (res) => {
        const data = [];
        data.push({ key: "", label: "-- Selecione -- ", value: "" });
        res?.map((item) =>
          data.push({
            key: item.id,
            label: item.sigla + " - " + item.resumo,
            value: item.sigla,
          })
        );
        setAmbitoItens(() => [...data]);
      });
    }
  }, [casa]);

  React.useEffect(() => {
    if (ambito && casa) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey17(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd"),
          casa,
          ambito
        ).then((res) => {
          if (res) {
            setConvergente(res.data.data.convergente);
            setDivergente(res.data.data.divergente);
            setNeutro(res.data.data.neutro);
            setNaoClassificado(res.data.data.naoClassificado);
          }
        });
      }
    }
  }, [ambito, casa, props.dtIni, props.dtFin]);

  const handleAmbitoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmbito(event.target.value);
  };

  const handleCasaLabel = (casa: number) => {
    switch (casa) {
      case 1:
        return "Senado Federal";
      case 2:
        return "Câmara dos Deputados";
      case 3:
        return "Congresso Nacional";
      default:
        return "Todas";
    }
  };

  return (
    <>
      <Grid>
        <Grid
          container
          style={{
            justifyContent: casa ? "space-around" : "start",
            marginLeft: `${isMobile
              ? "0px"
              : casa === 0 || casa === undefined
                ? "89px"
                : "0px"
              }`,
            paddingTop: `${isMobile ? "40px" : "30px"}`,
            paddingBottom: `${isMobile ? "20px" : "30px"}`,
            paddingLeft: `${isMobile ? "10px" : "0px"}`,
            paddingRight: `${isMobile ? "10px" : "0"}`,
          }}
        >
          {/* // loading ? null : */}
          <Grid
            item
            md={4}
            xs={12}
            lg={4}
            style={{ paddingBottom: `${isMobile ? "20px" : "0px"}` }}
          >
            <SelectTextFieldA
              name={"casa-federal"}
              value={casa}
              onChange={handleCasa}
              options={casaItens}
              label={"CASA LEGISLATIVA"}
              id={"casa-federal"}
              focused={true}
              placeholder={"Selecione"}
            />
            <ErrorMessage name="casaFederal" errors={error} />
          </Grid>

          {
            <>
              {casa ? (
                <Grid item md={4} xs={12} lg={4}>
                  <SelectTextFieldA
                    name={"ambito"}
                    value={ambito}
                    onChange={handleAmbitoOnChange}
                    options={ambitoItens}
                    label={"ÂMBITO"}
                    id={"ambito"}
                    focused={true}
                    placeholder={"Selecione"}
                  />
                  <ErrorMessage name="ambito" errors={error} />
                </Grid>
              ) : null}
            </>
          }
        </Grid>
        <Grid style={{ marginBottom: "32px" }}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          xs={12}
          style={{
            margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"
              }`,
          }}
        >
          <PieChartInfoInsight
            title="POSICIONAMENTOS DOS INTERLOCUTORES POR ÂMBITO"
            // subtitle={handleCasaLabel(casa)}
            labels={[
              {
                key: "Convergente",
                data: convergente,
                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy") +
                  '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value": ' + casa + '},{"key":"ambito-data","label":"AMBITO: ' + ambito + '","value": "' + ambito + '"},{"key":"qualificacao","label":"QUALIFICACAO: Convergente","value": "Convergente"}]',
                color: `${pallet.color.charts.green}`,
              },
              {
                key: "Divergente",
                data: divergente,
                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy") +
                  '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value": ' + casa + '},{"key":"ambito-data","label":"AMBITO: ' + ambito + '","value": "' + ambito + '"},{"key":"qualificacao","label":"QUALIFICACAO: Divergente","value": "Divergente"}]',
                color: `${pallet.color.charts.red}`,
              },
              {
                key: "Não Classificado",
                data: naoClassificado,
                url: '/interlocutor/?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy") +
                  '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value": ' + casa + '},{"key":"ambito-data","label":"AMBITO: ' + ambito + '","value": "' + ambito + '"},{"key":"qualificacao","label":"QUALIFICACAO: Não Classificado","value": "Não Classificado"}]',

                color: `${pallet.color.charts.purple}`,
              },
              {
                key: "Neutro",
                data: neutro,
                url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"legislatura","label":"LEGISLATURA: 57","value":"57"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy") +
                  '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value": ' + casa + '},{"key":"ambito-data","label":"AMBITO: ' + ambito + '","value": "' + ambito + '"},{"key":"qualificacao","label":"QUALIFICACAO: Neutro","value": "Neutro"}]',

                color: `${pallet.color.charts.lightGrey}`,
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AccordionContentKey17;
