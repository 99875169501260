import React from 'react';
import CarouselCustom from '../../../components/carousel/CarouselCustom';
import ParlamentarCarouselTabsAcontecimentos from './ParlamentarCarouselTabsAcontecimentos';
import ParlamentarCarouselTabsAcoesInfluencia from './ParlamentarCarouselTabsAcoesInfluencia';
import ParlamentarCarouselTabsProposicoes from './ParlamentarCarouselTabsProposicoes';
import ParlamentarCarouselTabsOutros from './ParlamentarCarouselTabsOutros';
import ParlamentarCarouselTabsComissoes from './ParlamentarCarouselTabsComissoes';
import ParlamentarCarouselTabsDiscursos from './ParlamentarCarouselTabsDiscursos';
import ParlamentarCarouselTabsDemandas from './ParlamentarCarouselTabsDemandas';
import { IParlamentar } from '../../../models/Parlamentar.Model';
import ParlamentarCarouselTabsTwitter from './ParlamentarCarouselTabsTwitter';

export default function ParlamentarCarouselTabs(props: { id_parlamentar: number, parlamentar?: IParlamentar, query: string, permissaoUsuario: number, twitterUsername: String }) {
    const [activeTab, setActiveTab] = React.useState(props.query ? 4 :  0);



    const itens = props?.parlamentar?.tipo === 'politico' &&  props?.permissaoUsuario !== 4 ? [

        { index: 0, hide: false, label: "Acontecimentos", component: <ParlamentarCarouselTabsAcontecimentos key={0} index={0} activeTab={activeTab} id_parlamentar={props.id_parlamentar} idPermissao={props.permissaoUsuario} /> },
        { index: 1, hide: false, label: "Demandas", component: <ParlamentarCarouselTabsDemandas key={1} index={1} activeTab={activeTab} id_parlamentar={props.id_parlamentar} idPermissao={props.permissaoUsuario}/> },

        { index: 2, hide: false, label: "Ações de Influência", component: <ParlamentarCarouselTabsAcoesInfluencia key={2} index={2} activeTab={activeTab} id_parlamentar={props.id_parlamentar} parlamentar={props?.parlamentar} /> },
        { index: 3, hide: false, label: "Proposições", component: <ParlamentarCarouselTabsProposicoes key={3} index={3} activeTab={activeTab} id_parlamentar={props.id_parlamentar} parlamentar={props?.parlamentar} /> },
        { index: 4, hide: false, label: "Comissões", component: <ParlamentarCarouselTabsComissoes key={4} index={4} activeTab={activeTab} id_parlamentar={props.id_parlamentar} query={props.query} parlamentar={props?.parlamentar} /> },
        { index: 5, hide: false, label: "Discursos", component: <ParlamentarCarouselTabsDiscursos key={5} index={5} activeTab={activeTab} id_parlamentar={props.id_parlamentar} idPermissao={props.permissaoUsuario} parlamentar={props.parlamentar} />  },
        { index: 6, hide: false, label: "Twitter", component: <ParlamentarCarouselTabsTwitter key={6} index={6} activeTab={activeTab} twitterUsername={String(props.twitterUsername)} /> },
        { index: 7, hide: false, label: "Outros", component: <ParlamentarCarouselTabsOutros key={7} index={7} activeTab={activeTab} id_parlamentar={props.id_parlamentar} /> },
    ]

    : props?.parlamentar?.tipo === 'politico' &&  props?.permissaoUsuario === 4 ?

     [
        // { index: 0, label: "Proposições", component: <ParlamentarCarouselTabsProposicoes key={0} index={0} activeTab={activeTab} id_parlamentar={props.id_parlamentar} parlamentar={props?.parlamentar} permissaoUsuario={props.permissaoUsuario} /> },
        { index: 0, hide: false, label: "Comissões", component: <ParlamentarCarouselTabsComissoes key={0} index={0} activeTab={activeTab} id_parlamentar={props.id_parlamentar} query={props.query} parlamentar={props?.parlamentar} permissaoUsuario={props.permissaoUsuario} /> },
        { index: 1, hide: false, label: "Demandas", component: <ParlamentarCarouselTabsDemandas key={1} index={1} activeTab={activeTab} id_parlamentar={props.id_parlamentar} idPermissao={props.permissaoUsuario}/> },
        { index: 2, hide: false, label: "Proposições", component: <ParlamentarCarouselTabsProposicoes key={2} index={2} activeTab={activeTab} id_parlamentar={props.id_parlamentar} parlamentar={props?.parlamentar} permissaoUsuario={props.permissaoUsuario} /> },
        { index: 3, hide: false, label: "Discursos", component: <ParlamentarCarouselTabsDiscursos key={3} index={3} activeTab={activeTab} id_parlamentar={props.id_parlamentar} idPermissao={props.permissaoUsuario} parlamentar={props.parlamentar} /> },
        { index: 4, hide: false, label: "Twitter", component: <ParlamentarCarouselTabsTwitter key={4} index={4} activeTab={activeTab} twitterUsername={props.twitterUsername} /> },    
        { index: 5, hide: false, label: "Outros", component: <ParlamentarCarouselTabsOutros key={5} index={5} activeTab={activeTab} id_parlamentar={props.id_parlamentar} permissaoUsuario={props.permissaoUsuario} /> },
    ]

        :
        [

            { index: 0, hide: false, label: "Acontecimentos", component: <ParlamentarCarouselTabsAcontecimentos key={0} index={0} activeTab={activeTab} id_parlamentar={props.id_parlamentar} /> },
            { index: 1, hide: false, label: "Demandas", component: <ParlamentarCarouselTabsDemandas key={1} index={1} activeTab={activeTab} id_parlamentar={props.id_parlamentar} /> },

        ]

    return (
        <CarouselCustom itens={itens} activeTab={activeTab} onChange={setActiveTab} />
    );
}