import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { usePallet } from '../../../contexts/PalletContext';

const LabelCount = styled.span`
    padding: 3px 12px;
    //margin-left: 10px;
    border-radius: 26px;
    background-color: ${(props => props.active ? "white" : "#F2F2F2")};
    color: ${(props => props.active ? props.pallet.textColorQuintenary : "#9B9B9B")};
    font-size: ${(props => props.pallet.fontSize.smallButton)};
    font-weight: ${(props => props.pallet.fontWeight.subtitle)};
    width: fit-content;
`;

const ButtonCountPendencia = (props: {
    item: {
        key: string,
        label: string,
    },

    active: boolean,
    total: number,
    style?: React.CSSProperties,
    onClick: (event: any) => void,
}) => {
    const { pallet } = usePallet();

    return (
        <Button
            onClick={(e) => { props.onClick(e) }}
            key={props.item.key}
            title={props.item.label}
            variant="outlined"
            style={{
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.fontSize.titleWidgets}`,
                fontWeight: pallet.general.weight_bold,
                //backgroundColor: `${props.active ? pallet.textColorQuintenary : "#FFFFFF"}`,
                color: `${props.active ? "white" : "#9B9B9B"}`,
                border: `1px solid ${props.active ? pallet.general.color2 : "#D8D8D8"}`,
                borderRadius: "6px",
                alignItems: "center",
                letterSpacing: "1px",
                padding: "5 7px",
                marginRight: "20px",
                ...props.style
            }}
        >

            {props.item.label}
            <span>&nbsp;&nbsp;</span>
            <LabelCount pallet={pallet} active={props.active} key={props.item.key} title={props.item.label}>{props.total}</LabelCount>


        </Button>
    )
}



export default ButtonCountPendencia;