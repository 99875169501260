import React, { useState } from 'react';
import sty from "styled-components";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, TextField, Typography, Button } from '@mui/material';
import styled from "styled-components";
import useStyles from '../../../../components/layout/Styles';
import { usePallet } from '../../../../contexts/PalletContext';
import { alpha } from '@mui/material/styles';
import { ListAlt, MoreHoriz } from "@material-ui/icons"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IParlamentar } from "../../../../models/Parlamentar.Model"
import { isMobile } from 'react-device-detect';
import { formatDate } from '../../../../utils/dateUtils';
// import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import MenuButtonAccordion from "../../../../components/MenuButtonAccordion"
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import { CalendarToday } from '@material-ui/icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccordionTextDataAcaoInfluenciaParlamentarDetail from './../../../parlamentar/_components/AccordionTextDataAcaoInfluenciaParlamentarDetail';
import AccordionTextDataAcaoInfluenciaDetail from './../../../proposicao/_components/acaodeinfluencia/AccordionTextDataAcaoInfluenciaDetail';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Visibility } from "@material-ui/icons";
import ProposicaoAcaoInfluenciaEdicao from './../../../proposicao/_components/acaodeinfluencia/ProposicaoAcaoInfluenciaEdicao';
import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import { useAlert } from '../../../../contexts/AlertContext';
import { utimes } from 'fs';
import ProposicaoAcaoDeInfluenciaService from '../../../../services/ProposicaoAcaoDeInfluenciaService';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';
// const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();


const DotPosicionamento = sty.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: ${isMobile ? "4px" : "25px"};
`;

const StyledMenu = styled((props: MenuProps) => (
    <Menu

        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 135,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '5px 0px',
            minWidth: 140,

        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
            },
        },
    },
}));


function AccordionTextDataAcaoInfluenciaProposicao(props: {
    lista: Array<{
        acao_influencia_ambito_id?: number,
        acao_influencia_atualizado_em?: Date,
        acao_influencia_casa_legislativa?: number,
        acao_influencia_descricao?: string,
        acao_influencia_id?: number,
        acao_influencia_idResponsavel?: number,
        acao_influencia_prazo?: Date,
        acao_influencia_responsavel?: string,
        acao_influencia_resultado: number,
        comissoes_sigla: string,
        comissoes_descricao: string,
        influenciados?: Array<{
            id: number,
            nome: string,
            tratamento: string
        }>
    }>,
    onChange?: () => void,
    propDescricao?: string,
}): JSX.Element {

    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    const [infoList, setInfoList] = useState<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const [excluidos, setExcluidos] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { user } = useAuthorization();

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined)
    };

    const setPage = (event: object, page: number) => setCurrentPage(page);

    async function loadResultadoLista() {
        props?.onChange();
    }

    const deletarAcaoInfluencia = (id) => {
        ProposicaoAcaoDeInfluenciaService.Delete(id).then(res => {

            if (res) {
                NewAlert('success', 'Ação de Influencia apagada com sucesso')
                handleClose()
            } else {
                NewAlert('error', 'Erro ao apagar, verifique os campos e apague novamente');
            }
        })
            .then(x => loadResultadoLista())
    }

    const corResultado = (value: string) => {
        switch (value) {
            case "semExito":
                return `${pallet.charts.red}`;
            case "pendente":
                return `${pallet.charts.yellow}`;
            case "exitoParcial":
                return `${pallet.charts.blue}`;
            case "exito":
                return `${pallet.charts.green}`;
            default:
                return 'white';
        }
    };



    const ChipPrioridade = styled(Grid)`
    max-width: ${isMobile ? "max-content" : "max-content"};
    place-content: center;
    background-color: ${pallet.backgroundColorSecondary};
    color: #9B9B9B;
    text-transform: uppercase;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${pallet.fontFamily.general};
    font-size: ${pallet.general.size12};
    padding: 5px 8px 5px 8px;
    border: 1px solid #D8D8D8;
    line-height: 15px;
    letter-spacing: 1px;
    font-weight: ${pallet.general.weight_600};
    box-sizing: border-box;  
    margin-bottom: ${isMobile ? "5px" : "5px"};
    margin-top: ${isMobile ? "0px" : "10px"};    
    margin-right: ${isMobile ? "30px" : "10px"};
    
    `;


    const Assunto = styled.div`
    font-size: ${pallet.general.size12};
    color: ${pallet.color.secundary.font};
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    align-self: center;
    max-height: ${isMobile ? "80px" : "none"};
    max-width: ${isMobile ? "200px" : "550px"}; 
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    margin-left: ${isMobile ? "10px" : "0px"};
    `;

    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    justify-content: center;
    text-transform: uppercase;
    padding-top: 10px;
    height: ${isMobile ? "64px" : "64px"};
    flex-flow: row-reverse;
    background: ${pallet.backGroundPageColorPrimary};
    padding-left: ${isMobile ? "0px" : "20px"};

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
    `;



    return (
        <>
            {props.lista?.map((item) => {
                return (
                    <Grid key={item.acao_influencia_id} >
                        <Grid
                            textAlign={"end"}
                            style={{ position: "relative", paddingBottom: "24px" }}
                        >


                            {/* ----------- BOTAO 3 pontinhos -------------- */}
                            <Button
                                disableElevation
                                variant="contained"
                                size="medium"
                                style={{
                                    backgroundColor: `${pallet.backgroundColorTertiary}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: pallet.fontWeight.lighter,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    color: `${pallet.color.quaternary.font}`,
                                    borderRadius: "6px",
                                    width: "25px",
                                    position: "absolute",
                                    top: "48px",
                                    height: "20px",
                                    right: "5px",
                                    zIndex: 10,
                                    // border: "1px solid red",
                                }}
                                // onClick={(e) => handleClick(e, item.id)}
                                onClick={(e) => handleClick(e, item?.acao_influencia_id)}
                                endIcon={<Grid style={{ fontSize: "30px", marginTop: "9px", marginRight: "10px" }}>
                                    <MoreHoriz fontSize='inherit' />
                                </Grid>
                                }
                            >
                            </Button>
                            <StyledMenu style={{
                                border: "1px solid lightgrey",
                                borderRadius: "6px",
                                   }}
                                id={`mmenu-${item?.acao_influencia_id}`}
                                MenuListProps={{
                                    'aria-labelledby': `button-${item?.acao_influencia_id}`,
                                }}
                                anchorEl={anchorEl}
                                open={open === item?.acao_influencia_id}
                                onClose={handleClose}
                            >
                                <MenuItem  style={{ borderRadius: "6px", minHeight: "45px" }}>
                                    <ProposicaoAcaoInfluenciaEdicao
                                        id_acao_influencia={item?.acao_influencia_id}
                                        propDescricao={props?.propDescricao}
                                        reloadList={loadResultadoLista}
                                    />
                                </MenuItem>


                                <Grid>
                                    < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "2px" : "5px"}`, marginBottom: `${isMobile ? "2px" : "5px"}` }} />
                                </Grid>


                                <MenuItem
                                    style={{
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.fontWeight.button,
                                        fontSize: `${pallet.general.size14}`,
                                        color: `${pallet.color.tertiary.font}`,
                                        borderRadius: "6px",
                                        padding: "7px",

                                    }}>

                                    <ButtonDialogConfirmation
                                        styles={{
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.fontWeight.button,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            borderRadius: "6px 6px 0px 0px",
                                            textTransform: "capitalize"
                                        }}
                                        title={"CONFIRMAÇÃO"}
                                        title_button={"Apagar"}
                                        confirmation_button={"Apagar"}
                                        content={"APAGAR AÇÃO DE INFLUÊNCIA?"}
                                        message={"Esta ação não poderá ser desfeita."}
                                        confirmationEvent={() => user.id_permissao !==3 ? deletarAcaoInfluencia(item?.acao_influencia_id) :     NewAlert(
                                            "error",
                                            "Não é possível realizar está ação com este perfil."
                                        )}

                                    >
                                        <DeleteIcon style={{
                                            color: `${pallet.color.tertiary.font}`,
                                            marginRight: "16px",
                                            fontFamily: `${pallet.fontFamily.general}`,
                                        }} />

                                    </ButtonDialogConfirmation>
                                </MenuItem>
                            </StyledMenu>
                        </Grid >


                        {/* ---------------- COMECA O ACCORDION --------------- */}

                        <Grid >
                            <Accordion defaultExpanded={true} elevation={0}
                                style={{
                                    border: "1px solid #EAEAEA",
                                    zIndex: 9,
                                    maxWidth: "100%",
                                }}
                            >
                                <AccordionSum
                                    expandIcon={<ArrowDropDownIcon
                                        style={{
                                            marginLeft: `${isMobile ? "0px" : "0px"}`,
                                            color: `${pallet.general.color2}`,
                                            fontSize: `${pallet.general.size16}`,
                                        }}
                                    />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid container direction="row" alignItems="center" style={{
                                        paddingLeft: "5px",
                                        paddingTop: `${isMobile ? "0" : "10px"}`,
                                        marginBottom: `${isMobile ? "-10px" : "0px"}`,
                                        // border: "1px solid red"

                                    }} >
                                        <Grid container item style={{ marginLeft: "20px" }}>
                                            <Grid container direction="row" >
                                                <Grid xs={12} container item alignItems="flex-start">
                                                    <Grid item>
                                                        <CalendarToday style={{ color: `${pallet.backgroundColorPrimary}`, height: "22px", width: "20px", marginRight: `${isMobile ? "10px" : "10px"}`, marginTop: "3px", marginLeft: `${isMobile ? "0px" : "5px"}` }} />
                                                    </Grid>
                                                    <Typography className={classes.heading} style={{
                                                        color: `${pallet.textColorSecondary}`,
                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                        fontSize: `${pallet.general.size14}`,
                                                        fontWeight: pallet.general.weight_600,
                                                    }}>
                                                        <Grid item alignContent="center"
                                                            style=
                                                            {{
                                                                marginTop: "5px",
                                                                marginRight: `${isMobile ? "0" : "10px"}`
                                                            }}>
                                                            {formatDate(new Date(item.acao_influencia_atualizado_em), 'dd/MM/yyyy')}
                                                        </Grid>
                                                    </Typography>

                                                    {/* - - - - - - - - - AJUSTE DE TAG - LAYOUT - - - - - - - */}
                                                    {isMobile ?
                                                        <Grid item style={{ width: "100px" }} />
                                                        :
                                                        null
                                                    }
                                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}

                                                    <Grid item>
                                                        <Grid container style={{ border: "1px solid #d8d8d8", backgroundColor: "white", borderRadius: "20px", padding: "3px 10px", marginTop: "2px" }}>
                                                            <Grid
                                                                style={{
                                                                    backgroundColor: `${item.acao_influencia_resultado === 1 ? pallet.charts.green : item.acao_influencia_resultado === 2 ? pallet.charts.red : item.acao_influencia_resultado === 3 ? pallet.charts.blue : pallet.charts.yellow}`,
                                                                    borderRadius: "50%",
                                                                    height: "8px",
                                                                    width: "8px",
                                                                    marginTop: "5px"
                                                                }} />
                                                            <Grid style={{ marginLeft: "8px", fontFamily: `${pallet.general.fontFamily}`, fontSize: "12px" }}>
                                                                {item.acao_influencia_resultado === 1 ? "Êxito" : item.acao_influencia_resultado === 2 ? "Sem êxito" : item.acao_influencia_resultado === 3 ? "Êxito Parcial" : "Pendente"}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item alignSelf="center" >
                                                    <Typography style={{
                                                        color: `${pallet.textColorTertiary}`,
                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                        fontSize: `${pallet.general.size12}`,
                                                        fontWeight: pallet.general.weight_600,
                                                        lineHeight: "15px",
                                                        letterSpacing: "1px",
                                                        marginLeft: "20px",
                                                        paddingBottom: `${isMobile ? "8px" : "0px"}`
                                                    }}>
                                                    </Typography>

                                                </Grid>
                                                <Grid xs={12} xl={12} container item style={{
                                                    paddingBottom: `${isMobile ? "5px" : "10px"}`
                                                }}>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionSum>

                                <Divider />

                                <AccordionDetails style={{
                                    fontFamily: `${pallet.general.fontFamily}`
                                }}>
                                    <Grid>
                                        <AccordionTextDataAcaoInfluenciaDetail
                                            ambitoId={item.acao_influencia_ambito_id}
                                            casa_legislativa={item.acao_influencia_casa_legislativa}
                                            descricao={item.acao_influencia_descricao}
                                            acao_influencia_id={item.acao_influencia_id}
                                            acao_influencia_prazo={item.acao_influencia_prazo}
                                            acao_influencia_responsavel={item.acao_influencia_responsavel}
                                            influenciados={item.influenciados}
                                            resultado={item.acao_influencia_resultado}
                                            ambitoSigla={item.comissoes_sigla}
                                            ambitoDescricao={item.comissoes_descricao}
                                            reloadList={() => loadResultadoLista()}
                                        // closeMenu={() => handleClose()}
                                        />
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid >
                )
            })}

        </>
    );
}

export default AccordionTextDataAcaoInfluenciaProposicao;