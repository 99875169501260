import useStyles from "../../../../components/layout/Styles";
import { usePallet } from "../../../../contexts/PalletContext";
import filtersBackgroundImage from '../../../../../src/img/OrangeBackgroundHeader.png';





const ProposicaoAcaoPreparatoriaEspecificaHeader=()=> {
const classes = useStyles();
const { pallet } = usePallet();
    return(
                <div
                
                style={{
                backgroundImage:
                `url(${filtersBackgroundImage})`,
                height: "95px",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
                }}

                ></div>
    )

}

export default ProposicaoAcaoPreparatoriaEspecificaHeader;