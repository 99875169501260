import * as Yup from "yup";

const AgendaCadastroSchema = Yup.object({

    esfera: Yup.string().required({ name: "esfera", err: "'Esfera' é um campo obrigatório" }),

    casaFederal: Yup.string().when("esfera", {
        is: "F",
        then: Yup.string().required({ name: "casaFederal", err: "'Casa Legislativa' é um campo obrigatório" }),
        otherwise: Yup.string().nullable().optional(),
    }),


    ambito: Yup.number().when("casaFederal", {
        is: !null,
        then: Yup.number().required({
            name: "ambito", err: "'Ambito' é um campo obrigatório"
        }),
        otherwise: Yup.number().nullable().optional(),
    }),

    casaEstados: Yup.number().when("esfera", {
        is: !'E',
        then: Yup.number().required({
            name: "casaEstado", err: "'Casa Legislativa' é um campo obrigatório"
        }),
        otherwise: Yup.number().nullable().optional(),
    }),


    estado: Yup.number().when("esfera", {
        is: 'M',
        then: Yup.number().required({
            name: "estado", err: "'Estado' é um campo obrigatório"
        }),
        otherwise: Yup.number().nullable().optional(),
    }),

    casaMunicipios: Yup.number().when("estado", {
        is: !null,
        then: Yup.number().required({
            name: "estado", err: "'Estado' é um campo obrigatório"
        }),
        otherwise: Yup.number().nullable().optional(),
    }),

    data_inicio: Yup.date().required({ name: "dataInicio", err: "'Data do Evento' é um campo obrigatório" }),
    hora: Yup.string().required({ name: "hora", err: "'Hora do Evento' é um campo obrigatório" }),

    local: Yup.string().required({ name: "local", err: "'Local' é um campo obrigatório" }),
    descricao: Yup.string().required({ name: "descricao", err: "'Descrição' é um campo obrigatório" }),

    // tipo: Yup.string().required({ name: "tipo", err: "'Tipo' é um campo obrigatório" }),

    pauta: Yup.array().required().min(1, { name: "pauta", err: "Escolha um tipo e inclua ao menos uma Pauta" }),

});


export default AgendaCadastroSchema;
