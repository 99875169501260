import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import styled2 from "styled-components";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, ListItemText, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { usePallet } from "../../../../contexts/PalletContext";
import InputMultilineTextField from '../../../../components/InputMultilineTextField';
import SolidButton from '../../../../components/SolidButton';
import { isMobile } from 'react-device-detect';
import EditIcon from '@mui/icons-material/Edit';
import { useAlert } from '../../../../contexts/AlertContext';
import TCService from '../../../../services/TCService';
import TCTemaCorporativoGrupoCreateSchema from '../Grupo/TCTemaCorporativoGrupoEditSchema';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import TabelasService from '../../../../services/TabelasService';
import TCTemaCorporativoTopicoCreateSchema from './TCTemaCorporativoTopicoEditSchema';
import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';


const DotPrioridade = styled2.div`
background-color: ${(props => props.bg)};
border-radius: 50%;
height: 8px;
width: 8px;
margin-right: 25px;
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;



    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogs(props: {
    idSubtema: number,
    idregister: number,
    reloadList?: () => void
    closeMenu?: () => void
}) {

    const { NewAlert } = useAlert();
    const [open, setOpen] = React.useState(false);
    const { pallet } = usePallet();

    const [data, setData] = React.useState({});

    const [error, setError] = React.useState<Array<{ name: string, err: string }>>([]);
    const [nome, setNome] = React.useState("");

    const [subTemaPrioridade, setSubTemaPrioridade] = React.useState("");

    const [idSubTemaGrupo, setIdSubTemaGrupo] = React.useState("");
    const [idSubTemaTema, setIdSubTemaTema] = React.useState("");
    const [idSubTemaSubtema, setIdSubTemaSubtema] = React.useState("");


    const [checked, setChecked] = React.useState<boolean>(true);

    const [arrayGrupoTema, setArrayGrupoTema] = React.useState([]);
    const [arraySubTemaTema, setArraySubTemaTema] = React.useState([]);
    const [arraySubTemaSubtema, setArraySubTemaSubtema] = React.useState([]);


    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    React.useEffect(() => {
        TabelasService.getProposicaoGrupoTemas().then(
            res => {
                const data = []
                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                res.map(item => data.push({ key: item.id, label: item.descricao, value: item.id }))
                setArrayGrupoTema(() => [...data]);
            })

    }, []);

    React.useEffect(() => {
        if (idSubTemaGrupo) {
            TabelasService.getProposicaoTemasByGrupo(Number(idSubTemaGrupo)).then(
                res => {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map(item => data.push({ key: item.id, label: item.descricao, value: item.id }))
                    setArraySubTemaTema(() => [...data]);
                }
            )
        }
    }, [idSubTemaGrupo])

    React.useEffect(() => {
        if (idSubTemaTema) {
            TabelasService.getProposicaoSubTemasByTema(Number(idSubTemaTema)).then(
                res => {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map(item => data.push({ key: item.id, label: item.descricao, value: item.id }))
                    setArraySubTemaSubtema(() => [...data]);
                }
            )
        }
    }, [idSubTemaTema])

    const handleClickOpen = async (idTopico, idSubtema) => {
        await TCService.GetTopico(idTopico, idSubtema).then(res => {
            setChecked(res?.data[0].tema_topico_ativo)
            setNome(res?.data[0].tema_topico_descricao)
            setIdSubTemaGrupo(String(res?.listInfoTema.grupo_id))
            setIdSubTemaTema(String(res?.listInfoTema.id))
            setIdSubTemaSubtema(String(res?.listInfoTema.subtema_id))
            // setSubTemaPrioridade(res?.data[0].subtema_prioridade_id)
        });
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
        props?.reloadList()
        props?.closeMenu()
    };

    const handleNomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNome(event.target.value)
    };

    const handleOnChangeActive = () => {
        setChecked(!checked)
    }

    const handleChangeGrupo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setArraySubTemaTema([]);
        setArraySubTemaSubtema([]);
        setIdSubTemaGrupo(event.target.value);
    };

    const handleChangeTema = (event: React.ChangeEvent<HTMLInputElement>) => {
        setArraySubTemaSubtema([]);
        setIdSubTemaTema(event.target.value)
    };

    const handleChangeSubtema = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIdSubTemaSubtema(event.target.value)
    };



    const salvarEdicaoTopico = async (e) => {
        e.preventDefault();
        const data = {
            descricao: nome,
            id_tema_grupo: idSubTemaGrupo,
            id_tema: idSubTemaTema,
            id_subtema: idSubTemaSubtema,
            id_topico: props.idregister,
            ativo: checked
        }

        setError(() => [])

        data && TCTemaCorporativoTopicoCreateSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));
        setData(data)

        TCTemaCorporativoTopicoCreateSchema.validate(data).catch(function (err) {
        });

        if (TCTemaCorporativoTopicoCreateSchema.isValidSync(data)) {
            await TCService.UpdateTopico(data, props?.idregister)
                .then(res => {
                    if (res) {
                        handleClose()
                        NewAlert('success', 'Tópico editado com sucesso')
                        props?.reloadList()
                        props?.closeMenu()
                    } else {
                        NewAlert('error', 'Erro ao editar, verifique os campos e salve novamente');
                    }
                })
            // .then(x => loadAcontecimentos(props.id_Proposicao))
        } else {
            NewAlert('error', 'Algum campo está incorreto ou vazio, verifique!');
        }
    }


    const { user } = useAuthorization();
    return (
        <div>
            <Button onClick={() => {
                user.id_permissao !== 3 ?

                    handleClickOpen(props.idregister, props.idSubtema) : NewAlert(
                        "error",
                        "Não é possível realizar está ação com este perfil."
                    )

            }}
                style={{
                    marginLeft: "-7px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    paddingRight: "85px",
                    marginRight: "-10px"
                }}>
                <EditIcon style={{
                    color: `${pallet.color.tertiary.font}`,
                    marginRight: "16px",
                    fontFamily: `${pallet.fontFamily.general}`,
                }} />

                <div style={{ fontSize: 0 }}>@</div>Editar
            </Button>
            <Dialog
                fullWidth={true}
                id={`dialog-${props.idregister}`}
                onClose={handleClose}
                //aria-labelledby="customized-dialog-title"
                open={open}
            >

                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Grid style={{
                        fontFamily: `${pallet.fontFamily}`,
                        fontSize: `${pallet.general.size18}`,
                        color: `${pallet.textColorSecondary}`,
                        padding: "12px",
                        marginTop: "7px",
                    }}>
                        EDITAR TÓPICO
                    </Grid>
                </BootstrapDialogTitle>

                <DialogContent dividers style={{ padding: "0px" }} >

                    <Grid style={{ padding: "30px 25px 0px 25px" }}>
                        Informações Gerais
                    </Grid>

                    <Grid style={{ padding: "30px 25px 0px 25px" }}>

                        <InputMultilineTextField
                            // defaultValue={dataAcontecimentos?.acontecimentos_acontecimento}
                            type={"text"}
                            maxRows={2}
                            value={nome}
                            onChange={handleNomeChange}
                            id={"nome-grupo"}
                            label="NOME"
                        />
                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                            <ErrorMessage name='nome' errors={error} />
                        </Grid>

                    </Grid>

                    <Grid style={{ padding: "30px 25px 30px 25px" }}>
                        <FormGroup>
                            <FormControlLabel control={
                                <Switch
                                    checked={checked}
                                    onChange={handleOnChangeActive}
                                />
                            } label={
                                <ListItemText>{checked ? "Ativo" : "Inativo"}</ListItemText>
                            } />
                        </FormGroup>
                    </Grid>
                    <Grid style={{ padding: "0px 25px 0px 25px" }}>
                        Associar a
                    </Grid>
                    <Grid style={{ padding: "30px 25px 0px 25px" }}>

                        <SelectTextFieldA
                            name={"grupo_tema"}
                            value={idSubTemaGrupo}
                            onChange={handleChangeGrupo}
                            options={arrayGrupoTema}
                            label={"GRUPO"}
                            id={"associar-grupo"}
                            focused={true}
                            placeholder={"Selecione"}
                        />
                    </Grid>
                    <Grid style={{ padding: "30px 25px 0px 25px" }}>
                        <SelectTextFieldA
                            name={"tema_tema"}
                            value={idSubTemaTema}
                            onChange={handleChangeTema}
                            options={arraySubTemaTema}
                            label={"TEMA"}
                            id={"associar-tema"}
                            focused={true}
                            placeholder={"Selecione"}
                        />
                    </Grid>
                    <Grid style={{ padding: "30px 25px 30px 25px" }}>
                        <SelectTextFieldA
                            name={"subtema_tema"}
                            value={idSubTemaSubtema}
                            onChange={handleChangeSubtema}
                            options={arraySubTemaSubtema}
                            label={"SUBTEMA"}
                            id={"associar-subtema"}
                            focused={true}
                            placeholder={"Selecione"}
                        />
                    </Grid>

                </DialogContent>
                <DialogActions style={{
                    padding: "30px",
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`
                }}>

                    <ButtonDialogConfirmation
                        styles={{
                            borderRadius: "6px 6px 0px 0px",
                            marginRight: "20px",
                            textTransform: "capitalize",
                            color: `${pallet.general.color1}`,
                        }}
                        title={"CONFIRMAÇÃO"}
                        title_button={"Cancelar"}
                        content={"CANCELAR EDIÇÃO?"}
                        message={"Esta ação não poderá ser desfeita."}
                        confirmation_button={"Cancelar"}
                        confirmationEvent={() => {
                            handleClose()
                        }}
                    />

                    <SolidButton
                        onClick={salvarEdicaoTopico}
                        onChange={props?.reloadList}
                        color={`${pallet.textColorPrimary}`}
                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                        fontSize={`${pallet.general.size14}`}
                        title={"SALVAR"}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}