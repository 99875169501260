import { MoreHoriz } from "@material-ui/icons"
import EditIcon from '@mui/icons-material/Edit'
import PasswordIcon from '@mui/icons-material/Password'
import { Divider, Grid } from "@mui/material"
import { isMobile } from "react-device-detect"
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuButtonIconNoText from "../../../components/MenuButtonIconNoText"
import { useNavigate } from 'react-router-dom';
import { IUser } from "../../../models/User.Model"
import Button from '@mui/material/Button';
import { styled, alpha } from "@mui/material/styles";
import { PalletProvider, usePallet } from "../../../contexts/PalletContext";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import GerUsuariosEdicao from "./GerUsuariosEdicao";
import GerUsuariosEdicaoPassWord from "./GerUsuariosEdicaoPassWord"
import PopupState from "material-ui-popup-state"

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    ".MuiListItemText-primary": {
        fontSize: 0
    },
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "0px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));


export const GerUsuariosConsultaBotoes = (props: { usuario: IUser, onChange: () => void }) => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { pallet } = usePallet();
    const [open, setOpen] = React.useState();
    // const editar = (props?.parlamentar?.id) => {
    //     console.log("EDITANDO");
    // };

    // onClick={() => (deletarEdicaoAcontecimentos(item.id))}
    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
        props.onChange();
    };
    return (
        <Grid item>
            <div>
                <Grid container item justifyContent="flex-end">

                    <Grid style={{
                        marginTop: "10px",
                        marginRight: `${isMobile ? "10px" : "50px"}`,
                    }}>
                        {(props?.usuario?.id) ?
                            // <MenuButtonIconNoText
                            //     iconButton={<MoreHoriz />}
                            //     itens={[
                            //         { path: `/usuario/${props?.usuario?.id}/cadastro`, icon: <EditIcon />, label: 'Editar perfil', },
                            //         { path: `/usuario/${props?.usuario?.id}/cadastro`, icon: <PasswordIcon />, label: 'Alterar senha', },
                            //     ]}

                            // />
                            <>
                                <Button
                                    id={`button`}
                                    aria-controls={
                                        anchorEl ? `mmenu` : undefined
                                    }
                                    disableElevation
                                    variant="contained"
                                    size="large"
                                    style={{
                                        backgroundColor: `${pallet.general.color3}`,
                                        textTransform: "capitalize",
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.fontWeight.button,
                                        fontSize: `${pallet.fontSize.smallButton}`,
                                        color: `${pallet.color.secundary.font}`,
                                        width: "25px",
                                        height: "40px",
                                    }}
                                    onClick={(e) => handleClick(e, 1)}
                                    endIcon={
                                        <Grid
                                            style={{
                                                fontSize: "30px",
                                                color: `${pallet.general.color4}`,
                                                marginTop: "9px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <MoreHoriz fontSize="inherit" />
                                        </Grid>
                                    }
                                ></Button>

                                <StyledMenu
                                    id={`mmenu`}
                                    MenuListProps={{
                                        "aria-labelledby": `button`,
                                    }}
                                    anchorEl={anchorEl}
                                    open={open === 1}
                                    onClose={handleClose}
                                >
                                    <MenuItem>
                                        <GerUsuariosEdicao
                                            usuario={props.usuario}
                                            closeMenu={() => handleClose()}
                                        />
                                    </MenuItem>
                                    <Divider
                                        style={{
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                        }}
                                    />
                                    {/* <MenuItem onClick={() => navigate(`${props?.eventos?.agenda_id}/cadastro`)}
                                        style={{
                                            paddingTop: "8px",
                                            paddingBottom: "8px",
                                            fontFamily: `${pallet.fontFamily.general}`,
                                            fontWeight: pallet.fontWeight.lighter,
                                            fontSize: `${pallet.fontSize.titleWidgets}`,
                                        }}>
                                        <PasswordIcon />
                                        Alterar Senha
                                    </MenuItem> */}

                                    <MenuItem>
                                        <GerUsuariosEdicaoPassWord
                                            usuario={props.usuario}
                                            closeMenu={() => handleClose()}                                            
                                        />                                        
                                    </MenuItem>

                                </StyledMenu>
                            </>

                            :
                            null
                        }
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}