import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../../components/carousel/TabPanel";
import { usePallet } from "../../../../contexts/PalletContext";
import ProposicaoService from "../../../../services/ProposicaoService";
import ProposicaoAcaoPreparatoria from "../acaopreparatoria/ProposicaoAcaoPreparatoria";
import { IProposicao } from "../../../../models/Proposicao.Model";


const ProposicaoCarouselTabsAcaoPreparatoria = (props: {
    proposicao?: IProposicao,
    id_proposicao: number,
    activeTab: any,
    index: number,
    lista?: Array<any>,
    onChange?: any,
    permissaoUsuario?: number

}) => {
    const { pallet } = usePallet();



    return (

        <TabPanel value={props.activeTab} index={props.index}>
            <Paper style={{ borderRadius: "6px", paddingTop: `${isMobile ? "0px" : "30px"}` }}>
                <ProposicaoAcaoPreparatoria
                    lista={props?.lista}
                    proposicao={props?.proposicao}
                    id_proposicao={props.id_proposicao}
                    index={props.index}
                    activeTab={props.activeTab}
                    onChange={props.onChange}
                    permissaoUsuario={props.permissaoUsuario}
                />
            </Paper>
        </TabPanel>
    )
}


export default ProposicaoCarouselTabsAcaoPreparatoria;