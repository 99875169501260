import React from 'react';
import { ETimeZoneTypes } from '../utils/dateUtils';

interface ITimeZoneProviderProps { children: React.ReactNode; }
interface ITimeZoneContextResult { timeZoneType: ETimeZoneTypes, setTimeZoneType: (zone: ETimeZoneTypes) => void }

const Context = React.createContext<ITimeZoneContextResult>(undefined);

const TimeZoneProvider = ({ children }: ITimeZoneProviderProps) => {

  const [timeZoneType, setTimeZoneType] = React.useState<ETimeZoneTypes>(ETimeZoneTypes.HORA_BRASILIA)

  return (
    <Context.Provider value={{ timeZoneType, setTimeZoneType }}>
      {children}
    </Context.Provider >
  );
}

export { TimeZoneProvider };
export const useTimeZone = () => React.useContext(Context);