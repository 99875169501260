import { Typography, Grid, Link } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import NumberFormat from 'react-number-format';
import { color } from '@mui/system';

export default function ImpactoFinanceiroData(props: { title: string, value: number,link?: string }) {
    const { pallet } = usePallet();
    const colorBall = "lightgreen"
    return (
        <div>
            <Grid container justifyContent="space-between" style={{ padding: "20px" }}>
                <Grid item style={{ paddingTop: "7px" }}>
                    <div style={{ height: "10px", width: "10px", borderRadius: 1000, backgroundColor: `${colorBall}` }}></div>
                </Grid>
                <Grid item style={{ paddingLeft: "10px" }}>
                    <Typography align={'center'} style={{
                            fontFamily: `${pallet.fontFamily.general}`,
                            color: `${pallet.general.color1}`
                    }}>
                        <Link href={props.link} onClick={() => { }} style={{
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontWeight: pallet.fontWeight.button,
                            textDecoration: "none",
                            color: `${pallet.color.secundary.font}`
                        }}> 
                        {props.title}
                        </Link> 
                    </Typography>
                </Grid>
                <Grid item style={{ paddingTop: "2.7px" }}>
                    <Typography align={'center'} style={{
                        paddingLeft: "10px",
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontWeight: pallet.fontWeight.text,
                        color: `${pallet.color.quaternary.font}`
                    }}>
                        <NumberFormat
                            value={props.value}
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            prefix={'R$ '}
                        />
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}