import * as http from '../helpers/http';

class ClientesService {

    getCliente = async (): Promise<{ id: number, nome: string }> => {
        const result = await http.get(`/cliente`);
        if (result.success) { return result.data }
        return undefined;
    };

    getDepartamentosList = async (): Promise<Array<{ id: number, descricao: string }>> => {
        const result = await http.get(`/cliente/departamento`);
        if (result.success) { return result.data }
        return undefined;
    };

    
    getDepartamento = async (id_dep:number): Promise<any> => {
        const result = await http.get(`/cliente/departamentos/${id_dep}`);
        if (result.success) { return result.data }
        return undefined;
    };

    DelDepartamento = async (id_dep:string): Promise<number> => {
        const result = await http.del(`/cliente/departamentos/${id_dep}`);
        if (result.success) { return result.data }
        return 0;
    };



}

export default new ClientesService();
