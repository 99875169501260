import { Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import PieChartRelatorio from '../PieChartRelatorio';
import { usePallet } from "../../../../contexts/PalletContext";
import legis_agenda from "../../../../img/logo_legis_alta.png";
import PrintIcon from '@mui/icons-material/Print';
import { isMobile } from 'react-device-detect';
import legis_agenda2 from "../../../../img/legis_agenda2.png";
import RelatorioService from "../../../../services/RelatoriosService";
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/utils';
import CircularLoading from '../../../../components/CircularLoading';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


export default function RelatorioInterlocutorAcoesDeInfluencia() {
    const { pallet } = usePallet();
    const { dtIni } = useParams() as any;
    const { dtFin } = useParams() as any;
    const [loading, setLoading] = useState(true)

    let totalGeral = 0;
    const[totalForaMap,setTotalForaMap] = useState(0);

    let filter = useQuery().get('filter');
    const [filtro, setFiltro] = useState(filter);
    //const [resTots, setResTots] = useState([])
    const [resList, setResList] = useState([])

    //let esferaTipo = filter.substring(27, 29)
    let filtrotitle = []

    try { filtrotitle = JSON.parse(filter) } catch { filtrotitle = [] };
    
    let esferaTipo = (filtrotitle.length>0 ? filtrotitle[0].value : "Geral")

    

    // console.log(esferaTipo === `E` ? true : false)

    const Imprimir = () => {
        window.print()
    }

    React.useEffect(() => {
        setLoading(true)

        if (
            String(format(new Date(dtIni), "yyyy")).slice(0, 1) !== "0" &&
            String(format(new Date(dtFin), "yyyy")).slice(0, 1) !== "0"
        ) {

            RelatorioService.GetInterlocutorAcaoInfluencia(
                format(new Date(dtIni), "yyyy-MM-dd"),
                format(new Date(dtFin), "yyyy-MM-dd 23:59:00.000"),
                String(filtro)
            ).then(async res => {

                //console.log("Respostaaaaaaaaaaaaaaa.........", res)
                //await setResTots(res?.data?.resTots)
                await setResList(res?.data)

                // setTimeout(() => {
                await setLoading(false)
                // }, 500);
            })
        }



        // resList.reduce((prev,prox) =>{

        // })  

        
        


      
    }, []);

    React.useEffect(() => {

        resList?.map(x => {
                
            totalGeral +=  Number(x.ai_tot) 
            

            setTotalForaMap(totalGeral)

        })
    }, [resList]);
    

    const CheckEsfera = (select) => {
        // console.log(select)
        switch (select) {
            case 'F':
                return 'Federal'
            case 'E':
                return 'Estadual'
            case 'M':
                return 'Municipal'
            default:
                return 'Federal'
        }
    }



    // console.log(filter.substring(27, 29)) 
    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <img style={{ width: '100px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                Relatório / {esferaTipo}
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Interlocutores envolvidos em Ações de Influência
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            <tr >
                                <td>
                                    {/* if countCD countSF countCN nao forem undefined */}
                                    {loading ?
                                        <CircularLoading
                                            size={100}
                                            top={isMobile ? "37%" : "40%"}
                                            left={isMobile ? "6%" : "16%"}
                                            zIndex={2}
                                        />
                                        :
                                        <Grid style={{
                                            width: '990px',
                                            marginTop: '30px',
                                            border: `1px solid #e0e0e0`,
                                            borderRadius: '7px'
                                        }}>
                                            <Grid container>
                                                <Grid item>
                                                    <Grid style={{
                                                        borderTopLeftRadius: '7px',
                                                        borderBottomLeftRadius: '7px',
                                                        backgroundColor: `${pallet.backgroundColorQuintenary}`,
                                                        padding: '15px',
                                                        marginTop: '-1px',
                                                        width: '70px',
                                                    }}>
                                                        <CalendarTodayIcon style={{
                                                            marginTop: '3px',
                                                            marginLeft: '8px',
                                                            color: `${pallet.textColorSecondary}`
                                                        }} />
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid >
                                                        <Typography
                                                            style={{
                                                                color: `${pallet.color.quinternary.font}`,
                                                                fontSize: `${pallet.general.size16}`,
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.general.weight_bold,
                                                                paddingLeft: "20px",
                                                                paddingTop: '10px'
                                                            }}>
                                                            PERÍODO DE CRIAÇÃO
                                                        </Typography>

                                                    </Grid>
                                                    <Grid>
                                                        <Grid container>
                                                            <Grid >
                                                                <Typography
                                                                    style={{
                                                                        color: `${pallet.color.secundary.font}`,
                                                                        fontSize: `${pallet.general.size12}`,
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.general.weight_bold,
                                                                        paddingLeft: "20px"
                                                                    }}>
                                                                    {String(format(new Date(dtIni), 'dd/MM/yyyy'))}
                                                                </Typography>

                                                            </Grid>
                                                            <Grid >
                                                                <Typography
                                                                    style={{
                                                                        color: `${pallet.color.secundary.font}`,
                                                                        fontSize: `${pallet.general.size12}`,
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.general.weight_bold,
                                                                        paddingLeft: "5px"
                                                                    }}>
                                                                    à
                                                                </Typography>

                                                            </Grid>
                                                            <Grid >
                                                                <Typography
                                                                    style={{
                                                                        color: `${pallet.color.secundary.font}`,
                                                                        fontSize: `${pallet.general.size12}`,
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.general.weight_bold,
                                                                        paddingLeft: "5px"
                                                                    }}>
                                                                    {String(format(new Date(dtFin), 'dd/MM/yyyy'))}
                                                                </Typography>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{marginLeft:'10px'}}>
                        TOTAL DE INTERLOCUTORES: {totalForaMap}
     

                    </Grid>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '250px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.quinternary.font}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            INTERLOCUTOR
                                        </Grid>
                                        <Grid style={{
                                            width: '148px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.quinternary.font}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            ÊXITO
                                        </Grid>
                                        <Grid style={{
                                            width: '148px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.quinternary.font}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            ÊXITO PARCIAL
                                        </Grid>
                                        <Grid style={{
                                            width: '148px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.quinternary.font}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            SEM ÊXITO
                                        </Grid>
                                        <Grid style={{
                                            width: '148px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.quinternary.font}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            PENDENTE
                                        </Grid>
                                        <Grid style={{
                                            width: '148px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.quinternary.font}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            TOTAL
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {resList?.map(x => {

                            let linelist = {
                                inter_descricao: "",
                                res_exito: 0,
                                res_semexito: 0,
                                res_exitoparcial: 0,
                                res_pendente: 0,
                                ai_tot: 0
                            }

                            //let totdep: number = 0;

                            linelist.inter_descricao = x.par_nome


                            x.resultados?.map(item => {

                                //totdep += Number(item.tot)

                                switch (item.resposta) {
                                    case 1:
                                        linelist.res_exito +=  1
                                        break;
                                    case 2:
                                        linelist.res_semexito += 1
                                        break;
                                    case 3:
                                        linelist.res_exitoparcial += 1
                                        break;
                                }

                                if (item.resposta === 99 || item.resposta === 0 || item.resposta === 4 || item.resposta === null) {
                                    linelist.res_pendente += 1
                                }


                            })

                            //linelist.dep_tot = Number(totdep)
                            linelist.ai_tot = Number(x.ai_tot)

                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '65px',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '250px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {linelist?.inter_descricao}
                                                </Grid>

                                                <Grid style={{
                                                    width: '148px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {linelist?.res_exito}
                                                </Grid>
                                                <Grid style={{
                                                    width: '148px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {linelist?.res_exitoparcial}
                                                </Grid>
                                                <Grid style={{
                                                    width: '148px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {linelist?.res_semexito}
                                                </Grid>
                                                <Grid style={{
                                                    width: '148px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {linelist?.res_pendente}
                                                </Grid>
                                                <Grid style={{
                                                    width: '148px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {linelist?.ai_tot}
                                                </Grid>

                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>

                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
