import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../components/carousel/TabPanel";
import ConteudoSabado from "./ConteudoSabado";

const AgendaCarouselTabsSabado = (props: { activeTab: any, index: number,  dateStartReference: Date, eventListTodayMaisCinco: Array<any>, loadEventos: () => void, loading: boolean}) => {

    return (
        <Grid style={{ borderRadius: "6px"}}>
            <TabPanel value={props.activeTab} index={props.index}>
                <ConteudoSabado index={props.index} activeTab={props.activeTab} dateStartReference={props?.dateStartReference} eventListTodayMaisCinco={props?.eventListTodayMaisCinco} loadEventos={props?.loadEventos} loading={props?.loading} />
            </TabPanel>
        </Grid>
    )
}

export default AgendaCarouselTabsSabado;