import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import TabPanel from "../../../../components/carousel/TabPanel";
import { usePallet } from "../../../../contexts/PalletContext";
import ProposicaoService from "../../../../services/ProposicaoService";
import ProposicaoAcaoDeInfluencia from "../acaodeinfluencia/ProposicaoAcaoDeInfluencia";


const ProposicaoCarouselTabsAcaoDeInfluencia = (props: { id_proposicao: number, activeTab: any, index: number, propDescricao: string }) => {
    const { pallet } = usePallet();



    return (
        <Paper style={{ borderRadius: "6px", marginTop: `${isMobile ? "0px" : "0px"}`}}>
            <TabPanel value={props.activeTab} index={props.index}>
                <ProposicaoAcaoDeInfluencia id_proposicao={props.id_proposicao} index={props.index} activeTab={props.activeTab} propDescricao={props?.propDescricao} />
            </TabPanel>
        </Paper>

    )
}


export default ProposicaoCarouselTabsAcaoDeInfluencia;