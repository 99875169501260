import * as React from 'react';
import Button from '@mui/material/Button';
import { usePallet } from '../contexts/PalletContext';
import { useNavigate } from 'react-router-dom';


export default function ButtonOutLine(props: { path: string }) {
  const { pallet } = usePallet();
  const navigate = useNavigate();


  return (
    <Button style={{
      backgroundColor: `${pallet.backGroundPageColorSecondary}`,
      color: `${pallet.textColorSecondary}`,
      border: `1px solid ${pallet.textColorSecondary}`,
      fontWeight: pallet.general.weight_600,
      padding: "10px 20px 10px 20px",
      fontFamily: `${pallet.general.fontFamily}`,
    }} variant="outlined" onClick={() => navigate(props.path)}>CADASTRAR</Button>
  );
}