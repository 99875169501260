import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { PalletProvider, usePallet } from "../../../../contexts/PalletContext";
import useStyles from "../../../../components/layout/Styles";
import ChatIcon from "@mui/icons-material/Chat";
import { fontWeight } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import { MoreHoriz } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ComentarioModal from "./ComentarioModal";
import { IComentario } from "../../../../models/Proposicao.Model";
import ComentarioService from "../../../../services/ComentarioService";
import { formatDate } from "../../../../utils/dateUtils";
import UsuarioService from "../../../../services/UsuarioService";
import { ILoggedUser } from "../../../../models/User.Model";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import InputMultilineTextField from "../../../../components/InputMultilineTextField";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import SolidButton from "../../../../components/SolidButton";
import AccordionComentario from "./AccordionComentario";
import ComentarioBox from "./ComentarioBox";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import { EComentarioModulos } from "../../../../models/Proposicao.Model";
import CircularLoading from "../../../../components/CircularLoading";

export default function ProposicaoAcaoPreparatoriaEspecificaComentario(props: {
  acao: IAcaoPreparatoria;
}) {
  const classes = useStyles();
  const { pallet } = usePallet();

  const [lista, setLista] = React.useState<Array<IComentario>>();
  const [listaUsuario, setListaUsuario] = React.useState<Array<ILoggedUser>>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState();
  const [novoComentario, SetNovoComentario] = React.useState();
  const [loading, setloading] = React.useState(false);

  useEffect(() => {
    UsuarioService.GetList().then((res) => {
      setListaUsuario(res);
    });

    reloadLista();
  }, []);

  const reloadLista = () => {
    setloading(true);
    ComentarioService.getComentario(
      String(props.acao.id),
      [{ key: "modulo", value: EComentarioModulos.AcaoPreparatoria }],
      1,
      8
    ).then(async (res) => {
      setLista(res.data);
    });
    setloading(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
    setOpen(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(undefined);
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      minWidth: 135,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "5px 0px",
        minWidth: 140,
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          // fontSize: 18,
        },
      },
    },
  }));

  const { user } = useAuthorization();

  const regexMencoes = /@@@([0-9]+)@@@/g;

  const trocarMencionados = (comentario) =>
    comentario.replace(regexMencoes, (...match) => {
      const id = parseInt(match[1]);

      const usuario = listaUsuario?.filter((item) => item.id === id)[0];

      const nome = usuario?.nome ? usuario?.nome : "Usuário não identificado";

      return `<b data-id="${id}">${nome}</b>`;
    });

  return (
    <Grid
      style={{
        fontFamily: pallet.fontFamily.general,
        fontSize: pallet.general.size14,
        paddingBottom: "20px",
      }}
    >
      <Grid
        container
        style={{
          paddingLeft: `${isMobile ? "20px" : "30px"}`,
          paddingRight: `${isMobile ? "0px" : "30px"}`,
          paddingTop: "30px",
          marginBottom: "10px",
        }}
      >
        <Grid style={{ width: "100%" }}>
          <AccordionComentario
            acao={props.acao}
            reloadLista={() => reloadLista()}
            listaUsuario={listaUsuario}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        {loading ? (
          <CircularLoading
            size={100}
            top={isMobile ? "37%" : "40%"}
            left={isMobile ? "6%" : "16%"}
            zIndex={2}
          />
        ) : (
          lista?.map((i) => {
            return (
              <ComentarioBox
                acao={props.acao}
                comentario={i}
                listaUsuario={listaUsuario}
                reloadLista={() => reloadLista()}
              />
            );
          })
        )}
      </Grid>
    </Grid>
  );
}
