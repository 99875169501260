import * as http from '../helpers/http';

class ParlamentarOutrosService {

  GetSummary = async (id: number): Promise<any> => {
    const result = await http.get(`/parlamentar/${id}/outros/summary`);
    if (result.success) {return result.data}
    return undefined;
  }

  GetFrentes = async (id: number, pageIndex: number = 1, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/parlamentar/${id}/frentes?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {return result.data;}
    return undefined;
  }

  GetEventos = async (id: number, pageIndex: number = 1, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/parlamentar/${id}/eventos?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {return result.data;}
    return undefined;
  }

  GetMandatos = async (id: number, pageIndex: number = 1, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/parlamentar/${id}/mandatos?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {return result.data;}
    return undefined;
  }

  GetHistorico = async (id: number, pageIndex: number = 1, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/parlamentar/${id}/historico?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {return result.data;}
    return undefined;
  }

}

export default new ParlamentarOutrosService();
