import * as Yup from "yup";

const TCTemaCorporativoSubTemaCreateSchema = Yup.object({
    descricao: Yup.string().required({ name: "nome", err: "Campo com preenchimento obrigatório" }),
    //id_tema_grupo: Yup.number().required({ name: "grupo", err: "Campo com preenchimento obrigatório" }),
    id_tema: Yup.number().required({ name: "tema", err: "Campo com preenchimento obrigatório" }),
    // id_prioridade: Yup.number().required({ name: "prioridade", err: "Campo com preenchimento obrigatório" }),
    ativo: Yup.boolean().required({ name: "prioridade", err: "Campo com preenchimento obrigatório" }),
});


export default TCTemaCorporativoSubTemaCreateSchema;