
import React from 'react';
// import _ from 'lodash';
import { Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import blankAvatar from '../../../img/avatar_foto.jpg';
import { IParlamentar } from '../../../models/Parlamentar.Model';
import { usePallet } from '../../../contexts/PalletContext';
import CircularLoading from '../../../components/CircularLoading';
import ImageLoader from '../../../components/ImageLoader';

const ParlamentarConsultaHeader = (props: { parlamentar: IParlamentar,idPermissao: number }): JSX.Element => {
    const { pallet } = usePallet();
    // console.log("OLA",props.parlamentar)
    return (
        <div
            style={{
                backgroundImage:
                    `${props.parlamentar?.cliente?.qualificacao?.toLowerCase() === "convergente" && props.idPermissao !== 4 ? pallet.color.ParlamentarQualificacao.convergente :
                        props.parlamentar?.cliente?.qualificacao?.toLowerCase() === "divergente" && props.idPermissao !== 4 ? pallet.color.ParlamentarQualificacao.divergente :
                            props.parlamentar?.cliente?.qualificacao?.toLowerCase() === "neutro" || props.idPermissao === 4 ? pallet.color.ParlamentarQualificacao.neutro :
                                pallet.color.ParlamentarQualificacao.naoClassificado
                    }`,
                height: "95px",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px"
            }}>
            <Grid container justifyContent="flex-start">
                <div style={{
                    marginLeft: `${isMobile ? "15px" : "50px"}`,
                    marginTop: "30px",
                    }}>
                    <ImageLoader
                        src={props.parlamentar?.url_foto}
                        pallet={pallet}
                        canEdit={false}
                    />
                </div>
            </Grid>
        </div>
    )

}

export default ParlamentarConsultaHeader;