import { Grid, IconButton, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { usePallet } from "../../../../contexts/PalletContext";
import legis_agenda from "../../../../img/logo_legis_alta.png";
import PrintIcon from '@mui/icons-material/Print';
import { isMobile } from 'react-device-detect';
import legis_agenda2 from "../../../../img/legis_agenda2.png";
import RelatorioService from "../../../../services/RelatoriosService";
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/utils';
import CircularLoading from '../../../../components/CircularLoading';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';



export default function RelatorioManifestacaoAcoesPorDepartamento() {
    const { pallet } = usePallet();
    const { dtIni } = useParams() as any;
    const { dtFin } = useParams() as any;
    const [loading, setLoading] = useState(true)

    let filter = useQuery().get('filter');
    const [filtro, setFiltro] = useState(filter)
    const [dataPrazo, setDataPrazo] = useState([])
    const [dataForaPrazo, setDataForaPrazo] = useState([])
    const [totalForaPrazo, setTotalForaPrazo] = useState(Number)
    const [totalNoPrazo, setTotalNoPrazo] = useState(Number)

    let filtrotitle = []

    try { filtrotitle = JSON.parse(filter) } catch { filtrotitle = [] };
    
    let esferaTipo = (filtrotitle.length>0 ? filtrotitle[0].value : "Geral")

    const Imprimir = () => {
        window.print()
    }

    React.useEffect(() => {
        setLoading(true)

        if (
            String(format(new Date(dtIni), "yyyy")).slice(0, 1) !== "0" &&
            String(format(new Date(dtFin), "yyyy")).slice(0, 1) !== "0"
        ) {


            RelatorioService.GetProposicoesManifestAcaoPrepDep(
                format(new Date(dtIni), "yyyy-MM-dd"),
                format(new Date(dtFin), "yyyy-MM-dd 23:59:00.000"),
                String(filtro)
            ).then(async res => {


                await setDataPrazo(res?.data?.noprazo?.lista)
                await setDataForaPrazo(res?.data?.foraprazo?.lista)
                await setTotalForaPrazo(res?.data?.foraprazo?.total)
                await setTotalNoPrazo(res?.data?.noprazo?.total)

                await setLoading(false)
            })
        }
    }, []);

    const CheckEsfera = (select) => {
        switch (select) {
            case 'F':
                return 'Federal'
            case 'E':
                return 'Estadual'
            case 'M':
                return 'Municipal'
            default:
                return 'Federal'
        }
    }

    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                        }}>
                                            <img style={{ width: '100px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                Relatório / {esferaTipo}
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Manifestação das Ações Preparatórias por Unidade de negócio
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            <tr >
                                <td>
                                    {loading ?
                                        <CircularLoading
                                            size={100}
                                            top={isMobile ? "37%" : "40%"}
                                            left={isMobile ? "6%" : "16%"}
                                            zIndex={2}
                                        />
                                        :
                                        <Grid style={{
                                            width: '990px',
                                            marginTop: '30px',
                                            border: `1px solid #e0e0e0`,
                                            borderRadius: '7px'
                                        }}>
                                            <Grid container>
                                                <Grid item>
                                                    <Grid style={{
                                                        borderTopLeftRadius: '7px',
                                                        borderBottomLeftRadius: '7px',
                                                        backgroundColor: `${pallet.backgroundColorQuintenary}`,
                                                        padding: '15px',
                                                        marginTop: '-1px',
                                                        width: '70px',
                                                    }}>
                                                        <CalendarTodayIcon style={{
                                                            marginTop: '3px',
                                                            marginLeft: '8px',
                                                            color: `${pallet.textColorSecondary}`
                                                        }} />
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid >
                                                        <Typography
                                                            style={{
                                                                color: `${pallet.color.quinternary.font}`,
                                                                fontSize: `${pallet.general.size16}`,
                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                fontWeight: pallet.general.weight_bold,
                                                                paddingLeft: "20px",
                                                                paddingTop: '10px'
                                                            }}>
                                                            PERÍODO DE APRESENTAÇÃO
                                                        </Typography>

                                                    </Grid>
                                                    <Grid>
                                                        <Grid container>
                                                            <Grid >
                                                                <Typography
                                                                    style={{
                                                                        color: `${pallet.color.secundary.font}`,
                                                                        fontSize: `${pallet.general.size12}`,
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.general.weight_bold,
                                                                        paddingLeft: "20px"
                                                                    }}>
                                                                    {String(format(new Date(dtIni), 'dd/MM/yyyy'))}
                                                                </Typography>

                                                            </Grid>
                                                            <Grid >
                                                                <Typography
                                                                    style={{
                                                                        color: `${pallet.color.secundary.font}`,
                                                                        fontSize: `${pallet.general.size12}`,
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.general.weight_bold,
                                                                        paddingLeft: "5px"
                                                                    }}>
                                                                    à
                                                                </Typography>

                                                            </Grid>
                                                            <Grid >
                                                                <Typography
                                                                    style={{
                                                                        color: `${pallet.color.secundary.font}`,
                                                                        fontSize: `${pallet.general.size12}`,
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.general.weight_bold,
                                                                        paddingLeft: "5px"
                                                                    }}>
                                                                    {String(format(new Date(dtFin), 'dd/MM/yyyy'))}
                                                                </Typography>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                            <Grid style={{marginTop: '70px'}}>
                            TOTAL: {totalNoPrazo}
                            </Grid>
                                <Grid style={{
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_bold,
                                    color: `${pallet.color.secundary.font}`
                                }}>
                                    | UNIDADE DE NEGÓCIO DENTRO DO PRAZO
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            UNIDADE DE NEGÓCIO
                                        </Grid>
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            %
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataPrazo.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: 'auto',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.departamento}
                                                </Grid>
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.count}
                                                </Grid>
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {Math.round((Number(x.count) * 100) / Number(totalNoPrazo))}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px'}}>
                                    TOTAL: {totalForaPrazo}
                                </Grid>
                                <Grid style={{
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_bold,
                                    color: `${pallet.color.secundary.font}`
                                }}>
                                    | UNIDADE DE NEGÓCIO FORA DO PRAZO
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            UNIDADE DE NEGÓCIO
                                        </Grid>
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            %
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataForaPrazo.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: 'auto',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.departamento}
                                                </Grid>
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.count}
                                                </Grid>
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {Math.round((Number(x.count) * 100) / Number(totalForaPrazo))}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}


                    </Grid>

                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
