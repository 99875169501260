import React from 'react';
import useStyles from '../../../components/layout/Styles';
import { Grid, Divider, Typography, Paper, Box } from '@mui/material';
import { usePallet } from '../../../contexts/PalletContext';
import { isMobile } from 'react-device-detect';
import LineChart from '../../../components/LineChart';
import DateYearSelector from '../../../components/DateYearSelector';
import EmptyError from '../../../components/EmptyError';

const PieChartParlamentarAcoesInfluencia = (props: {
    masterTitle: string,
    titleChart: string,
    total: number,
    seguindo: number,
    yearSel: Date,
    onDateChange: (dtYear: Date) => void,
    labels?: Array<{ ano: string, mes: string, qtd: string }>
}) => {

    const classes = useStyles();
    const { pallet } = usePallet();

    const onChangeDate = (yearDate: Date) => {
        props.onDateChange(yearDate)
    }

    return (
        <Paper>
            <div className={classes.paper} style={{ backgroundColor: `${pallet.backgroundColorTertiary}` }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Typography
                        style={{
                            color: `${pallet.backgroundColorPrimary}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            paddingRight: "20px"
                        }}>
                        {props.masterTitle}
                    </Typography>
                </Grid>
            </div>
            <Divider />
            <div className={classes.paper}>
                <Grid container>

                    <Grid xs={12} item>
                        <Grid container justifyContent={`${isMobile ? "center" : "flex-end"}`}>
                            <Grid container alignItems="center" direction="row"

                                style={{
                                    height: "53px",
                                    minWidth: `${isMobile ? "93px" : "93px"}`,
                                    maxWidth: `${isMobile ? "93px" : "93px"}`,
                                    background: "rgba(75, 17, 150, 0.2)",
                                    borderRadius: '5px',
                                    justifyContent: `${isMobile ? "center" : "rigth"}`                                  
                                }}
                            >

                                {/* <Grid >
                                    <CalendarOutlineIcon style={{
                                        color: `${pallet.color.secundary.font}`,
                                        marginTop: '6px',
                                        opacity: "0% !important",
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        marginLeft: `${isMobile ? "10px" : "5px"}`
                                    }} />
                                </Grid> */}
                                {/* <Grid
                                    style={{
                                        color: `${pallet.color.secundary.font}`,
                                        fontWeight: 400,
                                    }} >
                                    <DateRangeSelector color={pallet.color.secundary.font}

                                        startDate={props.dtIni}
                                        endDate={props.dtFin}
                                        onChange={onChangeDate}
                                    /> 
                                    <DateYearSelector yearDate={props.yearSel} onChange={onChangeDate} />

                                </Grid> */}

                                <DateYearSelector yearDate={props.yearSel} onChange={onChangeDate} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            {/* <Box display="flex" justifyContent="center" style={{ paddingTop: "30px", paddingBottom: "30px" }} >
                <PieChart
                    width={width}
                    height={height}
                    data={props.labels.map(i => {
                        return ({
                            key: i.key,
                            data: i.data
                        })
                    })}

                    series={
                        <PieArcSeries
                            displayAllLabels={false}
                            label={
                                null
                                //  <PieArcLabel
                                //      format={(v: any) => PieArcLabelFormat(v)}
                                //      fontFamily={`${pallet.fontFamily.general}`}
                                //      width={160}
                                //      height={40}
                                //  />
                            }
                            explode={false}
                            doughnut={true}
                            cornerRadius={cornerRadius}
                            padAngle={padAngle}
                            padRadius={padRadius}
                            colorScheme={props.labels.map(i => { return (i.color) })}
                        />
                    }
                />
            </Box> */}

            {/* <Divider /> 
            style={{ paddingTop: "5px", paddingRight: "20", paddingBottom: "18px", paddingLeft: "25px" }}
            */}

            <Box justifyContent="flex-start">

                {props?.labels?.reduce((a, b) => Number(a) + Number(b["qtd"]), 0) > 0 ?
                    <Grid>
                        {/* <GraficoLinha2
                            dados={dadosGraficoLinha}
                        />
                         */}

                        <LineChart dataresult={props.labels} />

                    </Grid>
                    :
                    <Grid
                        style={{
                            color: `${pallet.textColorQuaternary}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "98px",
                            marginTop: "20px",
                        }}
                    >
                        <EmptyError title="Ops!" description="Ainda não temos dados suficientes para gerar resultados." />
                    </Grid>
                }
            </Box>

        </Paper>

    )

}

export default PieChartParlamentarAcoesInfluencia;

function PieArcLabelFormat(v: any): import("react").ReactNode {
    throw new Error('Function not implemented.');
}
function setPeriod(startDate: Date, endDate: Date) {
    throw new Error('Function not implemented.');
}

