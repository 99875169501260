import React from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import { Menu, FormControl, Divider, IconButton, MenuItem, ListItemIcon, ListItemText, Switch, FormGroup, FormControlLabel, Grid } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useNavigate } from 'react-router-dom';
import { usePallet } from '../../../contexts/PalletContext';
import { useAlert } from "../../../contexts/AlertContext";
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsContextService, { INotificationsContextResultData } from '../../../services/NotificationsContextService';
import { useAuthorization } from '../../../contexts/AuthorizationContext';

const MenuNotification = styled(Menu)`
li {
  font-family: ${(props => props.pallet.fontFamily.general)};
  font-weight: ${(props => props.pallet.fontWeight.lighter)};
  font-size: ${(props => props.pallet.fontSize.titleWidgets)};}

  a {
    display: flex;
    color: #1E0736;
    text-decoration: none;
  }

  svg {
    color: #1E0736;
  }

  .MuiDivider-fullWidth {
    margin: 3px 0px;
  }
  .MuiFormControlLabel-labelPlacementEnd {
    min-width: 267px;
    display: flex;
    flex-flow: row-reverse;
    margin-left: unset;
    margin-right: unset;

    span:last-child {
      margin-right: auto;
    }
  }
`;

export default function NotificacaoMenuButton(props: {
  notificacao: INotificationsContextResultData,
  reloadList?: () => void,
  filtro?: string
}) {
  const { pallet } = usePallet();
  const navigate = useNavigate();
  const { NewAlert } = useAlert();

  // const [checked, setChecked] = React.useState<boolean>(!!props.notificacao?.noFollow);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [checkedF, setCheckedF] = React.useState<boolean>(false);

  const removeTags = (text: string) => {
    return text.replace(/<[^>]*>/g, ""); //remove tags html
  };

  const { user } = useAuthorization();



  // const whatsappText = (text: string) => {
  //   return "*LEGISNOTE NOTIFICA:*\n\n"
  //     .concat(
  //       text
  //         .replace(/<[^>]b>/g, "*")
  //         .replace(/<[^>]*b> /g, "*")
  //         .replace(/<[^>]*b>/g, "*")
  //         .replace(/<[^>]*span>/g, "")
  //         .replace(/<br[^>]*>/g, "\n")
  //         .replace(/<[^>]*li>/g, "\n")
  //         .replace(/<[^>]*ul>/g, "")
  //         .replace(/<[^>]*a>/g, "")
  //         .replace(/<a[^>]*>/g, "")
  //         .replace(/<span[^>]*>/g, "")
  //         .replace(/<[^>]*p>/g, "")
  //         .replace(/#*#/g, "")
  //     )
  // };


  const [formattedText, setFormattedText] = React.useState('');

  let letra = window.location.href.search("/");
  let end = window.location.href.slice(letra + 2);
  let letra2 = end.search("/");
  let end2 = end.slice(0, letra2);
  function transformLinks(html) {
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*?>(.*?)<\/a>/gi;
    const result = html.replace(linkRegex, (match, p1, p2, p3) => {
        if (p2.startsWith('/')) {
            return `*${p3}`;
        } else {
            return `*${p3}* <=> ${p2}`;
        }
    });
    return result;
}

const whatsappText = (html) => {
  let text = transformLinks(html);
  text = text.trim()
        .replace(/>\s*\n/g, '>')
        .replace(/(\r\n|\n|\r)/gm, '') // elimina quebras de linha de texto
        // .replace(/>\s*/g, '>')
        .replace(/<b>\s*/g, '*') // trata abertura de b
        .replace(/\s*<\/b>\s*/g, '* ') // trata fechamento de b
        .replace(/<br\/?>|<p>/g, '\n') // trata quebra de linha
        .replace(/<li>/g, '\n- ') // trata abertura de li
        .replace(/<\/li>/g, '') // trata fechamento de li
        .replace(/<span style="display: none;">[^>]+\/span>/g, '') // trata chaves ocultas
        .replace(/<[^>]+>/g, '')
        ;
  return text;
}



  const emailText = (text: string) => {
    return " LEGISNOTE NOTIFICA: \n\n"
      .concat(
        text
          .replace(/<[^>]b>/g, " ")
          .replace(/<[^>]*b> /g, " ")
          .replace(/<[^>]*b>/g, " ")
          .replace(/<[^>]*span>/g, "")
          .replace(/<br[^>]*>/g, "%0D%0A")
          .replace(/<[^>]*li>/g, "%0D%0A")
          .replace(/<[^>]*ul>/g, "")
          .replace(/<[^>]*a>/g, "")
          .replace(/<a[^>]*>/g, "")
          .replace(/<[^>]*p>/g, "")
      )
  };


  const handleOnChangeSeguir = (id_notificacao: number) => {
    if (props.filtro === "A" || !props.filtro) {
      NotificationsContextService
        .Seguir(id_notificacao)
        .then((res) => {
          if (res) {
            NewAlert("success", "Notificação alterada.");
            if (checked === true) {
              setChecked(false)
            } else {
              setChecked(true)
            }

            props.reloadList()
          } else {
            NewAlert("error", "Houve um erro. Tente novamente.");
          }
        })
    } else if (props.filtro === "D") {
      NotificationsContextService
        .NaoSeguir(props.notificacao.tipo)
        .then((res) => {
          if (res) {
            NewAlert("success", "Notificação alterada.");
            if (checkedF === true) {
              setCheckedF(false)
            } else {
              setCheckedF(true)
            }
            props.reloadList()
          } else {
            NewAlert("error", "Houve um erro. Tente novamente.");
          }
        })

    }
  };

  // let sMailto = `mailto:?subject=Nova%20Notificacao%20${props.notificacao.modulo.toUpperCase()}%20&body=${emailText(props.notificacao.mensagem)}`;

  // function doMailto() {
  //   window.open(sMailto);
  // }




  // TODO: VERIFICAR TRECHO COMENTADO ABAIXO -> PRECISA TER ISSO ? Caso sim, implementar seguir ao clicar
  return (
    <PopupState variant="popover" popupId="notification-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <IconButton
            {...bindTrigger(popupState)}
          >
            <MoreHorizIcon />
          </IconButton>
          <MenuNotification {...bindMenu(popupState)} pallet={pallet}>
            <FormControl component="fieldset" onChange={() => { return true }} style={{ minWidth: "195px" }}>

              {/* {props.notificacao.modulo === "PROPOSIÇÃO" && (
                <>
                  <MenuItem onClick={() => navigate(`${props.notificacao?.path}`)} >
                    <ListItemIcon>
                      <AddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Seguir Proposição</ListItemText>
                  </MenuItem>
                  <Divider />
                </>
              )} */}
              <MenuItem onClick={() => {
                navigator.clipboard.writeText(whatsappText(props.notificacao.mensagem));
                popupState.close();
              }}>
                <ListItemIcon>
                  <ContentCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Copiar texto da notificação</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={popupState.close}>
                {user.id_permissao != 3 ?
                  <Grid onClick={props.notificacao.mensagem.length > 1500 ? () => NewAlert("error", "O texto da notificação excede o limite que pode ser copiado diretamente no corpo do email.") : () => window.open(`mailto:?subject=Nova%20Notificacao%20${props.notificacao.modulo.toUpperCase()}%20&body=${emailText(props.notificacao.mensagem)}`, "_blank")}>
                    {/* <a href={`mailto:?subject=Nova%20Notificacao%20${props.notificacao.modulo.toUpperCase()}%20&body=${emailText(props.notificacao.mensagem)}`}> */}
                    <a href='#'>
                      <ListItemIcon>
                        <ShareIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Compartilhar por e-mail</ListItemText>
                    </a>
                  </Grid>


                  :

                  <Grid style={{ display: 'flex', flexDirection: 'row' }} onClick={(() => NewAlert("error", "Não é possível realizar está ação com este perfil."))}>
                    <ListItemIcon>
                      <ShareIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Compartilhar por e-mail</ListItemText>
                  </Grid>

                }
              </MenuItem>
              {props.filtro === "A" || props.filtro === "D" ?
                <>
                  {user.id_permissao !== 4 ?
                    <>
                      <Divider />
                      <MenuItem>
                        <ListItemIcon>
                          <NotificationsIcon fontSize="small" />
                        </ListItemIcon>
                        <FormGroup>
                          <FormControlLabel control={
                            props.filtro === 'D' ?
                              <Switch
                                checked={checkedF}
                                onChange={() => user.id_permissao !== 3 ? handleOnChangeSeguir(props.notificacao?.id) : NewAlert("error", "Não é possível realizar está ação com este perfil.")}
                              />
                              :
                              <Switch
                                checked={checked}
                                onChange={() => user.id_permissao !== 3 ? handleOnChangeSeguir(props.notificacao?.id) : NewAlert("error", "Não é possível realizar está ação com este perfil.")}
                              />

                          } label={
                            <ListItemText>{props.filtro === 'D' ? "Ativar" : "Desativar"} notificação do tipo</ListItemText>
                          } />
                        </FormGroup>

                      </MenuItem>
                    </>
                    :
                    null
                  }
                </>
                :
                null
              }

            </FormControl>
          </MenuNotification>
        </React.Fragment>
      )}
    </PopupState>

  );
}