import { Button } from '@mui/material';
import ButtonCount from '../../../components/ButtonCount';
import { usePallet } from '../../../contexts/PalletContext';
import styled from 'styled-components';
import ButtonNoCount from '../../../components/ButtonNoCount';
export interface IFiltroTipoProps {
    key: string,
    label: string,
    active?: boolean,
    origin?: string,
    type?: string[],
    component?: React.ReactNode,
    data?: any,
    value?: string,
    esfera?: string[],
    expandido?:boolean,
    dtIni?: Date,
    dtFin?: Date,
    hide?: boolean
}

export function TiposList(props: {
    filtros: IFiltroTipoProps[],
    onChange: (event: any) => void,
}): JSX.Element {
    const { pallet } = usePallet();
    return (
        <>
            {props.filtros?.map((item) => {
                return (
                    <>
                        <ButtonNoCount
                            item={item}
                            active={item.active}
                            onClick={(e) => props.onChange(e)}
                            style={{
                                backgroundColor: `${item.active ? pallet.textColorQuintenary : "#FFFFFF"}`,
                                display:`${item.hide ? 'none' : 'inline-flex'}`,
                                //flexDirection: "row-reverse",
                                //justifyContent: "space-between"    
                            }}
                        />
                    </>
                )
            })}
        </>
    )
}


export default TiposList;